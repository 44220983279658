import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { CardBody, Col, Row, Container, Card } from 'reactstrap';

import Breadcrumbs from '../../../components/shared/Breadcrumbs/Breadcrumbs';

import TrainingRecordsForm from './Form';

const TrainingRecordsCreate = ({ user }) => {
  const history = useHistory();
  const { id: organizationId } = useRouteMatch().params;

  const handleCancel = () => {
    history.push(`/organizations/${organizationId}/training-records`);
  };

  const breadCrumbItems = [
    {
      link: `/organizations/${organizationId}/training-records`,
      title: 'Training records',
    },
    {
      link: '',
      title: 'Create Training records',
    },
  ];

  return (
    <div className="page-content">
      <Container fluid className="container-bottom">
        <Breadcrumbs
          name="Training records"
          breadCrumbItems={breadCrumbItems}
        />
        <Row>
          <Col sm="12" md="3">
            <Card>
              <CardBody>
                <Link
                  className="nav-back"
                  to={`/organizations/${organizationId}/training-records`}
                >
                  <i className="bx bx-chevron-left arrow"></i> Back to List
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="9">
            <Card>
              <CardBody>
                <TrainingRecordsForm handleCancel={handleCancel} user={user} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TrainingRecordsCreate;
