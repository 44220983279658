const NUMBER = 'number';

const isEveryCorrectTypes = (list, checkedType) => {
  return list.every((item) => typeof item === checkedType);
};

export const dragReorder = (list, startIndex, endIndex) => {
  if (!isEveryCorrectTypes([startIndex, endIndex], NUMBER)) {
    return list;
  }

  const dragItem = list[startIndex];
  const newList = list.filter((_, index) => index !== startIndex);

  return [...newList.slice(0, endIndex), dragItem, ...newList.slice(endIndex)];
};
