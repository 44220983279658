import React from 'react';
import Header from '../../../pages/Authentication/Header';

const Layout = ({ organizationImage, user, children }) => {
  return (
    <>
      <Header organizationImage={organizationImage} user={user} />
      <div className="page-content">{children}</div>
    </>
  );
};

export default Layout;
