import React, { useState, useEffect } from 'react';

import HumanResourcesView from '../DetailsView';
import HumanResourcesEdit from '../Edit';

import { humanResourcesDetailsModel } from '../../../../../models/human-resources/human-resources-detail';
import { useHistory } from 'react-router';

const HumanResourcesDetails = ({
  resourcesDetails,
  user,
  organizationId,
  resourceId,
}) => {
  const {
    location: { state: { openEditMode } = {} },
  } = useHistory();

  const [editMode, updateEditMode] = useState(false);
  const [humanResourcesModel, updateHumanResourcesModel] = useState(
    humanResourcesDetailsModel,
  );

  useEffect(() => {
    if (resourcesDetails) {
      updateHumanResourcesModel(resourcesDetails);
    }
  }, [resourcesDetails]);

  useEffect(() => {
    if (openEditMode) {
      updateEditMode(true);
    }
  }, [openEditMode]);

  return (
    <div className="general">
      {editMode ? (
        <HumanResourcesEdit
          user={user}
          updateEditMode={updateEditMode}
          humanResourcesModel={humanResourcesModel}
          updateHumanResourcesModel={updateHumanResourcesModel}
          organizationId={organizationId}
          resourceId={resourceId}
        />
      ) : (
        <HumanResourcesView
          user={user}
          humanResourcesModel={humanResourcesModel}
          updateEditMode={updateEditMode}
          organizationId={organizationId}
        />
      )}
    </div>
  );
};

export default HumanResourcesDetails;
