import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, Modal } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { useHistory } from 'react-router';

import ImageCropper from '../Cropper';
import placeholderLogo from '../../../assets/images/im-placeholder.svg';
import { formatBytes } from '../../../utils/format-bytes-helper';
import { fileToDataURL } from '../../../utils/file-helper';
import { validationImageCropper } from '../../../utils/validation-cropper';
import { toast } from 'react-toastify';
import DocumentSetting from '../../../components/OrganizationSetting';
import { useIsCanViewDocument } from '../../../contexts/ViewDocument';
import { getDataUser } from '../../../utils/get-store-user';
import { USER_ROLE } from '../../../constants/helper';
import { ERROR_ACCESS_MESSAGE } from '../../../constants/messages';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const OrganizationSetting = ({ organizationImage, organizationName }) => {
  const history = useHistory();
  const onError = useErrorHandler();

  const [cropperModal, setCrroperModal] = useState(() => ({
    isOpen: false,
    urlFile: null,
  }));

  const { role } = getDataUser();
  const { clientUserAccess } = useIsCanViewDocument();

  useEffect(() => {
    const { сanManageOrganization } = clientUserAccess;
    if (role === USER_ROLE && !сanManageOrganization) {
      toast.error(ERROR_ACCESS_MESSAGE);
      history.push('/user-organizations');
    }
  }, [clientUserAccess, history, role]);

  const handleToogleModal = () => {
    setCrroperModal((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const handleOpenCropper = (url) => {
    setCrroperModal({ urlFile: url, isOpen: true });
  };

  const handleAcceptedFile = (file) => {
    try {
      validationImageCropper(file);
    } catch (error) {
      toast.error(error.message);
      return;
    }

    fileToDataURL(file)
      .then((result) => handleOpenCropper(result))
      .catch(onError);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex">
                <h4 className="mb-0 font-size-18">
                  “{organizationName ? organizationName : ''}” Settings
                </h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col sm="6">
                        <div className="dropzone-previews mb-3 mb-sm-0" id="file-previews">
                          {!organizationImage && (
                            <div className="placeholder-image d-flex justify-content-center">
                              <img src={placeholderLogo} alt="placeholder" />
                            </div>
                          )}
                          {organizationImage && (
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="60"
                                  className="rounded bg-light"
                                  alt={organizationImage.originalFileName}
                                  src={organizationImage.originalPath}
                                />
                              </Col>
                              <Col>
                                <span className="text-muted font-weight-bold">
                                  {organizationImage.originalFileName}
                                </span>
                                <p className="mb-0">
                                  <strong>
                                    {formatBytes(organizationImage.fileSize)}
                                  </strong>
                                </p>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </Col>
                      <Col
                        sm="6"
                        className="d-block d-sm-flex align-items-center justify-content-center justify-content-sm-end"
                      >
                        <Dropzone
                          onDrop={(acceptedFile) =>
                            handleAcceptedFile(acceptedFile[0])
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="btn btn-upload" {...getRootProps()}>
                              {organizationImage ? 'Change' : 'Upload Logo'}
                              <input {...getInputProps()} />
                            </div>
                          )}
                        </Dropzone>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                *Please, consider, limitation on the image size is <b>10Mb</b>{' '}
                max. <br />
                It is strongly recommended to upload an image with a transparent
                background in the format <b>PNG</b> or <b>SVG</b>.
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <DocumentSetting />
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={cropperModal.isOpen}
        toggle={handleToogleModal}
        centered={true}
        size="lg"
      >
        <ImageCropper
          handleToogleModal={handleToogleModal}
          urlFile={cropperModal.urlFile}
        />
      </Modal>
    </>
  );
};

export default OrganizationSetting;
