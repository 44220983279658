import React, { useState } from 'react';
import { Badge } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';
import { getFormatTime } from '../../../../utils/get-hour-times';
import TaskDeleteModal from '../../../../modals/TaskDelete';
import { deleteTrainingRecords } from '../../../../utils/api/training-records-api';
import MessageResetSuccess from '../../../../components/ToastSuccess';
import { SUCCESS_TRAINING_DELETE } from '../../../../constants/messages';
import { isUrlString } from '../../../../utils/is-url';
import {
  PENDING_STATUS,
  TRAINING_STATUS,
} from '../../../../constants/trainingRecords';
import CheckboxHead from '../../../../components/tables/HeadCheckbox';
import BodyCheckbox from '../../../../components/tables/BodyCheckbox';
import IconTooltip from '../../../../components/tooltips/IconTooltip';
import TableCellText from '../../../../components/tables/TableCellText';

export const columnsHead = [
  {
    name: 'checkbox',
    accessor: '',
    sort: false,
    Component: ({
      provided,
      snapshot,
      checkAllDocuments,
      checkedHead,
      pagination: { data },
    }) => {
      return (
        <CheckboxHead
          data={data}
          checkedHead={checkedHead}
          checkAllDocuments={checkAllDocuments}
          provided={provided}
          snapshot={snapshot}
        />
      );
    },
  },
  {
    name: 'number',
    accessor: 'System #',
    sort: true,
    classes: 'sort',
    widthPercent: 10,
  },
  {
    name: 'name',
    accessor: 'Name',
    sort: true,
    classes: 'sort',
    widthPercent: 14.7,
  },
  {
    name: 'assignees',
    accessor: 'Assignee(s)',
    sort: false,
    widthPercent: 14.7,
  },
  {
    name: 'linkToRecord',
    accessor: 'Link to Record',
    sort: false,
    widthPercent: 10,
  },
  {
    name: 'assigner',
    accessor: 'Assigner',
    sort: true,
    classes: 'sort',
    widthPercent: 14.7,
  },
  {
    name: 'trainingStatus',
    accessor: 'Status',
    sort: true,
    classes: 'sort',
    widthPercent: 10,
  },
  {
    name: 'creator',
    accessor: 'Creator',
    sort: true,
    classes: 'sort',
    widthPercent: 15,
  },
  {
    name: 'creationDate',
    accessor: 'Creation Date',
    sort: true,
    classes: 'sort',
    widthPercent: 14.5,
  },
  {
    name: 'creationTime',
    accessor: 'Creation Time',
    sort: true,
    classes: 'sort',
    widthPercent: 14.5,
  },
  {
    name: 'dueDate',
    accessor: 'Due Date',
    sort: true,
    classes: 'sort',
    widthPercent: 10,
  },
  {
    name: 'actions',
    accessor: 'Options',
    sort: false,
    classes: 'text-center',
    widthPercent: 15.7,
  },
];

export const columnsBody = [
  {
    name: 'checkbox',
    accessor: 'Selected',
    Component: ({ order: { id, isChecked = false }, handleUpdateChecked }) => {
      return (
        <BodyCheckbox
          id={id}
          isChecked={isChecked}
          handleUpdateChecked={handleUpdateChecked}
        />
      );
    },
  },
  {
    name: 'number',
    accessor: 'System #',
  },
  {
    name: 'name',
    accessor: 'Name',
    Component: ({ order, name, columnsHidden, organizationId }) => {
      const history = useHistory();
      const { id } = order;

      const handleRedirect = (organizationId, id) => {
        history.push(`/organizations/${organizationId}/training-records/${id}`);
      };

      return (
        <td
          key={name}
          onClick={() => handleRedirect(organizationId, id)}
          className={classnames('cursor-pointer', {
            'column-hidden': !columnsHidden[name],
          })}
        >
          <p className="td-before">Training Name</p>
          <TableCellText text={order[name]} id={`Training${name}${id}`} />
        </td>
      );
    },
  },
  {
    name: 'assignees',
    accessor: 'Assignee(s)',
    classes: '',
    Component: ({ order, name, columnsHidden }) => {
      const { assignees } = order;

      return (
        <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
          <p className="td-before">Assignee(s)</p>
          <TableCellText text={assignees} id={`Training${name}${order.id}`} />
        </td>
      );
    },
  },
  {
    name: 'linkToRecord',
    accessor: 'Link to Record',
    Component: ({ order, name, columnsHidden }) => (
      <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
        <p className="td-before">Link to Record</p>
        <div className="d-flex align-items-center justify-content-start">
          {order.link ? (
            isUrlString(order.link) ? (
              <a href={order.link} target='_self'>Document</a>
            ) : (
              <TableCellText
                text={order.link}
                id={`Training${name}${order.id}`}
              />
            )
          ) : (
            <span>-</span>
          )}
        </div>
      </td>
    ),
  },
  {
    name: 'assigner',
    accessor: 'Assigner',
    Component: ({ order, name, columnsHidden }) => {
      const { firstName, lastName, email, role } = order.assigner;
      const userName = `${firstName || ''} ${lastName || ''} - ${
        email || ''
      } - ${role || ''}`;

      return (
        <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
          <p className="td-before">Assigner</p>
          <TableCellText text={userName} id={`Training${name}${order.id}`} />
        </td>
      );
    },
  },
  {
    name: 'trainingStatus',
    accessor: 'Status',
    Component: ({ order, name, columnsHidden }) => (
      <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
        <p className="td-before">Status</p>
        {order.trainingStatus !== null && (
          <div className='d-flex align-items-center'>
            <Badge
              className={`font-size-12 badge-soft-${
                TRAINING_STATUS[order.trainingStatus] === PENDING_STATUS
                  ? 'warning'
                  : 'success'
              }`}
              pill
            >
              {TRAINING_STATUS[order.trainingStatus]}
            </Badge>
          </div>          
        )}
      </td>
    ),
  },
  {
    name: 'creator',
    accessor: 'Creator',
    Component: ({ order, name, columnsHidden }) => {
      const { firstName, lastName, email, role } = order.creator;
      const userName = `${firstName || ''} ${lastName || ''} - ${
        email || ''
      } - ${role || ''}`;

      return (
        <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
          <p className="td-before">Creator</p>
          <TableCellText text={userName} id={`Training${name}${order.id}`} />
        </td>
      );
    },
  },
  {
    name: 'creationDate',
    accessor: 'Creation Date',
    classes: '',
    Component: ({ order, name, columnsHidden }) => (
      <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
        <p className="td-before">Creation Date</p>
        <p>
          {order.creationDate ? moment(order.creationDate).format('ll') : ''}
        </p>
      </td>
    ),
  },
  {
    name: 'creationTime',
    accessor: 'Creation Time',
    classes: '',
    Component: ({ order, name, columnsHidden }) => (
      <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
        <p className="td-before">Creation Time</p>
        <p>{order.creationTime ? getFormatTime(order.creationTime) : ''}</p>
      </td>
    ),
  },
  {
    name: 'dueDate',
    accessor: 'Due Date',
    Component: ({ order, name, columnsHidden }) => (
      <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
        <p className="td-before">Due Date</p>
        <p>{order.dueDate ? moment(order.dueDate).format('ll') : ''}</p>
      </td>
    ),
  },
  {
    name: 'actions',
    accessor: 'Actions',
    classes: '',
    Component: ({ order, columnsHidden, organizationId, onDeleteRow }) => {
      const history = useHistory();

      const handleRedirect = (organizationId, id) => {
        history.push(`/organizations/${organizationId}/training-records/${id}`);
      };

      const handleOpenEdit = (organizationId, id) => {
        history.push({
          pathname: `/organizations/${organizationId}/training-records/${id}`,
          state: { openEditMode: true },
        });
      };

      const [deleteModal, updateDeleteModal] = useState({
        isOpen: false,
        values: {
          number: '',
          name: '',
        },
        model: {
          organizationId: '',
          ids: [],
        },
      });

      const handleDelete = (organizationId, id, number, name) => {
        updateDeleteModal({
          isOpen: true,
          values: { number, name },
          model: { organizationId, ids: [id] },
        });
      };

      const { canBeEdited, canBeDeleted } = order;

      return (
        <td
          className={`activate-column ${
            columnsHidden.actions ? '' : 'column-hidden'
          }`}
        >
          <p className="td-before">Actions</p>
          <div className="d-flex justify-content-start justify-content-md-center overflow-auto">
            <div className="d-flex column-user-active justify-content-start justify-content-md-end">
              <div className="actions">
                <div className="d-flex actions-inner">
                  <IconTooltip
                    icon="bx bx-show action"
                    id={`TrainingViewDetails${order.id}`}
                    text="View details"
                    onClick={() => handleRedirect(organizationId, order.id)}
                  />
                  {canBeEdited && (
                    <IconTooltip
                      icon="bx bxs-edit-alt action"
                      id={`trainingEdit${order.id}`}
                      text="Edit"
                      onClick={() => handleOpenEdit(organizationId, order.id)}
                    />
                  )}
                  {canBeDeleted && (
                    <IconTooltip
                      icon="bx bxs-trash-alt action"
                      id={`trainingDelete${order.id}`}
                      text="Delete"
                      onClick={() =>
                        handleDelete(
                          organizationId,
                          order.id,
                          order.number,
                          order.name,
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <TaskDeleteModal
            text="Training"
            isOpen={deleteModal.isOpen}
            model={deleteModal.model}
            values={deleteModal.values}
            onSubmit={(_, values) => {
              deleteTrainingRecords(values)
                .then(() => {
                  onDeleteRow(values.ids[0]);
                  toast.success(
                    <MessageResetSuccess message={SUCCESS_TRAINING_DELETE} />,
                  );
                })
                .finally(() => {
                  updateDeleteModal((prev) => ({ ...prev, isOpen: false }));
                });
            }}
            onCancel={() =>
              updateDeleteModal((prev) => ({ ...prev, isOpen: false }))
            }
          />
        </td>
      );
    },
  },
];
