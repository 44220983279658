import React from 'react';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';

import { OFFSET_ICON_TOOLTIP } from '../../../constants/tooltip';

const IconTooltip = ({ icon, id, text, onClick }) => {
  return (
    <>
      <i
        className={classnames(icon)}
        data-tip
        data-for={id}
        onClick={onClick}
      ></i>
      <ReactTooltip
        id={id}
        className="icon-tooltip"
        effect="solid"
        arrowColor="transparent"
        offset={OFFSET_ICON_TOOLTIP}
      >
        <span>{text}</span>
      </ReactTooltip>
    </>
  );
};

export default IconTooltip;
