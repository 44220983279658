import React from 'react';
import { Col, Row } from 'reactstrap';

const TableSelectedDelete = ({ title, countChecked, handleDelete }) => {
  if (countChecked) {
    return (
      <Row className="mb-3 mt-3">
        <Col className="d-flex justify-content-between align-items-center selected-delete">
          <p className="mb-0">
            Selected{' '}
            <b>
              {countChecked} {title}
              {countChecked > 1 && 's'}
            </b>
          </p>
          <div className="selected-delete__action" onClick={handleDelete}>
            <i className="bx bxs-trash-alt"></i> Delete
          </div>
        </Col>
      </Row>
    );
  }

  return null;
};

export default TableSelectedDelete;
