import React from 'react';
import { getDocumentName } from '../../../utils/documents/get-document-name';
import { AvField } from 'availity-reactstrap-validation';

const RevisionDeleteBody = ({ values = {}, model = {} }) => {
  const { revision } = values;
  const { documentId, organizationId } = model;

  return (
    <div className="modal-body">
      <p className="document-text">
        Are you sure you want to permanently delete the Revision {revision} of
        the Document {getDocumentName(values)}
      </p>
      <AvField
        name="documentId"
        type="text"
        value={documentId}
        className="btn-hidden"
      />
      <AvField
        name="organizationId"
        type="text"
        value={organizationId}
        className="btn-hidden"
      />
      <AvField
        name="revision"
        type="text"
        value={revision}
        className="btn-hidden"
      />
    </div>
  );
};

export default RevisionDeleteBody;
