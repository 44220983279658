import React from 'react';
import { Col, Row } from 'reactstrap';

const UsersList = ({ title, usersData, handleDelete, userKey }) => {
  return (
    <Row className="quality-edit-reviewer">
      <Col>
        <div className="info-column">
          <p className="info-column__title">{title}</p>
          {usersData.map(({ id, firstName, lastName, email, role }, i, arr) => {
            return (
              <React.Fragment key={`reviewers-${id}`}>
                <Row className="quality-item">
                  <Col xs="4">
                    <p>{`${firstName || ''} ${lastName || ''}`}</p>
                  </Col>
                  <Col xs="4">
                    <p>{email || ''}</p>
                  </Col>
                  <Col xs="4">
                    <div className="role-block">
                      <p className="info-column__text info-column_right">
                        {role || ''}
                      </p>
                      <i
                        className="bx bxs-trash-alt"
                        onClick={() => handleDelete(id, userKey)}
                      ></i>
                    </div>
                  </Col>
                </Row>
                {i !== arr.length - 1 && <hr />}
              </React.Fragment>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default UsersList;
