import { API } from '../backend-helper';

const HUMAN_RESOURCES = 'human-resource-records';
const DETAILS = 'details';
const FILE = 'file';
const AVAILABLE = 'available';

export const deleteHumanRecource = (body) => {
  return API.delete(`/${HUMAN_RESOURCES}`, { params: body });
};

export const getHumanResourcesDetails = (id, organizationId) => {
  return API.get(`/${HUMAN_RESOURCES}/${id}/${DETAILS}`, {
    params: { organizationId },
  });
};

export const editHumanResourcesDetails = (id, body) => {
  return API.put(`/${HUMAN_RESOURCES}/${id}/${DETAILS}`, body);
};

export const getHumanResourcesFile = (id, organizationId) => {
  return API.get(`/${HUMAN_RESOURCES}/${id}/${FILE}`, {
    params: { organizationId },
  });
};

export const getHumanResourcesAvailable = (params) => {
  return API.get(`/${HUMAN_RESOURCES}/${AVAILABLE}`, { params });
};
