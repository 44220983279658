import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select';

import { customStyles } from '../../utils/select-styles';
import { getFilteredSelect } from '../../utils/select-helpers';

const CustomMultiSelect = ({
  handleRequest,
  configOptions,
  onChange,
  isDisabled = false,
  placeholder = 'Choose',
}) => {
  const [options, updateOptions] = useState([]);
  const [search, updateSearch] = useState('');

  const handleRequestAPI = useCallback(
    (search) => {
      return handleRequest().then(({ data }) =>
        updateOptions(configOptions(data, search)),
      );
    },
    [configOptions, handleRequest],
  );

  useEffect(() => {
    handleRequestAPI();
  }, [handleRequestAPI]);

  const filteredOptions = useMemo(() => {
    return getFilteredSelect(options, search);
  }, [options, search]);

  return (
    <Select
      isMulti
      options={filteredOptions}
      onChange={(values) => onChange(values || [])}
      onInputChange={updateSearch}
      isClearable={true}
      isDisabled={isDisabled}
      styles={customStyles}
      placeholder={placeholder}
    />
  );
};

export default CustomMultiSelect;
