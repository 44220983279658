import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { Col, Row } from 'reactstrap';
import {
  INITIAL_LINK,
  INITIAL_OTHER_LINK,
} from '../../../../constants/document';
import { addNewItem } from '../../../../utils/documents/add-new-item';
import { getSeparateLinks } from '../../../../utils/documents/get-separate-links';
import { removeFilterIds } from '../../../../utils/documents/remove-filter-by-id';

import Index from '../../../Button';
import ReferencesAddDocument from '../AddDocument';
import AddOtherReferences from '../AddOther';
import ReferencesLinkEdit from '../LinkEdit';

const ReferencesAddRevision = ({ oldReferences, updateModel }) => {
  const { id: organizationId } = useRouteMatch().params;

  const [documentOldReferences, updateDocumentOldReferences] = useState([]);
  const [otherOldReferences, updateOtherOldReferences] = useState([]);

  useEffect(() => {
    const [oldDocumentsRef, oldOtherRef] = getSeparateLinks(oldReferences);
    updateDocumentOldReferences(oldDocumentsRef);
    updateOtherOldReferences(oldOtherRef);
    updateReferences(oldReferences);
  }, [oldReferences]);

  const [references, updateReferences] = useState([]);

  useEffect(() => {
    updateModel((prev) => {
      return {
        ...prev,
        references: references,
      };
    });
  }, [references, updateModel]);

  const [documentLinks, updateDocumentLinks] = useState([INITIAL_LINK]);
  const [otherLinks, updateOtherLinks] = useState([INITIAL_OTHER_LINK]);

  const handleUpdateReferences = (values, partUrl, linkId) => {
    if (values) {
      const { value: linkDescription, id, revision, type } = values;

      let link = `${window.location.origin}/organizations/${organizationId}/${partUrl}/${id}`;
      if (revision) {
        link = `${link}?revision=${revision}`;
      }

      updateReferences((references) => {
        const equalRefIndex = references.findIndex(
          ({ linkId: id }) => linkId === id,
        );
        if (equalRefIndex === -1) {
          return [...references, { type, link, linkDescription, linkId }];
        }

        return references.map((reference) => {
          const { linkId: id } = reference;
          if (linkId === id) {
            return { type, link, linkDescription, linkId };
          }
          return reference;
        });
      });
      return;
    }
    updateReferences((references) =>
      references.filter(({ linkId: id }) => id !== linkId),
    );
  };

  const handleDelete = (linkId) => {
    updateDocumentLinks((items) => removeFilterIds(items, linkId));
    updateReferences((references) => {
      return references.filter(({ linkId: id }) => id !== linkId);
    });
  };

  const handleDeleteOther = (linkId) => {
    updateOtherLinks((items) => removeFilterIds(items, linkId));
    updateReferences((references) =>
      references.filter(({ linkId: id }) => id !== linkId),
    );
  };

  const handleDeleteOldDocumentLink = (deletedId) => {
    updateReferences((references) =>
      references.filter(({ id }) => id !== deletedId),
    );
    updateDocumentOldReferences((references) =>
      references.filter(({ id }) => id !== deletedId),
    );
  };

  const handleDeleteOldOtherLink = (deletedId) => {
    updateReferences((references) =>
      references.filter(({ id }) => id !== deletedId),
    );
    updateOtherOldReferences((references) =>
      references.filter(({ id }) => id !== deletedId),
    );
  };

  return (
    <div className="general">
      <div className="general-header">
        <h6 className="general-header__title">References</h6>
      </div>
      <hr />
      <Row>
        <Col>
          <div className="info-column">
            <p className="info-column__title">Documents</p>
            {documentLinks.map((id) => {
              return (
                <ReferencesAddDocument
                  key={id}
                  id={id}
                  links={documentLinks}
                  handleDelete={handleDelete}
                  handleUpdateReferences={handleUpdateReferences}
                />
              );
            })}
            <AddOtherReferences
              color="btn btn-create"
              handler={() => updateDocumentLinks((prev) => addNewItem(prev))}
            >
              <i className="bx bx-plus"></i> Add New
            </AddOtherReferences>
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <ReferencesLinkEdit
            links={documentOldReferences}
            handleDelete={handleDeleteOldDocumentLink}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <div className="info-column">
            <p className="info-column__title">Other</p>
            {otherLinks.map((id) => {
              return (
                <Index
                  key={id}
                  id={id}
                  links={otherLinks}
                  handleDelete={handleDeleteOther}
                  handleUpdateReferences={handleUpdateReferences}
                />
              );
            })}
            <Index
              color="btn btn-create"
              handler={() => updateOtherLinks((prev) => addNewItem(prev))}
            >
              <i className="bx bx-plus"></i> Add New
            </Index>
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <ReferencesLinkEdit
            links={otherOldReferences}
            handleDelete={handleDeleteOldOtherLink}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ReferencesAddRevision;
