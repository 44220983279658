import React, { useMemo, useState, useCallback } from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
  Row,
  Col,
  Modal,
  UncontrolledTooltip,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  FormGroup,
} from 'reactstrap';
import classnames from 'classnames';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';

import Button from '../../components/Button';

import { customStyles } from '../../utils/select-styles';
import {
  DEBOUNCE_TIMER,
  FIRST_ACTIVE_TAB,
  SECOND_ACTIVE_TAB,
} from '../../constants/helper';
import {
  configureGetUsersGroup,
  configureTrainings,
} from '../../utils/config-get-organization';
import {
  changeTrainingIds,
  changeUserModel,
  changeUserRequest,
  changeUserSelect,
} from '../../utils/selector-helper-changed';
import { mergetParams } from '../../utils/merge-table-params';
import {
  getTrainingsList,
  getUsersOfUserGroup,
} from '../../utils/api/user-groups-api';
import MultiSelect from '../../components/shared/MultiSelect';

const UserAddTraining = ({
  onSubmit,
  onCancel,
  isOpen,
  organizationId,
  groupId,
}) => {
  const [activeTab, setActiveTab] = useState(FIRST_ACTIVE_TAB);

  const [userList, updateUserList] = useState([]);
  const [userRequest, updateUserRequest] = useState([]);

  const debouncedOrganization = debounce((inputValue, callback) => {
    const params = mergetParams({ organizationId, search: inputValue });
    getUsersOfUserGroup(groupId, params).then(({ data }) =>
      callback(configureGetUsersGroup(data)),
    );
  }, DEBOUNCE_TIMER);

  const handleUsersChange = (user) => {
    updateUserList(changeUserSelect(user));
    updateUserRequest(changeUserModel(user));
  };

  const getTrainingsListAPI = useCallback(() => {
    return getTrainingsList(groupId, organizationId).then(
      ({ data }) => data || [],
    );
  }, [groupId, organizationId]);

  const isEmpty = useMemo(() => userList.length, [userList]);

  const handleClose = () => {
    updateUserList([]);
    updateUserRequest([]);
    setActiveTab(FIRST_ACTIVE_TAB);
    onCancel();
  };

  const handleSubmit = (_, values) => {
    onSubmit(_, values, handleClose);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">
          Add User          
          <i
            id="training-tooltip-2"
            className="tooltip-training bx bx-info-circle info"
          ></i>
          <UncontrolledTooltip
            placement="top"
            id="tooltip-accordion"
            target="training-tooltip-2"
          >
            You can add User(s) and add specific traing record(s) to this User(s).
          </UncontrolledTooltip>
        </h5>
        <button
          type="button"
          onClick={handleClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={handleSubmit}>
        <Card className="mb-0">
          <CardBody>
            <div id="basic-pills-wizard" className="twitter-bs-wizard">
              <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === FIRST_ACTIVE_TAB,
                    })}
                  >
                    <span className="step-number mr-2">01</span>
                    User(s)
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === SECOND_ACTIVE_TAB,
                    })}
                  >
                    <span className="step-number mr-2">02</span>
                    Training Record(s)
                  </NavLink>
                </NavItem>
              </ul>
              <TabContent
                activeTab={activeTab}
                className="twitter-bs-wizard-tab-content"
              >
                <TabPane tabId={FIRST_ACTIVE_TAB}>
                  <div className="modal-body">
                    <FormGroup className="select2-container">
                      <label className="control-label">Select User(s)</label>
                      <AsyncSelect
                        styles={customStyles}
                        isMulti={true}
                        cacheOptions={false}
                        defaultOptions
                        onChange={(value) => handleUsersChange(value)}
                        loadOptions={debouncedOrganization}
                        placeholder="Select or search user(s)"
                      />
                    </FormGroup>
                  </div>
                  <div className="modal-footer">
                    <Row className="form-group">
                      <Col className="text-right">
                        <Button
                          type="button"
                          color="btn btn-light w-md waves-effect waves-light mr-3"
                          handler={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={!isEmpty}
                          handler={() => setActiveTab(SECOND_ACTIVE_TAB)}
                          color="btn btn-edit w-md waves-effect waves-light mr-3"
                        >
                          Save & Next
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tabId={SECOND_ACTIVE_TAB}>
                  <div className="modal-body">
                    {userList.map((user, i, arr) => {
                      const { id, fullName, email } = user;
                      return (
                        <React.Fragment key={id}>
                          <p>
                            {fullName} - {email}
                          </p>
                          <FormGroup className="select2-container">
                            <label className="control-label">
                              Select Training record(s)
                            </label>
                            <MultiSelect
                              id={id}
                              value={userRequest[i].trainingIds}
                              handleChange={(options) => {
                                updateUserRequest((users) =>
                                  changeUserRequest(users, options, id),
                                );
                              }}
                              handleRequest={getTrainingsListAPI}
                              placeholder="Select or search record(s)"
                              configOptions={configureTrainings}
                            />
                          </FormGroup>
                          {arr.length - 1 !== i && <hr />}
                        </React.Fragment>
                      );
                    })}
                  </div>
                  <div className="modal-footer">
                    <Row className="form-group">
                      <Col className="text-right">
                        <Button
                          type="button"
                          color="btn btn-light w-md waves-effect waves-light mr-3"
                          handler={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          handler={() => setActiveTab(SECOND_ACTIVE_TAB)}
                          color="btn btn-edit w-md waves-effect waves-light mr-3"
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </CardBody>
        </Card>
        <AvField
          name="groupId"
          type="text"
          value={groupId}
          className="btn-hidden"
        />
        <AvField
          name="organizationId"
          type="text"
          value={organizationId}
          className="btn-hidden"
        />
        <AvField
          name="users"
          type="text"
          value={changeTrainingIds(userRequest)}
          className="btn-hidden"
        />
      </AvForm>
    </Modal>
  );
};

export default UserAddTraining;
