import React, { useState, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  CardBody,
  Col,
  Row,
  Container,
  Card,
  TabContent,
  TabPane,
} from 'reactstrap';

import Breadcrumbs from '../../../components/shared/Breadcrumbs/Breadcrumbs';
import {
  GENERAL_DOCUMENT_TAB,
  QUALITY_DOCUMENT_TAB,
  REFERENCES_DOCUMENT_TAB,
  REVIEW_DOCUMENT_TAB,
  APPROVAL_DOCUMENT_TAB,
  REVISION_PARAM,
} from '../../../constants/helper';
import DocumentGeneral from '../../../components/DocumentGeneral';
import DocumentQuality from '../../../components/document/Quality';
import { User } from '../../../constants/role';
import DocumentReview from '../../../components/document/Review';
import DocumentApproval from '../../../components/document/DocumentApproval';
import { getDocumentTab } from '../../../utils/documents/get-document-tab';
import { useIsCanViewDocument } from '../../../contexts/ViewDocument';
import { getDocumentInfo } from '../../../utils/api/document-api';
import { configRevisionRequest } from '../../../utils/documents/config-body-revision';
import { useQueryHelper } from '../../../utils/query-params-helper';
import { getDataUser } from '../../../utils/get-store-user';
import { getDocumentPermission } from '../../../utils/documents/get-document-permission';
import { documentModel } from '../../../models/document/document';
import DocumentNavigation from '../../../components/document/Navagation';
import { documentRevisionModel } from '../../../models/document/document-revision';
import { emptyFieldsRevision } from '../../../constants/document';
import { useModel } from '../../../custom-hooks/useModel';
import References from '../../../components/document/References';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const DocumentView = () => {
  const { role } = getDataUser();
  const { id: organizationId, documentId } = useRouteMatch().params;
  const { revision: revisionParams } = useQueryHelper([REVISION_PARAM]);

  const { clientUserAccess } = useIsCanViewDocument();

  const [documentPermission, updateDocumentPermission] = useState({
    canViewDocumentQualityTasks: false,
    canViewDocumentReferences: false,
  });

  const { canViewDocumentQualityTasks, canViewDocumentReferences } =
    documentPermission;

  useEffect(() => {
    updateDocumentPermission(
      role === User ? clientUserAccess : getDocumentPermission,
    );
  }, [role, clientUserAccess]);

  const [currentRevision, updateCurrentRevision] = useState(() => ({
    value: null,
  }));

  const [document, updateDocument] = useState(documentModel);

  const onError = useErrorHandler();

  useEffect(() => {
    const revison = currentRevision.value || revisionParams;
    const params = configRevisionRequest({ organizationId }, revison);
    getDocumentInfo(documentId, params)
      .then(({ data }) => updateDocument(data))
      .catch(onError);
  }, [documentId, organizationId, currentRevision, revisionParams, onError]);

  const {
    revision,
    canViewApprovals,
    canViewReviews,
    canBeDeleted,
    canBeEdited,
    canViewDocumentGeneral,
  } = document;

  const [activeTab, setActiveTab] = useState(null);

  const toggleTabs = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const accessPermissionTabs = useMemo(() => {
    return [
      canViewDocumentGeneral,
      canViewDocumentQualityTasks,
      canViewDocumentReferences,
      canViewReviews,
      canViewApprovals,
    ];
  }, [
    canViewApprovals,
    canViewDocumentReferences,
    canViewDocumentGeneral,
    canViewDocumentQualityTasks,
    canViewReviews,
  ]);

  useEffect(() => {
    setActiveTab(getDocumentTab(accessPermissionTabs));
  }, [accessPermissionTabs]);

  const accessPermission = useMemo(() => {
    return {
      canViewDocumentGeneral,
      canViewDocumentQualityTasks,
      canViewDocumentReferences,
      canViewReviews,
      canViewApprovals,
      canBeDeleted,
    };
  }, [
    canViewApprovals,
    canViewDocumentReferences,
    canViewDocumentGeneral,
    canViewDocumentQualityTasks,
    canViewReviews,
    canBeDeleted,
  ]);

  const breadCrumbItems = [
    {
      link: `/organizations/${organizationId}/documents`,
      title: 'Documents',
    },
    {
      link: '',
      title: document.name,
    },
  ];

  const [revisionAddMode, updateRevisionAddMode] = useState(false);
  const [revisionModel, updateRevisionModel] = useState(documentRevisionModel);

  const { model, handleModelChange, handleChangeChecked, updateModel } =
    useModel(revisionModel, emptyFieldsRevision);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs 
          name="Documents" 
          breadCrumbItems={breadCrumbItems} 
          link={`/organizations/${organizationId}/documents`}
        />
        <Row>
          <Col xs="12" md="4" xl="3">
            <DocumentNavigation
              accessPermission={accessPermission}
              activeTab={activeTab}
              toggleTabs={toggleTabs}
              document={document}
              revisionAddMode={revisionAddMode}
            />
          </Col>
          <Col xs="12" md="8" xl="9">
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab} className="p-0">
                  {canViewDocumentGeneral && (
                    <TabPane tabId={GENERAL_DOCUMENT_TAB}>
                      <DocumentGeneral
                        canBeEditedDocument={canBeEdited}
                        currentRevision={currentRevision}
                        updateCurrentRevision={updateCurrentRevision}
                        revisionAddMode={revisionAddMode}
                        updateRevisionAddMode={updateRevisionAddMode}
                        revisionModel={revisionModel}
                        updateRevisionModel={updateRevisionModel}
                        rModel={model}
                        rHandleModelChange={handleModelChange}
                        rHandleChangeChecked={handleChangeChecked}
                        rUpdateModel={updateModel}
                      />
                    </TabPane>
                  )}
                  {canViewDocumentQualityTasks && (
                    <TabPane tabId={QUALITY_DOCUMENT_TAB}>
                      <DocumentQuality
                        revision={revision}
                        currentRevision={currentRevision}
                        updateCurrentRevision={updateCurrentRevision}
                        revisionAddMode={revisionAddMode}
                        updateRevisionModel={updateRevisionModel}
                        rUpdateModel={updateModel}
                      />
                    </TabPane>
                  )}
                  {canViewDocumentReferences && (
                    <TabPane tabId={REFERENCES_DOCUMENT_TAB}>
                      <References
                        revision={revision}
                        currentRevision={currentRevision}
                        updateCurrentRevision={updateCurrentRevision}
                        revisionAddMode={revisionAddMode}
                        rUpdateModel={updateModel}
                      />
                    </TabPane>
                  )}
                  {canViewReviews && (
                    <TabPane tabId={REVIEW_DOCUMENT_TAB}>
                      <DocumentReview
                        revision={revision}
                        currentRevision={currentRevision}
                        updateCurrentRevision={updateCurrentRevision}
                      />
                    </TabPane>
                  )}
                  {canViewApprovals && (
                    <TabPane tabId={APPROVAL_DOCUMENT_TAB}>
                      <DocumentApproval
                        revision={revision}
                        currentRevision={currentRevision}
                        updateCurrentRevision={updateCurrentRevision}
                      />
                    </TabPane>
                  )}
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DocumentView;
