import { takeEvery, fork, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import React from 'react';

import {
  IS_VALID_SET_TOKEN,
  SET_USER_PASSWORD,
  CONFIRM_EMAIL_PASSWORD,
} from './actionTypes';
import {
  setPassword,
  checkValidToken,
  confirmUserEmail,
} from '../../../utils/backend-helper';

import {
  SUCCESS_MESSAGE_SET,
  ERROR_INVALID_TOKEN,
  SUCCESS_MESSAGE_UPDATE_EMAIL,
} from '../../../constants/messages';
import MessageResetSuccess from '../../../components/ToastSuccess';

function* userSetPassword({
  payload: {
    data: { email, token, password, confirmPassword },
    history,
  },
}) {
  try {
    const response = yield call(setPassword, '/verifications/password/set', {
      email,
      token,
      password,
      confirmPassword,
    });
    if (response) {
      toast.success(<MessageResetSuccess message={SUCCESS_MESSAGE_SET} />);
      history.push('/Login');
    }
  } catch (error) {
    history.push('/Login');
  }
}

function* isValidSetToken({ payload: { data, history } }) {
  try {
    const response = yield call(checkValidToken, '/verifications/token', data);
    const {
      data: { isValid },
    } = response;

    if (!isValid) {
      toast.error(ERROR_INVALID_TOKEN);
      history.push('/Login');
    }
  } catch (error) {}
}

function* confirmEmailPassword({ payload: { data, history } }) {
  try {
    const response = yield call(confirmUserEmail, '/verifications/email', data);
    if (response) {
      toast.success(
        <MessageResetSuccess message={SUCCESS_MESSAGE_UPDATE_EMAIL} />,
      );
      history.push('/Login');
    }
  } catch (error) {}
}

export function* watchSetPassword() {
  yield takeEvery(SET_USER_PASSWORD, userSetPassword);
}

export function* watchSetTokenValid() {
  yield takeEvery(IS_VALID_SET_TOKEN, isValidSetToken);
}

export function* watchConfirmPassword() {
  yield takeEvery(CONFIRM_EMAIL_PASSWORD, confirmEmailPassword);
}

function* setPasswordSaga() {
  yield all([
    fork(watchSetPassword),
    fork(watchSetTokenValid),
    fork(watchConfirmPassword),
  ]);
}

export default setPasswordSaga;
