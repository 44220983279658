import React from 'react';
import logoLightSvg from '../../../../assets/images/logo-q.svg';

const HeaderLogo = () => {
  return (
    <span className="main-logo">
      <img src={logoLightSvg} alt="logo" height="30" />
    </span>
  );
};

export default HeaderLogo;
