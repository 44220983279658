import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Select from 'react-select';
import { debounce } from 'lodash';

import { customStyles } from '../../../utils/select-styles';
import { DEBOUNCE_TIMER } from '../../../constants/helper';

const SingleDisabledSelect = ({
  handleRequest,
  configOptions,
  handleChange,
  isDisabled = false,
  placeholder = 'Choose',
  documentTypeId,
  className,
  selectedRef,
}) => {
  const [options, updateOptions] = useState([]);

  const handleRequestAPI = useCallback(
    (search = '', documentTypeId) => {
      return handleRequest(search, documentTypeId).then((data) =>
        updateOptions(configOptions(data)),
      );
    },
    [configOptions, handleRequest],
  );

  useEffect(() => {
    if (documentTypeId) {
      handleRequestAPI('', documentTypeId);
    }
  }, [documentTypeId, handleRequestAPI]);

  const handleSearch = useMemo(() => {
    return debounce((search) => {
      if (!search) return;
      handleRequestAPI(search, documentTypeId);
    }, DEBOUNCE_TIMER);
  }, [documentTypeId, handleRequestAPI]);

  return (
    <Select
      ref={selectedRef}
      className={className}
      isDisabled={isDisabled}
      isMulti={false}
      styles={customStyles}
      isClearable={true}
      options={options}
      onChange={(value) => handleChange(value)}
      onInputChange={(value) => handleSearch(value)}
      placeholder={placeholder}
    />
  );
};

export default SingleDisabledSelect;
