import {
  REMOVE_ACTION,
  SELECT_ALL_OPTION,
  UNSELECT_ACTION,
} from '../constants/select';

export const isChangeSelectAll = (action, option, removedOption) => {
  return (
    (action === UNSELECT_ACTION && option.value === SELECT_ALL_OPTION.value) ||
    (action === REMOVE_ACTION &&
      removedOption.value === SELECT_ALL_OPTION.value)
  );
};
