// Keep request bodies
const cache = {};
const awaitMap = {};
const timers = {};
const pendingRequests = {};

const noop = () => {};

export function cancelDelayedRequest(url) {
  clearTimeout(timers[url]);
}

export function throttleAdapter(
  adapter,
  { delay = 3000, onStart = noop } = {},
) {
  return (config) => {
    const { url = '', data } = config;
    // Cache last body
    cache[url] = data;

    // Init list
    if (!awaitMap[url]) {
      awaitMap[url] = [];
    }

    // Do request
    const request = (body) => {
      config.data = body;
      return adapter(config);
    };

    // Wait some time
    const wait = () => {
      return new Promise((resolve, reject) => {
        // Stop timer prev promise
        cancelDelayedRequest(url);

        // Add to list
        awaitMap[url].push([resolve, reject]);

        // If same request pending
        // Wait finally
        if (pendingRequests[url] === true) {
          return;
        }

        // Setup new call
        timers[url] = setTimeout(function callAPI() {
          const subscribers = [...awaitMap[url]];
          // Clear old list
          awaitMap[url] = [];
          // Lock endpoint
          pendingRequests[url] = true;
          // Call start request handler
          onStart();

          const finallyHandler = () => {
            // Unlock endpoint
            pendingRequests[url] = false;
            // Send again with new data
            // After prev request
            if (awaitMap[url] && awaitMap[url].length) {
              callAPI();
            }
          };

          // Call action
          request(cache[url])
            .then((data) => {
              subscribers.forEach(([resolvePromise]) => resolvePromise(data));
              finallyHandler();
            })
            .catch((err) => {
              subscribers.forEach(([, rejectPromise]) => rejectPromise(err));
              finallyHandler();
            });
        }, delay);
      });
    };

    return wait();
  };
}
