import React from 'react';
import { Container } from 'reactstrap';

import GlobalSearchContainer from '../../../components/GlobalSearch/Container';
import OrganizationTitle from '../../../components/organization/Title';

const GlobalSearch = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <OrganizationTitle title="Global Search" />
        <GlobalSearchContainer />
      </Container>
    </div>
  );
};

export default GlobalSearch;
