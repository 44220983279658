import { useState, useEffect } from 'react';

const getIsFormEmpty = (inputs) => {
  return !Object.values(inputs).every((value) => value.trim());
};

const getObjectForm = (arrayInputs) => {
  return arrayInputs.reduce((acc, input) => {
    acc[input] = '';
    return acc;
  }, {});
};

const clearFields = (fields) => {
  return Object.entries(fields).reduce((acc, [key, value]) => {
    acc[key] = '';
    return acc;
  }, {});
};

export const useEmptyForm = (inputs) => {
  const [disabled, setDisable] = useState(true);

  const [values, setValues] = useState(() => getObjectForm(inputs));

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const clear = () => {
    setValues((prev) => clearFields(prev));
  };

  useEffect(() => {
    const isEmpty = getIsFormEmpty(values);
    isEmpty ? setDisable(true) : setDisable(false);
  }, [values]);

  return { disabled, onChange, values, clear };
};
