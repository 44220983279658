import { toast } from 'react-toastify';
const DEFAULT_DEBOUNCE = 200;

export let isCoolDown;

export const showToaster = (message = '', ms = DEFAULT_DEBOUNCE) => {
  if (isCoolDown) return;
  isCoolDown = true;
  toast.error(message);
  setTimeout(() => (isCoolDown = false), ms);
};
