import {
  ADMINS,
  SUCCESS_ADMINS,
  CREATE_ADMIN,
  CREATE_ADMIN_SUCCESS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  CLEAR_IMAGE_SUCCESS,
} from './actionTypes';

export const getAdmins = (id, limit, offset, search, orderBy, orderType) => {
  return {
    type: ADMINS,
    payload: { id, limit, offset, search, orderBy, orderType },
  };
};

export const getAdminsSuccess = (data) => {
  return {
    type: SUCCESS_ADMINS,
    payload: data,
  };
};

export const createAdmin = (data) => {
  return {
    type: CREATE_ADMIN,
    payload: data,
  };
};

export const createAdminSuccess = () => {
  return {
    type: CREATE_ADMIN_SUCCESS,
  };
};

export const uploadImage = (body, id, history) => {
  return {
    type: UPLOAD_IMAGE,
    payload: { body, id, history },
  };
};

export const uploadImageSuccess = () => {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
  };
};

export const clearImageSuccess = () => {
  return {
    type: CLEAR_IMAGE_SUCCESS,
  };
};

// export const deleteOrganization = (id) => {
//   return {
//     type: DELETE_ORGANIZATION,
//     id
//   }
// };

// export const deleteOrganizationSuccess = () => {
//   return {
//     type: DELETE_ORGANIZATION_SUCCESS,
//   }
// };

// export const setStatus = (id) => {
//   return {
//     type: SET_STATUS,
//     id
//   }
// };

// export const setStatusSuccess = () => {
//   return {
//     type: SET_STATUS_SUCCESS
//   }
// };
