import React from 'react';
import { Col } from 'reactstrap';
import { getDataUser } from '../../../../utils/get-store-user';
import UserProfileImage from '../../../../pages/Authentication/Profile/UserImage';

const DashboardAvatar = () => {
  const { avatar, firstName, lastName, role } = getDataUser();

  return (
    <Col sm="12" lg="6" className="d-flex align-self-center">
      <UserProfileImage avatar={avatar} />
      <div className="text-muted ml-3 d-flex flex-column justify-content-center">
        <h5 id="Tooltip-name">
          {firstName} {lastName}
        </h5>
        <p className="mb-1">{role}</p>
      </div>
    </Col>
  );
};

export default DashboardAvatar;
