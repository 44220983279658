import React from 'react';
import ReactTooltip from 'react-tooltip';

import { OFFSET_ICON_TOOLTIP } from '../../../constants/tooltip';
import { isLongText } from '../../../utils/admin-name-helper';

const ShouldLongTextTooltip = ({ id, text, maxLength }) => {
  return (
    <>
      <p data-tip data-for={id}>
        {text}
      </p>
      {isLongText(text, maxLength) && (
        <ReactTooltip
          id={id}
          className="icon-tooltip"
          effect="solid"
          arrowColor="transparent"
          offset={OFFSET_ICON_TOOLTIP}
        >
          <span>{text}</span>
        </ReactTooltip>
      )}
    </>
  );
};

export default ShouldLongTextTooltip;
