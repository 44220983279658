import React, { useState } from 'react';
import { Row, Col, Modal, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import AsyncSelect from 'react-select/async';

import { DEBOUNCE_TIMER } from '../../constants/helper';
import { changeFieldSelect } from '../../utils/selector-helper-changed';
import Button from '../../components/Button';
import { debounce } from 'lodash';
import {
  configureGetOrganization,
  configureGetAdmins,
} from '../../utils/config-get-organization';
import { getOrganizations, getClientUsers } from '../../utils/backend-helper';

const customStyles = {
  option: (styles) => {
    return {
      ...styles,
      color: '#000000',
      fontFamily: 'Poppins',
      fontWeight: '500',
    };
  },
};

const UserAddExistModal = ({ onSubmit, onCancel, isOpen, organizationId }) => {
  const [organizationIds, setOrganizationIds] = useState([]);
  const [usersIds, setUsersIds] = useState([]);

  const debouncedOrganization = debounce((inputValue, callback) => {
    getOrganizations(inputValue).then(({ data }) =>
      callback(configureGetOrganization(data, organizationId)),
    );
  }, DEBOUNCE_TIMER);

  let debouncedUsers = debounce((inputValue, callback) => {
    getClientUsers(organizationId, organizationIds, inputValue).then(
      ({ data }) => callback(configureGetAdmins(data)),
    );
  }, DEBOUNCE_TIMER);

  const handleOrganizationChange = (organizations) => {
    setOrganizationIds(changeFieldSelect(organizations));
  };

  const handleUsersChange = (users) => {
    setUsersIds(changeFieldSelect(users));
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Add an Existing User</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
        <div className="modal-body">
          <p className="text-exist">
            To add existing User(s), search directly in the second field, or you
            can first select Organization(s) to pull the list of Users.
          </p>
          <FormGroup className="select2-container">
            <label className="control-label">Select Organization(s)</label>
            <AsyncSelect
              styles={customStyles}
              isMulti={true}
              cacheOptions={false}
              defaultOptions
              onChange={(value) => handleOrganizationChange(value)}
              loadOptions={debouncedOrganization}
              placeholder="Search by organization"
            />
          </FormGroup>
          <FormGroup className="select2-container">
            <label className="control-label">Select User(s)</label>
            <AsyncSelect
              key={organizationIds.length}
              styles={customStyles}
              defaultOptions
              cacheOptions={false}
              isMulti={true}
              onChange={(value) => handleUsersChange(value)}
              loadOptions={debouncedUsers}
              placeholder="Search by name or email"
            />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={!usersIds.length}
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light mr-3"
              >
                Add
              </Button>
            </Col>
          </Row>
        </div>
        <AvField
          name="organizationId"
          type="text"
          value={organizationId}
          className="btn-hidden"
        />
        <AvField
          name="usersIds"
          type="text"
          value={usersIds}
          className="btn-hidden"
        />
      </AvForm>
    </Modal>
  );
};

export default UserAddExistModal;
