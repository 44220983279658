import React from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import classnames from 'classnames';

import { getAlphabetSymbol } from '../../../../../utils/training-records-helper/get-alphabet-symbol';
import {
  getTemplateQuestion,
  TWO_ANSWER,
} from '../../../../../utils/training-records-helper/get-template-question';
import {
  getCurrentIndex,
  getLatestId,
  removeQuestion,
  removeAnswer,
  addAnswer,
  changeQuestionName,
  changeAnswerName,
  toggleAnswerCorrect,
} from '../../../../../utils/training-records-helper/questions-helpers';

const TrainingQuestions = ({ questions, updateModel }) => {
  const handleCreateQuestion = () => {
    const latestId = getLatestId(questions);

    updateModel((prev) => ({
      ...prev,
      questions: {
        ...prev.questions,
        value: [...prev.questions.value, getTemplateQuestion(latestId)],
      },
    }));
  };

  const handleDeleteQuestion = (currentId) => {
    updateModel((prev) => ({
      ...prev,
      questions: {
        ...prev.questions,
        value: removeQuestion(prev.questions.value, currentId),
      },
    }));
  };

  const handleDeleteAnswer = (questionId, answerId) => {
    const questionIndex = getCurrentIndex(questions, questionId);
    if (questionIndex === -1) return;

    updateModel((prev) => ({
      ...prev,
      questions: {
        ...prev.questions,
        value: removeAnswer(prev.questions.value, questionIndex, answerId),
      },
    }));
  };

  const handleAddAnswer = (currentId) => {
    updateModel((prev) => ({
      ...prev,
      questions: {
        ...prev.questions,
        value: addAnswer(prev.questions.value, currentId),
      },
    }));
  };

  const handleUpdateQuestionName = (event, currentId) => {
    const { value } = event.target;

    updateModel((prev) => ({
      ...prev,
      questions: {
        ...prev.questions,
        value: changeQuestionName(questions, currentId, value),
      },
    }));
  };

  const handleUpdateAnswerName = (event, questionId, answerId) => {
    const { value } = event.target;

    const questionIndex = getCurrentIndex(questions, questionId);
    if (questionIndex === -1) return;

    updateModel((prev) => ({
      ...prev,
      questions: {
        ...prev.questions,
        value: changeAnswerName(
          prev.questions.value,
          questionIndex,
          answerId,
          value,
        ),
      },
    }));
  };

  const handleUpdateAnswerCorrect = (questionId, answerId) => {
    const questionIndex = questions.findIndex(({ id }) => id === questionId);
    if (questionIndex === -1) return;

    updateModel((prev) => ({
      ...prev,
      questions: {
        ...prev.questions,
        value: toggleAnswerCorrect(questions, questionIndex, answerId),
      },
    }));
  };

  return (
    <Row className="mt-3">
      <Col xs="12">
        <div className="info-column">
          <p className="info-column__title">Questions:</p>
          {questions.map(({ id, name, answers }, i) => {
            return (
              <div key={id} className="question-item">
                <p>{`#${i + 1} Question`}</p>
                <FormGroup className="select2-container d-flex">
                  <Input
                    type="textarea"
                    id="textarea"
                    placeholder="Enter question here"
                    value={name}
                    onChange={(event) => handleUpdateQuestionName(event, id)}
                    rows="3"
                  />
                  <i
                    className="bx bxs-trash-alt trash-icon"
                    onClick={() => handleDeleteQuestion(id)}
                  ></i>
                </FormGroup>
                <Row>
                  {answers.map(({ id: idx, name, isCorrect }, index, arr) => {
                    return (
                      <Col key={`${id}-${idx}`} xs="6">
                        <div className="answer-item">
                          <p>{getAlphabetSymbol(index)}</p>
                          <FormGroup className="select2-container d-flex">
                            <label className="custom-input">
                              <Input
                                type="input"
                                placeholder="Type answer"
                                className="custom-input__item"
                                value={name}
                                onChange={(event) =>
                                  handleUpdateAnswerName(event, id, idx)
                                }
                              />
                              <div className="custom-control custom-checkbox custom-checkbox-answer">
                                <input
                                  type="checkbox"
                                  checked={isCorrect}
                                  onChange={() =>
                                    handleUpdateAnswerCorrect(id, idx)
                                  }
                                  className="custom-control-input"
                                  id={`custom-checkbox-${id}-${idx}-${index}`}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`custom-checkbox-${id}-${idx}-${index}`}
                                ></label>
                              </div>
                            </label>
                            <i
                              className={classnames(
                                'bx bxs-trash-alt trash-icon',
                                { 'icon-disabled': arr.length === TWO_ANSWER },
                              )}
                              onClick={
                                arr.length > TWO_ANSWER
                                  ? () => handleDeleteAnswer(id, idx)
                                  : () => {}
                              }
                            ></i>
                          </FormGroup>
                        </div>
                      </Col>
                    );
                  })}
                  <Col xs="6">
                    <div className="answer-item">
                      <p>{getAlphabetSymbol(answers.length)}</p>
                      <div
                        className="answer-item__add"
                        onClick={() => handleAddAnswer(id)}
                      >
                        Add new answer
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      </Col>
      <Col xs="12">
        <button
          type="button"
          className="add-btn"
          onClick={handleCreateQuestion}
        >
          <i className="bx bx-plus"></i>
          Add new question
        </button>
      </Col>
    </Row>
  );
};

export default TrainingQuestions;
