import { getIdsCheckboxChecked } from './check-checkbox';
import { getTableNamesColumn } from './get-names-table-columns';
import { newFilterOrderParams } from './order-params';

export const getExportParams = (
  checkboxList,
  dataOfColumns,
  filters,
  organizationId,
  groupId,
  docTypeIds,
) => {
  const selectedIds = getIdsCheckboxChecked(checkboxList);
  const columns = getTableNamesColumn(dataOfColumns);
  const orderParams = newFilterOrderParams(filters);

  const otherParmas = {};

  if (organizationId) {
    otherParmas.organizationId = organizationId;
  }
  if (groupId) {
    otherParmas.groupId = groupId;
  }
  if (docTypeIds) {
    otherParmas.docTypeIds = docTypeIds;
  }

  return { selectedIds, columns, ...orderParams, ...otherParmas };
};
