export const EditHumanResourcesModel = {
  files: {
    required: false,
    value: [],
  },
  tagIds: {
    required: false,
    value: [],
  },
  tags: {
    required: false,
    value: [],
  },
};
