export const ORGANIZATIONS = 'ORGANIZATIONS';
export const SUCCESS_ORGANIZATION = 'SUCCESS_ORGANIZATION';

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const SUCCESS_CREATE_ORGANIZATION = 'SUCCESS_CREATE_ORGANIZATION';
export const CLEAR_CREATE_SUCCESS = 'CLEAR_CREATE_SUCCESS';

export const TOOGLE_MANAGE_BY_CLIENT = 'TOOGLE_MANAGE_BY_CLIENT';
export const TOOGLE_MANAGE_SUCCESS = 'TOOGLE_MANAGE_SUCCESS';

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const CLEAR_DELETE_SUCCESS = 'CLEAR_DELETE_SUCCESS';

export const SET_STATUS = 'SET_STATUS';
export const SET_STATUS_SUCCESS = 'SET_STATUS_SUCCESS';
export const CLEAR_SET_STATUS = 'CLEAR_SET_STATUS';
