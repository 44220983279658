import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import TableCellText from '../TableCellText';

const DocumentLinkColumn = ({
  order,
  name,
  columnsHidden,
  title,
  link,
  classes,
}) => {
  const history = useHistory();
  const { id } = order;

  const handleRedirect = (link) => history.push(link);

  return (
    <td
      key={name}
      onClick={() => handleRedirect(link)}
      className={classnames(
        'cursor-pointer',
        { 'column-hidden': !columnsHidden[name] },
        classes,
      )}
    >
      <p className="td-before">{title}</p>
      <TableCellText id={`${name}${id}`} text={order[name]} />
    </td>
  );
};

export default DocumentLinkColumn;
