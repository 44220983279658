const DOCUMENTS = 'documents';

const DOCUMENT_LINK_INDEX = 0;
const OTHER_LINK_INDEX = 1;

const isDocumentLink = (link) => link.indexOf(DOCUMENTS) !== -1;

export const getSeparateLinks = (references) => {
  return references.reduce(
    (acc, next) => {
      const { link } = next;

      const currentIndex = isDocumentLink(link)
        ? DOCUMENT_LINK_INDEX
        : OTHER_LINK_INDEX;

      acc[currentIndex].push(next);
      return acc;
    },
    [[], []],
  );
};
