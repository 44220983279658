import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  ALL_ACTIVE_ORGANIZATIONS,
  ALL_INACTIVE_ORGANIZATIONS,
  ALL_ORGANIZATIONS,
} from '../../../../constants/table';

const ExportLogsStatus = ({ handleChangeStatus }) => {
  const [isOpen, updateIsOpen] = useState(false);

  const dropdownItems = useMemo(() => {
    return [
      {
        text: 'All organizations',
        filteredValue: ALL_ORGANIZATIONS,
      },
      {
        text: 'All active organizations',
        filteredValue: ALL_ACTIVE_ORGANIZATIONS,
      },
      {
        text: 'All inactive organizations',
        filteredValue: ALL_INACTIVE_ORGANIZATIONS,
      },
    ];
  }, []);

  return (
    <Dropdown
      className="mr-3 d-flex align-items-center"
      isOpen={isOpen}
      toggle={() => updateIsOpen((isOpen) => !isOpen)}
    >
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={isOpen}
        className="document-dropdown"
      >
        <div
          className={classnames(
            'd-flex align-items-center filter-container d-cursor',
            { 'active-dropdown': isOpen },
          )}
        >
          <i className="font-size-20 bx bx-filter mr-1"></i>
          <span>Status</span>
        </div>
      </DropdownToggle>
      <DropdownMenu className="pt-2 pb-2">
        {dropdownItems.map(({ text, filteredValue }, index) => (
          <DropdownItem
            key={index}
            className="dropdown-file-item"
            onClick={() => handleChangeStatus(filteredValue)}
          >
            {text}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ExportLogsStatus;
