import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';

import { changeFieldSelect } from '../../utils/selector-helper-changed';
import Button from '../../components/Button';

const AdminDeleteModal = ({
  onSubmit,
  onCancel,
  isOpen,
  values,
  selectOptions,
  user,
}) => {
  const [organizationIds, setOrganizationIds] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setOrganizationIds([values.routeId]);
    }
  }, [isOpen, values.routeId]);

  const handleOrganizationChange = (organizations) => {
    setOrganizationIds(changeFieldSelect(organizations));
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Delete {user}</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
        {selectOptions.length > 1 ? (
          <div className="modal-body">
            <p className="text-exist">
              The {user}{' '}
              <b>
                {' '}
                {values.firstName} {values.lastName} ({values.email}){' '}
              </b>{' '}
              can be deleted from several Organizations. Please, select
              Organization(s) which they should be deleted from.
            </p>
            <FormGroup className="select2-container">
              <label className="control-label">Select Organization(s)</label>
              <Select
                isMulti
                defaultValue={selectOptions[0]}
                options={selectOptions}
                onChange={(value) => handleOrganizationChange(value)}
                placeholder="Search by organization"
              />
            </FormGroup>
          </div>
        ) : (
          <div className="modal-body">
            <p className="text-exist">
              Are you sure you want to delete the {user}{' '}
              <b>
                {' '}
                {values.firstName} {values.lastName} ({values.email}){' '}
              </b>{' '}
              from the current Organization <b> {values.organizationName}</b>?
            </p>
          </div>
        )}
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                disabled={!organizationIds.length}
                type="submit"
                color="btn delete-btn w-md waves-effect waves-light mr-3"
              >
                Delete
              </Button>
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
        <AvField
          name="ids"
          type="text"
          value={organizationIds}
          className="btn-hidden"
        />
        <AvField
          name="id"
          type="text"
          value={values.id}
          className="btn-hidden"
        />
      </AvForm>
    </Modal>
  );
};

export default AdminDeleteModal;
