import React from 'react';
import PaginationSelect from '../../shared/PaginationSelect';

const TableShowEntries = ({ limit, handleChangeLimit }) => {
  return (
    <div className="form-group mb-2 d-flex">
      <label className="col-form-label d-flex align-items-center mr-3">
        Show entries
      </label>
      <div className="d-flex align-items-center">
        <div className="select-wrapper">
          <PaginationSelect
            limit={limit}
            handleChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  );
};

export default TableShowEntries;
