import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';

import SingleSelect from '../../../selects/Single';
import CustomSwitch from '../../CustomSwitch';

import { useRouteMatch } from 'react-router-dom';
import { SECOND_SWITCH } from '../../../../constants/helper';
import { TRAINING_RECORDS_URL } from '../../../../constants/url';
import { configureTrainingsOptions } from '../../../../utils/config-get-organization';
import { getAllTrainingRecords } from '../../../../utils/api/training-records-api';

const LinkTrainingRecords = ({
  selectValues,
  handleUpdate,
  handleUpdateLink,
}) => {
  const { id: organizationId } = useRouteMatch().params;

  const getDataFromAPI = useCallback(
    async (_, search = '') => {
      const params = { organizationId, search, includeUsed: true };
      return getAllTrainingRecords(params).then(({ data }) => data);
    },
    [organizationId],
  );

  return (
    <Row className="mt-2 mb-2">
      <Col md="4" xl="2" className="d-flex align-items-center">
        <div className="link-swicher">
          <CustomSwitch
            name="task-switch"
            isChecked={selectValues[SECOND_SWITCH]}
            onChange={() => handleUpdate(SECOND_SWITCH)}
          />
          <p className="mb-0">Training Record</p>
        </div>
      </Col>
      <Col md="8" xl="10" className="mt-2 mt-md-0">
        <SingleSelect
          handleRequest={getDataFromAPI}
          placeholder="Choose training record"
          configOptions={configureTrainingsOptions}
          url={TRAINING_RECORDS_URL}
          isDisabled={!selectValues[SECOND_SWITCH]}
          handleChange={(values) =>
            handleUpdateLink(values, 'training-records', SECOND_SWITCH)
          }
        />
      </Col>
    </Row>
  );
};

export default LinkTrainingRecords;
