import React, { useState } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import Button from '../../components/Button';
import { MAX_INPUT_LENGTH } from '../../constants/helper';
import { validateEmptyText } from '../../constants/validatePassword';
import { REQUIRED_FIELD } from '../../constants/form';

const UserGroupAddNewModal = ({
  onSubmit,
  onCancel,
  isOpen,
  organizationId,
}) => {
  const [name, setName] = useState('');

  const handleChangeName = (name) => setName(name);
  const clear = () => setName('');

  const handleCancel = () => {
    clear();
    onCancel();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Create User Group</h5>
        <button
          type="button"
          onClick={handleCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm
        className="form-horizontal mt-2"
        onValidSubmit={(_, values) => onSubmit(_, values, clear)}
      >
        <div className="modal-body">
          <div className="form-group">
            <AvField
              type="text"
              name="name"
              label="Group name"
              className="form-control"
              placeholder="Enter group name"
              maxLength={MAX_INPUT_LENGTH}
              value={name}
              onChange={(e) => handleChangeName(e.target.value)}
              validate={validateEmptyText}
              errorMessage={REQUIRED_FIELD}
            />
          </div>
          <AvField
            name="organizationId"
            type="text"
            className="btn-hidden"
            value={organizationId}
          />
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={handleCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={!name.trim()}
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light mr-3"
              >
                Create Group
              </Button>
            </Col>
          </Row>
        </div>
      </AvForm>
    </Modal>
  );
};

export default UserGroupAddNewModal;
