export const listDashboard = {
  text: 'Dashboard',
  link: 'dashboard',
  icon: 'bx bx-id-card',
};

export const listAdmin = {
  text: 'Admins',
  link: 'admins',
  icon: 'bx bx-id-card',
};

export const listsUser = [
  {
    text: 'User Groups',
    link: 'user-groups',
    icon: 'bx bxs-collection',
  },
  {
    text: 'Users',
    link: 'users',
    icon: 'bx bxs-contact',
  },
];

export const listSetting = {
  text: 'Settings',
  link: 'setting',
  icon: 'bx bxs-cog',
};

export const listGeneral = {
  text: 'General',
  link: 'general',
  icon: 'bx bx-spreadsheet',
  subMenu: [
    {
      text: 'Documents',
      link: 'documents',
    },
    {
      text: 'Actions',
      link: 'tasks',
    },
    {
      text: 'Training records',
      link: 'training-records',
    },
    {
      text: 'Human Resources',
      link: 'human-resources',
    },
  ],
};

export const documentUserNav = {
  text: 'Documents',
  link: 'documents',
  icon: 'bx bxs-file-doc',
};

export const actionUserNav = {
  text: 'Actions',
  link: 'tasks',
  icon: 'bx bxs-spreadsheet',
};

export const trainingUserNav = {
  text: 'Training records',
  link: 'training-records',
  icon: 'bx bxs-receipt',
};
