import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, UncontrolledTooltip, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';

import Button from '../../components/Button';
import { customStyles } from '../../utils/select-styles';
import { configureTrainingsOptions } from '../../utils/config-get-organization';
import { changeTrainingDeleteModel } from '../../utils/selector-helper-changed';

const UserListDeleteModal = ({ onSubmit, onCancel, isOpen, values }) => {
  const [checked, setChecked] = useState(false);

  const handleChangeChecked = () => {
    setChecked((checked) => !checked);
  };

  const clearCheckBox = () => setChecked(false);

  const [trainingIds, updateTrainingIds] = useState([]);

  const handleSubmitModal = (event, values) => {
    onSubmit(event, values, clearCheckBox);
  };

  const [trainingOptions, updateTrainingOptions] = useState([]);

  useEffect(() => {
    updateTrainingOptions(configureTrainingsOptions(values.trainings));
  }, [values.trainings]);

  const handleTrainingsChange = (values) => {
    updateTrainingIds(changeTrainingDeleteModel(values));
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0 delete-user-title">Delete User</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm
        className="form-horizontal mt-2"
        onValidSubmit={handleSubmitModal}
      >
        <div className="modal-body">
          <p className="text-modal">
            Are you sure you want to delete the User{' '}
            <b>
              {values.firstName} {values.lastName} ({values.email})
            </b>{' '}
            from the current User Group?
          </p>
          <p className="text-modal">
            This User is currently included into <b>{values.groupCount}</b> User
            Group(s).
          </p>
          <p className="text-modal">
            If the User remains without a User Group, they won’t have any access
            permissions and will be unable to perform any actions in the current
            Organization.
          </p>

          {!!(values.trainings && values.trainings.length) && (
            <FormGroup className="select2-container">
              <label className="control-label">
                Training(s)
                {!!(values.trainings && values.trainings.length) && (
                  <>
                    <i
                      className="bx bx-info-circle info font-size-16 info-icon-user"
                      id="tooltip-delete-training"
                    ></i>
                    <UncontrolledTooltip
                      placement="right"
                      id="tooltip-document"
                      target="tooltip-delete-training"
                    >
                      <span>
                        The current User has Training(s) assigned via the
                        current User Group. If you click to un-assign particular
                        Training(s) from the User, the User will be deleted from
                        current User Group and Training(s) will be unassigned
                        from the User. You can also skip unassigning, and the
                        User will be just deleted from the User Group, but will
                        remain an assignee from the Training(s). Any completed
                        trainings will not be deleted.
                      </span>
                    </UncontrolledTooltip>
                  </>
                )}
              </label>
              <Select
                isMulti
                styles={customStyles}
                options={trainingOptions}
                onChange={(value) => handleTrainingsChange(value)}
                placeholder="Select training(s) to un-assisgn"
              />
            </FormGroup>
          )}

          <div className="custom-control custom-checkbox">
            <input
              checked={checked}
              type="checkbox"
              onChange={handleChangeChecked}
              className="custom-control-input"
              id="customControlInline"
            />
            <label
              className="custom-control-label"
              htmlFor="customControlInline"
            >
              I acknowledge
            </label>
          </div>
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="submit"
                color="btn btn-danger w-md waves-effect waves-light delete-btn mr-3"
                disabled={!checked}
              >
                Delete
              </Button>
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
        <AvField
          name="id"
          type="text"
          value={values.id}
          className="btn-hidden"
        />
        <AvField
          name="userId"
          type="text"
          value={values.userId}
          className="btn-hidden"
        />
        <AvField
          name="organizationId"
          type="text"
          value={values.organizationId}
          className="btn-hidden"
        />
        <AvField
          name="trainingIds"
          type="text"
          value={trainingIds}
          className="btn-hidden"
        />
      </AvForm>
    </Modal>
  );
};

export default UserListDeleteModal;
