import { useState, useEffect } from 'react';

import { INITIAL_CHECKED } from '../constants/table';
import { getNumbersOfCheck } from '../utils/check-checkbox';

export const useCheckboxCount = (list) => {
  const [countChecked, updateCountChecked] = useState(INITIAL_CHECKED);

  useEffect(() => {
    updateCountChecked(getNumbersOfCheck(list));
  }, [list]);

  return { countChecked };
};
