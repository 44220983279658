import React, { useState } from 'react';
import { Row, Col, Modal, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import AsyncSelect from 'react-select/async';

import { DEBOUNCE_TIMER } from '../../constants/helper';
import { changeFieldUserSelect } from '../../utils/selector-helper-changed';
import Button from '../../components/Button';
import { debounce } from 'lodash';
import { configureGetAdmins } from '../../utils/config-get-organization';
import { getUsersGroupAll } from '../../utils/backend-helper';

const customStyles = {
  option: (styles) => {
    return {
      ...styles,
      color: '#000000',
      fontFamily: 'Poppins',
      fontWeight: '500',
    };
  },
};

const UserGroupAddNew = ({
  onSubmit,
  onCancel,
  isOpen,
  organizationId,
  groupId,
}) => {
  const [userIds, setUserIds] = useState([]);

  const debouncedOrganization = debounce((inputValue, callback) => {
    getUsersGroupAll(groupId, organizationId, inputValue).then(({ data }) =>
      callback(configureGetAdmins(data)),
    );
  }, DEBOUNCE_TIMER);

  const handleUsersChange = (user) => {
    setUserIds(changeFieldUserSelect(user));
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Add User</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
        <div className="modal-body">
          <FormGroup className="select2-container">
            <label className="control-label">Select User(s)</label>
            <AsyncSelect
              styles={customStyles}
              isMulti={true}
              cacheOptions={false}
              defaultOptions
              onChange={(value) => handleUsersChange(value)}
              loadOptions={debouncedOrganization}
              placeholder="Select or search user(s)"
            />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={!userIds.length}
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light mr-3"
              >
                Add
              </Button>
            </Col>
          </Row>
        </div>
        <AvField
          name="groupId"
          type="text"
          value={groupId}
          className="btn-hidden"
        />
        <AvField
          name="organizationId"
          type="text"
          value={organizationId}
          className="btn-hidden"
        />
        <AvField
          name="users"
          type="text"
          value={userIds}
          className="btn-hidden"
        />
      </AvForm>
    </Modal>
  );
};

export default UserGroupAddNew;
