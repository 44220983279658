import React, { useState, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation';
import { Col, Input, Row } from 'reactstrap';
import Select from 'react-select';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

import {
  MAX_INPUT_LENGTH,
  MAX_TEXTAREA_LENGTH,
  DEBOUNCE_TIMER,
} from '../../../../constants/helper';
import Button from '../../../../components/Button';
import { customStyles } from '../../../../utils/select-styles';
import {
  getDocumentUsers,
  editTaskDetails,
} from '../../../../utils/backend-helper';
import { configureGetUsers } from '../../../../utils/config-get-organization';
import MessageResetSuccess from '../../../../components/ToastSuccess';
import { SUCCESS_TASK_EDIT } from '../../../../constants/messages';
import { getTaskModel } from '../../../../utils/get-task-model';
import TaskLinkToRecord from '../../../../components/task/LinkToRecord';
import TaskExternalLink from '../../../../components/task/ExternalLink';
import { getLinkToRecord } from '../../../../utils/tasks-helper/get-task-link';
import TaskEditLink from '../../../../components/task/EditLink';
import TagsListEdit from '../../../../components/shared/TaskListEdit';
import { getUnionArrayIds } from '../../../../utils/shared/get-union-array';
import { getFilteredData } from '../../../../utils/shared/get-filtered-data';
import { useErrorHandler } from '../../../../custom-hooks/useErrorHandler';
import FormGroupWrapper from './FormGroupWrapper';

const TaskDetailsEdit = ({
  taskModel,
  updateEditMode,
  user,
  updateTaskDetails,
}) => {
  const organizationId = useRouteMatch().params.id;
  const taskId = useRouteMatch().params.taskId;
  const onError = useErrorHandler();

  const handleSubmit = (_, values) => {
    const { taskId, ...body } = values;
    editTaskDetails(taskId, body)
      .then(({ data }) => {
        updateTaskDetails(data);
        toast.success(<MessageResetSuccess message={SUCCESS_TASK_EDIT} />);
        updateEditMode(false);
      })
      .catch(onError);
  };

  const [model, updateModel] = useState({
    number: {
      required: false,
      value: '',
    },
    dueDate: {
      required: true,
      value: '',
    },
    description: {
      required: true,
      value: '',
    },
    name: {
      required: true,
      value: '',
    },
    assigner: {
      required: true,
      value: '',
    },
    assignee: {
      required: true,
      value: '',
    },
    link: {
      required: false,
      value: '',
    },
    linkDescription: {
      required: false,
      value: '',
    },
    status: {
      required: true,
      value: '',
    },
    extraLinkDescription: {
      required: false,
      value: '',
    },
    extraLink: {
      required: false,
      value: '',
    },
    tagIds: {
      required: false,
      value: [],
    },
    tags: {
      required: false,
      value: [],
    },
  });

  useEffect(() => {
    updateModel((prev) => getTaskModel(prev, taskModel));
  }, [taskModel]);

  const handleModelChange = (event) => {
    const { name, value } = event.target;
    updateModel((prev) => ({
      ...prev,
      [name]: { ...prev[name], value },
    }));
  };

  const isDisabled = useMemo(() => {
    return !Object.values(model).every(({ required, value }) => {
      return required ? !!value || value === 0 : true;
    });
  }, [model]);

  const tasksOptions = [
    { label: 'To do', value: '0' },
    { label: 'Complete', value: '1' },
  ];

  const handleStatusChange = ({ value }) => {
    updateModel((prev) => ({ ...prev, status: { ...prev.status, value } }));
  };

  const [defaultAssignee, updateDefaultAssignee] = useState({
    label: '',
    value: '',
  });

  useEffect(() => {
    const { firstName, lastName, email, id } = taskModel.assignee;

    updateDefaultAssignee({
      label: `${firstName || ''} ${lastName || ''} (${email || ''})`,
      value: `${id}`,
    });
  }, [taskModel.assignee]);

  const [asigneeOptions, updateAsigneeOptions] = useState([]);

  useEffect(() => {
    getDocumentUsers(organizationId, '').then(({ data }) =>
      updateAsigneeOptions(configureGetUsers(data)),
    );
  }, [organizationId]);

  const handleAssigneeChange = ({ label, id: assigneeId }) => {
    updateDefaultAssignee({ label });
    updateModel((prev) => ({
      ...prev,
      assignee: { ...prev.assignee, value: assigneeId },
    }));
  };

  const debounceHandleSearch = useMemo(() => {
    return debounce((input) => {
      getDocumentUsers(organizationId, input).then(({ data }) =>
        updateAsigneeOptions(configureGetUsers(data)),
      );
    }, DEBOUNCE_TIMER);
  }, [organizationId]);

  const handleAssigneeSearch = (value) => {
    debounceHandleSearch(value);
  };

  const [defaultAssigner, updateDefaultAssigner] = useState({
    label: '',
    value: '',
  });

  useEffect(() => {
    const { firstName, lastName, email, id } = taskModel.assigner;

    updateDefaultAssigner({
      label: `${firstName || ''} ${lastName || ''} (${email || ''})`,
      value: `${id}`,
    });
  }, [taskModel.assigner]);

  const [assignerOptions, updateAssignerOptions] = useState([]);

  useEffect(() => {
    getDocumentUsers(organizationId, '').then(({ data }) =>
      updateAssignerOptions(configureGetUsers(data)),
    );
  }, [organizationId]);

  const handleAssigneerChange = ({ label, id: assignerId }) => {
    updateDefaultAssigner({ label });
    updateModel((prev) => ({
      ...prev,
      assigner: { ...prev.assigner, value: assignerId },
    }));
  };

  const debounceHandleAssignerSearch = useMemo(() => {
    return debounce((input) => {
      getDocumentUsers(organizationId, input).then(({ data }) =>
        updateAssignerOptions(configureGetUsers(data)),
      );
    }, DEBOUNCE_TIMER);
  }, [organizationId]);

  const handleAssignerSearch = (value) => {
    debounceHandleAssignerSearch(value);
  };

  const [isInitialLink, updateIsInitialLink] = useState(true);

  const handleModelChangeKey = (modelKey, value) => {
    updateModel((prev) => ({
      ...prev,
      [modelKey]: { ...prev[modelKey], value },
    }));
  };

  return (
    <>
      <div className="general-header">
        <h6 className="mt-2">Edit details</h6>
      </div>
      <hr />
      <AvForm className="form-horizontal mt-2" onValidSubmit={handleSubmit}>
        <div className="general-info">
          <Row>
            <Col xs="12" md="10">
              <FormGroupWrapper title="Action name*">
                <AvField
                  name="name"
                  className="form-control"
                  placeholder="Enter action name"
                  maxLength={MAX_INPUT_LENGTH}
                  onChange={(event) => handleModelChange(event)}
                  value={model.name.value}
                  type="text"
                />
              </FormGroupWrapper>
            </Col>
            <Col xs="12" md="2">
              <FormGroupWrapper title="System #">
                <Input
                  name="number"
                  className="form-control"
                  type="text"
                  value={model.number.value}
                  onChange={(event) => handleModelChange(event)}
                  disabled
                />
              </FormGroupWrapper>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroupWrapper title="Action status*">
                <Select
                  styles={customStyles}
                  defaultValue={tasksOptions[0]}
                  options={tasksOptions}
                  onChange={(value) => handleStatusChange(value)}
                  placeholder="Choose document type"
                />
              </FormGroupWrapper>
            </Col>
            <Col md="6">
              <FormGroupWrapper title="Due Date*">
                <input
                  className="form-control"
                  type="date"
                  name="dueDate"
                  value={model.dueDate.value}
                  placeholder="Choose due date"
                  onChange={(event) => handleModelChange(event)}
                />
              </FormGroupWrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroupWrapper title="Action description*">
                <AvInput
                  type="textarea"
                  id="textarea"
                  name="description"
                  placeholder="Enter description here"
                  maxLength={`${MAX_TEXTAREA_LENGTH}`}
                  onChange={(event) => handleModelChange(event)}
                  value={model.description.value}
                  rows="3"
                />
              </FormGroupWrapper>
            </Col>
          </Row>
          <TagsListEdit
            tags={model.tags.value}
            onChange={(values) => handleModelChangeKey('tagIds', values)}
            handleRemoveExist={(deletedId) =>
              handleModelChangeKey(
                'tags',
                getFilteredData(model.tags.value, 'id', deletedId),
              )
            }
          />
          <hr />
          {isInitialLink && taskModel.link ? (
            <TaskEditLink
              link={model.link.value}
              linkDescription={model.linkDescription.value}
              handleDelete={() => {
                updateModel((prev) => getLinkToRecord(prev, '', ''));
                updateIsInitialLink(false);
              }}
            />
          ) : (
            <TaskLinkToRecord
              updateLinkToRecord={(value, link) => {
                updateModel((prev) => getLinkToRecord(prev, value, link));
              }}
            />
          )}
          <TaskExternalLink
            extraLinkDescription={model.extraLinkDescription.value}
            extraLink={model.extraLink.value}
            handleModelChange={handleModelChange}
          />
          <Row className="mt-3">
            <Col sm="12" md="6">
              <FormGroupWrapper title="Action Assignee*">
                <Select
                  isMulti={false}
                  styles={customStyles}
                  options={asigneeOptions}
                  value={defaultAssignee}
                  onChange={(value) => handleAssigneeChange(value)}
                  onInputChange={(value) => handleAssigneeSearch(value)}
                  placeholder="Choose assignee"
                />
              </FormGroupWrapper>
            </Col>
            <Col xs="12" md="6">
              <FormGroupWrapper title="Action Assigner*">
                <Select
                  isMulti={false}
                  styles={customStyles}
                  options={assignerOptions}
                  value={defaultAssigner}
                  onChange={(value) => handleAssigneerChange(value)}
                  onInputChange={(value) => handleAssignerSearch(value)}
                  placeholder="Choose assigner"
                />
              </FormGroupWrapper>
            </Col>
          </Row>
          <Row className="form-group mt-2">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
                handler={() => updateEditMode(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
                disabled={isDisabled}
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </div>
        <AvField
          name="taskId"
          type="text"
          value={taskId}
          className="btn-hidden"
        />
        <AvField
          name="organizationId"
          type="text"
          value={organizationId}
          className="btn-hidden"
        />
        <AvField
          name="assignerId"
          type="text"
          value={model.assigner.value}
          className="btn-hidden"
        />
        <AvField
          name="assigneeId"
          type="text"
          value={model.assignee.value}
          className="btn-hidden"
        />
        <AvField
          name="status"
          type="text"
          value={model.status.value}
          className="btn-hidden"
        />
        <AvField
          name="dueDate"
          type="text"
          value={model.dueDate.value}
          className="btn-hidden"
        />
        <AvField name="link" type="hidden" value={model.link.value} />
        <AvField
          name="linkDescription"
          type="hidden"
          value={model.linkDescription.value}
        />
        <AvField
          name="tagIds"
          type="hidden"
          value={getUnionArrayIds([...model.tags.value, ...model.tagIds.value])}
        />
      </AvForm>
    </>
  );
};

export default TaskDetailsEdit;
