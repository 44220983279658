const isExistSomeItem = (list) => list?.length;
const isExistValues = (...values) => values.every((value) => value);

export const isValidExportLogs = (
  selectedIds,
  infoTypes,
  dateStart,
  dateEnd,
  selectedChanges,
) => {
  return (
    (isExistSomeItem(selectedIds) &&
      isExistSomeItem(infoTypes) &&
      isExistValues(dateStart, dateEnd)) ||
    (selectedChanges && isExistValues(dateStart, dateEnd))
  );
};
