import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import TrainingDetailsEdit from './DetailsEdit';
import TrainingDetailsView from './DetailsView';
import TrainingPass from './PassView';

import { trainingRecordDetailsModel } from '../../../../models/training-records/training-records-detail';

const TrainingRecordDetails = ({
  user,
  recordDetails,
  updateRecordDetails,
}) => {
  const history = useHistory();
  const { state } = history.location;

  const [editMode, updateEditMode] = useState(false);
  const [passMode, updatePassMode] = useState(false);
  const [trainingModel, updateTrainingModel] = useState(
    trainingRecordDetailsModel,
  );

  useEffect(() => {
    if (state && state.openEditMode) {
      updateEditMode(true);
    }
  }, [state]);

  useEffect(() => {
    if (recordDetails) {
      updateTrainingModel(recordDetails);
    }
  }, [recordDetails]);

  if (editMode) {
    return (
      <TrainingDetailsEdit
        user={user}
        trainingModel={trainingModel}
        updateTrainingModel={updateTrainingModel}
        updateRecordDetails={updateRecordDetails}
        updateEditMode={updateEditMode}
      />
    );
  }

  if (passMode) {
    return (
      <TrainingPass
        user={user}
        trainingModel={trainingModel}
        updatePassMode={updatePassMode}
        updateTrainingModel={updateTrainingModel}
      />
    );
  }

  return (
    <TrainingDetailsView
      user={user}
      trainingModel={trainingModel}
      updateEditMode={updateEditMode}
      updatePassMode={updatePassMode}
    />
  );
};

export default TrainingRecordDetails;
