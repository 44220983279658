import React from 'react';
import ReferencesForm from '../Form';

const ReferencesEdit = ({
  updateEditMode,
  references,
  currentRevision,
  revision,
  updateCurrentRevision,
}) => {
  return (
    <div className="general">
      <div className="general-header">
        <h6 className="general-header__title">References</h6>
      </div>
      <hr />
      <ReferencesForm
        oldReferences={references}
        updateEditMode={updateEditMode}
        currentRevision={currentRevision}
        revision={revision}
        updateCurrentRevision={updateCurrentRevision}
      />
    </div>
  );
};

export default ReferencesEdit;
