import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  CHANGE_EMAIL_PROFILE,
  CHANGE_PASSWORD_PROFILE,
} from './actionTypes';

export const profileSuccess = (msg) => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = (error) => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const changePasswordProfile = (values) => {
  return {
    type: CHANGE_PASSWORD_PROFILE,
    payload: values,
  };
};

export const changeEmailProfile = (user) => {
  return {
    type: CHANGE_EMAIL_PROFILE,
    payload: { user },
  };
};
