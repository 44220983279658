import React from 'react';

import userGroupImage from '../../../../../assets/images/help-screen/admin/im_User Groups1@3x.png';
import userGroupImage2 from '../../../../../assets/images/help-screen/admin/im_User Groups2@3x.png';
import userGroupImage3 from '../../../../../assets/images/help-screen/admin/im_User Groups2 Copy@3x.png';
import userGroupImage4 from '../../../../../assets/images/help-screen/admin/im_User Groups2 Copy 2@3x.png';
import userGroupImage5 from '../../../../../assets/images/help-screen/admin/im_User Groups2 Copy 4@3x.png';
import userGroupImage6 from '../../../../../assets/images/help-screen/admin/im_User Groups2 Copy 5@3x.png';
import HelpImage from '../../../shared/HelpImage';

const UserGroupNotesContent = () => {
  return (
    <>
      <p className="sub-title">
        User Groups are used for two primary purposes:
      </p>
      <p>a) Managing access permissions for a group of users.</p>
      <HelpImage src={userGroupImage} />
      <p>b) Assigning training records to a group of users.</p>
      <HelpImage src={userGroupImage2} />
      <p>
        User groups should be assigned such that everyone within the group will
        have the same access permissions and similar training requirements.
        Trainings can be assigned to users individually. However, when adding
        new users to a User Group, the trainings assigned to that group can be
        assigned to the new user
      </p>
      <HelpImage src={userGroupImage3} />
      <HelpImage src={userGroupImage4} />
      <p>
        This can be a useful tool as an organization grows.
      </p>
      <p>
        <b>Tip:</b> When creating multiple User Groups with similar access permissions, it can be helpful to create one and then use the Duplicate feature in the Options column to create a copy of that group with a new name.
      </p>
      <HelpImage src={userGroupImage5} />
      <HelpImage src={userGroupImage6} />
    </>
  );
};

export default UserGroupNotesContent;
