import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';
import { Col, FormGroup, Input, Row, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import moment from 'moment';
import { debounce } from 'lodash';

import {
  getFileByUrl,
  editDocumentGeneral,
  getDocumentUsers,
} from '../../utils/backend-helper';
import {
  DEBOUNCE_TIMER,
  DESCRIPTION_TYPE_KEY,
  DOCUMENT_STATUS,
  FINAL_KEY,
  MAX_INPUT_LENGTH,
  MAX_TEXTAREA_LENGTH,
  REVISION_NAME_KEY,
  REVISION_PARAM,
} from '../../constants/helper';
import { formatBytes } from '../../utils/format-bytes-helper';
import { saveFileAs } from '../../utils/fileSaver';
import { getDocumentTypes } from '../../utils/backend-helper';
import {
  configTags,
  configureGetDocumentTypes,
  configureGetUsers,
} from '../../utils/config-get-organization';
import MessageResetSuccess from '../ToastSuccess';
import {
  SUCCESS_DOCUMENT_EDIT,
  SUCCESS_REVISION_CREATE,
} from '../../constants/messages';
import IconButton from '../shared/IconButton';
import TextCheckbox from '../shared/TextCheckbox';
import CustomSelect from '../shared/CustomSelect';
import DocumentAddRevision from './AddRevision';
import {
  changeFlag,
  createDocumentRevision,
  downloadGeneralDocument,
  getDocumentGeneral,
  removeDocumentRevision,
} from '../../utils/api/document-api';
import ModalOuter from '../../modals/ModalOuter';
import RevisionDeleteBody from './DeleteBody';
import { configRevisionRequest } from '../../utils/documents/config-body-revision';
import { useQueryHelper } from '../../utils/query-params-helper';
import { customStyles } from '../../utils/select-styles';
import { useErrorHandler } from '../../custom-hooks/useErrorHandler';
import DocumentAttachedFiles from '../document/AttachedFiles';
import { filterByField } from '../../utils/training-records-helper/filter-by-id';
import DocumentDownloadFiles from '../document/DownloadFiles';
import { getFilesIds } from '../../utils/training-records-helper/get-files-ids';
import { validateEmptyText } from '../../constants/validatePassword';
import { REQUIRED_FIELD } from '../../constants/form';
import Index from '../CustomMultiSelect';
import { getTags } from '../../utils/api/organization-api';
import { getUnionArrayIds } from '../../utils/shared/get-union-array';
import TagsList from '../shared/TagsList';
import DocumentAttached from '../document/DocumentAttached';
import {
  changeFileDocument,
  changeExtraFiles,
} from '../../utils/file-attached/change-file-document';
import UserEmailBlock from '../shared/UserEmailBlock';
import Button from '../Button';

const DocumentGeneral = ({
  currentRevision,
  updateCurrentRevision,
  canBeEditedDocument,
  revisionAddMode,
  updateRevisionAddMode,
  revisionModel,
  updateRevisionModel,
  rModel,
  rHandleModelChange,
  rHandleChangeChecked,
  rUpdateModel,
}) => {
  const history = useHistory();
  const { id: organizationId, documentId } = useRouteMatch().params;

  const { revision: revisionParams } = useQueryHelper([REVISION_PARAM]);

  const [editMode, updateEditMode] = useState(false);

  const [documentSetting, updateDocumentSetting] = useState({
    documentInfo: {
      name: '',
      type: '',
      revision: '',
      revisionDescription: '',
      typeDescription: '',
      id: null,
      canBeDeleted: null,
      canBeEdited: null,
      reviewedAt: null,
      approvedAt: null,
      status: null,
      number: null,
      extraFiles: [],
      revisionName: '',
    },
    tags: [],
    file: {},
    creator: {},
    submitter: {},
    canViewReviews: false,
    canViewApprovals: false,
    trainingRecord: {},
    revisions: {
      list: [],
      latestRevision: null,
    },
  });

  const updateGeneralSetting = useCallback((data) => {
    const {
      canBeDeleted,
      canBeEdited,
      canDeleteRevision,
      name,
      type,
      id,
      revision,
      revisionDescription,
      file,
      extraFiles,
      creator,
      submitter,
      reviewedAt,
      status,
      number,
      canViewGeneral,
      canViewQualityTasks,
      canViewConnectToEmployee,
      canViewReviews,
      canViewApprovals,
      trainingRecord,
      typeDescription,
      revisions,
      isFinal,
      canAddRevision,
      revisionName,
      tags,
    } = data;

    updateDocumentSetting({
      documentInfo: {
        canBeDeleted,
        canBeEdited,
        canDeleteRevision,
        name,
        type,
        id,
        revision,
        revisionDescription,
        reviewedAt,
        status,
        number,
        typeDescription,
        isFinal,
        extraFiles: extraFiles || [],
        revisionName,
      },
      file,
      creator,
      submitter,
      canViewGeneral,
      canViewQualityTasks,
      canViewConnectToEmployee,
      canViewReviews,
      canViewApprovals,
      trainingRecord,
      canAddRevision,
      tags,
      revisions: {
        list: revisions,
        latestRevision: revisions[revisions.length - 1],
      },
    });
  }, []);

  const onError = useErrorHandler(`/organizations/${organizationId}/documents`);

  useEffect(() => {
    const revison = currentRevision.value || revisionParams;
    const params = configRevisionRequest({ organizationId }, revison);
    getDocumentGeneral(documentId, params)
      .then(({ data }) => updateGeneralSetting(data))
      .catch(onError);
  }, [
    documentId,
    organizationId,
    currentRevision,
    revisionParams,
    onError,
    updateGeneralSetting,
  ]);

  const {
    documentInfo,
    file,
    creator,
    submitter,
    trainingRecord,
    revisions,
    canAddRevision,
    tags,
  } = documentSetting;
  const {
    name,
    type,
    revision,
    revisionDescription,
    id,
    status,
    reviewedAt,
    canBeEdited,
    canDeleteRevision,
    number,
    typeDescription,
    isFinal,
    extraFiles,
    revisionName,
  } = documentInfo;

  let fileName, fileSize, fileId;
  if (file) {
    fileName = file.fileName;
    fileSize = file.fileSize;
    fileId = file.id;
  }

  const { id: submitterId } = submitter;

  const [documentTypeList, updateDocumentTypeList] = useState([]);

  const [model, updateModel] = useState({
    typeId: null,
    submitterId: null,
    revision: '',
    name: '',
    fileId: '',
    isFinal: false,
    extraFiles: [],
    revisionName: '',
    tags: [],
    newTagIds: [],
  });

  const handleModelChange = (event) => {
    const { name, value } = event.target;
    updateModel((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    updateModel((prev) => ({
      ...prev,
      revisionDescription,
      isFinal,
      name,
      revision,
      id,
      fileId,
      file,
      submitterId,
      number,
      tags,
      newTagIds: [],
      typeDescription,
      extraFiles: extraFiles || [],
      revisionName: revisionName,
    }));
  }, [
    fileId,
    file,
    id,
    name,
    number,
    revision,
    revisionDescription,
    submitterId,
    typeDescription,
    isFinal,
    trainingRecord,
    extraFiles,
    revisionName,
    tags,
  ]);

  useEffect(() => {
    updateRevisionModel((prev) => ({
      ...prev,
      id,
      type,
      name,
      number,
      revision,
      submitterId,
      typeDescription,
      revisionDescription,
    }));
  }, [
    id,
    name,
    type,
    number,
    revision,
    revisionDescription,
    submitterId,
    typeDescription,
    updateRevisionModel,
    tags,
  ]);

  useEffect(() => {
    getDocumentTypes(organizationId).then(({ data }) => {
      if (type) {
        const typeId = data.find((item) => item.name === type);
        if (typeId) {
          updateModel((prev) => ({ ...prev, typeId: typeId.id }));
        }
      }
      updateDocumentTypeList(configureGetDocumentTypes(data));
    });
  }, [organizationId, type]);

  const currentOption = (data, type) => {
    return data.find((item) => item.label === type);
  };

  const handleDocumentTypeChange = ({ id: typeId }) => {
    updateModel((prev) => ({ ...prev, typeId }));
  };

  const handleDownload = async (id, organizationId, revision, fileId) => {
    try {
      const document = await downloadGeneralDocument(id, fileId, {
        organizationId,
        revision,
      });
      const { path, fileName } = document.data;

      const { data } = await getFileByUrl(path);
      saveFileAs(data, fileName);
    } catch (error) {}
  };

  const handleSubmit = (_, values) => {
    const { id, ...body } = values;

    editDocumentGeneral(id, body)
      .then(({ data }) => {
        updateGeneralSetting(data);
        updateSelectedFile({});
        updateEditMode(false);
        toast.success(<MessageResetSuccess message={SUCCESS_DOCUMENT_EDIT} />);
      })
      .catch(onError);
  };

  const [defaultSubmitter, updateDefaultSubmitter] = useState({
    label: '',
    value: '',
  });

  useEffect(() => {
    const { firstName, lastName, email, id } = submitter;
    updateDefaultSubmitter({
      label: `${firstName || ''} ${lastName || ''} (${email || ''})`,
      value: `${id}`,
    });
  }, [submitter]);

  const [userOptions, updateUserOptions] = useState([]);

  useEffect(() => {
    getDocumentUsers(organizationId, '').then(({ data }) =>
      updateUserOptions(configureGetUsers(data)),
    );
  }, [organizationId]);

  const handleReviewersChange = ({ id: submitterId }) => {
    updateModel((prev) => ({ ...prev, submitterId }));
  };

  const debounceHandleSearch = useMemo(() => {
    return debounce((input) => {
      getDocumentUsers(organizationId, input).then(({ data }) =>
        updateUserOptions(configureGetUsers(data)),
      );
    }, DEBOUNCE_TIMER);
  }, [organizationId]);

  const handleReviewersSearch = (value) => {
    debounceHandleSearch(value);
  };

  const handleDeleteFile = () => {
    updateModel((prev) => ({ ...prev, fileId: null }));
  };

  const [selectedFile, updateSelectedFile] = useState({});

  const isDisabled = useMemo(() => {
    let checkKey = [DESCRIPTION_TYPE_KEY, FINAL_KEY, REVISION_NAME_KEY];
    return !Object.entries(model).every(([key, value]) => {
      const isCheckKey = checkKey.find((type) => type === key);
      return isCheckKey || value;
    });
  }, [model]);

  const [revisionDelete, updateRevisionDelete] = useState({
    isOpen: false,
    values: {
      revision: '',
      type: '',
      typeDescription: '',
      number: '',
      name: '',
    },
    model: {
      documentId: '',
      organizationId: '',
      revision: '',
    },
  });

  const handleRevisionOpen = (organizationId, documentId, documentInfo) => {
    const { revision, type, typeDescription, number, name } = documentInfo;

    updateRevisionDelete({
      isOpen: true,
      values: {
        revision,
        type,
        typeDescription,
        number,
        name,
      },
      model: {
        documentId,
        organizationId,
        revision,
      },
    });
  };

  const handleChangeChecked = () => {
    updateModel((prev) => ({
      ...prev,
      isFinal: !prev.isFinal,
    }));
  };

  const getTagsFromAPI = useCallback(() => {
    return getTags(organizationId);
  }, [organizationId]);

  if (revisionAddMode) {
    return (
      <DocumentAddRevision
        documentId={documentId}
        revisionModel={revisionModel}
        latestRevision={revisions.latestRevision}
        creator={creator}
        submitter={submitter}
        organizationId={organizationId}
        updateRevisionAddMode={updateRevisionAddMode}
        model={rModel}
        handleModelChange={rHandleModelChange}
        handleChangeChecked={rHandleChangeChecked}
        updateModel={rUpdateModel}
        handleSubmit={(_, values, isTraining = false) => {
          const { id, ...body } = values;
          createDocumentRevision(id, body)
            .then(({ data }) => {
              toast.success(
                <MessageResetSuccess message={SUCCESS_REVISION_CREATE} />,
              );
              if (isTraining) {
                const { revision, id } = data;
                history.push({
                  pathname: `/organizations/${organizationId}/training-records/create`,
                  state: {
                    linkToRecord: `${window.location.origin}/organizations/${organizationId}/documents/${id}?revision=${revision}`,
                    document: { id, revision },
                  },
                });
              }
              const { revision } = data;
              updateCurrentRevision({ value: revision });
              updateRevisionAddMode(false);
            })
            .catch(onError);
        }}
      />
    );
  }

  return (
    <div className="general">
      <div className="general-header">
        {editMode ? (
          <h6>Edit General</h6>
        ) : (
          <div className="header-block">
            <h6>General</h6>
            <p className="mb-0">
              This Document has {revisions.list.length} revision(s)
            </p>
          </div>
        )}
        {canBeEdited && !editMode && (
          <button
            className="general__btn-edit"
            onClick={() => updateEditMode((prev) => !prev)}
          >
            <i className="bx bxs-edit-alt"></i> Edit
          </button>
        )}
      </div>
      <hr />
      <AvForm className="form-horizontal mt-2" onValidSubmit={handleSubmit}>
        <div className="general-info">
          <Row>
            <Col xs="4">
              <div className="info-column">
                {editMode ? (
                  <>
                    <p className="info-column__title">Document type*</p>
                    <FormGroup className="select2-container">
                      <Select
                        styles={customStyles}
                        defaultValue={currentOption(documentTypeList, type)}
                        options={documentTypeList}
                        onChange={(value) => handleDocumentTypeChange(value)}
                      />
                    </FormGroup>
                  </>
                ) : (
                  <>
                    <p className="info-column__title">Document type:</p>
                    <p className="info-column__text">{type}</p>
                  </>
                )}
              </div>
            </Col>
            <Col xs="4">
              <div className="info-column">
                <p className="info-column__title">System #:</p>
                {editMode ? (
                  <div className="form-group">
                    <Input
                      name="number"
                      className="form-control"
                      type="text"
                      value={model.number || ''}
                      onChange={(event) => handleModelChange(event)}
                      disabled
                    />
                  </div>
                ) : (
                  <p className="info-column__text">
                    {model.number && `#${model.number}`}
                  </p>
                )}
              </div>
            </Col>
            <Col xs="4">
              <div className="info-column">
                {editMode ? (
                  <>
                    <p
                      className={classnames('info-column__title', {
                        'info-column_right': !editMode,
                      })}
                    >
                      Document name*
                    </p>
                    <div className="form-group">
                      <AvField
                        name="name"
                        className="form-control"
                        placeholder="Enter document name"
                        maxLength={MAX_INPUT_LENGTH}
                        type="text"
                        value={model.name}
                        onChange={(event) => handleModelChange(event)}
                        validate={validateEmptyText}
                        errorMessage={REQUIRED_FIELD}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <p
                      className={classnames('info-column__title', {
                        'info-column_right': !editMode,
                      })}
                    >
                      Document name:
                    </p>
                    <p className="info-column__text info-column_right">
                      {name}
                    </p>
                  </>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {editMode ? (
                <div className="form-group">
                  <p className="info-column__title">Document ID</p>
                  <AvField
                    name="typeDescription"
                    className="form-control"
                    placeholder="Enter Document ID"
                    maxLength={MAX_INPUT_LENGTH}
                    type="text"
                    value={model.typeDescription}
                    onChange={(event) => handleModelChange(event)}
                  />
                </div>
              ) : (
                <div className="info-column mt-2">
                  <p className="info-column__title">Document ID:</p>
                  <p className="info-column__text">{typeDescription}</p>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <hr />
        <div className="general-revision">
          <div className="info-column">
            {editMode ? (
              <>
                <TextCheckbox
                  text="Set as Final"
                  className="mb-3"
                  isChecked={model.isFinal}
                  onChange={handleChangeChecked}
                  disabled={isFinal}
                  id="tooltip-final"
                />
                <UncontrolledTooltip
                  placement="top"
                  id="tooltip-checkbox"
                  target="tooltip-final"
                >
                  <span>
                    Final Revision can be set manually if the current Document
                    does not require review and approval
                  </span>
                </UncontrolledTooltip>
                <Row>
                  <Col sm="4">
                    <p className="info-column__title">Revision*</p>
                    <div className="form-group">
                      <AvField
                        name="revision"
                        className="form-control"
                        type="number"
                        disabled
                        value={model.revision}
                        onChange={(event) => handleModelChange(event)}
                      />
                    </div>
                  </Col>
                  <Col sm="8">
                    <div className="info-column">
                      <p className="info-column__title">Revision name</p>
                      <div className="form-group">
                        <AvField
                          type="text"
                          name="revisionName"
                          value={model.revisionName}
                          className="form-control"
                          placeholder="Enter revision name"
                          maxLength={MAX_INPUT_LENGTH}
                          onChange={(event) => handleModelChange(event)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <div className="info-column__revision d-flex flex-wrap">
                  <p className="info-column__title">Revision:</p>
                  {canBeEditedDocument && (
                    <div className="revision-group">
                      {canAddRevision && (
                        <IconButton
                          iconClass="bx bx-plus"
                          text="Add revision"
                          onClick={() => updateRevisionAddMode(true)}
                        />
                      )}
                      <TextCheckbox
                        text="Set as Final"
                        className="ml-3"
                        disabled={isFinal}
                        isChecked={isFinal}
                        id="tooltip-final"
                        onChange={() => {
                          changeFlag(documentId, {
                            organizationId,
                            revision,
                          }).then(({ data }) => updateGeneralSetting(data));
                        }}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        id="tooltip-checkbox"
                        target="tooltip-final"
                      >
                        <span>
                          Final Revision can be set manually if the current
                          Document does not require review and approval
                        </span>
                      </UncontrolledTooltip>
                      {revisions.list.length > 1 && canDeleteRevision && (
                        <IconButton
                          iconClass="bx bxs-trash-alt"
                          text="Delete revision"
                          onClick={() =>
                            handleRevisionOpen(
                              organizationId,
                              documentId,
                              documentInfo,
                            )
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
                <CustomSelect
                  list={revisions.list}
                  currentOption={currentRevision.value || revision}
                  onChange={(event) => {
                    const { value } = event.target;
                    updateCurrentRevision({ value: value });
                  }}
                />
              </>
            )}
          </div>
          {!editMode && revisionName && (
            <div className="info-column">
              <p className="info-column__title">Revision name</p>
              <p className="info-column__text info-column__text-bottom">
                {revisionName}
              </p>
            </div>
          )}
          <div className="info-column">
            {editMode ? (
              <>
                <p className="info-column__title">Revision description*</p>
                <div className="form-group">
                  <AvField
                    type="textarea"
                    id="textarea"
                    name="revisionDescription"
                    placeholder="Enter revision description"
                    value={model.revisionDescription}
                    onChange={(event) => handleModelChange(event)}
                    maxLength={`${MAX_TEXTAREA_LENGTH}`}
                    validate={validateEmptyText}
                    errorMessage={REQUIRED_FIELD}
                    rows="3"
                  />
                </div>
              </>
            ) : (
              <>
                <p className="info-column__title">Revision description:</p>
                <p className="info-column__text info-column__text-bottom">
                  {revisionDescription}
                </p>
              </>
            )}
          </div>
          <div className="info-columns">
            {editMode ? (
              <>
                <p className="info-column__title">Tag(s):</p>
                <div className="form-group">
                  <Index
                    handleRequest={getTagsFromAPI}
                    configOptions={configTags}
                    placeholder="Choose tag(s)"
                    onChange={(values) => {
                      updateModel((prev) => ({
                        ...prev,
                        newTagIds: values,
                      }));
                    }}
                  />
                </div>
                <div className="filter-menu-container">
                  {model.tags.map(({ name, id }) => {
                    return (
                      <div key={id} className="filter-item">
                        <span>{name}</span>
                        <i
                          className="bx bx-x"
                          onClick={() => {
                            updateModel((prev) => ({
                              ...prev,
                              tags: prev.tags.filter((tag) => tag.id !== id),
                            }));
                          }}
                        ></i>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <TagsList tags={tags} />
            )}
          </div>
        </div>
        <hr />
        {editMode ? (
          <DocumentAttached
            text="Controlled document*"
            file={model.file}
            handleUploadFile={(data) => {
              updateModel((model) => changeFileDocument(model, data));
            }}
            onDeleteFile={() => {
              updateModel((model) => changeFileDocument(model, null));
            }}
          />
        ) : (
          <div className="general-attached">
            <div className="info-column">
              <p className="info-column__title">Controlled document:</p>
              <div className="document-attached">
                <div
                  className="file-wrapper"
                  onClick={() =>
                    handleDownload(
                      id,
                      organizationId,
                      currentRevision.value,
                      model.fileId,
                    )
                  }
                >
                  <i className="bx bxs-file-doc"></i>
                </div>
                <div className="file-info">
                  <p className="file-info__name">
                    {selectedFile.name || fileName}
                  </p>
                  <p className="file-info__size">
                    Size: {selectedFile.size || formatBytes(fileSize)}
                  </p>
                </div>
                {editMode && (
                  <div className="file-info__remove">
                    <i
                      className="bx bxs-trash-alt"
                      onClick={() => handleDeleteFile()}
                    ></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <hr />
        {editMode ? (
          <DocumentAttachedFiles
            title="Extra document(s)"
            files={model.extraFiles}
            onDeleteFile={(id) => {
              updateModel((model) =>
                changeExtraFiles(model, filterByField(model.extraFiles, id)),
              );
            }}
            updateModel={(body) => {
              updateModel((model) =>
                changeExtraFiles(model, [...model.extraFiles, body]),
              );
            }}
          />
        ) : (
          <DocumentDownloadFiles
            files={extraFiles}
            title="Extra document(s):"
            handleDownload={(fileId) =>
              handleDownload(id, organizationId, currentRevision.value, fileId)
            }
          />
        )}
        {!!(editMode || extraFiles.length) && <hr />}
        {editMode ? (
          <>
            <Row>
              <Col xl="12">
                <div className="info-column">
                  <p className="info-column__title">Responsible*</p>
                  <FormGroup className="select2-container">
                    <Select
                      isMulti={false}
                      styles={customStyles}
                      options={userOptions}
                      defaultValue={defaultSubmitter}
                      onChange={(value) => handleReviewersChange(value)}
                      onInputChange={(value) => handleReviewersSearch(value)}
                      placeholder="Choose"
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col xl="12">
                <div className="info-column">
                  <p className="info-column__title">Created By*</p>
                  <div className="form-group">
                    <Input
                      name="createdBy"
                      className="form-control"
                      type="text"
                      value={`${creator.firstName || ''} ${
                        creator.lastName || ''
                      } (${creator.email || ''}) - ${creator.role}`}
                      disabled
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col sm="6" className="mb-3">
              <UserEmailBlock title="Responsible:" user={submitter} />
            </Col>
            <Col sm="6" className="mb-3">
              <UserEmailBlock
                title="Created By:"
                user={creator}
                containerClassName="user-email-right"
              />
            </Col>
          </Row>
        )}
        <hr className={classnames({ 'd-none': editMode })} />
        {editMode ? (
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
                handler={() => updateEditMode((prev) => !prev)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
                disabled={isDisabled}
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        ) : (
          <div className="general-status">
            <div className="info-column">
              <p className="info-column__title">Status:</p>
              {editMode ? (
                <div className="form-group">
                  <AvField
                    name="id"
                    className="form-control"
                    placeholder="Enter document id"
                    maxLength={MAX_INPUT_LENGTH}
                    type="text"
                    value={DOCUMENT_STATUS[status]}
                    disabled
                  />
                </div>
              ) : (
                <p className="info-column__text">{DOCUMENT_STATUS[status]}</p>
              )}
            </div>
            <div className="info-column info-column_right">
              <p className="info-column__title">Reviewed date:</p>
              <p className="info-column__text">
                {reviewedAt ? moment(reviewedAt).format('ll') : ''}
              </p>
            </div>
          </div>
        )}
        <AvField
          name="organizationId"
          type="text"
          value={organizationId}
          className="btn-hidden"
        />
        <AvField
          name="typeId"
          type="text"
          value={model.typeId}
          className="btn-hidden"
        />
        <AvField
          name="fileId"
          type="text"
          value={model.file?.id}
          className="btn-hidden"
        />
        <AvField
          name="submitterId"
          type="text"
          value={`${model.submitterId}`}
          className="btn-hidden"
        />
        <AvField
          name="id"
          type="text"
          value={`${model.id}`}
          className="btn-hidden"
        />
        <AvField
          name="setAsFinal"
          type="text"
          value={`${!!model.isFinal}`}
          className="btn-hidden"
        />
        <AvField
          name="extraFileIds"
          type="text"
          value={getFilesIds(model.extraFiles)}
          className="btn-hidden"
        />
        <AvField
          name="tagIds"
          type="hidden"
          value={getUnionArrayIds([...model.tags, ...model.newTagIds])}
        />
      </AvForm>
      <ModalOuter
        title="Delete Document Revision"
        isOpen={revisionDelete.isOpen}
        onCancel={() =>
          updateRevisionDelete((prev) => ({ ...prev, isOpen: false }))
        }
        onSubmit={(_, values) => {
          const { documentId, ...body } = values;

          removeDocumentRevision(documentId, body)
            .then(({ data }) => {
              const { message } = data;
              updateCurrentRevision({ value: null });
              toast.success(<MessageResetSuccess message={message} />);
            })
            .catch((error) => onError(error))
            .finally(() =>
              updateRevisionDelete((prev) => ({ ...prev, isOpen: false })),
            );
        }}
      >
        <RevisionDeleteBody
          values={revisionDelete.values}
          model={revisionDelete.model}
        />
      </ModalOuter>
    </div>
  );
};

export default DocumentGeneral;
