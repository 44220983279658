import React from 'react';
import moment from 'moment';
import { CREATON_FORMAT } from '../../../../constants/helper';

const NotificationDropdownItem = ({ createdAt, task, onRedirectTask }) => {
  const { number, dueDate, name } = task;

  return (
    <div className="media mt-3" onClick={onRedirectTask}>
      <div className="bell-container">
        <i className="bx bxs-bell"></i>
      </div>
      <div className="media-body">
        <h6 className="mt-0 mb-1">Action Assigned</h6>
        <div className="font-size-12 text-muted">
          <p className="mb-1">
            The Action has been assigned to you: <b>#{number}</b> {name}{' '}
            <b>Due Date: {moment(dueDate).format(CREATON_FORMAT)}</b>
            <br />
            Open the Action for more detailed information.
          </p>
          <p className="mb-0">{moment(createdAt).fromNow()}</p>
        </div>
      </div>
    </div>
  );
};

export default NotificationDropdownItem;
