import React from 'react';
import { Container } from 'reactstrap';

import OrganizationTitle from '../../../components/organization/Title';
import DashboardCounters from '../../../components/Dashboard/Counters';
import DashboardHeader from '../../../components/Dashboard/Header/dashboard-header';
import DashboardDueDates from '../../../components/Dashboard/DueDate';
import DashboardDocuments from '../../../components/Dashboard/Documents';

const Dashboard = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <OrganizationTitle title="Dashboard" />
        <DashboardHeader />
        <DashboardCounters />
        <DashboardDueDates />
        <DashboardDocuments />
      </Container>
    </div>
  );
};

export default Dashboard;
