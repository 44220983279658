import React from 'react';

const TrainingRecordsQuestions = ({ questions }) => {
  return (
    <div className="question-container">
      {questions.map(({ number, name, answers }, index) => {
        return (
          <div key={number} className="question-item">
            <p className="question-item__text">
              <b>{index + 1}.</b> {name}
            </p>
            <ol type="a">
              {answers.map(({ number, name, isSelected }) => (
                <li className="passed-answer mb-1" key={number}>
                  {isSelected && <i className="bx bx-check"></i>}
                  {name}
                </li>
              ))}
            </ol>
          </div>
        );
      })}
      {!questions.length && <p>-</p>}
    </div>
  );
};

export default TrainingRecordsQuestions;
