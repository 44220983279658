import React, { useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

import MessageResetSuccess from '../../../ToastSuccess';
import DeleteDocumentTagModal from '../../../../modals/DeleteDocumentTag';

import { deleteDocumentTag } from '../../../../utils/api/organization-api';
import {
  modelTagDeleteModal,
  updateModelTagDeleteModal,
} from '../../../../models/organizations/tag-delete-modal';
import { SUCCESS_TAGS_DELETE } from '../../../../constants/messages';

const SettingTagBody = ({ tags, handleToggle, getTagsAPI }) => {
  const { id: organizationId } = useRouteMatch().params;

  const [deleteModal, updateDeleteModal] = useState(modelTagDeleteModal);

  const handleOpenDeleteModal = (name, organizationId, id) => {
    updateDeleteModal(
      updateModelTagDeleteModal({ name }, { organizationId, id }),
    );
  };

  const handleCloseDeleteModal = () => {
    updateDeleteModal((prev) => ({ ...prev, isOpen: false }));
  };

  const deleteDocumentTagAPI = useCallback(
    (organizationId, tagId) => {
      deleteDocumentTag(organizationId, tagId).then(() => {
        toast.success(<MessageResetSuccess message={SUCCESS_TAGS_DELETE} />);
        handleCloseDeleteModal();
        getTagsAPI();
      });
    },
    [getTagsAPI],
  );

  return (
    <div>
      {!!tags.length ? (
        <div className="filter-menu-container">
          {tags.map(({ id, name }) => {
            return (
              <div key={id} className="filter-item">
                <span>{name}</span>
                <i
                  className="bx bx-x"
                  onClick={() =>
                    handleOpenDeleteModal(name, organizationId, id)
                  }
                ></i>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="card-tag-body">
          <div>
            <p className="card-tag-body__text">No Tags yet</p>
            <Button
              className="button-tags button-tags_lg"
              onClick={handleToggle}
            >
              + Create
            </Button>
          </div>
        </div>
      )}
      <DeleteDocumentTagModal
        isOpen={deleteModal.isOpen}
        values={deleteModal.values}
        model={deleteModal.model}
        onCancel={handleCloseDeleteModal}
        onSubmit={({ organizationId, tagId }) =>
          deleteDocumentTagAPI(organizationId, tagId)
        }
      />
    </div>
  );
};

export default SettingTagBody;
