import React, { useState } from 'react';
import moment from 'moment';
import { Col, Row, Badge } from 'reactstrap';

import InfoColumn from '../../../../../components/shared/GeneralColumn';
import TrainingAttachedFile from '../AttachedFile';
import TrainingQuestionsList from '../QuestionList/QuestionList';
import TrainingViewHeader from '../shared/ViewHeader';
import UserList from '../../../../../modals/UserList';
import TagsList from '../../../../../components/shared/TagsList';

import { isUrlForLinkRecord, isUrlString } from '../../../../../utils/is-url';
import {
  PENDING_STATUS,
  TRAINING_STATUS,
} from '../../../../../constants/trainingRecords';
import { getDataUser } from '../../../../../utils/get-store-user';
import PlaceholderText from '../../../../../components/shared/PlaceholderText';
import UserEmailBlock from '../../../../../components/shared/UserEmailBlock';
import { CREATON_FORMAT, FORMAT_TIME } from '../../../../../constants/helper';
import InfoColumnWrapper from '../../../../../components/shared/InfoColumnWrapper';

const TrainingDetailsView = ({
  trainingModel,
  updateEditMode,
  updatePassMode,
  user,
}) => {
  const { role } = getDataUser();

  const {
    number,
    name,
    dueDate,
    description,
    link,
    assigner,
    creator,
    createdAt,
    canBeEdited,
    canBePassed,
    assigneeUsers,
    files,
    questions,
    assigneeUserGroups,
    trainingStatus,
    linkDescription,
    extraLinkDescription,
    extraLink,
    tags,
  } = trainingModel;

  const [userListModal, updateUserListModal] = useState({
    isOpen: false,
    users: [],
  });

  const handleOpenModal = (users) => {
    updateUserListModal({
      isOpen: true,
      users,
    });
  };

  return (
    <>
      <div className="general-header">
        <h6>
          <span className='mr-2'>Training record details</span>
          {trainingStatus !== null && (
            <Badge
              className={`font-size-12 mt-2 ml-0 mt-sm-0 badge-soft-${
                TRAINING_STATUS[trainingStatus] === PENDING_STATUS
                  ? 'warning'
                  : 'success'
              }`}
              pill
            >
              {TRAINING_STATUS[trainingStatus]}
            </Badge>
          )}
        </h6>
        <div className="btn-group">
          {canBePassed && (
            <button
              className="mr-2 general__btn-edit general__btn-edit_blue"
              onClick={() => updatePassMode((prev) => !prev)}
            >
              Pass training
            </button>
          )}
          {canBeEdited && (
            <button
              className="general__btn-edit"
              onClick={() => updateEditMode((prev) => !prev)}
            >
              <i className="bx bxs-edit-alt"></i> Edit
            </button>
          )}
        </div>
      </div>
      <hr />
      <div className="general-info">
        <TrainingViewHeader
          number={number}
          name={name}
          dueDate={dueDate}
          description={description}
        />
        <TagsList tags={tags} />
        <hr />
        <Row>
          <Col xs="12">
            <InfoColumnWrapper title="Assignee(s):">
              {assigneeUsers.map((user, index) => (
                <UserEmailBlock user={user} key={index} />
              ))}
              {!assigneeUsers?.length && (
                <PlaceholderText text="No assignees." />
              )}
            </InfoColumnWrapper>
          </Col>
        </Row>
        <hr />
        {assigneeUserGroups.map(({ name, userCount, users }, index) => (
          <p
            key={`${name}-${index}`}
            className="resourse"
            onClick={() => handleOpenModal(users)}
          >
            {name} - {userCount} user(s)
          </p>
        ))}
        <Row className="mt-4">
          <Col>
            <UserEmailBlock title="Assigner:" user={assigner} />
          </Col>
        </Row>
        <hr />
        <Row className="mt-3">
          <Col>
            <InfoColumn 
              title='Link to Record:' 
              text={isUrlForLinkRecord(link, role) ? (
                <a href={link} target='_self'>
                  <i className='bx bx-link top-link'></i>
                  {linkDescription}
                </a>
              ) : (
                linkDescription ? (
                  <span>{linkDescription}</span>
                ) : (
                  <PlaceholderText text="No link." />
                )
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InfoColumn 
              title='External Link:' 
              text={isUrlString(extraLink) ? (
                <a href={extraLink} target='_self'>
                  <i className='bx bx-link top-link'></i>
                  {extraLinkDescription}
                </a>
              ) : (
                extraLinkDescription ? (
                  <span>{extraLinkDescription}</span>
                ) : (
                  <PlaceholderText text="No link." />
                )
              )}              
            />
          </Col>
        </Row>
        <hr />
        <div className="general-attached">
          <InfoColumnWrapper title="File(s) attached:">
            <TrainingAttachedFile files={files} />
          </InfoColumnWrapper>
        </div>
        <hr />
        <Row>
          <Col xs="12">
            <InfoColumnWrapper title="Questions:">
              <TrainingQuestionsList questions={questions} />
            </InfoColumnWrapper>
          </Col>
        </Row>
        <hr />
        <Row className="mt-3">
          <Col sm="12" md="8">
            <UserEmailBlock title="Created By:" user={creator} />
          </Col>
          <Col sm="12" md="4">
            <div className="d-flex justify-content-end">
              <InfoColumn
                title="Creation Date:"
                text={createdAt ? moment(createdAt).format(CREATON_FORMAT) : ''}
              />
              <InfoColumn
                title="Creation Time:"
                text={createdAt ? moment(createdAt).format(FORMAT_TIME) : ''}
                classes="info-column_right ml-5"
              />
            </div>
          </Col>
        </Row>
      </div>
      <UserList
        isOpen={userListModal.isOpen}
        onClose={() =>
          updateUserListModal((prev) => ({ ...prev, isOpen: false }))
        }
        users={userListModal.users}
      />
    </>
  );
};

export default TrainingDetailsView;
