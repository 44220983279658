import React from 'react';
import { Card, CardBody, TabContent, TabPane } from 'reactstrap';

const HelpPages = ({ activeTab, navList }) => {
  return (
    <Card>
      <CardBody>
        <TabContent activeTab={activeTab} className="p-0">
          {navList.map(({ tabId, component: Component }) => (
            <TabPane key={tabId} tabId={tabId}>
              <Component />
            </TabPane>
          ))}
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default HelpPages;
