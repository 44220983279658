import { getSortedData } from './shared/get-sorted-data';

const SELECT_LIMIT = 20;
const NOT_FOUND_INDEX = -1;
const SORTED_KEY = 'label';

export const getFilteredSelect = (optionsList, search) => {
  const sortedOptions = getSortedData(optionsList, SORTED_KEY);

  return sortedOptions
    .filter((option) => {
      return option.value.indexOf(search) !== NOT_FOUND_INDEX;
    })
    .slice(0, SELECT_LIMIT);
};
