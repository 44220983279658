import React from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { ACTIVE } from '../../constants/modals';

const OrganizationActiveModal = ({
  onSubmit,
  onCancel,
  isOpen,
  values,
  model,
}) => {
  const { isActive, organizationName } = values;

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">
          Set Organization {isActive === ACTIVE ? 'Inactive' : 'Active'}
        </h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-4" onValidSubmit={onSubmit}>
        <div className="modal-body">
          <p className="text-active">
            Are you sure you want to set the Organization{' '}
            <b>{organizationName || ''}?</b>{' '}
            {isActive === ACTIVE ? 'Inactive' : 'Active'}?
          </p>
          <p className="text-active">
            {isActive === ACTIVE
              ? 'All Admins and Users will not be able to access the Organization upon its deactivation.'
              : 'All Admins and Users will  be able to access the Organization upon its activation.'}
          </p>
        </div>

        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <button
                className={`btn mr-3 w-md waves-effect waves-light ${
                  isActive === ACTIVE ? 'btn-deactive' : 'btn-primary'
                }`}
                type="submit"
              >
                {isActive === ACTIVE ? 'Set Inactive' : 'Set Active'}
              </button>
              <button
                type="button"
                className="btn btn-light w-md waves-effect waves-light mr-3"
                onClick={onCancel}
              >
                Cancel
              </button>
            </Col>
          </Row>
        </div>
        <AvField
          name="id"
          type="text"
          value={model.organizationId}
          className="btn-hidden"
        />
      </AvForm>
    </Modal>
  );
};

export default OrganizationActiveModal;
