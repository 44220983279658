/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { debounce } from 'lodash';
import classnames from 'classnames';

import OrganizationCreatingModal from '../../../modals/OrganizationCreating';
import DisplayColumnsModal from '../../../modals/DisplayColumns';
import TableHead from '../../../components/tables/TableHead';
import TableBody from '../../../components/tables/TableBody';

import { getOrganizations } from '../../../store/auth/organization/actions';
import {
  LIMIT,
  INITIAL_OFFSET,
  INITIAL_PAGE,
  TOTAL_ITEMS,
  PAGE_COUNT,
  MAX_LIMIT,
} from '../../../constants/table';
import { columnsHead, columnsBody } from './Table';
import { getFileName } from '../../../utils/get-file-name';
import { useDocumentExport } from '../../../custom-hooks/useDocumentExport';
import { getExportParams } from '../../../utils/get-export-params';
import { CSV } from '../../../constants/helper';
import { orderTable } from '../../../utils/order-table';
import { getColumnsFilter } from '../../../utils/get-columns-search';
import { mergetParams } from '../../../utils/merge-table-params';
import { getDataTableColumns } from '../../../utils/backend-helper';
import TableFooter from '../../../components/tables/TableFooter';
import TablePaginationText from '../../../components/tables/PaginationText';
import TableSpinner from '../../../components/tables/Spinner';
import { dragReorder } from '../../../utils/dragable-helpers/dragable-reoder';
import TableHeaderOrganizations from '../../../components/tables/HeaderOrganization';
import TableExportLogsMenu from '../../../components/tables/ExportLogsMenu';
import { changeStatusOrganizations } from '../../../utils/tables-helper/change-status-logs';
import { useCheckboxOrganizationTable } from '../../../custom-hooks/useCheckboxOrganizationTable';
import { changeDataFromHidden } from '../../../utils/tables-helper/change-data-from-hidden';
import { getClearFilter } from '../../../utils/tables-helper/status-filter';
import {
  getChangedResizeColumns,
  getEndResizeColumns,
} from '../../../utils/tables-helper/resizable';
import { useTableWidth } from '../../../custom-hooks/useTableWidth';
import { deleteRow } from '../../../utils/tables-helper/deleteRow';

const Organization = ({ user }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleToggleModal = (creatingSuccess = false) => {
    updatingTable(creatingSuccess);
    setIsOpenModal((isOpen) => !isOpen);
  };

  const [displayMoldal, setDisplayModal] = useState({
    isOpen: false,
  });

  const [columnsVisibility, updateColumnsVisibility] = useState({
    isactivated: {
      label: 'Options',
      isVisible: true,
    },
    name: {
      label: 'Organizations',
      isVisible: true,
      accessor: 'Name',
      sort: true,
    },
    isManagedByClient: {
      label: 'Managed by Client',
      isVisible: true,
      accessor: 'IsManagedByClient',
      sort: true,
    },
    status: {
      label: 'Status',
      isVisible: true,
      accessor: 'Status',
      sort: true,
    },
  });

  const [items, setItems] = useState();

  const [isLoading, updateIsLoading] = useState(false);

  const [pagination, setPagination] = useState({
    data: [],
    limit: LIMIT,
    offset: INITIAL_OFFSET,
    pageCount: PAGE_COUNT,
    pageNumber: INITIAL_PAGE,
    totalItems: TOTAL_ITEMS,
    columns: getColumnsFilter(columnsVisibility),
    search: '',
    orderBy: '',
    orderType: '',
  });

  const getHumansList = useCallback(
    (limit, offset, search, orderBy, orderType, columns) => {
      const params = mergetParams({
        limit,
        offset,
        search,
        orderBy,
        orderType,
      });
      return getDataTableColumns('/organizations', params, columns);
    },
    [],
  );

  const updatingTable = async (success) => {
    try {
      if (!success) return;
      updateIsLoading(true);
      const { limit, offset, search, orderBy, orderType, columns } = pagination;
      const data = await getHumansList(
        limit,
        offset,
        search,
        orderBy,
        orderType,
        columns,
      );
      setItems(data);
    } catch (error) {
    } finally {
      updateIsLoading(false);
    }
  };

  useEffect(() => {
    const getItems = async (
      limit,
      offset,
      search,
      orderBy,
      orderType,
      columns,
    ) => {
      try {
        updateIsLoading(true);
        const data = await getHumansList(
          limit,
          offset,
          search,
          orderBy,
          orderType,
          columns,
        );
        setItems(data);
      } catch (error) {
      } finally {
        updateIsLoading(false);
      }
    };

    getItems(
      pagination.limit,
      pagination.offset,
      pagination.search,
      pagination.orderBy,
      pagination.orderType,
      pagination.columns,
    );
  }, [
    pagination.limit,
    pagination.search,
    pagination.offset,
    pagination.orderBy,
    pagination.orderType,
    getHumansList,
  ]);

  useEffect(() => {
    const getColumns = getColumnsFilter(columnsVisibility);

    setPagination((prev) => ({
      ...prev,
      columns: getColumns,
    }));
  }, [columnsVisibility]);

  const debounceHandleUpdate = useMemo(() => {
    return debounce((input) => {
      setPagination((prev) => ({
        ...prev,
        pageNumber: INITIAL_PAGE,
        offset: INITIAL_OFFSET,
        search: input,
      }));
    }, 1000);
  }, []);

  const handleOrder = (key) => {
    setPagination((prev) => orderTable(key, prev));
    updateCheckedHead(false);
  };

  const handleChangeLimit = (value) => {
    setPagination((prev) => ({
      ...prev,
      pageNumber: INITIAL_PAGE,
      offset: INITIAL_OFFSET,
      limit: value,
    }));

    updateCheckedHead(false);
  };

  const handlePageClick = ({ selected }) => {
    const offset = Math.ceil(selected * pagination.limit);
    setPagination((prev) => ({
      ...prev,
      pageNumber: selected,
      offset,
    }));

    updateCheckedHead(false);
  };

  const handleToggleColumn = () => {
    setDisplayModal((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  useEffect(() => {
    const { limit, offset, search, orderBy, orderType } = pagination;
    getOrganizations(limit, offset, search, orderBy, orderType);
  }, [
    pagination.limit,
    pagination.search,
    pagination.offset,
    pagination.orderBy,
    pagination.orderType,
  ]);

  useEffect(() => {
    if (!items) return;
    const {
      data,
      pagination: { totalCount },
    } = items;
    setPagination((prev) => ({
      ...prev,
      data: changeDataFromHidden(data, shadowData),
      pageCount: Math.ceil(totalCount / prev.limit),
      totalItems: totalCount,
    }));
  }, [items]);

  const [columnsHidden, updateColumnsHidden] = useState({
    name: true,
    status: true,
    isManagedByClient: true,
    isactivated: true,
  });

  const handleSaveColumns = () => {
    updateColumnsHidden((prev) => {
      return Object.entries(prev).reduce((acc, [key, value]) => {
        acc[key] = columnsVisibility[key].isVisible;
        return acc;
      }, {});
    });
    setDisplayModal((prev) => ({ ...prev, isOpen: false }));
  };

  const handleUpdateColumnsVisible = (key) => {
    updateColumnsVisibility((prev) => ({
      ...prev,
      [key]: { ...prev[key], isVisible: !prev[key].isVisible },
    }));
  };

  const handleSelectAll = (toggle) => {
    const newColumnsVisible = Object.entries(columnsVisibility).reduce(
      (acc, [key, value]) => {
        acc[key] = { ...columnsVisibility[key], isVisible: toggle };
        return acc;
      },
      {},
    );
    updateColumnsVisibility(newColumnsVisible);
  };

  const { downloadFile } = useDocumentExport();

  const handleDownloadCSV = (pagination, dataOfColumns) => {
    const { search, orderBy, orderType, data } = pagination;

    const isCheckedList = data.some((checkbox) => checkbox.isChecked);
    const isFilter = search || orderType || orderBy || isCheckedList;

    const fileName = getFileName(
      'Q Suite Organizations',
      '',
      user,
      CSV,
      isFilter,
    );
    const body = getExportParams(data, dataOfColumns, {
      search,
      orderBy,
      orderType,
    });
    downloadFile('/export/organizations', body, fileName);
  };

  const [columnsMain, updateColumnsMain] = useState(columnsHead);
  const [columns, updateColumns] = useState(columnsBody);

  const [isOpenExportLogs, updateIsOpenExportLogs] = useState(false);

  const [shadowData, updateShadowData] = useState([]);

  const {
    checkedHead,
    updateCheckedHead,
    checkAllDocuments,
    handleUpdateChecked,
  } = useCheckboxOrganizationTable(pagination, setPagination, updateShadowData);

  useEffect(() => {
    if (!shadowData.length) {
      const params = mergetParams({
        limit: MAX_LIMIT,
        offset: INITIAL_OFFSET,
        search: pagination.search,
      });
      getDataTableColumns('/organizations', params).then(({ data }) => {
        updateShadowData(data);
      });
    }
  }, [pagination.search]);

  const handleClearFilters = () => {
    if (shadowData.length) updateShadowData(getClearFilter);
  };

  const handleToggleLogs = () => {
    if (isOpenExportLogs) handleClearFilters();
    updateIsOpenExportLogs((isOpen) => !isOpen);
  };

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      data: changeDataFromHidden(prev.data, shadowData),
    }));
  }, [shadowData]);

  const tableRef = useTableWidth(updateColumnsMain);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs="12">
            <div className="page-title-box d-flex">
              <h4 className="mb-0 font-size-18">Organizations</h4>
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col xs="12">
            <Card>
              <CardBody>
                <TableHeaderOrganizations
                  title="List of Organizations"
                  handleAddNew={handleToggleModal}
                  downloadCSV={() =>
                    handleDownloadCSV(pagination, columnsVisibility)
                  }
                  manageColumns={handleToggleColumn}
                  handleSearch={debounceHandleUpdate}
                  isOpenExportLogs={isOpenExportLogs}
                  handleOpenExportLogs={handleToggleLogs}
                />
                <TableExportLogsMenu
                  isOpen={isOpenExportLogs}
                  search={pagination.search}
                  shadowData={shadowData}
                  handleClearStatus={() => {
                    if (shadowData.length) updateShadowData(getClearFilter);
                  }}
                  handleChangeStatus={(filteredValue) => {
                    updateShadowData((data) =>
                      changeStatusOrganizations(data, filteredValue),
                    );
                  }}
                />
                <div ref={tableRef} className={classnames('table-wrapper')}>
                  <Table className="table-custom table table-centered table-nowrap table-document">
                    <TableHead
                      columnsHidden={columnsHidden}
                      handleOrder={handleOrder}
                      checkAllDocuments={checkAllDocuments}
                      checkedHead={checkedHead}
                      pagination={pagination}
                      updateCheckedHead={checkedHead}
                      columnsHead={columnsMain}
                      columns={columns}
                      handleChangeColumns={(
                        columns,
                        columnsHead,
                        startIndex,
                        endIndex,
                      ) => {
                        updateColumns(
                          dragReorder(columns, startIndex, endIndex),
                        );
                        updateColumnsMain(
                          dragReorder(columnsHead, startIndex, endIndex),
                        );
                      }}
                      onResizeColumns={(columnIndex, changedWidth) => {
                        updateColumnsMain((columns) =>
                          getChangedResizeColumns(
                            columns,
                            columnIndex,
                            changedWidth,
                          ),
                        );
                      }}
                      onResizeEnd={(columnIndex) => {
                        updateColumnsMain((columns) =>
                          getEndResizeColumns(columns, columnIndex),
                        );
                      }}
                    />
                    <TableBody
                      onDeleteRow={(deletedId) => {
                        setPagination((pagination) =>
                          deleteRow(pagination, deletedId),
                        );
                      }}
                      columnsHidden={columnsHidden}
                      pagination={pagination}
                      setPagination={setPagination}
                      columnsBody={columns}
                      updatingTable={updatingTable}
                      updateCheckedHead={updateCheckedHead}
                      handleUpdateChecked={handleUpdateChecked}
                    />
                  </Table>
                </div>
                {!!pagination.data.length && (
                  <TableFooter
                    limit={pagination.limit}
                    handleChangeLimit={handleChangeLimit}
                    pageCount={pagination.pageCount}
                    pageNumber={pagination.pageNumber}
                    handlePageClick={handlePageClick}
                  />
                )}
                {!(pagination.data.length || isLoading) && (
                  <TablePaginationText text="There are no Documents to display" />
                )}
                {isLoading && <TableSpinner />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <OrganizationCreatingModal
        isOpenModal={isOpenModal}
        handleToogleModal={handleToggleModal}
      />
      <DisplayColumnsModal
        columnsVisibility={columnsVisibility}
        isOpen={displayMoldal.isOpen}
        handleUpdateColumnsVisible={handleUpdateColumnsVisible}
        handleSelectAll={handleSelectAll}
        onCancel={() => setDisplayModal((prev) => ({ ...prev, isOpen: false }))}
        handleSaveColumns={handleSaveColumns}
      />
    </>
  );
};

export default Organization;
