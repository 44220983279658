import React from 'react';

const CellBlock = ({ title, text, classes }) => {
  return (
    <div className={`info-column ${classes ? classes : ''}`}>
      <div>
        <p className="info-column__title">{title}</p>
        <p className="info-column__text">{text}</p>
      </div>
    </div>
  );
};

export default CellBlock;
