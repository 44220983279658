import {
  NOT_FOUND_INDEX,
  VIEW_ASSIGNEE_TASK_ID,
  VIEW_TASK_ID,
} from '../../constants/helper';

export const isMainRadioSelected = (type) => {
  return [VIEW_TASK_ID, VIEW_ASSIGNEE_TASK_ID].includes(type);
};

export const getIndexSetting = (settings, type) => {
  return settings.findIndex((setting) => setting.type === type);
};

export const changeSettings = (settings, indexOfSetting, data) => {
  return indexOfSetting === NOT_FOUND_INDEX
    ? [...settings, data]
    : [
        ...settings.slice(0, indexOfSetting),
        data,
        ...settings.slice(indexOfSetting + 1, settings.length),
      ];
};
