import React from 'react';

const CheckboxSubmit = ({ text, id, isChecked, updateChecked }) => {
  return (
    <div className="checkbox-training">
      <div className="custom-control custom-checkbox custom-checkbox-answer">
        <input
          type="checkbox"
          className="custom-control-input"
          checked={isChecked}
          onChange={() => updateChecked(id)}
          id={`custom-checkbox-submit-${id}`}
        />
        <label
          className="custom-control-label"
          htmlFor={`custom-checkbox-submit-${id}`}
        ></label>
      </div>
      <p>{text}</p>
    </div>
  );
};

export default CheckboxSubmit;
