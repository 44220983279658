import React, { useState, useEffect, useCallback } from 'react';
import { Col, FormGroup, Input, Row, UncontrolledTooltip } from 'reactstrap';
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import Select from 'react-select';
import { unionBy } from 'lodash';

import Index from '../../Button';
import TextCheckbox from '../../shared/TextCheckbox';
import DocumentAttachedFiles from '../../document/AttachedFiles';
import CustomMultiSelect from '../../CustomMultiSelect';

import { getDocumentTypes } from '../../../utils/backend-helper';
import { getCurrentOption } from '../../../utils/documents/find-options';
import { customStyles } from '../../../utils/select-styles';
import {
  configTags,
  configureGetDocumentTypes,
} from '../../../utils/config-get-organization';
import {
  MAX_INPUT_LENGTH,
  MAX_TEXTAREA_LENGTH,
  OPTIONAL_ADD_REVISION_KEYS,
} from '../../../constants/helper';
import { useUserSelect } from '../../../custom-hooks/useUserSelect';
import { findType, isExistType } from '../../../utils/documents/is-exist-type';
import { useDisabledDocument } from '../../../custom-hooks/useDisabledDocument';
import { getUserInputName } from '../../../utils/get-user-label';
import { filterByField } from '../../../utils/training-records-helper/filter-by-id';
import { getFilesIds } from '../../../utils/training-records-helper/get-files-ids';
import { REQUIRED_FIELD } from '../../../constants/form';
import { validateEmptyText } from '../../../constants/validatePassword';
import { getTags } from '../../../utils/api/organization-api';
import { getUnionArrayIds } from '../../../utils/shared/get-union-array';
import DocumentAttached from '../../document/DocumentAttached';

const DocumentAddRevision = ({
  updateRevisionAddMode,
  revisionModel,
  submitter,
  creator,
  organizationId,
  handleSubmit,
  latestRevision,
  model,
  handleModelChange,
  handleChangeChecked,
  updateModel,
}) => {
  const [documentTypeList, updateDocumentTypeList] = useState([]);

  const { type } = revisionModel;

  useEffect(() => {
    getDocumentTypes(organizationId).then(({ data }) => {
      if (type) {
        const currentType = findType(data, type);
        isExistType(currentType?.id) &&
          updateModel((prev) => ({ ...prev, typeId: currentType.id }));
      }
      updateDocumentTypeList(configureGetDocumentTypes(data));
    });
  }, [organizationId, type, updateModel]);

  const [defaultOption, updateDefaultOption] = useState(null);

  const handleDocumentTypeChange = ({ label, id: typeId }) => {
    updateDefaultOption({ label });
    updateModel((prev) => ({ ...prev, typeId }));
  };

  const { usersOption, defaultUser, handleUsersSearch, updateDefaultUser } =
    useUserSelect(organizationId, submitter);

  const handleReviewersChange = ({ label, id: submitterId }) => {
    updateDefaultUser({ label });
    updateModel((prev) => ({ ...prev, submitterId }));
  };

  const [isChecked] = useState(false);

  const { isDisabled } = useDisabledDocument(
    model,
    isChecked,
    OPTIONAL_ADD_REVISION_KEYS,
  );

  const getTagsFromAPI = useCallback(() => {
    return getTags(organizationId);
  }, [organizationId]);

  return (
    <div className="general">
      <div className="general-header">
        <h6>New revision</h6>
      </div>
      <hr />
      <AvForm className="form-horizontal mt-2" onValidSubmit={handleSubmit}>
        <div className="general-info">
          <Row>
            <Col xs="4">
              <div className="info-column">
                <p className="info-column__title">Document type*</p>
                <FormGroup className="select2-container">
                  <Select
                    styles={customStyles}
                    value={
                      defaultOption || getCurrentOption(documentTypeList, type)
                    }
                    options={documentTypeList}
                    onChange={(value) => handleDocumentTypeChange(value)}
                  />
                </FormGroup>
              </div>
            </Col>
            <Col xs="4">
              <div className="info-column">
                <p className="info-column__title">System #</p>
                <div className="form-group">
                  <Input
                    name="number"
                    className="form-control"
                    type="text"
                    value={model.number || ''}
                    onChange={(event) => handleModelChange(event)}
                    disabled
                  />
                </div>
              </div>
            </Col>
            <Col xs="4">
              <div className="info-column">
                <p className="info-column__title info-column_right">
                  Document name*
                </p>
                <div className="form-group">
                  <AvField
                    name="name"
                    className="form-control"
                    placeholder="Enter document name"
                    maxLength={MAX_INPUT_LENGTH}
                    type="text"
                    validate={validateEmptyText}
                    errorMessage={REQUIRED_FIELD}
                    value={model.name}
                    onChange={(event) => handleModelChange(event)}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <p className="info-column__title">Document ID:</p>
                <AvField
                  name="typeDescription"
                  className="form-control"
                  placeholder="Enter Document ID"
                  maxLength={MAX_INPUT_LENGTH}
                  type="text"
                  value={model.typeDescription}
                  onChange={(event) => handleModelChange(event)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <hr />
        <div className="general-revision">
          <div className="info-column">
            <TextCheckbox
              text="Set as Final"
              className="mb-3"
              name="setAsFinal"
              id="tooltip-final"
              isChecked={model.setAsFinal}
              onChange={handleChangeChecked}
            />
            <UncontrolledTooltip
              placement="top"
              id="tooltip-checkbox"
              target="tooltip-final"
            >
              <span>
                Final Revision can be set manually if the current Document does
                not require review and approval
              </span>
            </UncontrolledTooltip>
          </div>
        </div>
        <Row>
          <Col sm="4">
            <div className="info-column">
              <p className="info-column__title">Revision*</p>
              <div className="form-group">
                <AvInput
                  disabled
                  type="text"
                  name="lastRevision"
                  className="form-control"
                  placeholder="Revision number"
                  value={latestRevision + 1}
                  onChange={(event) => handleModelChange(event)}
                />
              </div>
            </div>
          </Col>
          <Col sm="8">
            <div className="info-column">
              <p className="info-column__title">Revision name</p>
              <div className="form-group">
                <AvField
                  type="text"
                  name="revisionName"
                  value={model.revisionName}
                  className="form-control"
                  placeholder="Enter revision name"
                  maxLength={MAX_INPUT_LENGTH}
                  onChange={(event) => handleModelChange(event)}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="info-column">
          <p className="info-column__title">Revision description*</p>
          <div className="form-group">
            <AvField
              type="textarea"
              id="textarea"
              name="revisionDescription"
              placeholder="Enter revision description"
              value={model.revisionDescription || ''}
              onChange={(event) => handleModelChange(event)}
              maxLength={`${MAX_TEXTAREA_LENGTH}`}
              validate={validateEmptyText}
              errorMessage={REQUIRED_FIELD}
              rows="3"
            />
          </div>
        </div>
        <Row>
          <Col>
            <div className="info-column">
              <p className="info-column__title">Tag(s)</p>
              <div className="form-group">
                <CustomMultiSelect
                  handleRequest={getTagsFromAPI}
                  configOptions={configTags}
                  placeholder="Choose tag(s)"
                  onChange={(values) => {
                    updateModel((prev) => ({
                      ...prev,
                      tagIds: getUnionArrayIds(values),
                    }));
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <DocumentAttached
          text="Controlled document*"
          file={model.file}
          handleUploadFile={(data) => {
            updateModel((model) => ({
              ...model,
              file: data,
              fileId: data,
            }));
          }}
          onDeleteFile={() => {
            updateModel((model) => ({
              ...model,
              file: null,
              fileId: null,
            }));
          }}
        />
        <hr />
        <DocumentAttachedFiles
          title="Extra document(s)"
          files={model.extraFileIds}
          onDeleteFile={(id) => {
            updateModel((model) => ({
              ...model,
              extraFileIds: filterByField(model.extraFileIds, id),
            }));
          }}
          updateModel={(body) => {
            updateModel((prev) => ({
              ...prev,
              extraFileIds: [...prev.extraFileIds, body],
            }));
          }}
        />
        <hr />
        <Row>
          <Col xl="12">
            <div className="info-column">
              <p className="info-column__title">Responsible*</p>
              <FormGroup className="select2-container">
                <Select
                  isMulti={false}
                  styles={customStyles}
                  options={usersOption}
                  value={defaultUser}
                  onChange={(value) => handleReviewersChange(value)}
                  onInputChange={(value) => handleUsersSearch(value)}
                  placeholder="Choose"
                />
              </FormGroup>
            </div>
          </Col>
          <Col xl="12">
            <div className="info-column">
              <p className="info-column__title">Created By*</p>
              <div className="form-group">
                <Input
                  name="createdBy"
                  className="form-control"
                  type="text"
                  value={getUserInputName(creator)}
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="form-group">
          <Col className="text-right">
            <Index
              type="button"
              color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
              handler={() => updateRevisionAddMode((prev) => !prev)}
            >
              Cancel
            </Index>
            <Index
              type="submit"
              color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
              disabled={isDisabled}
            >
              Save
            </Index>
          </Col>
        </Row>
        <AvField
          name="organizationId"
          type="text"
          value={organizationId}
          className="btn-hidden"
        />
        <AvField
          name="typeId"
          type="text"
          value={model.typeId || ''}
          className="btn-hidden"
        />
        <AvField
          name="fileId"
          type="text"
          value={model.file?.id}
          className="btn-hidden"
        />
        <AvField
          name="submitterId"
          type="text"
          value={model.submitterId || ''}
          className="btn-hidden"
        />
        <AvField
          name="id"
          type="text"
          value={model.id}
          className="btn-hidden"
        />
        <AvField
          name="setAsFinal"
          type="text"
          value={`${model.setAsFinal}`}
          className="btn-hidden"
        />
        <AvField
          name="reviewerIds"
          type="text"
          value={model.reviewerIds}
          className="btn-hidden"
        />
        <AvField
          name="approverIds"
          type="text"
          value={model.approverIds}
          className="btn-hidden"
        />
        <AvField
          name="requiresTraining"
          type="hidden"
          value={model.requiresTraining}
        />
        <AvField
          name="extraFileIds"
          type="hidden"
          value={getFilesIds(model.extraFileIds)}
        />
        <AvField name="revisionName" type="hidden" value={model.revisionName} />
        <AvField
          name="references"
          type="hidden"
          value={unionBy(model.references, 'link')}
        />
        <AvField type="hidden" name="revision" value={model.revision} />
        <AvField type="hidden" name="tagIds" value={model.tagIds} />
      </AvForm>
    </div>
  );
};

export default DocumentAddRevision;
