import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { CardBody, Card, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import {
  ACCESS_USER_TAB,
  DOCUMENTS_USER_TAB,
  DOCUMENT_USER_TAB,
  TASKS_USER_TAB,
  TRAINING_USER_TAB,
} from '../../../../../constants/helper';

const UserSettingNavigation = ({
  redirectBack,
  activeTab,
  updateActiveTab,
}) => {
  const navTabs = useMemo(() => {
    return [
      {
        tab: ACCESS_USER_TAB,
        text: 'Access permissions',
      },
      {
        tab: DOCUMENTS_USER_TAB,
        text: 'Permissions for Documents',
      },
      {
        tab: TASKS_USER_TAB,
        text: 'Permissions for Actions',
      },
      {
        tab: TRAINING_USER_TAB,
        text: 'Permissions for Training Records',
      },
      {
        tab: DOCUMENT_USER_TAB,
        text: 'Permissions for Organization Settings',
      },
    ];
  }, []);

  const toggleTabs = (tab) => {
    if (tab !== activeTab) {
      updateActiveTab(tab);
    }
  };

  return (
    <Card>
      <CardBody>
        <Link className="nav-back" to={redirectBack}>
          <i className="bx bx-chevron-left arrow"></i> Back
        </Link>
        <hr className="nav-line" />
        <ul
          className="nav nav-tabs nav-tabs-setting nav-custom-tabs"
          role="tablist"
        >
          {navTabs.map(({ tab, text }) => (
            <NavItem key={tab}>
              <NavLink
                className={classnames({ active: activeTab === tab })}
                onClick={() => toggleTabs(tab)}
              >
                {text}
              </NavLink>
            </NavItem>
          ))}
        </ul>
      </CardBody>
    </Card>
  );
};

export default UserSettingNavigation;
