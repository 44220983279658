export const dataUrlToFile = (dataUrl, fileName = 'file.png') => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

export const getExtension = (file) => {
  return file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
};

export const fileToDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

export const canvasToBlob = (cropper) => {
  return new Promise((resolve, reject) => {
    const blob = cropper.getCroppedCanvas().toDataURL();

    const croppedImage = new Image();
    const fileBlob = dataUrlToFile(blob);
    croppedImage.src = URL.createObjectURL(fileBlob);

    croppedImage.onload = () => {
      const body = new FormData();
      body.append('file', fileBlob);
      resolve(body);
    };

    croppedImage.onerror = reject;
  });
};
