export const getChangedResizeColumns = (columns, columnIndex, changedWidth) => {
  return columns.map((column, index) => {
    if (columnIndex === index) {
      return {
        ...column,
        width: column.savedWidth + changedWidth,
      };
    }
    return column;
  });
};

export const getEndResizeColumns = (columns, columnIndex) => {
  return columns.map((column, index) => {
    if (columnIndex === index) {
      return {
        ...column,
        savedWidth: column.width,
      };
    }
    return column;
  });
};
