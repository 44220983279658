import { isUserRole } from './shared/is-user-role';

const HUMAN_RESOURCE = 'human-resources';

export const isUrlString = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const isHumanResourcesUrl = (string) => {
  return string.indexOf(HUMAN_RESOURCE) !== -1;
};

export const isUrlForLinkRecord = (link, role) => {
  console.log(link, role);
  return isUrlString(link) && !(isUserRole(role) && isHumanResourcesUrl(link));
};
