export const emptyFieldsRevision = {
  fileId: null,
  revisionDescription: '',
};

export const CHECKED_TRUE = true;
export const CHECKED_FALSE = false;

export const INITIAL_LINK = 0;
export const INITIAL_OTHER_LINK = 10000;

export const DOCUMENTS_TYPE = 0;
export const ACTIONS_TYPE = 1;
export const TRAINING_RECORDS_TYPE = 2;
export const HR_RECORDS_TYPE = 3;
