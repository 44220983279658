export const MIN_LENGTH = 8;
export const MIN_LENGTH_ERROR = 'Your password must be more than 8 symbols';

export const MAX_LENGTH = 50;
export const MAX_LENGTH_ERROR = 'Your password must be less than 50 symbols';

export const PASSWORD_VALIDATION =
  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])([a-zA-Z0-9!@#$%^&*()]+){8,50}$';
export const PASSWORD_VALIDATION_ERROR =
  'Password should contain at least one letter, one digit, and one capital letter';

export const PASSWORD_MATCH_ERROR = "Password doesn't match";

export const NUMBER_VALUDATION = '^[1-9][0-9]+$';
export const NUMBER_VALIDATION_ERROR = 'Only positive numbers';

export const REQUIRED_FIELD = 'This field is required';
