import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { FIRST_RADIO, SECOND_RADIO } from '../../../../constants/helper';
import { changeChacked } from '../../../../utils/shared/change-checked';

import TextCheckbox from '../../../shared/TextCheckbox';
import TextRadio from '../../../shared/TextRadio';
import RequiresTrainingIcon from './Icon';
import RequiresTrainingText from './Text/Text';

const RequiresTrainingEdit = ({ isChecked, handleChecked, disabled }) => {
  const [radios, updateRadios] = useState([false, false]);

  const handleCheckedRadio = (radioIndex) => {
    updateRadios((radios) => changeChacked(radios, radioIndex));
  };

  return (
    <div>
      <Row>
        <Col className="d-flex align-items-center">
          <TextCheckbox
            id="custom-radio-tooltip"
            isChecked={isChecked}
            onChange={handleChecked}
            text="Requires Training"
          />
          <RequiresTrainingIcon />
        </Col>
      </Row>
      {isChecked && (
        <Row>
          <Col>
            <hr />
            <div className="radio-container">
              <TextRadio
                disabled={disabled}
                name="training"
                text="Link Training"
                isChecked={radios[FIRST_RADIO]}
                onChange={() => handleCheckedRadio(FIRST_RADIO)}
              />
              <TextRadio
                disabled={disabled}
                name="training"
                text={<RequiresTrainingText />}
                isChecked={radios[SECOND_RADIO]}
                onChange={() => handleCheckedRadio(SECOND_RADIO)}
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default RequiresTrainingEdit;
