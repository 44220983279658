import React from 'react';
import { Col, Row } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { MAX_INPUT_LENGTH } from '../../../constants/helper';

const TaskExternalLink = ({
  extraLinkDescription,
  extraLink,
  handleModelChange,
}) => {
  return (
    <div className="info-column mt-3">
      <p className="info-column__title">External Link</p>
      <Row>
        <Col xs="12" md="4">
          <AvField
            type="text"
            name="extraLinkDescription"
            placeholder="Enter link name here"
            maxLength={`${MAX_INPUT_LENGTH}`}
            onChange={(event) => handleModelChange(event)}
            value={extraLinkDescription}
          />
        </Col>
        <Col xs="12" md="8">
          <AvField
            type="text"
            name="extraLink"
            placeholder="Enter link address here"
            onChange={(event) => handleModelChange(event)}
            value={extraLink}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TaskExternalLink;
