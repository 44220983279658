/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { ACTIVE } from '../../../constants/modals';
import { getClientOrganizations } from '../../../utils/backend-helper';

const OrganizationUsers = ({ user }) => {
  const history = useHistory();

  const [organizations, updateOrganizations] = useState([]);

  const handleUpdateOrganizations = () => {
    getClientOrganizations().then((result) => {
      const filterData = result.data.filter((item) => item.status === ACTIVE);
      updateOrganizations(filterData);
    });
  };

  useEffect(() => {
    handleUpdateOrganizations();
  }, []);

  const handleRedirectOrganization = (organizationId) => {
    history.push(`/organizations/${organizationId}/dashboard`);
  };

  return (
    <Container>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-flex">
            <h4 className="mb-0 font-size-18">
              Select an organization to Login
            </h4>
          </div>
        </Col>
      </Row>
      <Row>
        {organizations.map(({ id, name }) => {
          return (
            <Col key={id} sm="6" md='4' className="mt-3">
              <div
                className="client-organization-card"
                onClick={() => handleRedirectOrganization(id, user)}
              >
                <p className="client-organization-card__name mb-0">{name}</p>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default OrganizationUsers;
