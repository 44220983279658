export const customStyles = {
  option: (styles, state) => {
    const { isSelected, isFocused } = state;
    return {
      ...styles,
      color: '#000000',
      backgroundColor: isSelected ? '#B2D4FF' : isFocused ? '#B2D4FF' : null,
      fontFamily: 'Poppins',
      fontWeight: '500',
    };
  },

  control: (styles, { isDisabled }) => ({
    ...styles,
    borderColor: isDisabled ? 'rgba(73, 80, 87, 0.2)' : 'hsl(0,0%,80%)',
    backgroundColor: isDisabled ? 'transparent' : 'transparent',
    color: isDisabled ? 'rgba(73, 80, 87, 0.2)' : '#495057',
  }),

  placeholder: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? 'rgba(73, 80, 87, 0.2)' : '#495057',
  }),
};

export const qualityStyles = {
  option: (styles, state) => {
    return {
      ...styles,
      color: '#000000',
      fontFamily: 'Poppins',
      fontWeight: '500',
    };
  },
};
