import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Col, FormGroup, Row, UncontrolledTooltip } from 'reactstrap';
import AsyncSelect from 'react-select/async';

import { customStyles } from '../../../../utils/select-styles';
import { useTrainingAsyncSelect } from '../../../../custom-hooks/useTrainingAsyncSelect';
import Button from '../../../../components/Button';
import {
  DOCUMENT_CREATE_MODE,
  FIRST_RADIO,
  SECOND_RADIO,
} from '../../../../constants/helper';

const RequiresTraining = ({
  mode,
  isChecked,
  updateIsChecked,
  model,
  updateModel,
  handleSubmit,
  latestRevision,
  trainingRecord,
}) => {
  const history = useHistory();
  const { id: organizationId, documentId } = useRouteMatch().params;

  const [radios, updateRadios] = useState([false, false]);

  const handleUpdateRadio = (index) => {
    updateRadios((prev) =>
      prev.map((isChecked, i) => (index === i ? !isChecked : false)),
    );
  };

  const [existingTraining, updateExistingTraining] = useState(null);

  useEffect(() => {
    if (trainingRecord) {
      updateExistingTraining(trainingRecord);
      updateIsChecked(true);
      updateModel((prev) => ({
        ...prev,
        trainingRecordId: trainingRecord.id,
      }));
    }
  }, [trainingRecord, updateIsChecked, updateModel]);

  const { trainingRecordId, debounceTraining, handleTrainingChange } =
    useTrainingAsyncSelect(organizationId);

  useEffect(() => {
    const checkTraining = !isChecked || !radios[FIRST_RADIO];
    if (trainingRecordId) {
      updateModel((prev) => ({
        ...prev,
        trainingRecordId: checkTraining ? '' : trainingRecordId,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked, trainingRecordId, radios]);

  const handleRedirect = (event, mode, organizationId, model, id) => {
    if (mode === DOCUMENT_CREATE_MODE) {
      handleSubmit(event, { ...model, organizationId }, true);
      return;
    }
    history.push({
      pathname: `/organizations/${organizationId}/training-records/create`,
      state: {
        linkToRecord: `/organizations/${organizationId}/documents/${id}`,
        documentInfo: { isEdit: true },
        document: { id, revision: latestRevision },
      },
    });
  };

  const handleDeleteExistingTraining = () => {
    updateExistingTraining(null);
    updateModel((prev) => ({
      ...prev,
      trainingRecordId: '',
    }));
  };

  return (
    <>
      <Row>
        <Col className="d-flex align-items-center">
          <div
            className="custom-control custom-checkbox"
            id="custom-checkbox-tooltip"
          >
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => updateIsChecked((isChecked) => !isChecked)}
              className="custom-control-input"
              id="custom-checkbox"
            />
            <label
              className="custom-control-label"
              htmlFor="custom-checkbox"
            ></label>
            <span className="requires-text">Requires Training</span>
          </div>
          <UncontrolledTooltip
            placement="top"
            id="tooltip-document"
            target="custom-checkbox-tooltip"
          >
            <p className="mb-0">
              When the current document revision is approved, the Responsible
              user will be sent a reminder to link a training
            </p>
          </UncontrolledTooltip>
          <i
            className="bx bx-info-circle info-tooltip"
            id="requires-training-info"
          ></i>
          <UncontrolledTooltip
            placement="top"
            id="tooltip-document"
            target="requires-training-info"
          >
            <p className="mb-0">
              You will open the screen to create a Training that will be linked
              to the current Document
            </p>
          </UncontrolledTooltip>
        </Col>
      </Row>
      {isChecked && existingTraining && (
        <Row className="mt-3 d-flex">
          <Col>
            <p>{trainingRecord?.name}</p>
          </Col>
          <Col className="d-flex justify-content-end">
            <i
              className="bx bxs-trash-alt delete-training-icon"
              onClick={handleDeleteExistingTraining}
            ></i>
          </Col>
        </Row>
      )}
      {isChecked && !existingTraining && (
        <Row>
          <Col>
            <hr />
            <div className="radio-container">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="training"
                  id="exampleRadios2"
                  onChange={() => handleUpdateRadio(FIRST_RADIO)}
                  checked={radios[FIRST_RADIO]}
                />
                <span>Link Training</span>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="training"
                  id="exampleRadios2"
                  onChange={() => handleUpdateRadio(SECOND_RADIO)}
                  checked={radios[SECOND_RADIO]}
                />
                <span>
                  Create & Link Training
                  <i
                    id="Tooltip-accordion-link"
                    className="bx bx-info-circle info-tooltip"
                  ></i>
                </span>
                <UncontrolledTooltip
                  placement="top"
                  id="tooltip-document"
                  target="Tooltip-accordion-link"
                >
                  <p className="mb-0">
                    You will open the screen to create a Training that will be
                    linked to the current Document
                  </p>
                </UncontrolledTooltip>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {isChecked && radios[FIRST_RADIO] && (
        <Row className="mt-4">
          <Col>
            <div className="info-column">
              <p className="info-column__title">Training record(s)</p>
              <FormGroup className="select2-container">
                <AsyncSelect
                  styles={customStyles}
                  isMulti={false}
                  cacheOptions={false}
                  defaultOptions
                  onChange={(value) => handleTrainingChange(value)}
                  loadOptions={debounceTraining}
                  placeholder="Choose training record"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
      )}
      {isChecked && radios[SECOND_RADIO] && (
        <Row className="mt-4">
          <Col>
            <Button
              type="button"
              color="btn btn-training w-md waves-effect waves-light mr-3 pl-4 pr-4"
              handler={(event) =>
                handleRedirect(event, mode, organizationId, model, documentId)
              }
            >
              Go to creating
              <i className="bx bx-right-arrow-alt"></i>
            </Button>
          </Col>
        </Row>
      )}
      <hr />
    </>
  );
};

export default RequiresTraining;
