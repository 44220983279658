import React from 'react';

const SwitchSetting = ({
  id,
  organizationId,
  handleChecked,
  handleUpdateRadio,
  DOCUMENTS_ID,
  isDisabled,
  subOptions,
}) => {
  return (
    <input
      className="form-check-input"
      type="radio"
      id="exampleRadios2"
      onChange={() => {}}
      onClick={(event) =>
        handleUpdateRadio(event, id, organizationId, DOCUMENTS_ID, subOptions)
      }
      checked={handleChecked(DOCUMENTS_ID)}
      disabled={isDisabled}
    />
  );
};

export default SwitchSetting;
