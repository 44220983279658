import React, { useCallback, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';

import SingleDisabledSelect from '../../../selects/SingleDisabled';

import { isMoreOneLinks } from '../../../../utils/documents/is-more-one-links';
import { customStyles } from '../../../../utils/select-styles';
import { getDataUser } from '../../../../utils/get-store-user';
import { USER_ROLE } from '../../../../constants/helper';
import { getApiForReferences } from '../../../../utils/documents/get-api-for-references';
import {
  ACTIONS_TYPE,
  TRAINING_RECORDS_TYPE,
  HR_RECORDS_TYPE,
} from '../../../../constants/document';
import {
  apiTypesOption,
  subLinks,
} from '../../../../utils/documents/getConfigOptions';

const AddOtherReferences = ({
  id,
  links,
  handleDelete,
  handleUpdateReferences,
}) => {
  const { id: organizationId } = useRouteMatch().params;
  const { role } = getDataUser();

  const typesOptions = useMemo(() => {
    const humanResourceOption =
      role !== USER_ROLE
        ? [{ value: HR_RECORDS_TYPE, label: 'HR Record' }]
        : [];
    return [
      { value: ACTIONS_TYPE, label: 'Actions' },
      { value: TRAINING_RECORDS_TYPE, label: 'Training Record' },
      ...humanResourceOption,
    ];
  }, [role]);

  const [currentType, updateCurrentType] = useState(null);

  const configOptions = useMemo(() => {
    return apiTypesOption[currentType];
  }, [currentType]);

  const getSubLink = useMemo(() => {
    return subLinks[currentType];
  }, [currentType]);

  const getDataFromAPI = useCallback(
    async (search = '') => {
      if (!currentType) return;
      return getApiForReferences(currentType, organizationId, search);
    },
    [currentType, organizationId],
  );

  return (
    <Row className="mb-3">
      <Col sm="4">
        <Select
          placeholder="Choose type"
          styles={customStyles}
          isClearable={true}
          options={typesOptions}
          onChange={(value) => updateCurrentType(value?.value)}
        />
      </Col>
      <Col sm="8" className="d-flex align-items-center">
        <SingleDisabledSelect
          handleRequest={getDataFromAPI}
          configOptions={configOptions}
          documentTypeId={currentType}
          isDisabled={!currentType}
          className="w-100 mr-2"
          placeholder="Choose link"
          handleChange={(values) =>
            handleUpdateReferences(values, getSubLink, id)
          }
        />
        {isMoreOneLinks(links) ? (
          <i className="bx bxs-trash-alt" onClick={() => handleDelete(id)}></i>
        ) : (
          <i className="bx bxs-trash-alt trash-disabled"></i>
        )}
      </Col>
    </Row>
  );
};

export default AddOtherReferences;
