import React from 'react';
import { SECTION_TYPE } from '../../../constants/table';

export const globalSearchColumns = [
  {
    field: 'type',
    headerName: 'Type',
    sortable: true,
    classes: 'global-column',
    widthPercent: 15,
    valueGetter: (params) => {
      return SECTION_TYPE[params] || '';
    },
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: true,
    classes: 'global-column',
    widthPercent: 70,
    RenderCell: ({ text, params }) => (
      <p>
        <a href={params.link} target="_self" className="d-table-link">
          {text}
        </a>
      </p>
    ),
  },
  {
    field: 'link',
    headerName: 'Options',
    sortable: false,
    classes: 'global-column',
    widthPercent: 15,
    RenderCell: ({ text }) => (
      <p>
        <a href={text} target="_self">
          <i className="bx bx-show action"></i>
        </a>
      </p>
    ),
  },
];
