import {
  ADMINS,
  SUCCESS_ADMINS,
  CREATE_ADMIN,
  CREATE_ADMIN_SUCCESS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  CLEAR_IMAGE_SUCCESS,
  // SUCCESS_CREATE_ORGANIZATION,
  // DELETE_ORGANIZATION,
  // DELETE_ORGANIZATION_SUCCESS,
} from './actionTypes';

const initialState = {
  admins: null,
  creatingSuccess: null,
  imageSuccress: null,
  // deletingSuccess: null,
};

const admins = (state = initialState, action) => {
  switch (action.type) {
    case ADMINS:
      state = { ...state, creatingSuccess: null };
      break;
    case SUCCESS_ADMINS:
      state = { ...state, admins: action.payload };
      break;
    case CREATE_ADMIN:
      state = { ...state, creatingSuccess: null };
      break;
    case CREATE_ADMIN_SUCCESS:
      state = { ...state, creatingSuccess: true };
      break;
    case UPLOAD_IMAGE:
      state = { ...state, imageSuccress: null };
      break;
    case UPLOAD_IMAGE_SUCCESS:
      state = { ...state, imageSuccress: true };
      break;
    case CLEAR_IMAGE_SUCCESS:
      state = { ...state, imageSuccress: null };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default admins;
