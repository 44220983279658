import React from 'react';
import { Card, CardBody, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const HelpNavigation = ({ navList, activeTab, onChangeTab }) => {
  return (
    <Card>
      <CardBody>
        <ul
          className="nav nav-tabs nav-tabs-setting nav-custom-tabs"
          role="tablist"
        >
          {navList.map(({ title, tabId }) => {
            return (
              <NavItem key={tabId}>
                <NavLink
                  className={classnames({ active: activeTab === tabId })}
                  onClick={() => onChangeTab(tabId)}
                >
                  {title}
                </NavLink>
              </NavItem>
            );
          })}
        </ul>
      </CardBody>
    </Card>
  );
};

export default HelpNavigation;
