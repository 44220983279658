import {
  ACTIONS_TYPE,
  TRAINING_RECORDS_TYPE,
  HR_RECORDS_TYPE,
} from '../../constants/document';
import { getHumanResourcesAvailable } from '../api/human-resources-api';
import { getTasksAvailable } from '../api/task-api';
import { getAllTrainingRecords } from '../api/training-records-api';
import {
  getParamsForActions,
  getParamsForTraining,
  getParmasForHumanResources,
} from '../training-records-helper/get-params-for-training';

const ApiList = {
  [ACTIONS_TYPE]: {
    params: getParamsForActions,
    api: getTasksAvailable,
  },
  [TRAINING_RECORDS_TYPE]: {
    params: getParamsForTraining,
    api: getAllTrainingRecords,
  },
  [HR_RECORDS_TYPE]: {
    params: getParmasForHumanResources,
    api: getHumanResourcesAvailable,
  },
};

export const getApiForReferences = (type, organizationId, search) => {
  const { api, params } = ApiList[type];
  return api(params({ organizationId, search })).then(({ data }) => data);
};
