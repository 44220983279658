import React from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';

const BlueButton = ({ className, children, ...otherProps }) => {
  return (
    <Button className={classnames('button_blue', className)} {...otherProps}>
      {children}
    </Button>
  );
};

export default BlueButton;
