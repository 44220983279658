import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Media,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';

import UserNotification from '../../../pages/Authentication/Profile/UserNotification';
import UserProfileImage from '../../../pages/Authentication/Profile/UserImage';
import BlueButton from '../../shared/BlueButton';

import { isPersonNameLength } from '../../../utils/admin-name-helper';
import { getDataUser } from '../../../utils/get-store-user';
import { SUPERADMIN_ROLE } from '../../../constants/helper';

const ProfileCard = ({ handleUpdateUser }) => {
  const history = useHistory();

  const user = getDataUser();
  const { avatar, firstName, lastName, role, email } = user;

  return (
    <Row>
      <Col lg="12">
        <Card>
          <CardBody>
            {role === SUPERADMIN_ROLE ? (
              <Media body className="align-self-center">
                <Row>
                  <Col sm="12" md="6" className="d-flex align-self-center">
                    <div className="text-muted">
                      <h5>Medavice Super Admin</h5>
                      <p className="mb-1">{email}</p>
                    </div>
                  </Col>

                  <Col
                    sm="12"
                    md="6"
                    className="d-flex align-items-center justify-content-end"
                  >
                    <BlueButton
                      className="ml-4"
                      onClick={() => history.push('/Profile/edit')}
                    >
                      Edit profile
                    </BlueButton>
                  </Col>
                </Row>
              </Media>
            ) : (
              <Media body>
                <Row>
                  <Col sm="12" md="6" className="d-flex align-self-center">
                    <UserProfileImage avatar={avatar} />
                    <div className="text-muted ml-3 d-flex flex-column justify-content-center">
                      <h5 id="Tooltip-name">
                        {firstName} {lastName}
                      </h5>
                      {isPersonNameLength(firstName, lastName) && (
                        <UncontrolledTooltip
                          placement="top"
                          id="tooltip-text"
                          target="Tooltip-name"
                        >
                          <p className="mb-0">
                            {firstName} {lastName}
                          </p>
                        </UncontrolledTooltip>
                      )}
                      <p className="mb-1">{email}</p>
                    </div>
                  </Col>
                  <Col
                    sm="12"
                    md="6"
                    className="d-flex align-items-center justify-content-end"
                  >
                    <UserNotification
                      user={user}
                      handleUpdateUser={handleUpdateUser}
                    />
                    <BlueButton
                      className="ml-4"
                      onClick={() => history.push('/Profile/edit')}
                    >
                      Edit profile
                    </BlueButton>
                  </Col>
                </Row>
              </Media>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ProfileCard;
