import React, { useState } from 'react';
import PlaceholderText from '../../../../../components/shared/PlaceholderText';

import PassedTrainingModal from '../../../../../modals/PassedTraining';

const PassedTrainingList = ({ passedTrainingUsers }) => {
  const [passedUser, updatePassedUser] = useState({
    isOpen: false,
    values: {},
  });

  const handleOpenDetails = (values) => {
    updatePassedUser({
      isOpen: true,
      values,
    });
  };

  return (
    <div className="completion-section">
      <h6 className="completion-section__title">Passed Training:</h6>
      <ul className="completion-list">
        {passedTrainingUsers.map(
          ({
            user,
            link,
            number,
            name,
            dueDate,
            createdAt,
            description,
            files,
            questions,
            linkDescription,
          }, index, arr) => {
            const { id, firstName, lastName, email, role } = user;
            return (
              <li key={id}>
                <div className="completion-item-container">
                  <p className='mb-0'>
                    {firstName} {lastName} - {email} {role ? `-${role}` : ''}
                  </p>
                  <span
                    onClick={() =>
                      handleOpenDetails({
                        number,
                        name,
                        dueDate,
                        createdAt,
                        description,
                        link,
                        files,
                        questions,
                        linkDescription,
                      })
                    }
                  >
                    Show details
                  </span>
                </div>
                {arr.length - 1 !== index && <hr />}
              </li>
            );
          },
        )}
      </ul>
      {!passedTrainingUsers?.length && (
        <PlaceholderText text="No assignees have passed the training." />
      )}
      <hr />
      <PassedTrainingModal
        isOpen={passedUser.isOpen}
        onCancel={() =>
          updatePassedUser((prev) => ({ ...prev, isOpen: false }))
        }
        values={passedUser.values}
      />
    </div>
  );
};

export default PassedTrainingList;
