/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Row, Col, Modal, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';

import { getUserRole } from '../../pipes/getStatisPipe';
import { changeFieldSelect } from '../../utils/selector-helper-changed';
import ModalInner from '../../components/shared/ModalInner';
import { isActive } from '../../utils/active-modal-helper';

const AdminActiveModal = ({
  values,
  onSubmit,
  onCancel,
  isOpen,
  status,
  organizationId,
  selectOptions,
  user = 'Admin',
}) => {
  const [organizationIds, setOrganizationIds] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setOrganizationIds([organizationId]);
    }
  }, [isOpen]);

  const handleOrganizationChange = (organizations) => {
    setOrganizationIds(changeFieldSelect(organizations));
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <ModalInner
        title={`Set ${getUserRole(user)} ${
          isActive(status) ? 'Inactive' : 'Active'
        }`}
        onCancel={onCancel}
      >
        <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
          {selectOptions.length > 1 ? (
            <div className="modal-body">
              <p className="text-active">
                The {getUserRole(user)}{' '}
                <b>
                  {`${values.firstName} ${values.lastName} (${values.email})` ||
                    ''}
                </b>
                &nbsp; is included into more than one Organization. Please,
                select in which Organization(s) they should be set&nbsp;
                {isActive(status) ? 'Inactive' : 'Active'}.
              </p>
              <p className="text-active">
                {isActive(status)
                  ? `The ${getUserRole(
                      user,
                    )} will not be able to access a selected Organization.`
                  : `The ${getUserRole(
                      user,
                    )} will be able to access a selected Organization.`}
              </p>
              <FormGroup className="select2-container">
                <label className="control-label">Select Organization(s)</label>
                <Select
                  isMulti
                  defaultValue={selectOptions[0]}
                  options={selectOptions}
                  onChange={(value) => handleOrganizationChange(value)}
                  placeholder="Search by organization"
                />
              </FormGroup>
            </div>
          ) : (
            <div className="modal-body">
              <p className="text-exist">
                Are you sure you want to set the {getUserRole(user)}{' '}
                <b>
                  {' '}
                  {values.firstName} {values.lastName} ({values.email}){' '}
                </b>{' '}
                {isActive(status) ? 'Inactive' : 'Active'}?
              </p>
              <p className="text-exist">
                {isActive(status)
                  ? `The ${getUserRole(
                      user,
                    )} will not be able to access the Organization upon deactivation.`
                  : `The ${getUserRole(
                      user,
                    )} will be able to access the Organization upon activation.`}
              </p>
            </div>
          )}
          <div className="modal-footer">
            <Row className="form-group">
              <Col className="text-right">
                <button
                  disabled={!organizationIds.length}
                  className={`btn mr-3 w-md waves-effect waves-light ${
                    isActive(status) ? 'btn-deactive' : 'btn-primary'
                  }`}
                  type="submit"
                >
                  {isActive(status) ? 'Set Inactive' : 'Set Active'}
                </button>
                <button
                  type="button"
                  className="btn btn-light w-md waves-effect waves-light mr-3"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </div>
          <AvField
            name="id"
            type="string"
            value={values.id}
            className="btn-hidden"
          />
          <AvField
            name="routeId"
            type="string"
            value={organizationIds}
            className="btn-hidden"
          />
        </AvForm>
      </ModalInner>
    </Modal>
  );
};

export default AdminActiveModal;
