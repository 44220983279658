/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import Button from '../../../../components/Button';
import MessageResetSuccess from '../../../../components/ToastSuccess';

import {
  changeUserInfoEmail,
  resendInvite,
  getProfile,
} from '../../../../utils/backend-helper';
import {
  SUCCESS_EMAIL_UPDATE,
  SUCCESS_RESEND_EMAIL,
} from '../../../../constants/messages';
import { getDataUser } from '../../../../utils/get-store-user';

const ChangeEmailProfile = ({ handleUpdateUser }) => {
  const { id, newEmail, email: currentEmail } = getDataUser();

  const [email, updateEmail] = useState(newEmail);

  useEffect(() => {
    updateEmail(newEmail);
  }, [newEmail]);

  const handleUpdateEmail = (text) => updateEmail(text);

  const handleValidSubmit = (_, { id, email }) => {
    changeUserInfoEmail(id, email).then(({ data }) => {
      toast.success(<MessageResetSuccess message={SUCCESS_EMAIL_UPDATE} />);
      handleUpdateUser(data);
    });
  };

  const handleResendLink = () => {
    resendInvite(currentEmail).then(() =>
      toast.success(<MessageResetSuccess message={SUCCESS_RESEND_EMAIL} />),
    );
  };

  useEffect(() => {
    getProfile().then(({ data }) => handleUpdateUser(data));
  }, []);

  return (
    <>
      <AvForm
        className="form-horizontal"
        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
      >
        <div className="form-group">
          <div className="input-wrapper">
            <AvField
              name="email"
              type="email"
              label="Email"
              className="form-control"
              placeholder="Enter your new email"
              value={email}
              onChange={(e) => handleUpdateEmail(e.target.value)}
            />
            {newEmail && (
              <>
                <i
                  className="bx bxs-error-circle notification-email"
                  id="tooltip-profile-email"
                ></i>
                <UncontrolledTooltip
                  placement="top"
                  id="tooltip-profile"
                  target="tooltip-profile-email"
                >
                  <p className="mb-1 tooltip-title">Email not confirmed!</p>
                  <p className="mb-0">
                    Please check your updated email address for instructions to
                    verify and login.
                  </p>
                </UncontrolledTooltip>
              </>
            )}
          </div>
        </div>
        <Row className="form-group">
          <Col className="text-right">
            {newEmail && (
              <Button
                type="button"
                handler={(e, v) => handleResendLink(e, v)}
                color="btn btn-light w-md waves-effect waves-light mr-3"
              >
                Resend Link
              </Button>
            )}
            <button
              disabled={!email}
              className="btn btn-submit waves-effect waves-light"
              type="submit"
            >
              Save Changes
            </button>
          </Col>
        </Row>
        <AvField name="id" type="text" value={id} className="btn-hidden" />
      </AvForm>
    </>
  );
};

export default ChangeEmailProfile;
