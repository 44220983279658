import React from 'react';

import { DEFAULT_PAGINATION_LIMIT } from '../../../constants/helper';
import { SELECT_OPTIONS } from '../../../constants/table';

const PaginationSelect = ({ limit, handleChangeLimit }) => {
  return (
    <div className="select-wrapper">
      <select
        value={limit}
        className="custom-select pagination-select"
        onChange={(event) => handleChangeLimit(Number(event.target.value))}
      >
        {SELECT_OPTIONS.map((item) => (
          <option
            key={item}
            value={item}
            defaultValue={item === DEFAULT_PAGINATION_LIMIT}
          >
            {item}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PaginationSelect;
