import { useMemo } from 'react';

import { TRAINING_RECORD_KEY } from '../constants/helper';
import { isFindKey } from '../utils/documents/find-options';

export const useDisabledDocument = (
  model = {},
  isChecked = false,
  optionalKeys = [],
) => {
  const isDisabled = useMemo(() => {
    let optionals = optionalKeys;
    if (!isChecked) {
      optionals = [...optionalKeys, TRAINING_RECORD_KEY];
    }

    return !Object.entries(model).every(([key, value]) => {
      return isFindKey(optionals, key) || value || value === 0;
    });
  }, [model, isChecked, optionalKeys]);

  return { isDisabled };
};
