import { ACTIVE, INACTIVE } from '../../constants/modals';
import {
  ALL_ACTIVE_ORGANIZATIONS,
  ALL_INACTIVE_ORGANIZATIONS,
  ALL_ORGANIZATIONS,
} from '../../constants/table';

export const changeStatusOrganizations = (data, filteredValue) => {
  if (filteredValue === ALL_ORGANIZATIONS) {
    return data.map((item) => ({ ...item, isChecked: true }));
  }

  if (filteredValue === ALL_ACTIVE_ORGANIZATIONS) {
    return data.map((item) => ({ ...item, isChecked: item.status === ACTIVE }));
  }

  if (filteredValue === ALL_INACTIVE_ORGANIZATIONS) {
    return data.map((item) => ({
      ...item,
      isChecked: item.status === INACTIVE,
    }));
  }

  return data;
};
