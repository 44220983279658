import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment';

import TableSpinner from '../../../tables/Spinner';
import NoDataPlaceholder from '../../shared/NoDataPlaceholder';

import { DUE_DATE_DASHBOARD } from '../../../../constants/helper';
import { useSafeUpdateState } from '../../../../custom-hooks/useSafeUpdateState';
import { getTypeDescription } from '../../../../utils/dashboard-helper/get-type-description';

const DashboardTab = ({ getUsersCallback, getUsersAPI }) => {
  const history = useHistory();
  const { id: organizationId } = useRouteMatch().params;

  const [data, updateData] = useState([]);
  const [isLoading, updateIsLoading] = useState(false);

  const safeUpdateState = useSafeUpdateState(updateData);

  useEffect(() => {
    updateIsLoading(true);
    getUsersCallback(getUsersAPI)
      .then((data) => safeUpdateState(data))
      .finally(() => updateIsLoading(false));
  }, [getUsersCallback, getUsersAPI, safeUpdateState]);

  if (isLoading) {
    return <TableSpinner />;
  }

  if (!isLoading && !data.length) {
    return <NoDataPlaceholder title="No documents yet" />;
  }

  return (
    <ul>
      {data.map((item, index) => (
        <li key={item.dueDate}>
          <div
            className="d-flex justify-content-between align-items-center dashboard-item"
            onClick={() =>
              history.push(
                `/organizations/${organizationId}/documents/${item.id}/${
                  item.revision ? `?revision=${item.revision}` : ''
                }`,
              )
            }
          >
            <p className="mb-0">{getTypeDescription(item)}</p>
            <p className="mb-0">
              {moment(item.dueDate).format(DUE_DATE_DASHBOARD)}
            </p>
          </div>
          {data.length - 1 !== index && <hr />}
        </li>
      ))}
    </ul>
  );
};

export default DashboardTab;
