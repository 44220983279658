import { ASC, DESC } from '../../constants/table';

export const getStyleTableHead = (isHidden, name, orderBy, orderType) => {
  return [
    { 'column-hidden': !isHidden },
    { 'sorting-asc': name === orderBy && orderType === ASC },
    { 'sorting-desc': name === orderBy && orderType === DESC },
  ];
};

export const getStyleTableGlobal = (field, orderBy, orderType) => {
  return [
    { 'sorting-asc': field === orderBy && orderType === ASC },
    { 'sorting-desc': field === orderBy && orderType === DESC },
  ];
};
