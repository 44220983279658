import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { debounce } from 'lodash';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/shared/Breadcrumbs/Breadcrumbs';
import {
  ALL,
  INITIAL_OFFSET,
  INITIAL_PAGE,
  LIMIT,
  PAGE_COUNT,
  TOTAL_ITEMS,
} from '../../../constants/table';
import { getDataTableColumns } from '../../../utils/backend-helper';
import { mergetParams } from '../../../utils/merge-table-params';
import {
  CSV,
  DEBOUNCE_TIMER,
  DEFAULT_DOCUMENT_SORT,
  PDF,
  ZIP,
} from '../../../constants/helper';
import { getIdsCheckboxChecked } from '../../../utils/check-checkbox';
import DisplayColumnsModal from '../../../modals/DisplayColumns';
import TableBody from '../../../components/tables/TableBody';
import TableHead from '../../../components/tables/TableHead';
import { columnsHead, columnsBody } from './Table';
import { selectAll } from '../../../utils/select-all';
import TaskDeleteModal from '../../../modals/TaskDelete';
import { deleteTrainingRecords } from '../../../utils/api/training-records-api';
import MessageResetSuccess from '../../../components/ToastSuccess';
import { useDocumentExport } from '../../../custom-hooks/useDocumentExport';
import { getFileName } from '../../../utils/get-file-name';
import { getExportParams } from '../../../utils/get-export-params';
import { isMultySelected } from '../../../utils/is-multy-selected';
import { getColumnsFilter } from '../../../utils/get-columns-search';
import { orderTable } from '../../../utils/order-table';
import FilterDropdown from './Details/shared/FilterDropdown';
import { getDefaultSort } from '../../../utils/training-records-helper/get-default-sort';
import { useCheckboxTable } from '../../../custom-hooks/useCheckboxTable';
import { useCheckboxCount } from '../../../custom-hooks/useCheckboxCount';
import TableHeader from '../../../components/tables/TableHeader';
import TableSelectedDelete from '../../../components/tables/SelectedDelete';
import TableFooter from '../../../components/tables/TableFooter';
import { useIsCanViewDocument } from '../../../contexts/ViewDocument';
import TablePaginationText from '../../../components/tables/PaginationText';
import TableSpinner from '../../../components/tables/Spinner';
import { dragReorder } from '../../../utils/dragable-helpers/dragable-reoder';
import {
  getChangedResizeColumns,
  getEndResizeColumns,
} from '../../../utils/tables-helper/resizable';
import { useTableWidth } from '../../../custom-hooks/useTableWidth';
import { deleteRow } from '../../../utils/tables-helper/deleteRow';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const TrainingRecords = ({ user, organizationName }) => {
  const organizationId = Number(useRouteMatch().params.id);
  const history = useHistory();

  const [records, setRecords] = useState();
  const onError = useErrorHandler();

  const [columnsVisibility, updateColumnsVisibility] = useState({
    actions: {
      label: 'Options',
      isVisible: true,
    },
    assigner: {
      label: 'Assigner',
      isVisible: true,
      accessor: 'Assigner',
      sort: true,
    },
    assignees: {
      label: 'Assignee(s)',
      isVisible: true,
      accessor: 'Assignees',
      sort: true,
    },
    creationDate: {
      label: 'Creation Date',
      isVisible: false,
      accessor: 'CreationDate',
      sort: true,
    },
    creationTime: {
      label: 'Creation Time',
      isVisible: false,
      accessor: 'CreationTime',
      sort: true,
    },
    dueDate: {
      label: 'Due Date',
      isVisible: true,
      accessor: 'DueDate',
      sort: true,
    },
    number: {
      label: 'System #',
      isVisible: true,
      accessor: 'Number',
      sort: true,
    },
    linkToRecord: {
      label: 'Link to Record',
      isVisible: true,
      accessor: 'Link',
    },
    trainingStatus: {
      label: 'Status',
      isVisible: true,
      accessor: 'TrainingStatus',
      sort: true,
    },
    name: {
      label: 'Name',
      isVisible: true,
      accessor: 'Name',
      sort: true,
    },
    creator: {
      label: 'Created By',
      isVisible: false,
      accessor: 'Creator',
      sort: true,
    },
  });

  const [isLoading, updateIsLoading] = useState(false);

  const [pagination, setPagination] = useState({
    data: [],
    limit: LIMIT,
    offset: INITIAL_OFFSET,
    pageNumber: INITIAL_PAGE,
    pageCount: PAGE_COUNT,
    totalItems: TOTAL_ITEMS,
    columns: getColumnsFilter(columnsVisibility),
    status: ALL,
    search: '',
    orderBy: '',
    orderType: '',
  });

  const getTasksList = useCallback(
    (
      organizationId,
      limit,
      offset,
      search,
      orderBy,
      orderType,
      status,
      columns,
    ) => {
      const params = mergetParams({
        organizationId,
        limit,
        offset,
        search,
        orderBy,
        orderType,
        status,
      });
      return getDataTableColumns('/training-records', params, columns);
    },
    [],
  );

  const updatingTable = async (success) => {
    try {
      if (!success) return;
      updateIsLoading(true);
      const { limit, offset, search, orderBy, orderType, columns, status } =
        pagination;
      const data = await getTasksList(
        organizationId,
        limit,
        offset,
        search,
        orderBy,
        orderType,
        status,
        columns,
      );
      setRecords(data);
    } catch (error) {
      onError(error);
    } finally {
      updateIsLoading(false);
    }
  };

  useEffect(() => {
    const getItems = async (
      organizationId,
      limit,
      offset,
      search,
      orderBy,
      orderType,
      status,
      columns,
    ) => {
      try {
        updateIsLoading(true);
        const data = await getTasksList(
          organizationId,
          limit,
          offset,
          search,
          orderBy,
          orderType,
          status,
          columns,
        );
        setRecords(data);
      } catch (error) {
        onError(error);
      } finally {
        updateIsLoading(false);
      }
    };

    getItems(
      organizationId,
      pagination.limit,
      pagination.offset,
      pagination.search,
      pagination.orderBy,
      pagination.orderType,
      pagination.status,
      pagination.columns,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.limit,
    pagination.search,
    pagination.offset,
    pagination.orderBy,
    pagination.orderType,
    pagination.status,
    getTasksList,
  ]);

  const debounceHandleUpdate = useMemo(() => {
    return debounce((input) => {
      setPagination((prev) => ({
        ...prev,
        pageNumber: INITIAL_PAGE,
        offset: INITIAL_OFFSET,
        search: input,
      }));
    }, DEBOUNCE_TIMER);
  }, []);

  useEffect(() => {
    if (!records) return;
    const {
      data,
      pagination: { totalCount },
    } = records;
    setPagination((prev) => ({
      ...prev,
      data,
      pageCount: Math.ceil(totalCount / prev.limit),
      totalItems: totalCount,
    }));
  }, [records]);

  const handleSearch = (value) => {
    debounceHandleUpdate(value);
  };

  const handleOrder = (key) => {
    setPagination((prev) => orderTable(key, prev));
  };

  const handleChangeLimit = (value) => {
    setPagination((prev) => ({
      ...prev,
      pageNumber: INITIAL_PAGE,
      offset: INITIAL_OFFSET,
      limit: value,
    }));
  };

  const handlePageClick = ({ selected }) => {
    const offset = Math.ceil(selected * pagination.limit);
    setPagination((prev) => ({
      ...prev,
      pageNumber: selected,
      offset,
    }));
  };

  const breadCrumbItems = [
    {
      link: '',
      title: 'Training records',
    },
  ];

  const [columnsHidden, updateColumnsHidden] = useState({
    number: true,
    name: true,
    assignees: true,
    trainingStatus: true,
    linkToRecord: true,
    assigner: true,
    creator: false,
    creationDate: false,
    creationTime: false,
    dueDate: true,
    actions: true,
  });

  useEffect(() => {
    const getColumns = getColumnsFilter(columnsVisibility);

    setPagination((prev) => ({
      ...prev,
      columns: getColumns,
    }));
  }, [columnsVisibility]);

  const [displayModal, setDisplayModal] = useState({
    isOpen: false,
  });

  const handleToggleColumn = () => {
    setDisplayModal((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const handleSaveColumns = () => {
    updateColumnsHidden((prev) => {
      return Object.entries(prev).reduce((acc, [key, value]) => {
        acc[key] = columnsVisibility[key].isVisible;
        return acc;
      }, {});
    });
    setDisplayModal((prev) => ({ ...prev, isOpen: false }));
  };

  const handleUpdateColumnsVisible = (key) => {
    updateColumnsVisibility((prev) => ({
      ...prev,
      [key]: { ...prev[key], isVisible: !prev[key].isVisible },
    }));
  };

  const handleSelectAll = (toggle) => {
    updateColumnsVisibility(selectAll(columnsVisibility, toggle));
  };

  const [deleteModal, updateDeleteModal] = useState({
    isOpen: false,
    model: {
      organizationId: null,
      ids: [],
    },
    values: {
      countChecked: null,
    },
    isMulty: true,
  });

  const handleDeleteMultyDocuments = (data, organizationId, countChecked) => {
    const ids = getIdsCheckboxChecked(data);

    updateDeleteModal((prev) => ({
      isOpen: !prev.isOpen,
      model: { organizationId, ids },
      values: { countChecked },
    }));
  };

  const {
    checkedHead,
    updateCheckedHead,
    checkAllDocuments,
    handleUpdateChecked,
    unCheckAllDocuments,
  } = useCheckboxTable(pagination, setPagination);

  const { countChecked } = useCheckboxCount(pagination.data);

  const handleCreateNew = (organizationId) => {
    history.push(`/organizations/${organizationId}/training-records/create`);
  };

  const { downloadFile } = useDocumentExport();

  const getIsFilter = (search, orderType, orderBy, data) => {
    return (
      search || orderType || orderBy || data.some(({ isChecked }) => isChecked)
    );
  };

  const handleDownloadManyDocuments = (
    organizationId,
    pagination,
    dataOfColumns,
  ) => {
    let { search, orderBy, orderType, data } = pagination;

    const isFilter = getIsFilter(search, orderType, orderBy, data);
    const fileName = getFileName(
      organizationName,
      'Trainings',
      user,
      PDF,
      isFilter,
    );

    [orderBy, orderType] = getDefaultSort(orderBy, orderType, dataOfColumns);

    const body = getExportParams(
      data,
      dataOfColumns,
      { search, orderBy, orderType },
      organizationId,
    );
    downloadFile('export/many-training-records', body, fileName);
  };

  const handleDownloadOneDocument = (organizationId, pagination) => {
    const { search, data } = pagination;

    const fileExt = isMultySelected(data) ? ZIP : PDF;
    const fileName = getFileName(
      organizationName,
      'Trainings',
      user,
      fileExt,
      false,
    );

    const body = getExportParams(data, null, { search }, organizationId);
    downloadFile('/export/one-training-record', body, fileName);
  };

  const handleDownloadCSV = (organizationId, pagination, dataOfColumns) => {
    let { search, orderBy, orderType, data } = pagination;

    const isFilter = getIsFilter(search, orderType, orderBy, data);
    const fileName = getFileName(
      organizationName,
      'Trainings',
      user,
      CSV,
      isFilter,
    );

    if (!orderBy) {
      orderBy = DEFAULT_DOCUMENT_SORT;
    }

    const body = getExportParams(
      data,
      dataOfColumns,
      { search, orderBy, orderType },
      organizationId,
    );
    downloadFile('/export/training-records', body, fileName);
  };

  const {
    clientUserAccess: { canAddTrainingRecords },
  } = useIsCanViewDocument();

  const [columnsMain, updateColumnsMain] = useState(columnsHead);
  const [columns, updateColumns] = useState(columnsBody);

  const tableRef = useTableWidth(updateColumnsMain);

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          name="Training records"
          breadCrumbItems={breadCrumbItems}
        />
        <Row className="d-flex justify-content-center">
          <Col xs="12">
            <Card>
              <CardBody>
                <TableHeader
                  title="List of Training Records"
                  isCanAdd={canAddTrainingRecords}
                  handleAddNew={() => handleCreateNew(organizationId)}
                  downloadCSV={() =>
                    handleDownloadCSV(
                      organizationId,
                      pagination,
                      columnsVisibility,
                    )
                  }
                  downloadOneDocument={() =>
                    handleDownloadOneDocument(organizationId, pagination)
                  }
                  downloadManyDocuments={() =>
                    handleDownloadManyDocuments(
                      organizationId,
                      pagination,
                      columnsVisibility,
                    )
                  }
                  FilterComponent={
                    <FilterDropdown
                      changeStatus={(status) =>
                        setPagination((prev) => ({ ...prev, status }))
                      }
                    />
                  }
                  manageColumns={handleToggleColumn}
                  handleSearch={handleSearch}
                />
                <TableSelectedDelete
                  title="training record"
                  countChecked={countChecked}
                  handleDelete={() =>
                    handleDeleteMultyDocuments(
                      pagination.data,
                      organizationId,
                      countChecked,
                    )
                  }
                />
                <div ref={tableRef} className="table-wrapper">
                  <Table className="table-custom table table-centered table-nowrap table-document">
                    <TableHead
                      columnsHidden={columnsHidden}
                      handleOrder={handleOrder}
                      checkAllDocuments={checkAllDocuments}
                      pagination={pagination}
                      checkedHead={checkedHead}
                      updateCheckedHead={checkedHead}
                      columnsHead={columnsMain}
                      columns={columns}
                      handleChangeColumns={(
                        columns,
                        columnsHead,
                        startIndex,
                        endIndex,
                      ) => {
                        updateColumns(
                          dragReorder(columns, startIndex, endIndex),
                        );
                        updateColumnsMain(
                          dragReorder(columnsHead, startIndex, endIndex),
                        );
                      }}
                      onResizeColumns={(columnIndex, changedWidth) => {
                        updateColumnsMain((columns) =>
                          getChangedResizeColumns(
                            columns,
                            columnIndex,
                            changedWidth,
                          ),
                        );
                      }}
                      onResizeEnd={(columnIndex) => {
                        updateColumnsMain((columns) =>
                          getEndResizeColumns(columns, columnIndex),
                        );
                      }}
                    />
                    <TableBody
                      user={user}
                      onDeleteRow={(deletedId) => {
                        setPagination((pagination) =>
                          deleteRow(pagination, deletedId),
                        );
                      }}
                      columnsHidden={columnsHidden}
                      pagination={pagination}
                      setPagination={setPagination}
                      columnsBody={columns}
                      organizationId={organizationId}
                      updateCheckedHead={updateCheckedHead}
                      handleUpdateChecked={handleUpdateChecked}
                    />
                  </Table>
                </div>
                {!!pagination.data.length && (
                  <TableFooter
                    limit={pagination.limit}
                    handleChangeLimit={handleChangeLimit}
                    pageCount={pagination.pageCount}
                    pageNumber={pagination.pageNumber}
                    handlePageClick={handlePageClick}
                  />
                )}
                {!(pagination.data.length || isLoading) && (
                  <TablePaginationText text="There are no Trainings to display" />
                )}
                {isLoading && <TableSpinner />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <DisplayColumnsModal
        columnsVisibility={columnsVisibility}
        isOpen={displayModal.isOpen}
        handleUpdateColumnsVisible={handleUpdateColumnsVisible}
        handleSelectAll={handleSelectAll}
        onCancel={() => setDisplayModal((prev) => ({ ...prev, isOpen: false }))}
        handleSaveColumns={handleSaveColumns}
      />
      <TaskDeleteModal
        text="Training(s)"
        isOpen={deleteModal.isOpen}
        model={deleteModal.model}
        values={deleteModal.values}
        isMulty={true}
        onSubmit={(_, values) => {
          deleteTrainingRecords(values).then(({ data }) => {
            const { message } = data;

            updatingTable(true);
            unCheckAllDocuments();

            toast.success(<MessageResetSuccess message={message} />);
            updateDeleteModal((prev) => ({ ...prev, isOpen: false }));
          });
        }}
        onCancel={() =>
          updateDeleteModal((prev) => ({ ...prev, isOpen: false }))
        }
      />
    </div>
  );
};

export default TrainingRecords;
