import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import Select from 'react-select';
import { debounce } from 'lodash';

import { customStyles } from '../../../utils/select-styles';
import { DEBOUNCE_TIMER } from '../../../constants/helper';
import {
  SELECT_ACTION,
  SELECT_ALL_OPTION,
  UNSELECT_ACTION,
} from '../../../constants/select';
import { isChangeSelectAll } from '../../../utils/isChangeSelectAll';

const MultiSelect = ({
  handleRequest,
  configOptions,
  handleChange,
  value,
  isDisabled = false,
  placeholder = 'Choose',
}) => {
  const [options, updateOptions] = useState([]);

  const handleRequestAPI = useCallback(
    (search = '') => {
      return handleRequest(search).then((data) =>
        updateOptions(configOptions(data)),
      );
    },
    [configOptions, handleRequest],
  );

  useEffect(() => {
    handleRequestAPI();
  }, [handleRequestAPI]);

  const handleSearch = useMemo(() => {
    return debounce((input) => {
      handleRequestAPI(input);
    }, DEBOUNCE_TIMER);
  }, [handleRequestAPI]);

  const valueRef = useRef(value);
  valueRef.current = value;

  const isOptionSelected = (option) =>
    valueRef.current.some(({ value }) => value === option.value) ||
    isSelectAllSelected();

  const getOptions = () => [SELECT_ALL_OPTION, ...options];
  const isSelectAllSelected = () => valueRef.current.length === options.length;
  const getValue = () => (isSelectAllSelected() ? [SELECT_ALL_OPTION] : value);

  const onChange = (values, actionMeta) => {
    const { action, option, removedValue } = actionMeta;

    if (action === SELECT_ACTION && option.value === SELECT_ALL_OPTION.value) {
      handleChange(options);
    } else if (isChangeSelectAll(action, option, removedValue)) {
      handleChange([]);
    } else if (action === UNSELECT_ACTION && isSelectAllSelected()) {
      handleChange(options.filter(({ value }) => value !== option.value));
    } else {
      handleChange(values || []);
    }
  };

  return (
    <Select
      isMulti
      isDisabled={isDisabled}
      value={getValue()}
      options={getOptions()}
      isOptionSelected={isOptionSelected}
      styles={customStyles}
      isClearable={true}
      onChange={onChange}
      onInputChange={(value) => handleSearch(value)}
      placeholder={placeholder}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
    />
  );
};

export default MultiSelect;
