import {
  MAX_LENGTH_TEXT,
  MAX_LENGTH_TO_VIEW,
  MAX_LENGTH_TO_VIEW_PROFILE,
} from '../constants/helper';

export const getCutName = (name = '') =>
  name.substr(0, MAX_LENGTH_TO_VIEW) + '...';

export const isNameLength = (name = '') =>
  name && name.length > MAX_LENGTH_TO_VIEW;

export const isPersonNameLength = (firstName = '', lastName = '') => {
  return `${firstName}${lastName}`.length > MAX_LENGTH_TO_VIEW_PROFILE;
};

export const isLongString = (name = '') => {
  return String(name)?.length > MAX_LENGTH_TEXT;
};

export const isLongText = (text, maxLength) => {
  return String(text).length > maxLength;
};
