import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import { unionBy } from 'lodash';

import Index from '../../../Button';
import ReferencesAddDocument from '../AddDocument';
import AddOtherReferences from '../AddOther';

import { useErrorHandler } from '../../../../custom-hooks/useErrorHandler';
import {
  INITIAL_LINK,
  INITIAL_OTHER_LINK,
} from '../../../../constants/document';
import { removeFilterIds } from '../../../../utils/documents/remove-filter-by-id';
import { addNewItem } from '../../../../utils/documents/add-new-item';
import { getSeparateLinks } from '../../../../utils/documents/get-separate-links';
import { getIdsFromReferences } from '../../../../utils/documents/get-ids-from-references';
import ReferencesLinkEdit from '../LinkEdit';
import { editReferences } from '../../../../utils/api/document-api';
import MessageResetSuccess from '../../../ToastSuccess';
import { SUCCESS_DOCUMENT_EDIT } from '../../../../constants/messages';

const ReferencesForm = ({
  updateEditMode,
  oldReferences,
  currentRevision,
  revision,
  updateCurrentRevision,
}) => {
  const { id: organizationId, documentId } = useRouteMatch().params;

  const [oldReferenceIds, updateOldReferencesIds] = useState([]);

  const [documentOldReferences, updateDocumentOldReferences] = useState([]);
  const [otherOldReferences, updateOtherOldReferences] = useState([]);

  useEffect(() => {
    const [oldDocumentsRef, oldOtherRef] = getSeparateLinks(oldReferences);
    updateDocumentOldReferences(oldDocumentsRef);
    updateOtherOldReferences(oldOtherRef);
    updateOldReferencesIds(getIdsFromReferences(oldReferences));
  }, [oldReferences]);

  const [references, updateReferences] = useState([]);

  const [documentLinks, updateDocumentLinks] = useState([INITIAL_LINK]);
  const [otherLinks, updateOtherLinks] = useState([INITIAL_OTHER_LINK]);

  const handleUpdateReferences = (values, partUrl, linkId) => {
    if (values) {
      const { value: linkDescription, id, revision, type } = values;

      let link = `${window.location.origin}/organizations/${organizationId}/${partUrl}/${id}`;
      if (revision) {
        link = `${link}?revision=${revision}`;
      }

      updateReferences((references) => {
        const equalRefIndex = references.findIndex(
          ({ linkId: id }) => linkId === id,
        );
        if (equalRefIndex === -1) {
          return [...references, { type, link, linkDescription, linkId }];
        }

        return references.map((reference) => {
          const { linkId: id } = reference;
          if (linkId === id) {
            return { type, link, linkDescription, linkId };
          }
          return reference;
        });
      });
      return;
    }
    updateReferences((references) =>
      references.filter(({ linkId: id }) => id !== linkId),
    );
  };

  const handleDelete = (linkId) => {
    updateDocumentLinks((items) => removeFilterIds(items, linkId));
    updateReferences((references) =>
      references.filter(({ linkId: id }) => id !== linkId),
    );
  };

  const handleDeleteOther = (linkId) => {
    updateOtherLinks((items) => removeFilterIds(items, linkId));
    updateReferences((references) =>
      references.filter(({ linkId: id }) => id !== linkId),
    );
  };

  const handleDeleteOldDocumentLink = (deletedId) => {
    updateOldReferencesIds((ids) => ids.filter((id) => id !== deletedId));
    updateDocumentOldReferences((references) =>
      references.filter(({ id }) => id !== deletedId),
    );
  };

  const handleDeleteOldOtherLink = (deletedId) => {
    updateOldReferencesIds((ids) => ids.filter((id) => id !== deletedId));
    updateOtherOldReferences((references) =>
      references.filter(({ id }) => id !== deletedId),
    );
  };

  const onError = useErrorHandler();

  const handleSubmit = (_, values) => {
    const { documentId, ...body } = values;
    editReferences(documentId, body)
      .then(() => {
        updateEditMode(false);
        updateCurrentRevision((prev) => ({ ...prev }));
        toast.success(<MessageResetSuccess message={SUCCESS_DOCUMENT_EDIT} />);
      })
      .catch(onError);
  };

  const getUnionReferences = (newReferences, existReferences) => {
    const newUnionReferences = unionBy(newReferences, 'link');

    return newUnionReferences.filter(({ link }) => {
      return existReferences.every(({ link: existLink }) => link !== existLink);
    });
  };

  return (
    <AvForm onValidSubmit={handleSubmit}>
      <Row>
        <Col>
          <div className="info-column">
            <p className="info-column__title">Documents</p>
            {documentLinks.map((id) => {
              return (
                <ReferencesAddDocument
                  key={id}
                  id={id}
                  links={documentLinks}
                  handleDelete={handleDelete}
                  handleUpdateReferences={handleUpdateReferences}
                />
              );
            })}
            <Index
              color="btn btn-create"
              handler={() => updateDocumentLinks((prev) => addNewItem(prev))}
            >
              <i className="bx bx-plus"></i> Add New
            </Index>
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <ReferencesLinkEdit
            links={documentOldReferences}
            handleDelete={handleDeleteOldDocumentLink}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <div className="info-column">
            <p className="info-column__title">Other</p>
            {otherLinks.map((id) => {
              return (
                <AddOtherReferences
                  key={id}
                  id={id}
                  links={otherLinks}
                  handleDelete={handleDeleteOther}
                  handleUpdateReferences={handleUpdateReferences}
                />
              );
            })}
            <Index
              color="btn btn-create"
              handler={() => updateOtherLinks((prev) => addNewItem(prev))}
            >
              <i className="bx bx-plus"></i> Add New
            </Index>
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <ReferencesLinkEdit
            links={otherOldReferences}
            handleDelete={handleDeleteOldOtherLink}
          />
        </Col>
      </Row>
      <Row className="form-group mt-2">
        <Col className="text-right">
          <Index
            type="button"
            color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
            handler={() => updateEditMode(false)}
          >
            Cancel
          </Index>
          <Index
            type="submit"
            color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
          >
            Save Changes
          </Index>
        </Col>
      </Row>
      <AvField
        name="newReferences"
        type="hidden"
        value={getUnionReferences(references, [
          ...documentOldReferences,
          ...otherOldReferences,
        ])}
      />
      <AvField name="oldReferenceIds" type="hidden" value={oldReferenceIds} />
      <AvField name="documentId" type="hidden" value={documentId} />
      <AvField name="organizationId" type="hidden" value={organizationId} />
      <AvField
        name="revision"
        type="text"
        value={currentRevision.value || revision}
        className="btn-hidden"
      />
    </AvForm>
  );
};

export default ReferencesForm;
