import React from 'react';
import { FormGroup } from 'reactstrap';

const FormGroupWrapper = ({ name, children }) => {
  return (
    <div className="info-column">
      <p className="info-column__title">{name}</p>
      <FormGroup className="select2-container">{children}</FormGroup>
    </div>
  );
};

export default FormGroupWrapper;
