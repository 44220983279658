import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';

const TableSpinner = () => {
  return (
    <Row>
      <Col className="d-flex justify-content-center">
        <Spinner color="primary" />
      </Col>
    </Row>
  );
};

export default TableSpinner;
