import { ACTIVE } from '../constants/helper';
import { ORGANIZATIONS_USER_ROUTE } from '../constants/routes';
import { getOrganizations } from './backend-helper';

const getCountActiveStatus = (organizations) => {
  return organizations.filter(({ status }) => status === ACTIVE);
};

export const getRouteErrorOrganization = async () => {
  const { data } = await getOrganizations();

  const activeOrganizations = getCountActiveStatus(data);

  if (activeOrganizations.length <= 1) {
    return `/organizations/${activeOrganizations[0].id}/dashboard`;
  }

  return ORGANIZATIONS_USER_ROUTE;
};
