import React from 'react';

const Checkbox = ({ id, isChecked, onChange, classes }) => {
  return (
    <div
      className={`custom-control custom-checkbox ${
        classes ? classes : 'custom-checkbox-answer'
      }`}
    >
      <input
        type="checkbox"
        className="custom-control-input"
        checked={isChecked}
        onChange={onChange}
        id={`custom-checkbox-${id}`}
      />
      <label
        className="custom-control-label"
        htmlFor={`custom-checkbox-${id}`}
      ></label>
    </div>
  );
};

export default Checkbox;
