import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';

import {
  createOrganization,
  clearOrganizationSuccess,
} from '../../store/auth/organization/actions';
import Index from '../../components/Button';

const OrganizationCreatingModal = ({
  isOpenModal,
  handleToogleModal,
  createOrganization,
  creatingSuccess,
  clearOrganizationSuccess,
}) => {
  const [name, setName] = useState('');

  const handleChangeName = (value) => {
    setName(value);
  };

  const handleValidSubmit = (event, values) => {
    createOrganization(values);
  };

  const handleCancel = () => {
    handleToogleModal();
    setName('');
  };

  useEffect(() => {
    if (creatingSuccess) {
      handleToogleModal(creatingSuccess);
      clearOrganizationSuccess();
      setName('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingSuccess]);

  return (
    <Modal isOpen={isOpenModal} toggle={handleToogleModal} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Create Organization</h5>
        <button
          type="button"
          onClick={handleCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm
        className="form-horizontal mt-4"
        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
      >
        <div className="modal-body">
          <div className="form-group">
            <AvField
              name="name"
              label="Organization name"
              className="form-control"
              placeholder="Enter organization name"
              type="name"
              value={name}
              onChange={(e) => handleChangeName(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Index
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={handleCancel}
              >
                Cancel
              </Index>
              <Index
                type="submit"
                color="btn btn-primary w-md waves-effect waves-light"
                disabled={!Boolean(name.length)}
              >
                Create organization
              </Index>
            </Col>
          </Row>
        </div>
      </AvForm>
    </Modal>
  );
};

const mapStateToProps = ({ Organization: { creatingSuccess } }) => ({
  creatingSuccess,
});

export default connect(mapStateToProps, {
  createOrganization,
  clearOrganizationSuccess,
})(OrganizationCreatingModal);
