import {
  ORGANIZATIONS,
  SUCCESS_ORGANIZATION,
  CREATE_ORGANIZATION,
  SUCCESS_CREATE_ORGANIZATION,
  CLEAR_CREATE_SUCCESS,
  TOOGLE_MANAGE_BY_CLIENT,
  TOOGLE_MANAGE_SUCCESS,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_SUCCESS,
  SET_STATUS,
  SET_STATUS_SUCCESS,
  CLEAR_DELETE_SUCCESS,
  CLEAR_SET_STATUS,
} from './actionTypes';

export const getOrganizations = (limit, offset, search, orderBy, orderType) => {
  return {
    type: ORGANIZATIONS,
    payload: { limit, offset, search, orderBy, orderType },
  };
};

export const getDataSuccess = (data) => {
  return {
    type: SUCCESS_ORGANIZATION,
    payload: data,
  };
};

export const createOrganization = (name) => {
  return {
    type: CREATE_ORGANIZATION,
    payload: name,
  };
};

export const createOrganizationSuccess = () => {
  return {
    type: SUCCESS_CREATE_ORGANIZATION,
  };
};

export const clearOrganizationSuccess = () => {
  return {
    type: CLEAR_CREATE_SUCCESS,
  };
};

export const managedByClient = (id) => {
  return {
    type: TOOGLE_MANAGE_BY_CLIENT,
    payload: { id },
  };
};

export const manageByClientSuccess = (id) => {
  return {
    type: TOOGLE_MANAGE_SUCCESS,
    id,
  };
};

export const deleteOrganization = (id) => {
  return {
    type: DELETE_ORGANIZATION,
    id,
  };
};

export const deleteOrganizationSuccess = () => {
  return {
    type: DELETE_ORGANIZATION_SUCCESS,
  };
};

export const setStatus = (id) => {
  return {
    type: SET_STATUS,
    id,
  };
};

export const setStatusSuccess = () => {
  return {
    type: SET_STATUS_SUCCESS,
  };
};

export const clearDeleteSuccess = () => {
  return {
    type: CLEAR_DELETE_SUCCESS,
  };
};

export const clearStatus = () => {
  return {
    type: CLEAR_SET_STATUS,
  };
};
