export class SessionStorage {
  static storage = sessionStorage;

  setItem(key, value) {
    SessionStorage.storage.setItem(key, JSON.stringify(value));
  }

  getItem(key, defaultValue) {
    try {
      return JSON.parse(SessionStorage.storage.getItem(key));
    } catch (err) {
      return SessionStorage ? defaultValue : null;
    }
  }

  removeItem(key) {
    SessionStorage.storage.removeItem(key);
  }
}
