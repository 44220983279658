import React from 'react';

const Button = ({
  type = 'button',
  color,
  disabled = false,
  handler,
  children,
}) => {
  return (
    <button type={type} className={color} disabled={disabled} onClick={handler}>
      {children}
    </button>
  );
};

export default Button;
