import HelpArtifacts from './Artifacts';
import HelpGeneral from './General';
import HelpIntroduction from './Introduction';
import HelpNotes from './NotesAdmin';
import HelpNotesSuperAdmin from './NotesSuperAdmin';

import {
  ADMIN_ROLE,
  GENERAL_FEATURE_TAB,
  INTRODUCTION_TAB,
  MAIN_ARTIFACTS_TAB,
  NOTES_ADMIN_TAB,
  NOTES_SUPER_ADMIN_TAB,
  USER_ROLE,
} from '../../constants/helper';

const firstNavigationList = {
  title: 'Introduction',
  tabId: INTRODUCTION_TAB,
  component: HelpIntroduction,
};

const secondNavigationList = [
  {
    title: 'Main artifacts and flows',
    tabId: MAIN_ARTIFACTS_TAB,
    component: HelpArtifacts,
  },
  {
    title: 'General Features',
    tabId: GENERAL_FEATURE_TAB,
    component: HelpGeneral,
  },
];

const navigationSuperAdmin = {
  title: 'Notes about the Medavice Super Admin',
  tabId: NOTES_SUPER_ADMIN_TAB,
  component: HelpNotesSuperAdmin,
};

const navigationAdmin = {
  title: 'Notes about the Admin',
  tabId: NOTES_ADMIN_TAB,
  component: HelpNotes,
};

export const getNavigationList = (role) => {
  if (role === USER_ROLE) {
    return [firstNavigationList, ...secondNavigationList];
  }
  if (role === ADMIN_ROLE) {
    return [firstNavigationList, navigationAdmin, ...secondNavigationList];
  }
  return [
    firstNavigationList,
    navigationSuperAdmin,
    navigationAdmin,
    ...secondNavigationList,
  ];
};
