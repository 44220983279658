import moment from 'moment';
import { FORMAT_DUE_DATE } from '../constants/helper';

const isValidDates = (dates) => {
  return dates.every((date) => date);
};

export const getRangeFormatedDate = (start, end) => {
  if (isValidDates([start, end])) {
    return [
      moment(start).format(FORMAT_DUE_DATE),
      moment(end).format(FORMAT_DUE_DATE) + 'T23:50:00Z',
    ];
  }

  return [null, null];
};
