export const SUCCESS_MESSAGE_ORGANIZATION =
  'Organization has been successfully created!';
export const SUCCESS_STATUS_CHANGED = 'Status has been successfully changed';
export const SUCCESS_EDIT_MESSAGE =
  'Organization name has been edited successfully';
export const SUCCESS_DELETE_MESSAGE =
  'Organization has been deleted successfully';

export const SUCCESS_MESSAGE_FORGET =
  'If you have an account with the email provided, we will send you a reset link';

export const SUCCESS_MESSAGE_RESET =
  'Your password has been updated successfully!';
export const SUCCESS_PASSWORD_UPDATE = 'Your password is changed.';
export const ERROR_MESSAGE_RESET =
  'Your session to reset your password has expired. Please, click “Forgot password”';
export const ERROR_INVALID_TOKEN =
  'Your session to reset your password has expired. Please, click “Forgot password”';

export const SUCCESS_STATUS_MESSAGE = 'Status has been updated successfully';
export const SUCCESS_MESSAGE_CREATE = 'Admin has been successfully created';
export const SUCCESS_MESSAGE_ADD = 'Admin(s) have been successfully added';

export const ERROR_LOGIN_MESSAGE = 'Login and password do not match';
export const SUCCESS_STATUS_MESSAGE_ADMIN =
  'Status has been updated successfully';

export const SUCCESS_MESSAGE_EDIT = 'Admin has been successfully edited';
export const SUCCESS_MESSAGE_USER_CREATE = 'User has been successfully created';
export const SUCCESS_MESSAGE_USER_ADD = 'User(s) have been successfully added';

export const SUCCESS_MESSAGE_SET = 'Your password has been set up successfully';
export const SUCCESS_MESSAGE_SEND =
  'The Welcome Email is sent to the Admin email';
export const SUCCESS_MESSAGE_USER_SEND =
  'The Welcome Email is sent to the User email';
export const SUCCESS_MESSAGE_UPDATE_EMAIL =
  'Your new email is confirmed successfully, please, use it for Login';

export const SUCCESS_DELETE_ADMIN = 'Admin has been successfully deleted';
export const SUCCESS_MESSAGE_ACTIVE = 'Status has been successfully changed';

export const SUCCESS_DELETE_USER = 'User has been successfully deleted';

export const SUCCESS_UPDATE_NAME = 'Your name is updated';

export const SUCCESS_MESSAGE_USER_GROUP_CREATE =
  'User Group has been successfully created';
export const SUCCESS_USER_GROUP_EDIT =
  'User Group has been successfully edited';
export const SUCCESS_MESSAGE_ADD_USER = 'User has been successfully added';
export const SUCCESS_MESSAGE_DELETE_USER = 'User has been successfully deleted';
export const SUCCESS_USER_GROUP_DELETE =
  'User Group has been successfully deleted';
export const SUCCESS_MESSAGE_USER_EDIT = 'User has been successfully edited';
export const SUCCESS_MESSAGE_ADMIN_EDIT = 'Admin has been successfully edited';
export const SUCCESS_DUPLICATE_GROUP =
  'User Group has been successfully duplicated';

export const SUCCESS_EMAIL_UPDATE = 'Your email is updated';
export const SUCCESS_RESEND_EMAIL =
  'The new email with the confirmation link is sent to your new mailbox';

export const SUCCESS_DOCUMENT_TYPE_CREATE =
  'Document type has been successfully created';
export const SUCCESS_DOCUMENT_TYPE_DELETE =
  'Document type has been successfully deleted';
export const SUCCESS_DOCUMENT_EDIT = 'The document is updated successfully';
export const SUCCESS_DOCUMENT_CREATE = 'Your document is created';
export const SUCCESS_DOCUMENT_DELETE = 'The Document is deleted';

export const SUCCESS_REVISION_CREATE = 'The Document Revision is saved';

export const SUCCESS_TASK_CREATE = 'Your action is created';
export const SUCCESS_TASK_EDIT = 'The action is updated successfully';
export const SUCCESS_TASK_DELETE = 'The action is deleted';

export const SUCCESS_TRAINING_UPDATE = 'The training is updated successfully';
export const SUCCESS_TRAINING_CREATE =
  'The training is linked to the document.';
export const SUCCESS_TRAINING_CREATE_NEW =
  'The training is created successfully';
export const SUCCESS_TRAINING_LINKED = 'The training is linked to the document';
export const SUCCESS_TRAINING_DELETE = 'The Training is deleted';

export const SUCCESS_RECORD_DELETE = 'The record is successfully deleted';
export const SUCCESS_RECORD_EDIT = 'The record is successfully edited';

export const SUCCESS_TAGS_CREATE = 'The tag has been successfully created';
export const SUCCESS_TAGS_DELETE = 'The tag has been successfully deleted';

export const LOADING_DOCUMENT = 'Your report is being generated, please, wait.';
export const SUCCESS_LOADING_DOCUMENT = 'Your report is downloaded';
export const ERROR_LOADING_DOCUMENT = 'No records to export';

export const ERROR_VALIDATION_DOCUMENTS = 'Fill all required fields';
export const ERROR_SAVE_REQUIRED =
  'Please save your document data in the "General" section first';

export const NETWORK_ERROR = 'Request failed';
export const ERROR_ACCESS_MESSAGE =
  'Access denied. Please, contact your support team.';
