import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { AvField } from 'availity-reactstrap-validation';
import Select from 'react-select';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import classnames from 'classnames';
import { debounce } from 'lodash';

import {
  DEBOUNCE_TIMER,
  MAX_INPUT_LENGTH,
  MAX_REVISION,
  MAX_TEXTAREA_LENGTH,
  MIN_REVISION,
} from '../../../../constants/helper';
import { getDocumentUsers } from '../../../../utils/backend-helper';
import Button from '../../../../components/Button';
import { getDocumentTypes } from '../../../../utils/backend-helper';
import {
  configTags,
  configureGetDocumentTypes,
  configureGetUsers,
} from '../../../../utils/config-get-organization';
import { SuperAdmin } from '../../../../constants/role';
import DocumentAttachedFiles from '../../../../components/document/AttachedFiles';
import DocumentAttached from '../../../../components/document/DocumentAttached';
import { filterByField } from '../../../../utils/training-records-helper/filter-by-id';
import { getFilesIds } from '../../../../utils/training-records-helper/get-files-ids';
import { validateEmptyText } from '../../../../constants/validatePassword';
import {
  NUMBER_VALIDATION_ERROR,
  REQUIRED_FIELD,
} from '../../../../constants/form';
import CustomMultiSelect from '../../../../components/CustomMultiSelect';
import { getTags } from '../../../../utils/api/organization-api';
import { getUnionArrayIds } from '../../../../utils/shared/get-union-array';
import {
  changeExtraFileDocument,
  changeControlledDocument,
} from '../../../../utils/file-attached/change-file-document';

const customStyles = {
  option: (styles) => {
    return {
      ...styles,
      color: '#000000',
      fontFamily: 'Poppins',
      fontWeight: '500',
    };
  },
};

const GeneralSection = ({
  isChecked,
  updateIsChecked,
  organizationId,
  user,
  handleCancelCreator,
  model,
  updateModel,
  isValidForm,
  createdDocument,
}) => {
  const [documentTypeList, updateDocumentTypeList] = useState([]);

  const handleModelChange = (event) => {
    const { name, value } = event.target;
    updateModel((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    getDocumentTypes(organizationId).then(({ data }) =>
      updateDocumentTypeList(configureGetDocumentTypes(data)),
    );
  }, [organizationId]);

  const handleDocumentTypeChange = ({ id: typeId }) => {
    updateModel((prev) => ({ ...prev, typeId }));
  };

  const { firstName, lastName, email, role, id } = user;
  const defaultUser = {
    label: `${firstName || ''} ${lastName || ''} - ${email} - ${role}`,
    value: `${id}`,
  };

  const [userOptions, updateUserOptions] = useState([]);

  useEffect(() => {
    getDocumentUsers(organizationId, '').then(({ data }) =>
      updateUserOptions(configureGetUsers(data)),
    );
  }, [organizationId]);

  const handleSubmitterChange = ({ id: submitterId }) => {
    updateModel((prev) => ({ ...prev, submitterId }));
  };

  const debounceHandleSearch = useMemo(() => {
    return debounce((input) => {
      getDocumentUsers(organizationId, input).then(({ data }) =>
        updateUserOptions(configureGetUsers(data)),
      );
    }, DEBOUNCE_TIMER);
  }, [organizationId]);

  const handleSubmitterSearch = (value) => {
    debounceHandleSearch(value);
  };

  const getTagsFromAPI = useCallback(() => {
    return getTags(organizationId);
  }, [organizationId]);

  return (
    <div className="general">
      <div className="general-header">
        <h6 className="mt-2">General</h6>
      </div>
      <hr />
      <div className="general-info">
        <Row>
          <Col xs="6">
            <div className="info-column">
              <p className="info-column__title">Document type*</p>
              <FormGroup className="select2-container">
                <Select
                  styles={customStyles}
                  options={documentTypeList}
                  onChange={(value) => handleDocumentTypeChange(value)}
                  placeholder="Choose document type"
                />
              </FormGroup>
            </div>
          </Col>
          <Col xs="6">
            <div className="info-column">
              <p className="info-column__title">Document name*</p>
              <div className="form-group">
                <AvField
                  name="name"
                  className="form-control"
                  placeholder="Enter document name"
                  maxLength={MAX_INPUT_LENGTH}
                  onChange={(event) => handleModelChange(event)}
                  validate={validateEmptyText}
                  errorMessage={REQUIRED_FIELD}
                  value={model.name}
                  type="text"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <div className="info-column">
            <p className="info-column__title">Document ID</p>
            <div className="form-group">
              <AvField
                type="text"
                name="typeDescription"
                className="form-control"
                placeholder="Enter Document ID"
                maxLength={MAX_INPUT_LENGTH}
                onChange={(event) => handleModelChange(event)}
                value={model.typeDescription}
              />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <div className="general-revision">
        <Row>
          <Col sm="4">
            <div className="info-column">
              <p className="info-column__title">Revision*</p>
              <div className="form-group">
                <AvField
                  name="revision"
                  className="form-control"
                  type="number"
                  min={MIN_REVISION}
                  max={MAX_REVISION}
                  onChange={(event) => handleModelChange(event)}
                  value={model.revision}
                  placeholder="Revision number"
                  errorMessage={NUMBER_VALIDATION_ERROR}
                />
              </div>
            </div>
          </Col>
          <Col xs="8">
            <div className="info-column">
              <p className="info-column__title">Revision name</p>
              <div className="form-group">
                <AvField
                  type="text"
                  name="revisionName"
                  value={model.revisionName}
                  className="form-control"
                  placeholder="Enter revision name"
                  maxLength={MAX_INPUT_LENGTH}
                  onChange={(event) => handleModelChange(event)}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="info-column">
          <p className="info-column__title">Revision description*</p>
          <div className="form-group">
            <AvField
              type="textarea"
              id="textarea"
              name="revisionDescription"
              placeholder="Enter revision description"
              maxLength={`${MAX_TEXTAREA_LENGTH}`}
              onChange={(event) => handleModelChange(event)}
              value={model.revisionDescription}
              validate={validateEmptyText}
              errorMessage={REQUIRED_FIELD}
              rows="3"
            />
          </div>
        </div>
      </div>
      <Row>
        <Col>
          <div className="info-column">
            <p className="info-column__title">Tag(s)</p>
            <div className="form-group">
              <CustomMultiSelect
                handleRequest={getTagsFromAPI}
                configOptions={configTags}
                placeholder="Choose tag(s)"
                onChange={(values) => {
                  updateModel((prev) => ({
                    ...prev,
                    tagIds: getUnionArrayIds(values),
                  }));
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <hr />
      <DocumentAttached
        text="Controlled document*"
        file={model.fileId}
        handleUploadFile={(data) => {
          updateModel((model) => changeControlledDocument(model, data));
        }}
        onDeleteFile={() => {
          updateModel((model) => changeControlledDocument(model, ''));
        }}
      />
      <hr />
      <DocumentAttachedFiles
        title="Extra document(s)"
        files={model.extraFileIds}
        onDeleteFile={(id) => {
          updateModel((model) =>
            changeExtraFileDocument(
              model,
              filterByField(model.extraFileIds, id),
            ),
          );
        }}
        updateModel={(body) => {
          updateModel((model) =>
            changeExtraFileDocument(model, [...model.extraFileIds, body]),
          );
        }}
      />
      <hr />
      <div className={classnames('general-responsible', 'd-block')}>
        <Row>
          <Col xl="12">
            <div className="info-column">
              <p className="info-column__title">Responsible*</p>
              <FormGroup className="select2-container">
                <Select
                  isMulti={false}
                  styles={customStyles}
                  options={userOptions}
                  defaultValue={defaultUser}
                  onChange={(value) => handleSubmitterChange(value)}
                  onInputChange={(value) => handleSubmitterSearch(value)}
                  placeholder="Choose"
                />
              </FormGroup>
            </div>
          </Col>
          <Col xl="12">
            <div className="info-column">
              <p className="info-column__title">Created By*</p>
              <div className="form-group">
                <Input
                  name="createdBy"
                  className="form-control"
                  type="text"
                  value={
                    user.role === SuperAdmin
                      ? 'Q Suite Super Admin'
                      : `${user.firstName || ''} ${user.lastName || ''} (${
                          user.email || ''
                        }) - ${user.role}`
                  }
                  disabled
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row className="form-group mt-3">
        <Col className="text-right">
          <Button
            type="button"
            color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4 mb-3"
            handler={handleCancelCreator}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4 mb-3"
            disabled={!isValidForm}
          >
            Save & Next
          </Button>
        </Col>
      </Row>
      <AvField
        name="typeId"
        type="text"
        value={model.typeId}
        className="btn-hidden"
      />
      <AvField
        name="submitterId"
        type="text"
        value={model.submitterId}
        className="btn-hidden"
      />
      <AvField
        name="fileId"
        type="text"
        value={model.fileId?.id}
        className="btn-hidden"
      />
      <AvField
        name="organizationId"
        type="text"
        value={organizationId}
        className="btn-hidden"
      />
      <AvField
        name="typeDescription"
        type="text"
        value={model.typeDescription}
        className="btn-hidden"
      />
      <AvField
        name="extraFileIds"
        type="text"
        value={getFilesIds(model.extraFileIds)}
        className="btn-hidden"
      />
      <AvField name="tagIds" type="hidden" value={model.tagIds} />
      {createdDocument?.revision && (
        <AvField
          name="revision"
          type="hidden"
          value={createdDocument.revision}
        />
      )}
      {createdDocument?.id && (
        <AvField name="id" type="hidden" value={createdDocument.id} />
      )}
    </div>
  );
};

export default GeneralSection;
