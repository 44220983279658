import React from 'react';

import ReviewComment from '../../../ReviewComment';
import ReviewColumn from '../Column';
import { REVIEW_STATUS } from '../../../../constants/helper';

const ReviewItem = ({ review }) => {
  const { firstName, lastName, email, comment, responseStatus } = review;

  const reviewColumns = [
    {
      title: 'Reviewer name:',
      text: `${firstName} ${lastName}`,
    },
    {
      title: 'Email:',
      text: `${email}`,
    },
    {
      title: 'Response:',
      text: REVIEW_STATUS[responseStatus],
    },
  ];

  return (
    <div className="review-user">
      <div className="review-responses">
        {reviewColumns.map(({ title, text }) => (
          <ReviewColumn key={title} title={title} text={text} />
        ))}
      </div>
      <ReviewComment comment={comment} />
      <hr />
    </div>
  );
};

export default ReviewItem;
