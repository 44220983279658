import { ASC, DESC } from '../constants/table';

export const orderTable = (key, pagination) => {
  const { orderType, orderBy } = pagination;
  const isDubleClick = orderBy === key || orderBy === '';

  return {
    ...pagination,
    orderBy: key,
    orderType: orderType === ASC && isDubleClick ? DESC : ASC,
  };
};
