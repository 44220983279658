import React from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import ModalInner from '../../components/shared/ModalInner';
import Button from '../../components/Button';
import { MAX_INPUT_LENGTH } from '../../constants/helper';

const OrganizationEditModal = ({
  values,
  onSubmit,
  onCancel,
  isOpen,
  handleChangeEdit,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <ModalInner title="Edit Organization" onCancel={onCancel}>
        <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
          <div className="modal-body">
            <div className="form-group">
              <AvField
                onChange={(e) => handleChangeEdit(e.target.value)}
                name="name"
                label="Organization name"
                className="form-control"
                placeholder="Enter organization name"
                type="name"
                maxLength={MAX_INPUT_LENGTH}
                value={values.name}
              />
              <AvField
                name="id"
                type="number"
                value={values.id}
                className="btn-hidden"
              />
            </div>
          </div>
          <div className="modal-footer">
            <Row className="form-group">
              <Col className="text-right pr-0">
                <Button
                  type="button"
                  color="btn btn-light w-md waves-effect waves-light mr-3"
                  handler={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!values.name.length}
                  type="submit"
                  color="btn btn-edit w-md waves-effect waves-light"
                >
                  Save changes
                </Button>
              </Col>
            </Row>
          </div>
        </AvForm>
      </ModalInner>
    </Modal>
  );
};

export default OrganizationEditModal;
