import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';

import PlaceholderText from '../../../../../components/shared/PlaceholderText';

import { getQuestionCols } from '../../../../../utils/training-records-helper/get-question-cols';

const TrainingQuestionsList = ({ questions }) => {
  const [cols, updateCols] = useState({});

  useEffect(() => {
    updateCols(() => getQuestionCols(questions));
  }, [questions]);

  const handleUpdateCols = (id) => {
    updateCols((prev) => ({
      ...prev,
      [id]: {
        isOpen: !prev[id].isOpen,
        id,
      },
    }));
  };

  if (questions?.length) {
    return (
      <div className="question-container">
        {questions.map(({ id, name, answers }, index) => {
          return (
            <div key={id} className="question-item">
              <p
                className="question-item__text"
                onClick={() => handleUpdateCols(id)}
              >
                <b>{index + 1}.</b> {name}
                <i
                  className={classnames('bx bx-chevron-down arrow', {
                    downArrow: cols[id] && cols[id].isOpen,
                  })}
                ></i>
              </p>
              {cols[id] && (
                <Collapse isOpen={cols[id].isOpen}>
                  <ol type="a">
                    {answers.map(({ id, name, isCorrect }) => (
                      <li key={id} className="mb-1">
                        {name}
                      </li>
                    ))}
                  </ol>
                </Collapse>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  return <PlaceholderText text="No questions." />;
};

export default TrainingQuestionsList;
