import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import { CURRENT_DATE, MIN_EXPORT_DATE } from '../../../../constants/table';

const ExportLogsRange = ({ startDate, endDate, onChange }) => {
  const ExampleCustomInput = forwardRef(({ onClick }, ref) => (
    <div
      ref={ref}
      className="d-flex align-items-center filter-container d-cursor date-container"
      onClick={onClick}
    >
      <i className="font-size-20 bx bx-calendar mr-1"></i>
      <span>Range</span>
    </div>
  ));

  return (
    <div className="date-container mr-3 d-flex align-items-center">
      <DatePicker
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        shouldCloseOnSelect={false}
        minDate={MIN_EXPORT_DATE}
        maxDate={CURRENT_DATE}
        formatWeekDay={nameOfDay => nameOfDay.substr(0,1)}
        customInput={<ExampleCustomInput />}
        todayButton={<div className='today'>Today</div>}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
        }) => (
          <div className='date-container-header'>
            <p className='date-container-header__date mb-0'>
              {moment(date).format('MMMM YYYY')}
            </p>
            <div className='date-container-header__arrows'>              
              <i 
                className='bx bx-up-arrow-alt'
                onClick={decreaseMonth}
              ></i>
              <i 
                className='bx bx-down-arrow-alt'
                onClick={increaseMonth}
              ></i>
            </div>
          </div>
        )}
      >
      </DatePicker>
    </div>
  );
};

export default ExportLogsRange;
