import React from 'react';

import HelpImage from '../shared/HelpImage';

import accessImage from '../../../assets/images/help-screen/im_Documents Copy 2@3x.png';
import organizationImage1 from '../../../assets/images/help-screen/im_Documents Copy 2-1@3x.png';
import organizationImage2 from '../../../assets/images/help-screen/im_Documents Copy 2-2@3x.png'
import organizationImage3 from '../../../assets/images/help-screen/im_Documents Copy 2-4@3x.png';
import onboardingImage from '../../../assets/images/help-screen/im_Documents Copy 2-5@3x.png';
import uniquenessImage from '../../../assets/images/help-screen/im_Documents Copy 2-6@3x.png';
import activationImage1 from '../../../assets/images/help-screen/im_Documents Copy 2-9@3x.png';
import activationImage2 from '../../../assets/images/help-screen/im_Documents Copy 2-7@3x.png';
import accessCodeImage from '../../../assets/images/help-screen/im_Documents Copy 2-8@3x.png';

export const mokDataNotesSuperAdmin = [
  {
    title: 'Access Permissions',
    content: (
      <>
        <p>
          Medavice Super Admin has the full-access permissions for all the
          Organizations.
        </p>
        <HelpImage src={accessImage} />
      </>      
    ),
  },
  {
    title: 'Organization Management',
    content: (
      <>
        <p>
          Medavice Super Admin cannot click to enter an Organization, when this
          Organization has the “Managed by Client” toggle set On. Medavice Super
          Admin can change the position of this toggle. This toggle works as a
          “reminder” in order Medavice Super Admin does not make any changes or
          service in an Organization which did not request help with set up and
          onboarding. 
        </p>
        <HelpImage src={organizationImage1} />
        <p>
          In this context, it is important to remember that only
          Medavice Super Admin can onboard Client Admins in all the Organizations.
        </p>
        <HelpImage src={organizationImage2} />
        <HelpImage src={organizationImage3} />
      </>      
    ),
  },
  {
    title: 'Client Admin onboarding',
    content: (
      <>
        <p>
          When you need to onboard the same Client Admin with the same email
          address into 2+ Organizations, you need to create this Client Admin in
          one of the Organizations, then use the “Add Existing” option for Admins
          in other Organizations. The “Add an Existing Admin” pop-up allows you to
          select Organization(s), then Admin(s) in the Organization(s) selected
          and add the existing Admin(s) into the current Organization.
        </p>
        <HelpImage src={onboardingImage} />
      </>      
    ),
  },
  {
    title: 'Email Uniqueness',
    content: (
      <>
        <p>
          The system does not allow you to onboard a user more than 1 time, the
          same email can be used only 1 time, only for one user role.
        </p>
        <HelpImage src={uniquenessImage} />
      </>      
    ),
  },
  {
    title: 'Activation',
    content: (
      <>
        <p>
          When you click to Set Active an Admin for the first time, a welcome
          email is sent. While they do not set up their password by the link in
          the welcome email, you can resend their invite. This action is allowed
          not more than 5 times every 24h. It may be needed in case admin contacts
          you for a welcome email (which they could delete by mistake or lose it
          as spam, or similar).
        </p>
        <HelpImage src={activationImage1} />
        <HelpImage src={activationImage2} />
      </>
    ),
  },
  {
    title: 'Access Code',
    content: (
      <>
        <p>
          Do not forget to click “Generate Access Code” for a new Client Admin.
          This action sends them an email with access code which they will need
          for Documents Approval. The access code is not linked to an
          Organization, it is the same for the same Client Admin whatever the
          Organization(s), no matter where you clicked to generate it. After each
          time the access code is generated, the system generates the new access
          code automatically every 12 months. When a new access code is generated
          manually or automatically, the previous one becomes inactive.
        </p>
        <HelpImage src={accessCodeImage} />
      </>      
    ),
  },
];
