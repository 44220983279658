import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Col, Row, Button } from 'reactstrap';
import moment from 'moment';

import InfoColumn from '../../../../components/shared/GeneralColumn';
import TaskDetailsEdit from '../Edit';
import TagsList from '../../../../components/shared/TagsList';

import { isUrlForLinkRecord, isUrlString } from '../../../../utils/is-url';
import { TASK_STATUS } from '../../../../constants/helper';
import { getDataUser } from '../../../../utils/get-store-user';
import TaskDeleteModal from '../../../../modals/TaskDelete';
import { deleteTask } from '../../../../utils/backend-helper';
import { toast } from 'react-toastify';
import MessageResetSuccess from '../../../../components/ToastSuccess';
import { SUCCESS_TASK_DELETE } from '../../../../constants/messages';
import PlaceholderText from '../../../../components/shared/PlaceholderText';
import UserEmailBlock from '../../../../components/shared/UserEmailBlock';

const TaskDetails = ({ taskDetails, user, updateTaskDetails }) => {
  const { id: organizationId } = useRouteMatch().params;
  const [editMode, updateEditMode] = useState(false);
  const history = useHistory();

  const { role } = getDataUser();

  useEffect(() => {
    if (history.location.state && history.location.state.openEditMode) {
      updateEditMode(true);
    }
  }, [history.location.state]);

  const [taskModel, updateTaskModel] = useState({
    number: '',
    name: '',
    status: '',
    dueDate: '',
    description: '',
    link: '',
    linkDescription: '',
    extraLink: '',
    extraLinkDescription: '',
    id: null,
    tags: [],
    assignee: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
    },
    assigner: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
    },
    creator: {
      firstName: '',
      lastName: '',
      email: '',
      role: '',
    },
    creationDate: '',
    creationTime: '',
    canBeEdited: false,
    canBeDeleted: false,
  });

  const {
    id,
    number,
    name,
    status,
    dueDate,
    description,
    link,
    assignee,
    assigner,
    creator,
    creationDate,
    creationTime,
    canBeEdited,
    linkDescription,
    extraLink,
    extraLinkDescription,
    tags,
    canBeDeleted,
  } = taskModel;

  useEffect(() => {
    if (taskDetails) {
      updateTaskModel(taskDetails);
    }
  }, [taskDetails]);

  const taskInfo = [
    {
      title: 'System #:',
      text: number,
    },
    {
      title: 'Action Name:',
      text: name,
    },
    {
      title: 'Action Status:',
      text: status !== undefined ? TASK_STATUS[status] : '',
    },
    {
      title: 'Due Date:',
      text: dueDate ? moment(dueDate).format('ll') : '',
    },
  ];

  const [deleteModal, updateDeleteModal] = useState({
    isOpen: false,
    model: {
      organizationId: null,
      ids: [],
    },
    values: {
      countChecked: null,
    },
    isMulty: false,
  });

  const handleDeleteTask = (organizationId, id, number, name) => {
    updateDeleteModal((prev) => ({
      ...prev,
      isOpen: true,
      values: { number, name },
      model: { organizationId, ids: [id] },
    }));
  };

  return (
    <div className="general">
      {editMode ? (
        <TaskDetailsEdit
          taskModel={taskModel}
          updateEditMode={updateEditMode}
          user={user}
          updateTaskDetails={updateTaskDetails}
        />
      ) : (
        <>
          <div className="general-header">
            <h6 className="mb-2 mb-sm-0">Action details</h6>
            <div className="d-flex">
              {canBeDeleted && (
                <Button
                  className="button-delete"
                  onClick={() =>
                    handleDeleteTask(organizationId, id, number, name)
                  }
                >
                  Delete action
                </Button>
              )}
              {canBeEdited && (
                <button
                  className="general__btn-edit"
                  onClick={() => updateEditMode((prev) => !prev)}
                >
                  <i className="bx bxs-edit-alt"></i> Edit
                </button>
              )}
            </div>
          </div>
          <hr />
          <div className="general-info">
            <div className="d-flex justify-content-between">
              {taskInfo.map(({ title, text, classes }) => {
                return (
                  <div key={title} className="info-column">
                    <p className="info-column__title">{title}</p>
                    <p className="info-column__text">{text}</p>
                  </div>
                );
              })}
            </div>
            <Row>
              <Col xs="12" className="mt-3">
                <InfoColumn title="Action description:" text={description} />
              </Col>
            </Row>
            <TagsList tags={tags} />
            <hr />
            <Row>
              <Col>
                <div className="info-column">
                  <p className="info-column__title">Link to Record:</p>
                  <p className="info-column__text">
                    {isUrlForLinkRecord(link, role) ? (
                      <a href={link} target='_self'>
                        <i className='bx bx-link top-link'></i>
                        {linkDescription}
                      </a>
                    ) : linkDescription ? (
                      <span>{linkDescription}</span>
                    ) : (
                      <PlaceholderText text="No link." />
                    )}
                  </p>
                </div>
              </Col>
              <Col>
                <div className='info-column'>
                  <p className='info-column__title info-column_right'>External Link:</p>
                  <p className='info-column__text info-column_right'>
                    {isUrlString(extraLink)? (
                      <a href={extraLink} target='_self'>
                        <i className='bx bx-link top-link'></i>
                        {extraLinkDescription}
                      </a>
                    ) : extraLinkDescription ? (
                      <span>{extraLinkDescription}</span>
                    ) : (
                      <PlaceholderText text="No link." />
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="12" md="6">
                <UserEmailBlock title="Action Assignee:" user={assignee} />
              </Col>
              <Col sm="12" md="6">
                <UserEmailBlock
                  title="Assigner:"
                  user={assigner}
                  containerClassName="user-email-right"
                />
              </Col>
            </Row>
            <hr />
            <Row className="mt-3">
              <Col sm="12" md="8">
                <UserEmailBlock title="Creator:" user={creator} />
              </Col>
              <Col sm="12" md="4">
                <div className="d-flex justify-content-end">
                  <InfoColumn
                    title="Creation Date:"
                    text={creationDate ? moment(creationDate).format('ll') : ''}
                  />
                  <InfoColumn
                    title="Creation Time:"
                    text={
                      creationTime
                        ? moment(creationDate).format('HH:mm:ss')
                        : ''
                    }
                    classes="info-column_right ml-5"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
      <TaskDeleteModal
        model={deleteModal.model}
        isOpen={deleteModal.isOpen}
        values={deleteModal.values}
        isMulty={false}
        onSubmit={(_, values) => {
          deleteTask(values).then(() => {
            toast.success(
              <MessageResetSuccess message={SUCCESS_TASK_DELETE} />,
            );
            history.push(`/organizations/${organizationId}/tasks`);
          });
        }}
        onCancel={() =>
          updateDeleteModal((prev) => ({ ...prev, isOpen: false }))
        }
      />
    </div>
  );
};

export default TaskDetails;
