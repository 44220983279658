import React, { useState, useEffect } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { getUserRole } from '../../pipes/getStatisPipe';
import { MAX_INPUT_LENGTH } from '../../constants/helper';
import { isEmpty } from '../../utils/empty-form';
import Button from '../../components/Button';

const AdminEditModal = ({
  onSubmit,
  onCancel,
  isOpen,
  values,
  user = 'Admin',
}) => {
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const onChange = (event) => {
    const { name, value } = event.target;
    setFields((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  useEffect(() => {
    setFields(() => ({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
    }));
  }, [values]);

  const isDisabled = !isEmpty(fields);

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Edit {getUserRole(user)}</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
        <div className="modal-body">
          <div className="form-group">
            <AvField
              name="firstName"
              label="First name"
              className="form-control"
              placeholder="Enter first name"
              maxLength={MAX_INPUT_LENGTH}
              type="text"
              value={fields.firstName}
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <AvField
              name="lastName"
              label="Last name"
              type="text"
              maxLength={MAX_INPUT_LENGTH}
              placeholder="Enter last name"
              onChange={onChange}
              value={fields.lastName}
            />
          </div>
          <div className="form-group">
            <AvField
              name="email"
              label="Email"
              type="email"
              placeholder="Enter email here"
              onChange={onChange}
              disabled={!values.emailConfirmed}
              value={values.email}
            />
          </div>
          <AvField
            name="id"
            type="text"
            value={values.id}
            className="btn-hidden"
          />
          <AvField
            name="currentEmail"
            type="text"
            value={values.email}
            className="btn-hidden"
          />
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={isDisabled}
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light mr-3"
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </div>
      </AvForm>
    </Modal>
  );
};

export default AdminEditModal;
