import moment from 'moment';
import { FORMAT_EXPORT_DATE } from '../constants/helper';

export const getFileName = (
  organizationName,
  title,
  user,
  ext,
  isSelected = false,
) => {
  return `${organizationName || ''} ${title} ${
    isSelected ? 'selected ' : ''
  } - version ${(user && user.email) || ''} -  ${moment().format(
    FORMAT_EXPORT_DATE,
  )}.${ext}`;
};
