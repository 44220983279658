import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import ForgetPassword from './auth/forget-password/reducer';
import ChangePassword from './auth/change-password/reducer';
import Profile from './auth/profile/reducer';
import Organization from './auth/organization/reducer';
import Admins from './auth/admins/reducer';
import SetPassword from './auth/set-password/reducer';
import Notification from './auth/notification/reducer';
import CompletionProgress from './auth/training-recrods/reducer';
import Search from './search/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  ForgetPassword,
  ChangePassword,
  Profile,
  Organization,
  Admins,
  SetPassword,
  Notification,
  CompletionProgress,
  Search,
});

export default rootReducer;
