import React, { useState, useContext, useMemo } from 'react';

const ViewDocumentContext = React.createContext();

export function useIsCanViewDocument() {
  return useContext(ViewDocumentContext);
}

const ViewDocumentProvider = ({ children }) => {
  const [isCanViewDocuments, updateIsCanViewDocuments] = useState(false);

  const [clientUserAccess, updateClientUserAccess] = useState({});

  const value = useMemo(() => {
    return {
      isCanViewDocuments: isCanViewDocuments,
      updateIsCanViewDocuments: updateIsCanViewDocuments,
      clientUserAccess: clientUserAccess,
      updateClientUserAccess: updateClientUserAccess,
    };
  }, [isCanViewDocuments, clientUserAccess]);

  return (
    <ViewDocumentContext.Provider value={value}>
      {children}
    </ViewDocumentContext.Provider>
  );
};

export default ViewDocumentProvider;
