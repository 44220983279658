import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import {
  ALL,
  ALL_ASSIGNEE,
  PASSED,
  PENDING,
} from '../../../../../../constants/table';

const FilterDropdown = ({ changeStatus }) => {
  const [isOpen, updateIsOpen] = useState(false);

  const toggle = () => {
    updateIsOpen((isOpen) => !isOpen);
  };

  const changeFilter = (e, status) => {
    e.stopPropagation();
    updateIsOpen((isOpen) => !isOpen);
    changeStatus(status);
  };

  const dropdownMenu = [
    {
      text: 'All',
      status: ALL,
    },
    {
      text: 'Assigned',
      status: ALL_ASSIGNEE,
    },
    {
      text: 'Assigned Pending',
      status: PENDING,
    },
    {
      text: 'Assigned Passed',
      status: PASSED,
    },
  ];

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={isOpen}>
        <div className="d-flex align-items-center d-cursor filter-item">
          <i className="font-size-20 bx bxs-filter-alt mr-2"></i>
          <span>Filter</span>
        </div>
      </DropdownToggle>
      <DropdownMenu className="p-2">
        {dropdownMenu.map(({ text, status }, i) => {
          return (
            <div
              key={i}
              className="dropdown-text"
              onClick={(e) => changeFilter(e, status)}
            >
              {text}
            </div>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default FilterDropdown;
