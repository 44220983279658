import { LIMIT_SEARCH_SELECT } from '../../constants/helper';

export const getParamsForActions = (params) => {
  return {
    ...params,
    limit: LIMIT_SEARCH_SELECT,
  };
};

export const getParamsForTraining = (params) => ({
  ...params,
  includeUsed: true,
});

export const getParmasForHumanResources = (params) => ({ ...params });
