import { takeEvery, fork, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import React from 'react';

import { FORGET_PASSWORD } from './actionTypes';
import { forgetPassword } from '../../../utils/backend-helper';

import { SUCCESS_MESSAGE_FORGET } from '../../../constants/messages';
import MessageResetSuccess from '../../../components/ToastSuccess';

function* forgetUser({ payload: { user, history } }) {
  try {
    yield call(forgetPassword, '/verifications/password', {
      email: user.email,
    });
    toast.success(<MessageResetSuccess message={SUCCESS_MESSAGE_FORGET} />);
  } catch (error) {
  } finally {
    history.push('/Login');
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
