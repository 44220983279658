import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Col } from 'reactstrap';

import ActionButton from './Action';

import ActionLogo from '../../../../assets/images/dashboard/ic-action-white.svg';
import DocumentLogo from '../../../../assets/images/dashboard/ic-document-white.svg';
import TrainingLogo from '../../../../assets/images/dashboard/ic-training-white.svg';

import { useIsCanViewDocument } from '../../../../contexts/ViewDocument';
import { getDataUser } from '../../../../utils/get-store-user';
import { User } from '../../../../constants/role';

const DashboardButtons = () => {
  const history = useHistory();
  const { id: organizationId } = useRouteMatch().params;

  const { role } = getDataUser();

  const {
    clientUserAccess: { canAddTasks, canAddDocuments, canAddTrainingRecords },
  } = useIsCanViewDocument();

  return (
    <Col
      sm="12"
      lg="6"
      className="d-flex align-items-center justify-content-end"
    >
      <div className='w-100'>
        {(role !== User || canAddTasks) && (
          <ActionButton
            onClick={() =>
              history.push(`/organizations/${organizationId}/tasks/create`)
            }
          >
            <img src={ActionLogo} alt="action logo" />
            New Action
          </ActionButton>
        )}
        {(role !== User || canAddTrainingRecords) && (
          <ActionButton
            onClick={() =>
              history.push(
                `/organizations/${organizationId}/training-records/create`,
              )
            }
          >
            <img src={TrainingLogo} alt="action logo" />
            New Training
          </ActionButton>
        )}
        {(role !== User || canAddDocuments) && (
          <ActionButton
            onClick={() =>
              history.push(`/organizations/${organizationId}/documents/create`)
            }
          >
            <img src={DocumentLogo} alt="action logo" />
            New Document
          </ActionButton>
        )}
      </div>
    </Col>
  );
};

export default DashboardButtons;
