import React from 'react';
import { Row, Col, Modal } from 'reactstrap';
import moment from 'moment';

import InfoColumn from '../../components/shared/GeneralColumn';
import TrainingAttachedFile from '../../pages/Organization/TrainingRecords/Details/AttachedFile';
import TrainingRecordsQuestions from '../../pages/Organization/TrainingRecords/Completion/Questions';

import { FORMAT_TIME } from '../../constants/helper';
import { isUrlForLinkRecord } from '../../utils/is-url';
import { getDataUser } from '../../utils/get-store-user';

const PassedTrainingModal = ({ isOpen, onCancel, values }) => {
  const { role } = getDataUser();

  return (
    <Modal
      className="p-2"
      isOpen={isOpen}
      toggle={onCancel}
      centered={true}
      size="lg"
    >
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Passed Training Details</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <InfoColumn title="System #:" text={values.number} />
          </Col>
          <Col>
            <InfoColumn title="Training Name:" text={values.name} />
          </Col>
          <Col>
            <InfoColumn
              title="Due Date:"
              text={moment(values.dueDate).format('ll')}
              classes="text-right"
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="mt-3">
            <InfoColumn title="Description:" text={values.description} />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs='12' className='mt-2 mb-2'>
            <InfoColumn 
              title='Link to Record:'
              text={isUrlForLinkRecord(values.link, role) ? (
                <a href={values.link} target='_self'>
                  <i className='bx bx-link top-link'></i>
                  {values.linkDescription}
                </a>
              ) : (
                <span>{values.linkDescription || '-'}</span>
              )}
            />
          </Col>
        </Row>
        <hr />
        <div className="general-attached mb-4">
          <div className="info-column">
            <p className="info-column__title">File(s) attached:</p>
            <TrainingAttachedFile files={values.files} canDownload={false} />
          </div>
        </div>
        <hr />
        <Row>
          <Col xs="12" className="mt-3">
            <div className="info-column">
              <p className="info-column__title">Questions:</p>
              <TrainingRecordsQuestions questions={values.questions} />
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="mt-2 mb-4">
            <InfoColumn
              title="Completion Date:"
              text={moment(values.createdAt).format('ll')}
            />
          </Col>
          <Col className="mt-2 mb-4">
            <InfoColumn
              title="Completion Time:"
              text={moment(values.createdAt).format(FORMAT_TIME)}
              classes="text-right"
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default PassedTrainingModal;
