import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { CardBody, Col, Row, Container, Card } from 'reactstrap';

import Breadcrumbs from '../../../components/shared/Breadcrumbs/Breadcrumbs';
import TaskDetails from './Details';
import { getTaskDetails } from '../../../utils/backend-helper';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const TaskView = ({ user }) => {
  const { id: organizationId, taskId } = useRouteMatch().params;

  const [taskDetails, updateTaskDetails] = useState();

  const onError = useErrorHandler();

  useEffect(() => {
    getTaskDetails(taskId, organizationId)
      .then(({ data }) => updateTaskDetails(data))
      .catch(onError);
  }, [onError, organizationId, taskId]);

  const breadCrumbItems = [
    {
      link: `/organizations/${organizationId}/documents`,
      title: 'Actions',
    },
    {
      link: '',
      title: taskDetails && taskDetails.name,
    },
  ];

  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            name="Actions"
            breadCrumbItems={breadCrumbItems}
            link={`/organizations/${organizationId}/tasks`}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <TaskDetails
                    taskDetails={taskDetails}
                    user={user}
                    updateTaskDetails={updateTaskDetails}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default TaskView;
