import {
  SET_USER_PASSWORD,
  IS_VALID_SET_TOKEN,
  CONFIRM_EMAIL_PASSWORD,
} from './actionTypes';

export const userSetPassword = (data, history) => {
  return {
    type: SET_USER_PASSWORD,
    payload: { data, history },
  };
};

export const isValidSetToken = (data, history) => {
  return {
    type: IS_VALID_SET_TOKEN,
    payload: { data, history },
  };
};

export const confirmEmailPassword = (data, history) => {
  return {
    type: CONFIRM_EMAIL_PASSWORD,
    payload: { data, history },
  };
};
