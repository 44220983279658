import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import React from 'react';

import { CHANGE_PASSWORD_PROFILE, CHANGE_EMAIL_PROFILE } from './actionTypes';
import { profileSuccess, profileError } from './actions';
import { SUCCESS_PASSWORD_UPDATE } from '../../../constants/messages';

import {
  changePasswordAdminProfile,
  changeEmailAdminProfile,
} from '../../../utils/backend-helper';
import MessageResetSuccess from '../../../components/ToastSuccess';

function* changePasswordProfile({
  payload: { oldPassword, password, confirmPassword },
}) {
  try {
    const response = yield call(
      changePasswordAdminProfile,
      '/users/me/password',
      { oldPassword, password, confirmPassword },
    );
    if (response) {
      toast.success(<MessageResetSuccess message={SUCCESS_PASSWORD_UPDATE} />);
    }
  } catch (error) {}
}

function* changeEmailProfile({ user }) {
  try {
    const response = yield call(changeEmailAdminProfile, '/users/me/password', {
      email: user.email,
    });
    if (response) {
      yield put(profileSuccess('Reset link are sended to your mailbox'));
    }
  } catch (error) {
    yield put(profileError(error));
  }
}

export function* watchUserPasswordChange() {
  yield takeEvery(CHANGE_PASSWORD_PROFILE, changePasswordProfile);
}

export function* watchUserEmailChange() {
  yield takeEvery(CHANGE_EMAIL_PROFILE, changeEmailProfile);
}

function* ProfileSaga() {
  yield all([fork(watchUserPasswordChange), fork(watchUserEmailChange)]);
}

export default ProfileSaga;
