import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  Card,
  Row,
  Col,
  CardBody,
  Button,
  ButtonGroup,
  Container,
  Spinner,
} from 'reactstrap';

import {
  uploadImage,
  clearImageSuccess,
} from '../../../store/auth/admins/actions';
import { dataUrlToFile } from '../../../utils/file-helper';
import CustomButton from '../../../components/Button';

const ImageCropper = ({
  handleToogleModal,
  urlFile,
  uploadImage,
  imageSuccress,
  clearImageSuccess,
}) => {
  const routeId = Number(useRouteMatch().params.id);
  const cropperRef = useRef(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const history = useHistory();

  const [cropperConfig, setCropperConfig] = useState({
    src: urlFile,
    cropResult: null,
    ratio: 16 / 9,
    zoom: 0.5,
    dragMode: 'crop',
    rotate: 0,
  });

  const onCropImage = () => {
    setIsLoadingImage(true);

    const cropper = cropperRef.current.cropper;

    const blob = cropper.getCroppedCanvas().toDataURL();

    const croppedImage = new Image();
    croppedImage.src = URL.createObjectURL(dataUrlToFile(blob));

    croppedImage.onload = () => {
      const body = new FormData();
      body.append('file', dataUrlToFile(blob));

      uploadImage(body, routeId, history);
    };
  };

  const handleRotate = (direction = 'right') => {
    setCropperConfig((prev) => ({
      ...prev,
      rotate: direction === 'right' ? prev.rotate + 90 : prev.rotate - 90,
    }));
  };

  useEffect(() => {
    if (imageSuccress) {
      setIsLoadingImage(false);
      handleToogleModal(imageSuccress);
      clearImageSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSuccress]);

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <div className="modal-header  align-items-center">
                <h5 className="modal-title mt-0">Crop Image</h5>
                <button
                  type="button"
                  onClick={handleToogleModal}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bx bx-x close-icon-modal"></i>
                </button>
              </div>
              <div className="mt-4">
                <div className="img-container mb-2">
                  <Cropper
                    style={{ height: 400, width: '100%' }}
                    aspectRatio={cropperConfig.ratio}
                    preview=".img-preview"
                    guides={false}
                    src={cropperConfig.src}
                    rotateTo={cropperConfig.rotate}
                    dragMode={cropperConfig.dragMode}
                    ref={cropperRef}
                    checkOrientation={true}
                  />
                </div>
              </div>
              <Row id="actions">
                <Col
                  sm={6}
                  xl={7}
                  className="img-crop-preview-btns docs-buttons w-100"
                >
                  <div className="button-items mt-2">
                    <Row>
                      <Col sm={12}>
                        <ButtonGroup>
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => handleRotate('left')}
                            title="Rotate Left"
                          >
                            <span className="docs-tooltip" id="rotate1">
                              <span className="mdi mdi-rotate-left"></span>
                            </span>
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => handleRotate('right')}
                            title="Rotate Right"
                          >
                            <span className="docs-tooltip" id="rotate2">
                              <span className="mdi mdi-rotate-right"></span>
                            </span>
                          </Button>
                        </ButtonGroup>
                      </Col>
                      <Col sm={12}>
                        <CustomButton
                          type="button"
                          color="btn btn-primary w-md waves-effect waves-light"
                          handler={onCropImage}
                        >
                          Crop & save
                        </CustomButton>
                        <CustomButton
                          color="btn btn-light w-md waves-effect waves-light mr-3"
                          handler={handleToogleModal}
                        >
                          Cancel
                        </CustomButton>
                        {isLoadingImage && (
                          <Spinner className="mr-2" color="primary" />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col sm={6} xl={5}>
                  <div className="docs-preview image-crop-preview clearfix">
                    <div className="img-preview preview-lg"></div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ Admins: { imageSuccress } }) => ({ imageSuccress });

export default connect(mapStateToProps, { uploadImage, clearImageSuccess })(
  ImageCropper,
);
