import { uniqueData } from './unique-data';

const USERS = 'users';

export const changeFieldSelect = (fields) => {
  if (!fields) {
    return [];
  }

  return Object.values(fields).reduce((acc, value) => {
    acc.push(value['id']);
    return acc;
  }, []);
};

export const changeFieldUserSelect = (users) => {
  if (!users) {
    return [];
  }

  return Object.values(users).reduce((acc, { id }) => {
    acc.push({ userId: id, trainingIds: [] });
    return acc;
  }, []);
};

export const changeUserAndGroupSelect = (fields) => {
  if (!fields) {
    return [[], []];
  }

  return Object.values(fields).reduce(
    (acc, value) => {
      const { id } = value;
      value.group === USERS ? acc[0].push(id) : acc[1].push(id);
      return acc;
    },
    [[], []],
  );
};

export const changeUserSelect = (fields) => {
  if (!fields) {
    return [];
  }

  return Object.values(fields).reduce((acc, value) => {
    acc.push(value['user']);
    return acc;
  }, []);
};

export const changeUserModel = (users) => {
  if (!users) return [];

  return Object.values(users).reduce((acc, { user: { id } }) => {
    acc.push({ userId: id, trainingIds: [] });
    return acc;
  }, []);
};

export const changeTrainingModel = (trainings, additionalList) => {
  if (!trainings) return [];

  return uniqueData([
    ...Object.values(trainings).reduce((acc, { id }) => {
      if (id) acc.push(id);
      return acc;
    }, []),
    ...additionalList,
  ]);
};

export const changeTrainingDeleteModel = (trainings) => {
  if (!trainings) return [];

  return trainings.map(({ id }) => id);
};

export const changeUserRequest = (users, options, id) => {
  return users.map((user) => {
    const { userId } = user;
    return id === userId ? { ...user, trainingIds: options } : user;
  });
};

export const changeTrainingIds = (users) =>
  users.map((user) => ({
    ...user,
    trainingIds: user.trainingIds.map(({ id }) => id),
  }));
