import React from 'react';
import DocumentText from './DocumentText';

import { documentTextsLeft, documentTextsRight } from './documentContentMok';

import documentImage from '../../../../assets/images/help-screen/flow/flow-1.png';
import HelpImage from '../../shared/HelpImage';


const DocumentContent = () => {
  return (
    <>
      <p>
        A Document is an artifact that allows the Organization to track
        documents which are in use in your Organization. A user must have access
        permissions granted to view or edit Documents. These permissions are
        granted by an Admin through User Group settings.
      </p>
      <HelpImage src={documentImage} />
      <div className="help-document-content">
        <div className="help-document-content__list">
          {documentTextsLeft.map((props, index) => (
            <DocumentText key={index} {...props} />
          ))}
        </div>
        <div className="help-document-content__list">
          {documentTextsRight.map((props, index) => (
            <DocumentText key={index} {...props} />
          ))}
        </div>
      </div>
    </>
  );
};

export default DocumentContent;
