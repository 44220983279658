import {
  UPDATE_COMPLETION_PROGESS,
  CLOSE_COMPLETION_PROGESS,
} from './actionTypes';

const initialState = {
  successCompletion: false,
};

const completionProgress = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COMPLETION_PROGESS:
      state = {
        ...state,
        successCompletion: true,
      };
      break;
    case CLOSE_COMPLETION_PROGESS:
      state = {
        ...state,
        successCompletion: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default completionProgress;
