import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import { Col, Row } from 'reactstrap';
import Index from '../../CustomMultiSelect';

import { configTags } from '../../../utils/config-get-organization';
import { getTags } from '../../../utils/api/organization-api';

const TagField = ({ onChange }) => {
  const { id: organizationId } = useRouteMatch().params;

  const getTagsFromAPI = useCallback(() => {
    return getTags(organizationId);
  }, [organizationId]);

  return (
    <Row>
      <Col>
        <div className="info-column">
          <p className="info-column__title">Tag(s)</p>
          <div className="form-group">
            <Index
              handleRequest={getTagsFromAPI}
              configOptions={configTags}
              placeholder="Choose tag(s)"
              onChange={onChange}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default TagField;
