import React, { useState, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';

import TrainingCompletionView from './CompletionView';

import { getCompletionProgress } from '../../../../utils/api/training-records-api';
import { trainingCompletionModel } from '../../../../models/training-completion/training-completion-detail';
import { closeCompletion } from '../../../../store/actions';
import { useErrorHandler } from '../../../../custom-hooks/useErrorHandler';

const TrainingRecordCompletion = ({
  user,
  closeCompletion,
  successCompletion,
}) => {
  const onError = useErrorHandler();

  const { recordId, id: organizationId } = useRouteMatch().params;

  const [completionData, updateCompletionData] = useState(
    trainingCompletionModel,
  );

  const getCompletionProgressAPI = useCallback(() => {
    getCompletionProgress(recordId, organizationId)
      .then(({ data }) => {
        updateCompletionData(data);
      })
      .catch(onError);
  }, [organizationId, recordId, onError]);

  useEffect(() => {
    getCompletionProgressAPI();
  }, [getCompletionProgressAPI]);

  useEffect(() => {
    if (successCompletion) {
      getCompletionProgressAPI();
      closeCompletion();
    }
  }, [successCompletion, getCompletionProgressAPI, closeCompletion]);

  return (
    <div className="general">
      <TrainingCompletionView user={user} completionData={completionData} />
    </div>
  );
};

const mapStateToProps = ({ CompletionProgress: { successCompletion } }) => ({
  successCompletion,
});

export default connect(mapStateToProps, { closeCompletion })(
  TrainingRecordCompletion,
);
