/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

import {
  LIMIT,
  INITIAL_OFFSET,
  INITIAL_PAGE,
  TOTAL_ITEMS,
  PAGE_COUNT,
} from '../../../constants/table';
import {
  deleteDocument,
  getDataTableColumns,
} from '../../../utils/backend-helper';
import DisplayColumnsModal from '../../../modals/DisplayColumns';
import TableBody from '../../../components/tables/TableBody';
import TableHead from '../../../components/tables/TableHead';
import Breadcrumbs from '../../../components/shared/Breadcrumbs/Breadcrumbs';
import { columnsHead, columnsBody } from './Table';
import { getIdsCheckboxChecked } from '../../../utils/check-checkbox';
import DocumentDeleteModal from '../../../modals/DocumentDelete';
import MessageResetSuccess from '../../../components/ToastSuccess';
import { getFileName } from '../../../utils/get-file-name';
import { getExportParams } from '../../../utils/get-export-params';
import { useDocumentExport } from '../../../custom-hooks/useDocumentExport';
import {
  CSV,
  PDF,
  ZIP,
  DEFAULT_DOCUMENT_SORT,
} from '../../../constants/helper';
import { orderTable } from '../../../utils/order-table';
import { getColumnsFilter } from '../../../utils/get-columns-search';
import { mergetParams } from '../../../utils/merge-table-params';
import { getPageCount } from '../../../utils/table';
import { useCheckboxTable } from '../../../custom-hooks/useCheckboxTable';
import { useCheckboxCount } from '../../../custom-hooks/useCheckboxCount';
import { selectAll } from '../../../utils/select-all';
import { isMultySelected } from '../../../utils/is-multy-selected';
import TableHeader from '../../../components/tables/TableHeader';
import TableSelectedDelete from '../../../components/tables/SelectedDelete';
import TableFooter from '../../../components/tables/TableFooter';
import { useIsCanViewDocument } from '../../../contexts/ViewDocument';
import { getDataUser } from '../../../utils/get-store-user';
import FilterDocument from '../../../components/tables/FilterDocuments';
import TableFilterMenu from '../../../components/tables/FilterMenu';
import { removeFilterIds } from '../../../utils/documents/remove-filter-by-id';
import { getDocumentTypes } from '../../../utils/api/organization-api';
import { changeCheckboxChecked } from '../../../utils/shared/change-checkbox-checked';
import { CHECKED_FALSE } from '../../../constants/document';
import { changeFilterChecked } from '../../../utils/api/change-filter-checked';
import TableSpinner from '../../../components/tables/Spinner';
import TablePaginationText from '../../../components/tables/PaginationText';
import { dragReorder } from '../../../utils/dragable-helpers/dragable-reoder';
import {
  getChangedResizeColumns,
  getEndResizeColumns,
} from '../../../utils/tables-helper/resizable';
import { useTableWidth } from '../../../custom-hooks/useTableWidth';
import { deleteRow } from '../../../utils/tables-helper/deleteRow';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const Documents = ({ organizationName }) => {
  const { id: organizationId } = useRouteMatch().params;

  const history = useHistory();
  const user = getDataUser();

  const [documents, setDocuments] = useState();

  const [columnsVisibility, updateColumnsVisibility] = useState({
    actions: {
      label: 'Options',
      isVisible: true,
    },
    approvedAt: {
      label: 'Approved Date',
      isVisible: true,
      accessor: 'ApprovedAt',
      sort: true,
    },
    name: {
      label: 'Name',
      isVisible: true,
      accessor: 'Name',
      sort: true,
    },
    type: {
      label: 'Type',
      isVisible: true,
      accessor: 'Type',
      sort: true,
    },
    status: {
      label: 'Status',
      isVisible: true,
      accessor: 'Status',
      sort: true,
    },
    revision: {
      label: 'Revision',
      isVisible: true,
      accessor: 'Revision',
      sort: true,
    },
    reviewedAt: {
      label: 'Reviewed Date',
      isVisible: false,
      accessor: 'ReviewedAt',
      sort: true,
    },
    isFinal: {
      isVisible: true,
      accessor: 'IsFinal',
      classes: 'hidden',
    },
    revisionName: {
      isVisible: true,
      accessor: 'RevisionName',
      classes: 'hidden',
    },
  });

  const [isLoading, updateIsLoading] = useState(false);

  const [pagination, setPagination] = useState({
    data: [],
    limit: LIMIT,
    offset: INITIAL_OFFSET,
    pageNumber: INITIAL_PAGE,
    pageCount: PAGE_COUNT,
    totalItems: TOTAL_ITEMS,
    columns: getColumnsFilter(columnsVisibility),
    docTypeIds: [],
    search: '',
    orderBy: '',
    orderType: '',
  });

  const getDocumentsList = useCallback(
    (
      organizationId,
      limit,
      offset,
      search,
      orderBy,
      orderType,
      docTypeIds,
      columns,
    ) => {
      const params = mergetParams({
        organizationId,
        limit,
        offset,
        search,
        orderBy,
        orderType,
        docTypeIds,
      });
      return getDataTableColumns('/documents', params, columns);
    },
    [],
  );

  const onError = useErrorHandler();

  const updatingTable = async (success) => {
    try {
      if (!success) return;
      updateIsLoading(true);
      const { limit, offset, search, orderBy, orderType, columns, docTypeIds } =
        pagination;
      const data = await getDocumentsList(
        organizationId,
        limit,
        offset,
        search,
        orderBy,
        orderType,
        docTypeIds,
        columns,
      );
      setDocuments(data);
    } catch (error) {
      onError(error);
    } finally {
      updateIsLoading(false);
    }
  };

  useEffect(() => {
    const getItems = async (
      organizationId,
      limit,
      offset,
      search,
      orderBy,
      orderType,
      docTypeIds,
      columns,
    ) => {
      try {
        updateIsLoading(true);
        const data = await getDocumentsList(
          organizationId,
          limit,
          offset,
          search,
          orderBy,
          orderType,
          docTypeIds,
          columns,
        );
        setDocuments(data);
      } catch (error) {
        onError(error);
      } finally {
        updateIsLoading(false);
      }
    };

    getItems(
      organizationId,
      pagination.limit,
      pagination.offset,
      pagination.search,
      pagination.orderBy,
      pagination.orderType,
      pagination.docTypeIds,
      pagination.columns,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.limit,
    pagination.search,
    pagination.offset,
    pagination.orderBy,
    pagination.orderType,
    pagination.docTypeIds,
    getDocumentsList,
  ]);

  const debounceHandleUpdate = useMemo(() => {
    return debounce((input) => {
      setPagination((prev) => ({
        ...prev,
        pageNumber: INITIAL_PAGE,
        offset: INITIAL_OFFSET,
        search: input,
      }));
    }, 1000);
  }, []);

  const handleOrder = (key) => {
    setPagination((prev) => orderTable(key, prev));
  };

  const handleChangeLimit = (value) => {
    setPagination((prev) => ({
      ...prev,
      pageNumber: INITIAL_PAGE,
      offset: INITIAL_OFFSET,
      limit: value,
    }));
  };

  const handlePageClick = ({ selected }) => {
    const offset = Math.ceil(selected * pagination.limit);
    setPagination((prev) => ({
      ...prev,
      pageNumber: selected,
      offset,
    }));
  };

  const handleAddNewModal = (organizationId) => {
    history.push(`/organizations/${organizationId}/documents/create`);
  };

  const [displayModal, setDisplayModal] = useState({
    isOpen: false,
  });

  const handleToggleColumn = () => {
    setDisplayModal((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  useEffect(() => {
    if (!documents) return;
    const {
      data,
      pagination: { totalCount },
    } = documents;
    setPagination((prev) => ({
      ...prev,
      data,
      pageCount: getPageCount(totalCount, prev.limit),
      totalItems: totalCount,
    }));
  }, [documents]);

  const [columnsHidden, updateColumnsHidden] = useState({
    type: true,
    name: true,
    revision: true,
    status: true,
    approvedAt: true,
    reviewedAt: false,
    actions: true,
  });

  useEffect(() => {
    const getColumns = getColumnsFilter(columnsVisibility);

    setPagination((prev) => ({
      ...prev,
      columns: getColumns,
    }));
  }, [columnsVisibility]);

  const handleSaveColumns = () => {
    updateColumnsHidden((prev) => {
      return Object.entries(prev).reduce((acc, [key, value]) => {
        acc[key] = columnsVisibility[key].isVisible;
        return acc;
      }, {});
    });
    setDisplayModal((prev) => ({ ...prev, isOpen: false }));
  };

  const handleUpdateColumnsVisible = (key) => {
    updateColumnsVisibility((prev) => ({
      ...prev,
      [key]: { ...prev[key], isVisible: !prev[key].isVisible },
    }));
  };

  const handleSelectAll = (toggle) => {
    updateColumnsVisibility(selectAll(columnsVisibility, toggle));
  };

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    model: {
      organizationId: null,
      documentIds: null,
    },
    values: {
      type: '',
      name: '',
      number: '',
      revision: '',
    },
    isMulty: true,
  });

  const handleDeleteMultyDocuments = (data, organizationId, countChecked) => {
    const ids = getIdsCheckboxChecked(data);

    setDeleteModal((prev) => ({
      isOpen: !prev.isOpen,
      model: {
        organizationId,
        documentIds: ids,
      },
      values: { countChecked },
    }));
  };

  const {
    checkedHead,
    updateCheckedHead,
    checkAllDocuments,
    handleUpdateChecked,
    unCheckAllDocuments,
  } = useCheckboxTable(pagination, setPagination);

  const { countChecked } = useCheckboxCount(pagination.data);

  const breadCrumbItems = [
    {
      link: '',
      title: 'Documents',
    },
  ];

  const { downloadFile } = useDocumentExport();
  const { downloadFile: downloadPdf } = useDocumentExport();
  const { downloadFile: downloadZip } = useDocumentExport();

  const handleDownloadManyDocuments = (
    organizationId,
    pagination,
    dataOfColumns,
  ) => {
    const { search, orderBy, orderType, data } = pagination;
    const fileName = getFileName(organizationName, '', user, PDF);

    const body = getExportParams(
      data,
      dataOfColumns,
      { search, orderBy, orderType },
      organizationId,
    );
    downloadPdf('/export/many-Documents', body, fileName);
  };

  const handleDownloadOneDocument = (organizationId, pagination) => {
    const { search, data } = pagination;

    const fileExt = isMultySelected(data) ? ZIP : PDF;
    const fileName = getFileName(
      organizationName,
      'Documents',
      user,
      fileExt,
      false,
    );

    const body = getExportParams(data, null, { search }, organizationId);
    downloadZip('/export/one-document', body, fileName);
  };

  const handleDownloadCSV = (organizationId, pagination, dataOfColumns) => {
    let { search, orderBy, orderType, data } = pagination;

    const isCheckedList = data.some(({ isChecked }) => isChecked);
    const isFilter = search || orderType || orderBy || isCheckedList;

    const fileName = getFileName(organizationName, '', user, CSV, isFilter);

    if (!orderBy) {
      orderBy = DEFAULT_DOCUMENT_SORT;
    }

    const body = getExportParams(
      data,
      dataOfColumns,
      { search, orderBy, orderType },
      organizationId,
    );
    downloadFile('/export/Documents', body, fileName);
  };

  const handleDownloadDocumentAttachments = (
    organizationId,
    pagination,
    dataOfColumns,
    docTypeIds,
  ) => {
    const { search, orderBy, orderType, data } = pagination;

    const fileName = getFileName(
      organizationName,
      'Document Attachments',
      user,
      ZIP,
    );
    const body = getExportParams(
      data,
      dataOfColumns,
      { search, orderBy, orderType },
      organizationId,
      null,
      docTypeIds,
    );

    downloadFile('/export/document-attachments', body, fileName);
  };

  const {
    clientUserAccess: { canAddDocuments },
  } = useIsCanViewDocument();

  const [documentTypes, updateDocumentTypes] = useState([]);

  const getDocumentTypeAPI = useCallback(() => {
    return getDocumentTypes(organizationId).then(({ data }) =>
      updateDocumentTypes(data || []),
    );
  }, [organizationId]);

  useEffect(() => {
    getDocumentTypeAPI();
  }, [organizationId]);

  const [columnsMain, updateColumnsMain] = useState(columnsHead);
  const [columns, updateColumns] = useState(columnsBody);

  const tableRef = useTableWidth(updateColumnsMain);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs name="Documents" breadCrumbItems={breadCrumbItems} />
          <Row className="d-flex justify-content-center">
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableHeader
                    title="List of Documents"
                    isCanAdd={canAddDocuments}
                    handleAddNew={() => handleAddNewModal(organizationId)}
                    downloadCSV={() =>
                      handleDownloadCSV(
                        organizationId,
                        pagination,
                        columnsVisibility,
                      )
                    }
                    downloadOneDocument={() =>
                      handleDownloadOneDocument(organizationId, pagination)
                    }
                    downloadManyDocuments={() =>
                      handleDownloadManyDocuments(
                        organizationId,
                        pagination,
                        columnsVisibility,
                      )
                    }
                    downloadDocumentAttachments={(docTypeIds) =>
                      handleDownloadDocumentAttachments(
                        organizationId,
                        pagination,
                        columnsVisibility,
                        docTypeIds,
                      )
                    }
                    manageColumns={handleToggleColumn}
                    handleSearch={debounceHandleUpdate}
                    FilterComponent={
                      <FilterDocument
                        documentTypes={documentTypes}
                        updateDocumentTypes={updateDocumentTypes}
                        changeFilters={(ids) => {
                          setPagination((prev) => ({
                            ...prev,
                            docTypeIds: ids,
                          }));
                        }}
                      />
                    }
                  />
                  <TableSelectedDelete
                    title="document"
                    countChecked={countChecked}
                    handleDelete={() =>
                      handleDeleteMultyDocuments(
                        pagination.data,
                        organizationId,
                        countChecked,
                      )
                    }
                  />
                  <TableFilterMenu
                    documentTypes={documentTypes}
                    handleDelete={(id) => {
                      updateDocumentTypes((menu) =>
                        changeCheckboxChecked(menu, id),
                      );
                      setPagination((prev) => ({
                        ...prev,
                        docTypeIds: removeFilterIds(prev.docTypeIds, id),
                      }));
                    }}
                    handleClearAll={() => {
                      updateDocumentTypes((menu) =>
                        changeFilterChecked(menu, CHECKED_FALSE),
                      );
                      setPagination((prev) => ({ ...prev, docTypeIds: [] }));
                    }}
                  />
                  <div ref={tableRef} className="table-wrapper">
                    <Table className="table-custom table table-centered table-nowrap table-document">
                      <TableHead
                        columnsHidden={columnsHidden}
                        handleOrder={handleOrder}
                        pagination={pagination}
                        checkAllDocuments={checkAllDocuments}
                        checkedHead={checkedHead}
                        updateCheckedHead={checkedHead}
                        columnsHead={columnsMain}
                        columns={columns}
                        handleChangeColumns={(
                          columns,
                          columnsHead,
                          startIndex,
                          endIndex,
                        ) => {
                          updateColumns(
                            dragReorder(columns, startIndex, endIndex),
                          );
                          updateColumnsMain(
                            dragReorder(columnsHead, startIndex, endIndex),
                          );
                        }}
                        onResizeColumns={(columnIndex, changedWidth) => {
                          updateColumnsMain((columns) =>
                            getChangedResizeColumns(
                              columns,
                              columnIndex,
                              changedWidth,
                            ),
                          );
                        }}
                        onResizeEnd={(columnIndex) => {
                          updateColumnsMain((columns) =>
                            getEndResizeColumns(columns, columnIndex),
                          );
                        }}
                      />
                      <TableBody
                        user={user}
                        onDeleteRow={(deletedId) => {
                          setPagination((pagination) =>
                            deleteRow(pagination, deletedId),
                          );
                        }}
                        columnsHidden={columnsHidden}
                        pagination={pagination}
                        setPagination={setPagination}
                        columnsBody={columns}
                        organizationId={organizationId}
                        updateCheckedHead={updateCheckedHead}
                        handleUpdateChecked={handleUpdateChecked}
                      />
                    </Table>
                  </div>
                  {!!pagination.data.length && (
                    <TableFooter
                      limit={pagination.limit}
                      handleChangeLimit={handleChangeLimit}
                      pageCount={pagination.pageCount}
                      pageNumber={pagination.pageNumber}
                      handlePageClick={handlePageClick}
                    />
                  )}
                  {!(pagination.data.length || isLoading) && (
                    <TablePaginationText text="There are no Documents to display" />
                  )}
                  {isLoading && <TableSpinner />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DisplayColumnsModal
        columnsVisibility={columnsVisibility}
        isOpen={displayModal.isOpen}
        handleUpdateColumnsVisible={handleUpdateColumnsVisible}
        handleSelectAll={handleSelectAll}
        onCancel={() => setDisplayModal((prev) => ({ ...prev, isOpen: false }))}
        handleSaveColumns={handleSaveColumns}
      />
      <DocumentDeleteModal
        model={deleteModal.model}
        isOpen={deleteModal.isOpen}
        values={deleteModal.values}
        isMulty={true}
        onSubmit={(_, { organizationId, documentIds }) => {
          deleteDocument(organizationId, documentIds).then(({ data }) => {
            const { message } = data;

            updatingTable(true);
            unCheckAllDocuments();
            toast.success(<MessageResetSuccess message={message} />);
            setDeleteModal((prev) => ({ ...prev, isOpen: false }));
          });
        }}
        onCancel={() => setDeleteModal((prev) => ({ ...prev, isOpen: false }))}
      />
    </>
  );
};

export default Documents;
