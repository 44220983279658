import React from 'react';
import { Card, CardBody, Media } from 'reactstrap';
import classnames from 'classnames';

const CardCounter = ({ title, counter, src, background = '' }) => {
  return (
    <Card>
      <CardBody className="p-4">
        <Media className="d-cursor-none">
          <Media body>
            <p className="text-muted font-weight-medium">{title}</p>
            <h4 className="mb-0">{counter}</h4>
          </Media>
          <div
            className={classnames(
              'mini-stat-icon icon-sm rounded-circle d-flex align-items-center justify-content-center',
              background,
            )}
          >
            <img src={src} alt="counter icon" />
          </div>
        </Media>
      </CardBody>
    </Card>
  );
};

export default CardCounter;
