import React from 'react';

import { formatBytes } from '../../../utils/format-bytes-helper';

const DocumentDownloadFiles = ({ files = [], title, handleDownload }) => {
  if (files.length) {
    return (
      <div className="general-attached">
        <div className="info-column">
          <p className="info-column__title">{title}</p>
          <div className="file-attached-container">
            {files.map(({ fileName, fileSize, id }) => {
              return (
                <div key={id} className="document-attached">
                  <div
                    className="file-wrapper"
                    onClick={() => handleDownload(id)}
                  >
                    <i className="bx bxs-file-doc"></i>
                  </div>
                  <div className="file-info">
                    <p className="file-info__name">{fileName}</p>
                    <p className="file-info__size">
                      Size: {formatBytes(fileSize)}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default DocumentDownloadFiles;
