import React from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';

import ProfileHeader from '../../../../components/Profile/Header';
import ProfileCard from '../../../../components/Profile/Card';

const UserProfile = ({ handleUpdateUser }) => {
  return (
    <Container>
      <ProfileHeader />
      <ProfileCard handleUpdateUser={handleUpdateUser} />
    </Container>
  );
};

const mapStateToProps = ({ Profile: { success } }) => ({ success });

export default connect(mapStateToProps)(UserProfile);
