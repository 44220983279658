import React from 'react';

import ActionsContent from './ActionsContent';
import DocumentContent from './DocumentContent';
import TrainingRecordContent from './TrainingRecordsContent';
import HumanResourcesContent from './HumanResourcesContent';
import HelpImage from '../shared/HelpImage';

import tagImage from '../../../assets/images/help-screen/flow/flow-34.png';
import tagImage2 from '../../../assets/images/help-screen/flow/flow-35.png';
import tagImage3 from '../../../assets/images/help-screen/flow/flow-36.png';
import tagImage4 from '../../../assets/images/help-screen/flow/flow-37.png';
import tagImage5 from '../../../assets/images/help-screen/flow/flow-38.png';

export const mokArtifacts = [
  {
    title: 'Documents',
    content: <DocumentContent />,
  },
  {
    title: 'Actions',
    content: <ActionsContent />,
  },
  {
    title: 'Training Records',
    content: <TrainingRecordContent />,
  },
  {
    title: 'HR Records',
    content: <HumanResourcesContent />,
  },
  {
    title: 'Tags and Global Search',
    content: (
      <>
        <p>
          Tags can be used throughout Q Suite to label items. Tags are managed
          on the Organization Settings level. Admins and users with access
          permissions to edit Organization Settings can add and remove tags.         
        </p>
        <HelpImage src={tagImage} />
        <HelpImage src={tagImage2} />
        <p>
          Users can use the Global Search to identify any artifacts with a
          particular tag.
        </p>
        <HelpImage src={tagImage3} />
        <p>
          All tags currently available for use can be viewed on the Organization
          Settings page. These tags can be added to any artifact (document,
          training, action, HR record) in the Organization.
        </p>
        <HelpImage src={tagImage4} />
        <p>
          Adding the same Tag to various artifacts allows a link to be
          established between them. For example, adding a tag for a piece of
          equipment can link calibration and maintenance records/documents for
          that equipment. Also, a tag for a specific supplier can be added to
          documents such as Supplier Risk Assessments, Quality Agreements, and
          SCARs. Tags for a particular product may also be useful to quickly
          establish a Design History File, Device Master Record, or Device
          History Record. In any of these cases, using Global Search to pull up
          all items with those tags is a quick and easy way to pull the required
          records.
        </p>
        <p>
          Global Search is sensitive for Tags, Names of Trainings, Actions, and
          Documents, Document Types and IDs, User First & Last Names, and Email
          in HR Records. The search runs only for artifacts that are viewable
          per the user’s access permissions. The table of Global Search Results
          is universal, displaying any Documents, Trainings, Actions, and HR
          records with the search criteria.
        </p>
        <HelpImage src={tagImage5} />
      </>
    ),
  },
];
