import ProfileEmail from '../Email';
import ProfilePersonalInfo from '../PersonalInfo';
import ProfilePassword from '../Password';

import {
  EMAIL_TAB,
  PASSWORD_TAB,
  PERSONAL_INFO_TAB,
} from '../../../constants/helper';

export const navigationList = [
  {
    navigationText: 'Change E-mail',
    tabId: EMAIL_TAB,
    component: ProfileEmail,
  },
  {
    navigationText: 'Change Password',
    tabId: PASSWORD_TAB,
    component: ProfilePassword,
  },
];

export const navigationForUser = {
  navigationText: 'Change personal info',
  tabId: PERSONAL_INFO_TAB,
  component: ProfilePersonalInfo,
};
