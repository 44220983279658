import React, { useEffect, useMemo } from 'react';
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link, useRouteMatch } from 'react-router-dom';

import { Admin, SuperAdmin } from '../../../../constants/role';
import { useIsCanViewDocument } from '../../../../contexts/ViewDocument';
import {
  listAdmin,
  listDashboard,
  listGeneral,
  listSetting,
  listsUser,
} from '../../../../constants/sidebar-navigation';
import { getClientUserNav } from '../../../../utils/get-user-nav';
import { USER_ROLE } from '../../../../constants/helper';

const SidebarContent = (props) => {
  const organizationId = useRouteMatch().params.id;
  const { role } = props;

  const { clientUserAccess } = useIsCanViewDocument();

  const lists = useMemo(() => {
    if (role === SuperAdmin) {
      return [listAdmin, ...listsUser, listGeneral, listSetting];
    } else if (role === Admin) {
      return [listDashboard, ...listsUser, listGeneral, listSetting];
    }
    return [listDashboard, ...getClientUserNav(clientUserAccess)];
  }, [clientUserAccess, role]);

  useEffect(() => {
    var pathName = props.location.pathname;
    const initMenu = () => {
      new MetisMenu('#side-menu');
      var matchingMenuItem = null;
      var ul = document.getElementById('side-menu');
      var items = ul.getElementsByTagName('a');

      [...items].some((item) => {
        if (pathName.includes(item.pathname)) {
          matchingMenuItem = item;
          return true;
        }
        return false;
      });

      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, lists]);

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  }

  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        {role !== USER_ROLE && <li className="menu-title">Menu</li>}
        {lists.map(({ text, link, icon, subMenu }) => {
          return (
            <React.Fragment key={text}>
              {subMenu ? (
                <li>
                  <Link to="/general#" className="has-arrow waves-effect">
                    <i className={icon}></i>
                    <span>{text}</span>
                  </Link>
                  <ul className="sub-menu">
                    {subMenu.map(({ link, text }, i) => {
                      return (
                        <li key={`${text}-${i}`}>
                          <Link to={`/organizations/${organizationId}/${link}`}>
                            {text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ) : (
                <li>
                  <Link
                    to={`/organizations/${organizationId}/${link}`}
                    className="waves-effect"
                  >
                    <i className={icon}></i>
                    <span>{text}</span>
                  </Link>
                </li>
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export default withRouter(SidebarContent);
