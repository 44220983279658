import React from 'react';
import { Col, Row } from 'reactstrap';

import CustomPagination from '../../shared/Pagination';
import TableShowEntries from '../ShowEntries';

const TablePagination = ({
  limit,
  pageCount,
  currentPage,
  handleChangeLimit,
  handleChangePage,
}) => {
  return (
    <Row className="mt-3">
      <Col>
        <TableShowEntries limit={limit} handleChangeLimit={handleChangeLimit} />
      </Col>
      <Col className="d-flex justify-content-end">
        <CustomPagination
          pageCount={pageCount}
          forcePage={currentPage}
          handlePageClick={handleChangePage}
        />
      </Col>
    </Row>
  );
};

export default TablePagination;
