import { useLocation } from 'react-router-dom';

export const useQueryHelper = (names) => {
  const query = new URLSearchParams(useLocation().search);

  return names.reduce((acc, next) => {
    acc[next] = query.get(next) || '';
    return acc;
  }, {});
};
