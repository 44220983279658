import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { useQueryHelper } from '../../../utils/query-params-helper';
import { useEmptyForm } from '../useEmptyForm';
import { confirmEmailPassword, isValidSetToken } from '../../../store/actions';

const ConfirmEmailPage = ({ confirmEmailPassword, isValidSetToken }) => {
  const history = useHistory();

  const { email, token } = useQueryHelper(['email', 'token']);

  const [verificationBody, updateVerificationBody] = useState({
    email: '',
    token: '',
  });

  useEffect(() => {
    // check valid & inValid token
    // isValidSetToken({ email, token }, history);
    updateVerificationBody({ email, token });
  }, [token, email]);

  const handleValidSubmit = (event, values) => {
    confirmEmailPassword(values, history);
  };

  const formInputs = ['password'];

  const { disabled, onChange, values } = useEmptyForm(formInputs);

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-12 text-center">
                      <div className="confirm-password-header">
                        <h5>Email Confirmation</h5>
                        <p>
                          Please enter your password to confirm your updated
                          email.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <AvForm
                    className="form-horizontal mt-4"
                    onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                  >
                    <div className="form-group form-confirm">
                      <AvField
                        name="password"
                        label="Password"
                        className="form-control"
                        placeholder="Enter password"
                        type="password"
                        value={values.password}
                        onChange={onChange}
                      />
                    </div>
                    <Row className="form-group">
                      <Col className="text-right">
                        <button
                          className="btn w-md waves-effect waves-light w-100 btn-confirm"
                          disabled={disabled}
                          type="submit"
                        >
                          Enter
                        </button>
                      </Col>
                    </Row>
                    <div className="mt-3 mb-2 text-center">
                      <Link to="/login" className="text-password">
                        Back to login
                      </Link>
                    </div>
                    <AvField
                      name="token"
                      type="text"
                      value={verificationBody.token}
                      className="btn-hidden"
                    />
                    <AvField
                      name="email"
                      type="text"
                      value={verificationBody.email}
                      className="btn-hidden"
                    />
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default connect(null, { confirmEmailPassword, isValidSetToken })(
  ConfirmEmailPage,
);
