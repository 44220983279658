export const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#f8f8fb' : '#fff',
});

export const getItemStyle = (
  { isDragging, isDropAnimating },
  draggableStyle,
) => ({
  ...draggableStyle,
  userSelect: 'none',

  color: isDragging ? '#fff' : '#495057',
  background: isDragging ? '#2684FF' : '#F8F9FA',

  ...(!isDragging && { transform: 'translate(0,0)' }),
  ...(isDropAnimating && { transitionDuration: '0.3s' }),
});
