import React from 'react';
import classnames from 'classnames';

const TableExportLogs = ({ isOpenExportLogs, handleOpenExportLogs }) => {
  return (
    <div
      className={classnames(
        { active: isOpenExportLogs },
        'd-flex align-items-center filter-container d-cursor mr-3 mb-2',
      )}
      onClick={handleOpenExportLogs}
    >
      <i className="font-size-20 bx bxs-file-export mr-2"></i>
      <span>Logs export</span>
    </div>
  );
};

export default TableExportLogs;
