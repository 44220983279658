/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import {
  createAdmin,
  createAdminSuccess,
} from '../../store/auth/admins/actions';
import { useEmptyForm } from '../../custom-hooks/useEmptyForm';
import Button from '../../components/Button';
import { MAX_INPUT_LENGTH } from '../../constants/helper';
import { validateEmptyText } from '../../constants/validatePassword';
import { REQUIRED_FIELD } from '../../constants/form';

const AdminAddNewModal = ({
  isOpenModal,
  handleToggleModal,
  organizationId,
  createAdmin,
  creatingSuccess,
}) => {
  const formInputs = ['firstName', 'lastName', 'email'];

  const { disabled, onChange, values, clear } = useEmptyForm(formInputs);

  const handleValidSubmit = (event, values) => {
    const data = { ...values, organizationId };
    createAdmin(data);
  };

  const handleCloseModal = () => {
    handleToggleModal();
    clear();
  };

  useEffect(() => {
    if (creatingSuccess) {
      handleToggleModal(creatingSuccess);
      clear();
    }
  }, [creatingSuccess]);

  return (
    <Modal isOpen={isOpenModal} toggle={handleCloseModal} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Create Admin</h5>
        <button
          type="button"
          onClick={handleCloseModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm
        className="form-horizontal mt-2"
        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
      >
        <div className="modal-body">
          <div className="form-group">
            <AvField
              name="firstName"
              label="First name"
              className="form-control"
              placeholder="Enter first name"
              maxLength={MAX_INPUT_LENGTH}
              type="text"
              value={values.firstName}
              onChange={onChange}
              validate={validateEmptyText}
              errorMessage={REQUIRED_FIELD}
            />
          </div>
          <div className="form-group">
            <AvField
              name="lastName"
              label="Last name"
              type="text"
              maxLength={MAX_INPUT_LENGTH}
              placeholder="Enter last name"
              value={values.lastName}
              onChange={onChange}
              validate={validateEmptyText}
              errorMessage={REQUIRED_FIELD}
            />
          </div>
          <div className="form-group">
            <AvField
              name="email"
              label="Email"
              type="email"
              placeholder="Enter email here"
              value={values.email}
              onChange={onChange}
              validate={validateEmptyText}
              errorMessage={REQUIRED_FIELD}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={disabled}
                color="btn btn-primary w-md waves-effect waves-light"
              >
                Create Admin
              </Button>
            </Col>
          </Row>
        </div>
      </AvForm>
    </Modal>
  );
};

const mapStateToProps = ({ Admins: { creatingSuccess } }) => ({
  creatingSuccess,
});

export default connect(mapStateToProps, { createAdmin, createAdminSuccess })(
  AdminAddNewModal,
);
