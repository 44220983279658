import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';

import SingleSelect from '../../../selects/Single';
import CustomSwitch from '../../CustomSwitch';

import { useRouteMatch } from 'react-router-dom';
import { SECOND_SWITCH } from '../../../../constants/helper';
import { getParamsForTraining } from '../../../../utils/training-records-helper/get-params-for-training';
import { configureTasksOptions } from '../../../../utils/config-get-organization';
import { getTasksAvailable } from '../../../../utils/api/task-api';

const LinkTask = ({ selectValues, handleUpdate, handleUpdateLink }) => {
  const { id: organizationId } = useRouteMatch().params;

  const getDataFromAPI = useCallback(
    async (_, search = '') => {
      const params = getParamsForTraining({ organizationId, search });
      return getTasksAvailable(params).then(({ data }) => data);
    },
    [organizationId],
  );

  return (
    <Row className="mt-2 mb-2">
      <Col md="4" xl="2" className="d-flex align-items-center">
        <div className="link-swicher">
          <CustomSwitch
            name="task-switch"
            isChecked={selectValues[SECOND_SWITCH]}
            onChange={() => handleUpdate(SECOND_SWITCH)}
          />
          <p className="mb-0">Action</p>
        </div>
      </Col>
      <Col md="8" xl="10" className="mt-2 mt-md-0">
        <SingleSelect
          handleRequest={getDataFromAPI}
          placeholder="Choose action"
          configOptions={configureTasksOptions}
          isDisabled={!selectValues[SECOND_SWITCH]}
          handleChange={(values) =>
            handleUpdateLink(values, 'tasks', SECOND_SWITCH)
          }
        />
      </Col>
    </Row>
  );
};

export default LinkTask;
