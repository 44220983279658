import { LocalStorage } from '../utils/storage';
import { SessionStorage } from '../utils/sessionStorage';

export const local = 'LocalStorage';
export const session = 'SessionStorage';

const storage = new LocalStorage();
const sessionStorage = new SessionStorage();

export const storages = {
  [local]: storage,
  [session]: sessionStorage,
};
