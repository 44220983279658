/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/shared/Breadcrumbs/Breadcrumbs';
import DisplayColumnsModal from '../../../modals/DisplayColumns';
import UserGroupAddNewModal from '../../../modals/UserGroupAddNew';
import TableHead from '../../../components/tables/TableHead';
import TableBody from '../../../components/tables/TableBody';
import {
  createUserGroup,
  getDataTableColumns,
} from '../../../utils/backend-helper';
import { SUCCESS_MESSAGE_USER_GROUP_CREATE } from '../../../constants/messages';
import {
  LIMIT,
  INITIAL_OFFSET,
  INITIAL_PAGE,
  TOTAL_ITEMS,
  PAGE_COUNT,
} from '../../../constants/table';
import MessageResetSuccess from '../../../components/ToastSuccess';
import { columnsHead, columnsBody } from './Table';
import { getFileName } from '../../../utils/get-file-name';
import { useDocumentExport } from '../../../custom-hooks/useDocumentExport';
import { getExportParams } from '../../../utils/get-export-params';
import { CSV } from '../../../constants/helper';
import { orderTable } from '../../../utils/order-table';
import { getColumnsFilter } from '../../../utils/get-columns-search';
import { mergetParams } from '../../../utils/merge-table-params';
import { useCheckboxTable } from '../../../custom-hooks/useCheckboxTable';
import TableFooter from '../../../components/tables/TableFooter';
import TableHeader from '../../../components/tables/TableHeader';
import TablePaginationText from '../../../components/tables/PaginationText';
import TableSpinner from '../../../components/tables/Spinner';
import { dragReorder } from '../../../utils/dragable-helpers/dragable-reoder';
import {
  getChangedResizeColumns,
  getEndResizeColumns,
} from '../../../utils/tables-helper/resizable';
import { useTableWidth } from '../../../custom-hooks/useTableWidth';
import { deleteRow } from '../../../utils/tables-helper/deleteRow';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const OrganizationUserGroup = ({ organizationName, user }) => {
  const { id: organizationId } = useRouteMatch().params;

  const [userGroup, setUserGroups] = useState(null);
  const onError = useErrorHandler();

  const [columnsVisibility, updateColumnsVisibility] = useState({
    activeted: {
      label: 'Options',
      isVisible: true,
    },
    name: {
      label: 'Group',
      isVisible: true,
      accessor: 'Name',
      sort: true,
    },
    userCount: {
      label: 'Users',
      isVisible: true,
      accessor: 'UserCount',
      sort: true,
    },
  });

  const [isLoading, updateIsLoading] = useState(false);

  const [pagination, setPagination] = useState({
    data: [],
    limit: LIMIT,
    offset: INITIAL_OFFSET,
    pageNumber: INITIAL_PAGE,
    pageCount: PAGE_COUNT,
    totalItems: TOTAL_ITEMS,
    columns: getColumnsFilter(columnsVisibility),
    search: '',
    orderBy: '',
    orderType: '',
  });

  const getUserGroupsList = useCallback(
    (organizationId, limit, offset, search, orderBy, orderType, columns) => {
      const params = mergetParams({
        organizationId,
        limit,
        offset,
        search,
        orderBy,
        orderType,
      });
      return getDataTableColumns('/user-groups', params, columns);
    },
    [],
  );

  const updatingTable = async (success) => {
    try {
      if (!success) return;
      updateIsLoading(true);
      const { limit, offset, search, orderBy, orderType, columns } = pagination;
      const data = await getUserGroupsList(
        organizationId,
        limit,
        offset,
        search,
        orderBy,
        orderType,
        columns,
      );
      setUserGroups(data);
    } catch (error) {
      onError(error);
    } finally {
      updateIsLoading(false);
    }
  };

  useEffect(() => {
    const getItems = async (
      organizationId,
      limit,
      offset,
      search,
      orderBy,
      orderType,
      columns,
    ) => {
      try {
        updateIsLoading(true);
        const data = await getUserGroupsList(
          organizationId,
          limit,
          offset,
          search,
          orderBy,
          orderType,
          columns,
        );
        setUserGroups(data);
      } catch (error) {
        onError(error);
      } finally {
        updateIsLoading(false);
      }
    };

    getItems(
      organizationId,
      pagination.limit,
      pagination.offset,
      pagination.search,
      pagination.orderBy,
      pagination.orderType,
      pagination.columns,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.limit,
    pagination.search,
    pagination.offset,
    pagination.orderBy,
    pagination.orderType,
    getUserGroupsList,
  ]);

  const debounceHandleUpdate = useMemo(() => {
    return debounce((input) => {
      setPagination((prev) => ({
        ...prev,
        pageNumber: INITIAL_PAGE,
        offset: INITIAL_OFFSET,
        search: input,
      }));
    }, 1000);
  }, []);

  const handleSearch = (value) => {
    debounceHandleUpdate(value);
  };

  const handleOrder = (key) => {
    setPagination((prev) => orderTable(key, prev));
  };

  const handleChangeLimit = (value) => {
    setPagination((prev) => ({
      ...prev,
      pageNumber: INITIAL_PAGE,
      offset: INITIAL_OFFSET,
      limit: value,
    }));
  };

  const handlePageClick = ({ selected }) => {
    const offset = Math.ceil(selected * pagination.limit);
    setPagination((prev) => ({
      ...prev,
      pageNumber: selected,
      offset,
    }));
  };

  const [displayModal, setDisplayModal] = useState({
    isOpen: false,
  });

  const handleToggleColumn = () => {
    setDisplayModal((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const [addNewModal, setAddNewModal] = useState({
    isOpen: false,
    organizationId: null,
  });

  const handleAddNewModal = (organizationId) => {
    setAddNewModal((prev) => ({
      isOpen: !prev.isOpen,
      organizationId,
    }));
  };

  useEffect(() => {
    if (!userGroup) return;
    const {
      data,
      pagination: { totalCount },
    } = userGroup;
    setPagination((prev) => ({
      ...prev,
      data,
      pageCount: Math.ceil(totalCount / prev.limit),
      totalItems: totalCount,
    }));
  }, [userGroup]);

  const [columnsHidden, updateColumnsHidden] = useState({
    name: true,
    userCount: true,
    activeted: true,
  });

  useEffect(() => {
    const getColumns = getColumnsFilter(columnsVisibility);

    setPagination((prev) => ({
      ...prev,
      columns: getColumns,
    }));
  }, [columnsVisibility]);

  const handleSaveColumns = () => {
    updateColumnsHidden((prev) => {
      return Object.keys(prev).reduce((acc, keys) => {
        acc[keys] = columnsVisibility[keys].isVisible;
        return acc;
      }, {});
    });
    setDisplayModal((prev) => ({ ...prev, isOpen: false }));
  };

  const handleUpdateColumnsVisible = (key) => {
    updateColumnsVisibility((prev) => ({
      ...prev,
      [key]: { ...prev[key], isVisible: !prev[key].isVisible },
    }));
  };

  const handleSelectAll = (toggle) => {
    const newColumnsVisible = Object.keys(columnsVisibility).reduce(
      (acc, key) => {
        acc[key] = { ...columnsVisibility[key], isVisible: toggle };
        return acc;
      },
      {},
    );
    updateColumnsVisibility(newColumnsVisible);
  };

  const breadCrumbItems = [
    {
      link: '',
      title: 'User Groups',
    },
  ];

  const {
    checkedHead,
    updateCheckedHead,
    checkAllDocuments,
    handleUpdateChecked,
  } = useCheckboxTable(pagination, setPagination);

  const { downloadFile } = useDocumentExport();

  const handleDownloadCSV = (organizationId, pagination, dataOfColumns) => {
    const fileName = getFileName(organizationName, '', user, CSV);
    let { search, orderBy, orderType, data } = pagination;

    const body = getExportParams(
      data,
      dataOfColumns,
      { search, orderBy, orderType },
      organizationId,
    );
    downloadFile('/export/groups', body, fileName);
  };

  const [columnsMain, updateColumnsMain] = useState(columnsHead);
  const [columns, updateColumns] = useState(columnsBody);

  const tableRef = useTableWidth(updateColumnsMain);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            name={`“${organizationName}” User Groups`}
            breadCrumbItems={breadCrumbItems}
          />
          <Row className="d-flex justify-content-center">
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableHeader
                    title="List of User Groups"
                    handleAddNew={() => handleAddNewModal(organizationId)}
                    downloadCSV={() =>
                      handleDownloadCSV(
                        organizationId,
                        pagination,
                        columnsVisibility,
                      )
                    }
                    manageColumns={handleToggleColumn}
                    handleSearch={handleSearch}
                  />
                  <div ref={tableRef} className="table-wrapper">
                    <Table className="table-custom table table-centered table-nowrap">
                      <TableHead
                        columnsHidden={columnsHidden}
                        handleOrder={handleOrder}
                        checkAllDocuments={checkAllDocuments}
                        checkedHead={checkedHead}
                        updateCheckedHead={checkedHead}
                        pagination={pagination}
                        columnsHead={columnsMain}
                        columns={columns}
                        handleChangeColumns={(
                          columns,
                          columnsHead,
                          startIndex,
                          endIndex,
                        ) => {
                          updateColumns(
                            dragReorder(columns, startIndex, endIndex),
                          );
                          updateColumnsMain(
                            dragReorder(columnsHead, startIndex, endIndex),
                          );
                        }}
                        onResizeColumns={(columnIndex, changedWidth) => {
                          updateColumnsMain((columns) =>
                            getChangedResizeColumns(
                              columns,
                              columnIndex,
                              changedWidth,
                            ),
                          );
                        }}
                        onResizeEnd={(columnIndex) => {
                          updateColumnsMain((columns) =>
                            getEndResizeColumns(columns, columnIndex),
                          );
                        }}
                      />
                      <TableBody
                        columnsHidden={columnsHidden}
                        onDeleteRow={(deletedId) => {
                          setPagination((pagination) =>
                            deleteRow(pagination, deletedId),
                          );
                        }}
                        pagination={pagination}
                        setPagination={setPagination}
                        columnsBody={columns}
                        organizationName={organizationName}
                        updatingTable={updatingTable}
                        organizationId={organizationId}
                        updateCheckedHead={updateCheckedHead}
                        handleUpdateChecked={handleUpdateChecked}
                      />
                    </Table>
                  </div>
                  {!!pagination.data.length && (
                    <TableFooter
                      limit={pagination.limit}
                      handleChangeLimit={handleChangeLimit}
                      pageCount={pagination.pageCount}
                      pageNumber={pagination.pageNumber}
                      handlePageClick={handlePageClick}
                    />
                  )}
                  {!(pagination.data.length || isLoading) && (
                    <TablePaginationText text="There are no User Groups to display" />
                  )}
                  {isLoading && <TableSpinner />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DisplayColumnsModal
        columnsVisibility={columnsVisibility}
        isOpen={displayModal.isOpen}
        handleUpdateColumnsVisible={handleUpdateColumnsVisible}
        handleSelectAll={handleSelectAll}
        onCancel={() => setDisplayModal((prev) => ({ ...prev, isOpen: false }))}
        handleSaveColumns={handleSaveColumns}
      />
      <UserGroupAddNewModal
        onSubmit={(event, { name, organizationId }, callback) => {
          createUserGroup(name, organizationId).then(() => {
            updatingTable(true);
            callback();
            setAddNewModal((prev) => ({ ...prev, isOpen: false }));
            toast.success(
              <MessageResetSuccess
                message={SUCCESS_MESSAGE_USER_GROUP_CREATE}
              />,
            );
          });
        }}
        organizationId={addNewModal.organizationId}
        isOpen={addNewModal.isOpen}
        onCancel={() => setAddNewModal((prev) => ({ ...prev, isOpen: false }))}
      />
    </>
  );
};

export default OrganizationUserGroup;
