import { CLOSE_NOTIFICATION, UPDATE_NOTIFICATION } from './actionTypes';

export const updateNotification = () => {
  return {
    type: UPDATE_NOTIFICATION,
  };
};

export const closeNotification = () => {
  return {
    type: CLOSE_NOTIFICATION,
  };
};
