import React, { useState } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { keyAuth } from '../../constants/api';

import { LocalStorage } from '../../utils/storage';
import { SessionStorage } from '../../utils/sessionStorage';
import { getDataUser } from '../../utils/get-store-user';

const storage = new LocalStorage();
const sessionStorage = new SessionStorage();

const Organizationmiddleware = ({
  component: Component,
  layout: Layout,
  path,
  exact,
  setRouteId,
  organizationImage,
  organizationName,
}) => {
  const isAuth = () => {
    return Boolean(storage.getItem(keyAuth) || sessionStorage.getItem(keyAuth));
  };

  const [user] = useState(getDataUser());

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {
        if (isAuth()) {
          return (
            <Layout
              setRouteId={setRouteId}
              organizationImage={organizationImage}
              organizationName={organizationName}
              user={user}
            >
              <Component
                organizationImage={organizationImage}
                organizationName={organizationName}
                user={user}
                {...props}
              />
            </Layout>
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/Login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default withRouter(Organizationmiddleware);
