import React from 'react';

import CustomSwitch from '../CustomSwitch';

const TextRadio = ({ id, text, name, onChange, isChecked, disabled }) => {
  return (
    <div id={id} className="form-check pl-0">
      <CustomSwitch
        name={name}
        isChecked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <span>{text}</span>
    </div>
  );
};

export default TextRadio;
