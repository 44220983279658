import React from 'react';
import classnames from 'classnames';

const LabelBlock = ({ icon, text }) => {
  return (
    <div className="d-flex mr-3 label-block">
      <p className="mb-2">
        <i
          className={classnames(
            'font-size-18 v-middle mr-1 d-cursor-none',
            icon,
          )}
        ></i>
        {text}
      </p>
    </div>
  );
};

export default LabelBlock;
