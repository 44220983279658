import React from 'react';

import CardTitle from '../../shared/CardTitle';
import HelpTabContent from '../shared/TabContent';

import { mokArtifacts } from './artifactsMok';

const HelpArtifacts = () => {
  return (
    <div>
      <CardTitle title="Main artifacts and flows" />
      <HelpTabContent mokData={mokArtifacts} />
    </div>
  );
};

export default HelpArtifacts;
