import React from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import Button from '../../components/Button';

const DocumentTypeDeleteModal = ({ onSubmit, onCancel, isOpen, values }) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Delete Document Type</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
        <div className="modal-body">
          <p className="text-modal">
            Are you sure you want to delete the document type{' '}
            <b>{values.name}</b>? Once you delete it, it will not be possible to
            create documents of this type in the current Organization. The
            existing documents will not be affected.
          </p>
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="submit"
                color="btn btn-danger w-md waves-effect waves-light delete-btn mr-3"
              >
                Delete
              </Button>
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
        <AvField
          name="doctypeId"
          type="text"
          value={values.doctypeId}
          className="btn-hidden"
        />
        <AvField
          name="id"
          type="text"
          value={values.organizationId}
          className="btn-hidden"
        />
      </AvForm>
    </Modal>
  );
};

export default DocumentTypeDeleteModal;
