import React, { useState, useEffect } from 'react';
import { Button, Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import { getStatusPipe } from '../../../../pipes/getStatisPipe';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import OrganizationEditModal from '../../../../modals/OrganizationEdit';
import OrganizationActiveModal from '../../../../modals/OrganizationActive';
import OrganizationDeletingModal from '../../../../modals/OrganizationDelete';
import {
  changeOrganizationStatus,
  deleteOrganization,
  editOneOrganization,
} from '../../../../utils/backend-helper';
import MessageResetSuccess from '../../../../components/ToastSuccess';
import {
  SUCCESS_DELETE_MESSAGE,
  SUCCESS_EDIT_MESSAGE,
  SUCCESS_STATUS_CHANGED,
} from '../../../../constants/messages';
import { changeManaged } from '../../../../utils/api/organization-api';
import CheckboxHead from '../../../../components/tables/HeadCheckbox';
import BodyCheckbox from '../../../../components/tables/BodyCheckbox';
import TableCellText from '../../../../components/tables/TableCellText';
import IconTooltip from '../../../../components/tooltips/IconTooltip';

export const columnsHead = [
  {
    name: 'checkbox',
    accessor: '',
    sort: false,
    Component: ({
      provided,
      snapshot,
      checkAllDocuments,
      checkedHead,
      pagination: { data },
    }) => {
      return (
        <CheckboxHead
          data={data}
          checkedHead={checkedHead}
          checkAllDocuments={checkAllDocuments}
          provided={provided}
          snapshot={snapshot}
        />
      );
    },
  },
  {
    name: 'name',
    accessor: 'Organizations',
    sort: true,
    classes: 'column-title sort',
    widthPercent: 32,
  },
  {
    name: 'status',
    accessor: 'Status',
    sort: true,
    classes: 'column-title sort',
    widthPercent: 22,
  },
  {
    name: 'isManagedByClient',
    accessor: 'Managed by Client',
    sort: true,
    classes: 'column-title text-center sort',
    widthPercent: 18,
  },
  {
    name: 'isactivated',
    accessor: 'Options',
    sort: false,
    classes: 'text-center',
    widthPercent: 28,
  },
];

export const columnsBody = [
  {
    name: 'checkbox',
    accessor: 'Selected',
    Component: ({ order: { id, isChecked = false }, handleUpdateChecked }) => {
      return (
        <BodyCheckbox
          id={id}
          isChecked={isChecked}
          handleUpdateChecked={handleUpdateChecked}
        />
      );
    },
  },
  {
    name: 'name',
    accessor: 'Organizations',
    classes: '',
    Component: ({ order, name, columnsHidden }) => {
      const history = useHistory();
      const { id, isManagedByClient } = order;

      const handleRedirect = (id) => {
        history.push(`/organizations/${id}/admins`);
      };

      return (
        <td
          key={name}
          onClick={() => !isManagedByClient && handleRedirect(id)}
          className={classnames(
            { 'cursor-pointer': !isManagedByClient },
            { 'column-hidden': !columnsHidden[name] },
          )}
        >
          <p className="td-before">Organizations</p>
          <TableCellText id={`Organization${name}${id}`} text={order[name]} />
        </td>
      );
    },
  },
  {
    name: 'status',
    accessor: 'Status',
    classes: '',
    Component: ({ order, columnsHidden }) => (
      <td className={`${columnsHidden.status ? '' : 'column-hidden'}`}>
        <p className="td-before">Status</p>
        <div>
          <Badge
            className={`font-size-12 badge-soft-${
              order.status === 'Active' ? 'success' : 'danger'
            }`}
            pill
          >
            {order.status}
          </Badge>
        </div>
      </td>
    ),
  },
  {
    name: 'isManagedByClient',
    accessor: 'Managed by Client',
    classes: '',
    Component: ({ order, columnsHidden, setPagination }) => {
      const [isChecked, updateIsChecked] = useState(false);

      useEffect(() => {
        updateIsChecked(order.isManagedByClient);
      }, [order.isManagedByClient]);

      const handleChangeManaged = ({ id }) => {
        changeManaged(id).then(({ data }) => {
          const { isManagedByClient, id: changeId } = data;

          updateIsChecked(isManagedByClient);
          setPagination((pagination) => ({
            ...pagination,
            data: pagination.data.map((item) => {
              const { id, isManagedByClient } = item;
              return {
                ...item,
                isManagedByClient:
                  id === changeId ? !isManagedByClient : isManagedByClient,
              };
            }),
          }));
        });
      };

      return (
        <td className={columnsHidden.isManagedByClient ? '' : 'column-hidden'}>
          <p className="td-before">Managed by Client</p>
          <div
            className="custom-control custom-switch d-flex justify-content-start justify-content-md-center overflow-auto"
            dir="ltr"
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={order.id}
              onChange={(e) => handleChangeManaged(e.target)}
              checked={isChecked}
            />
            <label className="custom-control-label" htmlFor={order.id}></label>
          </div>
        </td>
      );
    },
  },
  {
    name: 'activeted',
    accessor: 'Actions',
    classes: '',
    Component: ({ order, columnsHidden, updatingTable, onDeleteRow }) => {
      const history = useHistory();

      const handlerRedirectAdmin = (isManaged, id) => {
        if (isManaged) return;
        history.push(`/organizations/${id}/admins`);
      };

      const [activeModal, setActiveModal] = useState({
        isOpen: false,
        values: {
          organizationName: '',
          isActive: null,
        },
        model: {
          organizationId: null,
        },
      });

      const handleActiveModal = (
        organizationId,
        isActive,
        organizationName,
      ) => {
        setActiveModal((prev) => ({
          isOpen: !prev.isOpen,
          values: { organizationName, isActive },
          model: { organizationId },
        }));
      };

      const [editModal, setEditModal] = useState({
        isOpen: false,
        model: {
          name: '',
          id: null,
        },
      });

      const handleEditModal = (id, name) => {
        setEditModal((prev) => ({
          isOpen: !prev.isOpen,
          model: { id, name },
        }));
      };

      const handleChangeEdit = (text) => {
        setEditModal((prev) => ({
          ...prev,
          model: { ...prev.model, name: text },
        }));
      };

      const [deleteModal, setDeleteModal] = useState({
        isOpen: false,
        model: {
          organizationId: null,
        },
        values: {
          organizationName: '',
        },
      });

      const handleToogleDeleteModal = (organizationName, id) => {
        setDeleteModal((prev) => ({
          isOpen: !prev.isOpen,
          model: { organizationId: id },
          values: { organizationName },
        }));
      };

      return (
        <>
          <td
            className={`activate-column ${
              columnsHidden.isactivated ? '' : 'column-hidden'
            }`}
          >
            <p className="td-before">Actions</p>
            <div className="activated-column__organization">
              <Button
                type="button"
                color={order.status === 'Active' ? '' : 'primary'}
                className={`btn-sm btn-rounded btn-active mr-2 ${
                  order.status === 'Active' ? 'btn-muted' : ''
                }`}
                onClick={() =>
                  handleActiveModal(order.id, order.status, order.name)
                }
              >
                {getStatusPipe(order.status)}
              </Button>
              <div className="actions">
                <IconTooltip
                  icon={`bx bx-show action ${
                    order.isManagedByClient ? 'muted-icon' : 'active-icon'
                  }`}
                  id={`OrganizationView${order.id}`}
                  text="View details"
                  onClick={() =>
                    handlerRedirectAdmin(order.isManagedByClient, order.id)
                  }
                />
                <IconTooltip
                  icon="bx bxs-edit-alt action"
                  id={`OrganizationEdit${order.id}`}
                  text="Edit"
                  onClick={() => handleEditModal(order.id, order.name)}
                />
                <IconTooltip
                  icon="bx bxs-trash-alt action"
                  id={`HRDeleteDetails${order.id}`}
                  text="Delete"
                  onClick={() => handleToogleDeleteModal(order.name, order.id)}
                />
              </div>
            </div>
          </td>
          <OrganizationActiveModal
            isOpen={activeModal.isOpen}
            values={activeModal.values}
            model={activeModal.model}
            onSubmit={(_, { id }) => {
              changeOrganizationStatus(id).then(() => {
                updatingTable(true);
                toast.success(
                  <MessageResetSuccess message={SUCCESS_STATUS_CHANGED} />,
                );
                setActiveModal((prev) => ({ ...prev, isOpen: false }));
              });
            }}
            onCancel={() =>
              setActiveModal((prev) => ({ ...prev, isOpen: false }))
            }
          />
          <OrganizationEditModal
            isOpen={editModal.isOpen}
            values={editModal.model}
            handleChangeEdit={handleChangeEdit}
            onSubmit={(e, v) => {
              editOneOrganization(v.id, v.name)
                .then(() => {
                  updatingTable(true);
                  toast.success(
                    <MessageResetSuccess message={SUCCESS_EDIT_MESSAGE} />,
                  );
                })
                .finally(() => {
                  setEditModal((prev) => ({ ...prev, isOpen: false }));
                });
            }}
            onCancel={() => {
              setEditModal((prev) => ({ ...prev, isOpen: false }));
            }}
          />
          <OrganizationDeletingModal
            isOpen={deleteModal.isOpen}
            onCancel={() =>
              setDeleteModal((prev) => ({ ...prev, isOpen: false }))
            }
            values={deleteModal.values}
            model={deleteModal.model}
            onSubmit={(_, { id }) => {
              deleteOrganization(id).then(() => {
                toast.success(
                  <MessageResetSuccess message={SUCCESS_DELETE_MESSAGE} />,
                );
                setDeleteModal((prev) => ({ ...prev, isOpen: false }));
                onDeleteRow(id);
              });
            }}
          />
        </>
      );
    },
  },
];
