import { toast } from 'react-toastify';
import {
  typeOfImages,
  errorMessageType,
  maxSizeImage,
  errorMessageSize,
  maxDocumentSize,
  errorDocumentSize,
} from '../constants/uploadImage';
import { getExtension } from './/file-helper';

export const validationImageCropper = (file) => {
  const ext = getExtension(file);
  const checkedType = typeOfImages.find((type) => type === ext);

  if (!checkedType) {
    throw new Error(errorMessageType);
  }

  if (file.size > maxSizeImage) {
    throw new Error(errorMessageSize);
  }

  return null;
};

export const validationDocumentFile = (file) => {
  if (file.size > maxDocumentSize) {
    throw new Error(errorDocumentSize);
  }

  return null;
};

export const validationFile = (file) => {
  try {
    validationDocumentFile(file);
  } catch (error) {
    toast.error(error.message);
    throw error;
    // return;
  }
};
