/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

import { SUCCESS_MESSAGE_ADD } from '../../../constants/messages';
import AdminAddNewModal from '../../../modals/AdminsAddNew';
import AdminAddExistModal from '../../../modals/AdminsAddExist';
import DisplayColumnsModal from '../../../modals/DisplayColumns';
import TableHead from '../../../components/tables/TableHead';
import TableBody from '../../../components/tables/TableBody';
import MessageResetSuccess from '../../../components/ToastSuccess';
import { addAdmins, getDataTableColumns } from '../../../utils/backend-helper';
import {
  LIMIT,
  INITIAL_OFFSET,
  INITIAL_PAGE,
  TOTAL_ITEMS,
  PAGE_COUNT,
} from '../../../constants/table';
import { columnsHead, columnsBody } from './Table';
import { getFileName } from '../../../utils/get-file-name';
import { useDocumentExport } from '../../../custom-hooks/useDocumentExport';
import { getExportParams } from '../../../utils/get-export-params';
import { CSV, DEFAULT_SORT } from '../../../constants/helper';
import { orderTable } from '../../../utils/order-table';
import { mergetParams } from '../../../utils/merge-table-params';
import { getColumnsFilter } from '../../../utils/get-columns-search';
import { useCheckboxTable } from '../../../custom-hooks/useCheckboxTable';
import TableHeader from '../../../components/tables/TableHeader';
import TableFooter from '../../../components/tables/TableFooter';
import TablePaginationText from '../../../components/tables/PaginationText';
import TableSpinner from '../../../components/tables/Spinner';
import { dragReorder } from '../../../utils/dragable-helpers/dragable-reoder';
import {
  getChangedResizeColumns,
  getEndResizeColumns,
} from '../../../utils/tables-helper/resizable';
import { useTableWidth } from '../../../custom-hooks/useTableWidth';
import { deleteRow } from '../../../utils/tables-helper/deleteRow';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const OrganizationAdmins = ({ organizationName, user }) => {
  const { id: organizationId } = useRouteMatch().params;
  const onError = useErrorHandler();

  const [isOpenModalAdd, setIsOpenModalAdd] = useState(false);

  const [isOpenExistModal, setIsOpenExistModal] = useState(false);

  const [displayMoldal, setDisplayModal] = useState({
    isOpen: false,
  });

  const handleToggleColumn = () => {
    setDisplayModal((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const handleToggleModal = (creatingSuccess = false) => {
    updatingTable(creatingSuccess);
    setIsOpenModalAdd((isOpen) => !isOpen);
  };

  const handleToggleExistModal = () => {
    setIsOpenExistModal((isOpen) => !isOpen);
  };

  const [columnsVisibility, updateColumnsVisibility] = useState({
    activeted: {
      label: 'Options',
      isVisible: true,
    },
    isactivated: {
      label: 'Activated',
      isVisible: true,
      accessor: 'IsActivated',
      sort: true,
    },
    email: {
      label: 'Email',
      isVisible: true,
      accessor: 'Email',
      sort: true,
    },
    firstName: {
      label: 'First Name',
      isVisible: true,
      accessor: 'FirstName',
      sort: true,
    },
    lastName: {
      label: 'Last Name',
      isVisible: true,
      accessor: 'LastName',
      sort: true,
    },
    status: {
      label: 'Status',
      isVisible: true,
      accessor: 'Status',
      sort: true,
    },
  });

  const [isLoading, updateIsLoading] = useState(false);

  const [pagination, setPagination] = useState({
    data: [],
    limit: LIMIT,
    offset: INITIAL_OFFSET,
    pageNumber: INITIAL_PAGE,
    pageCount: PAGE_COUNT,
    totalItems: TOTAL_ITEMS,
    search: '',
    orderBy: '',
    orderType: '',
  });

  const [admins, setAdmins] = useState();

  const getAdminssList = useCallback(
    (organizationId, limit, offset, search, orderBy, orderType, columns) => {
      const params = mergetParams({
        organizationId,
        limit,
        offset,
        search,
        orderBy,
        orderType,
      });
      return getDataTableColumns('/client-admins', params, columns);
    },
    [],
  );

  const updatingTable = async (success) => {
    try {
      if (!success) return;
      updateIsLoading(true);
      const { limit, offset, search, orderBy, orderType, columns } = pagination;
      const data = await getAdminssList(
        organizationId,
        limit,
        offset,
        search,
        orderBy,
        orderType,
        columns,
      );
      setAdmins(data);
    } catch (error) {
      onError(error);
    } finally {
      updateIsLoading(false);
    }
  };

  useEffect(() => {
    const getItems = async (
      organizationId,
      limit,
      offset,
      search,
      orderBy,
      orderType,
      columns,
    ) => {
      try {
        updateIsLoading(true);
        const data = await getAdminssList(
          organizationId,
          limit,
          offset,
          search,
          orderBy,
          orderType,
          columns,
        );
        setAdmins(data);
      } catch (error) {
        onError(error);
      } finally {
        updateIsLoading(false);
      }
    };

    getItems(
      organizationId,
      pagination.limit,
      pagination.offset,
      pagination.search,
      pagination.orderBy,
      pagination.orderType,
      pagination.columns,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.limit,
    pagination.search,
    pagination.offset,
    pagination.orderBy,
    pagination.orderType,
    getAdminssList,
  ]);

  useEffect(() => {
    const getColumns = getColumnsFilter(columnsVisibility);

    setPagination((prev) => ({
      ...prev,
      columns: getColumns,
    }));
  }, [columnsVisibility]);

  const debounceHandleUpdate = useMemo(() => {
    return debounce((input) => {
      setPagination((prev) => ({
        ...prev,
        pageNumber: INITIAL_PAGE,
        offset: INITIAL_OFFSET,
        search: input,
      }));
    }, 1000);
  }, []);

  const handleSearch = (value) => {
    debounceHandleUpdate(value);
  };

  const handleOrder = (key) => {
    setPagination((prev) => orderTable(key, prev));
  };

  const handleChangeLimit = (value) => {
    setPagination((prev) => ({
      ...prev,
      pageNumber: INITIAL_PAGE,
      offset: INITIAL_OFFSET,
      limit: value,
    }));
  };

  const handlePageClick = ({ selected }) => {
    const offset = Math.ceil(selected * pagination.limit);
    setPagination((prev) => ({
      ...prev,
      pageNumber: selected,
      offset,
    }));
  };

  useEffect(() => {
    if (!admins) return;
    const {
      data,
      pagination: { totalCount },
    } = admins;
    setPagination((prev) => ({
      ...prev,
      data,
      pageCount: Math.ceil(totalCount / prev.limit),
      totalItems: totalCount,
    }));
  }, [admins]);

  const [columnsHidden, updateColumnsHidden] = useState({
    firstName: true,
    lastName: true,
    email: true,
    status: true,
    isactivated: true,
    activeted: true,
  });

  const handleSaveColumns = () => {
    updateColumnsHidden((prev) => {
      return Object.entries(prev).reduce((acc, [key, value]) => {
        acc[key] = columnsVisibility[key].isVisible;
        return acc;
      }, {});
    });
    setDisplayModal((prev) => ({ ...prev, isOpen: false }));
  };

  const handleUpdateColumnsVisible = (key) => {
    updateColumnsVisibility((prev) => ({
      ...prev,
      [key]: { ...prev[key], isVisible: !prev[key].isVisible },
    }));
  };

  const handleSelectAll = (toggle) => {
    const newColumnsVisible = Object.entries(columnsVisibility).reduce(
      (acc, [key, value]) => {
        acc[key] = { ...columnsVisibility[key], isVisible: toggle };
        return acc;
      },
      {},
    );
    updateColumnsVisibility(newColumnsVisible);
  };

  const { updateCheckedHead, checkAllDocuments, handleUpdateChecked } =
    useCheckboxTable(pagination, setPagination);

  const { downloadFile } = useDocumentExport();

  const handleDownloadCSV = (organizationId, pagination, dataOfColumns) => {
    let { search, orderBy, orderType, data } = pagination;

    const isCheckedList = data.some(({ isChecked }) => isChecked);
    const isFilter = search || orderType || orderBy || isCheckedList;

    const fileName = getFileName(
      organizationName,
      'Admins',
      user,
      CSV,
      isFilter,
    );

    if (!orderBy) {
      orderBy = DEFAULT_SORT;
    }

    const body = getExportParams(
      data,
      dataOfColumns,
      { search, orderBy, orderType },
      organizationId,
    );
    downloadFile('/export/admins', body, fileName);
  };

  const [columnsMain, updateColumnsMain] = useState(columnsHead);
  const [columns, updateColumns] = useState(columnsBody);

  const tableRef = useTableWidth(updateColumnsMain);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex">
                <h4 className="mb-0 font-size-18">
                  “{organizationName ? organizationName : ''}” admins
                </h4>
              </div>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableHeader
                    title="List of Admins"
                    handleAddNew={handleToggleModal}
                    handleAddExisting={handleToggleExistModal}
                    downloadCSV={() =>
                      handleDownloadCSV(
                        organizationId,
                        pagination,
                        columnsVisibility,
                      )
                    }
                    manageColumns={handleToggleColumn}
                    handleSearch={handleSearch}
                  />
                  <div ref={tableRef} className="table-wrapper">
                    <Table className="table-custom table table-centered table-nowrap table-document">
                      <TableHead
                        columnsHidden={columnsHidden}
                        handleOrder={handleOrder}
                        checkAllDocuments={checkAllDocuments}
                        pagination={pagination}
                        columnsHead={columnsMain}
                        columns={columns}
                        handleChangeColumns={(
                          columns,
                          columnsHead,
                          startIndex,
                          endIndex,
                        ) => {
                          updateColumns(
                            dragReorder(columns, startIndex, endIndex),
                          );
                          updateColumnsMain(
                            dragReorder(columnsHead, startIndex, endIndex),
                          );
                        }}
                        onResizeColumns={(columnIndex, changedWidth) => {
                          updateColumnsMain((columns) =>
                            getChangedResizeColumns(
                              columns,
                              columnIndex,
                              changedWidth,
                            ),
                          );
                        }}
                        onResizeEnd={(columnIndex) => {
                          updateColumnsMain((columns) =>
                            getEndResizeColumns(columns, columnIndex),
                          );
                        }}
                      />
                      <TableBody
                        onDeleteRow={(deletedId) => {
                          setPagination((pagination) =>
                            deleteRow(pagination, deletedId),
                          );
                        }}
                        columnsHidden={columnsHidden}
                        pagination={pagination}
                        setPagination={setPagination}
                        columnsBody={columns}
                        organizationName={organizationName}
                        updatingTable={updatingTable}
                        organizationId={organizationId}
                        updateCheckedHead={updateCheckedHead}
                        handleUpdateChecked={handleUpdateChecked}
                      />
                    </Table>
                  </div>
                  {!!pagination.data.length && (
                    <TableFooter
                      limit={pagination.limit}
                      handleChangeLimit={handleChangeLimit}
                      pageCount={pagination.pageCount}
                      pageNumber={pagination.pageNumber}
                      handlePageClick={handlePageClick}
                    />
                  )}
                  {!(pagination.data.length || isLoading) && (
                    <TablePaginationText text="There are no Admins to display" />
                  )}
                  {isLoading && <TableSpinner />}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AdminAddNewModal
        isOpenModal={isOpenModalAdd}
        handleToggleModal={handleToggleModal}
        organizationId={organizationId}
      />
      <AdminAddExistModal
        organizationId={organizationId}
        isOpen={isOpenExistModal}
        onSubmit={(event, { adminsIds, organizationId }) => {
          addAdmins(organizationId, adminsIds)
            .then(() => {
              updatingTable(true);
              toast.success(
                <MessageResetSuccess message={SUCCESS_MESSAGE_ADD} />,
              );
            })
            .finally(() => {
              setIsOpenExistModal(false);
            });
        }}
        onCancel={() => setIsOpenExistModal(false)}
      />
      <DisplayColumnsModal
        columnsVisibility={columnsVisibility}
        isOpen={displayMoldal.isOpen}
        handleUpdateColumnsVisible={handleUpdateColumnsVisible}
        handleSelectAll={handleSelectAll}
        onCancel={() => setDisplayModal((prev) => ({ ...prev, isOpen: false }))}
        handleSaveColumns={handleSaveColumns}
      />
    </>
  );
};

export default OrganizationAdmins;
