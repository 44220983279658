import { getOrganization } from './backend-helper';

export const getDataAPI = async (id) => {
  const response = await getOrganization(`/organizations/${id}`, id);

  const result = {};
  result.logo = response.data && response.data.logo && response.data.logo;
  result.name = response.data && response.data.name;

  return result;
};
