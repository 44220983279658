import React from 'react';
import placeholder from '../../../../assets/images/placeholder-large.svg';

const UserProfileImage = ({ avatar }) => {
  return (
    <img
      className="image-profile"
      src={avatar ? avatar.compactPath : placeholder}
      alt="placeholder"
    />
  );
};

export default UserProfileImage;
