import { API } from '../backend-helper';

const SYSTEM_NOTIFICATION = 'system-notifications';
const EMAIL_NOTIFICATION = 'email-notifications';
const COUNT = 'count';
const USERS = 'users';
const PROFLE = 'me/Profile';

export const changeNotification = () => {
  return API.patch(`/${USERS}/${PROFLE}/${EMAIL_NOTIFICATION}`);
};

export const getSystemNotificationList = (params) => {
  return API.get(`/${SYSTEM_NOTIFICATION}`, { params });
};

export const getSystemCountNotification = (params) => {
  return API.get(`/${SYSTEM_NOTIFICATION}/${COUNT}`, { params });
};

export const changeSystemNotification = (id, organizationId) => {
  return API.patch(`${SYSTEM_NOTIFICATION}/${id}`, { id: organizationId });
};

export const changeAllSystemNotification = (id) => {
  return API.patch(`${SYSTEM_NOTIFICATION}`, { id });
};
