const isOneElement = (array) => array?.length === 1;

export const isMultySelected = (dataList) => {
  if (isOneElement(dataList)) return false;

  const checkedCounter = dataList.reduce(
    (acc, { isChecked }) => (acc += +!!isChecked),
    0,
  );
  return checkedCounter > 1 ? true : !checkedCounter;
};
