import React from 'react';

import ChangeNameProfile from '../../../pages/Authentication/Profile/ChangeName';
import ProfileUploadPhoto from '../UploadPhoto';
import ProfileCardTitle from '../shared/CardTitle/CardTitle';

import { SUPERADMIN_ROLE } from '../../../constants/helper';
import { getDataUser } from '../../../utils/get-store-user';

const ProfilePersonalInfo = ({ handleUpdateUser }) => {
  const { role } = getDataUser();

  return (
    <div>
      <ProfileCardTitle title="Change personal info" />
      <hr className="divider-top" />
      <ProfileUploadPhoto handleUpdateUser={handleUpdateUser} />
      <hr className="divider-bottom" />
      {role !== SUPERADMIN_ROLE && (
        <ChangeNameProfile handleUpdateUser={handleUpdateUser} />
      )}
    </div>
  );
};

export default ProfilePersonalInfo;
