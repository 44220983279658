import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from '../../../store/actions';
import { isMobileHelper } from '../../../utils/is-mobile-helper';
import {
  leftSideBarDefault,
  leftSideBarCondensed,
} from '../../../constants/sidebarTypes';

import UserView from './UserView';
import Header from './Header';
import Sidebar from './Sidebar';
import { User } from '../../../constants/role';

const Layout = ({
  setRouteId,
  isPreloader,
  leftSideBarTheme,
  layoutWidth,
  leftSideBarType,
  topbarTheme,
  changeSidebarTheme,
  changeLayoutWidth,
  changeSidebarType,
  changeTopbarTheme,
  organizationImage,
  user,
  updateIsCanViewDocuments,
  children,
}) => {
  const [isMobile] = useState(() => isMobileHelper());
  const route = useParams().id;

  useEffect(() => {
    setRouteId(route);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (leftSideBarTheme) {
      changeSidebarTheme(leftSideBarTheme);
    }

    if (layoutWidth) {
      changeLayoutWidth(layoutWidth);
    }

    if (leftSideBarType) {
      changeSidebarType(leftSideBarType);
    }
    if (topbarTheme) {
      changeTopbarTheme(topbarTheme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMenuCallback = () => {
    if (leftSideBarType === leftSideBarDefault) {
      changeSidebarType(leftSideBarCondensed, isMobile);
    } else if (leftSideBarType === leftSideBarCondensed) {
      changeSidebarType(leftSideBarDefault, isMobile);
    }
  };

  return (
    <>
      {isPreloader && (
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>
      )}

      <div id="layout-wrapper">
        <Header
          toggleMenuCallback={toggleMenuCallback}
          organizationImage={organizationImage}
          user={user}
        />
        {user.role === User ? (
          <UserView
            id={user.id}
            updateIsCanViewDocuments={updateIsCanViewDocuments}
            user={user}
          >
            <Sidebar
              theme={leftSideBarTheme}
              type={leftSideBarType}
              isMobile={isMobile}
            />
            <div className="main-content">{children}</div>
          </UserView>
        ) : (
          <>
            <Sidebar
              theme={leftSideBarTheme}
              type={leftSideBarType}
              isMobile={isMobile}
            />
            <div className="main-content">{children}</div>
          </>
        )}
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  return {
    ...state.Layout,
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter(Layout));
