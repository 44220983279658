import React, { useState } from 'react';
import { Col, Row, Collapse, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import {
  getCurrentSetting,
  getDisableSetting,
} from '../../../../utils/disable-setting';
import {
  END_TRAINING_ID,
  START_TRAINING_ID,
  VIEW_ASSIGNEE_TRAINING_ID,
  VIEW_TRAINING_ID,
} from '../../../../constants/helper';
import { changeGroupSetting } from '../../../../utils/backend-helper';
import SwitchSetting from '../../../../components/switchers/SwitchSetting';
import {
  allTrainingAddOptions,
  allTrainingDeleteOptions,
  allTrainingEditOptions,
  trainingAddOptions,
  trainingDeleteOptions,
  trainingEditOptions,
} from '../settingsMok';
import ToggleList from '../shared/ToggleList';
import { insertSetting } from '../../../../utils/insert-setting';
import { getDataUser } from '../../../../utils/get-store-user';
import { isUserRole } from '../../../../utils/shared/is-user-role';

const TrainingSettingTab = ({
  settings,
  updateSettings,
  organizationId,
  id,
  isOnlyView = false,
}) => {
  const { role } = getDataUser();

  const [cols, setCols] = useState([false, false]);

  const handleUpdateCols = (columnIndex) => {
    setCols((prev) =>
      prev.map((toggle, index) => (index === columnIndex ? !toggle : toggle)),
    );
  };

  const handleChangeGroupSetting = (id, organizationId, type, subOptions) => {
    changeGroupSetting(id, organizationId, type).then(({ data }) => {
      const isMainRadioId = [
        VIEW_TRAINING_ID,
        VIEW_ASSIGNEE_TRAINING_ID,
      ].includes(data.type);
      if (isMainRadioId && !data.isSwitchedOn) {
        const disabledSettings = getDisableSetting(
          settings,
          START_TRAINING_ID,
          END_TRAINING_ID,
        );
        updateSettings(disabledSettings);
        return;
      }

      updateSettings((prev) => insertSetting(prev, data, subOptions));
    });
  };

  const handleUpdateRadio = (event, id, organizationId, VIEW_TASK_ID) => {
    event.stopPropagation();
    handleChangeGroupSetting(id, organizationId, VIEW_TASK_ID);
  };

  const handleChecked = (type) => {
    if (!settings.length) return false;

    const setting = settings.find((setting) => setting.type === type);
    return setting && setting.isSwitchedOn;
  };

  const allViewList = [
    {
      text: 'Edit option',
      list: allTrainingEditOptions,
    },
    {
      text: 'Delete option',
      list: allTrainingDeleteOptions,
    },
    {
      text: 'Add option',
      list: allTrainingAddOptions,
    },
  ];

  const viewList = [
    {
      text: 'Edit option',
      list: trainingEditOptions,
    },
    {
      text: 'Delete option',
      list: trainingDeleteOptions,
    },
    {
      text: 'Add option',
      list: trainingAddOptions,
    },
  ];

  return (
    <>
      <Row>
        <Col className="permissions-document">
          <h5>Permissions for Training Records</h5>
          {!isOnlyView && (
            <>
              <p>
                <b>Turning On</b> the options below will allow the users of the
                current User Group to access the Trainings.
              </p>
              <p>
                You can <b>turn on only one higher-level option</b>, and among
                the grouped options only one can be turned on under the enabled
                parent-option.
              </p>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="accordion">
            <div
              className="accordion-header"
              onClick={() => handleUpdateCols(0)}
            >
              <div className="form-check accordion-header__radio">
                <SwitchSetting
                  id={id}
                  organizationId={organizationId}
                  handleChecked={handleChecked}
                  handleUpdateRadio={handleUpdateRadio}
                  DOCUMENTS_ID={VIEW_TRAINING_ID}
                  isDisabled={
                    isOnlyView
                      ? true
                      : getCurrentSetting(settings, VIEW_ASSIGNEE_TRAINING_ID)
                  }
                />
                <p>
                  {' '}
                  View all training records
                  <i
                    id="training-tooltip-accordion-1"
                    className="bx bx-info-circle info"
                  ></i>
                </p>
                <UncontrolledTooltip
                  placement="top"
                  id="tooltip-accordion"
                  target="training-tooltip-accordion-1"
                >
                  <p className="mb-0">
                    {isUserRole(role)
                      ? 'Allows you to view all the training records in the current Organization'
                      : 'The users will be able to view all the training records in the current Organization'}
                  </p>
                </UncontrolledTooltip>
              </div>
              <i
                className={classnames('bx bx-chevron-down arrow', {
                  downArrow: cols[0],
                })}
              ></i>
            </div>
          </div>
          <Collapse isOpen={cols[0]}>
            <div className="collapse-content">
              {allViewList.map(({ text, list }, index) => {
                return (
                  <ToggleList
                    isOnlyView={isOnlyView}
                    key={index}
                    list={list}
                    text={text}
                    settings={settings}
                    id={id}
                    mainId={VIEW_TRAINING_ID}
                    organizationId={organizationId}
                    handleChangeGroupSetting={handleChangeGroupSetting}
                    handleChecked={handleChecked}
                  />
                );
              })}
            </div>
          </Collapse>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="accordion">
            <div
              className="accordion-header"
              onClick={() => handleUpdateCols(1)}
            >
              <div className="form-check accordion-header__radio">
                <SwitchSetting
                  id={id}
                  organizationId={organizationId}
                  handleChecked={handleChecked}
                  handleUpdateRadio={handleUpdateRadio}
                  DOCUMENTS_ID={VIEW_ASSIGNEE_TRAINING_ID}
                  isDisabled={
                    isOnlyView
                      ? true
                      : getCurrentSetting(settings, VIEW_TRAINING_ID)
                  }
                />
                <p>
                  {' '}
                  View training records, where the user is an Assignee, or a
                  Creator, or an Assigner
                  <i
                    id="training-tooltip-accordion-2"
                    className="bx bx-info-circle info"
                  ></i>
                </p>
                <UncontrolledTooltip
                  placement="top"
                  id="tooltip-accordion"
                  target="training-tooltip-accordion-2"
                >
                  <p className="mb-0">
                    {isUserRole(role)
                      ? 'In the current Organization, allows you to view only training records, where you are an Assignee, or a Creator, or an Assigner'
                      : 'In the current Organization, the users will be able to view only training records where they are an Assignee, or a Creator, or an Assigner. A training record Assigner can be selected by the training record Creator, or an Assigner can be the same person as a Creator'}
                  </p>
                </UncontrolledTooltip>
              </div>
              <i
                className={classnames('bx bx-chevron-down arrow', {
                  downArrow: cols[1],
                })}
              ></i>
            </div>
          </div>
          <Collapse isOpen={cols[1]}>
            <div className="collapse-content">
              {viewList.map(({ text, list }, index) => {
                return (
                  <ToggleList
                    isOnlyView={isOnlyView}
                    key={index}
                    list={list}
                    text={text}
                    settings={settings}
                    id={id}
                    mainId={VIEW_ASSIGNEE_TRAINING_ID}
                    organizationId={organizationId}
                    handleChangeGroupSetting={handleChangeGroupSetting}
                    handleChecked={handleChecked}
                  />
                );
              })}
            </div>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default TrainingSettingTab;
