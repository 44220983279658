export const getDocumentName = ({
  type,
  typeDescription,
  number,
  name,
} = {}) => {
  return `${type || ''}${typeDescription ? `-${typeDescription}` : ''} ${
    number || ''
  } ${name || ''}`;
};
