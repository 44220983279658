import React, { useState } from 'react';
import { Modal } from 'reactstrap';

const DocumentText = ({ text, modalContent }) => {
  const [isOpen, updateIsOpen] = useState(false);

  const handleToggleModal = () => updateIsOpen((isOpen) => !isOpen);

  return (
    <>
      <p className="artifacts-document-text" onClick={handleToggleModal}>
        {text}
      </p>
      <Modal
        isOpen={isOpen}
        toggle={handleToggleModal}
        centered={true}
        size="lg"
      >
        <div className="modal-header align-items-center">
          <h5 className="modal-title mt-0">{text}</h5>
          <button
            type="button"
            onClick={handleToggleModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="bx bx-x close-icon-modal"></i>
          </button>
        </div>
        <div className="modal-body">{modalContent}</div>
      </Modal>
    </>
  );
};

export default DocumentText;
