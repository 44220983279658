import React from 'react';
import { Col, Row } from 'reactstrap';

import DashboardActions from './Actions';
import DashboardTraining from './Training/dashboard-training';

const DashboardDueDates = () => {
  return (
    <Row>
      <Col md="6" className="dashboard-container">
        <DashboardActions />
      </Col>
      <Col md="6" className="dashboard-container">
        <DashboardTraining />
      </Col>
    </Row>
  );
};

export default DashboardDueDates;
