import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';

import CardCounter from './CardCounter';
import TrainingIcon from '../../../assets/images/dashboard/ic-training-purple.svg';
import ActionIcon from '../../../assets/images/dashboard/ic-action-orange.svg';
import DocumentBlueIcon from '../../../assets/images/dashboard/ic-document-blue.svg';
import DocumentGreenIcon from '../../../assets/images/dashboard/ic-document-green.svg';

import { useRouteMatch } from 'react-router';
import { getCounters } from '../../../utils/api/dashboard-api';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const DashboardCounters = () => {
  const { id: organizationId } = useRouteMatch().params;
  const onError = useErrorHandler();

  const [dataCounter, updateDataCounter] = useState({});

  useEffect(() => {
    getCounters({ organizationId })
      .then(({ data }) => updateDataCounter(data))
      .catch(onError);
  }, [organizationId, onError]);

  return (
    <Row>
      <Col sm="12" md="6" xl="3">
        <CardCounter
          counter={dataCounter?.actionsToDo}
          title="Actions to do"
          src={ActionIcon}
          background="orange-background"
        />
      </Col>
      <Col sm="12" md="6" xl="3">
        <CardCounter
          counter={dataCounter?.trainingToPass}
          title="Training to pass"
          src={TrainingIcon}
          background="purple-background"
        />
      </Col>
      <Col sm="12" md="6" xl="3">
        <CardCounter
          counter={dataCounter?.documentsToReview}
          title="Documents to Review"
          src={DocumentBlueIcon}
          background="blue-background"
        />
      </Col>
      <Col sm="12" md="6" xl="3">
        <CardCounter
          counter={dataCounter?.documentsToApprove}
          title="Documents to Approve"
          src={DocumentGreenIcon}
          background="green-background"
        />
      </Col>
    </Row>
  );
};

export default DashboardCounters;
