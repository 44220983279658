import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import TrainingViewHeader from '../shared/ViewHeader';
import InfoColumn from '../../../../../components/shared/GeneralColumn';
import TrainingAttachedFile from '../AttachedFile';
import PassQuestionForm from '../PassQuestionForm';

import { passTrainingRecord } from '../../../../../utils/api/training-records-api';
import { getQuestionModel } from '../../../../../utils/training-records-helper/get-question-model';
import { updateCompletion } from '../../../../../store/actions';
import { isUrlForLinkRecord, isUrlString } from '../../../../../utils/is-url';
import { getDataUser } from '../../../../../utils/get-store-user';
import { useErrorHandler } from '../../../../../custom-hooks/useErrorHandler';
import InfoColumnWrapper from '../../../../../components/shared/InfoColumnWrapper';

const TrainingPass = ({
  trainingModel,
  updatePassMode,
  updateTrainingModel,
  updateCompletion,
}) => {
  const { role } = getDataUser();

  const {
    number,
    name,
    dueDate,
    description,
    link,
    files,
    questions,
    linkDescription,
    extraLinkDescription,
    extraLink,
  } = trainingModel;

  const onError = useErrorHandler();

  const handleSubmit = (_, values) => {
    const { id, ...body } = values;
    const { questions, organizationId } = body;

    const newBody = { organizationId, questions: getQuestionModel(questions) };
    passTrainingRecord(id, newBody)
      .then(({ data }) => {
        updateTrainingModel(data);
        updateCompletion();
        updatePassMode(false);
      })
      .catch(onError);
  };

  return (
    <>
      <div className="general-header">
        <h6 className="mt-2">Pass Training</h6>
      </div>
      <hr />
      <div className="general-info">
        <TrainingViewHeader
          number={number}
          name={name}
          dueDate={dueDate}
          description={description}
        />
      </div>
      <hr />
      <Row className="mt-3">
        <Col>
          <InfoColumn
            title='Link to Record:' 
            text={isUrlForLinkRecord(link, role) ? (
              <a href={link} target='_self'>
                <i className='bx bx-link top-link'></i>
                {linkDescription}
              </a>
            ) : (
              <span>{linkDescription || '-'}</span>
            )}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <InfoColumn 
            title='External Link:' 
            text={isUrlString(extraLink) ? (
              <a href={extraLink} target='_self'>
                <i className='bx bx-link top-link'></i>
                {extraLinkDescription}
              </a>
            ) : (
              <span>{extraLinkDescription || '-'}</span>
            )}
          />     
        </Col>
      </Row>
      <hr />
      <div className="general-attached">
        <InfoColumnWrapper title="File(s) attached:">
          <TrainingAttachedFile files={files} />
        </InfoColumnWrapper>
      </div>
      <hr />
      <PassQuestionForm
        questions={questions}
        handleSubmit={handleSubmit}
        updatePassMode={updatePassMode}
      />
    </>
  );
};

export default connect(null, { updateCompletion })(TrainingPass);
