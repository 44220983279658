export const getTypeDescription = ({
  type,
  typeDescription,
  revision,
  isFinal,
  name,
}) => {
  return `${type} ${
    typeDescription ? `- ${typeDescription}` : ''
  } ${name} Revision ${revision} ${isFinal ? '(final)' : ''}`;
};
