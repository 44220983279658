import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Input, Spinner } from 'reactstrap';
import { debounce } from 'lodash';

import { isGlobalSearchUrl } from '../../../utils/search-helper/is-global-search-url';
import { DEBOUNCE_TIMER } from '../../../constants/helper';
import {
  CLEAR_SEARCH,
  CLEAR_TAG_SEARCH,
  INPUT_SEARCH,
  TOGGLE_SEARCH,
} from '../../../store/search/actionTypes';
import { EMPTY_STRING } from '../../../constants/table';

const SearchHeader = () => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const inputMobileRef = useRef(null);
  const currentPage = useRef(null);

  const history = useHistory();
  const { pathname } = useLocation();
  const { id: organizationId } = useRouteMatch().params;

  const redirectToGlobalSearch = useCallback(() => {
    history.push(`/organizations/${organizationId}/global-search`);
  }, [history, organizationId]);

  const { inputSearch, isTagSearch, isLoading, isOpenSearchMobile } = useSelector(
    ({ Search }) => Search,
  );

  const handleRedirect = useCallback(
    (search) => {
      if (!isGlobalSearchUrl(pathname)) {
        redirectToGlobalSearch();
      }
      dispatch({ type: INPUT_SEARCH, payload: search });
    },
    [pathname, dispatch, redirectToGlobalSearch],
  );

  const debounceSearch = useMemo(() => {
    return debounce(handleRedirect, DEBOUNCE_TIMER);
  }, [handleRedirect]);

  useEffect(() => {
    inputRef.current.value = inputSearch;
    inputMobileRef.current.value = inputSearch;
    if (isTagSearch) {
      redirectToGlobalSearch();
      dispatch({ type: CLEAR_TAG_SEARCH });
    }
  }, [dispatch, inputSearch, isTagSearch, redirectToGlobalSearch]);

  useEffect(() => {
    const currentPathname = currentPage.current;
    if (currentPathname !== pathname && !isGlobalSearchUrl(pathname)) {
      inputRef.current.value = EMPTY_STRING;
      inputMobileRef.current.value = EMPTY_STRING;
    }
    currentPage.current = pathname;
  }, [pathname]);

  const clearSearch = () => {
    dispatch({ type: CLEAR_SEARCH });
  };

  return (
    <>
      <div className="dropdown d-inline-block d-lg-none ml-2">
        <button
          type="button"
          className="btn header-item noti-icon waves-effect"
          id="page-header-search-dropdown"
          onClick={() => dispatch({ type: TOGGLE_SEARCH })}
        >
          <i className="mdi mdi-magnify"></i>
        </button>
        <div
          className={isOpenSearchMobile ? "dropdown-menu dropdown-menu-lg dropdown-menu-left p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
          aria-labelledby="page-header-search-dropdown"
        >
          <div className="p-3">
            <div className="form-group m-0">
              <div className="input-group">
                <Input
                  type="text"
                  innerRef={inputMobileRef}
                  onChange={(event) => debounceSearch(event.target.value)}
                  className="form-control w-100"
                  placeholder="Search..."
                />
              </div>              
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-lg-inline-block search-header">
        <Input
          type="text"
          innerRef={inputRef}
          onChange={(event) => debounceSearch(event.target.value)}
          className="form-control search-input"
          placeholder="Search..."
        />
        <i className="bx bx-search-alt search-icon"></i>
        {!!inputSearch?.length && (
          <i className="bx bx-x clean-icon" onClick={clearSearch}></i>
        )}
        <div className="spinner-container">
          {isLoading && <Spinner className="search-spinner" color="primary" />}
        </div>
      </div>
    </>
  );
};

export default SearchHeader;
