import {
  actionUserNav,
  documentUserNav,
  trainingUserNav,
  listSetting,
} from '../constants/sidebar-navigation';

export const getClientUserNav = (permissions) => {
  const {
    canViewDocuments,
    canViewTasks,
    canViewTrainingRecords,
    сanManageOrganization,
  } = permissions;

  const navigation = [];
  if (canViewTasks) {
    navigation.push(actionUserNav);
  }
  if (canViewDocuments) {
    navigation.push(documentUserNav);
  }
  if (canViewTrainingRecords) {
    navigation.push(trainingUserNav);
  }
  if (сanManageOrganization) {
    navigation.push(listSetting);
  }

  return navigation;
};
