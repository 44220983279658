import React, { useState, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import Button from '../../../../../components/Button';
import Checkbox from '../shared/Checkbox';
import CheckboxSubmit from '../shared/CheckboxSubmit';

import { updateCheckboxAnswer } from '../../../../../utils/training-records-helper/pass-question-checkbox-change';
import {
  changeQuestion,
  isAllQuestionSelected,
} from '../../../../../utils/training-records-helper/get-correct-question';

const PassQuestionForm = ({ handleSubmit, questions, updatePassMode }) => {
  const organizationId = useRouteMatch().params.id;
  const recordId = useRouteMatch().params.recordId;

  const [isChecked, updateIsChecked] = useState([
    {
      text: 'I have acknowledged and understood all the artifacts related to this Training.',
      isChecked: false,
    },
    {
      text: 'I understand if I have any questions on the artifacts or the Training itself, I may ask my supervisor.',
      isChecked: false,
    },
  ]);

  const updateChecked = (i) => {
    updateIsChecked((prev) =>
      prev.map((item, idx) => {
        return i === idx ? { ...item, isChecked: !item.isChecked } : item;
      }),
    );
  };

  const [questionList, updateQuestionList] = useState([]);

  useEffect(() => {
    updateQuestionList(changeQuestion(questions));
  }, [questions]);

  const updateCheckedQuestion = (index, answerId) => {
    updateQuestionList((questions) =>
      updateCheckboxAnswer(questions, index, answerId),
    );
  };

  const isDisabled = useMemo(() => {
    return !(
      isAllQuestionSelected(questionList) &&
      isChecked.every(({ isChecked }) => isChecked)
    );
  }, [isChecked, questionList]);

  return (
    <AvForm className="form-horizontal mt-2" onValidSubmit={handleSubmit}>
      {!!questions.length && (
        <Row>
          <Col>
            <div className="info-column">
              <p className="info-column__title">Questions:</p>
              <div className="question-container pass-question-container">
                {questionList.map(({ id, number, name, answers }, index) => {
                  return (
                    <div key={number} className="question-item">
                      <p className="question-item__text">
                        <b>{index + 1}.</b> {name}
                      </p>
                      <ol type="a">
                        {answers.map(({ id, number, name, isSelected }) => (
                          <li key={number}>
                            <div className="pass-answer">
                              <p>{name}</p>
                              <Checkbox
                                id={id}
                                isChecked={isSelected}
                                classes="checkbox-answer"
                                onChange={() =>
                                  updateCheckedQuestion(index, id)
                                }
                              />
                            </div>
                          </li>
                        ))}
                      </ol>
                    </div>
                  );
                })}
              </div>
            </div>
            <hr />
          </Col>
        </Row>
      )}
      {isChecked.map(({ text, isChecked }, i) => {
        return (
          <CheckboxSubmit
            key={i}
            id={i}
            text={text}
            isChecked={isChecked}
            updateChecked={updateChecked}
          />
        );
      })}
      <AvField
        name="organizationId"
        type="text"
        value={organizationId}
        className="btn-hidden"
      />
      <AvField
        name="questions"
        type="text"
        value={questionList}
        className="btn-hidden"
      />
      <AvField name="id" type="text" value={recordId} className="btn-hidden" />
      <Row className="form-group mt-2">
        <Col className="text-right">
          <Button
            type="button"
            color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
            handler={() => updatePassMode(false)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
            disabled={isDisabled}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </AvForm>
  );
};

export default PassQuestionForm;
