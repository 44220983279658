import React, { useState, useRef } from 'react';
import {
  Card,
  Row,
  Col,
  CardBody,
  Button,
  ButtonGroup,
  Container,
  Spinner,
} from 'reactstrap';
import Cropper from 'react-cropper';

import {
  configCropper,
  RIGHT,
  LEFT,
  ROTATE_DEGREE,
} from '../../../constants/cropperConfig';
import 'cropperjs/dist/cropper.css';

import { canvasToBlob } from '../../../utils/file-helper';
import CustomButton from '../../Button';

const ImageCropper = ({
  handleToggleModal,
  urlFile,
  isLoading,
  handleSaveImage,
  handleLoading,
}) => {
  const cropperRef = useRef(null);

  const [cropper, setCropperConfig] = useState({
    ...configCropper,
    src: urlFile,
  });

  const onCropImage = () => {
    handleLoading();
    const cropper = cropperRef.current.cropper;

    canvasToBlob(cropper).then((body) => handleSaveImage(body));
  };

  const handleRotate = (direction = RIGHT) => {
    setCropperConfig((prev) => ({
      ...prev,
      rotate:
        direction === RIGHT
          ? prev.rotate + ROTATE_DEGREE
          : prev.rotate - ROTATE_DEGREE,
    }));
  };

  return (
    <Container fluid>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <div className="modal-header align-items-center">
                <h5 className="modal-title mt-0">Crop Image</h5>
                <button
                  type="button"
                  onClick={handleToggleModal}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bx bx-x close-icon-modal"></i>
                </button>
              </div>
              <div className="mt-4">
                <div className="img-container mb-2">
                  <Cropper
                    className="image-cropper"
                    aspectRatio={cropper.ratio}
                    preview=".img-preview"
                    guides={false}
                    src={cropper.src}
                    rotateTo={cropper.rotate}
                    dragMode={cropper.dragMode}
                    ref={cropperRef}
                    checkOrientation={true}
                  />
                </div>
              </div>
              <Row id="actions">
                <Col
                  sm={6}
                  xl={7}
                  className="img-crop-preview-btns docs-buttons w-100"
                >
                  <div className="button-items mt-2">
                    <Row>
                      <Col sm={12}>
                        <ButtonGroup>
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => handleRotate(LEFT)}
                            title="Rotate Left"
                          >
                            <span className="docs-tooltip" id="rotate1">
                              <span className="mdi mdi-rotate-left"></span>
                            </span>
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            onClick={() => handleRotate(RIGHT)}
                            title="Rotate Right"
                          >
                            <span className="docs-tooltip" id="rotate2">
                              <span className="mdi mdi-rotate-right"></span>
                            </span>
                          </Button>
                        </ButtonGroup>
                      </Col>
                      <Col sm={12}>
                        <CustomButton
                          type="button"
                          color="btn btn-primary w-md waves-effect waves-light"
                          handler={onCropImage}
                        >
                          Crop & save
                        </CustomButton>
                        <CustomButton
                          color="btn btn-light w-md waves-effect waves-light mr-3"
                          handler={handleToggleModal}
                        >
                          Cancel
                        </CustomButton>
                        {isLoading && (
                          <Spinner className="mr-2" color="primary" />
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col sm={6} xl={5}>
                  <div className="docs-preview image-crop-preview clearfix">
                    <div className="img-preview preview-lg"></div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ImageCropper;
