import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Select from 'react-select';
import { debounce } from 'lodash';

import { customStyles } from '../../../utils/select-styles';
import { DEBOUNCE_TIMER } from '../../../constants/helper';

const SingleSelect = ({
  handleRequest,
  configOptions,
  handleChange,
  url,
  isDisabled = false,
  placeholder = 'Choose',
  docTypeId,
}) => {
  const [options, updateOptions] = useState([]);

  const handleRequestAPI = useCallback(
    (url, search = '', docTypeId) => {
      return handleRequest(url, search, docTypeId).then((data) =>
        updateOptions(configOptions(data)),
      );
    },
    [configOptions, handleRequest],
  );

  useEffect(() => {
    handleRequestAPI(url);
  }, [handleRequestAPI, url]);

  const handleSearch = useMemo(() => {
    return debounce((input) => {
      handleRequestAPI(url, input, docTypeId);
    }, DEBOUNCE_TIMER);
  }, [docTypeId, handleRequestAPI, url]);

  return (
    <Select
      isDisabled={isDisabled}
      isMulti={false}
      styles={customStyles}
      isClearable={true}
      options={options}
      onChange={(value) => handleChange(value)}
      onInputChange={(value) => handleSearch(value)}
      placeholder={placeholder}
    />
  );
};

export default SingleSelect;
