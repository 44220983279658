import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { getTrainingRecordsFile } from '../../../../../utils/api/training-records-api';
import { formatBytes } from '../../../../../utils/format-bytes-helper';
import { getFileByUrl } from '../../../../../utils/backend-helper';
import { saveFileAs } from '../../../../../utils/fileSaver';
import PlaceholderText from '../../../../../components/shared/PlaceholderText';

const TrainingAttachedFile = ({ files, canDownload = true }) => {
  const organizationId = useRouteMatch().params.id;
  const recordId = useRouteMatch().params.recordId;

  const handleDownload = async (recordId, id, organizationId) => {
    try {
      const document = await getTrainingRecordsFile(
        recordId,
        id,
        organizationId,
      );
      const { path, fileName } = document.data;

      const { data } = await getFileByUrl(path);
      saveFileAs(data, fileName);
    } catch (error) {}
  };

  if (files.length) {
    return (
      <div className="file-attached-container">
        {files.map(({ fileName, fileSize, id }) => {
          return (
            <div className="document-attached" key={id}>
              <div
                className="file-wrapper"
                onClick={() =>
                  canDownload
                    ? handleDownload(recordId, id, organizationId)
                    : {}
                }
              >
                <i className="bx bxs-file-doc"></i>
              </div>
              <div className="file-info">
                <p className="file-info__name">{fileName}</p>
                <p className="file-info__size">Size: {formatBytes(fileSize)}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return <PlaceholderText text="No files." />;
};

export default TrainingAttachedFile;
