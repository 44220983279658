import React from 'react';
import moment from 'moment';
import { Col, Row } from 'reactstrap';

import InfoColumn from '../../../../../../components/shared/GeneralColumn';

const TrainingViewHeader = ({ number, name, dueDate, description }) => {
  const trainingInfo = [
    {
      title: 'System #:',
      text: number,
    },
    {
      title: 'Training Name:',
      text: name,
    },
    {
      title: 'Due Date:',
      text: dueDate ? moment(dueDate).format('ll') : '',
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between">
        {trainingInfo.map(({ title, text }) => (
          <div key={title} className="info-column">
            <p className="info-column__title">{title}</p>
            <p className="info-column__text">{text}</p>
          </div>
        ))}
      </div>
      <Row>
        <Col xs="12" className="mt-3">
          <InfoColumn title="Description:" text={description} />
        </Col>
      </Row>
    </>
  );
};

export default TrainingViewHeader;
