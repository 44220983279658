import { ONE_ROUTE, SUPERADMIN_ROLE } from '../constants/helper';
import {
  DASHBOARD,
  ORGANIZATIONS_ROUTE,
  ORGANIZATIONS_USER_ROUTE,
} from '../constants/routes';
import { getOrganizations } from './backend-helper';

export const getLoginRoute = async (user) => {
  const { role, organizationCount } = user;

  if (role === SUPERADMIN_ROLE) {
    return ORGANIZATIONS_ROUTE;
  }

  if (organizationCount > ONE_ROUTE) {
    return ORGANIZATIONS_USER_ROUTE;
  }

  const {
    data: [{ id }],
  } = await getOrganizations();
  return `${ORGANIZATIONS_ROUTE}/${id}${DASHBOARD}`;
};
