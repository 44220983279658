import moment from 'moment';
import { isObject } from './is-object';

import { DUE_DATE_KEY, FORMAT_DUE_DATE } from '../constants/helper';

export const getTaskModel = (model, taskModel) => {
  const newModel = {};

  Object.keys(model).forEach((key) => {
    const taskValue = taskModel[key];
    const modelValue = model[key];

    let currentValue;
    if (Array.isArray(taskValue)) {
      currentValue = taskValue;
    } else if (isObject(taskValue)) {
      currentValue = taskValue.id;
    } else if (key === DUE_DATE_KEY) {
      currentValue = moment(taskValue).format(FORMAT_DUE_DATE);
    } else if (taskValue === 0) {
      currentValue = taskValue;
    } else {
      currentValue = taskValue || modelValue.value;
    }

    newModel[key] = { ...modelValue, value: currentValue };
  });

  return newModel;
};
