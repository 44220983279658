export const getDropdownItems = (downloadCSV, downloadOneDocument, downloadManyDocuments) => {
  const list = [];

  if (downloadCSV) {
    list.push({
      text: 'CSV file',
      handler: downloadCSV,
      tooltipText: 'Click to export data from the table in a CSV file. If you apply any filters, only data matching the filter criteria will be exported. If you select particular records, only the selected records will be exported. Otherwise, whole data will be exported'
    })
  }

  if (downloadOneDocument) {
    list.push({
      text: 'PDF archive',
      handler: downloadOneDocument,
      tooltipText: 'Click to export one record per one PDF file. If you apply any filters, only records matching the filter criteria will be exported. If you select particular records, only the selected records will be exported. Otherwise, the whole dataset will be exported'
    })
  }

  if (downloadManyDocuments){
    list.push({
      text: 'Single PDF',
      handler: downloadManyDocuments,
      tooltipText: 'Click to export data from the table in a PDF file. If you apply any filters, only data matching the filter criteria will be exported. If you select particular records, only the selected records will be exported. Otherwise, the whole dataset will be exported'
    })
  }

  return list;
}