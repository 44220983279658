import React, { useMemo } from 'react';
import { getFilteredReferences } from '../../../../utils/documents/get-filtered-references';
import DocumentReferencesLink from './Link';

const ReferencesView = ({ referencesData, updateEditMode }) => {
  const { canBeEdited, references } = referencesData;

  const filteredReferences = useMemo(() => {
    return getFilteredReferences(references);
  }, [references]);

  return (
    <div className="general">
      <div className="general-header">
        <h6 className="general-header__title">References</h6>
        {canBeEdited && (
          <button
            className="general__btn-edit"
            onClick={() => updateEditMode(true)}
          >
            <i className="bx bxs-edit-alt"></i> Edit
          </button>
        )}
      </div>
      {filteredReferences.map(({ title, links }) => {
        return (
          <DocumentReferencesLink key={title} title={title} links={links} />
        );
      })}
    </div>
  );
};

export default ReferencesView;
