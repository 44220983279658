import {
  DEFAULT_DOCUMENT_SORT,
  DEFAULT_NAME_SORT,
} from '../../constants/helper';
import { ASC, DESC } from '../../constants/table';

export const getDefaultSort = (orderBy, orderType, dataOfColumns) => {
  if (!orderBy) {
    const { isVisible } = dataOfColumns.number;

    return [
      isVisible ? DEFAULT_DOCUMENT_SORT : DEFAULT_NAME_SORT,
      isVisible ? DESC : ASC,
    ];
  }
  return [orderBy, orderType];
};
