import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

import { DEBOUNCE_TIMER } from '../constants/helper';
import { getDocumentUsers } from '../utils/backend-helper';
import { configureGetUsers } from '../utils/config-get-organization';

export const useUserSelect = (organizationId, user) => {
  const [usersOption, updateUsersOption] = useState([]);

  useEffect(() => {
    getDocumentUsers(organizationId, '').then(({ data }) =>
      updateUsersOption(configureGetUsers(data)),
    );
  }, [organizationId]);

  const [defaultUser, updateDefaultUser] = useState({ label: '', value: '' });

  useEffect(() => {
    const { firstName, lastName, email, id } = user;

    updateDefaultUser({
      label: `${firstName || ''} ${lastName || ''} (${email || ''})`,
      value: `${id}`,
    });
  }, [user]);

  const debounceHandleAssignerSearch = useMemo(() => {
    return debounce((input) => {
      getDocumentUsers(organizationId, input).then(({ data }) =>
        updateUsersOption(configureGetUsers(data)),
      );
    }, DEBOUNCE_TIMER);
  }, [organizationId]);

  const handleUsersSearch = (value) => {
    debounceHandleAssignerSearch(value);
  };

  return { usersOption, defaultUser, handleUsersSearch, updateDefaultUser };
};
