import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';

import MessageResetSuccess from '../../../../components/ToastSuccess';
import Index from '../../../../components/Button';

import { changeUserInfo } from '../../../../utils/backend-helper';
import { SUCCESS_UPDATE_NAME } from '../../../../constants/messages';
import { getDataUser } from '../../../../utils/get-store-user';
import { MAX_INPUT_LENGTH } from '../../../../constants/helper';

const ChangeNameProfile = ({ handleUpdateUser }) => {
  const { id, firstName, lastName } = getDataUser();

  const [values, setValues] = useState({ firstName, lastName });

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  const handleValidSubmit = (_, { id, firstName, lastName }) => {
    const fieldInputs = [firstName, lastName].map((field) =>
      field ? field : null,
    );

    changeUserInfo(id, ...fieldInputs).then(({ data }) => {
      toast.success(<MessageResetSuccess message={SUCCESS_UPDATE_NAME} />);
      handleUpdateUser(data);
    });
  };

  return (
    <AvForm
      className="form-horizontal"
      onValidSubmit={(_, values) => handleValidSubmit(_, values)}
    >
      <Row>
        <Col>
          <AvField
            name="firstName"
            type="text"
            label="First name"
            className="form-control"
            placeholder="Enter your first name"
            value={values.firstName}
            onChange={onChange}
            maxLength={MAX_INPUT_LENGTH}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AvField
            name="lastName"
            type="text"
            label="Last name"
            className="form-control"
            placeholder="Enter your last name"
            value={values.lastName}
            onChange={onChange}
            maxLength={MAX_INPUT_LENGTH}
          />
        </Col>
      </Row>
      <Row className="form-group">
        <Col className="text-right">
          <Index
            disabled={!(values.firstName.trim() && values.lastName.trim())}
            color="btn btn-submit waves-effect waves-light mt-4"
            type="submit"
          >
            Save Changes
          </Index>
        </Col>
      </Row>
      <AvField name="id" type="text" value={id} className="btn-hidden" />
    </AvForm>
  );
};

export default ChangeNameProfile;
