export const editOptionsCol1 = [
  {
    label: 'Edit all not approved Documents',
    tooltipText:
      'The users will be able to edit only not yet approved Documents in the current Organization',
    userTooltipText:
      'Allows you to edit only not yet approved Documents in the current Organization',
    name: 'edit-col1',
    type: 1,
    groupTypes: [2, 3],
    subOptions: [
      {
        label: 'Section General',
        type: 39,
      },
      {
        label: 'Section Quality Actions',
        type: 40,
      },
      {
        label: 'Section References',
        type: 41,
      },
    ],
  },
  {
    label: 'Edit all related not approved Documents',
    tooltipText:
      'In the current Organization, the users will be able to edit only not yet approved Documents where they are selected as Reviewer, or Approver, or Responsible, or they are an Owner',
    userTooltipText:
      'In the current Organization, allows you to edit only not yet approved Documents where you are selected as Reviewer, or Approver, or Responsible, or you are an Owner',
    name: 'edit-col1',
    type: 2,
    groupTypes: [1, 3],
    subOptions: [
      {
        label: 'Section General',
        type: 42,
      },
      {
        label: 'Section Quality Actions',
        type: 43,
      },
      {
        label: 'Section References',
        type: 44,
      },
    ],
  },
  {
    label: 'Edit all not approved Documents where the user is an Owner',
    tooltipText:
      'In the current Organization, the users will be able to edit only not yet approved Documents where they are an Owner',
    userTooltipText:
      'In the current Organization, allows you to edit only not yet approved Documents where you are an Owner',
    name: 'edit-col1',
    type: 3,
    groupTypes: [1, 2],
    subOptions: [
      {
        label: 'Section General',
        type: 45,
      },
      {
        label: 'Section Quality Actions',
        type: 46,
      },
      {
        label: 'Section References',
        type: 47,
      },
    ],
  },
];

export const deleteOptionsCol1 = [
  {
    label: 'Delete all Documents',
    tooltipText:
      'The users will be able to delete all the Documents in the current Organization',
    userTooltipText:
      'Allows you to delete all the Documents in the current Organization',
    name: 'delete-col1',
    type: 4,
    groupTypes: [5, 6, 7, 8, 9],
  },
  {
    label: 'Delete all not approved Documents',
    tooltipText:
      'The users will be able to delete all not yet approved Documents in the current Organization',
    userTooltipText:
      'Allows you to delete all not yet approved Documents in the current Organization',
    name: 'delete-col1',
    type: 5,
    groupTypes: [4, 6, 7, 8, 9],
  },
  {
    label: 'Delete all related Documents',
    tooltipText:
      'In the current Organization, the users will be able to delete all Documents where they are selected as Reviewer, or Approver, or Responsible, or they are an Owner',
    userTooltipText:
      'In the current Organization, allows you to delete all Documents where you are selected as Reviewer, or Approver, or Responsible, or you are an Owner',
    name: 'delete-col1',
    type: 6,
    groupTypes: [4, 5, 7, 8, 9],
  },
  {
    label: 'Delete all related not approved Documents',
    tooltipText:
      'In the current Organization, the users will be able to delete only not yet approved Documents where they are selected as Reviewer, or Approver, or Responsible, or they are an Owner',
    userTooltipText:
      'In the current Organization, allows you to delete only not yet approved Documents where you are selected as Reviewer, or Approver, or Responsible, or you are an Owner',
    name: 'delete-col1',
    type: 7,
    groupTypes: [4, 5, 6, 8, 9],
  },
  {
    label: 'Delete all Documents where the user is an Owner',
    tooltipText:
      'In the current Organization, the users will be able to delete all Documents where they are an Owner',
    userTooltipText:
      'In the current Organization, allows you to delete all the Documents where you are an Owner',
    name: 'delete-col1',
    type: 8,
    groupTypes: [4, 5, 6, 7, 9],
  },
  {
    label: 'Delete all not approved Documents where the user is an Owner',
    tooltipText:
      'In the current Organization, the users will be able to delete only not yet approved Documents where they are an Owner',
    userTooltipText:
      'In the current Organization, allows you to delete only not yet approved Documents where you are an Owner',
    name: 'delete-col1',
    type: 9,
    groupTypes: [4, 5, 6, 7, 8],
  },
];

export const addOptionsCol1 = [
  {
    label: 'Add a new document',
    tooltipText:
      'The users will be able to add a new document in the current Organization',
    userTooltipText:
      'Allows you to add a new document in the current Organization',
    name: 'add-col1',
    type: 10,
    groupTypes: [],
  },
];

export const editOptionsCol2 = [
  {
    label: 'Edit all related not approved Documents',
    tooltipText:
      'In the current Organization, the users will be able to edit only not yet approved Documents where they are selected as Reviewer, or Approver, or Responsible, or they are an Owner',
    userTooltipText:
      'In the current Organization, allows you to edit only not yet approved Documents where you are selected as Reviewer, or Approver, or Responsible, or you are an Owner',
    name: 'edit-col2',
    type: 12,
    groupTypes: [],
    subOptions: [
      {
        label: 'Section General',
        type: 53,
      },
      {
        label: 'Section Quality Actions',
        type: 54,
      },
      {
        label: 'Section References',
        type: 55,
      },
    ],
  },
];

export const deleteOptionsCol2 = [
  {
    label:
      'Delete all approved Documents and Documents to which the user is related no matter the document status',
    tooltipText:
      'In the current Organization, the users will be able to delete approved Documents and Documents where they are selected as Reviewer, or Approver, or Responsible, or they are an Owner',
    userTooltipText:
      'In the current Organization, allows you to delete approved Documents and Documents where you are selected as Reviewer, or Approver, or Responsible, or you are an Owner',
    name: 'delete-col2',
    type: 13,
    groupTypes: [14],
  },
  {
    label: 'Delete all not approved Documents to which the user is related',
    tooltipText:
      'In the current Organization, the users will be able to delete only not yet approved Documents where they are selected as Reviewer, or Approver, or Responsible, or they are an Owner',
    userTooltipText:
      'In the current Organization, allows you to delete only not yet approved Documents where you are selected as Reviewer, or Approver, or Responsible, or you are an Owner',
    name: 'delete-col2',
    type: 14,
    groupTypes: [13],
  },
];

export const addOptionsCol2 = [
  {
    label: 'Add a new document',
    tooltipText:
      'The users will be able to add a new document in the current Organization',
    userTooltipText:
      'Allows you to add a new document in the current Organization',
    name: 'add-col2',
    type: 15,
    groupTypes: [],
  },
];

export const allTaskEditOptions = [
  {
    label: 'Edit all not completed actions',
    tooltipText:
      'The users will be able to edit only not yet completed actions in the current Organization',
    userTooltipText:
      'Allows you to edit only not yet completed actions in the current Organization',
    name: 'task-edit-col1',
    type: 17,
    groupTypes: [18],
  },
  {
    label:
      'Edit all not completed actions where the user is an Owner or an Assigner',
    tooltipText:
      'In the current Organization, the users will be able to edit only not yet completed actions where they are a Creator or an Assigner. A action Assigner can be selected by the action Creator, or Assigner can be the same person as a Creator',
    userTooltipText:
      'In the current Organization, allows you to edit only not yet completed actions, where you are an Owner or an Assigner',
    name: 'task-edit-col1',
    type: 18,
    groupTypes: [17],
  },
];

export const allTaskDeleteOptions = [
  {
    label: 'Delete all actions',
    tooltipText:
      'The users will be able to delete all the actions in the current Organization',
    userTooltipText:
      'Allows you to delete all actions in the current Organization',
    name: 'tasks-delete-col1',
    type: 19,
    groupTypes: [20],
  },
  {
    label:
      'Delete all not completed actions, where the user is an Owner or Assigner',
    tooltipText:
      'In the current Organization, the users will be able to delete only not yet completed actions where they are a Creator or an Assigner. A action Assigner can be selected by the action Creator, or Assigner can be the same person as a Creator',
    userTooltipText:
      'In the current Organization, allows you to delete only not yet completed actions, where you are an Owner or an Assigner',
    name: 'task-delete-col1',
    type: 20,
    groupTypes: [19],
  },
];

export const allTaskAddOptions = [
  {
    label: 'Add a new action',
    tooltipText:
      'The users will be able to add a new action in the current Organization',
    userTooltipText:
      'Allows you to add new actions in the current Organization',
    name: 'task-add-col1',
    type: 21,
    groupTypes: [],
  },
];

export const taskEditOption = [
  {
    label: 'Edit not completed actions where the user is an Owner or Assigner',
    tooltipText:
      'In the current Organization, the users will be able to edit only not yet completed actions where they are a Creator, or an Assigner. A action Assigner can be selected by the action Creator, or Assigner can be the same person as a Creator',
    userTooltipText:
      'In the current Organization, allows you to edit only not yet completed actions, where you are an Owner, or an Assigner',
    name: 'task-edit-col2',
    type: 23,
    groupTypes: [],
  },
];

export const taskDeleteOption = [
  {
    label:
      'Delete not completed actions, where the user is an Owner or Assigner',
    tooltipText:
      'In the current Organization, the users will be able to delete only not yet completed actions where they are a Creator or an Assigner. A action Assigner can be selected by the action Creator, or Assigner can be the same person as a Creator',
    userTooltipText:
      'In the current Organization, allows you to delete only not yet completed actions, where you are an Owner, or an Assigner',
    name: 'task-delete-col2',
    type: 24,
    groupTypes: [],
  },
];

export const taskAddOption = [
  {
    label: 'Add a new action',
    tooltipText:
      'The users will be able to add a new action in the current Organization',
    userTooltipText:
      'Allows you to add new actions in the current Organization',
    name: 'task-add-col2',
    type: 25,
    groupTypes: [],
  },
];

export const allTrainingEditOptions = [
  {
    label: 'Edit all training records',
    tooltipText:
      'In the current Organization, the users will be able to edit all training records',
    userTooltipText:
      'Allows you to edit all the training records in the current Organization',
    name: 'all-training-edit-col',
    type: 27,
    groupTypes: [],
  },
];

export const allTrainingDeleteOptions = [
  {
    label: 'Delete all training records',
    tooltipText:
      'In the current Organization, the users will be able to delete all training records',
    userTooltipText:
      'Allows you to delete all the training records in the current Organization',
    name: 'all-training-delete-col',
    type: 28,
    groupTypes: [],
  },
];

export const allTrainingAddOptions = [
  {
    label: 'Add a new training record',
    tooltipText:
      'The users will be able to add a new training record in the current Organization',
    userTooltipText:
      'Allows you to add a new training record in the current Organization',
    name: 'all-training-add-col',
    type: 29,
    groupTypes: [],
  },
];

export const trainingEditOptions = [
  {
    label: 'Edit training records, where the user is a Creator or an Assigner',
    tooltipText:
      'In the current Organization, the users will be able to edit training records where they are a Creator or an Assigner. A training record Assigner can be selected by the training record Creator, or an Assigner can be the same person as a Creator',
    userTooltipText:
      'In the current Organization, allows you to edit only training records, where you are a Creator, or an Assigner',
    name: 'training-edit-col',
    type: 31,
    groupTypes: [],
  },
];

export const trainingDeleteOptions = [
  {
    label:
      'Delete training records, where the user is a Creator or an Assigner',
    tooltipText:
      'In the current Organization, the users will be able to delete training records where they are a Creator or an Assigner. A training record Assigner can be selected by the training record Creator, or an Assigner can be the same person as a Creator',
    userTooltipText:
      'In the current Organization, allows you to delete only training records, where you are a Creator, or an Assigner',
    name: 'training-delete-col',
    type: 32,
    groupTypes: [],
  },
];

export const trainingAddOptions = [
  {
    label: 'Add a new training record',
    tooltipText:
      'The users will be able to add a new training record in the current Organization',
    userTooltipText:
      'Allows you to add a new training record in the current Organization',
    name: 'training-add-col',
    type: 33,
    groupTypes: [],
  },
];

export const allSectionOptions = [
  {
    label: 'Section General',
    type: 34,
  },
  {
    label: 'Section Quality Actions',
    type: 35,
  },
  {
    label: 'Section References',
    type: 36,
  },
  {
    label: 'Section Review',
    type: 37,
  },
  {
    label: 'Section Approval',
    type: 38,
  },
];

export const sectionOptions = [
  {
    label: 'Section General',
    type: 48,
  },
  {
    label: 'Section Quality Actions',
    type: 49,
  },
  {
    label: 'Section References',
    type: 50,
  },
  {
    label: 'Section Review',
    type: 51,
  },
  {
    label: 'Section Approval',
    type: 52,
  },
];
