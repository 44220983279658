import React from 'react';

const MessageResetSuccess = ({ message }) => (
  <div className="d-flex align-items-center">
    <i className="bx bx-check success-icon"></i>
    <p className="ml-1 m-0">{message}</p>
  </div>
);

export default MessageResetSuccess;
