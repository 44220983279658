export const documentRevisionModel = {
  typeId: null,
  submitterId: null,
  revision: '',
  name: '',
  fileId: '',
  number: null,
  revisionDescription: '',
  requiresTraining: false,
  type: '',
  typeDescription: '',
  id: null,
  setAsFinal: false,
  reviewerIds: [],
  approverIds: [],
  references: [],
  extraFileIds: [],
  revisionName: '',
  tagIds: [],
};
