import {
  SET_USER_PASSWORD,
  IS_VALID_SET_TOKEN,
  CONFIRM_EMAIL_PASSWORD,
} from './actionTypes';

const initialState = {};

const setPassword = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_PASSWORD:
      state = { ...state };
      break;
    case IS_VALID_SET_TOKEN:
      state = { ...state };
      break;
    case CONFIRM_EMAIL_PASSWORD:
      state = { ...state };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default setPassword;
