import React from 'react';

import CardTitle from '../../shared/CardTitle';
import HelpImage from '../shared/HelpImage';

import generalImage from '../../../assets/images/help-screen/general/general-1.png';
import generalImage2 from '../../../assets/images/help-screen/general/general-2.png';
import generalImage3 from '../../../assets/images/help-screen/general/general-3.png';
import generalImage4 from '../../../assets/images/help-screen/general/general-4.png';
import generalImage5 from '../../../assets/images/help-screen/general/general-5.png';

const HelpGeneral = () => {
  return (
    <div>
      <CardTitle title="General Features" />
      <p>
        Tables throughout Q Suite can be managed by users. The options in
        “Manage Columns” allows the user to select columns which the user
        prefers to view currently in the table.
      </p>
      <HelpImage src={generalImage} />
      <p>
        Table columns can be resized by hovering on the borders of columns. The
        table will default back to the original dimensions and columns when the
        page is refreshed.
      </p>
      <HelpImage src={generalImage2} />
      <p>
        In every table data that is too long to fit in the cell will be cut to
        display the first portion. Users are able to see the entire text by
        hovering on the ellipsis.
      </p>
      <HelpImage src={generalImage3} />
      <p>
        Data in tables can be sorted using the arrow buttons in column headers
        where this option is available. Some columns do not have sorting
        ability.
      </p>
      <HelpImage src={generalImage4} />
      <p>
        Users can click&drag a column’s header and move it to change the
        column’s position. Users can arrange an order of the columns for viewing
        the tables. Please, note, this option is only available if you have your
        table content at the top-scroll position (i.e., you do not scroll down).
      </p>
      <HelpImage src={generalImage5} />
    </div>
  );
};

export default HelpGeneral;
