import React from 'react';

import AttachedList from '../../shared/AttachedList';
import DropZoneFile from '../../shared/DropZoneFile';

const DocumentAttached = ({ text, file, onDeleteFile, handleUploadFile }) => {
  return (
    <div className="general-attached">
      <div className="info-column">
        <p className="info-column__title">{text}</p>
        {file ? (
          <AttachedList files={[file]} onDeleteFile={onDeleteFile} />
        ) : (
          <DropZoneFile isSingle={true} handleUploadFile={handleUploadFile} />
        )}
      </div>
    </div>
  );
};

export default DocumentAttached;
