import {
  MIN_LENGTH,
  MIN_LENGTH_ERROR,
  MAX_LENGTH,
  MAX_LENGTH_ERROR,
  NUMBER_VALUDATION,
  NUMBER_VALIDATION_ERROR,
} from './form';

export const validatePasswordLength = {
  minLength: { value: MIN_LENGTH, errorMessage: MIN_LENGTH_ERROR },
  maxLength: { value: MAX_LENGTH, errorMessage: MAX_LENGTH_ERROR },
};

export const validateNumber = {
  pattern: { value: NUMBER_VALUDATION, errorMessage: NUMBER_VALIDATION_ERROR },
};

export const validateEmptyText = {
  trimText: (value) => value.trim().length > 0,
};
