export const breadCrumbItemsProfile = [
  {
    link: `/profile`,
    title: 'Profile',
  },
  {
    link: '',
    title: 'Edit Profile',
  },
];
