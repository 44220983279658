/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Switch, Router, Route } from 'react-router-dom';
import history from './components/history/history';
import { connect } from 'react-redux';

// Import Routes all
import { userRoutes, authRoutes, organizationRoutes } from './routes/allRoutes';

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';
import Organizationmiddleware from './routes/middleware/Organizationmiddleware';

// layouts Format
import VerticalLayout from './components/Layouts/Vertical';
import NonAuthLayout from './components/Layouts/NonAuth';
import UserLayout from './components/Layouts/User';
import { TOAST_AUTO_CLOSE } from './constants/helper';

import ViewDocumentProvider from './contexts/ViewDocument';
// Import scss
import './assets/scss/theme.scss';
import { getDataAPI } from './utils/get-data-api-helper';

const App = (props) => {
  const { imageSuccress } = props;
  const [organizationImage, setOrganizationImage] = useState(null);
  const [routeId, setRouteId] = useState(null);

  const NonAuthmiddleware = ({ component: Component }) => (
    <Route render={(props) => <Component {...props} />} />
  );

  const getDataImage = async () => {
    const logo = await getDataAPI(routeId);
    setOrganizationImage(logo);
  };

  const { logo: organizationImg, name: organizationName } =
    organizationImage || {};

  useEffect(() => {
    if (imageSuccress) {
      getDataImage();
    }
  }, [imageSuccress]);

  useEffect(() => {
    if (!routeId) return;

    getDataImage();
  }, [routeId]);

  return (
    <React.Fragment>
      <ViewDocumentProvider>
        <Router history={history}>
          <Switch>
            {authRoutes.map((route, idx) => (
              <NonAuthmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
              />
            ))}
            {organizationRoutes.map((route, idx) => (
              <Organizationmiddleware
                path={route.path}
                exact={route.exact}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                setRouteId={setRouteId}
                organizationImage={organizationImg}
                organizationName={organizationName}
              />
            ))}
            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={UserLayout}
                component={route.component}
                key={idx}
                organizationImage={organizationImg}
              />
            ))}
          </Switch>
        </Router>
        <ToastContainer
          autoClose={TOAST_AUTO_CLOSE}
          closeButton={false}
          hideProgressBar={true}
          transition={Zoom}
          limit={1}
        />
      </ViewDocumentProvider>
    </React.Fragment>
  );
};

const mapStateToProps = ({ Admins: { imageSuccress } }) => ({ imageSuccress });
export default connect(mapStateToProps)(App);
