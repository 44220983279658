import { API } from '../backend-helper';

const TRAINING_RECORDS = 'training-records';
const DETAILS = 'details';
const FILE = 'file';
const COMPLETION_PROGRESS = 'completion-progress';
const PASS = 'pass';
const ALL = 'all';

export const getTrainingRecordsDetails = (id, organizationId) => {
  return API.get(`/${TRAINING_RECORDS}/${id}/${DETAILS}`, {
    params: { organizationId },
  });
};

export const getTrainingRecordsFile = (id, fileId, organizationId) => {
  return API.get(`/${TRAINING_RECORDS}/${id}/${FILE}/${fileId}`, {
    params: { organizationId },
  });
};

export const changeTrainingRecord = (id, body) => {
  return API.put(`/${TRAINING_RECORDS}/${id}/${DETAILS}`, body);
};

export const createTrainingRecords = (body) => {
  return API.post(`/${TRAINING_RECORDS}`, body);
};

export const deleteTrainingRecords = (body) => {
  return API.delete(`/${TRAINING_RECORDS}`, { params: body });
};

export const getCompletionProgress = (id, organizationId) => {
  return API.get(`/${TRAINING_RECORDS}/${id}/${COMPLETION_PROGRESS}`, {
    params: { organizationId },
  });
};

export const passTrainingRecord = (id, body) => {
  return API.put(`/${TRAINING_RECORDS}/${id}/${PASS}`, body);
};

export const getAllTrainingRecords = (params) => {
  return API.get(`/${TRAINING_RECORDS}/${ALL}`, { params });
};
