/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
} from 'reactstrap';
import { debounce } from 'lodash';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/shared/Breadcrumbs/Breadcrumbs';
import { addUserGroupUsers } from '../../../utils/backend-helper';
import DisplayColumnsModal from '../../../modals/DisplayColumns';
import UserGroupAddNew from '../../../modals/UserGroupAdd';
import TableHead from '../../../components/tables/TableHead';
import TableBody from '../../../components/tables/TableBody';
import { SUCCESS_MESSAGE_ADD_USER } from '../../../constants/messages';
import {
  LIMIT,
  INITIAL_OFFSET,
  INITIAL_PAGE,
  TOTAL_ITEMS,
  PAGE_COUNT,
} from '../../../constants/table';
import MessageResetSuccess from '../../../components/ToastSuccess';
import UserGroupSetting from '../UserGroupSetting';
import { columnsHead, columnsBody } from './Table';
import { getFileName } from '../../../utils/get-file-name';
import { useDocumentExport } from '../../../custom-hooks/useDocumentExport';
import { getExportParams } from '../../../utils/get-export-params';
import { CSV, DEFAULT_SORT } from '../../../constants/helper';
import { orderTable } from '../../../utils/order-table';
import UserAddTraining from '../../../modals/UserAddTraining';
import { getColumnsFilter } from '../../../utils/get-columns-search';
import { mergetParams } from '../../../utils/merge-table-params';
import { getDataTableColumns } from '../../../utils/backend-helper';
import { addClientUser } from '../../../utils/api/user-groups-api';
import { useCheckboxTable } from '../../../custom-hooks/useCheckboxTable';
import TableFooter from '../../../components/tables/TableFooter';
import TableHeader from '../../../components/tables/TableHeader';
import TablePaginationText from '../../../components/tables/PaginationText';
import TableSpinner from '../../../components/tables/Spinner';
import { dragReorder } from '../../../utils/dragable-helpers/dragable-reoder';
import {
  getChangedResizeColumns,
  getEndResizeColumns,
} from '../../../utils/tables-helper/resizable';
import { useTableWidth } from '../../../custom-hooks/useTableWidth';
import { deleteRow } from '../../../utils/tables-helper/deleteRow';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const OrganizationUserGroupUsers = ({ user, organizationName }) => {
  const { id: organizationId, groupId } = useRouteMatch().params;

  const [activeTab, setActiveTab] = useState('1');
  const onError = useErrorHandler();

  const toggleTabs = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const [userGroupUsers, setUserGroupUsers] = useState(null);

  const [columnsVisibility, updateColumnsVisibility] = useState({
    activeted: {
      label: 'Options',
      isVisible: true,
    },
    isactivated: {
      label: 'Activated',
      isVisible: true,
      accessor: 'IsActivated',
      sort: true,
    },
    email: {
      label: 'Email',
      isVisible: true,
      accessor: 'Email',
      sort: true,
    },
    firstName: {
      label: 'First Name',
      isVisible: true,
      accessor: 'FirstName',
      sort: true,
    },
    lastName: {
      label: 'Last Name',
      isVisible: true,
      accessor: 'LastName',
      sort: true,
    },
    status: {
      label: 'Status',
      isVisible: true,
      accessor: 'Status',
      sort: true,
    },
  });

  const [isLoading, updateIsLoading] = useState(false);

  const [pagination, setPagination] = useState({
    data: [],
    limit: LIMIT,
    offset: INITIAL_OFFSET,
    pageNumber: INITIAL_PAGE,
    pageCount: PAGE_COUNT,
    totalItems: TOTAL_ITEMS,
    columns: getColumnsFilter(columnsVisibility),
    search: '',
    orderBy: '',
    orderType: '',
    groupName: '',
    assignedToTrainings: false,
  });

  const getGroupsUsersList = useCallback(
    (organizationId, limit, offset, search, orderBy, orderType, columns) => {
      const params = mergetParams({
        organizationId,
        limit,
        offset,
        search,
        orderBy,
        orderType,
      });
      return getDataTableColumns(
        `user-groups/${groupId}/users`,
        params,
        columns,
      );
    },
    [],
  );

  const updatingTable = async (success) => {
    try {
      if (!success) return;
      updateIsLoading(true);
      const { limit, offset, search, orderBy, orderType, columns } = pagination;
      const data = await getGroupsUsersList(
        organizationId,
        limit,
        offset,
        search,
        orderBy,
        orderType,
        columns,
      );
      setUserGroupUsers(data);
    } catch (error) {
      onError(error);
    } finally {
      updateIsLoading(false);
    }
  };

  useEffect(() => {
    const getItems = async (
      organizationId,
      limit,
      offset,
      search,
      orderBy,
      orderType,
      columns,
    ) => {
      try {
        updateIsLoading(true);
        const data = await getGroupsUsersList(
          organizationId,
          limit,
          offset,
          search,
          orderBy,
          orderType,
          columns,
        );
        setUserGroupUsers(data);
      } catch (error) {
        onError(error);
      } finally {
        updateIsLoading(false);
      }
    };

    getItems(
      organizationId,
      pagination.limit,
      pagination.offset,
      pagination.search,
      pagination.orderBy,
      pagination.orderType,
      pagination.columns,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.limit,
    pagination.search,
    pagination.offset,
    pagination.orderBy,
    pagination.orderType,
    getGroupsUsersList,
  ]);

  const debounceHandleUpdate = useMemo(() => {
    return debounce((input) => {
      setPagination((prev) => ({
        ...prev,
        pageNumber: INITIAL_PAGE,
        offset: INITIAL_OFFSET,
        search: input,
      }));
    }, 1000);
  }, []);

  const handleSearch = (value) => {
    debounceHandleUpdate(value);
  };

  const handleOrder = (key) => {
    setPagination((prev) => orderTable(key, prev));
  };

  const handleChangeLimit = (value) => {
    setPagination((prev) => ({
      ...prev,
      pageNumber: INITIAL_PAGE,
      offset: INITIAL_OFFSET,
      limit: value,
    }));
  };

  const handlePageClick = ({ selected }) => {
    const offset = Math.ceil(selected * pagination.limit);
    setPagination((prev) => ({
      ...prev,
      pageNumber: selected,
      offset,
    }));
  };

  const [displayModal, setDisplayModal] = useState({
    isOpen: false,
  });

  const handleToggleColumn = () => {
    setDisplayModal((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);

  const [addUserModal, updateAddUserModal] = useState({
    isOpen: false,
  });

  const handleAddNewModal = (assignedToTrainings) => {
    if (assignedToTrainings) {
      updateAddUserModal((prev) => ({ ...prev, isOpen: true }));
    } else {
      setIsOpenAddModal(true);
    }
  };

  useEffect(() => {
    if (!userGroupUsers) return;
    const {
      data: {
        assignedToTrainings,
        users,
        group: { name },
        pagination: { totalCount },
      },
    } = userGroupUsers;
    setPagination((prev) => ({
      ...prev,
      data: users,
      pageCount: Math.ceil(totalCount / prev.limit),
      totalItems: totalCount,
      groupName: name,
      assignedToTrainings,
    }));
  }, [userGroupUsers]);

  const [columnsHidden, updateColumnsHidden] = useState({
    firstName: true,
    lastName: true,
    email: true,
    status: true,
    isactivated: true,
    activeted: true,
  });

  useEffect(() => {
    const getColumns = getColumnsFilter(columnsVisibility);

    setPagination((prev) => ({
      ...prev,
      columns: getColumns,
    }));
  }, [columnsVisibility]);

  const handleSaveColumns = () => {
    updateColumnsHidden((prev) => {
      return Object.keys(prev).reduce((acc, keys) => {
        acc[keys] = columnsVisibility[keys].isVisible;
        return acc;
      }, {});
    });
    setDisplayModal((prev) => ({ ...prev, isOpen: false }));
  };

  const handleUpdateColumnsVisible = (key) => {
    updateColumnsVisibility((prev) => ({
      ...prev,
      [key]: { ...prev[key], isVisible: !prev[key].isVisible },
    }));
  };

  const handleSelectAll = (toggle) => {
    const newColumnsVisible = Object.keys(columnsVisibility).reduce(
      (acc, key) => {
        acc[key] = { ...columnsVisibility[key], isVisible: toggle };
        return acc;
      },
      {},
    );
    updateColumnsVisibility(newColumnsVisible);
  };

  const breadCrumbItems = [
    {
      link: `/organizations/${organizationId}/user-groups`,
      title: 'User Groups',
    },
    {
      link: '',
      title: pagination.groupName,
    },
  ];

  const {
    checkedHead,
    updateCheckedHead,
    checkAllDocuments,
    handleUpdateChecked,
  } = useCheckboxTable(pagination, setPagination);

  const { downloadFile } = useDocumentExport();

  const handleDownloadCSV = (
    organizationId,
    groupId,
    pagination,
    dataOfColumns,
  ) => {
    let { search, orderBy, orderType, data } = pagination;

    const isCheckedList = data.some(({ isChecked }) => isChecked);
    const isFilter = search || orderType || orderBy || isCheckedList;

    const fileName = getFileName(
      organizationName,
      pagination.groupName,
      user,
      CSV,
      isFilter,
    );

    if (!orderBy) {
      orderBy = DEFAULT_SORT;
    }

    const body = getExportParams(
      data,
      dataOfColumns,
      { search, orderBy, orderType },
      organizationId,
      groupId,
    );
    downloadFile('/export/group-users', body, fileName);
  };

  const [columnsMain, updateColumnsMain] = useState(columnsHead);
  const [columns, updateColumns] = useState(columnsBody);

  const tableRef = useTableWidth(updateColumnsMain);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            name={`“${pagination.groupName}” User Groups`}
            breadCrumbItems={breadCrumbItems}
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggleTabs('1')}
                      >
                        List of Users
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggleTabs('2')}
                      >
                        User Group Settings
                      </NavLink>
                    </NavItem>
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <TabContent activeTab={activeTab} className="p-0">
            <TabPane tabId="1" id="list-of-users">
              <Row className="d-flex justify-content-center">
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableHeader
                        title="List of Users"
                        handleAddNew={() =>
                          handleAddNewModal(pagination.assignedToTrainings)
                        }
                        downloadCSV={() =>
                          handleDownloadCSV(
                            organizationId,
                            groupId,
                            pagination,
                            columnsVisibility,
                          )
                        }
                        manageColumns={handleToggleColumn}
                        handleSearch={handleSearch}
                      />
                      <div ref={tableRef} className="table-wrapper">
                        <Table className="table-custom table table-centered table-nowrap table-document">
                          <TableHead
                            columnsHidden={columnsHidden}
                            handleOrder={handleOrder}
                            checkAllDocuments={checkAllDocuments}
                            checkedHead={checkedHead}
                            updateCheckedHead={checkedHead}
                            pagination={pagination}
                            columnsHead={columnsMain}
                            columns={columns}
                            handleChangeColumns={(
                              columns,
                              columnsHead,
                              startIndex,
                              endIndex,
                            ) => {
                              updateColumns(
                                dragReorder(columns, startIndex, endIndex),
                              );
                              updateColumnsMain(
                                dragReorder(columnsHead, startIndex, endIndex),
                              );
                            }}
                            onResizeColumns={(columnIndex, changedWidth) => {
                              updateColumnsMain((columns) =>
                                getChangedResizeColumns(
                                  columns,
                                  columnIndex,
                                  changedWidth,
                                ),
                              );
                            }}
                            onResizeEnd={(columnIndex) => {
                              updateColumnsMain((columns) =>
                                getEndResizeColumns(columns, columnIndex),
                              );
                            }}
                          />
                          <TableBody
                            columnsHidden={columnsHidden}
                            pagination={pagination}
                            setPagination={setPagination}
                            columnsBody={columns}
                            updatingTable={updatingTable}
                            onDeleteRow={(deletedId) => {
                              setPagination((pagination) =>
                                deleteRow(pagination, deletedId),
                              );
                            }}
                            organizationId={organizationId}
                            updateCheckedHead={updateCheckedHead}
                            handleUpdateChecked={handleUpdateChecked}
                          />
                        </Table>
                      </div>
                      {!!pagination.data.length && (
                        <TableFooter
                          limit={pagination.limit}
                          handleChangeLimit={handleChangeLimit}
                          pageCount={pagination.pageCount}
                          pageNumber={pagination.pageNumber}
                          handlePageClick={handlePageClick}
                        />
                      )}
                      {!(pagination.data.length || isLoading) && (
                        <TablePaginationText text="There are no Users to display" />
                      )}
                      {isLoading && <TableSpinner />}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2" id="user-group-settings">
              <UserGroupSetting organizationId={organizationId} id={groupId} />
            </TabPane>
          </TabContent>
        </Container>
      </div>
      <DisplayColumnsModal
        columnsVisibility={columnsVisibility}
        isOpen={displayModal.isOpen}
        handleUpdateColumnsVisible={handleUpdateColumnsVisible}
        handleSelectAll={handleSelectAll}
        onCancel={() => setDisplayModal((prev) => ({ ...prev, isOpen: false }))}
        handleSaveColumns={handleSaveColumns}
      />
      <UserGroupAddNew
        organizationId={organizationId}
        groupId={groupId}
        isOpen={isOpenAddModal}
        onSubmit={(event, { groupId, organizationId, users }) => {
          addUserGroupUsers(groupId, organizationId, users).then(() => {
            updatingTable(true);
            toast.success(
              <MessageResetSuccess message={SUCCESS_MESSAGE_ADD_USER} />,
            );
            setIsOpenAddModal(false);
          });
        }}
        onCancel={() => setIsOpenAddModal(false)}
      />
      <UserAddTraining
        organizationId={organizationId}
        groupId={groupId}
        isOpen={addUserModal.isOpen}
        onCancel={() =>
          updateAddUserModal((prev) => ({ ...prev, isOpen: false }))
        }
        onSubmit={(_, values, callback) => {
          const { groupId, ...body } = values;
          addClientUser(groupId, body)
            .then(() => {
              updatingTable(true);
              toast.success(
                <MessageResetSuccess message={SUCCESS_MESSAGE_ADD_USER} />,
              );
              updateAddUserModal((prev) => ({ ...prev, isOpen: false }));
            })
            .finally(() => callback());
        }}
      />
    </>
  );
};

export default OrganizationUserGroupUsers;
