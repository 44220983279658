import React, { useState } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import Button from '../../components/Button';

const OrganizationDeletingModal = ({
  onSubmit,
  onCancel,
  isOpen,
  values,
  model,
}) => {
  const [checked, setChecked] = useState(false);

  const handleChangeChecked = () => {
    setChecked((checked) => !checked);
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Delete Organization</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
        <div className="modal-body">
          <p className="text-modal">
            Are you sure you want to delete the Organization{' '}
            <b>{values.organizationName || ''}?</b> All data related to this
            Organization will be permanently deleted.
          </p>
          <div className="custom-control custom-checkbox">
            <input
              checked={checked}
              type="checkbox"
              onChange={handleChangeChecked}
              className="custom-control-input"
              id="customControlInline"
            />
            <label
              className="custom-control-label"
              htmlFor="customControlInline"
            >
              I acknowledge
            </label>
          </div>
        </div>
        <div className="modal-footer">
          <Row>
            <Col className="text-right pr-0">
              <Button
                type="submit"
                color="btn btn-danger w-md waves-effect waves-light delete-btn mr-3"
                disabled={!checked}
              >
                Delete
              </Button>
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light"
                handler={onCancel}
              >
                Cancel
              </Button>
            </Col>
          </Row>
          <AvField
            name="id"
            type="hidden"
            value={model.organizationId}
          />
        </div>        
      </AvForm>
    </Modal>
  );
};

export default OrganizationDeletingModal;
