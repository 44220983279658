import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';

const TableFilterMenu = ({ documentTypes, handleDelete, handleClearAll }) => {
  const isDocumentChecked = useMemo(() => {
    return (
      documentTypes?.length &&
      documentTypes.some((document) => document?.isChecked)
    );
  }, [documentTypes]);

  if (isDocumentChecked) {
    return (
      <Row className="mb-3 mt-3">
        <Col className="d-flex justify-content-between align-items-center selected-delete">
          <div className="filter-menu-container">
            {documentTypes.map(({ id, name, isChecked }) => {
              if (isChecked) {
                return (
                  <div key={id} className="filter-item">
                    <span>{name}</span>
                    <i className="bx bx-x" onClick={() => handleDelete(id)}></i>
                  </div>
                );
              }
              return null;
            })}
          </div>
          <div className="selected-delete__action" onClick={handleClearAll}>
            Clear all
          </div>
        </Col>
      </Row>
    );
  }

  return null;
};

export default TableFilterMenu;
