export const insertSetting = (collection, data, subOptions) => {
  const configSubOptions = subOptions
    ? subOptions.map(({ type }) => ({ type, isSwitchedOn: true }))
    : [];

  const cutCollection = collection.filter(({ type }) => {
    return !configSubOptions.some(({ type: typeSub }) => type === typeSub);
  });

  const indexOfSetting = cutCollection.findIndex(
    (setting) => setting.type === data.type,
  );

  if (indexOfSetting === -1) {
    return [...cutCollection, data, ...configSubOptions];
  }

  return [
    ...cutCollection.slice(0, indexOfSetting),
    data,
    ...cutCollection.slice(indexOfSetting + 1, cutCollection.length),
    ...configSubOptions,
  ];
};
