export const UserPermission = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  },
  userGroups: [],
  settings: [],
};
