export const filterOrderParams = (search, orderBy, orderType) => {
  const orderParams = {};
  if (orderBy) {
    orderParams['Order.Key'] = orderBy;
  }
  if (orderType) {
    orderParams['Order.Direction'] = orderType;
  }
  if (search) {
    orderParams['search'] = search;
  }

  return orderParams;
};

export const newFilterOrderParams = ({ orderBy, orderType, search }) => {
  const orderParams = {};
  if (orderBy) {
    orderParams['Order.Key'] = orderBy;
  }
  if (orderType) {
    orderParams['Order.Direction'] = orderType;
  }
  if (search) {
    orderParams['search'] = search;
  }

  return orderParams;
};
