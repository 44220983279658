export const selectAllColumns = (columns, toggle) => {
  return Object.keys(columns).reduce((acc, key) => {
    acc[key] = { ...columns[key], isVisible: toggle };
    return acc;
  });
};

export const selectAll = (columns, toggle) => {
  return Object.entries(columns).reduce((acc, [key, value]) => {
    acc[key] = { ...columns[key], isVisible: toggle };
    return acc;
  }, {});
};
