import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import classnames from 'classnames';

import { uploadFile } from '../../../utils/backend-helper';
import { validationDocumentFile } from '../../../utils/validation-cropper';

const DropZoneFile = ({ handleUploadFile, isSingle }) => {
  const uploadNewFile = useCallback(
    (file) => {
      try {
        validationDocumentFile(file);
      } catch ({ message }) {
        toast.error(message);
        return;
      }

      const body = new FormData();
      body.append('file', file);

      uploadFile(body, file).then(({ data }) => handleUploadFile(data));
    },
    [handleUploadFile],
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!acceptedFiles.length) return;
      for (let i = 0; i < acceptedFiles.length; i++) {
        uploadNewFile(acceptedFiles[i]);
      }
    },
    [uploadNewFile],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className={classnames('drop-zone-file', { 'drag-active': isDragActive })}
    >
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="upload-container">
          <i className="bx bxs-cloud-upload cloud"></i>
          <p className="file-text">
            Drop file{isSingle ? '' : 's'} here or сlick to upload
          </p>
        </div>
      </div>
    </div>
  );
};

export default DropZoneFile;
