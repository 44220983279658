import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  CardBody,
  Col,
  Row,
  Container,
  Card,
  TabContent,
  TabPane,
} from 'reactstrap';
import Breadcrumbs from '../../../../components/shared/Breadcrumbs/Breadcrumbs';
import {
  ACCESS_USER_TAB,
  DOCUMENTS_USER_TAB,
  DOCUMENT_USER_TAB,
  TASKS_USER_TAB,
  TRAINING_USER_TAB,
} from '../../../../constants/helper';

import { getClientUserSettings } from '../../../../utils/api/client-users-api';
import AccessPermission from './View';
import UserSettingNavigation from './UserNavigation';
import { UserPermission } from '../../../../models/user-permission/user-permission';
import DocumentSettingTab from '../../UserGroupSetting/DocumentSetting';
import TaskSettingTab from '../../UserGroupSetting/TaskSetting';
import TrainingSettingTab from '../../UserGroupSetting/TrainingSetting';
import { User } from '../../../../constants/role';
import OrganizationUserSetting from '../../UserGroupSetting/UserSetting';

const UserAccessPermission = ({ user: currentUser }) => {
  const { id: organizationId, userId } = useRouteMatch().params;

  const [userSettings, updateUserSettings] = useState(UserPermission);

  useEffect(() => {
    getClientUserSettings(userId, { organizationId }).then(({ data }) =>
      updateUserSettings(data),
    );
  }, [organizationId, userId]);

  const [activeTab, updateActiveTab] = useState(ACCESS_USER_TAB);

  const { user, userGroups, settings } = userSettings;

  const redirectBack =
    currentUser.role === User
      ? `/profile`
      : `/organizations/${organizationId}/users`;

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs name="Access Permissions" />
        <Row>
          <Col xs="12" sm="4" xl="3">
            <UserSettingNavigation
              activeTab={activeTab}
              updateActiveTab={updateActiveTab}
              redirectBack={redirectBack}
            />
          </Col>
          <Col xs="12" sm="8" xl="9">
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab} className="p-0">
                  <TabPane tabId={ACCESS_USER_TAB}>
                    <AccessPermission
                      organizationId={organizationId}
                      currentUser={currentUser}
                      userGroups={userGroups}
                      user={user}
                    />
                  </TabPane>
                  <TabPane tabId={DOCUMENTS_USER_TAB}>
                    <DocumentSettingTab
                      settings={settings}
                      id={userId}
                      organizationId={organizationId}
                      updateSettings={() => {}}
                      isOnlyView={true}
                    />
                  </TabPane>
                  <TabPane tabId={TASKS_USER_TAB}>
                    <TaskSettingTab
                      settings={settings}
                      id={userId}
                      organizationId={organizationId}
                      updateSettings={() => {}}
                      isOnlyView={true}
                    />
                  </TabPane>
                  <TabPane tabId={TRAINING_USER_TAB}>
                    <TrainingSettingTab
                      settings={settings}
                      id={userId}
                      organizationId={organizationId}
                      updateSettings={() => {}}
                      isOnlyView={true}
                    />
                  </TabPane>
                  <TabPane tabId={DOCUMENT_USER_TAB}>
                    <OrganizationUserSetting
                      settings={settings}
                      updateSettings={() => {}}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserAccessPermission;
