import { API } from '../backend-helper';

const DOCUMENTS = 'documents';
const REVISION = 'revision';
const GENERAL = 'general';
const FILE = 'file';
const AVAILABLE = 'available';
const REFERENCES = 'references';

export const getDocumentInfo = (id, params) => {
  return API.get(`/${DOCUMENTS}/${id}`, { params });
};

export const createDocumentRevision = (id, body) => {
  return API.put(`/${DOCUMENTS}/${id}/${REVISION}`, body);
};

export const changeFlag = (id, body) => {
  return API.patch(`/${DOCUMENTS}/${id}/${REVISION}`, body);
};

export const getDocumentGeneral = (id, params) => {
  return API.get(`/${DOCUMENTS}/${id}/${GENERAL}`, { params });
};

export const removeDocumentRevision = (id, params) => {
  return API.delete(`/${DOCUMENTS}/${id}/${REVISION}`, { params });
};

export const downloadGeneralDocument = (id, fileId, body) => {
  return API.get(`${DOCUMENTS}/${id}/${FILE}/${fileId}`, { params: body });
};

export const getAvailableDocuments = (params) => {
  return API.get(`${DOCUMENTS}/${AVAILABLE}`, { params });
};

export const getReferences = (id, params) => {
  return API.get(`${DOCUMENTS}/${id}/${REFERENCES}`, { params });
};

export const editReferences = (id, body) => {
  return API.put(`${DOCUMENTS}/${id}/${REFERENCES}`, body);
};
