import React, { useCallback } from 'react';
import { Col, Row } from 'reactstrap';

import SingleSelect from '../../../selects/Single';
import CustomSwitch from '../../CustomSwitch';

import { configureHumanResources } from '../../../../utils/config-get-organization';
import { HUMAN_RESOURCE_RECORDS_URL } from '../../../../constants/url';
import { THIRD_SWITCH } from '../../../../constants/helper';
import { useRouteMatch } from 'react-router-dom';
import { getHumanResourcesAvailable } from '../../../../utils/api/human-resources-api';

const LinkHumanResources = ({
  selectValues,
  handleUpdate,
  handleUpdateLink,
}) => {
  const { id: organizationId } = useRouteMatch().params;

  const getHumanResourcesAPI = useCallback(
    async (_, search = '') => {
      const params = { organizationId, search };
      return getHumanResourcesAvailable(params).then(({ data }) => data);
    },
    [organizationId],
  );

  return (
    <Row className="mt-2 mb-2">
      <Col md="4" xl="2" className="d-flex align-items-center">
        <div className="link-swicher">
          <CustomSwitch
            name="task-switch"
            isChecked={selectValues[THIRD_SWITCH]}
            onChange={() => handleUpdate(THIRD_SWITCH)}
          />
          <p className="mb-0">HR Record</p>
        </div>
      </Col>
      <Col md="8" xl="10" className="mt-2 mt-md-0">
        <SingleSelect
          handleRequest={getHumanResourcesAPI}
          placeholder="Choose hr record"
          configOptions={configureHumanResources}
          url={HUMAN_RESOURCE_RECORDS_URL}
          isDisabled={!selectValues[THIRD_SWITCH]}
          handleChange={(values) =>
            handleUpdateLink(values, 'human-resources', THIRD_SWITCH)
          }
        />
      </Col>
    </Row>
  );
};

export default LinkHumanResources;
