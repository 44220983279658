import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import React from 'react';

import { ADMINS, CREATE_ADMIN, UPLOAD_IMAGE } from './actionTypes';

import {
  getAdminsSuccess,
  createAdminSuccess,
  uploadImageSuccess,
} from './actions';

import {
  getAdminsData,
  createNewAdmin,
  uploadNewImage,
  uploadLogoOrganization,
} from '../../../utils/backend-helper';

import { SUCCESS_MESSAGE_CREATE } from '../../../constants/messages';
import MessageResetSuccess from '../../../components/ToastSuccess';

function* getAdmins({
  payload: { id, limit, offset, search, orderBy, orderType },
}) {
  try {
    const response = yield call(
      getAdminsData,
      `/client-admins?organizationId=${id}`,
      { limit, offset, search, orderBy, orderType },
    );
    yield put(getAdminsSuccess(response));
  } catch (error) {}
}

function* createAdmin({ payload }) {
  try {
    const response = yield call(createNewAdmin, '/client-admins', payload);
    if (response) {
      yield put(createAdminSuccess());
      toast.success(<MessageResetSuccess message={SUCCESS_MESSAGE_CREATE} />);
    }
  } catch (error) {}
}

function* uploadImage({ payload: { body, id, history } }) {
  try {
    const response = yield call(uploadNewImage, '/upload/image', body);
    const {
      data: { id: imageId },
    } = response;

    const imgageResponse = yield call(
      uploadLogoOrganization,
      `/organizations/${id}/logo/${imageId}`,
      { id, imageId },
    );
    if (imgageResponse) {
      yield put(uploadImageSuccess());
    }
  } catch (error) {
    history.push(`/organizations/${id}/dashboard`);
  }
}

export function* watchOrganizations() {
  yield takeEvery(ADMINS, getAdmins);
}

export function* watchCreateAdmin() {
  yield takeEvery(CREATE_ADMIN, createAdmin);
}

export function* watchUploadImage() {
  yield takeEvery(UPLOAD_IMAGE, uploadImage);
}

function* adminsSaga() {
  yield all([
    fork(watchOrganizations),
    fork(watchCreateAdmin),
    fork(watchUploadImage),
  ]);
}

export default adminsSaga;
