import React, { useState, useEffect, useMemo } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import {
  NOTIFICATION_LIMIT,
  NOTIFICATION_OFFSET,
} from '../../../../constants/notification';
import {
  getSystemCountNotification,
  getSystemNotificationList,
} from '../../../../utils/api/notification-api';
import { closeNotification } from '../../../../store/actions';
import NotificationDropdownItem from '../notification-dropdown-item/notification-dropdown-item';

const NotificationDropdown = ({ successNotification, closeNotification }) => {
  const organizationId = useRouteMatch().params.id;

  const notificationsParams = useMemo(
    () => ({
      organizationId: organizationId,
      limit: NOTIFICATION_LIMIT,
      offset: NOTIFICATION_OFFSET,
    }),
    [organizationId],
  );

  const [notificationList, updateNotificationList] = useState([]);

  useEffect(() => {
    getSystemNotificationList(notificationsParams).then(({ data }) =>
      updateNotificationList(data),
    );
  }, [notificationsParams]);

  const [notificationCount, updateNotificationCount] = useState(0);

  useEffect(() => {
    getSystemCountNotification({ organizationId }).then(({ data }) =>
      updateNotificationCount(data.count),
    );
  }, [organizationId]);

  useEffect(() => {
    if (successNotification) {
      getSystemCountNotification({ organizationId }).then(({ data }) => {
        updateNotificationCount(data.count);
        closeNotification();
      });
    }
  }, [successNotification, organizationId, closeNotification]);

  const [menu, setMenu] = useState(false);

  const history = useHistory();

  const handleRedirectTask = (organizationId, taskId) => {
    history.push(`/organizations/${organizationId}/tasks/${taskId}`);
  };

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => notificationList?.length && setMenu(!menu)}
      className="dropdown d-inline-block profile-dropdown-menu"
      tag="li"
    >
      <DropdownToggle
        className="btn header-item noti-icon waves-effect"
        tag="button"
        id="page-header-notifications-dropdown"
      >
        <i className="bx bxs-bell"></i>
        {!!notificationCount && (
          <span className="badge badge-success badge-pill">
            {notificationCount}
          </span>
        )}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0">
        <SimpleBar className="p-3">
          <h5>Notifications</h5>
          {notificationList.map(({ createdAt, task }, i, arr) => {
            const { id: taskId } = task;
            return (
              <React.Fragment key={taskId}>
                <NotificationDropdownItem
                  task={task}
                  createdAt={createdAt}
                  onRedirectTask={() =>
                    handleRedirectTask(organizationId, taskId)
                  }
                />
                {arr.length - 1 !== i && (
                  <div className="dropdown-divider"></div>
                )}
              </React.Fragment>
            );
          })}
        </SimpleBar>
        <div className="p-2 border-top">
          <Link
            className={classnames(
              'btn btn-sm btn-link font-size-14 btn-block text-center',
              { 'disabled-link': !notificationList.length },
            )}
            to={`/organizations/${organizationId}/notifications`}
          >
            Show all
          </Link>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export const mapStatetoProps = ({ Notification: { successNotification } }) => ({
  successNotification,
});

export default connect(mapStatetoProps, { closeNotification })(
  NotificationDropdown,
);
