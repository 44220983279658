import React from 'react';

import ChangeEmailProfile from '../../../pages/Authentication/Profile/ChangeEmail';
import ProfileCardTitle from '../shared/CardTitle/CardTitle';

const ProfileEmail = ({ handleUpdateUser }) => {
  return (
    <div>
      <ProfileCardTitle title="Change E-mail" />
      <hr className="divider-top" />
      <ChangeEmailProfile handleUpdateUser={handleUpdateUser} />
    </div>
  );
};

export default ProfileEmail;
