import React, { useRef, useState } from 'react';
import { Table } from 'reactstrap';
import classnames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import ResizingColumn from '../../tables/ResizingColumn';

import {
  getItemStyle,
  getListStyle,
} from '../../../utils/dragable-helpers/dragable-styles';
import { getStyleTableGlobal } from '../../../utils/styles/get-style-table-head';
import { useTableWidth } from '../../../custom-hooks/useTableWidth';
import { GLOBAL_SEARCH_WIDTH } from '../../../constants/table';

const GlobalSearchTable = ({
  // tableRef,
  updateColumns,
  rows,
  columns,
  handleSort,
  handleChangeColumns,
  onResizeColumns,
  onResizeEnd,
  orderBy,
  orderType,
}) => {
  const currentColumns = useRef(null);

  const onDragStart = () => {
    currentColumns.current = columns;
  };

  const onDragUpdate = ({ source, destination }) => {
    handleChangeColumns(
      currentColumns.current,
      source?.index,
      destination?.index,
    );
  };

  const [isResizing, updateIsResizing] = useState(false);

  const tableRef = useTableWidth(updateColumns, GLOBAL_SEARCH_WIDTH);

  return (
    <div ref={tableRef} className="table-wrapper">
      <Table className="table-custom">
        <thead className="thead-light">
          <DragDropContext
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
          >
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <tr
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {columns.map(
                    (
                      { field, headerName, sortable, classes, width },
                      columnIndex,
                    ) => (
                      <Draggable
                        key={field}
                        draggableId={field}
                        index={columnIndex}
                        isDragDisabled={isResizing}
                      >
                        {(provided, snapshot) => (
                          <th
                            id="table-header"
                            key={field}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              minWidth: `${width}px`,
                              maxWidth: `${width}px`,
                              ...getItemStyle(
                                snapshot,
                                provided.draggableProps.style,
                              ),
                            }}
                            className={classnames(
                              classes,
                              { 'head-sort': sortable },
                              { 'd-cursor-scroll': isResizing },
                              ...getStyleTableGlobal(field, orderBy, orderType),
                            )}
                            onClick={() => {
                              sortable && !isResizing && handleSort(field);
                            }}
                          >
                            {headerName}
                            <ResizingColumn
                              columnIndex={columnIndex}
                              onResizeStart={(isStarted) =>
                                updateIsResizing(isStarted)
                              }
                              onResizeEnd={() => onResizeEnd(columnIndex)}
                              onResizeColumns={onResizeColumns}
                            />
                          </th>
                        )}
                      </Draggable>
                    ),
                  )}
                </tr>
              )}
            </Droppable>
          </DragDropContext>
        </thead>
        <tbody>
          {!!rows &&
            rows.map((_, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map(
                  (
                    {
                      field,
                      RenderCell,
                      headerName,
                      classes,
                      valueGetter,
                      width,
                    },
                    colIndex,
                  ) => {
                    const rowData = rows[rowIndex][field];
                    const text = valueGetter ? valueGetter(rowData) : rowData;
                    return (
                      <td
                        key={`${rowIndex}-${colIndex}`}
                        className={classnames('table-column', classes)}
                        style={{
                          minWidth: `${width}px`,
                          maxWidth: `${width}px`,
                        }}
                      >
                        <p className="td-before">{headerName}</p>
                        {RenderCell ? (
                          <RenderCell text={text} params={rows[rowIndex]} />
                        ) : (
                          <p>{text}</p>
                        )}
                      </td>
                    );
                  },
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};

export default GlobalSearchTable;
