import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const RequiresTrainingIcon = () => {
  return (
    <>
      <i
        className="bx bx-info-circle info-tooltip"
        id="requires-training-info"
      ></i>
      <UncontrolledTooltip
        placement="top"
        id="tooltip-document"
        target="requires-training-info"
      >
        <p className="mb-0">
          When the current document revision is approved, the Responsible user
          will be sent a reminder to link a training.
        </p>
      </UncontrolledTooltip>
    </>
  );
};

export default RequiresTrainingIcon;
