import {
  ASC,
  DESC,
  ORDER_BY_KEY,
  ORDER_DIRECTION_KEY,
} from '../../constants/table';

const isDoubleClick = (orderBy, field) => {
  const EMPTY_STRING = '';

  return [EMPTY_STRING, field].some((value) => value === orderBy);
};

export const getSortedTable = (pagination, field) => {
  const { [ORDER_BY_KEY]: orderBy, [ORDER_DIRECTION_KEY]: orderDirection } =
    pagination;

  const newOrderDirection =
    orderDirection === ASC && isDoubleClick(orderBy, field) ? DESC : ASC;

  return {
    ...pagination,
    [ORDER_BY_KEY]: field,
    [ORDER_DIRECTION_KEY]: newOrderDirection,
  };
};
