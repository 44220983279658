import { API } from '../backend-helper';

const ORGANIZATIONS = 'organizations';
const MANAGE_BY_CLIENT = 'managed-by-client';
const DOCUMENT_TYPES = 'document-types';
const AVAILABLE = 'available';
const TAGS = 'tags';
const GLOBAL_SEARCH = 'global-search';

export const changeManaged = (id) => {
  return API.patch(`/${ORGANIZATIONS}/${id}/${MANAGE_BY_CLIENT}`);
};

export const getDocumentTypes = (id, params) => {
  return API.get(`${ORGANIZATIONS}/${id}/${DOCUMENT_TYPES}/${AVAILABLE}`, {
    params,
  });
};

export const getTags = (id) => {
  return API.get(`${ORGANIZATIONS}/${id}/${TAGS}`);
};

export const createDocumentTag = (id, body) => {
  return API.post(`${ORGANIZATIONS}/${id}/${TAGS}`, body);
};

export const deleteDocumentTag = (id, tagId) => {
  return API.delete(`${ORGANIZATIONS}/${id}/${TAGS}/${tagId}`);
};

export const getGlobalSearch = (id, params) => {
  return API.get(`${ORGANIZATIONS}/${id}/${GLOBAL_SEARCH}`, { params });
};
