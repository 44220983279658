import React, { useCallback, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Col, Row } from 'reactstrap';

import SingleSelect from '../../../selects/Single';
import SingleDisabledSelect from '../../../selects/SingleDisabled';

import { getDocumentTypes } from '../../../../utils/api/organization-api';
import {
  configureDocumentTypes,
  configureGetDocumentTypes,
} from '../../../../utils/config-get-organization';
import { getAvailableDocuments } from '../../../../utils/api/document-api';
import { isMoreOneLinks } from '../../../../utils/documents/is-more-one-links';

const ReferencesAddDocument = ({
  id,
  links,
  handleDelete,
  handleUpdateReferences,
}) => {
  const { id: organizationId } = useRouteMatch().params;

  const getDocumentTypesAPI = useCallback(async () => {
    const params = { isOnlyApproved: true };
    return getDocumentTypes(organizationId, params).then(({ data }) => data);
  }, [organizationId]);

  const getDocumentsAPI = useCallback(
    async (search = '', docTypeId) => {
      const params = { organizationId, search, docTypeId };
      return getAvailableDocuments(params).then(({ data }) => data);
    },
    [organizationId],
  );

  const selectedRef = useRef();

  const handleClear = () => {
    selectedRef.current.select.clearValue();
  };

  const [documentTypeId, updateDocumentTypeId] = useState(null);

  return (
    <Row className="mb-3">
      <Col sm="4">
        <SingleSelect
          handleRequest={getDocumentTypesAPI}
          placeholder="Choose document type"
          configOptions={configureGetDocumentTypes}
          handleChange={(value) => {
            handleClear();
            updateDocumentTypeId(value?.id);
          }}
        />
      </Col>
      <Col sm="8" className="d-flex align-items-center">
        <SingleDisabledSelect
          selectedRef={selectedRef}
          handleRequest={getDocumentsAPI}
          placeholder="Choose document"
          configOptions={configureDocumentTypes}
          isDisabled={!documentTypeId}
          documentTypeId={documentTypeId}
          className="w-100 mr-2"
          handleChange={(values) =>
            handleUpdateReferences(values, 'documents', id)
          }
        />
        {isMoreOneLinks(links) ? (
          <i className="bx bxs-trash-alt" onClick={() => handleDelete(id)}></i>
        ) : (
          <i className="bx bxs-trash-alt trash-disabled"></i>
        )}
      </Col>
    </Row>
  );
};

export default ReferencesAddDocument;
