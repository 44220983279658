import { API } from '../backend-helper';

const USER_GROUPS = 'user-groups';
const DUPLICATE = 'duplicate';
const TRAININGS = 'trainings';
const ADD = 'add';
const ALL = 'all';

export const duplicateUserGroups = (body) => {
  return API.post(`/${USER_GROUPS}/${DUPLICATE}`, body);
};

export const getTrainingsList = (id, organizationId) => {
  return API.get(`/${USER_GROUPS}/${id}/${TRAININGS}`, {
    params: { organizationId },
  });
};

export const addClientUser = (id, body) => {
  return API.post(`/${USER_GROUPS}/${id}/${ADD}`, body);
};

export const getUsersOfUserGroup = (id, params) => {
  return API.get(`/${USER_GROUPS}/${id}/${ALL}`, { params });
};
