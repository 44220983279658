import moment from 'moment';
import { FORMAT_DUE_DATE } from '../../constants/helper';
import { getTemplateQuestion } from '../../utils/training-records-helper/get-template-question';

export const CreateTrainingRecordsModel = {
  name: {
    value: '',
    required: true,
  },
  dueDate: {
    value: moment().format(FORMAT_DUE_DATE),
    required: true,
  },
  description: {
    value: '',
    required: true,
  },
  assigneeUserIds: {
    value: [],
    required: false,
  },
  newAssigneeUserGroupIds: {
    value: [],
    required: false,
  },
  assignerId: {
    value: null,
    required: true,
  },
  link: {
    value: '',
    required: false,
  },
  linkDescription: {
    value: '',
    required: false,
  },
  files: {
    value: [],
    required: false,
  },
  questions: {
    value: [getTemplateQuestion(0)],
    required: false,
  },
  extraLink: {
    value: null,
    required: false,
  },
  extraLinkDescription: {
    value: null,
    required: false,
  },
  tagIds: {
    value: null,
    required: false,
  },
};
