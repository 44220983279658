import React from 'react';

import HelpImage from '../../shared/HelpImage';

import actionImage from '../../../../assets/images/help-screen/flow/flow-13.png';
import actionImage2 from '../../../../assets/images/help-screen/flow/flow-14.png';
import actionImage3 from '../../../../assets/images/help-screen/flow/flow-15.png';
import actionImage4 from '../../../../assets/images/help-screen/flow/flow-16.png';

export const actionsTexts = [
  {
    text: 'Automatic Actions',
    modalContent: (
      <>
        <p>
          Actions are automatically generated by the system in several
          scenarios. Actions are created when:
        </p>
        <ul className="list-default">
          <li>
            <p>User(s) are added as a Document Reviewer or Approver</p>
          </li>
          <li>
            <p>User is selected as the Document Responsible</p>
          </li>
          <li>
            <p>Approved Document Revision which Requires Training</p>
          </li>
          <li>
            <p>User(s) are assigned a Training</p>
          </li>
        </ul>
        <table className="simple-table">
          <tbody>
            <tr>
              <td>Action Name</td>
              <td>Trigger Details</td>
              <td>Assignee</td>
            </tr>
            <tr>
              <td>Document Review</td>
              <td>
                A user has been added as a reviewer of a document revision
              </td>
              <td>Reviewer</td>
            </tr>
            <tr>
              <td>Document Approval</td>
              <td>
                A user has been added as an approver of a document revision,
                which has status “Reviewed”. Or the status in a document
                revision, where the user is an approver, has become “Reviewed”.
              </td>
              <td>Approver</td>
            </tr>
            <tr>
              <td>Assign Reviewers and Approvers</td>
              <td>
                A user is indicated in the Responsible field when a document
                revision is saved. This user should assign reviewers and
                approvers as needed.
              </td>
              <td>Responsible</td>
            </tr>
            <tr>
              <td>Link a Training</td>
              <td>
                A document revision, where “Requires Training” is selected, has
                been approved. Create and/or link a training record.
              </td>
              <td>Responsible</td>
            </tr>
            <tr>
              <td>Pass Training</td>
              <td>A user has been assigned to a training.</td>
              <td>Assignee</td>
            </tr>
          </tbody>
        </table>
        <p>
          Automatically created Actions are assigned to the user as indicated by
          the triggering event (i.e. the User added as a Reviewer or Approver).
          Due dates are automatically set for 2 weeks after the date of
          assigning, except for Training actions. These are set for the Training
          due date.
        </p>
        <p>
          Actions that are automatically generated will automatically be marked
          as complete when the action is completed by the user. One exception to
          this is the Assign Reviewers and Approvers tasks. These tasks need to
          be manually completed. This is noted in the task description.
        </p>
      </>
    ),
  },
  {
    text: 'Manual Actions',
    modalContent: (
      <>
        <p>
          Actions can also be created manually. On the “Actions” screen, Users
          may have the “Add New” button if the User has access permissions which
          allow actions to be created.
        </p>
        <HelpImage src={actionImage} />
        <p>
          To create a new action, select the “Add New” button. All fields marked
          with asterisks must be filled.
        </p>
        <HelpImage src={actionImage2} />
        <p>
          Any user in the organization, Admin or a User, may be selected as the
          Action Assignee. The selected User will receive a notification and
          email that a new task has been assigned to them.
        </p>
        <HelpImage src={actionImage3} />
        <p>Manually created Actions must be manually marked as complete.</p>
      </>
    ),
  },
  {
    text: 'Action Status',
    modalContent: (
      <>
        <HelpImage src={actionImage4} />
        <p>
          An Action has its Status which can be “To Do” or “Complete”. The
          status of automatically created Actions will be automatically changed
          to “Complete” when the user completes the required action. The status
          of manually created actions must be manually changed to complete. Only
          users with edit permissions of Actions may edit the status of an
          Action.
        </p>
        <p>
          Actions can be linked to any artifact: document revision, training, or
          HR record/user. There may only be 1 artifact link per Action. There is
          also a similar option to add an External Link - any direct link may be
          added here. The link will be displayed as the text in the “Link Name”
          field when the Action is viewed. This feature may be used to direct
          users to any external resources.
        </p>
      </>
    ),
  },
];
