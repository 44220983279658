import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Col,
  NavItem,
  Row,
  NavLink,
  TabPane,
  TabContent,
} from 'reactstrap';
import classnames from 'classnames';

import DocumentSettingTab from './DocumentSetting';
import TaskSettingTab from './TaskSetting';
import TrainingSettingTab from './TrainingSetting';

import {
  FIRST_SETTING_TAB,
  FOURTH_SETTING_TAB,
  SECOND_SETTING_TAB,
  THIRD_SETTING_TAB,
} from '../../../constants/helper';
import { getGroupSetting } from '../../../utils/backend-helper';
import OrganizationSetting from './OrganizationSetting';

const UserGroupSetting = ({ organizationId, id }) => {
  const [activeTab, setActiveTab] = useState(FIRST_SETTING_TAB);

  const toggleTabs = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const [settings, updateSettings] = useState([]);

  useEffect(() => {
    getGroupSetting(id, organizationId).then(({ data }) =>
      updateSettings(data),
    );
  }, [id, organizationId]);

  return (
    <Row>
      <Col xs="3">
        <Card>
          <CardBody>
            <ul className="nav nav-tabs nav-tabs-setting" role="tablist">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === FIRST_SETTING_TAB,
                  })}
                  onClick={() => toggleTabs(FIRST_SETTING_TAB)}
                >
                  Permissions for Documents
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === SECOND_SETTING_TAB,
                  })}
                  onClick={() => toggleTabs(SECOND_SETTING_TAB)}
                >
                  Permissions for Actions
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === THIRD_SETTING_TAB,
                  })}
                  onClick={() => toggleTabs(THIRD_SETTING_TAB)}
                >
                  Permissions for Training Records
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === FOURTH_SETTING_TAB,
                  })}
                  onClick={() => toggleTabs(FOURTH_SETTING_TAB)}
                >
                  Permissions for Organization Settings
                </NavLink>
              </NavItem>
            </ul>
          </CardBody>
        </Card>
      </Col>
      <Col xs="9">
        <Card>
          <CardBody>
            <TabContent activeTab={activeTab} className="p-0">
              <TabPane tabId={FIRST_SETTING_TAB} id="permissions-for-document">
                <DocumentSettingTab
                  settings={settings}
                  updateSettings={updateSettings}
                  organizationId={organizationId}
                  id={id}
                />
              </TabPane>
              <TabPane tabId={SECOND_SETTING_TAB} id="permissions-for-tasks">
                <TaskSettingTab
                  settings={settings}
                  updateSettings={updateSettings}
                  organizationId={organizationId}
                  id={id}
                />
              </TabPane>
              <TabPane tabId={THIRD_SETTING_TAB} id="permissions-for-tasks">
                <TrainingSettingTab
                  settings={settings}
                  updateSettings={updateSettings}
                  organizationId={organizationId}
                  id={id}
                />
              </TabPane>
              <TabPane
                tabId={FOURTH_SETTING_TAB}
                id="permission-for-organization"
              >
                <OrganizationSetting
                  settings={settings}
                  updateSettings={updateSettings}
                />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default UserGroupSetting;
