import React, { useState } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Button from '../../components/Button';
import { MAX_INPUT_LENGTH } from '../../constants/helper';

const UserGroupDuplicateModel = ({ isOpen, onCancel, onSubmit, model }) => {
  const [groupName, updateGroupName] = useState('');

  const handleChangeName = (value) => updateGroupName(value);

  const clearGropName = () => updateGroupName('');

  const handleSubmitModal = (event, values) => {
    onSubmit(event, values, clearGropName);
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Duplicate User Group</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm
        className="form-horizontal mt-2"
        onValidSubmit={handleSubmitModal}
      >
        <div className="modal-body">
          <p className="text-active">
            By confirming this action, you will create a new User group, which
            will inherit all the settings of the duplicated User Group
          </p>
          <div className="form-group">
            <AvField
              name="name"
              label="Group name"
              className="form-control"
              placeholder="Enter group name"
              maxLength={MAX_INPUT_LENGTH}
              type="text"
              onChange={(e) => handleChangeName(e.target.value)}
              value={groupName}
            />
          </div>
          <AvField
            name="sourceGroupId"
            type="text"
            value={model.sourceGroupId}
            className="btn-hidden"
          />
          <AvField
            name="organizationId"
            type="text"
            value={model.organizationId}
            className="btn-hidden"
          />
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={!groupName}
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light mr-3 pl-3 pr-3"
              >
                Create Group
              </Button>
            </Col>
          </Row>
        </div>
      </AvForm>
    </Modal>
  );
};

export default UserGroupDuplicateModel;
