import React from 'react';

const ReviewColumn = ({ title, text }) => {
  return (
    <div className="info-column">
      <p className="info-column__title">{title}</p>
      <p className="info-column__text">{text}</p>
    </div>
  );
};

export default ReviewColumn;
