import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import React from 'react';
import MessageResetSuccess from '../../../components/ToastSuccess';

import {
  ORGANIZATIONS,
  CREATE_ORGANIZATION,
  TOOGLE_MANAGE_BY_CLIENT,
  DELETE_ORGANIZATION,
  SET_STATUS,
} from './actionTypes';

import {
  getDataSuccess,
  createOrganizationSuccess,
  manageByClientSuccess,
  deleteOrganizationSuccess,
  setStatusSuccess,
} from './actions';

import {
  createNewOrganization,
  toogleManageByClient,
  deleteNewOrganization,
  getDataOrganizations,
  setActiveStatus,
} from '../../../utils/backend-helper';

import {
  SUCCESS_MESSAGE_ORGANIZATION,
  SUCCESS_DELETE_MESSAGE,
  SUCCESS_STATUS_MESSAGE,
} from '../../../constants/messages';

function* getOrganizations({
  payload: { limit, offset, search, orderBy, orderType },
}) {
  try {
    const response = yield call(getDataOrganizations, '/organizations?', {
      limit,
      offset,
      search,
      orderBy,
      orderType,
    });

    yield put(getDataSuccess(response));
  } catch (error) {}
}

function* createOrganization({ payload: { name } }) {
  try {
    const response = yield call(createNewOrganization, '/organizations', {
      name,
    });
    if (response) {
      yield put(createOrganizationSuccess());
      toast.success(
        <MessageResetSuccess message={SUCCESS_MESSAGE_ORGANIZATION} />,
      );
    }
  } catch (error) {}
}

function* deleteOrganization({ id }) {
  try {
    const response = yield call(deleteNewOrganization, `/organizations/${id}`, {
      id,
    });
    if (response) {
      yield put(deleteOrganizationSuccess());

      toast.success(<MessageResetSuccess message={SUCCESS_DELETE_MESSAGE} />);
    }
  } catch (error) {}
}

function* managedByClient({ payload: { id } }) {
  try {
    const response = yield call(
      toogleManageByClient,
      `/organizations/${id}/managed-by-client`,
    );
    if (response) {
      yield put(manageByClientSuccess(id));
    }
  } catch (error) {}
}

function* setStatus({ id }) {
  try {
    const response = yield call(
      setActiveStatus,
      `/organizations/${id}/status`,
      { id },
    );
    if (response) {
      yield put(setStatusSuccess());
      toast.success(<MessageResetSuccess message={SUCCESS_STATUS_MESSAGE} />);
    }
  } catch (error) {}
}

export function* watchOrganizations() {
  yield takeEvery(ORGANIZATIONS, getOrganizations);
}

export function* watchCreateOrganization() {
  yield takeEvery(CREATE_ORGANIZATION, createOrganization);
}

export function* watchDeleteOrganization() {
  yield takeEvery(DELETE_ORGANIZATION, deleteOrganization);
}

export function* watchManageByClient() {
  yield takeEvery(TOOGLE_MANAGE_BY_CLIENT, managedByClient);
}

export function* watchActiveStatus() {
  yield takeEvery(SET_STATUS, setStatus);
}

function* organizationSaga() {
  yield all([
    fork(watchOrganizations),
    fork(watchCreateOrganization),
    fork(watchManageByClient),
    fork(watchDeleteOrganization),
    fork(watchActiveStatus),
  ]);
}

export default organizationSaga;
