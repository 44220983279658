import React from 'react';

import CardTitle from '../../shared/CardTitle';
import HelpIntroductionContent from './Content';

const HelpIntroduction = () => {
  return (
    <div>
      <CardTitle title="Introduction" />
      <HelpIntroductionContent />
    </div>
  );
};

export default HelpIntroduction;
