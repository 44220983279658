import {
  GENERAL_DOCUMENT_TAB,
  QUALITY_DOCUMENT_TAB,
  REFERENCES_DOCUMENT_TAB,
  REVIEW_DOCUMENT_TAB,
  APPROVAL_DOCUMENT_TAB,
} from '../../constants/helper';

const tabs = [
  GENERAL_DOCUMENT_TAB,
  QUALITY_DOCUMENT_TAB,
  REFERENCES_DOCUMENT_TAB,
  REVIEW_DOCUMENT_TAB,
  APPROVAL_DOCUMENT_TAB,
];

export const getDocumentTab = (permissions) => {
  return tabs.find((_, index) => permissions[index]);
};
