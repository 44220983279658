import { useCallback } from 'react';
import { useMounted } from './useMounted';

export const useSafeUpdateState = (updateState) => {
  const isMounted = useMounted();

  return useCallback(
    (data) => {
      if (isMounted.current) {
        updateState(data);
      }
      return;
    },
    [isMounted, updateState],
  );
};
