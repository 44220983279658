import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  CardBody,
  Col,
  Row,
  Container,
  Card,
  TabContent,
  TabPane,
} from 'reactstrap';

import Breadcrumbs from '../../../components/shared/Breadcrumbs/Breadcrumbs';
import DetailsNavigation from '../../../components/task/Navigation';
import TrainingRecordDetails from '../TrainingRecords/Details';
import TrainingRecordCompletion from '../TrainingRecords/Completion';

import { getTrainingRecordsDetails } from '../../../utils/api/training-records-api';
import {
  TRAINING_RECORD_TAB,
  TRAINING_PROGGRESS_TAB,
} from '../../../constants/helper';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const TrainingRecordView = ({ user }) => {
  const { id: organizationId, recordId } = useRouteMatch().params;

  const onError = useErrorHandler();

  const [recordDetails, updateRecordDetails] = useState(null);

  useEffect(() => {
    getTrainingRecordsDetails(recordId, organizationId)
      .then(({ data }) => updateRecordDetails(data))
      .catch(onError);
  }, [onError, organizationId, recordId]);

  const breadCrumbItems = [
    {
      link: `/organizations/${organizationId}/training-records`,
      title: 'Training records',
    },
    {
      link: '',
      title: recordDetails && recordDetails.name,
    },
  ];

  const [activeTab, updateActiveTab] = useState(TRAINING_RECORD_TAB);

  const redirectBack = `/organizations/${organizationId}/training-records`;

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs name="Training record" breadCrumbItems={breadCrumbItems} />
        <Row>
          <Col xs="12" sm="4" xl="3">
            <DetailsNavigation
              user={user}
              data={recordDetails}
              activeTab={activeTab}
              redirectBack={redirectBack}
              updateActiveTab={updateActiveTab}
            />
          </Col>
          <Col xs="12" sm="8" xl="9">
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab} className="p-0">
                  <TabPane tabId={TRAINING_RECORD_TAB}>
                    <div className="general">
                      <TrainingRecordDetails
                        recordDetails={recordDetails}
                        user={user}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId={TRAINING_PROGGRESS_TAB}>
                    <TrainingRecordCompletion user={user} />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TrainingRecordView;
