import React, { useEffect, useState } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { MAX_INPUT_LENGTH } from '../../constants/helper';
import Button from '../../components/Button';

const UserGroupEditModal = ({ onSubmit, onCancel, isOpen, values }) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(values.name);
  }, [values.name]);

  const handleChangeName = (value) => {
    setName(value);
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Edit User Group</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
        <div className="modal-body">
          <div className="form-group">
            <AvField
              name="name"
              label="Group name"
              className="form-control"
              placeholder="Enter group name"
              maxLength={MAX_INPUT_LENGTH}
              type="text"
              onChange={(e) => handleChangeName(e.target.value)}
              value={name}
            />
          </div>
          <AvField
            name="id"
            type="text"
            value={values.id}
            className="btn-hidden"
          />
          <AvField
            name="organizationId"
            type="text"
            value={values.routeId}
            className="btn-hidden"
          />
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={!name}
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light mr-3"
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </div>
      </AvForm>
    </Modal>
  );
};

export default UserGroupEditModal;
