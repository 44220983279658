import React from 'react';
import { USER_ROLE } from '../../../../../constants/helper';
import { getDataUser } from '../../../../../utils/get-store-user';

const DocumentReferencesLink = ({ title, links = [] }) => {
  const { role } = getDataUser();

  return (
    <>
      <hr />
      <div className="references-container">
        <ul>
          {' '}
          {title}
          {links.length ? (
            links.map(({ link, linkDescription, id }) => {
              return (
                <li key={id}>
                  {title === 'Human Resources' && role === USER_ROLE ? (
                    <p>{linkDescription}</p>
                  ) : (
                    <a target="_self" href={link}>
                      <i className="bx bx-link"></i>
                      {linkDescription}
                    </a>
                  )}
                </li>
              );
            })
          ) : (
            <li>No links yet.</li>
          )}
        </ul>
      </div>
    </>
  );
};

export default DocumentReferencesLink;
