import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Row, Col, Modal, FormGroup, Label, Input } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';

import Button from '../../components/Button';

import { MAX_INPUT_LENGTH } from '../../constants/helper';

const CreateDocumentTagModal = ({ onSubmit, isOpen, onCancel }) => {
  const { id: organizationId } = useRouteMatch().params;

  const { register, handleSubmit, formState } = useForm({ mode: 'onChange' });
  const { isDirty, isValid, errors } = formState;

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Create Tag</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <form className="form-horizontal mt-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <FormGroup>
            <Label
              for="documentType"
              className={classnames({ 'input-error-label': errors.name })}
            >
              Create tag
            </Label>
            <Input
              type="text"
              name="name"
              id="documentType"
              maxLength={MAX_INPUT_LENGTH}
              placeholder="Enter tag name"
              className={classnames({ 'input-error': errors.name })}
              innerRef={register({
                validate: {
                  isNotEmpty: (value) => value.trim().length > 0,
                },
              })}
            />
            {errors.name && (
              <p className="input-error-name">This field is required</p>
            )}
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={!isDirty || !isValid}
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light mr-3"
              >
                Create
              </Button>
            </Col>
          </Row>
        </div>
        <input ref={register} name="id" type="hidden" value={organizationId} />
      </form>
    </Modal>
  );
};

export default CreateDocumentTagModal;
