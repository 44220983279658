import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';

const InputWrapper = ({ text, children }) => {
  return (
    <Row>
      <Col>
        <p className="info-column__title">{text}</p>
        <FormGroup className="select2-container">{children}</FormGroup>
      </Col>
    </Row>
  );
};

export default InputWrapper;
