export const getFilteredReferences = (references) => {
  return references.reduce(
    (acc, next) => {
      const { type } = next;
      acc[type].links.push(next);
      return acc;
    },
    [
      { title: 'Document', links: [] },
      { title: 'Actions', links: [] },
      { title: 'Training Records', links: [] },
      { title: 'Human Resources', links: [] },
    ],
  );
};
