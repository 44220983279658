import React from 'react';
import { Modal } from 'reactstrap';

const HelpImageModal = ({ isOpen, toggle, src }) => {
  return (
    <Modal className='modal-xl-custom' isOpen={isOpen} toggle={toggle} centered={true}>
      <i className='bx bx-x-circle modal-close-icon' onClick={toggle}></i>
      <div className='modal-help-image'>
        <img src={src} alt='HelpImage' />
      </div>
    </Modal>
  )
}

export default HelpImageModal;