import React from 'react';
import { Input } from 'reactstrap';

const TableSearch = ({ handleSearch }) => {
  return (
    <div className="search-box mr-2 d-inline-block">
      <div className="position-relative">
        <Input
          type="text"
          onChange={(event) => handleSearch(event.target.value)}
          className="form-control search-input"
          placeholder="Search..."
        />
        <i className="bx bx-search-alt search-icon"></i>
      </div>
    </div>
  );
};

export default TableSearch;
