import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Row, Col, FormGroup } from 'reactstrap';
import { AvForm, AvInput, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
import Select from 'react-select';

import { getReviews, updateReview } from '../../../utils/backend-helper';
import {
  CREATON_FORMAT,
  DOCUMENT_RESPONSE_STATUS,
  REVISION_PARAM,
} from '../../../constants/helper';
import Index from '../../Button';
import ReviewItem from './Item';
import { configRevisionRequest } from '../../../utils/documents/config-body-revision';
import { useQueryHelper } from '../../../utils/query-params-helper';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';
import { customStyles } from '../../../utils/select-styles';
import InfoColumn from '../../shared/GeneralColumn';

const DocumentReview = ({
  currentRevision,
  revision,
  updateCurrentRevision,
}) => {
  const { id: organizationId, documentId } = useRouteMatch().params;
  const { revision: revisionParams } = useQueryHelper([REVISION_PARAM]);

  const [editMode, updateEditMode] = useState(false);

  const [reviewInfo, updateReviewInfo] = useState({
    reviews: [],
    canAddReview: false,
  });

  const [model, updateModel] = useState({
    status: null,
    comment: '',
  });

  useEffect(() => {
    const revison = currentRevision.value || revisionParams;
    const params = configRevisionRequest({ organizationId }, revison);
    getReviews(documentId, params).then(({ data }) => updateReviewInfo(data));
  }, [documentId, organizationId, currentRevision, revisionParams]);

  const responseOptions = [
    { value: '0', label: 'Reviewed', status: '0' },
    { value: '1', label: 'Rejected', status: '1' },
  ];

  const handleResponseChange = (value) => {
    updateModel((prev) => ({ ...prev, status: value.status }));
  };

  const handleModelChange = (event) => {
    const { value } = event.target;
    updateModel((prev) => ({ ...prev, comment: value }));
  };

  const onError = useErrorHandler(`/organizations/${organizationId}/documents`);

  const handleSubmit = (_, values) => {
    const { documentId, ...body } = values;
    updateReview(documentId, body)
      .then(() => {
        updateEditMode(false);
        updateCurrentRevision((prev) => ({ ...prev }));
        const params = configRevisionRequest(
          { organizationId },
          currentRevision.value,
        );
        getReviews(documentId, params).then(({ data }) =>
          updateReviewInfo(data),
        );
      })
      .catch(onError);
  };

  return (
    <div className="general">
      <div className="general-header">
        <h6 className="general-header__title">Review</h6>
        {reviewInfo.canAddReview && !editMode && (
          <button
            className="general__btn-edit"
            onClick={() => updateEditMode(true)}
          >
            <i className="bx bxs-edit-alt"></i> Edit
          </button>
        )}
      </div>
      <hr />
      {editMode ? (
        <AvForm className="form-horizontal mt-2" onValidSubmit={handleSubmit}>
          <Row>
            <Col>
              <p className="info-column__title">Review Response:</p>
              <FormGroup className="select2-container">
                <Select
                  placeholder="Choose review response"
                  styles={customStyles}
                  options={responseOptions}
                  onChange={(value) => handleResponseChange(value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="info-column__title">Comment:</p>
              <div className="form-group">
                <AvInput
                  type="textarea"
                  id="textarea"
                  name="comment"
                  placeholder="Leave comment"
                  value={model.revisionDescription}
                  onChange={(event) => handleModelChange(event)}
                  maxLength="300"
                  rows="3"
                />
              </div>
            </Col>
          </Row>
          <Row className="form-group mt-4">
            <Col className="text-right">
              <Index
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
                handler={() => updateEditMode(false)}
              >
                Cancel
              </Index>
              <Index
                type="submit"
                disabled={model.status === null}
                color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
              >
                Save Changes
              </Index>
            </Col>
          </Row>
          <AvField
            name="organizationId"
            type="text"
            value={organizationId}
            className="btn-hidden"
          />
          <AvField
            name="documentId"
            type="text"
            value={documentId}
            className="btn-hidden"
          />
          <AvField
            name="status"
            type="text"
            value={model.status}
            className="btn-hidden"
          />
          <AvField
            name="revision"
            type="text"
            value={currentRevision.value || revision}
            className="btn-hidden"
          />
        </AvForm>
      ) : (
        <>
          <div className="general-responsible">
            <InfoColumn
              title="Status"
              text={DOCUMENT_RESPONSE_STATUS[reviewInfo.status]}
            />
            <InfoColumn
              classes="info-column_right"
              title="Reviewed Date"
              text={
                reviewInfo.reviewedAt
                  ? moment(reviewInfo.reviewedAt).format(CREATON_FORMAT)
                  : ''
              }
            />
          </div>
          <hr />
          {reviewInfo.reviews.map((review, idx) => (
            <ReviewItem review={review} key={review.lastName + idx} />
          ))}
          {!reviewInfo.reviews.length && (
            <h5 className="mt-2">There are no review responses yet</h5>
          )}
        </>
      )}
    </div>
  );
};

export default DocumentReview;
