import React from 'react';

import HelpImage from '../../../shared/HelpImage';

import accessImage from '../../../../../assets/images/help-screen/admin/access-1.png';
import accessImage2 from '../../../../../assets/images/help-screen/admin/im_Two high-level permission options Copy@3x.png';
import accessImage3 from '../../../../../assets/images/help-screen/admin/im_Two high-level permission options Copy 2@3x.png';
import accessImage4 from '../../../../../assets/images/help-screen/admin/im_Two high-level permission options@3-1x.png';


export const userAccessTexts = [
  {
    text: 'Two high-level permission options',
    modalContent: (
      <>
        <p>Permissions to view all artifacts</p>
        <p>Permissions to view related artifacts. Related artifacts are ones where a User is an Owner, Reviewer, Assignee, etc.</p>
        <HelpImage src={accessImage} />
        <HelpImage src={accessImage2} />
        <HelpImage src={accessImage3} />
      </>
    )
  },
  {
    text: 'Several lower-level permissions options',
    modalContent: (
      <>
        <p>These are artifact-specific permissions that allow users to edit, delete and add items as described by each permission description.</p>
        <HelpImage src={accessImage4} />
      </>
    )
  }
]