import React from 'react';
import classnames from 'classnames';

import LabelBlock from '../LabelBlock';

import { getFullName } from '../../../utils/shared/get-full-name';

const UserEmailBlock = ({ title, user = {}, containerClassName }) => {
  return (
    <div className={classnames('user-email-block', containerClassName)}>
      {!!title && <p className="user-email-block__title">{title}</p>}
      <div className="d-flex flex-wrap user-email-block">
        <LabelBlock icon="bx bxs-user" text={getFullName(user)} />
        <LabelBlock icon="bx bxs-envelope" text={user?.email} />
      </div>
    </div>
  );
};

export default UserEmailBlock;
