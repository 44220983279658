import {
  CLEAR_TAG_SEARCH,
  INPUT_SEARCH,
  TAG_SEARCH,
  FINISHED_LOADING,
  CLEAR_SEARCH,
  TOGGLE_SEARCH
} from './actionTypes';

const initialState = {
  inputSearch: '',
  isTagSearch: false,
  isLoading: false,
  isOpenSearchMobile: false,
};

const searchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case INPUT_SEARCH:
      state = {
        ...state,
        inputSearch: payload,
        isLoading: true,
      };
      break;
    case TAG_SEARCH:
      state = {
        ...state,
        inputSearch: payload,
        isTagSearch: true,
        isLoading: true,
      };
      break;
    case CLEAR_TAG_SEARCH:
      state = {
        ...state,
        isTagSearch: false,
      };
      break;
    case FINISHED_LOADING:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case CLEAR_SEARCH:
      state = {
        ...state,
        inputSearch: '',
        isLoading: false,
      };
      break;
    case TOGGLE_SEARCH: 
      state = {
        ...state,
        isOpenSearchMobile: !state.isOpenSearchMobile
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default searchReducer;
