import React from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import Index from '../../components/Button';

const ModalOuter = ({ onSubmit, onCancel, isOpen, title = '', children }) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">{title}</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
        {children}
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Index
                type="submit"
                color="btn btn-danger w-md waves-effect waves-light delete-btn mr-3"
              >
                Delete
              </Index>
              <Index
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Index>
            </Col>
          </Row>
        </div>
      </AvForm>
    </Modal>
  );
};

export default ModalOuter;
