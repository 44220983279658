import React from 'react';

import SwitchOption from '../../../../../components/switchers/SwitchOption';

import { getCurrentSetting } from '../../../../../utils/disable-setting';
import { getDataUser } from '../../../../../utils/get-store-user';
import { isUserRole } from '../../../../../utils/shared/is-user-role';

const ToggleList = ({
  settings,
  list,
  id,
  mainId,
  organizationId,
  handleChangeGroupSetting,
  handleChecked,
  text,
  isOnlyView,
}) => {
  const { role } = getDataUser();

  return (
    <div className="section-edit">
      <p className="section-text">{text}</p>
      {list.map(
        (
          { label, tooltipText, userTooltipText, name, type, groupTypes },
          idx,
        ) => {
          return (
            <SwitchOption
              isOnlyView={isOnlyView}
              key={type}
              groupTypes={groupTypes}
              settings={settings}
              type={type}
              label={label}
              name={name}
              tooltipText={isUserRole(role) ? userTooltipText : tooltipText}
              idx={idx}
              id={id}
              active={getCurrentSetting(settings, mainId)}
              organizationId={organizationId}
              handleChangeGroupSetting={handleChangeGroupSetting}
              handleChecked={handleChecked}
            />
          );
        },
      )}
    </div>
  );
};

export default ToggleList;
