import React from 'react';

const ModalInner = ({ children, title, onCancel }) => {
  return (
    <>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">{title}</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      {children}
    </>
  );
};

export default ModalInner;
