import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, BreadcrumbItem } from 'reactstrap';

const Breadcrumbs = ({ name, breadCrumbItems, link, goBack, backText }) => {
  const history = useHistory();

  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex flex-wrap align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">
            {link ? (
              <Link
                className="nav-back"
                to={link}
                onClick={() => goBack && history.goBack()}
              >
                <i className="bx bx-chevron-left arrow"></i>
                {backText || 'Back to List'}
              </Link>
            ) : (
              <>{name}</>
            )}
          </h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {!!breadCrumbItems &&
                breadCrumbItems.map(({ link, title }, i) => {
                  return (
                    <BreadcrumbItem key={i}>
                      {link ? <Link to={link}>{title}</Link> : <>{title}</>}
                    </BreadcrumbItem>
                  );
                })}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Breadcrumbs;
