import React from 'react';
import { Col, Row } from 'reactstrap';

import SettingDocumentType from './DocumentType';
import SettingDocumentTag from './DocumentTag';

const OrganizationSetting = () => {
  return (
    <Row>
      <Col sm="12" md="6">
        <SettingDocumentType />
      </Col>
      <Col sm="12" md="6">
        <SettingDocumentTag />
      </Col>
    </Row>
  );
};

export default OrganizationSetting;
