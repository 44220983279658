export const changeOrganizationSetting = (settings, changedSetting) => {
  const indexChangedSetting = settings.findIndex(
    ({ type }) => type === changedSetting.type,
  );
  if (indexChangedSetting !== -1) {
    return settings.map((setting) =>
      setting.type === changedSetting.type ? changedSetting : setting,
    );
  }
  return [...settings, changedSetting];
};
