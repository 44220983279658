import React from 'react';
import { Col, Row } from 'reactstrap';
import { getDataUser } from '../../../utils/get-store-user';
import { isUrlForLinkRecord } from '../../../utils/is-url';

const TaskEditLink = ({ link, linkDescription, handleDelete }) => {
  const { role } = getDataUser();

  return (
    <Row>
      <Col>
        <div className="info-column">
          <p className="info-column__title">Link to Record</p>
        </div>
        <p className="info-column__text d-flex justify-content-between">
          {isUrlForLinkRecord(link, role) ? (
            <a href={link} target='_self'>
              <i className='bx bx-link top-link'></i>
              {linkDescription}
            </a>
          ) : (
            <span>{linkDescription}</span>
          )}
          <i onClick={handleDelete} className="bx bxs-trash-alt link-trash"></i>
        </p>
      </Col>
    </Row>
  );
};

export default TaskEditLink;
