import {
  ACTIONS_TYPE,
  TRAINING_RECORDS_TYPE,
  HR_RECORDS_TYPE,
} from '../../constants/document';
import {
  configureHumanResources,
  configureTasksOptions,
  configureTrainingsOptions,
} from '../config-get-organization';

export const apiTypesOption = {
  [ACTIONS_TYPE]: configureTasksOptions,
  [TRAINING_RECORDS_TYPE]: configureTrainingsOptions,
  [HR_RECORDS_TYPE]: configureHumanResources,
};

export const subLinks = {
  [ACTIONS_TYPE]: 'tasks',
  [TRAINING_RECORDS_TYPE]: 'training-records',
  [HR_RECORDS_TYPE]: 'human-resources',
};
