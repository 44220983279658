import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';

import Index from '../../Button';
import ExportLogsInfoType from './InfoType';
import ExportLogsRange from './Range';
import ExportLogsStatus from './Status';
import ExportLogsChanges from './Changes';
import MessageResetSuccess from '../../ToastSuccess';

import {
  DATE_RANGE_END,
  DATE_RANGE_START,
  INFO_TYPES,
  INITIAL_EXPORT_LOGS,
} from '../../../constants/table';
import { changeCheckedFromIndex } from '../../../utils/shared/change-checkbox-checked';
import { getCheckedIndexFromInfoTypes } from '../../../utils/tables-helper/info-type';
import { getCheckedIdsFromHidden } from '../../../utils/tables-helper/get-checked-ids-from-hidden';
import { isValidExportLogs } from '../../../utils/tables-helper/is-disabled-export-logs';
import { getExportOrganizationLogs } from '../../../utils/api/exports-api';
import { toast } from 'react-toastify';
import { getRangeFormatedDate } from '../../../utils/getRangeDate';

const TableExportLogsMenu = ({
  isOpen,
  handleClearStatus,
  handleChangeStatus,
  shadowData,
  search,
}) => {
  const [infoTypes, updateInfoTypes] = useState(INFO_TYPES);
  const [exportLogs, updateExportLogs] = useState(INITIAL_EXPORT_LOGS);

  useEffect(() => {
    updateExportLogs((logs) => ({
      ...logs,
      SelectedIds: getCheckedIdsFromHidden(shadowData),
      InfoTypes: getCheckedIndexFromInfoTypes(infoTypes),
      search,
    }));
  }, [search, shadowData, infoTypes]);

  const handleExportLogs = () => {
    getExportOrganizationLogs(exportLogs).then(({ data: { message } }) => {
      toast.success(<MessageResetSuccess message={message} />);
    });
  };

  const isDisabled = useMemo(() => {
    const {
      SelectedIds,
      InfoTypes,
      [DATE_RANGE_START]: dateStart,
      [DATE_RANGE_END]: dateEnd,
      IncludeOrganizationChanges: selectedChanges,
    } = exportLogs;

    return !isValidExportLogs(
      SelectedIds,
      InfoTypes,
      dateStart,
      dateEnd,
      selectedChanges,
    );
  }, [exportLogs]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleClearFilters = () => {
    handleClearStatus();
    updateExportLogs(INITIAL_EXPORT_LOGS);
    updateInfoTypes(INFO_TYPES);
    setStartDate(null);
    setEndDate(null);
  };

  if (isOpen) {
    return (
      <Row className="mb-3 mt-3">
        <Col className="d-flex justify-content-between align-items-center selected-delete">
          <div className="filter-menu-container">
            <ExportLogsStatus handleChangeStatus={handleChangeStatus} />
            <ExportLogsInfoType
              infoTypes={infoTypes}
              handleClear={() => {
                updateInfoTypes(INFO_TYPES);
              }}
              handleChecked={(index) => {
                updateInfoTypes((infoTypes) =>
                  changeCheckedFromIndex(infoTypes, index),
                );
              }}
            />
            <ExportLogsRange
              startDate={startDate}
              endDate={endDate}
              // onChangeToday={(today) => {

              // }}
              onChange={([start, end]) => {
                setStartDate(start);
                setEndDate(end);

                [start, end] = getRangeFormatedDate(start, end);

                updateExportLogs((logs) => ({
                  ...logs,
                  [DATE_RANGE_START]: start,
                  [DATE_RANGE_END]: end,
                }));
              }}
            />
            <ExportLogsChanges
              isChecked={exportLogs.IncludeOrganizationChanges}
              onChange={() => {
                updateExportLogs((logs) => ({
                  ...logs,
                  IncludeOrganizationChanges: !logs.IncludeOrganizationChanges,
                }));
              }}
            />
          </div>
          <div className="d-flex align-items-center">
            <div
              className="selected-delete__action mr-3"
              onClick={handleClearFilters}
            >
              Clear all
            </div>
            <Index
              color="export-logs-button"
              disabled={isDisabled}
              handler={handleExportLogs}
            >
              Export Logs
            </Index>
          </div>
        </Col>
      </Row>
    );
  }

  return null;
};

export default TableExportLogsMenu;
