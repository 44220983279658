import React, { useState } from 'react';
import { Col, Row, Collapse, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';

import SwitchSetting from '../../../../components/switchers/SwitchSetting';
import { changeGroupSetting } from '../../../../utils/backend-helper';
import {
  END_ALL,
  END_RELATED,
  END_VIEW_ID,
  START_ALL,
  START_RELATED,
  START_VIEW_ID,
  VIEW_APPROVED_ID,
  VIEW_DOCUMENTS_ID,
} from '../../../../constants/helper';
import {
  getCurrentSetting,
  getDisableSetting,
} from '../../../../utils/disable-setting';
import { insertSetting } from '../../../../utils/insert-setting';
import {
  addOptionsCol1,
  addOptionsCol2,
  deleteOptionsCol1,
  deleteOptionsCol2,
  editOptionsCol1,
  editOptionsCol2,
  allSectionOptions,
  sectionOptions,
} from '../settingsMok';

import SwitchOption from '../../../../components/switchers/SwitchOption';
import SwitchOptionMulti from '../../../../components/switchers/SwitchOptionMulti';
import { getDataUser } from '../../../../utils/get-store-user';
import { isUserRole } from '../../../../utils/shared/is-user-role';

const DocumentSettingTab = ({
  settings,
  updateSettings,
  organizationId,
  id,
  isOnlyView = false,
}) => {
  const { role } = getDataUser();

  const [cols, setCols] = useState({
    col1: false,
    col2: false,
  });

  const handleUpdateCols = (name) => {
    setCols((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleChangeGroupSetting = (id, organizationId, type, subOptions) => {
    changeGroupSetting(id, organizationId, type).then(({ data }) => {
      const isMainRadioId = [VIEW_DOCUMENTS_ID, VIEW_APPROVED_ID].includes(
        data.type,
      );
      if (isMainRadioId && !data.isSwitchedOn) {
        const disabledSettings = getDisableSetting(
          settings,
          START_VIEW_ID,
          END_VIEW_ID,
          START_ALL,
          END_ALL,
          START_RELATED,
          END_RELATED,
        );
        updateSettings(disabledSettings);
        return;
      }

      updateSettings((prev) => insertSetting(prev, data, subOptions));
    });
  };

  const handleUpdateRadio = (
    event,
    id,
    organizationId,
    VIEW_DOCUMENTS_ID,
    subOptions,
  ) => {
    event.stopPropagation();
    handleChangeGroupSetting(id, organizationId, VIEW_DOCUMENTS_ID, subOptions);
  };

  const handleChecked = (type) => {
    if (!settings.length) return false;

    const indexOfSetting = settings.findIndex(
      (setting) => setting.type === type,
    );
    if (indexOfSetting !== -1) {
      return settings[indexOfSetting].isSwitchedOn;
    }
    return false;
  };

  const handleChangeGroupSettingMulti = (id, organizationId, type) => {
    changeGroupSetting(id, organizationId, type).then(({ data }) => {
      updateSettings((prev) => insertSetting(prev, data));
    });
  };

  return (
    <>
      <Row>
        <Col className="permissions-document">
          <h5>Permissions for Documents</h5>
          {!isOnlyView && (
            <>
              <p>
                <b>Turning On</b> the options below will allow the users of the
                current User Group to access the Documents.
              </p>
              <p>
                You can <b>turn on only one higher-level option</b>, and among
                the grouped options only one can be turned on under the enabled
                parent-option.
              </p>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="accordion">
            <div
              className="accordion-header"
              onClick={() => handleUpdateCols('col1')}
            >
              <div className="form-check accordion-header__radio">
                <SwitchSetting
                  id={id}
                  organizationId={organizationId}
                  handleChecked={handleChecked}
                  handleUpdateRadio={handleUpdateRadio}
                  DOCUMENTS_ID={VIEW_DOCUMENTS_ID}
                  subOptions={allSectionOptions}
                  isDisabled={
                    isOnlyView
                      ? true
                      : getCurrentSetting(settings, VIEW_APPROVED_ID)
                  }
                />
                <p>
                  {' '}
                  View all documents
                  <i
                    id="Tooltip-accordion-1"
                    className="bx bx-info-circle info"
                  ></i>
                </p>
                <UncontrolledTooltip
                  placement="top"
                  id="tooltip-accordion"
                  target="Tooltip-accordion-1"
                >
                  <p className="mb-0">
                    {isUserRole(role)
                      ? '"Allows you to view all the Documents in the current Organization'
                      : 'The users will be able to view all the Documents in the current Organization'}
                  </p>
                </UncontrolledTooltip>
              </div>
              <i
                className={classnames('bx bx-chevron-down arrow', {
                  downArrow: cols.col1,
                })}
              ></i>
            </div>
          </div>
          <Collapse isOpen={cols.col1}>
            <div className="collapse-content">
              <div className="section-main">
                {allSectionOptions.map(({ label, type }) => {
                  return (
                    <SwitchOptionMulti
                      type={type}
                      key={type}
                      label={label}
                      id={id}
                      isOnlyView={isOnlyView}
                      organizationId={organizationId}
                      handleCheckedMulti={handleChecked}
                      active={getCurrentSetting(settings, VIEW_DOCUMENTS_ID)}
                      handleChangeGroupSettingMulti={
                        handleChangeGroupSettingMulti
                      }
                    />
                  );
                })}
              </div>
              <hr />
              <div className="section-edit">
                <p className="section-text">Edit option</p>
                {editOptionsCol1.map(
                  (
                    {
                      label,
                      tooltipText,
                      userTooltipText,
                      name,
                      type,
                      groupTypes,
                      subOptions,
                    },
                    idx,
                  ) => {
                    return (
                      <SwitchOption
                        isOnlyView={isOnlyView}
                        key={type}
                        groupTypes={groupTypes}
                        settings={settings}
                        type={type}
                        label={label}
                        name={name}
                        tooltipText={
                          isUserRole(role) ? userTooltipText : tooltipText
                        }
                        idx={idx}
                        id={id}
                        active={getCurrentSetting(settings, VIEW_DOCUMENTS_ID)}
                        organizationId={organizationId}
                        handleChangeGroupSetting={handleChangeGroupSetting}
                        handleChangeGroupSettingMulti={
                          handleChangeGroupSettingMulti
                        }
                        handleChecked={handleChecked}
                        subOptions={subOptions}
                      />
                    );
                  },
                )}
              </div>
              <div className="section-delete">
                <p className="section-text">Delete option</p>
                {deleteOptionsCol1.map(
                  (
                    {
                      label,
                      tooltipText,
                      userTooltipText,
                      name,
                      type,
                      groupTypes,
                    },
                    idx,
                  ) => {
                    return (
                      <SwitchOption
                        isOnlyView={isOnlyView}
                        key={type}
                        groupTypes={groupTypes}
                        settings={settings}
                        type={type}
                        label={label}
                        name={name}
                        tooltipText={
                          isUserRole(role) ? userTooltipText : tooltipText
                        }
                        idx={idx}
                        id={id}
                        active={getCurrentSetting(settings, VIEW_DOCUMENTS_ID)}
                        organizationId={organizationId}
                        handleChangeGroupSetting={handleChangeGroupSetting}
                        handleChecked={handleChecked}
                      />
                    );
                  },
                )}
              </div>
              <div className="section-add">
                <p className="section-text">Add option</p>
                {addOptionsCol1.map(
                  (
                    {
                      label,
                      tooltipText,
                      userTooltipText,
                      name,
                      type,
                      groupTypes,
                    },
                    idx,
                  ) => {
                    return (
                      <SwitchOption
                        isOnlyView={isOnlyView}
                        key={type}
                        groupTypes={groupTypes}
                        settings={settings}
                        type={type}
                        label={label}
                        name={name}
                        tooltipText={
                          isUserRole(role) ? userTooltipText : tooltipText
                        }
                        idx={idx}
                        id={id}
                        active={getCurrentSetting(settings, VIEW_DOCUMENTS_ID)}
                        organizationId={organizationId}
                        handleChangeGroupSetting={handleChangeGroupSetting}
                        handleChecked={handleChecked}
                      />
                    );
                  },
                )}
              </div>
            </div>
          </Collapse>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="accordion">
            <div
              className="accordion-header"
              onClick={() => handleUpdateCols('col2')}
            >
              <div className="form-check accordion-header__radio">
                <SwitchSetting
                  id={id}
                  isOnlyView={isOnlyView}
                  organizationId={organizationId}
                  handleChecked={handleChecked}
                  handleUpdateRadio={handleUpdateRadio}
                  DOCUMENTS_ID={VIEW_APPROVED_ID}
                  subOptions={sectionOptions}
                  isDisabled={
                    isOnlyView
                      ? true
                      : getCurrentSetting(settings, VIEW_DOCUMENTS_ID)
                  }
                />
                <p>
                  View all approved documents and documents in any status when
                  the user is related to the documents
                  <i
                    id="Tooltip-accordion-2"
                    className="bx bx-info-circle info"
                  ></i>
                </p>
                <UncontrolledTooltip
                  placement="top"
                  id="tooltip-accordion"
                  target="Tooltip-accordion-2"
                >
                  <p className="mb-0">
                    {isUserRole(role)
                      ? 'In the current Organization, allows you to view only approved Documents and Documents where you are selected as Reviewer, or Approver, or Responsible, or you are an Owner'
                      : 'In the current Organization, the users will be able to view only approved Documents and Documents where they are selected as Reviewer, or Approver, or Responsible, or they are an Owner'}
                  </p>
                </UncontrolledTooltip>
              </div>
              <i
                className={classnames('bx bx-chevron-down arrow', {
                  downArrow: cols.col2,
                })}
              ></i>
            </div>
          </div>
          <Collapse isOpen={cols.col2}>
            <div className="collapse-content">
              <div className="section-main">
                {sectionOptions.map(({ label, type }) => {
                  return (
                    <SwitchOptionMulti
                      type={type}
                      key={type}
                      label={label}
                      id={id}
                      isOnlyView={isOnlyView}
                      organizationId={organizationId}
                      handleCheckedMulti={handleChecked}
                      active={getCurrentSetting(settings, VIEW_APPROVED_ID)}
                      handleChangeGroupSettingMulti={
                        handleChangeGroupSettingMulti
                      }
                    />
                  );
                })}
              </div>
              <div className="section-edit">
                <p className="section-text">Edit option</p>
                {editOptionsCol2.map(
                  (
                    {
                      label,
                      tooltipText,
                      userTooltipText,
                      name,
                      type,
                      groupTypes,
                      subOptions,
                    },
                    idx,
                  ) => {
                    const text = isUserRole(role)
                      ? userTooltipText
                      : tooltipText;
                    return (
                      <SwitchOption
                        isOnlyView={isOnlyView}
                        key={type}
                        groupTypes={groupTypes}
                        settings={settings}
                        type={type}
                        label={label}
                        name={name}
                        tooltipText={text}
                        idx={idx}
                        id={id}
                        active={getCurrentSetting(settings, VIEW_APPROVED_ID)}
                        organizationId={organizationId}
                        handleChangeGroupSetting={handleChangeGroupSetting}
                        handleChangeGroupSettingMulti={
                          handleChangeGroupSettingMulti
                        }
                        handleChecked={handleChecked}
                        subOptions={subOptions}
                      />
                    );
                  },
                )}
              </div>
              <div className="section-edit">
                <p className="section-text">Delete option</p>
                {deleteOptionsCol2.map(
                  (
                    {
                      label,
                      tooltipText,
                      userTooltipText,
                      name,
                      type,
                      groupTypes,
                    },
                    idx,
                  ) => {
                    const text = isUserRole(role)
                      ? userTooltipText
                      : tooltipText;
                    return (
                      <SwitchOption
                        isOnlyView={isOnlyView}
                        key={type}
                        groupTypes={groupTypes}
                        settings={settings}
                        type={type}
                        label={label}
                        name={name}
                        tooltipText={text}
                        idx={idx}
                        id={id}
                        active={getCurrentSetting(settings, VIEW_APPROVED_ID)}
                        organizationId={organizationId}
                        handleChangeGroupSetting={handleChangeGroupSetting}
                        handleChecked={handleChecked}
                      />
                    );
                  },
                )}
                <div className="section-edit">
                  <p className="section-text">Add option</p>
                  {addOptionsCol2.map(
                    (
                      {
                        label,
                        tooltipText,
                        userTooltipText,
                        name,
                        type,
                        groupTypes,
                      },
                      idx,
                    ) => {
                      const text = isUserRole(role)
                        ? userTooltipText
                        : tooltipText;
                      return (
                        <SwitchOption
                          isOnlyView={isOnlyView}
                          key={type}
                          groupTypes={groupTypes}
                          settings={settings}
                          type={type}
                          label={label}
                          name={name}
                          tooltipText={text}
                          idx={idx}
                          id={id}
                          active={getCurrentSetting(settings, VIEW_APPROVED_ID)}
                          organizationId={organizationId}
                          handleChangeGroupSetting={handleChangeGroupSetting}
                          handleChecked={handleChecked}
                        />
                      );
                    },
                  )}
                </div>
              </div>
            </div>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default DocumentSettingTab;
