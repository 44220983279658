import React from 'react';

const IconButton = ({ iconClass, text, onClick }) => {
  return (
    <div className="icon-button" onClick={onClick}>
      <i className={`icon ${iconClass}`}></i>
      {text}
    </div>
  );
};

export default IconButton;
