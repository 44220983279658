import { all } from 'redux-saga/effects';

//public
import AuthSaga from './auth/login/saga';
import ForgetSaga from './auth/forget-password/saga';
import changePasswordSaga from './auth/change-password/saga';
import ProfileSaga from './auth/profile/saga';
import LayoutSaga from './layout/saga';
import OrganizationSaga from './auth/organization/saga';
import AdminsSaga from './auth/admins/saga';
import SetPasswordSaga from './auth/set-password/saga';

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    ProfileSaga(),
    ForgetSaga(),
    changePasswordSaga(),
    LayoutSaga(),
    OrganizationSaga(),
    AdminsSaga(),
    SetPasswordSaga(),
  ]);
}
