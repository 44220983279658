import React, { useMemo } from 'react';
import { Row, Col, Modal } from 'reactstrap';

const DisplayColumnsModal = ({
  isOpen,
  onCancel,
  columnsVisibility,
  handleUpdateColumnsVisible,
  handleSelectAll,
  handleSaveColumns,
}) => {
  const isDisabled = useMemo(() => {
    return !Object.values(columnsVisibility).some(({ isVisible }) => isVisible);
  }, [columnsVisibility]);

  const isSelectAll = useMemo(() => {
    return Object.values(columnsVisibility).every(({ isVisible }) => isVisible);
  }, [columnsVisibility]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={onCancel}
      className="modal-columns"
      centered={true}
    >
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Display Columns</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <div className="modal-body">
        {Object.entries(columnsVisibility).map(([key, value]) => {
          const { label, isVisible, classes } = value;
          return (
            <div
              key={key}
              className={` custom-control custom-checkbox mb-3 ${
                classes ? classes : ''
              }`}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id={`customControlInline${key}`}
                checked={isVisible}
                onChange={() => handleUpdateColumnsVisible(key)}
              />
              <label
                className="custom-control-label"
                htmlFor={`customControlInline${key}`}
              >
                {label}
              </label>
            </div>
          );
        })}
      </div>
      <div className="modal-footer d-block">
        <Row>
          <Col sm='5' className="d-flex align-items-center mb-3 mb-sm-0">
            {isSelectAll ? (
              <p
                className="mb-0 modal-text"
                onClick={() => handleSelectAll(false)}
              >
                Unselect All
              </p>
            ) : (
              <p
                className="mb-0 modal-text"
                onClick={() => handleSelectAll(true)}
              >
                Select All
              </p>
            )}
          </Col>
          <Col sm='7'>
            <button
              type="button"
              disabled={isDisabled}
              className="btn w-100 btn-edit w-md waves-effect waves-light pl-3 pr-3"
              onClick={handleSaveColumns}
            >
              Save
            </button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default DisplayColumnsModal;
