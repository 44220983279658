import {
  UPDATE_COMPLETION_PROGESS,
  CLOSE_COMPLETION_PROGESS,
} from './actionTypes';

export const updateCompletion = () => {
  return {
    type: UPDATE_COMPLETION_PROGESS,
  };
};

export const closeCompletion = () => {
  return {
    type: CLOSE_COMPLETION_PROGESS,
  };
};
