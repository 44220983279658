import React from 'react';
import CustomTooltip from '../../tooltips/CustomTooltip';
import TableExportLogs from '../TableExportLogs';

const TableOrganizationSettings = ({
  downloadCSV,
  manageColumns,
  isOpenExportLogs,
  handleOpenExportLogs,
}) => {
  return (
    <>
      <TableExportLogs
        isOpenExportLogs={isOpenExportLogs}
        handleOpenExportLogs={handleOpenExportLogs}
      />
      <div
        className="file-container mb-2"
        id="Tooltip-columns"
        onClick={manageColumns}
      >
        <i className="bx bxs-cog setting"></i>
        <span className="setting-text">Manage columns</span>
      </div>
      <CustomTooltip
        id="downloadCSVFile"
        className="d-flex align-items-center filter-container d-cursor mr-3 mb-2"
        onClick={downloadCSV}
        text="Click to export data from the table in a CSV file. If you apply any filters, only data matching the filter criteria will be exported. If you select particular records, only the selected records will be exported. Otherwise, whole data will be exported"
      >
        <i className="font-size-20 bx bx-table mr-2"></i>
        <span>CSV file</span>
      </CustomTooltip>
    </>
  );
};

export default TableOrganizationSettings;
