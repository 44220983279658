import React, { useState, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Card, CardBody, UncontrolledTooltip } from 'reactstrap';
import {
  createDocumentList,
  deleteDocumentType,
  getDocumentsList,
} from '../../../utils/backend-helper';
import { toast } from 'react-toastify';

import Index from '../../Button';
import DocumentCreateModal from '../../../modals/DocumentCreate';
import DocumentTypeDeleteModal from '../../../modals/DocumentTypeDelete';
import { MAX_LENGTH_TO_VIEW_NAME } from '../../../constants/helper';
import MessageResetSuccess from '../../ToastSuccess';
import {
  SUCCESS_DOCUMENT_TYPE_CREATE,
  SUCCESS_DOCUMENT_TYPE_DELETE,
} from '../../../constants/messages';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const SettingDocumentType = () => {
  const { id: organizationId } = useRouteMatch().params;
  const onError = useErrorHandler();

  const [documents, setDocuments] = useState([]);

  const getDocumentsHandler = useCallback(
    (organizationId) => {
      getDocumentsList(organizationId)
        .then((result) => {
          let { data } = result;
          const emptyData = [];

          data = (data || emptyData).sort((firstDocument, secondDocument) => {
            return firstDocument.name.localeCompare(secondDocument.name);
          });

          setDocuments(data);
        })
        .catch(onError);
    },
    [onError],
  );

  const updatingTable = (success) => {
    if (success) {
      getDocumentsHandler(organizationId);
    }
  };

  useEffect(() => {
    getDocumentsHandler(organizationId);
  }, [getDocumentsHandler, organizationId]);

  const [createNewModal, setCreateNewModal] = useState({
    isOpen: false,
    organizationId: null,
  });

  const handleCreateNewModal = (organizationId) => {
    setCreateNewModal((prev) => ({
      isOpen: !prev.isOpen,
      organizationId,
    }));
  };

  const [deleteDocumentModal, setDeleteDocumentModal] = useState({
    isOpen: false,
    values: {
      organizationId: null,
      doctypeId: null,
      name: '',
    },
  });

  const handleDeleteDocumentModal = (organizationId, doctypeId, name) => {
    setDeleteDocumentModal((prev) => ({
      isOpen: !prev.isOpen,
      values: { organizationId, doctypeId, name },
    }));
  };

  return (
    <Card className="mb-2 h-100">
      <CardBody className="document">
        <div className="document-header">
          <h4 className="document-title d-flex align-items-center mb-0">
            Document Types
            <i
              className="bx bx-info-circle setting-icon"
              id="tooltip-setting-document"
            ></i>
            <UncontrolledTooltip
              placement="top-start"
              id="tooltip-accordion"
              target="tooltip-setting-document"
            >
              <span>
                The Documents Types created here will be available for documents
                creation in the current Organization
              </span>
            </UncontrolledTooltip>
          </h4>
          {!!documents.length && (
            <Index
              color="btn btn-create btn-end"
              handler={() => handleCreateNewModal(organizationId)}
            >
              + Add New
            </Index>
          )}
        </div>
        {documents.length ? (
          <div className="document-list">
            {documents.map(({ name, id }) => {
              return (
                <div key={id} className="document-list__item">
                  <div className="document-inner">
                    <p id={'Tooltip-name' + id}>{name}</p>
                    {name.length > MAX_LENGTH_TO_VIEW_NAME && (
                      <UncontrolledTooltip
                        placement="top"
                        id="tooltip-text"
                        target={'Tooltip-name' + id}
                      >
                        <span className="send">{name}</span>
                      </UncontrolledTooltip>
                    )}
                    <i
                      className="bx bxs-trash-alt"
                      onClick={() =>
                        handleDeleteDocumentModal(organizationId, id, name)
                      }
                    ></i>
                  </div>
                  <hr />
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <hr />
            <div className="document-empty">
              <p className="document-empty__title">No Documents Types yet</p>
              <Index
                color="btn btn-create btn-create_lg"
                handler={() => handleCreateNewModal(organizationId)}
              >
                + Create
              </Index>
            </div>
          </>
        )}
      </CardBody>
      <DocumentCreateModal
        onSubmit={({ name, organizationId }, callback) => {
          createDocumentList(name, organizationId)
            .then(() => {
              updatingTable(true);
              toast.success(
                <MessageResetSuccess message={SUCCESS_DOCUMENT_TYPE_CREATE} />,
              );
              callback();
              setCreateNewModal((prev) => ({ ...prev, isOpen: false }));
            })
            .catch(onError);
        }}
        onCancel={() =>
          setCreateNewModal((prev) => ({ ...prev, isOpen: false }))
        }
        isOpen={createNewModal.isOpen}
        organizationId={createNewModal.organizationId}
      />
      <DocumentTypeDeleteModal
        onSubmit={(_, { id, doctypeId }) => {
          deleteDocumentType(id, doctypeId)
            .then(() => {
              updatingTable(true);
              toast.success(
                <MessageResetSuccess message={SUCCESS_DOCUMENT_TYPE_DELETE} />,
              );
              setDeleteDocumentModal((prev) => ({ ...prev, isOpen: false }));
            })
            .catch(onError);
        }}
        onCancel={() =>
          setDeleteDocumentModal((prev) => ({ ...prev, isOpen: false }))
        }
        isOpen={deleteDocumentModal.isOpen}
        values={deleteDocumentModal.values}
      />
    </Card>
  );
};

export default SettingDocumentType;
