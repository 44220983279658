import React, { useRef } from 'react';
import { toast } from 'react-toastify';

import MessageResetSuccess from '../components/ToastSuccess';
import {
  ERROR_LOADING_DOCUMENT,
  LOADING_DOCUMENT,
  SUCCESS_LOADING_DOCUMENT,
} from '../constants/messages';
import { exportFile } from '../utils/backend-helper';
import { saveFileAs } from '../utils/fileSaver';

export const useDocumentExport = () => {
  const toastId = useRef(null);

  const downloadFile = (url, body, fileName) => {
    const getExportFile = exportFile(url);

    const notifyLoading = () =>
      (toastId.current = toast.success(
        <MessageResetSuccess message={LOADING_DOCUMENT} />,
        {
          autoClose: false,
        },
      ));
    notifyLoading();

    getExportFile(body, true)
      .then((data) => {
        saveFileAs(data, fileName);
        toast.dismiss(toastId.current);
        toast.success(
          <MessageResetSuccess message={SUCCESS_LOADING_DOCUMENT} />,
        );
      })
      .catch((error) => {
        toast.dismiss(toastId.current);
        toast.error(ERROR_LOADING_DOCUMENT);
      });
  };

  return { downloadFile };
};
