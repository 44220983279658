import React from 'react';
import { Container } from 'reactstrap';

import HelpContent from '../../../components/Help/Content';
import OrganizationTitle from '../../../components/organization/Title';

const HelpOrganizationPage = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <OrganizationTitle title="Help" />
        <HelpContent />
      </Container>
    </div>
  );
};

export default HelpOrganizationPage;
