import React from 'react';
import { Card, CardBody, Media, Row } from 'reactstrap';

import DashboardAvatar from './Avatar';
import DashboardButtons from './Buttons';

const DashboardHeader = () => {
  return (
    <Card>
      <CardBody>
        <Media body>
          <Row>
            <DashboardAvatar />
            <DashboardButtons />
          </Row>
        </Media>
      </CardBody>
    </Card>
  );
};

export default DashboardHeader;
