import React, { useState } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { keyUser } from '../../constants/api';

import { mergeStorage } from '../../utils/merge-storage-helper';
import { getDataUser } from '../../utils/get-store-user';
import { LocalStorage } from '../../utils/storage';
import { SessionStorage } from '../../utils/sessionStorage';

const storage = new LocalStorage();
const sessionStorage = new SessionStorage();

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  path,
  exact,
  organizationImage,
}) => {
  const isAuth = () => {
    return Boolean(storage.getItem(keyUser) || sessionStorage.getItem(keyUser));
  };

  const [user, updateUser] = useState(getDataUser());

  const handleUpdateUser = (newUser) => {
    mergeStorage(newUser);
    updateUser(getDataUser());
  };

  return (
    <Route
      path={path}
      render={(props) => {
        if (isAuth()) {
          return (
            <Layout organizationImage={organizationImage} user={user}>
              <Component
                {...props}
                user={user}
                handleUpdateUser={handleUpdateUser}
              />
            </Layout>
          );
        }
        return (
          <Redirect
            to={{ pathname: '/Login', state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export default withRouter(Authmiddleware);
