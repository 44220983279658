import { CLOSE_NOTIFICATION, UPDATE_NOTIFICATION } from './actionTypes';

const initialState = {
  successNotification: false,
};

const notification = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATION:
      state = {
        ...state,
        successNotification: true,
      };
      break;
    case CLOSE_NOTIFICATION:
      state = {
        ...state,
        successNotification: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default notification;
