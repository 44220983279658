import {
  ACTIONS_TYPE,
  DOCUMENTS_TYPE,
  HR_RECORDS_TYPE,
  TRAINING_RECORDS_TYPE,
} from '../constants/document';
import { LIMIT_SEARCH_SELECT } from '../constants/helper';

const createUserLabel = ({ firstName, lastName, email, role }) => {
  return `${firstName || ''} ${lastName || ''} ${email ? `(${email})` : ''} ${
    role ? '- ' + role : ''
  }`;
};

const createFullName = ({ fullName, email }) => {
  return `${fullName} ${email ? `(${email})` : ''}`;
};

export const configureGetDeleteOrganization = (data, currentOrganization) => {
  return [currentOrganization, ...data].reduce(
    (acc, { organizationName, organizationId, userStatus }) => {
      if (userStatus) {
        acc.push({
          label: organizationName,
          value: organizationName,
          id: organizationId,
        });
      }
      return acc;
    },
    [],
  );
};

export const configureGetActiveOrganization = (
  data,
  currentOrganization,
  isActive,
) => {
  return [currentOrganization, ...data].reduce(
    (acc, { organizationName, organizationId, userStatus }) => {
      if (isActive === userStatus) {
        acc.push({
          label: organizationName,
          value: organizationName,
          id: organizationId,
        });
      }
      return acc;
    },
    [],
  );
};

export const configureGetOrganization = (data, currentID = 0) => {
  return data.reduce((acc, { name, id }) => {
    if (currentID !== id) {
      acc.push({ label: name, value: name, id });
    }
    return acc;
  }, []);
};

export const configureGetAdmins = (data) => {
  return data.reduce((acc, user) => {
    acc.push({
      label: createFullName(user),
      value: createFullName(user),
      id: user.id,
    });
    return acc;
  }, []);
};

export const configureGetUsersGroup = (data) => {
  return data
    .reduce((acc, user) => {
      acc.push({
        label: createFullName(user),
        value: createFullName(user),
        user,
      });
      return acc;
    }, [])
    .slice(0, LIMIT_SEARCH_SELECT);
};

export const configureTrainings = (data) => {
  return [
    ...data.reduce((acc, { name, id }) => {
      acc.push({ label: name, value: name, id });
      return acc;
    }, []),
  ];
};

export const configureHumanResources = (data) => {
  return data.reduce((acc, user) => {
    const { id } = user;
    const template = createUserLabel(user);
    acc.push({ label: template, value: template, id, type: HR_RECORDS_TYPE });
    return acc;
  }, []);
};

export const configureTrainingsOptions = (data) => {
  const options = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const option = data[key];
      const { name, id } = option;
      options.push({
        label: name,
        value: name,
        id,
        type: TRAINING_RECORDS_TYPE,
      });
    }
  }
  return options;
};

export const configureTasksOptions = (data) => {
  const options = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const option = data[key];
      const { name, id, number, email } = option;
      const template = `${name} - system #${number} (assigned to ${email})`;
      options.push({
        label: template,
        value: template,
        id,
        type: ACTIONS_TYPE,
      });
    }
  }
  return options;
};

export const configureCurrentOrganization = ({ name, id }) => {
  return [
    {
      label: name,
      value: name,
      id,
    },
  ];
};

export const configureGetDocumentTypesMulti = (data) => {
  if (!data) return;
  return data.map(({ name, id }) => ({ label: name, value: name, id }));
};

export const configureGetDocumentTypes = (data) => {
  if (!data) return;
  return data.reduce((acc, { name, id }) => {
    acc.push({ label: name, value: name, id });
    return acc;
  }, []);
};

export const configureDocumentTypes = (data) => {
  return data.map(({ id, isFinal, name, revision, type, typeDescription }) => {
    const template = `${type} - ${typeDescription} ${name} Revision ${revision} ${
      isFinal ? '(final)' : ''
    }`;
    return {
      label: template,
      value: template,
      id,
      revision,
      type: DOCUMENTS_TYPE,
    };
  });
};

export const configureGetUsers = (data) => {
  return data.reduce((acc, user) => {
    const { id, isBlocked } = user;
    acc.push({
      label: createUserLabel(user),
      value: createUserLabel(user),
      id,
      isBlocked,
    });
    return acc;
  }, []);
};

export const configureGetTrainings = (data) => {
  return data.reduce((acc, { id, name }) => {
    acc.push({ label: name, value: name, id });
    return acc;
  }, []);
};

export const mergeUsersAndGroups = (groups, users) => {
  const groupsData = groups.reduce((acc, { id, name, userCount }) => {
    if (userCount) {
      acc.push({
        label: `User Group - ${name}`,
        value: name,
        id,
        group: 'groups',
      });
    }
    return acc;
  }, []);
  const usersData = users.reduce(
    (acc, { email, role, firstName, lastName, id }) => {
      acc.push({
        label: createUserLabel({ firstName, lastName, email, role }),
        value: createUserLabel({ firstName, lastName, email, role }),
        id,
        group: 'users',
      });
      return acc;
    },
    [],
  );
  return [...groupsData, ...usersData].slice(0, LIMIT_SEARCH_SELECT);
};

export const configTags = (data) => {
  if (!data) return;
  return data.map(({ name, id }) => {
    return { label: name, value: name, id };
  });
};
