import React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const NavigationItem = ({ text, activeTab, activedTab, onClick }) => {
  return (
    <NavItem>
      <NavLink
        className={classnames({ active: activeTab === activedTab })}
        onClick={onClick}
      >
        {text}
      </NavLink>
    </NavItem>
  );
};

export default NavigationItem;
