export const getCorrectQuestionList = (questions) => {
  return questions.map(({ answers }) => {
    return answers.every(
      ({ isCorrect, isSelected }) => isCorrect === isSelected,
    );
  });
};

export const isCorrectQuestions = (questions) =>
  questions.every((question) => question);

export const changeQuestion = (questions) => {
  return questions.map((question) => ({
    ...question,
    answers: question.answers.map((answer) => ({
      ...answer,
      isSelected: false,
    })),
  }));
};

export const isAllQuestionSelected = (questions) => {
  return questions.every(({ answers }) => {
    return answers.some(({ isSelected }) => isSelected);
  });
};
