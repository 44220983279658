export const updateCheckboxAnswer = (questions, index, answerId) => {
  return [
    ...questions.slice(0, index),
    {
      ...questions[index],
      answers: questions[index].answers.map((answer) => ({
        ...answer,
        isSelected: answer.id === answerId ? !answer.isSelected : false,
      })),
    },
    ...questions.slice(index + 1),
  ];
};
