import React from 'react';

import CardTitle from '../../shared/CardTitle';
import HelpTabContent from '../shared/TabContent';

import { mokDataNotes } from './Contents/mok-notes';

const HelpNotes = () => {
  return (
    <div>
      <CardTitle title="Notes about the Admin" />
      <HelpTabContent mokData={mokDataNotes} />
    </div>
  );
};

export default HelpNotes;
