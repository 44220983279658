import React from 'react';
import DocumentText from '../DocumentContent/DocumentText';
import HelpImage from '../../shared/HelpImage';

import { trainingRecordTexts } from './training-record-mok';
import trainingImage from '../../../../assets/images/help-screen/flow/flow-17.png';
import trainingImage2 from '../../../../assets/images/help-screen/flow/flow-18.png';
import trainingImage3 from '../../../../assets/images/help-screen/flow/flow-19.png';
import trainingImage4 from '../../../../assets/images/help-screen/flow/flow-20.png';

const TrainingRecordContent = () => {
  return (
    <>
      <p>
        A Training Record is an artifact that allows the Organization to track
        training records for Users.
      </p>
      <HelpImage src={trainingImage} />
      <p>
        In order for a user to access Training Records, they must have at least
        one setting for access to Training Records turned on through their User
        Group access permissions, which are controlled by Admins.
      </p>
      <HelpImage src={trainingImage2} />
      <p>
        Training records can be added when a Document is approved (see above) or
        can be added manually at any time. On the “Training Records” screen,
        users may have the “Add New” button if adding trainings is allowed by
        their access permissions.
      </p>
      <HelpImage src={trainingImage3} />
      <p>
        When a new training is created, all required fields, which are marked
        with asterisks, must be filled.
      </p>
      <HelpImage src={trainingImage4} />
      <div className="help-document-content">
        <div className="help-document-content__list">
          {trainingRecordTexts.map((props, index) => (
            <DocumentText key={index} {...props} />
          ))}
        </div>
      </div>
    </>
  );
};

export default TrainingRecordContent;
