import React from 'react';
import { Col, Row } from 'reactstrap';

const OrganizationTitle = ({ title }) => {
  return (
    <Row>
      <Col className="page-title-box">
        <h4 className="mb-0 font-size-18">{title}</h4>
      </Col>
    </Row>
  );
};

export default OrganizationTitle;
