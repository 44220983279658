import { keyAuth, keySession } from '../constants/api';
import { storages } from '../constants/storages';
import { LocalStorage } from './storage';

const storage = new LocalStorage();

export const getCurrentTokens = () => {
  const currentStorage = storage.getItem(keySession);
  if (!currentStorage) return {};

  return storages[currentStorage].getItem(keyAuth) || {};
};

export const updateTokens = (data) => {
  const currentStorage = storage.getItem(keySession);
  if (!currentStorage) return {};

  storages[currentStorage].setItem(keyAuth, data);
};
