import React from 'react';

import ButtonUploadPhoto from '../ButtonUploadPhoto';
import ButtonDeletePhoto from '../ButtonDeletePhoto';

const ButtonsPhoto = ({ avatar, handleUpdateUser }) => {
  return (
    <div className="d-flex">
      <ButtonUploadPhoto
        text={avatar ? 'Change photo' : 'Upload photo'}
        handleUpdateUser={handleUpdateUser}
      />
      {avatar && <ButtonDeletePhoto handleUpdateUser={handleUpdateUser} />}
    </div>
  );
};

export default ButtonsPhoto;
