export const mergetParams = (fields) => {
  const { orderBy, orderType, ...other } = fields;
  const params = {};

  if (fields.orderBy) {
    params['Order.Key'] = fields.orderBy;
  }

  if (fields.orderType) {
    params['Order.Direction'] = fields.orderType;
  }

  return { ...params, ...other };
};
