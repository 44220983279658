import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';

const Accordion = ({ title, children }) => {
  const [isOpen, updateIsOpen] = useState(false);

  const handleOpen = () => updateIsOpen((isOpen) => !isOpen);

  return (
    <>
      <div className="accordion-custom" onClick={handleOpen}>
        <div className="accordion-inner">
          <div className="accordion-inner_left">
            <i className={classnames('bx bx-help-circle icon-help font-size-18 mr-2', {
              'green-arrow': isOpen,
            })}
            ></i>
            {title}
          </div>
          <i
            className={classnames('bx bx-chevron-down arrow', {
              downArrow: isOpen,
            })}
          ></i>
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="accordion-collapse-content">{children}</div>
      </Collapse>
    </>
  );
};

export default Accordion;
