import React from 'react';
import { USER_ROLE } from '../../../../constants/helper';
import { getDataUser } from '../../../../utils/get-store-user';

const ReferencesLinkEdit = ({
  links,
  handleDelete,
  isHumanResources = false,
}) => {
  const { role } = getDataUser();

  return (
    <>
      <div className="references-container">
        <ul>
          {links.map(({ link, linkDescription, id }) => {
            return (
              <li key={id} className="ref-link-item">
                <>
                  {isHumanResources && role === USER_ROLE ? (
                    <p>{linkDescription}</p>
                  ) : (
                    <a target="_self" href={link}>
                      <i className="bx bx-link"></i>
                      {linkDescription}
                    </a>
                  )}
                  <i
                    className="bx bxs-trash-alt"
                    onClick={() => handleDelete(id)}
                  ></i>
                </>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default ReferencesLinkEdit;
