import React from 'react';
import { ACTIVE, INACTIVE } from '../../../constants/modals';
import IconTooltip from '../IconTooltip';

const TooltipAction = ({
  status,
  id,
  email,
  routeId,
  handleSendEmail,
  handleDeleteAdmin,
  organizationName,
  firstName,
  lastName,
  organizationUserStatuses,
  emailConfirmed,
}) => {
  if (status === ACTIVE && !emailConfirmed) {
    return (
      <IconTooltip
        icon="bx bxs-send action"
        id={`UsersSend${id}`}
        text="Resend Invite"
        onClick={() => handleSendEmail(email)}
      />
    );
  }

  if (status === INACTIVE && !emailConfirmed) {
    return (
      <IconTooltip
        icon="bx bxs-trash-alt action"
        id={`UsersDelete${id}`}
        text="Delete"
        onClick={() =>
          handleDeleteAdmin(
            id,
            routeId,
            firstName,
            lastName,
            email,
            organizationName,
            organizationUserStatuses,
          )
        }
      />
    );
  }

  return null;
};

export default TooltipAction;
