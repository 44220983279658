import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { useRouteMatch } from 'react-router-dom';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

import {
  getQualityTask,
  getDocumentUsers,
  editQuality,
} from '../../../utils/backend-helper';
import QualityReviewer from '../../QualityReviwer';
import Button from '../../Button';
import { qualityStyles } from '../../../utils/select-styles';
import {
  DEBOUNCE_TIMER,
  APPROVERS,
  REVIEWERS,
  REVISION_PARAM,
  DOCUMENT_EDIT_MODE,
} from '../../../constants/helper';
import { changeFieldSelect } from '../../../utils/selector-helper-changed';
import { configureGetUsers } from '../../../utils/config-get-organization';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import MessageResetSuccess from '../../ToastSuccess';
import { SUCCESS_DOCUMENT_EDIT } from '../../../constants/messages';
import { uniqueData } from '../../../utils/unique-data';
import { configRevisionRequest } from '../../../utils/documents/config-body-revision';
import { useQueryHelper } from '../../../utils/query-params-helper';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';
import RevisionAddQuality from '../DocumentRevision/RevisionAddQuality';
import RequiresTrainingView from '../RequiresTraining/View/View';
import RequiresTraining from '../../../pages/Organization/DocumentAdd/RequiresTraining';
import RequiresTrainingEdit from '../RequiresTraining/Edit';

const DocumentQuality = ({
  currentRevision,
  revision,
  updateCurrentRevision,
  revisionAddMode,
  rUpdateModel,
}) => {
  const { revision: revisionParams } = useQueryHelper([REVISION_PARAM]);

  const [isChecked, updateIsChecked] = useState(false);
  const [editMode, updateEditMode] = useState(false);
  const [reviewerIds, updateReviewerIds] = useState([]);
  const [approverIds, updateApproverIds] = useState([]);

  const [model, updateModel] = useState({
    reviewerIds: [],
    approverIds: [],
  });

  const [qualityData, updateQualityData] = useState({
    canBeEdited: false,
    canEditTraining: null,
    reviewers: [],
    approvers: [],
    trainingRecord: {},
  });

  const debounceReviewers = debounce((inputValue, callback) => {
    getDocumentUsers(organizationId, inputValue).then(({ data }) =>
      callback(configureGetUsers(data)),
    );
  }, DEBOUNCE_TIMER);

  const debounceApprovers = debounce((inputValue, callback) => {
    getDocumentUsers(organizationId, inputValue).then(({ data }) =>
      callback(configureGetUsers(data)),
    );
  }, DEBOUNCE_TIMER);

  const handleReviewersChange = (user) => {
    updateReviewerIds(changeFieldSelect(user));
  };

  const handleApproversChange = (user) => {
    updateApproverIds(changeFieldSelect(user));
  };

  const { canBeEdited, reviewers, approvers, trainingRecord, canEditTraining } =
    qualityData;

  const organizationId = useRouteMatch().params.id;
  const documentId = useRouteMatch().params.documentId;

  useEffect(() => {
    const revison = currentRevision.value || revisionParams;
    const params = configRevisionRequest({ organizationId }, revison);
    getQualityTask(documentId, params).then(({ data }) => {
      updateQualityData(data);
      updateIsChecked(data.requiresTraining);
    });
  }, [documentId, organizationId, currentRevision, revisionParams]);

  const onError = useErrorHandler();

  useEffect(() => {
    const [reviewers, approvers] = [REVIEWERS, APPROVERS].map((key) =>
      qualityData[key].map((item) => item.id),
    );

    updateModel({
      reviewerIds: uniqueData([...reviewers, ...reviewerIds]),
      approverIds: uniqueData([...approvers, ...approverIds]),
    });
  }, [
    reviewerIds,
    approverIds,
    qualityData.reviewers,
    qualityData.approvers,
    qualityData,
  ]);

  const handleSubmit = (_, values) => {
    const { id, ...body } = values;

    editQuality(id, body)
      .then(({ data }) => {
        updateQualityData(data);
        updateEditMode(false);
        updateCurrentRevision((prev) => ({ ...prev }));
        toast.success(<MessageResetSuccess message={SUCCESS_DOCUMENT_EDIT} />);
      })
      .catch(onError);
  };

  const handleDelete = (id, key) => {
    const currentId = qualityData[key].findIndex((item) => item.id === id);

    updateQualityData((prev) => ({
      ...prev,
      [key]: prev[key].filter((_, i) => currentId !== i),
    }));
  };

  if (revisionAddMode) {
    return (
      <RevisionAddQuality
        usersReviewer={reviewers}
        usersApprover={approvers}
        updateModel={rUpdateModel}
      />
    );
  }

  return (
    <div className="general">
      <div className="general-header">
        <h6>{editMode ? 'Edit Quality Actions' : 'Quality Actions'}</h6>
        {(canBeEdited || canEditTraining) && !editMode && (
          <button
            className="general__btn-edit"
            onClick={() => updateEditMode((prev) => !prev)}
          >
            <i className="bx bxs-edit-alt"></i> Edit
          </button>
        )}
      </div>
      <hr />
      {editMode ? (
        <AvForm className="form-horizontal mt-2" onValidSubmit={handleSubmit}>
          {!canEditTraining && (
            <div className="quality-assign">
              <Row>
                <Col>
                  <div className="info-column">
                    <p className="info-column__title">Assign Reviewer(s)</p>
                    <FormGroup className="select2-container">
                      <AsyncSelect
                        styles={qualityStyles}
                        isMulti={true}
                        cacheOptions={false}
                        defaultOptions
                        onChange={(value) => handleReviewersChange(value)}
                        loadOptions={debounceReviewers}
                        placeholder="Assign new reviewer(s)"
                      />
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="quality-edit-reviewer">
                <Col>
                  <div className="info-column">
                    <p className="info-column__title">Assigned Reviewer(s)</p>
                    {qualityData.reviewers.map(
                      ({ id, firstName, lastName, email, role }, i, arr) => {
                        return (
                          <React.Fragment key={`reviewers-${id}`}>
                            <Row className="quality-item">
                              <Col xs="4">
                                <p>{`${firstName || ''} ${lastName || ''}`}</p>
                              </Col>
                              <Col xs="4">
                                <p>{email}</p>
                              </Col>
                              <Col xs="4">
                                <div className="role-block">
                                  <p className="info-column__text info-column_right">
                                    {role || ''}
                                  </p>
                                  <i
                                    className="bx bxs-trash-alt"
                                    onClick={() => handleDelete(id, REVIEWERS)}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                            {i !== arr.length - 1 && <hr />}
                          </React.Fragment>
                        );
                      },
                    )}
                  </div>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <div className="info-column">
                    <p className="info-column__title">Assign Approver(s)</p>
                    <FormGroup className="select2-container">
                      <AsyncSelect
                        styles={qualityStyles}
                        isMulti={true}
                        cacheOptions={false}
                        defaultOptions
                        onChange={(value) => handleApproversChange(value)}
                        loadOptions={debounceApprovers}
                        placeholder="Assign new approver(s)"
                      />
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="quality-edit-reviewer">
                <Col>
                  <div className="info-column">
                    <p className="info-column__title">Assigned Approver(s)</p>
                    {qualityData.approvers.map(
                      ({ id, firstName, lastName, email, role }, i, arr) => {
                        return (
                          <React.Fragment key={`approvers-${id}`}>
                            <Row className="quality-item">
                              <Col xs="4">
                                <p>{`${firstName || ''} ${lastName || ''}`}</p>
                              </Col>
                              <Col xs="4">
                                <p>{email}</p>
                              </Col>
                              <Col xs="4">
                                <div className="role-block">
                                  <p className="info-column__text info-column_right">
                                    {role || ''}
                                  </p>
                                  <i
                                    className="bx bxs-trash-alt"
                                    onClick={() => handleDelete(id, APPROVERS)}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                            {i !== arr.length - 1 && <hr />}
                          </React.Fragment>
                        );
                      },
                    )}
                  </div>
                </Col>
              </Row>
              <hr />
            </div>
          )}
          <div className="quality-assign">
            {canEditTraining ? (
              <RequiresTraining
                trainingRecord={trainingRecord}
                mode={DOCUMENT_EDIT_MODE}
                isChecked={isChecked}
                updateIsChecked={updateIsChecked}
                model={model}
                updateModel={updateModel}
                latestRevision={currentRevision.value || revision}
              />
            ) : (
              <RequiresTrainingEdit
                isChecked={isChecked}
                handleChecked={() => updateIsChecked((isChecked) => !isChecked)}
                disabled={true}
              />
            )}
          </div>
          <Row className="form-group mt-4">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
                handler={() => updateEditMode(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
              >
                Save Changes
              </Button>
            </Col>
          </Row>
          <AvField
            name="organizationId"
            type="text"
            value={organizationId}
            className="btn-hidden"
          />
          <AvField
            name="reviewerIds"
            type="text"
            value={model.reviewerIds}
            className="btn-hidden"
          />
          <AvField
            name="approverIds"
            type="text"
            value={model.approverIds}
            className="btn-hidden"
          />
          <AvField
            name="id"
            type="text"
            value={documentId}
            className="btn-hidden"
          />
          <AvField
            name="revision"
            type="text"
            value={currentRevision.value || revision}
            className="btn-hidden"
          />
          {isChecked && (
            <AvField
              name="trainingRecordId"
              type="hidden"
              value={model.trainingRecordId || ''}
            />
          )}
          <AvField
            name="requiresTraining"
            type="hidden"
            value={`${isChecked}`}
          />
        </AvForm>
      ) : (
        <div className="quality-assign">
          <Row>
            <Col>
              <div className="info-column">
                <p className="info-column__title">Assign Reviewer(s):</p>
                <QualityReviewer
                  users={reviewers}
                  placeholderText="No reviewers."
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col>
              <div className="info-column">
                <p className="info-column__title">Assign Approver(s):</p>
                <QualityReviewer
                  users={approvers}
                  placeholderText="No approvers."
                />
              </div>
            </Col>
          </Row>
          <RequiresTrainingView
            name={trainingRecord?.name}
            id={trainingRecord?.id}
          />
        </div>
      )}
    </div>
  );
};

export default DocumentQuality;
