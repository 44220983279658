import React, { useState, useEffect } from 'react';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';

import MessageResetSuccess from '../../../../../components/ToastSuccess';
import Button from '../../../../../components/Button';
import TagsListEdit from '../../../../../components/shared/TaskListEdit';
import DocumentAttached from '../../../../../components/document/DocumentAttached';

import { EditHumanResourcesModel } from '../../../../../models/human-resources/human-resources-edit';
import { editHumanResourcesDetails } from '../../../../../utils/api/human-resources-api';
import { SUCCESS_RECORD_EDIT } from '../../../../../constants/messages';
import { getSingleFile } from '../../../../../utils/training-records-helper/get-files-ids';
import { getFilteredData } from '../../../../../utils/shared/get-filtered-data';
import { getUnionArrayIds } from '../../../../../utils/shared/get-union-array';
import { changeFileTrainingRecords } from '../../../../../utils/file-attached/change-file-training-records';
import { useErrorHandler } from '../../../../../custom-hooks/useErrorHandler';

const HumanResourcesEdit = ({
  updateEditMode,
  updateHumanResourcesModel,
  humanResourcesModel,
  organizationId,
  resourceId,
}) => {
  const [model, updateModel] = useState(EditHumanResourcesModel);
  const onError = useErrorHandler();

  const { firstName, lastName } = humanResourcesModel;

  useEffect(() => {
    updateModel((prev) => ({
      ...prev,
      files: {
        ...prev.files,
        value: humanResourcesModel.file ? [humanResourcesModel.file] : [],
      },
      tags: {
        ...prev.tags,
        value: humanResourcesModel.tags || [],
      },
    }));
  }, [humanResourcesModel.file, humanResourcesModel.tags]);

  const handleSubmit = (_, values) => {
    const { id, ...body } = values;

    editHumanResourcesDetails(id, body)
      .then(({ data }) => {
        updateHumanResourcesModel(data);
        toast.success(<MessageResetSuccess message={SUCCESS_RECORD_EDIT} />);
        updateEditMode(false);
      })
      .catch(onError);
  };

  const handleModelChangeKey = (modelKey, value) => {
    updateModel((prev) => ({
      ...prev,
      [modelKey]: { ...prev[modelKey], value },
    }));
  };

  return (
    <>
      <div className="general-header">
        <h6 className="mt-2">
          {firstName} {lastName} Record
        </h6>
      </div>
      <hr />
      <AvForm className="form-horizontal mt-2" onValidSubmit={handleSubmit}>
        <TagsListEdit
          tags={model.tags.value}
          onChange={(values) => handleModelChangeKey('tagIds', values)}
          handleRemoveExist={(deletedId) =>
            handleModelChangeKey(
              'tags',
              getFilteredData(model.tags.value, 'id', deletedId),
            )
          }
        />
        <hr />
        <DocumentAttached
          text="File(s) attached"
          file={model.files.value[0]}
          handleUploadFile={(data) => {
            updateModel((model) =>
              changeFileTrainingRecords(model, [...model.files.value, data]),
            );
          }}
          onDeleteFile={() => {
            updateModel((model) => changeFileTrainingRecords(model, []));
          }}
        />
        <Row className="form-group mt-3">
          <Col className="text-right">
            <Button
              type="button"
              color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
              handler={() => updateEditMode(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
            >
              Save Changes
            </Button>
          </Col>
        </Row>
        <AvField
          name="organizationId"
          type="text"
          value={organizationId}
          className="btn-hidden"
        />
        <AvField
          name="id"
          type="text"
          value={resourceId}
          className="btn-hidden"
        />
        <AvField
          name="fileId"
          type="text"
          value={getSingleFile(model.files.value)}
          className="btn-hidden"
        />
        <AvField
          name="tagIds"
          type="hidden"
          value={getUnionArrayIds([...model.tags.value, ...model.tagIds.value])}
        />
      </AvForm>
    </>
  );
};

export default HumanResourcesEdit;
