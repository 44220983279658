import React, { useState } from 'react';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { get } from 'lodash';

import { AvForm, AvField } from 'availity-reactstrap-validation';
import { loginUser, apiError } from '../../../store/actions';
import logo from '../../../assets/images/profile/logo-q-suite-white.svg';
import { useEmptyForm } from '../useEmptyForm';

const Login = ({ loginUser, error, location }) => {
  const formInputs = ['email', 'password'];

  const pathname = get(location, 'state.from.pathname', null);

  const history = useHistory();
  const [isRemember, setIsRemember] = useState(false);
  const { disabled, onChange, values } = useEmptyForm(formInputs);

  const handleCheckbox = () => {
    setIsRemember((isChecked) => !isChecked);
  };

  const handleValidSubmit = (_, values, pathname) => {
    loginUser(values, history, isRemember, pathname);
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-12">
                      <div className="text-primary text-center login-header">
                        <div className="logo-container">
                          <img src={logo} alt="logo"></img>
                        </div>
                        <p>Sign in to access your system</p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="pt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) =>
                        handleValidSubmit(e, v, pathname)
                      }
                    >
                      {error && <Alert color="danger">{error}</Alert>}
                      <div className="form-group">
                        <AvField
                          name="email"
                          type="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          value={values.email}
                          onChange={onChange}
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          placeholder="Enter Password"
                          value={values.password}
                          onChange={onChange}
                        />
                      </div>
                      <div className="custom-control custom-checkbox">
                        <input
                          checked={isRemember}
                          type="checkbox"
                          onChange={handleCheckbox}
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="mt-3">
                        <button
                          disabled={disabled}
                          className="btn btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-password">
                          Forgot password
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = ({ Login: { error } }) => ({ error });

export default connect(mapStatetoProps, { loginUser, apiError })(Login);
