import React from 'react';
import UserAccessNotesContent from './UserAccess';
import UserGroupNotesContent from './UserGroups';

import HelpImage from '../../shared/HelpImage';

import accessImage from '../../../../assets/images/help-screen/admin/im_Documents Copy@3x.png';
import accessImage2 from '../../../../assets/images/help-screen/admin/im_Documents Copy 2@3x.png'
import userImage from '../../../../assets/images/help-screen/admin/im_Users & User Groups1@3x.png';
import userImage2 from '../../../../assets/images/help-screen/admin/im_Users & User Groups_2@3x.png';

import onboardingImage from '../../../../assets/images/help-screen/admin/im_User onboarding@3x.png';
import uniquenessImage from '../../../../assets/images/help-screen/admin/im_Email Uniqueness @3x.png';
import userStatusImage from '../../../../assets/images/help-screen/admin/im_User status@3x.png';

import activationImage from '../../../../assets/images/help-screen/admin/im_Activation Copy@3x.png';
import activationImage2 from '../../../../assets/images/help-screen/admin/im_Activation@3x.png';

import generateCodeImage from '../../../../assets/images/help-screen/admin/im_Access Code @3x.png';

import accessTrainingImage1 from '../../../../assets/images/help-screen/admin/im_Users, User Groups and their Training Records@3x.png';
import accessTrainingImage2 from '../../../../assets/images/help-screen/admin/im_Users, User Groups and their Training Records Copy@3x.png';
import accessTrainingImage3 from '../../../../assets/images/help-screen/admin/im_Users, User Groups and their Training Records Copy 2@3x.png';

export const mokDataNotes = [
  {
    title: 'Access Permissions',
    content: (
      <>
        <p>
          Admins have full access permissions inside the Organizations which they
          are included to, except an Admin cannot see and cannot manage Admins.
        </p>
        <HelpImage src={accessImage} />
        <HelpImage src={accessImage2} />
      </>      
    ),
  },
  {
    title: 'Users & User Groups',
    content: (
      <>
        <p>Admins can onboard Users and manage User Groups.</p>
        <p>Click 1 User Group to view its details.</p>
        <HelpImage src={userImage} />
        <HelpImage src={userImage2} />
      </>
    ),
  },
  {
    title: 'User Groups',
    content: <UserGroupNotesContent />,
  },
  {
    title: 'User onboarding',
    content: (
      <>
        <p>
          To onboard the same User with the same email address into 2+
          Organizations, create this User in one of the Organizations, then use
          the “Add Existing” option for Users in other Organizations. The “Add an
          Existing User” pop-up allows the user to select Organization(s), then
          User(s) in the Organization(s) selected and add the existing User(s)
          into the current Organization.
        </p>
        <HelpImage src={onboardingImage} />
      </>
    ),
  },
  {
    title: 'Email Uniqueness',
    content: (
      <>
        <p>
          The system does not allow Admins to onboard more than 1 User at a time.
          The same email can be used only 1 time, only for one user role.
        </p>
        <HelpImage src={uniquenessImage} />
      </>      
    ),
  },
  {
    title: 'User status',
    content: (
      <>
        <p>
          Users are set Inactive by default. This means they do not have access to
          the system until they are Set Active.
        </p>
        <HelpImage src={userStatusImage} />
      </>      
    ),
  },
  {
    title: 'Activation',
    content:
    (
      <>
        <p>
          When a User is “Set Active” for the first time, a welcome email is sent. If the User does not set up their password using the link in the welcome email, their invite can be resent using the buttons on the right column of the User table. This action is allowed not more than 5 times every 24h. This feature is useful if a user does not receive the welcome email (which they could delete by mistake, lose it as spam, or similar).
        </p>
        <HelpImage src={activationImage2} />
        <HelpImage src={activationImage} />
      </>
    )
  },
  {
    title: 'Access Code',
    content: (
      <>
        <p>
          After a User has activated their account by logging in, do not forget
          to click “Generate Access Code” for a new User.
        </p>
        <p>
          This action sends them an email with access code which they will need
          for Documents Approval. The access code is not linked to an
          Organization, it is the same for that particular User, no matter the
          Organization(s). After each time the access code is generated, the
          system generates the new access code automatically every 12 months.
          When a new access code is generated manually or automatically, the
          previous one becomes inactive. Users will be required to enter their
          access code when approving Documents in Q Suite.
        </p>
        <HelpImage src={generateCodeImage} />        
      </>
    ),
  },
  {
    title: 'User Access Permissions',
    content: <UserAccessNotesContent />,
  },
  {
    title: 'Users, User Groups and their Training Records',
    content: (
      <>
        <p>
          When a User is added into a User Group that has Training(s) assigned, it
          is possible to select which Training(s) should be assigned to the new
          User. 
        </p>
        <HelpImage src={accessTrainingImage1} />
        <HelpImage src={accessTrainingImage2} />
        <p>
          When a User is removed from such a User Group, it is possible to
          select which Training(s) should be or not un-assigned from the User
          removed. Only not yet passed by this User Trainings, that are assigned
          to the User Group and this User, can be selected to un-assign.
        </p>
        <HelpImage src={accessTrainingImage3} />
      </>      
    ),
  },
];
