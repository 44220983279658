import React from 'react';
import PlaceholderText from '../../../../../components/shared/PlaceholderText';

const PendingTrainingList = ({ pendingUsers }) => {
  return (
    <div className="completion-section">
      <h6 className="completion-section__title">Pending Training:</h6>
      <ul className="completion-list">
        {pendingUsers.map(({ id, firstName, lastName, email, role }) => {
          return (
            <li key={id}>
              <p>
                {firstName} {lastName} - {email} {role ? `-${role}` : ''}
              </p>
              <hr />
            </li>
          );
        })}
      </ul>
      {!pendingUsers?.length && (
        <PlaceholderText text="No assignees are pending." />
      )}
    </div>
  );
};

export default PendingTrainingList;
