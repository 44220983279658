import React from 'react';
import { Container } from 'reactstrap';

import HelpContent from '../../components/Help/Content';
import OrganizationTitle from '../../components/organization/Title';

const HelpPage = () => {
  return (
    <Container fluid>
      <OrganizationTitle title="Help" />
      <HelpContent />
    </Container>
  );
};

export default HelpPage;
