import React from 'react';
import classnames from 'classnames';

const Checkbox = ({ id, isChecked, onChange, disabled, name }) => {
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        name={name}
        disabled={disabled}
        checked={isChecked}
        onChange={onChange}
        id={`custom-checkbox-${id}`}
      />
      <label
        className={classnames('custom-control-label', { cursor: !disabled })}
        htmlFor={`custom-checkbox-${id}`}
      ></label>
    </div>
  );
};

export default Checkbox;
