import {
  ORGANIZATIONS,
  SUCCESS_ORGANIZATION,
  CREATE_ORGANIZATION,
  SUCCESS_CREATE_ORGANIZATION,
  CLEAR_CREATE_SUCCESS,
  TOOGLE_MANAGE_BY_CLIENT,
  TOOGLE_MANAGE_SUCCESS,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_SUCCESS,
  SET_STATUS,
  SET_STATUS_SUCCESS,
  CLEAR_DELETE_SUCCESS,
  CLEAR_SET_STATUS,
} from './actionTypes';

const initialState = {
  items: null,
  creatingSuccess: null,
  deletingSuccess: null,
  updateStatusSuccess: null,
  editSuccess: null,
};

const organization = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATIONS:
      state = { ...state };
      break;
    case SUCCESS_ORGANIZATION:
      state = {
        ...state,
        items: action.payload,
      };
      break;
    case CREATE_ORGANIZATION:
      state = {
        ...state,
        creatingSuccess: null,
      };
      break;
    case SUCCESS_CREATE_ORGANIZATION:
      state = {
        ...state,
        creatingSuccess: true,
      };
      break;
    case CLEAR_CREATE_SUCCESS:
      state = { ...state, creatingSuccess: null };
      break;
    case TOOGLE_MANAGE_BY_CLIENT:
      state = { ...state };
      break;
    case TOOGLE_MANAGE_SUCCESS:
      const { items } = state;
      const { data } = items;

      const currentIndex = data.findIndex((item) => item.id === action.id);
      data[currentIndex].isManagedByClient =
        !data[currentIndex].isManagedByClient;

      state = { ...state, items: { ...items, data } };
      break;
    case DELETE_ORGANIZATION:
      state = { ...state, deletingSuccess: null };
      break;
    case DELETE_ORGANIZATION_SUCCESS:
      state = { ...state, deletingSuccess: true };
      break;
    case CLEAR_DELETE_SUCCESS:
      state = { ...state, deletingSuccess: null };
      break;
    case SET_STATUS:
      state = { ...state, updateStatusSuccess: null };
      break;
    case SET_STATUS_SUCCESS:
      state = { ...state, updateStatusSuccess: true };
      break;
    case CLEAR_SET_STATUS:
      state = { ...state, updateStatusSuccess: null };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default organization;
