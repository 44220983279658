import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

import ProfileNavigation from '../Navigation';
import ProfilePages from '../Pages';

import {
  PERSONAL_INFO_TAB,
  EMAIL_TAB,
  SUPERADMIN_ROLE,
} from '../../../constants/helper';
import { getDataUser } from '../../../utils/get-store-user';

const ProfileContainer = ({ handleUpdateUser }) => {
  const { role } = getDataUser();

  const [activeTab, updateActiveTab] = useState(() => {
    return role === SUPERADMIN_ROLE ? EMAIL_TAB : PERSONAL_INFO_TAB;
  });

  return (
    <Row>
      <Col xs="12" md="4" xl="3">
        <ProfileNavigation
          activeTab={activeTab}
          onChangeTab={(newTab) => updateActiveTab(newTab)}
        />
      </Col>
      <Col xs="12" md="8" xl="9">
        <ProfilePages
          activeTab={activeTab}
          handleUpdateUser={handleUpdateUser}
        />
      </Col>
    </Row>
  );
};

export default ProfileContainer;
