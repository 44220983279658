import React, { useState, useEffect } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link, useRouteMatch } from 'react-router-dom';

import { SUPERADMIN_ROLE } from '../../../constants/helper';
import { getOrganizations } from '../../../utils/backend-helper';
import { hasUserManyCompanies } from '../../../pipes/getStatisPipe';
import placeholder from '../../../assets/images/placeholder-large.svg';

const ProfileMenu = ({ user }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [link, setLink] = useState('');

  const { id: organizationId } = useRouteMatch().params;

  const handleOpenMenu = () => setIsOpenMenu((isOpen) => !isOpen);

  const getCurrentOrganization = (user) => {
    getOrganizations().then(({ data }) => {
      const { id: organizationId } = data[0];

      setLink(`/organizations/${organizationId}/dashboard`);
    });
  };

  useEffect(() => {
    if (user.organizationCount) {
      getCurrentOrganization(user);
    }
  }, [user]);

  const [helpLink, updateHelpLink] = useState('');

  useEffect(() => {
    updateHelpLink(
      organizationId ? `/organizations/${organizationId}/help` : '/help',
    );
  }, [organizationId]);

  return (
    <Dropdown
      isOpen={isOpenMenu}
      toggle={handleOpenMenu}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item waves-effect"
        id="page-header-user-dropdown"
        tag="button"
      >
        {user.role === SUPERADMIN_ROLE ? (
          <div>
            <span className="d-inline-block ml-2 mr-1">
              Q Suite Super Admin
            </span>
            <i className="mdi mdi-chevron-down"></i>
          </div>
        ) : (
          <div>
            <div className="image-wrapper">
              <img
                className="profile-logo"
                src={user.avatar ? user.avatar.compactPath : placeholder}
                alt="placeholder"
              />
            </div>
            <span className="d-none d-sm-inline-block ml-2 mr-1">
              {user.firstName} {user.lastName}
            </span>
            <i className="mdi mdi-chevron-down"></i>
          </div>
        )}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem tag="a" href="/profile">
          <i className="bx bxs-user font-size-16 align-middle mr-1"></i>
          Profile
        </DropdownItem>
        {user.organizationCount ? (
          <DropdownItem
            tag="a"
            href={
              hasUserManyCompanies(user.organizationCount)
                ? '/user-organizations'
                : link
            }
          >
            <i className="bx bxs-group font-size-16 align-middle mr-1"></i>
            {hasUserManyCompanies(user.organizationCount)
              ? 'Organizations'
              : 'Organization'}
          </DropdownItem>
        ) : (
          <DropdownItem tag="a" href="/organizations">
            <i className="bx bxs-group font-size-16 align-middle mr-1"></i>
            Organizations
          </DropdownItem>
        )}
        <DropdownItem tag="a" href={helpLink}>
          <i className="bx bxs-help-circle font-size-16 align-middle mr-1"></i>
          Help
        </DropdownItem>
        <div className="dropdown-divider"></div>
        <Link to="/logout" className="dropdown-item">
          <i className="bx bx-log-out font-size-16 align-middle mr-1 text-danger"></i>
          <span>Logout</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileMenu;
