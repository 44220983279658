import React from 'react';

import AttachedList from '../../shared/AttachedList';
import DropZoneFiles from '../../shared/DropZoneFiles';

const DocumentAttachedFiles = ({ title, files, updateModel, onDeleteFile }) => {
  return (
    <div className="general-attached">
      <div className="info-column">
        <p className="info-column__title">{title}</p>
        <AttachedList files={files} onDeleteFile={onDeleteFile} />
        <DropZoneFiles updateModel={updateModel} />
      </div>
    </div>
  );
};

export default DocumentAttachedFiles;
