export const changeFileDocument = (model, file) => ({
  ...model,
  file,
});

export const changeExtraFileDocument = (model, files) => ({
  ...model,
  extraFileIds: files,
});

export const changeExtraFiles = (model, files) => ({
  ...model,
  extraFiles: files,
});

export const changeControlledDocument = (model, value) => ({
  ...model,
  fileId: value,
});
