import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { apiError } from './actions';
import { postLogin } from '../../../utils/backend-helper';
import { clearSessionData } from '../../../utils/clear-storage-helper';
import { saveLoginStorage } from '../../../utils/get-store-user';
import { getLoginRoute } from '../../../utils/getLoginRoute';

function* loginUser({
  payload: {
    user: { email, password },
    history,
    isRemember = false,
    pathname,
  },
}) {
  try {
    const { data = {} } = yield call(postLogin, '/sessions', {
      email,
      password,
    });
    saveLoginStorage(data, isRemember);

    const routePath = yield call(getLoginRoute, data.user);

    history.push(pathname ? pathname : routePath);
  } catch (error) {}
}

function* logoutUser({ payload: { history } }) {
  try {
    clearSessionData();
    history.push('/Login');
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
