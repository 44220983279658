import React from 'react';
import { Col, Row } from 'reactstrap';

const TablePaginationText = ({ text }) => {
  return (
    <Row>
      <Col>
        <h5 className="mt-2">{text}</h5>
      </Col>
    </Row>
  );
};

export default TablePaginationText;
