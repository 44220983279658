import React, { useState, useMemo } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import classnames from 'classnames';
import Checkbox from '../../shared/Checkbox';

import { CHECKED_FALSE, CHECKED_TRUE } from '../../../constants/document';
import {
  getFilterIds,
  getFilterMenu,
} from '../../../utils/documents/get-filter-ids';
import { changeCheckboxChecked } from '../../../utils/shared/change-checkbox-checked';
import { changeFilterChecked } from '../../../utils/api/change-filter-checked';

const FilterDocument = ({
  changeFilters,
  documentTypes,
  updateDocumentTypes,
}) => {
  const [isOpenFilter, updateIsOpenFilter] = useState(false);

  const handleApply = (menuList) => {
    if (isOpenFilter) {
      const filteredMenu = getFilterMenu(menuList);
      const ids = getFilterIds(filteredMenu);
      changeFilters(ids, filteredMenu);
    }
    updateIsOpenFilter((isOpen) => !isOpen);
  };

  const handleSelectAll = () =>
    updateDocumentTypes((menu) => changeFilterChecked(menu, CHECKED_TRUE));
  const handleUnselectedAll = () =>
    updateDocumentTypes((menu) => changeFilterChecked(menu, CHECKED_FALSE));

  const isSelectedAll = useMemo(
    () => documentTypes.every(({ isChecked }) => isChecked),
    [documentTypes],
  );
  const isActiveFilter = useMemo(
    () => documentTypes.some(({ isChecked }) => isChecked),
    [documentTypes],
  );

  return (
    <Dropdown isOpen={isOpenFilter} toggle={() => handleApply(documentTypes)}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={isOpenFilter}
        className="document-dropdown"
      >
        <div
          className={classnames(
            { 'active-filter': isActiveFilter },
            'd-flex align-items-center filter-container cursor',
          )}
        >
          <i className="font-size-20 bx bxs-filter-alt mr-2"></i>
          <span>Filter</span>
        </div>
      </DropdownToggle>
      <DropdownMenu className="p-2">
        <div className="document-dropdown__header">Document types</div>
        <DropdownItem divider />
        <div className="document-dropdown__body">
          {documentTypes.map(({ id, name, isChecked = false }) => {
            return (
              <div key={id} className="dropdown-text">
                <Checkbox
                  id={id}
                  isChecked={isChecked}
                  onChange={() =>
                    updateDocumentTypes((menu) =>
                      changeCheckboxChecked(menu, id),
                    )
                  }
                />
                <span>{name}</span>
              </div>
            );
          })}
        </div>
        <DropdownItem divider />
        <div className="document-dropdown__footer">
          {isSelectedAll ? (
            <button
              type="button"
              className="select-button"
              onClick={handleUnselectedAll}
            >
              <span>Unselect All</span>
            </button>
          ) : (
            <button
              type="button"
              className="select-button"
              onClick={handleSelectAll}
            >
              <span>Select All</span>
            </button>
          )}
          <button
            type="button"
            className="btn btn-edit w-md waves-effect waves-light"
            onClick={() => handleApply(documentTypes)}
          >
            Apply
          </button>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default FilterDocument;
