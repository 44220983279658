import React from 'react';
import Accordion from '../../../shared/Accordion';

const HelpTabContent = ({ mokData }) => {
  return (
    <div>
      {mokData.map(({ title, content }) => (
        <Accordion key={title} title={title}>
          {content}
        </Accordion>
      ))}
    </div>
  );
};

export default HelpTabContent;
