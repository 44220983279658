import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CardBody, Col, Row, Container, Card } from 'reactstrap';
import { toast } from 'react-toastify';

import Breadcrumbs from '../../../components/shared/Breadcrumbs/Breadcrumbs';
import { SUCCESS_TASK_CREATE } from '../../../constants/messages';
import TaskForm from './Form';
import { createTask } from '../../../utils/backend-helper';
import MessageResetSuccess from '../../../components/ToastSuccess';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const TaskCreate = ({ user }) => {
  const history = useHistory();
  const organizationId = useRouteMatch().params.id;
  const onError = useErrorHandler();

  const handleSubmit = (_, values) => {
    createTask(values)
      .then(() => {
        toast.success(<MessageResetSuccess message={SUCCESS_TASK_CREATE} />);
        history.push(`/organizations/${organizationId}/tasks`);
      })
      .catch(onError);
  };

  const handleCancel = () => {
    history.push(`/organizations/${organizationId}/tasks`);
  };

  const breadCrumbItems = [
    {
      link: `/organizations/${organizationId}/tasks`,
      title: 'Action',
    },
    {
      link: '',
      title: 'Create New Action',
    },
  ];

  return (
    <div className="page-content">
      <Container fluid className="container-bottom">
        <Breadcrumbs
          name="Tasks"
          breadCrumbItems={breadCrumbItems}
          link={`/organizations/${organizationId}/tasks`}
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <TaskForm
                  handleSubmit={handleSubmit}
                  handleCancel={handleCancel}
                  user={user}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TaskCreate;
