import React, { useState } from 'react';
import classnames from 'classnames';
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import Button from '../../../Button';
import Checkbox from '../../../shared/Checkbox';

const ExportLogsInfoType = ({ infoTypes, handleChecked, handleClear }) => {
  const [isOpen, updateIsOpen] = useState(false);

  return (
    <Dropdown
      className="d-flex align-items-center mr-3"
      isOpen={isOpen}
      toggle={() => updateIsOpen((isOpen) => !isOpen)}
    >
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={isOpen}
        className="d-cursor"
      >
        <div
          className={classnames(
            'd-flex align-items-center filter-container cursor',
            { 'active-dropdown': isOpen },
          )}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            className={classnames('category-logo', { 'active-logo': isOpen })}
            viewBox="0 0 24 24"
          >
            <path d="M4 11h6c.553 0 1-.447 1-1V4c0-.553-.447-1-1-1H4C3.447 3 3 3.447 3 4v6C3 10.553 3.447 11 4 11zM14 11h6c.553 0 1-.447 1-1V4c0-.553-.447-1-1-1h-6c-.553 0-1 .447-1 1v6C13 10.553 13.447 11 14 11zM4 21h6c.553 0 1-.447 1-1v-6c0-.553-.447-1-1-1H4c-.553 0-1 .447-1 1v6C3 20.553 3.447 21 4 21zM17 21c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4S14.794 21 17 21z" />
          </svg>
          <span>Info type</span>
        </div>
      </DropdownToggle>
      <DropdownMenu className="p-2 dropdown-logs-info" flip={false}>
        <div className="document-dropdown__header">Info types</div>
        <DropdownItem divider />
        <div className="dropdown-logs-info__menu">
          {infoTypes.map(({ type, isChecked }, index) => {
            return (
              <div key={index} className="dropdown-text">
                <Checkbox
                  id={type}
                  isChecked={isChecked}
                  onChange={() => handleChecked(index)}
                />
                <span> {type} </span>
              </div>
            );
          })}
        </div>
        <div className="dropdown-logs-info__footer">
          <Button
            handler={handleClear}
            color="dropdown-logs-info__footer-clear"
          >
            Clear
          </Button>
          <Button
            handler={() => updateIsOpen(false)}
            color="dropdown-logs-info__footer-save"
          >
            Save
          </Button>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ExportLogsInfoType;
