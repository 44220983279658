import { useState } from 'react';
import { debounce } from 'lodash';

import { DEBOUNCE_TIMER, LIMIT_SEARCH_SELECT } from '../constants/helper';
import { changeUserAndGroupSelect } from '../utils/selector-helper-changed';
import { getDataTable, getDocumentUsers } from '../utils/backend-helper';
import { mergeUsersAndGroups } from '../utils/config-get-organization';

export const useUserAsyncSelect = (organizationId) => {
  const [userIds, updateUserIds] = useState([]);
  const [groupIds, updateGroupIds] = useState([]);

  const debounceUser = debounce((inputValue, callback) => {
    Promise.all([
      getDataTable('/user-groups', {
        organizationId,
        search: inputValue,
        limit: LIMIT_SEARCH_SELECT,
      }),
      getDocumentUsers(organizationId, inputValue),
    ]).then(([{ data: groups }, { data: users }]) =>
      callback(mergeUsersAndGroups(groups, users)),
    );
  }, DEBOUNCE_TIMER);

  const handleUserChange = (fields) => {
    const [users, groups] = changeUserAndGroupSelect(fields);
    updateUserIds(users);
    updateGroupIds(groups);
  };

  return { userIds, groupIds, debounceUser, handleUserChange };
};
