import React from 'react';
import { Col, Row } from 'reactstrap';

import UserProfileImage from '../../../pages/Authentication/Profile/UserImage';
import ButtonsPhoto from './ButtonsPhoto';

import { getDataUser } from '../../../utils/get-store-user';

const ProfileUploadPhoto = ({ handleUpdateUser }) => {
  const { avatar } = getDataUser();

  return (
    <>
      <Row>
        <Col className="d-flex align-items-center">
          <UserProfileImage avatar={avatar} />
          <ButtonsPhoto avatar={avatar} handleUpdateUser={handleUpdateUser} />
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="mt-3 mb-3">
            *Please, consider, limitation on the image size is 10Mb max.
          </p>
        </Col>
      </Row>
    </>
  );
};

export default ProfileUploadPhoto;
