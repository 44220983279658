import { useState, useEffect } from 'react';

export const useModel = (data = {}, additionData = {}) => {
  const [model, updateModel] = useState({
    setAsFinal: false,
  });

  useEffect(() => {
    updateModel({ ...data, ...additionData });
  }, [data, additionData]);

  const handleModelChange = (event) => {
    const { name, value } = event.target;
    updateModel((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeChecked = () => {
    updateModel((prev) => ({
      ...prev,
      setAsFinal: !prev.setAsFinal,
    }));
  };

  return { model, handleModelChange, handleChangeChecked, updateModel };
};
