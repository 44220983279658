import React, { useState } from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import Button from '../../components/Button';
import { MAX_INPUT_LENGTH } from '../../constants/helper';

const DocumentCreateModal = ({
  onSubmit,
  onCancel,
  isOpen,
  organizationId,
}) => {
  const [name, setName] = useState('');

  const clear = () => setName('');

  const handleToggleModal = () => {
    onCancel();
    clear();
  };

  const onChange = (value) => {
    setName(value);
  };

  const handleSubmitModal = (_, { name, organizationId }) => {
    name = name.trim();
    onSubmit({ name, organizationId }, clear);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggleModal} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Create Document Type</h5>
        <button
          type="button"
          onClick={handleToggleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm
        className="form-horizontal mt-2"
        onValidSubmit={handleSubmitModal}
      >
        <div className="modal-body">
          <div className="form-group">
            <AvField
              name="name"
              label="Document Type Name"
              className="form-control"
              placeholder="Enter document type name"
              maxLength={MAX_INPUT_LENGTH}
              type="text"
              value={name}
              onChange={(e) => onChange(e.target.value)}
            />
          </div>
          <AvField
            name="organizationId"
            type="text"
            className="btn-hidden"
            value={organizationId}
          />
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={handleToggleModal}
              >
                Cancel
              </Button>
              <Button
                disabled={!name.trim()}
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light mr-3"
              >
                Create
              </Button>
            </Col>
          </Row>
        </div>
      </AvForm>
    </Modal>
  );
};

export default DocumentCreateModal;
