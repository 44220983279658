import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from 'reactstrap';

import ModalTemplate from '../../../modals/ModalTemplate';
import { getDropdownItems } from './DropdownItems';

const TableExportDropdown = ({
  downloadCSV,
  downloadOneDocument,
  downloadManyDocuments,
  downloadDocumentAttachments,
}) => {
  const [isOpen, updateIsOpen] = useState(false);

  const toggle = () => {
    updateIsOpen((isOpen) => !isOpen);
  };

  const [isOpenModal, updateIsOpenModal] = useState(false);

  const toogleModal = () => {
    updateIsOpenModal((isOpen) => !isOpen);
  };

  const dropdownItems = useMemo(() => {
    return getDropdownItems(downloadCSV, downloadOneDocument, downloadManyDocuments);
  }, [downloadCSV, downloadOneDocument, downloadManyDocuments])

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} className="mr-3">
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={isOpen}
        className="document-dropdown"
      >
        <div
          className={classnames(
            'd-flex align-items-center filter-container cursor',
            { 'active-dropdown': isOpenModal },
          )}
        >
          <i className="font-size-20 bx bxs-file-export mr-2"></i>
          <span>Export</span>
        </div>
      </DropdownToggle>
      <DropdownMenu className="pt-2 pb-2">
        {dropdownItems.map(({ text, handler, tooltipText }, index) => {
          return (
            <React.Fragment key={index}>
              <DropdownItem
                className="dropdown-file-item"
                onClick={handler}
                id={`dropdown-${index}`}
              >
                {text}
              </DropdownItem>
              <UncontrolledTooltip
                placement="right"
                id="tooltip-document"
                target={`dropdown-${index}`}
              >
                <span>{tooltipText}</span>
              </UncontrolledTooltip>
            </React.Fragment>
          );
        })}
        {downloadDocumentAttachments && (
          <>
            <DropdownItem
              className="dropdown-file-item"
              onClick={toogleModal}
              id="dropdown-attachments"
            >
              Attachments
            </DropdownItem>
            <UncontrolledTooltip
              placement="right"
              id="tooltip-document"
              target="dropdown-attachments"
            >
              <span>
                Click to export attachments from Revisions of the selected
                Documents. If you click to export attachments without selecting
                the Documents, you will need to select the Document Type(s),
                then all the attachments from all the Revisions of the Documents
                of the selected Type(s) will be exported
              </span>
            </UncontrolledTooltip>
          </>
        )}
      </DropdownMenu>
      {downloadDocumentAttachments && (
        <ModalTemplate
          title="Export Attachments"
          submitText="Export"
          isOpen={isOpenModal}
          onCancel={() => updateIsOpenModal(false)}
          onSubmit={(_, { docTypeIds }) => {
            downloadDocumentAttachments(docTypeIds);
            updateIsOpenModal(false);
          }}
        />
      )}
    </Dropdown>
  );
};

export default TableExportDropdown;
