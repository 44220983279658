import React, { useState, useCallback, useMemo } from 'react';
import { Row, Col, Modal, FormGroup, Label } from 'reactstrap';
import { useRouteMatch } from 'react-router-dom';
import { AvForm } from 'availity-reactstrap-validation';

import MultiSelect from '../../components/shared/MultiSelect';
import Index from '../../components/Button';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';

import { getDocumentTypes } from '../../utils/api/organization-api';
import { configureGetDocumentTypesMulti } from '../../utils/config-get-organization';
import { getIdsFromData } from '../../utils/shared/get-ids-from-data';

const ModalTemplate = ({
  onSubmit,
  onCancel,
  isOpen,
  title = '',
  submitText = '',
}) => {
  const { id: organizationId } = useRouteMatch().params;

  const getDocumentTypesAPI = useCallback(
    (search = '') => {
      return getDocumentTypes(organizationId).then(({ data }) => data || []);
    },
    [organizationId],
  );

  const [selected, updateSelected] = useState([]);

  const isValidForm = useMemo(() => selected.length, [selected]);

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">{title}</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
        <div className="modal-body">
          <FormGroup>
            <Label for="exampleEmail">Type(s) of Document</Label>
            <MultiSelect
              value={selected}
              handleRequest={getDocumentTypesAPI}
              placeholder="Select or search type(s)"
              configOptions={configureGetDocumentTypesMulti}
              handleChange={updateSelected}
            />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Index
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Index>
              <Index
                type="submit"
                color="btn w-md waves-effect waves-light mr-3"
                disabled={!isValidForm}
              >
                {submitText}
              </Index>
            </Col>
          </Row>
        </div>
        <AvInput
          type="hidden"
          name="docTypeIds"
          value={getIdsFromData(selected)}
        />
      </AvForm>
    </Modal>
  );
};

export default ModalTemplate;
