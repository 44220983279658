import React from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import Index from '../../components/Button';

const TaskDeleteModal = ({
  isOpen,
  onSubmit,
  onCancel,
  model,
  values,
  isMulty = false,
  title,
  text,
  footerText,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">
          Delete {title ? title : text ? text : 'Actions'}
        </h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
        <div className="modal-body">
          <p className="text-modal">
            {isMulty ? (
              <>
                Are you sure you want to permanently delete{' '}
                {values.countChecked} {text ? text : `Action(s)`}?{' '}
              </>
            ) : (
              <>
                Are you sure you want to{footerText ? '' : ' permanently'}{' '}
                delete the {text ? text : `Action`}{' '}
                <b>
                  {values.number} {values.name}
                </b>
                ?{' '}
              </>
            )}
          </p>
          {footerText && <p className="text-modal">{footerText}</p>}
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Index
                type="submit"
                color="btn btn-danger w-md waves-effect waves-light delete-btn mr-3"
              >
                Delete
              </Index>
              <Index
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Index>
            </Col>
          </Row>
        </div>
        <AvField
          name="ids"
          type="text"
          value={model.ids}
          className="btn-hidden"
        />
        <AvField
          name="organizationId"
          type="text"
          value={model.organizationId}
          className="btn-hidden"
        />
      </AvForm>
    </Modal>
  );
};

export default TaskDeleteModal;
