export const configCropper = {
  cropResult: null,
  ratio: 16 / 9,
  zoom: 0.5,
  dragMode: 'crop',
  rotate: 0,
};

export const RIGHT = 'right';
export const LEFT = 'left';

export const ROTATE_DEGREE = 90;
