import React from 'react';
import { Modal } from 'reactstrap';

const UserList = ({ isOpen, onClose, users }) => {
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Users</h5>
        <button
          type="button"
          onClick={onClose}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <div className="modal-body">
        {users.map(({ id, firstName, lastName, role, email }, i, arr) => {
          return (
            <React.Fragment key={id}>
              <p>
                {firstName || ''} {lastName || ''} - {email} - {role}
              </p>
              {arr.length - 1 !== i && <hr />}
            </React.Fragment>
          );
        })}
      </div>
    </Modal>
  );
};

export default UserList;
