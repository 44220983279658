import React from 'react';

import HelpImage from '../../shared/HelpImage';
import { USER_ROLE } from '../../../../constants/helper';
import { getDataUser } from '../../../../utils/get-store-user';

import HRImage from '../../../../assets/images/help-screen/flow/flow-31.png';
import HRImage2 from '../../../../assets/images/help-screen/flow/flow-32.png';
import HRImage3 from '../../../../assets/images/help-screen/flow/flow-33.png';

const HumanResourcesContent = () => {
  const { role } = getDataUser();

  if (role === USER_ROLE) {
    return (
      <>
        <p>HR Records are artifacts that allow an Organization to store human resources information. Only an Admin can view and edit HR Records.</p>
        <p>HR Records automatically appear in the Organization when a new Admin or User is added into the Organization.</p>
        <p>A regular user can meet reference-links to HR records in other artifacts that are available to them.</p>
      </>
    )
  }

  return (
    <>
      <p>
        HR Records are artifacts that allow an Organization to store human
        resources information. Only an Admin can view and edit HR Records.
      </p>
      <HelpImage src={HRImage} />
      <HelpImage src={HRImage2} />
      <p>
        When editing, a file can be uploaded as a resume or curriculum vitae
        (CV).
      </p>
      <HelpImage src={HRImage3} />
      <p>
        HR Records automatically appear in the Organization when a new Admin
        or User is added into the Organization. It is possible to remove an HR
        Record only when its corresponding Admin/User is no longer included
        into the Organization.
      </p>
      <p>All data in the HR Record is filled automatically:</p>
      <ul className="list-default">
        <li>
          <p>
            User Group(s): the list is updated when the User is added or
            removed to a User Group. This information is not included for
            Admins, only Users have relation to User Groups.
          </p>
        </li>
        <li>
          <p>
            Connected Document(s): the list of Document Revisions which is
            updated when the current HR Record is added as a Reference to
            Document Revision(s). See information below in the “Documents”.
          </p>
        </li>
        <li>
          <p className="mb-1">Connected Training(s):</p>
          <p>
            The list of Trainings is updated when the User-Admin, owner of the
            current HR Record, is set as a Training assignee, or removed from
            there. See information below in the “Training Records”.
          </p>
        </li>
      </ul>
    </>
  )
};

export default HumanResourcesContent;