import React, { useCallback, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import classnames from 'classnames';

import CardHeader from '../shared/DueDateHeader';
import DashboardTab from './DasboardTab';

import { APPROVE_TAB, REVIEW_TAB } from '../../../constants/helper';
import {
  getDocumentApprove,
  getDocumentReview,
} from '../../../utils/api/dashboard-api';

const DashboardDocuments = () => {
  const history = useHistory();
  const { id: organizationId } = useRouteMatch().params;

  const [activeTab, updateActiveTab] = useState(REVIEW_TAB);

  const getUsersCallback = useCallback(
    (callbackAPI) => {
      return callbackAPI({ organizationId }).then(({ data }) => data);
    },
    [organizationId],
  );

  return (
    <Row>
      <Col>
        <Card>
          <CardHeader
            title="Documents"
            onClick={() =>
              history.push(`/organizations/${organizationId}/documents`)
            }
            subLine={true}
          />
          <CardBody>
            <Nav pills className="bg-light rounded">
              <NavItem>
                <NavLink
                  onClick={() => updateActiveTab(REVIEW_TAB)}
                  className={classnames('document-nav', {
                    'nav-active': activeTab === REVIEW_TAB,
                  })}
                >
                  To review
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => updateActiveTab(APPROVE_TAB)}
                  className={classnames('document-nav', {
                    'nav-active': activeTab === APPROVE_TAB,
                  })}
                >
                  To approve
                </NavLink>
              </NavItem>
            </Nav>
          </CardBody>
          <hr className="dashboard-line" />
          <CardBody className="dashboard-documents-card">
            {activeTab === REVIEW_TAB && (
              <DashboardTab
                getUsersCallback={getUsersCallback}
                getUsersAPI={getDocumentReview}
              />
            )}
            {activeTab === APPROVE_TAB && (
              <DashboardTab
                getUsersCallback={getUsersCallback}
                getUsersAPI={getDocumentApprove}
              />
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default DashboardDocuments;
