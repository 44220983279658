import { useState } from 'react';
import { debounce } from 'lodash';

import { DEBOUNCE_TIMER } from '../constants/helper';
import { configureGetTrainings } from '../utils/config-get-organization';
import { getAllTrainingRecords } from '../utils/api/training-records-api';

export const useTrainingAsyncSelect = (organizationId) => {
  const [trainingRecordId, updateTrainingRecordId] = useState();

  const debounceTraining = debounce((search, callback) => {
    getAllTrainingRecords({ organizationId, search }).then(({ data }) =>
      callback(configureGetTrainings(data)),
    );
  }, DEBOUNCE_TIMER);

  const handleTrainingChange = ({ id }) => updateTrainingRecordId(id);

  return { trainingRecordId, debounceTraining, handleTrainingChange };
};
