import React from 'react';

import ShouldLongTextTooltip from '../../tooltips/ShouldLongTextTooltip';

import { MAX_LENGTH_TEXT } from '../../../constants/helper';

function TableCellText({ id, text }) {
  return (
    <ShouldLongTextTooltip id={id} text={text} maxLength={MAX_LENGTH_TEXT} />
  );
}

export default TableCellText;
