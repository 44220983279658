export const modelTagDeleteModal = {
  isOpen: false,
  values: {
    name: '',
  },
  model: {
    organizationId: null,
    id: null,
  },
};

export const updateModelTagDeleteModal = (values, model) => {
  return {
    isOpen: true,
    values: { ...values },
    model: { ...model },
  };
};
