export const changePaginationCheckbox = (pagination, checkboxId) => {
  return {
    ...pagination,
    data: pagination.data.map((item = {}) => {
      const { id, isChecked = false } = item;

      return id === checkboxId ? { ...item, isChecked: !isChecked } : item;
    }),
  };
};

export const changeCheckboxHidden = (list, checkboxId) => {
  return list.map((item) => {
    const { id, isChecked = false } = item;

    return id === checkboxId ? { ...item, isChecked: !isChecked } : item;
  });
};
