import React from 'react';
import { CardBody, Col, Row, Container, Card } from 'reactstrap';

import NotificationList from './List';
import Breadcrumbs from '../../../components/shared/Breadcrumbs/Breadcrumbs';

const Notifications = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs link="#" goBack={true} backText="Back" />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <NotificationList />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Notifications;
