import React from 'react';
import { Col, Row } from 'reactstrap';

const ProfileHeader = () => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex">
          <h4 className="mb-0 font-size-18">Profile</h4>
        </div>
      </Col>
    </Row>
  );
};

export default ProfileHeader;
