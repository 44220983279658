export const documentModel = {
  id: null,
  type: '',
  typeDescription: '',
  number: null,
  name: '',
  revision: null,
  canViewReviews: null,
  canViewApprovals: null,
  canBeEdited: null,
  canBeDeleted: null,
};
