import React, { useState } from 'react';
import { Button, Badge } from 'reactstrap';
import { toast } from 'react-toastify';

import MessageResetSuccess from '../../../../components/ToastSuccess';
import { ACTIVE } from '../../../../constants/modals';
import { INACTIVE_STATUS, ACTIVE_STATUS } from '../../../../constants/table';
import {
  configureGetDeleteOrganization,
  configureGetActiveOrganization,
} from '../../../../utils/config-get-organization';
import { getStatusPipe } from '../../../../pipes/getStatisPipe';
import TooltipAction from '../../../../components/tooltips/ActionTooltip';
import {
  changeAdminStatus,
  deleteAdmin,
  editAdmin,
  generateAccessCode,
  resendEmail,
} from '../../../../utils/backend-helper';
import {
  SUCCESS_MESSAGE_SEND,
  SUCCESS_MESSAGE_ACTIVE,
  SUCCESS_DELETE_ADMIN,
  SUCCESS_MESSAGE_ADMIN_EDIT,
} from '../../../../constants/messages';
import AdminActiveModal from '../../../../modals/AdminsSetActive';
import AdminEditModal from '../../../../modals/AdminsEdit';
import AdminDeleteModal from '../../../../modals/AdminsDelete';
import CheckboxHead from '../../../../components/tables/HeadCheckbox';
import BodyCheckbox from '../../../../components/tables/BodyCheckbox';
import IconTooltip from '../../../../components/tooltips/IconTooltip';

export const columnsHead = [
  {
    name: 'checkbox',
    accessor: '',
    sort: false,
    Component: ({
      provided,
      snapshot,
      checkAllDocuments,
      checkedHead,
      pagination: { data },
    }) => {
      return (
        <CheckboxHead
          data={data}
          checkedHead={checkedHead}
          checkAllDocuments={checkAllDocuments}
          provided={provided}
          snapshot={snapshot}
        />
      );
    },
  },
  {
    name: 'firstName',
    accessor: 'First Name',
    sort: true,
    classes: 'column-title sort',
    widthPercent: 15.5,
  },
  {
    name: 'lastName',
    accessor: 'Last Name',
    sort: true,
    classes: 'column-title sort',
    widthPercent: 15.5,
  },
  {
    name: 'email',
    accessor: 'Email',
    sort: true,
    classes: 'column-title sort',
    widthPercent: 15.5,
  },
  {
    name: 'status',
    accessor: 'Status',
    sort: true,
    classes: 'column-title sort',
    widthPercent: 15,
  },
  {
    name: 'isactivated',
    accessor: 'Activated',
    sort: true,
    classes: 'column-title sort',
    widthPercent: 10,
  },
  {
    name: 'activeted',
    accessor: 'Options',
    sort: false,
    classes: 'text-center',
    widthPercent: 28.5,
  },
];

export const columnsBody = [
  {
    name: 'checkbox',
    accessor: 'Selected',
    Component: ({ order: { id, isChecked = false }, handleUpdateChecked }) => {
      return (
        <BodyCheckbox
          id={id}
          isChecked={isChecked}
          handleUpdateChecked={handleUpdateChecked}
        />
      );
    },
  },
  {
    name: 'firstName',
    accessor: 'First Name',
    classes: '',
  },
  {
    name: 'lastName',
    accessor: 'Last Name',
    classes: '',
  },
  {
    name: 'email',
    accessor: 'Email',
    classes: '',
  },
  {
    name: 'status',
    accessor: 'Status',
    Component: ({ order, columnsHidden }) => (
      <td className={`${columnsHidden.status ? '' : 'column-hidden'}`}>
        <p className="td-before">Status</p>
        <div>
          <Badge
            className={`font-size-12 badge-soft-${
              order.status === 'Active' ? 'success' : 'danger'
            }`}
            pill
          >
            {order.status}
          </Badge>
        </div>
      </td>
    ),
  },
  {
    name: 'isactivated',
    accessor: 'Activated',
    classes: '',
    Component: ({ order, columnsHidden }) => (
      <td className={columnsHidden.isactivated ? '' : 'column-hidden'}>
        <p className="td-before">Activated</p>
        <p>{order.isActivated ? 'Yes' : 'No'}</p>
      </td>
    ),
  },
  {
    name: 'activeted',
    accessor: 'Actions',
    classes: '',
    Component: ({
      order,
      columnsHidden,
      organizationId,
      organizationName,
      updatingTable,
      onDeleteRow,
    }) => {
      const [activeModal, setActiveModal] = useState({
        isOpen: false,
        isActive: '',
        organizationUserStatuses: [],
        selectOptions: [],
        currentOrganzation: {
          id: null,
          name: null,
        },
        model: {
          id: null,
          routerId: null,
        },
      });

      const handleActiveModal = (
        status,
        id,
        routeId,
        firstName,
        lastName,
        email,
        organizationUserStatuses,
        organizationName,
      ) => {
        const isActive = status === ACTIVE ? ACTIVE_STATUS : INACTIVE_STATUS;
        let selectOptions = [];
        if (organizationUserStatuses.length) {
          const currentOrganization = {
            organizationId: routeId,
            organizationName: organizationName,
            userStatus: isActive,
          };

          selectOptions = configureGetActiveOrganization(
            organizationUserStatuses,
            currentOrganization,
            isActive,
          );
        }

        setActiveModal((prev) => ({
          isOpen: !prev.isOpen,
          organizationUserStatuses,
          status,
          currentOrganzation: { id: routeId, name: organizationName },
          model: { id, routeId, firstName, lastName, email },
          selectOptions,
        }));
      };

      const [editModal, setEditModal] = useState({
        isOpen: false,
        model: {
          id: null,
          routeId: null,
          firstName: '',
          lastName: '',
          email: '',
          emailConfirmed: null,
        },
      });

      const handleToogleEditModal = (
        id,
        routeId,
        firstName,
        lastName,
        email,
        emailConfirmed,
      ) => {
        setEditModal((prev) => ({
          isOpen: !prev.isOpen,
          model: {
            id,
            routeId,
            firstName,
            lastName,
            email,
            emailConfirmed,
          },
        }));
      };

      const handleSendEmail = (email) => {
        resendEmail(email).then(() => {
          toast.success(
            <MessageResetSuccess
              message={`${SUCCESS_MESSAGE_SEND} ${email}`}
            />,
          );
        });
      };

      const [deleteModal, setDeleteModal] = useState({
        isOpen: false,
        organizationUserStatuses: [],
        selectOptions: [],
        currentOrganzation: {
          id: null,
          name: null,
        },
        model: {
          id: null,
          routeId: null,
          firstName: '',
          lastName: '',
          email: '',
          organizationName: '',
        },
      });

      const handleDeleteAdmin = (
        id,
        routeId,
        firstName,
        lastName,
        email,
        organizationName,
        organizationUserStatuses,
      ) => {
        let selectOptions = [];
        if (organizationUserStatuses.length) {
          const currentOrganzation = {
            organizationId: routeId,
            organizationName: organizationName,
            userStatus: 1,
          };
          selectOptions = configureGetDeleteOrganization(
            organizationUserStatuses,
            currentOrganzation,
          );
        }

        setDeleteModal((prev) => ({
          isOpen: !prev.isOpen,
          organizationUserStatuses,
          currentOrganzation: { id: routeId, name: organizationName },
          model: { id, routeId, firstName, lastName, email, organizationName },
          selectOptions,
        }));
      };

      const generateCode = (id) => {
        generateAccessCode(id).then(({ data }) => {
          const { message } = data;
          toast.success(<MessageResetSuccess message={message} />);
        });
      };

      return (
        <>
          <td
            className={`activate-column ${
              columnsHidden.activeted ? '' : 'column-hidden'
            }`}
          >
            <p className="td-before">Actions</p>
            <div className="d-flex justify-content-start justify-content-md-center">
              <div className="d-flex column-admins-active">
                <Button
                  type="button"
                  color={order.status === ACTIVE ? '' : 'primary'}
                  className={`btn-sm btn-rounded btn-active mr-2 ${
                    order.status === ACTIVE ? 'btn-muted' : ''
                  }`}
                  onClick={() =>
                    handleActiveModal(
                      order.status,
                      order.id,
                      organizationId,
                      order.firstName,
                      order.lastName,
                      order.email,
                      order.organizationUserStatuses,
                      organizationName,
                    )
                  }
                >
                  {getStatusPipe(order.status)}
                </Button>
                <div className="actions">
                  <IconTooltip
                    icon="bx bx-sync action"
                    id={`AdminsGenerate${order.id}`}
                    text="Generate Access Code"
                    onClick={() => generateCode(order.id)}
                  />
                  <IconTooltip
                    icon="bx bxs-edit-alt action"
                    id={`AdminsEdit${order.id}`}
                    text="Edit"
                    onClick={() =>
                      handleToogleEditModal(
                        order.id,
                        organizationId,
                        order.firstName,
                        order.lastName,
                        order.email,
                        order.emailConfirmed,
                      )
                    }
                  />
                  <TooltipAction
                    status={order.status}
                    isActivated={order.isActivated}
                    emailConfirmed={order.emailConfirmed}
                    id={order.id}
                    email={order.email}
                    routeId={organizationId}
                    firstName={order.firstName}
                    lastName={order.lastName}
                    organizationName={organizationName}
                    organizationUserStatuses={order.organizationUserStatuses}
                    handleSendEmail={handleSendEmail}
                    handleDeleteAdmin={handleDeleteAdmin}
                  />
                </div>
              </div>
            </div>
          </td>
          <AdminActiveModal
            values={activeModal.model}
            selectOptions={activeModal.selectOptions}
            onSubmit={(event, v) => {
              changeAdminStatus(v.id, v.routeId)
                .then(() => {
                  toast.success(
                    <MessageResetSuccess message={SUCCESS_MESSAGE_ACTIVE} />,
                  );
                })
                .finally(() => {
                  updatingTable(true);
                  setActiveModal((prev) => ({ ...prev, isOpen: false }));
                });
            }}
            isOpen={activeModal.isOpen}
            status={activeModal.status}
            onCancel={() => {
              setActiveModal((prev) => ({ ...prev, isOpen: false }));
            }}
            organizationId={organizationId}
            currentOrganzation={activeModal.currentOrganzation}
            organizationUserStatuses={activeModal.organizationUserStatuses}
          />
          <AdminEditModal
            values={editModal.model}
            isOpen={editModal.isOpen}
            onSubmit={(
              event,
              { firstName, lastName, email, id, currentEmail },
            ) => {
              email = email === currentEmail ? {} : { email };
              editAdmin(id, { firstName, lastName, ...email })
                .then(() => {
                  updatingTable(true);
                  toast.success(
                    <MessageResetSuccess
                      message={SUCCESS_MESSAGE_ADMIN_EDIT}
                    />,
                  );
                })
                .finally(() => {
                  setEditModal((prev) => ({ ...prev, isOpen: false }));
                });
            }}
            onCancel={() =>
              setEditModal((prev) => ({ ...prev, isOpen: false }))
            }
          />
          <AdminDeleteModal
            values={deleteModal.model}
            selectOptions={deleteModal.selectOptions}
            isOpen={deleteModal.isOpen}
            user="Admin"
            onSubmit={(event, { id, ids }) => {
              deleteAdmin(id, ids)
                .then(() => {
                  onDeleteRow(id);
                  toast.success(
                    <MessageResetSuccess message={SUCCESS_DELETE_ADMIN} />,
                  );
                })
                .finally(() => {
                  setDeleteModal((prev) => ({ ...prev, isOpen: false }));
                });
            }}
            onCancel={() =>
              setDeleteModal((prev) => ({ ...prev, isOpen: false }))
            }
          />
        </>
      );
    },
  },
];
