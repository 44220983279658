import React, { useState } from 'react'
import HelpImageModal from './Modal';

const HelpImage = ({ src }) => {
  const [isOpen, updateIsOpen] = useState(false);

  const toggleModal = () => updateIsOpen(isOpen => !isOpen);

  return (
    <div 
      className='help-image-container' 
      onClick={() => updateIsOpen(isOpen => !isOpen)}
    >
      <img         
        className='help-image-container__image' 
        src={src} 
        alt='HelpImage' 
      />
      <HelpImageModal 
        src={src}
        isOpen={isOpen}
        toggle={toggleModal}
      />
    </div>
  )
};

export default HelpImage;