import React from 'react';
import PlaceholderText from '../shared/PlaceholderText';

const QualityReviewer = ({ users, placeholderText }) => {
  if (users?.length) {
    return (
      <div className="quality-reviewers">
        <ul className="reviewer-list">
          {users.map(({ id, firstName, lastName, email, role }) => {
            return (
              <li key={id}>
                <div className="reviewer-item">
                  <p className="reviewer-item__name">{`${firstName || ''} ${
                    lastName || ''
                  }`}</p>
                  <div className="reviewer-item__email">
                    <p>{email}</p>
                  </div>
                  <p className="reviewer-item__role">{role || ''}</p>
                </div>
                <hr />
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return <PlaceholderText text={placeholderText} />;
};

export default QualityReviewer;
