import { throttleAdapter } from '../../services/throttleadapter';
import { API } from '../backend-helper';
import axios from 'axios';

export const SESSTIONS = 'sessions';

export const sendRefreshToken = (refreshToken) => {
  return API.put(`/${SESSTIONS}`, refreshToken, {
    adapter: throttleAdapter(axios.defaults.adapter),
  });
};
