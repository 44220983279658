import { API } from '../backend-helper';

const DASHBOARD = 'dashboard';
const ACTIONS = 'actions';
const COUNTERS = 'counters';
const TRAININGS = 'trainings';
const DOCUMENTS_REVIEW = 'documents-to-review';
const DOCUMENTS_APPROVE = 'documents-to-approve';

export const getCounters = (params) => {
  return API.get(`/${DASHBOARD}/${COUNTERS}`, { params });
};

export const getActions = (params) => {
  return API.get(`/${DASHBOARD}/${ACTIONS}`, { params });
};

export const getTrainings = (params) => {
  return API.get(`/${DASHBOARD}/${TRAININGS}`, { params });
};

export const getDocumentReview = (params) => {
  return API.get(`/${DASHBOARD}/${DOCUMENTS_REVIEW}`, { params });
};

export const getDocumentApprove = (params) => {
  return API.get(`/${DASHBOARD}/${DOCUMENTS_APPROVE}`, { params });
};
