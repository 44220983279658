import React, { useState, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
  Button,
} from 'reactstrap';
import { toast } from 'react-toastify';

import CreateDocumentTagModal from '../../../modals/CreateDocumentTag';
import SettingTagBody from './TagBody';
import MessageResetSuccess from '../../ToastSuccess';

import {
  createDocumentTag,
  getTags,
} from '../../../utils/api/organization-api';
import { SUCCESS_TAGS_CREATE } from '../../../constants/messages';
import { getSortedTags } from '../../../utils/tags-helper/get-sorted-tags';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';

const SettingDocumentTag = () => {
  const { id: organizationId } = useRouteMatch().params;
  const onError = useErrorHandler();

  const [isOpenCreateModal, updateIsOpenCreateModal] = useState(false);
  const handleToggle = () => updateIsOpenCreateModal(true);

  const [tags, updateTags] = useState([]);

  const getTagsAPI = useCallback(() => {
    getTags(organizationId)
      .then(({ data }) => updateTags(getSortedTags(data)))
      .catch(onError);
  }, [organizationId, onError]);

  useEffect(() => getTagsAPI(), [getTagsAPI, organizationId]);

  const createDocumentTagAPI = useCallback(
    (id, values) => {
      createDocumentTag(id, values)
        .then(() => {
          toast.success(<MessageResetSuccess message={SUCCESS_TAGS_CREATE} />);
          updateIsOpenCreateModal(false);
          getTagsAPI();
        })
        .catch(onError);
    },
    [getTagsAPI, onError],
  );

  return (
    <Card className="h-100 mb-2">
      <CardBody>
        <div className="d-flex align-items-center justify-content-between">
          <CardTitle className="tag-title d-flex align-items-center mb-0">
            {' '}
            Tags
            <i
              className="bx bx-info-circle setting-icon"
              id="tooltip-tags-document"
            ></i>
            <UncontrolledTooltip
              placement="top-start"
              id="tooltip-accordion"
              target="tooltip-tags-document"
            >
              <span>
                The Tags created here will be available for all artifacts in the
                current Organization
              </span>
            </UncontrolledTooltip>
          </CardTitle>
          {!!tags.length && (
            <Button className="button-tags" onClick={handleToggle}>
              + Add New
            </Button>
          )}
        </div>
        <hr />
        <SettingTagBody
          tags={tags}
          handleToggle={handleToggle}
          getTagsAPI={getTagsAPI}
        />
      </CardBody>
      <CreateDocumentTagModal
        isOpen={isOpenCreateModal}
        onCancel={() => updateIsOpenCreateModal(false)}
        onSubmit={({ id, ...values }) => {
          createDocumentTagAPI(id, values);
        }}
      />
    </Card>
  );
};

export default SettingDocumentTag;
