export const configBodyRevision = (body) => {
  return {
    ...body,
    revision: body.revision - 1,
  };
};

export const configRevisionRequest = (params, currentRevision) => {
  const otherParams = currentRevision
    ? {
        revision: currentRevision,
      }
    : {};

  return { ...params, ...otherParams };
};
