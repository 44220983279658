import React, { useState } from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { useRouteMatch } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';

import Button from '../../../../components/Button';
import RequiresTrainingEdit from '../../../../components/document/RequiresTraining/Edit';

import { getDocumentUsers } from '../../../../utils/backend-helper';
import { qualityStyles } from '../../../../utils/select-styles';
import { DEBOUNCE_TIMER } from '../../../../constants/helper';
import { changeFieldSelect } from '../../../../utils/selector-helper-changed';
import { configureGetUsers } from '../../../../utils/config-get-organization';
import { AvField } from 'availity-reactstrap-validation';

const QualitySection = ({ handleCancelCreator, createdDocument }) => {
  const { id: organizationId } = useRouteMatch().params;

  const [isChecked, updateIsChecked] = useState(false);

  const [reviewerIds, updateReviewerIds] = useState([]);
  const [approverIds, updateApproverIds] = useState([]);

  const debounceReviewers = debounce((inputValue, callback) => {
    getDocumentUsers(organizationId, inputValue).then(({ data }) =>
      callback(configureGetUsers(data)),
    );
  }, DEBOUNCE_TIMER);

  const debounceApprovers = debounce((inputValue, callback) => {
    getDocumentUsers(organizationId, inputValue).then(({ data }) =>
      callback(configureGetUsers(data)),
    );
  }, DEBOUNCE_TIMER);

  const handleReviewersChange = (user) => {
    updateReviewerIds(changeFieldSelect(user));
  };

  const handleApproversChange = (user) => {
    updateApproverIds(changeFieldSelect(user));
  };

  return (
    <div className="general">
      <div className="general-header">
        <h6 className="mt-2">Quality Actions</h6>
      </div>
      <hr />
      <div className="quality-assign">
        <Row>
          <Col>
            <div className="info-column">
              <p className="info-column__title">Assign Reviewer(s)</p>
              <FormGroup className="select2-container">
                <AsyncSelect
                  styles={qualityStyles}
                  isMulti={true}
                  cacheOptions={false}
                  defaultOptions
                  onChange={(value) => handleReviewersChange(value)}
                  loadOptions={debounceReviewers}
                  placeholder="Assign new reviewer(s)"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <div className="info-column">
              <p className="info-column__title">Assign Approver(s)</p>
              <FormGroup className="select2-container">
                <AsyncSelect
                  styles={qualityStyles}
                  isMulti={true}
                  cacheOptions={false}
                  defaultOptions
                  onChange={(value) => handleApproversChange(value)}
                  loadOptions={debounceApprovers}
                  placeholder="Assign new approver(s)"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <RequiresTrainingEdit
          isChecked={isChecked}
          handleChecked={() => updateIsChecked((isChecked) => !isChecked)}
          disabled={true}
        />
      </div>
      <Row className="form-group mt-4">
        <Col className="text-right">
          <Button
            type="button"
            color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
            handler={handleCancelCreator}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
          >
            Save & Next
          </Button>
        </Col>
      </Row>
      <AvField
        name="reviewerIds"
        type="text"
        value={reviewerIds}
        className="btn-hidden"
      />
      <AvField
        name="approverIds"
        type="text"
        value={approverIds}
        className="btn-hidden"
      />
      <AvField name="id" type="hidden" value={createdDocument?.id} />
      <AvField
        name="revision"
        type="hidden"
        value={createdDocument?.revision}
      />
      <AvField name="organizationId" type="hidden" value={organizationId} />
      <AvField name="requiresTraining" type="hidden" value={`${isChecked}`} />
      <AvField name="isCreating" type="hidden" value="true" />
    </div>
  );
};

export default QualitySection;
