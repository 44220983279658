export const getNumbersOfChecked = (checkboxList) => {
  return checkboxList.reduce((acc, next) => (acc += next.isChecked), 0);
};

export const getNumbersOfCheck = (ckeckboxList) => {
  return ckeckboxList.reduce(
    (acc, { isChecked }) => (acc += isChecked ? isChecked : 0),
    0,
  );
};

export const getIdsChecked = (checkboxList) => {
  const result = [];
  checkboxList.forEach(({ isChecked, id }) => {
    if (isChecked) result.push(id);
  });
  return result;
};

export const getIdsCheckboxChecked = (checkboxList) => {
  return checkboxList.reduce((acc, { isChecked, id }) => {
    if (isChecked) acc.push(id);
    return acc;
  }, []);
};
