import React from 'react';
import { CardTitle, Button } from 'reactstrap';

const CardHeader = ({ title, onClick, subLine }) => {
  return (
    <>
      <div className="pb-2 pt-3 pl-4 pr-4">
        <CardTitle className="mb-0 d-flex justify-content-between align-items-center">
          {title}
          <Button
            color="info"
            outline
            onClick={onClick}
            className="waves-effect waves-light due-title-button"
          >
            View all
            <i className="bx bx-right-arrow-alt middle"></i>
          </Button>
        </CardTitle>
      </div>
      {!subLine && <hr />}
    </>
  );
};

export default CardHeader;
