import React, { useState } from 'react';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import CheckboxHead from '../../../../components/tables/HeadCheckbox';

import TaskDeleteModal from '../../../../modals/TaskDelete';
import MessageResetSuccess from '../../../../components/ToastSuccess';
import { SUCCESS_RECORD_DELETE } from '../../../../constants/messages';
import { deleteHumanRecource } from '../../../../utils/api/human-resources-api';
import BodyCheckbox from '../../../../components/tables/BodyCheckbox';
import DocumentLinkColumn from '../../../../components/tables/TableLinkColumn';
import TableCellText from '../../../../components/tables/TableCellText';
import IconTooltip from '../../../../components/tooltips/IconTooltip';

export const columnsHead = [
  {
    name: 'checkbox',
    accessor: '',
    sort: false,
    Component: ({
      provided,
      snapshot,
      checkAllDocuments,
      checkedHead,
      pagination: { data },
    }) => {
      return (
        <CheckboxHead
          data={data}
          checkedHead={checkedHead}
          checkAllDocuments={checkAllDocuments}
          provided={provided}
          snapshot={snapshot}
        />
      );
    },
  },
  {
    name: 'firstName',
    accessor: 'First Name',
    sort: true,
    classes: 'sort',
    widthPercent: 18,
  },
  {
    name: 'lastName',
    accessor: 'Last Name',
    sort: true,
    classes: 'sort',
    widthPercent: 18,
  },
  {
    name: 'role',
    accessor: 'Role',
    sort: true,
    classes: 'sort',
    widthPercent: 15,
  },
  {
    name: 'userGroups',
    accessor: 'User Group(s)',
    sort: false,
    classes: 'sort',
    widthPercent: 15,
  },
  {
    name: 'trainings',
    accessor: 'Training(s)',
    sort: false,
    classes: 'sort',
    widthPercent: 18,
  },
  {
    name: 'actions',
    accessor: 'Options',
    sort: false,
    classes: 'text-center',
    widthPercent: 16,
  },
];

export const columnsBody = [
  {
    name: 'checkbox',
    accessor: 'Selected',
    Component: ({ order: { id, isChecked = false }, handleUpdateChecked }) => {
      return (
        <BodyCheckbox
          id={id}
          isChecked={isChecked}
          handleUpdateChecked={handleUpdateChecked}
        />
      );
    },
  },
  {
    name: 'firstName',
    accessor: 'First Name',
    Component: ({ order, name, columnsHidden, organizationId }) => (
      <DocumentLinkColumn
        order={order}
        name={name}
        columnsHidden={columnsHidden}
        title="First Name"
        link={`/organizations/${organizationId}/human-resources/${order.id}`}
      />
    ),
  },
  {
    name: 'lastName',
    accessor: 'Last Name',
    Component: ({ order, name, columnsHidden, organizationId }) => (
      <DocumentLinkColumn
        order={order}
        name={name}
        columnsHidden={columnsHidden}
        title="Last Name"
        link={`/organizations/${organizationId}/human-resources/${order.id}`}
      />
    ),
  },
  {
    name: 'role',
    accessor: 'Role',
  },
  {
    name: 'userGroups',
    accessor: 'User Group(s)',
    classes: '',
    Component: ({ order, name, columnsHidden }) => {
      const { userGroups } = order;

      return (
        <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
          <p className="td-before">User Group(s)</p>
          <TableCellText id={`HR${name}${order.id}`} text={userGroups} />
        </td>
      );
    },
  },
  {
    name: 'trainings',
    accessor: 'Trainings',
    classes: '',
    Component: ({ order, name, columnsHidden }) => {
      const { trainings } = order;

      return (
        <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
          <p className="td-before">Trainings</p>
          <TableCellText id={`HR${name}${order.id}`} text={trainings} />
        </td>
      );
    },
  },
  {
    name: 'actions',
    accessor: 'Actions',
    Component: ({
      order,
      columnsHidden,
      organizationId,
      user,
      onDeleteRow,
    }) => {
      const history = useHistory();

      const [deleteModal, updateDeleteModal] = useState({
        isOpen: false,
        values: {
          number: '',
          name: '',
        },
        model: {
          organizationId: '',
          ids: [],
        },
      });

      const handleDelete = (organizationId, id, firstName, lastName, role) => {
        updateDeleteModal({
          isOpen: true,
          values: {
            name: `${firstName || ''} ${lastName || ''} ${
              role ? `- ${role}` : ''
            }`,
          },
          model: { organizationId, ids: [id] },
        });
      };

      const handleRedirect = (organizationId, id) => {
        history.push(`/organizations/${organizationId}/human-resources/${id}`);
      };

      const { canBeDeleted, id } = order;

      return (
        <td
          className={`activate-column ${
            columnsHidden.actions ? '' : 'column-hidden'
          }`}
        >
          <p className="td-before">Actions</p>
          <div className="d-flex justify-content-start justify-content-md-center overflow-auto">
            <div className="d-flex column-user-active justify-content-start justify-content-md-end">
              <div className="actions">
                <div className="d-flex actions-inner">
                  <IconTooltip
                    icon="bx bx-show action"
                    id={`HRViewDetails${order.id}`}
                    text="View details"
                    onClick={() => handleRedirect(organizationId, order.id)}
                  />
                  <IconTooltip
                    icon="bx bxs-edit-alt action"
                    id={`HREdit${order.id}`}
                    text="Edit"
                    onClick={() => {
                      history.push({
                        pathname: `/organizations/${organizationId}/human-resources/${id}`,
                        state: { openEditMode: true },
                      });
                    }}
                  />
                  {canBeDeleted && (
                    <IconTooltip
                      icon="bx bxs-trash-alt action"
                      id={`HRDeleteDetails${order.id}`}
                      text="Delete"
                      onClick={() =>
                        handleDelete(
                          organizationId,
                          order.id,
                          order.firstName,
                          order.lastName,
                          order.role,
                        )
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <TaskDeleteModal
            text="Human Resource"
            footerText="It will be permanently deleted."
            isOpen={deleteModal.isOpen}
            model={deleteModal.model}
            values={deleteModal.values}
            onSubmit={(_, values) => {
              deleteHumanRecource(values)
                .then(() => {
                  onDeleteRow(values.ids[0]);
                  toast.success(
                    <MessageResetSuccess message={SUCCESS_RECORD_DELETE} />,
                  );
                })
                .finally(() => {
                  updateDeleteModal((prev) => ({ ...prev, isOpen: false }));
                });
            }}
            onCancel={() =>
              updateDeleteModal((prev) => ({ ...prev, isOpen: false }))
            }
          />
        </td>
      );
    },
  },
];
