import React from 'react';

import Checkbox from '../Checkbox';

const TextCheckbox = ({
  text,
  className,
  onChange,
  isChecked,
  disabled,
  id,
}) => {
  return (
    <div id={id} className={`${className} text-checkbox`}>
      <Checkbox
        id={`revision-checkbox-${id}`}
        isChecked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      {text}
    </div>
  );
};

export default TextCheckbox;
