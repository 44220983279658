export const ADMINS = 'ADMINS';
export const SUCCESS_ADMINS = 'SUCCESS_ADMINS';

export const CREATE_ADMIN = 'CREATE_ADMIN';
export const CREATE_ADMIN_SUCCESS = 'SUCCESS_CREATE_ORGANIZATION';

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const CLEAR_IMAGE_SUCCESS = 'CLEAR_IMAGE_SUCCESS';

// export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
// export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
