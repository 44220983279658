const { INITIAL_OFFSET, INITIAL_PAGE } = require('../../constants/table');

export const changeLimit = (pagination, limit) => ({
  ...pagination,
  offset: INITIAL_OFFSET,
  limit,
});

export const getInitialPage = (paginationPage) => ({
  ...paginationPage,
  currentPage: INITIAL_PAGE,
});

export const changeOffset = (pagination, selectedPage) => ({
  ...pagination,
  offset: Math.ceil(selectedPage * pagination.limit),
});

export const changePage = (paginationPage, selectedPage) => ({
  ...paginationPage,
  currentPage: selectedPage,
});

export const getPaginationChanges = (pagination, totalCount, limit) => {
  return {
    ...pagination,
    totalCount,
    pageCount: Math.ceil(totalCount / limit),
  };
};
