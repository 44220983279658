import React, { useCallback, useState } from 'react';
import { Col, Row } from 'reactstrap';

import SingleSelect from '../../../selects/Single';
import CustomSwitch from '../../CustomSwitch';
import SingleDisabledSelect from '../../../selects/SingleDisabled';

import {
  configureDocumentTypes,
  configureGetDocumentTypes,
} from '../../../../utils/config-get-organization';
import { FIRST_SWITCH } from '../../../../constants/helper';
import { useRouteMatch } from 'react-router-dom';
import { getDocumentTypes } from '../../../../utils/api/organization-api';
import { getAvailableDocuments } from '../../../../utils/api/document-api';

const LinkDocument = ({ selectValues, handleUpdate, handleUpdateLink }) => {
  const { id: organizationId } = useRouteMatch().params;

  const getDocumentTypesAPI = useCallback(async () => {
    const params = { isOnlyApproved: true };
    return getDocumentTypes(organizationId, params).then(({ data }) => data);
  }, [organizationId]);

  const [docTypeId, updateDocTypeId] = useState(null);

  const getDocumentsAPI = useCallback(
    async (search = '', docTypeId) => {
      const params = { organizationId, search, docTypeId };
      return getAvailableDocuments(params).then(({ data }) => data);
    },
    [organizationId],
  );

  return (
    <Row className="mt-2 mb-2">
      <Col md="4" xl="2" className="d-flex align-items-center">
        <div className="link-swicher">
          <CustomSwitch
            name="task-switch"
            isChecked={selectValues[FIRST_SWITCH]}
            onChange={() => handleUpdate(FIRST_SWITCH)}
          />
          <p className="mb-0">Document</p>
        </div>
      </Col>
      <Col md="8" xl="4" className="mt-2 mt-xl-0">
        <SingleSelect
          handleRequest={getDocumentTypesAPI}
          placeholder="Choose document type"
          configOptions={configureGetDocumentTypes}
          isDisabled={!selectValues[FIRST_SWITCH]}
          handleChange={(value) => updateDocTypeId(value?.id)}
        />
      </Col>
      <Col md="12" xl="6" className="mt-2 mt-xl-0">
        <SingleDisabledSelect
          handleRequest={getDocumentsAPI}
          placeholder="Choose document"
          configOptions={configureDocumentTypes}
          isDisabled={!(selectValues[FIRST_SWITCH] && !!docTypeId)}
          documentTypeId={docTypeId}
          className="w-100 mr-2"
          handleChange={(values) =>
            handleUpdateLink(values, 'documents', FIRST_SWITCH)
          }
        />
      </Col>
    </Row>
  );
};

export default LinkDocument;
