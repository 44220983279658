import React from 'react';
import classnames from 'classnames';

const SwitchOptionMulti = ({
  type,
  label,
  active,
  handleCheckedMulti,
  handleChangeGroupSettingMulti,
  organizationId,
  id,
  isOnlyView = false,
}) => {
  const isChecked = handleCheckedMulti(type);

  return (
    <div className={classnames('option-container', { active: active })}>
      <div className="custom-control custom-switch" dir="ltr">
        <input
          id={type}
          type="checkbox"
          className="custom-control-input"
          onChange={() =>
            handleChangeGroupSettingMulti(id, organizationId, type)
          }
          checked={isChecked}
          disabled={isOnlyView}
        />
        <label
          className={classnames('custom-control-label', { grey: !isChecked })}
          htmlFor={type}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default SwitchOptionMulti;
