import React from 'react';

import HelpImage from '../../shared/HelpImage';

import documentImage from '../../../../assets/images/help-screen/flow/flow-2.png';
import documentImage2 from '../../../../assets/images/help-screen/flow/flow-3x.png'
import documentImage3 from '../../../../assets/images/help-screen/flow/flow-4.png'
import documentImage4 from '../../../../assets/images/help-screen/flow/flow-5.png'
import documentImage5 from '../../../../assets/images/help-screen/flow/flow-6.png'
import documentImage6 from '../../../../assets/images/help-screen/flow/flow-7.png'
import documentImage7 from '../../../../assets/images/help-screen/flow/flow-8.png'
import documentImage8 from '../../../../assets/images/help-screen/flow/flow-9.png'
import documentImage9 from '../../../../assets/images/help-screen/flow/flow-10.png'

export const documentTextsLeft = [
  {
    text: 'Document Types',
    modalContent: (
      <>
        <p>
          Document Types can be used to classify documents within your Quality
          Management System. Document Types are established for the Organization
          within the Organization Settings. The Document Types listed in
          Settings will be available to all users via drop-down when adding a
          new document to the system.
        </p>
        <p>
          Document Types should be set up to reflect the documentation system
          established within the organization. There is no limit to the number
          of Document Types.{' '}
        </p>
        <p>
          Document Types are configured on the Organization Settings.
          Administrators have full access permissions on this screen. Users may
          have access if their User Group has the permission for “Organization
          Settings” turned on.
        </p>
        <HelpImage src={documentImage} />
        <p>
          Document Types can be created and deleted through Organization
          Settings. All the available Document Types displayed in Organization
          Settings will be available for document creation.
        </p>
        <HelpImage src={documentImage2} />
      </>
    ),
  },
  {
    text: 'Add a New Document',
    modalContent: (
      <>
        <p>
          If a user has permission to add a document, select the “Add New”
          button on the Documents screen
        </p>
        <HelpImage src={documentImage7} />
        <p>
          All required fields, which are marked with an asterisk, must be filled
          when creating a new document.
        </p>
        <HelpImage src={documentImage8} />
        <p>
          Document Type is one such required field. It pulls information from
          the Organization Settings. If there are no Document Types in the
          Organization, it will be impossible to create any Document.
        </p>
        <p>
          Another specific field is the “Revision”. When a document is created,
          a revision number news to be specified.
        </p>
        <p>
          The combination of Document Type, Name, and Revision of a single
          Document must be unique within the Organization. Two documents cannot
          be created with the same Type, Name and Revision.
        </p>
        <p>
          Document ID may be used to indicate the document identification number
          or other ID. In the Documents table, Document Type is concatenated
          with the Document ID. For example, if the Document Type was “SOP” and
          the Document ID was 500, “SOP-500” would be displayed in the first
          column of the Documents table.
        </p>
      </>
    ),
  },
  {
    text: 'Revisions',
    modalContent: (
      <>
        <p>
          Once a document is created, additional revisions may be created as
          part of the same document using the “+ New Revision” button. Revisions
          can be created, edited and deleted based on a user’s access
          permissions. These actions are allowed if a User has any kind of
          permission to edit and is related to at least one Document Revision.
        </p>
        <HelpImage src={documentImage6} />
        <p>
          A specific document revision can be “Set as Final” by a user with edit
          permissions and a relationship to the Document containing the Revision
          controlled. The Final revision is the revision that will open by
          default when a document is selected. A revision will automatically be
          marked as Final when the revision is approved.
        </p>
        <p>
          After the document is created, each next new revision number will be
          assigned automatically, and it will always be one integer larger than
          the largest revision number.
        </p>
        <p>
          If a User has permission to view a particular Document, they can
          switch between its Revisions.
        </p>
        <p>
          Permission to edit is revision-specific and depends on the settings
          that get applied on individual User permissions. Setting names,
          tool-tips and explanative labels in User Group Settings provide
          detailed information about the user's possible relation to documents
          and permissions depending upon these relationships.
        </p>
      </>
    ),
  },
  {
    text: 'Document Details',
    modalContent: (
      <>
        <p>Each document revision has the sections:</p>
        <ul className="list-default">
          <li>
            <p className="mb-1">General:</p>
            <p>
              Contains main information about the document. Some fields are
              required (marked with asterisks).
            </p>
          </li>
          <li>
            <p className="mb-1">Quality Actions:</p>
            <p>
              Allows the user to select Document Revision Reviewers and
              Approvers. This is not required. When a Reviewer or an Approver is
              selected here, they get an Action automatically assigned for
              document review or approval. This section is optional, can be left
              empty - depending upon if the document revision needs to pass
              review and approval validation.
            </p>
          </li>
          <li>
            <p className="mb-1">References:</p>
            <p>
              Allows the user to add links to various artifacts in the current
              Organization. This is not required. This section is useful when a
              User needs to indicate any specific Document Revision, or an HR
              Record, or a Training, or an Action which is anyhow related to the
              current document. References are revision-specific. E.g., A user
              may reference a Complaint Document in a CAPA Document if that
              Complaint led to the CAPA being opened. Also, if the CAPA required
              re-training, the user could reference the Training Record in the
              CAPA.
            </p>
          </li>
          <li>
            <p className="mb-1">Review:</p>
            <p>
              The Review Section of the document will contain the Review
              responses of any users who are assigned as a Reviewer and the user
              completes their Review. Only users added as a Reviewer in Quality
              Actions may edit the Review Section. Other users may have
              read-only access here, and see who Reviewed or Rejected the
              document revision. Reviewers may submit comments when they submit
              their “Reviewed/Rejected” responses.
            </p>
          </li>
          <li>
            <p className="mb-1">Approval:</p>
            <p>
              The Approval Section of the document will contain the Approval
              responses of any users who are assigned as an Approver and the
              user completes their Approval. Only users added as an Approver in
              Quality Actions may edit the Approval Section. Approval cannot be
              completed until all assigned Reviews have been completed for that
              revision and the revision is not rejected. Other users may have
              read-only access here, and see who Approved or Rejected the
              document revision. Approvers may submit comments when they submit
              their “Approved/Rejected” responses. Approvers must enter their
              correct access code when submitting their response. Access codes
              are delivered via email to users. If a user loses their access
              code, an Admin can resend the access code to that user. The
              approval response is a 21 CFR Part 11 compliant e-signature
              process.
            </p>
          </li>
        </ul>
      </>
    ),
  },
];

export const documentTextsRight = [
  {
    text: 'Document Status',
    modalContent: (
      <>
        <p>
          A newly created Document Revision has status “Submitted” by default.
          The Document status is automatically changed during the review and
          approval processes.
        </p>
        <p>
          The Review and Approval Sections will both have statuses of “Not
          Started”, “In-Progress”, or “Completed”. If no Reviewers or Approvers
          have submitted a response, the status of that section will be “Not
          Started”. If at least one response has been submitted, but multiple
          Reviewers or Approvers are assigned and not all responses have been
          received, the status of that section will be “In Progress”. Documents
          cannot be edited while a Review or Approval is In Progress. Once all
          assigned Reviewers or Approvers submit a response, the status of the
          section will be “Completed”. Document Approval cannot be completed
          until Review is completed.{' '}
        </p>
        <p>
          The overall Document Revision status will be automatically updated
          based on the responses received during the Review and Approval
          processes. When all Review responses are received and none are
          rejections, the Document Revision status will be updated to
          “Reviewed”. When all Approval responses are received and none are
          rejections, the Document Revision status will be updated to
          “Approved”.
        </p>
        <p>
          If any Reviewer or Approver submits a rejection response, the Document
          status for that revision will be updated to “Rejected”. The document
          can then be updated by anyone with edit permissions. Then the Reviewer
          or Approver who responded with the rejection can edit their Review or
          Approval response. This is repeated until the Document is approved.
        </p>
        <p>
          If new Reviewers or Approvers are assigned after a document is
          Reviewed or Approved, the Document Revision status will be updated to
          “Submitted” until the responses are received for the new assignees.
          The Review or Approval statuses will also be updated to “In Progress”
          at this time if there are existing Reviews or Approvals.
        </p>
        <p>
          When a document revision becomes Approved, it is set as Final
          automatically. Users with permission to edit can change the Final
          revision selection manually by clicking the checkbox in another
          revision. The Final revision will be the one opened by default when
          the document is selected.
        </p>
      </>
    ),
  },
  {
    text: 'Requires Training',
    modalContent: (
      <>
        <p>
          On Document Revision creation, or when editing, it is possible to mark
          it as “Requires Training” in the “Quality Actions” section. The
          checkbox should be selected for any Documents where users are required
          to complete training related to that Document. 
        </p>
        <HelpImage src={documentImage9} />
        <p>
          Training is conducted using the Training section of Q Suite. See below for more information
          on Training.
        </p>
        <p>
          When indicating that training is required for a particular document,
          two options will be enabled upon Document Approval: “Link Training”
          and “Create & Link Training”. These options will only be enabled once
          the document status is “Approved”. An action will be automatically
          generated for the user identified in the “Responsible” field of the
          Document Revision when the Document status changes to “Approved” to
          remind the user to create and/or link the training record. This action
          is only generated if “Requires Training” is selected.
        </p>
        <p>
          “Link Training” is used to link an existing training to the current
          Document Revision. “Create & Link Training” is used to create a new
          training and link it to the current Document Revision.
        </p>
        <p>
          Linking a Training to the Document Revision allows the system to
          indicate a relationship between a Document Revision and a specific
          Training record based on this documentation. Adding a Training inserts
          a link to the current Document Revision in the Training linked. See
          below for more information on creating Trainings.
        </p>
      </>
    ),
  },
  {
    text: 'Export Attachments',
    modalContent: (
      <>
        <HelpImage src={documentImage3} />
        <p>
          Reports and exports of data can be accomplished using the features
          above the tables for all artifacts. Exports include:
        </p>
        <ul className="list-default">
          <li>
            <p>
              CSV File - Download of the selected data from the data in .CSV
              format. If no selections are made, all data will be included in
              the CSV.{' '}
            </p>
          </li>
          <li>
            <p>
              PDF Archive - Multiple PDF files will be generated based on the
              selections in the table. The PDF will be a summary of the item
              details. For Documents, the default Revision will be used. If no
              selections are made, a PDF summary will be generated for all items
              in the table.
            </p>
          </li>
          <li>
            <p>
              Single PDF - Download of the selected items in the table in .PDF
              format. This will print a copy of the table, with current
              filters/selections applied. If no selections are made, all data
              will be included in the PDF.
            </p>
          </li>
        </ul>
        <p>
          In addition to the report-export features (CSV file, PDF archive,
          Single PDF), which are commonly available for all artifacts, Documents
          have an “Attachments” export in the Options column or through the
          Export dropdown.
        </p>
        <p>
          Each Document may have several Revisions. Only the default Revision
          data is displayed in the Document's row in the table of Documents. If
          you export an attachment, you will export only the controlled document
          (a file required for Document Revision creation) - from the default
          Revision. A single file will be exported, the default one.
        </p>
        <HelpImage src={documentImage4} />
        <p>
          If you use the Export dropdown for Attachments, you will be required
          to select Document Types for which you want to export the attachments.
          If you select all, this will export attachments from all Document
          Revisions and the archive of attachments will contain the controlled
          files and the extra file(s) grouped by Document Types, then by
          Document Names, then by Revisions. All Attachments from all Revisions
          will be included.
        </p>
        <p>
          If you select a particular Document Type(s), this will export all the
          Attachments from all Revisions of the type(s) selected. The
          Attachments will be grouped inside an archive in the same way as
          described above.
        </p>
        <HelpImage src={documentImage5} />
      </>
    ),
  },
];
