import React from 'react';

const CustomSelect = ({ list = [], onChange, currentOption }) => {
  return (
    <div className="custom-select-wrapper">
      <select
        className="custom-select custom-select-revision"
        onChange={onChange}
        value={currentOption}
      >
        {list.map((item) => {
          return (
            <option
              key={item}
              value={item}
              defaultValue={item === currentOption}
            >
              Revision {item}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default CustomSelect;
