import React, { useEffect } from 'react';
import { Row, Col, Card, CardBody, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { useQueryHelper } from '../../../utils/query-params-helper';
import { useEmptyForm } from '../useEmptyForm';
import { userSetPassword, isValidSetToken } from '../../../store/actions';

import {
  PASSWORD_VALIDATION,
  PASSWORD_VALIDATION_ERROR,
  PASSWORD_MATCH_ERROR,
} from '../../../constants/form';
import { validatePasswordLength } from '../../../constants/validatePassword';

const getValidatePassword = (
  _,
  { password, confirmPassword },
  input,
  callback,
) => {
  password === confirmPassword
    ? callback(true)
    : callback(PASSWORD_MATCH_ERROR);
};

const validatePassword = {
  pattern: {
    value: PASSWORD_VALIDATION,
    errorMessage: PASSWORD_VALIDATION_ERROR,
  },
  ...validatePasswordLength,
};

const confirmValidatePassword = {
  async: getValidatePassword,
  ...validatePasswordLength,
};

const SetPasswordPage = ({ userSetPassword, isValidSetToken }) => {
  const history = useHistory();

  const { email, token } = useQueryHelper(['email', 'token']);

  const formInputs = ['password', 'confirmPassword'];
  const { disabled, onChange, values } = useEmptyForm(formInputs);

  useEffect(() => {
    isValidSetToken({ email, token }, history);
  }, [token, email, history, isValidSetToken]);

  const handleValidSubmit = (event, { password, confirmPassword }) => {
    userSetPassword({ email, token, password, confirmPassword }, history);
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-12 text-center">
                      <div className="login-header">
                        <h5>Set Password</h5>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(event, values) =>
                        handleValidSubmit(event, values)
                      }
                    >
                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          className="form-control"
                          placeholder="Enter password"
                          type="password"
                          value={values.password}
                          onChange={onChange}
                          validate={validatePassword}
                        />
                      </div>
                      <div className="form-group">
                        <AvField
                          name="confirmPassword"
                          label="Confirm Password"
                          className="form-control"
                          placeholder="Confirm password"
                          type="password"
                          value={values.confirmPassword}
                          onChange={onChange}
                          validate={confirmValidatePassword}
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn w-md waves-effect waves-light w-100 form-button"
                            type="submit"
                            disabled={disabled}
                          >
                            Set Password
                          </button>
                        </Col>
                      </Row>
                      <div className="mt-4 text-center">
                        <Link to="/login" className="text-password">
                          Back to login
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default connect(null, { userSetPassword, isValidSetToken })(
  SetPasswordPage,
);
