import { ADMIN_ROLE } from '../constants/helper';
import { ACTIVE } from '../constants/modals';

export const getStatusPipe = (status) => {
  return status === ACTIVE ? 'Set Inactive' : 'Set Active';
};

export const getUserRole = (user) => {
  return user === ADMIN_ROLE ? 'Admin' : 'User';
};

export const hasUserManyCompanies = (count) => {
  return count > 1;
};
