import React from 'react';

import PassedTrainingList from '../PassedList';
import PendingTrainingList from '../PendingList';

const TrainingCompletionView = ({ user, completionData }) => {
  const { pendingUsers, passedTrainingUsers } = completionData;

  return (
    <>
      <div className="general-header">
        <h6>Completion progress</h6>
      </div>
      <hr />
      <PassedTrainingList passedTrainingUsers={passedTrainingUsers} />
      <PendingTrainingList pendingUsers={pendingUsers} />
    </>
  );
};

export default TrainingCompletionView;
