export const humanResourcesDetailsModel = {
  id: null,
  firstName: '',
  lastName: '',
  role: '',
  status: null,
  createdAt: '',
  documents: [],
  trainings: [],
  userGroups: [],
  tags: [],
  file: null,
  activated: null,
  canBeEdited: false,
  canBeDeleted: false,
};
