import React from 'react';
import Checkbox from '../../../../pages/Organization/TrainingRecords/Details/shared/Checkbox';

const ExportLogsChanges = ({ isChecked, onChange }) => {
  return (
    <div className="d-flex align-items-center">
      <Checkbox isChecked={isChecked} onChange={onChange} />
      Changes with Organizations
    </div>
  );
};

export default ExportLogsChanges;
