import React from 'react';
import DocumentText from '../DocumentContent/DocumentText';

import { actionsTexts } from './actionsContentMok';
import actionImage from '../../../../assets/images/help-screen/flow/flow-11.png';
import actionImage2 from '../../../../assets/images/help-screen/flow/flow-12.png';

import HelpImage from '../../shared/HelpImage';

const ActionsContent = () => {
  return (
    <>
      <p>
        An Action is an artifact that allows the Organization to track tasks.
        Users receive notifications and emails when Actions are assigned to
        them. Notifications can be turned off using the “Notifications” button
        in the User’s Profile.
      </p>
      <HelpImage src={actionImage} />
      <p>
        In order for Actions to be viewed and edited by a User, the User must
        have at least one setting for access to Actions turned on through the
        User Group access permission settings, which are controlled by Admins.
      </p>
      <HelpImage src={actionImage2} />
      <div className="help-document-content">
        <div className="help-document-content__list">
          {actionsTexts.map((props, index) => (
            <DocumentText key={index} {...props} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ActionsContent;
