import React from 'react';

import ChangePasswordProfile from '../../../pages/Authentication/Profile/ChangePassword';
import ProfileCardTitle from '../shared/CardTitle/CardTitle';

const ProfilePassword = () => {
  return (
    <div>
      <ProfileCardTitle title="Change Password" />
      <hr className="divider-top" />
      <ChangePasswordProfile />
    </div>
  );
};

export default ProfilePassword;
