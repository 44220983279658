import React from 'react';

const SwitcherCheckbox = ({ id, onChange, isChecked, disabled, text }) => {
  return (
    <div className="custom-control custom-switch" dir="ltr">
      <input
        id={id}
        type="checkbox"
        className="custom-control-input"
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className="custom-control-label" htmlFor={id}>
        {text}
      </label>
    </div>
  );
};

export default SwitcherCheckbox;
