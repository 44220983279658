import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import {
  deleteUserGroup,
  editUserGroup,
} from '../../../../utils/backend-helper';
import MessageResetSuccess from '../../../../components/ToastSuccess';
import {
  SUCCESS_DUPLICATE_GROUP,
  SUCCESS_USER_GROUP_DELETE,
  SUCCESS_USER_GROUP_EDIT,
} from '../../../../constants/messages';
import UserGroupDeleteModal from '../../../../modals/UserGroupDelete';
import UserGroupEditModal from '../../../../modals/UserGroupEdit';
import UserGroupDuplicateModel from '../../../../modals/UserGroupDublicate';
import { duplicateUserGroups } from '../../../../utils/api/user-groups-api';
import CheckboxHead from '../../../../components/tables/HeadCheckbox';
import BodyCheckbox from '../../../../components/tables/BodyCheckbox';
import TableCellText from '../../../../components/tables/TableCellText';
import IconTooltip from '../../../../components/tooltips/IconTooltip';

export const columnsHead = [
  {
    name: 'checkbox',
    accessor: '',
    sort: false,
    Component: ({
      provided,
      snapshot,
      checkAllDocuments,
      checkedHead,
      pagination: { data },
    }) => {
      return (
        <CheckboxHead
          data={data}
          checkedHead={checkedHead}
          checkAllDocuments={checkAllDocuments}
          provided={provided}
          snapshot={snapshot}
        />
      );
    },
  },
  {
    name: 'name',
    accessor: 'Group',
    sort: true,
    classes: 'column-title sort',
    widthPercent: 50,
  },
  {
    name: 'userCount',
    accessor: 'Users',
    sort: true,
    classes: 'column-title sort',
    widthPercent: 25,
  },
  {
    name: 'activeted',
    accessor: 'Options',
    classes: 'text-center',
    sort: false,
    widthPercent: 25,
  },
];

export const columnsBody = [
  {
    name: 'checkbox',
    accessor: 'Selected',
    Component: ({ order: { id, isChecked = false }, handleUpdateChecked }) => {
      return (
        <BodyCheckbox
          id={id}
          isChecked={isChecked}
          handleUpdateChecked={handleUpdateChecked}
        />
      );
    },
  },
  {
    name: 'name',
    accessor: 'Group',
    classes: '',
    Component: ({ order, name, columnsHidden, organizationId }) => {
      const history = useHistory();
      const { id } = order;

      const handlerRedirect = (id, listName = '') => {
        history.push({
          pathname: `/organizations/${organizationId}/user-groups/${id}`,
          state: { listName },
        });
      };

      return (
        <td
          key={name}
          onClick={() => handlerRedirect(id, order.name)}
          className={classnames('cursor-pointer', {
            'column-hidden': !columnsHidden[name],
          })}
        >
          <p className="td-before">Group</p>
          <TableCellText
            id={`UserGroup${name}${order.id}`}
            text={order[name]}
          />
        </td>
      );
    },
  },
  {
    name: 'userCount',
    accessor: 'Users',
  },
  {
    name: 'activeted',
    accessor: 'Actions',
    classes: '',
    Component: ({
      order,
      columnsHidden,
      organizationId,
      updatingTable,
      pagination,
      onDeleteRow,
    }) => {
      const history = useHistory();

      const handlerRedirect = (id, listName = '') => {
        history.push({
          pathname: `/organizations/${organizationId}/user-groups/${id}`,
          state: { listName },
        });
      };

      const [editModal, setEditModal] = useState({
        isOpen: false,
        model: {
          routeId: null,
          name: '',
        },
      });

      const handleToogleEditModal = (routeId, id, name) => {
        setEditModal((prev) => ({
          isOpen: !prev.isOpen,
          model: {
            routeId,
            name,
            id,
          },
        }));
      };

      const [deleteModal, setDeleteModal] = useState({
        isOpen: false,
        isMulti: false,
        model: {
          id: null,
          organizationId: null,
          name: '',
          groupCount: null,
        },
      });

      const handleDelete = (
        organizationId,
        id,
        userCounter,
        name,
        totalCountGroup,
      ) => {
        if (!userCounter) {
          deleteUserGroup(id, organizationId).then(() => {
            onDeleteRow(id);
            toast.success(
              <MessageResetSuccess message={SUCCESS_USER_GROUP_DELETE} />,
            );
          });
        } else {
          setDeleteModal((prev) => ({
            isOpen: !prev.isOpen,
            isMulti: totalCountGroup > 1,
            model: { organizationId, id, userCounter, name },
          }));
        }
      };

      const [duplicateModal, updateDuplicateModal] = useState({
        isOpen: false,
        model: {
          organizationId: null,
          sourceGroupId: null,
        },
      });

      const handlerDuplicate = (organizationId, sourceGroupId) => {
        updateDuplicateModal((prev) => ({
          isOpen: !prev.isOpen,
          model: { organizationId, sourceGroupId },
        }));
      };

      const closeDuplicate = () => {
        updateDuplicateModal((prev) => ({ ...prev, isOpen: false }));
      };

      return (
        <>
          <td
            className={`activate-column ${
              columnsHidden.activeted ? '' : 'column-hidden'
            }`}
          >
            <p className="td-before">Actions</p>
            <div className="user-group__activated-column">
              <div className="actions">
                <IconTooltip
                  icon="bx bxs-duplicate"
                  id={`UserGroupDuplicate${order.id}`}
                  text="Duplicate"
                  onClick={() => handlerDuplicate(organizationId, order.id)}
                />
                <IconTooltip
                  icon="bx bx-show action"
                  id={`UserGroupView${order.id}`}
                  text="View details"
                  onClick={() => handlerRedirect(order.id, order.name)}
                />
                <IconTooltip
                  icon="bx bxs-edit-alt action"
                  id={`UserGroupEdit${order.id}`}
                  text="Edit"
                  onClick={() =>
                    handleToogleEditModal(organizationId, order.id, order.name)
                  }
                />
                <IconTooltip
                  icon="bx bxs-trash-alt action"
                  id={`UserGroupDelete${order.id}`}
                  text="Delete"
                  onClick={() =>
                    handleDelete(
                      organizationId,
                      order.id,
                      order.userCount,
                      order.name,
                      pagination.totalItems,
                    )
                  }
                />
              </div>
            </div>
          </td>
          <UserGroupEditModal
            values={editModal.model}
            isOpen={editModal.isOpen}
            onSubmit={(event, { id, organizationId, name }) => {
              editUserGroup(id, organizationId, name).then(() => {
                updatingTable(true);
                toast.success(
                  <MessageResetSuccess message={SUCCESS_USER_GROUP_EDIT} />,
                );
                setEditModal((prev) => ({ ...prev, isOpen: false }));
              });
            }}
            onCancel={() =>
              setEditModal((prev) => ({ ...prev, isOpen: false }))
            }
          />
          <UserGroupDeleteModal
            values={deleteModal.model}
            isOpen={deleteModal.isOpen}
            isMulti={deleteModal.isMulti}
            organizationId={organizationId}
            onSubmit={(
              event,
              { id, organizationId, groupReceiverId },
              callback,
            ) => {
              deleteUserGroup(id, organizationId, groupReceiverId).then(() => {
                onDeleteRow(id);
                toast.success(
                  <MessageResetSuccess message={SUCCESS_USER_GROUP_DELETE} />,
                );
                callback();
                setDeleteModal((prev) => ({ ...prev, isOpen: false }));
              });
            }}
            onCancel={() =>
              setDeleteModal((prev) => ({ ...prev, isOpen: false }))
            }
          />
          <UserGroupDuplicateModel
            isOpen={duplicateModal.isOpen}
            model={duplicateModal.model}
            onCancel={closeDuplicate}
            onSubmit={(_, values, callback) => {
              duplicateUserGroups(values).then(() => {
                updatingTable(true);
                toast.success(
                  <MessageResetSuccess message={SUCCESS_DUPLICATE_GROUP} />,
                );
                callback();
                closeDuplicate();
              });
            }}
          />
        </>
      );
    },
  },
];
