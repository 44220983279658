import React from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Index from '../../components/Button';

const DeleteDocumentTagModal = ({
  onSubmit,
  isOpen,
  onCancel,
  values,
  model,
}) => {
  const { register, handleSubmit } = useForm();

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Delete Tag</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <form className="form-horizontal mt-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-body">
          <p className="text-modal">
            Are you sure you want to delete the tag <b>{values.name}</b>? It
            will be deleted from all artifacts in the current Organization.
          </p>
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Index
                type="submit"
                color="btn btn-danger w-md waves-effect waves-light delete-btn mr-3"
              >
                Delete
              </Index>
              <Index
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Index>
            </Col>
          </Row>
        </div>
        <input
          ref={register}
          name="organizationId"
          type="hidden"
          value={model.organizationId}
        />
        <input ref={register} name="tagId" type="hidden" value={model.id} />
      </form>
    </Modal>
  );
};

export default DeleteDocumentTagModal;
