export const changeCheckedAll = (pagination, isCheckedAll) => {
  return {
    ...pagination,
    data: pagination.data.map((item) => ({
      ...item,
      isChecked: !isCheckedAll,
    })),
  };
};

export const changeCheckedAllHidden = (list, offset, limit, isCheckedAll) => {
  return [
    ...list.slice(0, offset),
    ...list.slice(offset, offset + limit).map((item) => ({
      ...item,
      isChecked: !isCheckedAll,
    })),
    ...list.slice(offset + limit),
  ];
};
