import moment from 'moment';

export const ASC = 'asc';
export const DESC = 'desc';

export const LIMIT = 25;
export const TOTAL_ITEMS = 0;
export const PAGE_COUNT = 0;
export const INITIAL_OFFSET = 0;
export const INITIAL_PAGE = 0;
export const EMPTY_STRING = '';
export const MAX_LIMIT = 10000;

export const ORDER_BY_KEY = 'Order.Key';
export const ORDER_DIRECTION_KEY = 'Order.Direction';
export const DATE_RANGE_START = 'DateRange.Start';
export const DATE_RANGE_END = 'DateRange.End';

export const INACTIVE_STATUS = 1;
export const ACTIVE_STATUS = 0;

export const SEARCH_LIMIT = 20;

export const ALL = '0';
export const ALL_ASSIGNEE = '1';
export const PENDING = '2';
export const PASSED = '3';

export const INITIAL_CHECKED = 0;

export const ID_KEY = 'id';
export const IS_CHECKED_KEY = 'isChecked';

export const SELECT_OPTIONS = [10, 25, 50, 100];

export const SECTION_TYPE = [
  'Document',
  'Action',
  'Training Record',
  'HR Record',
];

export const KEY_ENTER = 'Enter';

export const INFO_TYPES = [
  {
    type: 'Admins',
    isChecked: false,
  },
  {
    type: 'User Groups',
    isChecked: false,
  },
  {
    type: 'Users',
    isChecked: false,
  },
  {
    type: 'Access Codes',
    isChecked: false,
  },
  {
    type: 'Logo',
    isChecked: false,
  },
  {
    type: 'Tags',
    isChecked: false,
  },
  {
    type: 'Document Types',
    isChecked: false,
  },
  {
    type: 'Documents & Document Revisions',
    isChecked: false,
  },
  {
    type: 'Actions',
    isChecked: false,
  },
  {
    type: 'Training Records',
    isChecked: false,
  },
  {
    type: 'HR Records',
    isChecked: false,
  },
];

export const ALL_ORGANIZATIONS = 'ALL_ORGANIZATIONS';
export const ALL_ACTIVE_ORGANIZATIONS = 'ALL_ACTIVE_ORGANIZATIONS';
export const ALL_INACTIVE_ORGANIZATIONS = 'ALL_INACTIVE_ORGANIZATIONS';

export const INITIAL_EXPORT_LOGS = {
  search: EMPTY_STRING,
  InfoTypes: INFO_TYPES,
  [DATE_RANGE_START]: null,
  [DATE_RANGE_END]: null,
  IncludeOrganizationChanges: false,
  SelectedIds: [],
};

export const MIN_EXPORT_DATE = moment().subtract(3, 'M').toDate();
export const CURRENT_DATE = new Date();

export const CHECKBOX_WIDTH = 94;
export const GLOBAL_SEARCH_WIDTH = 22;
export const ONE_HUNDRED_PERCENT = 100;
