import React from 'react';

import CardTitle from '../../shared/CardTitle';
import HelpTabContent from '../shared/TabContent';

import { mokDataNotesSuperAdmin } from './notesSuperAdminMok';

const HelpNotesSuperAdmin = () => {
  return (
    <div>
      <CardTitle title="Notes about the Medavice Super Admin" />
      <HelpTabContent mokData={mokDataNotesSuperAdmin} />
    </div>
  );
};

export default HelpNotesSuperAdmin;
