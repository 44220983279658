import { LocalStorage } from './/storage';
import { keyUser, keySession } from '../constants/api';
import { storages } from '../constants/storages';

const storage = new LocalStorage();

export const mergeStorage = (user) => {
  const currentStorage = storage.getItem(keySession);
  if (!currentStorage) return;

  const { role, organizationCount } =
    storages[currentStorage].getItem(keyUser) || {};

  user = { ...user, role, organizationCount };

  storages[currentStorage].setItem(keyUser, user);
  return;
};
