import React from 'react';

import { deleteAvatar } from '../../../../utils/api/profile-api';

const ButtonDeletePhoto = ({ handleUpdateUser }) => {
  const handleDelete = async () => {
    const { data } = await deleteAvatar();
    handleUpdateUser(data);
  };

  return (
    <span className="delete-photo-button" onClick={handleDelete}>
      Delete photo
    </span>
  );
};

export default ButtonDeletePhoto;
