import React from 'react';
import { Col, Row } from 'reactstrap';

import CustomPagination from '../../shared/Pagination';
import TableShowEntries from '../ShowEntries';

const TableFooter = ({
  limit,
  handleChangeLimit,
  pageCount,
  pageNumber,
  handlePageClick,
}) => {
  return (
    <Row className="mt-3">
      <Col xs="12" sm="6">
        <TableShowEntries limit={limit} handleChangeLimit={handleChangeLimit} />
      </Col>
      <Col xs="12" sm="6" className="d-flex justify-content-end">
        <CustomPagination
          pageCount={pageCount}
          forcePage={pageNumber}
          handlePageClick={handlePageClick}
        />
      </Col>
    </Row>
  );
};

export default TableFooter;
