import React from 'react';

const InfoColumn = ({ title, text, classes }) => {
  return (
    <div className={`info-column ${classes ? classes : ''}`}>
      <p className="info-column__title">{title}</p>
      <p className="info-column__text">{text}</p>
    </div>
  );
};

export default InfoColumn;
