import React from 'react';
import { connect } from 'react-redux';

import ProfileMenu from '../../../Profile/Menu';
import NotificationDropdown from '../NotificationDropdown';
import SearchHeader from '../../../organization/SearchHeader';
import HeaderLogo from '../../../shared/HeaderLogo';

import { withNamespaces } from 'react-i18next';
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from '../../../../store/actions';
import {
  leftSideBarDefault,
  leftSideBarCondensed,
} from '../../../../constants/sidebarTypes';

const Header = ({
  changeSidebarType,
  toggleLeftmenu,
  leftMenu,
  leftSideBarType,
  organizationImage,
  user,
}) => {
  const tToggle = () => {
    toggleLeftmenu(!leftMenu);
    if (leftSideBarType === leftSideBarDefault) {
      changeSidebarType(leftSideBarCondensed);
    } else if (leftSideBarType === leftSideBarCondensed) {
      changeSidebarType(leftSideBarDefault);
    }
  };

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box d-none d-lg-flex justify-content-center align-items-center">
            <div className="logo logo-light">
              <span className="logo-lg page-title-box">
                {organizationImage && (
                  <img
                    className="header-logo-lg"
                    src={organizationImage.originalPath}
                    alt="logo"
                  />
                )}
              </span>
              <HeaderLogo organizationImage={organizationImage} />
            </div>
          </div>
          <button
            type="button"
            onClick={() => {
              tToggle();
            }}
            className="btn btn-sm px-3 font-size-16 header-item waves-effect"
            id="vertical-menu-btn"
          >
            <i className="fa fa-fw fa-bars"></i>
          </button>
          <SearchHeader />
        </div>
        <div className="d-flex">
          <NotificationDropdown />
          <ProfileMenu user={user} />
        </div>
      </div>
    </header>
  );
};

const mapStatetoProps = ({
  Layout: { layoutType, showRightSidebar, leftMenu, leftSideBarType },
}) => ({
  layoutType,
  showRightSidebar,
  leftMenu,
  leftSideBarType,
});

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withNamespaces()(Header));
