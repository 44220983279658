export const getTableNamesColumn = (columns) => {
  if (!columns) return [];

  return Object.keys(columns).reduce((acc, key) => {
    const { isVisible, accessor } = columns[key];

    if (accessor && isVisible) {
      acc.push(accessor);
    }

    return acc;
  }, []);
};
