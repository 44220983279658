import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import classnames from 'classnames';

const ReviewComment = ({ comment }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="review-comment">
      <p
        className="review-comment__title"
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        Comment{' '}
        {comment && (
          <i
            className={classnames('bx bx-chevron-down arrow', {
              downArrow: isOpen,
            })}
          ></i>
        )}
      </p>
      <Collapse isOpen={isOpen}>
        <div className="comment-container">
          <p> {comment} </p>
        </div>
      </Collapse>
    </div>
  );
};

export default ReviewComment;
