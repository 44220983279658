import React, { useState, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Card } from 'reactstrap';

import { getClientUser } from '../../../../utils/backend-helper';
import { useIsCanViewDocument } from '../../../../contexts/ViewDocument';
import { useErrorHandler } from '../../../../custom-hooks/useErrorHandler';

const UserView = ({ id, user, children }) => {
  const organizationId = useRouteMatch().params.id;
  const onError = useErrorHandler();

  const [userData, updateUserData] = useState(null);
  const { updateIsCanViewDocuments, updateClientUserAccess } =
    useIsCanViewDocument();

  const getUser = useCallback(() => {
    return getClientUser(id, organizationId)
      .then(({ data }) => {
        if (data.canViewDocuments) {
          updateIsCanViewDocuments(true);
        }
        updateClientUserAccess(data);
        updateUserData(data);
      })
      .catch(onError);
  }, [id, organizationId, updateClientUserAccess, updateIsCanViewDocuments, onError]);

  useEffect(() => {
    getUser(id, organizationId);
  }, [organizationId, id, getUser]);

  if (userData && !userData.canViewDocuments && !userData.canViewTasks) {
    return (
      <div className="user-content">
        <div className="inner-content">
          <Card className="custom-card">
            <div className="custom-card__body">
              <p>
                You currently have <b>no access permissions allowed</b> within
                the current Organization. Please, contact your administrator or
                support team for more information and help
              </p>
            </div>
          </Card>
        </div>
      </div>
    );
  }

  return children;
};

export default UserView;
