import React, { useCallback, useState } from 'react';
import { Modal } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import ImageCropper from '../../../shared/ImageCropper';
import { validationImageCropper } from '../../../../utils/validation-cropper';
import { fileToDataURL } from '../../../../utils/file-helper';
import { uploadAvatar, uploadImage } from '../../../../utils/backend-helper';

const ButtonUploadPhoto = ({ text, handleUpdateUser }) => {
  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles.length) return;
    handleAcceptedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [cropperModal, updateCropperModal] = useState({
    isOpen: false,
    urlFile: null,
    isLoading: false,
  });

  const handleLoading = () => {
    updateCropperModal((prev) => ({ ...prev, isLoading: true }));
  };

  const handleToggleModal = () => {
    updateCropperModal((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const handleSave = async (body) => {
    const { data } = await uploadImage(body);
    const response = await uploadAvatar(data.id);

    handleUpdateUser(response.data);
    updateCropperModal((prev) => ({
      ...prev,
      isOpen: false,
      isLoading: false,
    }));
  };

  const handleAcceptedFile = (file) => {
    try {
      validationImageCropper(file);
    } catch (error) {
      toast.error(error.message);
      return;
    }

    fileToDataURL(file).then((result) => {
      updateCropperModal({ urlFile: result, isOpen: true });
    });
  };

  return (
    <>
      <div {...getRootProps()} className="upload-photo-button">
        <input {...getInputProps()} />
        <div>{text}</div>
      </div>
      <Modal
        isOpen={cropperModal.isOpen}
        toggle={handleToggleModal}
        centered={true}
        size="lg"
      >
        <ImageCropper
          handleToggleModal={handleToggleModal}
          urlFile={cropperModal.urlFile}
          isLoading={cropperModal.isLoading}
          handleLoading={handleLoading}
          handleSaveImage={(body) => handleSave(body)}
        />
      </Modal>
    </>
  );
};

export default ButtonUploadPhoto;
