import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import { toast } from 'react-toastify';

import { DOCUMENT_STATUS } from '../../../../constants/helper';
import {
  deleteDocument,
  downloadDocument,
  getFileByUrl,
} from '../../../../utils/backend-helper';
import { saveFileAs } from '../../../../utils/fileSaver';
import { User } from '../../../../constants/role';
import DocumentDeleteModal from '../../../../modals/DocumentDelete';
import MessageResetSuccess from '../../../../components/ToastSuccess';
import { SUCCESS_DOCUMENT_DELETE } from '../../../../constants/messages';
import CheckboxHead from '../../../../components/tables/HeadCheckbox';
import BodyCheckbox from '../../../../components/tables/BodyCheckbox';
import { getItemStyle } from '../../../../utils/dragable-helpers/dragable-styles';
import { getStyleTableHead } from '../../../../utils/styles/get-style-table-head';
import ResizingColumn from '../../../../components/tables/ResizingColumn';
import IconTooltip from '../../../../components/tooltips/IconTooltip';
import TableCellText from '../../../../components/tables/TableCellText';

export const columnsHead = [
  {
    name: 'checkbox',
    accessor: '',
    sort: false,
    Component: ({
      provided,
      snapshot,
      checkAllDocuments,
      checkedHead,
      pagination: { data },
    }) => {
      return (
        <CheckboxHead
          data={data}
          checkedHead={checkedHead}
          checkAllDocuments={checkAllDocuments}
          provided={provided}
          snapshot={snapshot}
        />
      );
    },
  },
  {
    name: 'type',
    accessor: 'Type',
    sort: true,
    classes: 'sort',
    widthPercent: 19,
  },
  {
    name: 'name',
    accessor: 'Name',
    sort: true,
    classes: 'sort',
    widthPercent: 19,
  },
  {
    name: 'revision',
    accessor: 'Revision',
    sort: true,
    widthPercent: 14.5,
    Component: ({
      provided,
      snapshot,
      name,
      columnsHidden,
      handleOrder,
      pagination,
      width,
      columnIndex,
      onResizeStart,
      onResizeEnd,
      onResizeColumns,
    }) => {
      const { orderBy, orderType } = pagination;

      return (
        <th
          key={name}
          className={classnames(
            'head-sort d-cursor-pointer',
            ...getStyleTableHead(columnsHidden[name], name, orderBy, orderType),
          )}
          onClick={() => handleOrder(name)}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...getItemStyle(snapshot, provided.draggableProps.style),
            minWidth: `${width}px`,
            maxWidth: `${width}px`,
          }}
        >
          <span id="info-tooltip">Revision</span>
          <UncontrolledTooltip
            placement="top"
            id="tooltip-access"
            target="info-tooltip"
          >
            <span className="active">
              If a Document does not have a Final Revision, here you can see the
              latest Reviewed or the biggest one. Downloading the Document takes
              a file from the Revision displayed in this column.
            </span>
          </UncontrolledTooltip>

          <ResizingColumn
            columnIndex={columnIndex}
            onResizeStart={onResizeStart}
            onResizeEnd={onResizeEnd}
            onResizeColumns={onResizeColumns}
          />
        </th>
      );
    },
  },
  {
    name: 'status',
    accessor: 'Status',
    sort: true,
    classes: 'column-title sort',
    widthPercent: 14.5,
  },
  {
    name: 'approvedAt',
    accessor: 'Approved Date',
    sort: true,
    classes: 'column-title sort',
    widthPercent: 15,
  },
  {
    name: 'reviewedAt',
    accessor: 'Reviewed Date',
    sort: true,
    classes: 'column-title text-center sort',
    widthPercent: 15,
  },
  {
    name: 'actions',
    accessor: 'Options',
    sort: false,
    classes: 'text-center',
    widthPercent: 18,
  },
];

export const columnsBody = [
  {
    name: 'checkbox',
    accessor: 'Selected',
    Component: ({ order: { id, isChecked = false }, handleUpdateChecked }) => {
      return (
        <BodyCheckbox
          id={id}
          isChecked={isChecked}
          handleUpdateChecked={handleUpdateChecked}
        />
      );
    },
  },
  {
    name: 'type',
    accessor: 'Type',
    Component: ({ order, name, columnsHidden }) => {
      const { id, typeDescription } = order;
      const text = order[name] || '';

      return (
        <td
          key={name}
          className={classnames({ 'column-hidden': !columnsHidden[name] })}
        >
          <p className="td-before">Document Type</p>
          <TableCellText
            id={`Document${name}${id}`}
            text={`${text}${typeDescription ? `-${typeDescription}` : ''}`}
          />
        </td>
      );
    },
  },
  {
    name: 'name',
    accessor: 'Name',
    classes: '',
    Component: ({ order, name, columnsHidden, organizationId }) => {
      const history = useHistory();
      const { id } = order;
      const text = order[name] || '';

      const handleRedirect = (organizationId, id) => {
        history.push(`/organizations/${organizationId}/documents/${id}`);
      };

      return (
        <td
          key={name}
          onClick={() => handleRedirect(organizationId, id)}
          className={classnames('cursor-pointer', {
            'column-hidden': !columnsHidden[name],
          })}
        >
          <p className="td-before">Document Name</p>
          <TableCellText id={`DocumentName${name}${order.id}`} text={text} />
        </td>
      );
    },
  },
  {
    name: 'revision',
    accessor: 'Revision',
    Component: ({ order, name, columnsHidden }) => {
      const { isFinal } = order;

      return (
        <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
          <p className="td-before">Revision</p>
          <TableCellText
            id={`Revision${name}${order.id}`}
            text={`${order[name]} ${isFinal ? 'Final' : ''}`}
          />
        </td>
      );
    },
  },
  {
    name: 'status',
    accessor: 'Status',
    classes: '',
    Component: ({ order, name, columnsHidden }) => (
      <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
        <p className="td-before">Status</p>
        <TableCellText
          id={`Status${name}${order.id}`}
          text={DOCUMENT_STATUS[order.status]}
        />
      </td>
    ),
  },
  {
    name: 'approvedAt',
    accessor: 'Approved Date',
    classes: '',
    Component: ({ order, name, columnsHidden }) => (
      <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
        <p className="td-before">Approved Date</p>
        <p>{order.approvedAt ? moment(order.approvedAt).format('ll') : ''}</p>
      </td>
    ),
  },
  {
    name: 'reviewedAt',
    accessor: 'Reviewed Date',
    classes: '',
    Component: ({ order, name, columnsHidden }) => (
      <td className={classnames({ 'column-hidden': !columnsHidden[name] })}>
        <p className="td-before">Reviewed Date</p>
        <p>{order.reviewedAt ? moment(order.reviewedAt).format('ll') : ''}</p>
      </td>
    ),
  },
  {
    name: 'actions',
    accessor: 'Actions',
    classes: '',
    Component: ({
      order,
      columnsHidden,
      organizationId,
      user,
      onDeleteRow,
    }) => {
      const history = useHistory();

      const handleDownload = async (id, organizationId, fileId) => {
        try {
          const document = await downloadDocument(id, organizationId, fileId);
          const { path, fileName } = document.data;

          const { data } = await getFileByUrl(path);
          saveFileAs(data, fileName);
        } catch (error) {}
      };

      const handleRedirect = (organizationId, id) => {
        history.push(`/organizations/${organizationId}/documents/${id}`);
      };

      const [deleteModal, setDeleteModal] = useState({
        isOpen: false,
        model: {
          organizationId: null,
          documentIds: null,
        },
        values: {
          type: '',
          name: '',
          number: '',
          revision: '',
        },
      });

      const handleDeleteModal = (
        organizationId,
        documentIds,
        type,
        name,
        number,
        revision,
        typeDescription,
      ) => {
        setDeleteModal((prev) => ({
          isOpen: !prev.isOpen,
          model: {
            organizationId,
            documentIds: [documentIds],
          },
          values: { type, name, number, revision, typeDescription },
        }));
      };

      return (
        <>
          <td
            className={`activate-column ${
              columnsHidden.actions ? '' : 'column-hidden'
            }`}
          >
            <p className="td-before">Actions</p>
            <div className="d-flex justify-content-start justify-content-md-center overflow-auto">
              <div className="d-flex column-user-active justify-content-start justify-content-md-end">
                <div className="actions">
                  <div className="d-flex actions-inner">
                    <IconTooltip
                      icon="bx bx-show action"
                      id={`documentViewDetails${order.id}`}
                      text="View details"
                      onClick={() => handleRedirect(organizationId, order.id)}
                    />
                    {order.file?.id && (
                      <IconTooltip
                        icon="bx bxs-download action"
                        id={`documentDownload${order.id}`}
                        text="Download"
                        onClick={() =>
                          handleDownload(
                            order.id,
                            organizationId,
                            order.file.id,
                          )
                        }
                      />
                    )}
                    {!(user.role === User && !order.canBeDeleted) && (
                      <IconTooltip
                        icon="bx bxs-trash-alt action"
                        id={`documentDeleteDetails${order.id}`}
                        text="Delete"
                        onClick={() =>
                          handleDeleteModal(
                            organizationId,
                            order.id,
                            order.type,
                            order.name,
                            order.number,
                            order.revision,
                            order.typeDescription,
                          )
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </td>
          <DocumentDeleteModal
            values={deleteModal.values}
            isOpen={deleteModal.isOpen}
            model={deleteModal.model}
            isMulty={false}
            onSubmit={(_, { organizationId, documentIds }) => {
              deleteDocument(organizationId, documentIds).then(() => {
                onDeleteRow(documentIds[0]);
                toast.success(
                  <MessageResetSuccess message={SUCCESS_DOCUMENT_DELETE} />,
                );
                setDeleteModal((prev) => ({ ...prev, isOpen: false }));
              });
            }}
            onCancel={() =>
              setDeleteModal((prev) => ({ ...prev, isOpen: false }))
            }
          />
        </>
      );
    },
  },
];
