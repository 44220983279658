import React from 'react';
import { getItemStyle } from '../../../utils/dragable-helpers/dragable-styles';

const CheckboxHead = ({
  provided,
  snapshot,
  data,
  checkAllDocuments,
  checkedHead,
  width,
}) => {
  return (
    <th
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...getItemStyle(snapshot, provided.draggableProps.style),
        minWidth: `${width}px`,
        maxWidth: `${width}px`,
      }}
      className="text-center fixed-column"
    >
      <div className="custom-control custom-checkbox document-checkbox table-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="custom-checkbox"
          checked={checkedHead}
          onChange={() => checkAllDocuments(data)}
        />
        <label
          className="custom-control-label"
          htmlFor="custom-checkbox"
        ></label>
      </div>
    </th>
  );
};

export default CheckboxHead;
