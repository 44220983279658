import React from 'react';

const ListSectionContainer = ({ title = '', children }) => {
  return (
    <div className="list-container">
      <p className="list-container__title">{title}</p>
      <ul className="list">{children}</ul>
    </div>
  );
};

export default ListSectionContainer;
