import React from 'react';

import HelpImage from '../../../shared/HelpImage';
import DocumentText from '../../../Artifacts/DocumentContent/DocumentText';

import accessImage from '../../../../../assets/images/help-screen/admin/im_User Access Permissions @3x.png';
import accessImage2 from '../../../../../assets/images/help-screen/admin/im_User Access Permissions 1@3x.png';
import accessImage3 from '../../../../../assets/images/help-screen/admin/im_User Access Permissions 2@3x.png';
import accessImage4 from '../../../../../assets/images/help-screen/admin/im_User Access Permissions 2 Copy@3x.png';
import accessImage5 from '../../../../../assets/images/help-screen/admin/im_User Access Permissions 2 Copy 2@3x.png';
import accessImage6 from '../../../../../assets/images/help-screen/admin/im_User Access Permissions 2 Copy 3@3x.png';
import { userAccessTexts } from './userAccessMok';

const UserAccessNotesContent = () => {
  return (
    <>
      <p>
        By default, a User does not have any specific access permissions inside
        an Organization. An Admin needs to grant them by turning on settings on
        User Groups’ level. In order for a User to receive access permissions,
        an Admin needs to create at least 1 User Group, add the User into this
        User Group and turn on specific settings of access permissions for this
        user group.
      </p>
      <HelpImage src={accessImage} />
      <HelpImage src={accessImage2} />
      <HelpImage src={accessImage3} />
      <p>
        The settings allow an organization to regulate detailed access
        permissions for all the artifacts in the Organization: Documents,
        Actions, Training records, Organization settings (Logo, Document Types,
        Tags).
      </p>
      <p>
        Each screen with settings contains detailed explanative labels and
        tool-tips. Pay attention to this information. The logic of all the
        access permissions settings is this:
      </p>
      <div className="help-document-content">
        <div className="help-document-content__list">
          {userAccessTexts.map((props, index) => (
            <DocumentText key={index} {...props} />
          ))}
        </div>
      </div>
      <p className='mt-3'>
        Only one high-level permission option can be selected. One lover level
        permission selection for each type (add, edit, delete) may be selected.
        If none are selected, the user will have no permissions for that type.
      </p>
      <p>
        Make sure to grant logical access permissions for each User Group and
        assign every User to at least one User Group. If a User does not have a
        User Group or their User Groups do not have set access permissions, the
        User will be unable to access the system. (E.g., if a user is somehow
        related to a Document Revision, access permissions for Document
        Revisions must be turned on. Otherwise, the user will receive Actions
        assigned for Documents, but they will see an "Access Denied" error
        notification when clicking a link to a Document Revision in the assigned
        Action.
      </p>
      <p>
        When a specific User is included into more than 1 User Group, their
        individual access permissions will be ones that are wider and allow more
        actions with more of the Organization’s artifacts.
      </p>
      <p>
        You can view accumulated access permissions of a User on their
        individual User screen with settings that are applied to them. These
        settings are displayed as defined by their cumulative User Groups and
        they are read-only.
      </p>
      <HelpImage src={accessImage4} />
      <HelpImage src={accessImage5} />
      <HelpImage src={accessImage6} />
    </>
  );
};

export default UserAccessNotesContent;
