import React from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { useEmptyForm } from '../../custom-hooks/useEmptyForm';
import Button from '../../components/Button';
import { MAX_INPUT_LENGTH } from '../../constants/helper';
import { validateEmptyText } from '../../constants/validatePassword';
import { REQUIRED_FIELD } from '../../constants/form';

const UserAddNewModal = ({ onSubmit, onCancel, isOpen, organizationId }) => {
  const formInputs = ['firstName', 'lastName', 'email'];

  const { disabled, onChange, values, clear } = useEmptyForm(formInputs);

  const handleToogleModal = () => {
    onCancel();
    clear();
  };

  const handleSubmitModal = (event, value) => {
    onSubmit(event, value, clear);
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToogleModal} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Create User</h5>
        <button
          type="button"
          onClick={handleToogleModal}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm
        className="form-horizontal mt-2"
        onValidSubmit={handleSubmitModal}
      >
        <div className="modal-body">
          <div className="form-group">
            <AvField
              name="firstName"
              label="First name"
              className="form-control"
              placeholder="Enter first name"
              maxLength={MAX_INPUT_LENGTH}
              type="text"
              value={values.firstName}
              onChange={onChange}
              validate={validateEmptyText}
              errorMessage={REQUIRED_FIELD}
            />
          </div>
          <div className="form-group">
            <AvField
              name="lastName"
              label="Last name"
              type="text"
              maxLength={MAX_INPUT_LENGTH}
              placeholder="Enter last name"
              value={values.lastName}
              onChange={onChange}
              validate={validateEmptyText}
              errorMessage={REQUIRED_FIELD}
            />
          </div>
          <div className="form-group">
            <AvField
              name="email"
              label="Email"
              type="email"
              placeholder="Enter email here"
              value={values.email}
              onChange={onChange}
              validate={validateEmptyText}
              errorMessage={REQUIRED_FIELD}
            />
          </div>
          <AvField
            name="organizationId"
            type="text"
            className="btn-hidden"
            value={organizationId}
          />
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={handleToogleModal}
              >
                Cancel
              </Button>
              <Button
                disabled={disabled}
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light mr-3"
              >
                Create User
              </Button>
            </Col>
          </Row>
        </div>
      </AvForm>
    </Modal>
  );
};

export default UserAddNewModal;
