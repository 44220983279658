export const TWO_ANSWER = 2;

export const getTemplateQuestion = (id) => ({
  id,
  name: '',
  answers: [...Array(TWO_ANSWER)].map((_, i) => ({
    id: i,
    name: '',
    isCorrect: false,
  })),
});

export const createTemplateAnswer = () => {};
