import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import LinkHumanResources from '../../shared/LinkToRecord/LinkHumanResources';
import LinkDocument from '../../shared/LinkToRecord/LinkDocument';
import LinkTask from '../../shared/LinkToRecord/LinkTask';

import { useIsCanViewDocument } from '../../../contexts/ViewDocument';
import { USER_ROLE } from '../../../constants/helper';
import { getDataUser } from '../../../utils/get-store-user';
import { changeSelected } from '../../../utils/tasks-helper/change-selected';
import { changeSavedLinks } from '../../../utils/tasks-helper/change-saved-links';

const TrainingLinkToRecord = ({ updateLinkToRecord }) => {
  const { role } = getDataUser();
  const { id: organizationId } = useRouteMatch().params;

  const {
    clientUserAccess: { canViewDocuments, canViewTasks },
  } = useIsCanViewDocument();

  const [selectValues, updateSelectValues] = useState([false, false, false]);

  const [savedLinks, updateSavedLinks] = useState([null, null, null]);

  const handleUpdate = (index) => {
    updateSelectValues((list) => changeSelected(list, index));
    const currentSavedLink = savedLinks[index];
    if (currentSavedLink) {
      const { value, link } = currentSavedLink;
      updateLinkToRecord(value, link);
      return;
    }
    updateLinkToRecord('', '');
  };

  const handleUpdateLink = (values, partUrl, switcherIndex) => {
    if (values) {
      const { value, id, revision } = values;
      const link = `${
        window.location.origin
      }/organizations/${organizationId}/${partUrl}/${id}${
        revision ? `?revision=${revision}` : ''
      }`;
      updateLinkToRecord(value, link);
      updateSavedLinks((links) =>
        changeSavedLinks(links, switcherIndex, { value, link }),
      );
      return;
    }
    updateLinkToRecord('', '');
    updateSavedLinks((links) => changeSavedLinks(links, switcherIndex, null));
  };

  return (
    <Row>
      <Col>
        <div className="info-column">
          <p className="info-column__title">Link to Record</p>
        </div>
        {(role !== USER_ROLE || canViewDocuments) && (
          <LinkDocument
            selectValues={selectValues}
            handleUpdate={handleUpdate}
            handleUpdateLink={handleUpdateLink}
          />
        )}
        {(role !== USER_ROLE || canViewTasks) && (
          <LinkTask
            selectValues={selectValues}
            handleUpdate={handleUpdate}
            handleUpdateLink={handleUpdateLink}
          />
        )}
        {role !== USER_ROLE && (
          <LinkHumanResources
            selectValues={selectValues}
            handleUpdate={handleUpdate}
            handleUpdateLink={handleUpdateLink}
          />
        )}
      </Col>
    </Row>
  );
};

export default TrainingLinkToRecord;
