import React, { useMemo, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import {
  CardBody,
  Col,
  NavItem,
  Row,
  NavLink,
  Container,
  Card,
  TabContent,
  TabPane,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { AvForm } from 'availity-reactstrap-validation';
import classnames from 'classnames';

import Breadcrumbs from '../../../components/shared/Breadcrumbs/Breadcrumbs';
import MessageResetSuccess from '../../../components/ToastSuccess';
import GeneralSection from './GeneralSection';
import QualitySection from './QualitySection';
import ReferencesSection from './ReferencesSection';

import {
  GENERAL_DOCUMENT_TAB,
  QUALITY_DOCUMENT_TAB,
  REFERENCES_DOCUMENT_TAB,
  TRAINING_RECORD_KEY,
  DESCRIPTION_TYPE_KEY,
  REVISION_NAME_KEY,
  TAG_IDS_KEY,
} from '../../../constants/helper';
import {
  ERROR_VALIDATION_DOCUMENTS,
  SUCCESS_DOCUMENT_CREATE,
  ERROR_SAVE_REQUIRED,
} from '../../../constants/messages';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';
import {
  createDocument,
  editDocumentGeneral,
  editQuality,
} from '../../../utils/backend-helper';
import { editReferences } from '../../../utils/api/document-api';

export const DocumentAdd = ({ user }) => {
  const history = useHistory();
  const organizationId = useRouteMatch().params.id;

  const [activeTab, setActiveTab] = useState(GENERAL_DOCUMENT_TAB);

  const toggleTabs = (tab) => {
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };

  const [model, updateModel] = useState({
    typeId: null,
    submitterId: user.id,
    revision: '',
    name: '',
    fileId: '',
    revisionDescription: '',
    typeDescription: '',
    extraFileIds: [],
    revisionName: '',
    tagIds: [],
  });

  const [isChecked, updateIsChecked] = useState(false);

  const isValidForm = useMemo(() => {
    let checkKey = [
      TRAINING_RECORD_KEY,
      DESCRIPTION_TYPE_KEY,
      REVISION_NAME_KEY,
      TAG_IDS_KEY,
    ];
    return Object.entries(model).every(([key, value]) => {
      const isCheckKey = checkKey.find((type) => type === key);
      return isCheckKey || value;
    });
  }, [model]);

  const breadCrumbItems = [
    {
      link: `/organizations/${organizationId}/documents`,
      title: 'Documents',
    },
    {
      link: '',
      title: 'Create New Document',
    },
  ];

  const onError = useErrorHandler();

  const [createdDocument, updateCreatedDocument] = useState({});

  const isDocumentCreated = useMemo(() => {
    return Object.keys(createdDocument).length;
  }, [createdDocument]);

  const handleCancelCreator = () => {
    history.push(`/organizations/${organizationId}/documents`);
  };

  const showErrorMessage = (message) => {
    toast.error(message);
  };

  const handlerRedirect = (tab) => {
    if (!isValidForm) {
      showErrorMessage(ERROR_VALIDATION_DOCUMENTS);
      return;
    }
    if (!isDocumentCreated) {
      showErrorMessage(ERROR_SAVE_REQUIRED);
      return;
    }
    toggleTabs(tab);
  };

  const navTabs = [
    {
      text: 'General',
      isActive: GENERAL_DOCUMENT_TAB,
      handler: () => toggleTabs(GENERAL_DOCUMENT_TAB),
    },
    {
      text: 'Quality Actions',
      isActive: QUALITY_DOCUMENT_TAB,
      handler: () => handlerRedirect(QUALITY_DOCUMENT_TAB),
    },
    {
      text: 'References',
      isActive: REFERENCES_DOCUMENT_TAB,
      handler: () => handlerRedirect(REFERENCES_DOCUMENT_TAB),
    },
  ];

  const submitGeneral = (_, values) => {
    if (!isDocumentCreated) {
      createDocument(values)
        .then(({ data }) => {
          updateCreatedDocument(data);
          toast.success(
            <MessageResetSuccess message={SUCCESS_DOCUMENT_CREATE} />,
          );
          setActiveTab(QUALITY_DOCUMENT_TAB);
        })
        .catch(onError);
      return;
    }

    const { id, ...body } = values;
    editDocumentGeneral(id, body)
      .then(({ data }) => {
        updateCreatedDocument(data);
        setActiveTab(QUALITY_DOCUMENT_TAB);
      })
      .catch(onError);
  };

  const submitQualityTask = (_, values) => {
    const { id, ...body } = values;
    editQuality(id, body)
      .then(() => {
        setActiveTab(REFERENCES_DOCUMENT_TAB);
      })
      .catch(onError);
  };

  const submitReferences = (_, values) => {
    const { id, ...body } = values;
    editReferences(id, body)
      .then(() => {
        toast.success(
          <MessageResetSuccess message={SUCCESS_DOCUMENT_CREATE} />,
        );
        history.push(`/organizations/${organizationId}/documents`);
      })
      .catch(onError);
  };

  return (
    <div className="page-content">
      <Container fluid className="container-bottom">
        <Breadcrumbs name="Documents" breadCrumbItems={breadCrumbItems} />
        <Row>
          <Col xs="12" sm="4" xl="3">
            <Card>
              <CardBody>
                <Link
                  className="nav-back"
                  to={`/organizations/${organizationId}/documents`}
                >
                  <i className="bx bx-chevron-left arrow"></i> Back to List
                </Link>
                <hr className="nav-line" />
                <ul
                  className="nav nav-tabs nav-tabs-setting nav-custom-tabs"
                  role="tablist"
                >
                  {navTabs.map(({ text, isActive, handler }) => (
                    <NavItem key={text}>
                      <NavLink
                        className={classnames({
                          active: activeTab === isActive,
                        })}
                        onClick={handler}
                      >
                        {text}
                      </NavLink>
                    </NavItem>
                  ))}
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" sm="8" xl="9">
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab} className="p-0">
                  <TabPane tabId={GENERAL_DOCUMENT_TAB} id="general">
                    <AvForm onValidSubmit={submitGeneral}>
                      <GeneralSection
                        isChecked={isChecked}
                        updateIsChecked={updateIsChecked}
                        organizationId={organizationId}
                        user={user}
                        handleCancelCreator={handleCancelCreator}
                        model={model}
                        updateModel={updateModel}
                        createdDocument={createdDocument}
                        isValidForm={isValidForm}
                      />
                    </AvForm>
                  </TabPane>
                  <TabPane tabId={QUALITY_DOCUMENT_TAB} id="quality-tasks">
                    <AvForm onValidSubmit={submitQualityTask}>
                      <QualitySection
                        createdDocument={createdDocument}
                        handleCancelCreator={handleCancelCreator}
                      />
                    </AvForm>
                  </TabPane>
                  <TabPane
                    tabId={REFERENCES_DOCUMENT_TAB}
                    id="connect-employees"
                  >
                    <AvForm onValidSubmit={submitReferences}>
                      <ReferencesSection
                        createdDocument={createdDocument}
                        handleCancelCreator={handleCancelCreator}
                      />
                    </AvForm>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DocumentAdd;
