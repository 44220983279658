import { FORGET_PASSWORD } from './actionTypes';

const initialState = {};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = { ...state };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
