import { useState, useEffect } from 'react';

import { IS_CHECKED_KEY } from '../constants/table';
import { isEveryElement } from '../utils/shared/is-every-element';
import { changePaginationCheckbox } from '../utils/tables-helper/change-checkbox-pagination';
import { changeCheckedAll } from '../utils/tables-helper/change-checked-all';

export const useCheckboxTable = (
  { limit, pageNumber, search },
  setPagination,
) => {
  const [checkedHead, updateCheckedHead] = useState(false);

  useEffect(() => {
    updateCheckedHead(false);
  }, [limit, pageNumber, search]);

  const checkAllDocuments = (data) => {
    const isCheckedAll = isEveryElement(data, IS_CHECKED_KEY);

    setPagination((pagination) => changeCheckedAll(pagination, isCheckedAll));
    updateCheckedHead((isCheckedHead) =>
      isCheckedAll ? false : !isCheckedHead,
    );
  };

  const handleUpdateChecked = (checkboxId) => {
    setPagination((pagination) =>
      changePaginationCheckbox(pagination, checkboxId),
    );
  };

  const unCheckAllDocuments = () => updateCheckedHead(false);

  return {
    checkedHead,
    updateCheckedHead,
    handleUpdateChecked,
    checkAllDocuments,
    unCheckAllDocuments,
  };
};
