import React, { useMemo } from 'react';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';

import { useRouteMatch } from 'react-router';
import { MANAGE_DOCUMENT_ID } from '../../../../constants/helper';
import SwitcherCheckbox from '../../../../components/shared/SwitcherCheckbox';
import { changeGroupSetting } from '../../../../utils/backend-helper';
import { getIsCheckedType } from '../../../../utils/user-groups-setting/is-checked-setting';
import { changeOrganizationSetting } from '../../../../utils/user-groups-setting/change-organization.setting';

const OrganizationSetting = ({ settings, updateSettings }) => {
  const { id: organizationId, groupId } = useRouteMatch().params;

  const isCheckedType = useMemo(
    () => getIsCheckedType(settings, MANAGE_DOCUMENT_ID),
    [settings],
  );

  const changeSetting = (groupId, organizationId, type) => {
    changeGroupSetting(groupId, organizationId, type).then(({ data }) =>
      updateSettings((settings) => changeOrganizationSetting(settings, data)),
    );
  };

  return (
    <>
      <Row>
        <Col className="permissions-document">
          <h5>Permissions for Organization Settings</h5>
          <p>
            <b>Turning On</b> the options below will allow the users of the
            current User Group to access the Organization Settings.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="accordion">
            <div className="accordion-header">
              <div className="form-check accordion-header__radio pl-0 d-flex">
                <SwitcherCheckbox
                  id="switch-organization"
                  isChecked={isCheckedType}
                  onChange={() =>
                    changeSetting(groupId, organizationId, MANAGE_DOCUMENT_ID)
                  }
                />
                <p>
                  {' '}
                  Manage organization settings
                  <i
                    id="organization-tooltip-accordion-1"
                    className="bx bx-info-circle info"
                  ></i>
                </p>
                <UncontrolledTooltip
                  placement="top"
                  id="tooltip-accordion"
                  target="organization-tooltip-accordion-1"
                >
                  <p className="mb-0">
                    The users will be able to change the Logo, create and delete
                    Document Types and Tags in the current Organization
                  </p>
                </UncontrolledTooltip>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OrganizationSetting;
