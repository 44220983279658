import React from 'react';
import ReactTooltip from 'react-tooltip';

const CustomTooltip = ({ id, className, onClick, text, children }) => {
  return (
    <>
      <div data-tip data-for={id} onClick={onClick} className={className}>
        {children}
      </div>
      <ReactTooltip
        id={id}
        className="icon-tooltip custom-tooltip"
        effect="solid"
        arrowColor="transparent"
        place="right"
      >
        <span>{text}</span>
      </ReactTooltip>
    </>
  );
};

export default CustomTooltip;
