import React, { useState } from 'react';
import { Col, Row, Collapse, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import {
  getCurrentSetting,
  getDisableSetting,
} from '../../../../utils/disable-setting';
import {
  VIEW_TASK_ID,
  VIEW_ASSIGNEE_TASK_ID,
  END_TASK_ID,
} from '../../../../constants/helper';
import { changeGroupSetting } from '../../../../utils/backend-helper';
import SwitchSetting from '../../../../components/switchers/SwitchSetting';
import {
  allTaskAddOptions,
  allTaskDeleteOptions,
  allTaskEditOptions,
  taskAddOption,
  taskDeleteOption,
  taskEditOption,
} from '../settingsMok';
import ToggleList from '../shared/ToggleList';
import { getDataUser } from '../../../../utils/get-store-user';
import { isUserRole } from '../../../../utils/shared/is-user-role';
import {
  getIndexSetting,
  isMainRadioSelected,
  changeSettings,
} from '../../../../utils/user-groups-setting/changeSetting';

const TaskSettingTab = ({
  settings,
  updateSettings,
  organizationId,
  id,
  isOnlyView = false,
}) => {
  const { role } = getDataUser();

  const [cols, setCols] = useState([false, false]);

  const handleUpdateCols = (idx) => {
    setCols((prev) => prev.map((toggle, i) => (i === idx ? !toggle : toggle)));
  };

  const handleChangeGroupSetting = (id, organizationId, type) => {
    changeGroupSetting(id, organizationId, type).then(({ data }) => {
      const { isSwitchedOn, type } = data;

      const indexOfSetting = getIndexSetting(settings, type);
      const isMainRadio = isMainRadioSelected(type);

      if (isMainRadio && !isSwitchedOn) {
        updateSettings(getDisableSetting(settings, VIEW_TASK_ID, END_TASK_ID));
        return;
      }

      updateSettings((settings) =>
        changeSettings(settings, indexOfSetting, data),
      );
    });
  };

  const handleUpdateRadio = (event, id, organizationId, VIEW_TASK_ID) => {
    event.stopPropagation();
    handleChangeGroupSetting(id, organizationId, VIEW_TASK_ID);
  };

  const handleChecked = (type) => {
    if (!settings.length) return false;

    const indexOfSetting = settings.findIndex(
      (setting) => setting.type === type,
    );
    return indexOfSetting !== -1
      ? settings[indexOfSetting].isSwitchedOn
      : false;
  };

  const allViewList = [
    {
      text: 'Edit option',
      list: allTaskEditOptions,
    },
    {
      text: 'Delete option',
      list: allTaskDeleteOptions,
    },
    {
      text: 'Add option',
      list: allTaskAddOptions,
    },
  ];

  const viewList = [
    {
      text: 'Edit option',
      list: taskEditOption,
    },
    {
      text: 'Delete option',
      list: taskDeleteOption,
    },
    {
      text: 'Add option',
      list: taskAddOption,
    },
  ];

  return (
    <>
      <Row>
        <Col className="permissions-document">
          <h5>Permissions for Actions</h5>
          {!isOnlyView && (
            <>
              <p>
                <b>Turning On</b> the options below will allow the users of the
                current User Group to access the Actions
              </p>
              <p>
                You can <b>turn on only one higher-level option</b>, and among
                the grouped options only one can be turned on under the enabled
                parent-option
              </p>
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="accordion">
            <div
              className="accordion-header"
              onClick={() => handleUpdateCols(0)}
            >
              <div className="form-check accordion-header__radio">
                <SwitchSetting
                  id={id}
                  organizationId={organizationId}
                  handleChecked={handleChecked}
                  handleUpdateRadio={handleUpdateRadio}
                  DOCUMENTS_ID={VIEW_TASK_ID}
                  isDisabled={
                    isOnlyView
                      ? true
                      : getCurrentSetting(settings, VIEW_ASSIGNEE_TASK_ID)
                  }
                />
                <p>
                  {' '}
                  View all actions
                  <i
                    id="task-tooltip-accordion-1"
                    className="bx bx-info-circle info"
                  ></i>
                </p>
                <UncontrolledTooltip
                  placement="top"
                  id="tooltip-accordion"
                  target="task-tooltip-accordion-1"
                >
                  <p className="mb-0">
                    {isUserRole(role)
                      ? 'Allows you to view all the actions in the current Organization'
                      : 'The users will be able to view all the actions in the current Organization'}
                  </p>
                </UncontrolledTooltip>
              </div>
              <i
                className={classnames('bx bx-chevron-down arrow', {
                  downArrow: cols[0],
                })}
              ></i>
            </div>
          </div>
          <Collapse isOpen={cols[0]}>
            <div className="collapse-content">
              {allViewList.map(({ text, list }, index) => {
                return (
                  <ToggleList
                    isOnlyView={isOnlyView}
                    key={index}
                    list={list}
                    text={text}
                    settings={settings}
                    id={id}
                    mainId={VIEW_TASK_ID}
                    organizationId={organizationId}
                    handleChangeGroupSetting={handleChangeGroupSetting}
                    handleChecked={handleChecked}
                  />
                );
              })}
            </div>
          </Collapse>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="accordion">
            <div
              className="accordion-header"
              onClick={() => handleUpdateCols(1)}
            >
              <div className="form-check accordion-header__radio">
                <SwitchSetting
                  id={id}
                  organizationId={organizationId}
                  handleChecked={handleChecked}
                  handleUpdateRadio={handleUpdateRadio}
                  DOCUMENTS_ID={VIEW_ASSIGNEE_TASK_ID}
                  isDisabled={
                    isOnlyView
                      ? true
                      : getCurrentSetting(settings, VIEW_TASK_ID)
                  }
                />
                <p>
                  View only actions, where the user is selected as Assignee, or
                  the user is an Creator, or an Assigner
                  <i
                    id="task-tooltip-accordion-2"
                    className="bx bx-info-circle info"
                  ></i>
                </p>
                <UncontrolledTooltip
                  placement="top"
                  id="tooltip-accordion"
                  target="task-tooltip-accordion-2"
                >
                  <p className="mb-0">
                    {isUserRole(role)
                      ? 'In the current Organization, allows you to view only actions, where you are an Assignee, an Owner, or an Assigner'
                      : 'In the current Organization, the users will be able to view only Tasks where they are an Assignee, or a Creator, or an Assigner. A task Assigner can be selected by the action Creator, or Assigner can be the same person as a Creator'}
                  </p>
                </UncontrolledTooltip>
              </div>
              <i
                className={classnames('bx bx-chevron-down arrow', {
                  downArrow: cols[1],
                })}
              ></i>
            </div>
          </div>
          <Collapse isOpen={cols[1]}>
            <div className="collapse-content">
              {viewList.map(({ text, list }, index) => {
                return (
                  <ToggleList
                    isOnlyView={isOnlyView}
                    key={index}
                    list={list}
                    text={text}
                    settings={settings}
                    id={id}
                    mainId={VIEW_ASSIGNEE_TASK_ID}
                    organizationId={organizationId}
                    handleChangeGroupSetting={handleChangeGroupSetting}
                    handleChecked={handleChecked}
                  />
                );
              })}
            </div>
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default TaskSettingTab;
