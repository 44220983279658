export const changeDataFromHidden = (list, hiddenList) => {
  if (!hiddenList?.length) return list;

  return list.map((item) => {
    const hiddenItem = hiddenList.find(({ id }) => id === item.id);

    if (hiddenItem?.isChecked !== undefined) {
      return { ...item, isChecked: hiddenItem.isChecked };
    }
    return item;
  });
};
