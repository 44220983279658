import React, { useState, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation';
import { Col, Input, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import Button from '../../../../../components/Button';
import TrainingQuestions from '../Questions';
import MessageResetSuccess from '../../../../../components/ToastSuccess';
import TrainingLinkToRecord from '../../../../../components/TrainingRecord/TrainingLinkToRecord';

import { EditTrainingModel } from '../../../../../models/training-records/training-records-edit';
import { getTaskModel } from '../../../../../utils/get-task-model';
import { useUserSelect } from '../../../../../custom-hooks/useUserSelect';
import { useUserAsyncSelect } from '../../../../../custom-hooks/useUserAsyncSelect';

import {
  ASSIGNEE_USER_KEY,
  MAX_INPUT_LENGTH,
  MAX_TEXTAREA_LENGTH,
} from '../../../../../constants/helper';
import { customStyles } from '../../../../../utils/select-styles';
import { changeTrainingRecord } from '../../../../../utils/api/training-records-api';
import { uniqueData } from '../../../../../utils/unique-data';
import { isEmptyValue } from '../../../../../utils/is-empty-value';
import { SUCCESS_TRAINING_UPDATE } from '../../../../../constants/messages';
import { getFilesIds } from '../../../../../utils/training-records-helper/get-files-ids';
import {
  isQuestionsCorrect,
  removeIdQuestions,
} from '../../../../../utils/training-records-helper/questions-helpers';
import { getLinkToRecord } from '../../../../../utils/tasks-helper/get-task-link';
import TaskEditLink from '../../../../../components/task/EditLink';
import TaskExternalLink from '../../../../../components/task/ExternalLink';
import TagsListEdit from '../../../../../components/shared/TaskListEdit';
import { getFilteredData } from '../../../../../utils/shared/get-filtered-data';
import { getUnionArrayIds } from '../../../../../utils/shared/get-union-array';
import DocumentAttachedFiles from '../../../../../components/document/AttachedFiles';
import { filterByField } from '../../../../../utils/training-records-helper/filter-by-id';
import { changeFileTrainingRecords } from '../../../../../utils/file-attached/change-file-training-records';
import { useErrorHandler } from '../../../../../custom-hooks/useErrorHandler';
import FormGroupWrapper from '../../../TaskView/Edit/FormGroupWrapper';

const TrainingDetailsEdit = ({
  user,
  trainingModel,
  updateTrainingModel,
  updateRecordDetails,
  updateEditMode,
}) => {
  const { id: organizationId, recordId } = useRouteMatch().params;
  const onError = useErrorHandler();

  const handleSubmit = (_, values) => {
    const { id, ...body } = values;
    changeTrainingRecord(id, body)
      .then(({ data }) => {
        updateTrainingModel(data);
        toast.success(
          <MessageResetSuccess message={SUCCESS_TRAINING_UPDATE} />,
        );
        updateEditMode(false);
      })
      .catch(onError);
  };

  const [model, updateModel] = useState(EditTrainingModel);

  useEffect(() => {
    updateModel((prev) => getTaskModel(prev, trainingModel));
  }, [trainingModel]);

  const handleModelChange = (event) => {
    const { name, value } = event.target;
    updateModel((prev) => ({
      ...prev,
      [name]: { ...prev[name], value },
    }));
  };

  const { usersOption, defaultUser, handleUsersSearch, updateDefaultUser } =
    useUserSelect(organizationId, trainingModel.assigner);

  const handleAssignerChange = ({ label, id: assignerId }) => {
    updateDefaultUser({ label });
    updateModel((prev) => ({
      ...prev,
      assigner: { ...prev.assignee, value: assignerId },
    }));
  };

  const {
    userIds: assigneesIds,
    groupIds,
    debounceUser: debounceAssignees,
    handleUserChange: handleAssigneesChange,
  } = useUserAsyncSelect(organizationId);

  const handleAssigneeDelete = (currentId) => {
    updateModel((prev) => ({
      ...prev,
      assigneeUsers: {
        ...prev.assigneeUsers,
        value: [
          ...prev.assigneeUsers.value.filter(({ id }) => id !== currentId),
        ],
      },
    }));
  };

  const handleUserGroupsDelete = (currentName) => {
    updateModel((prev) => ({
      ...prev,
      assigneeUserGroups: {
        ...prev.assigneeUserGroups,
        value: [
          ...prev.assigneeUserGroups.value.filter(
            ({ name }) => name !== currentName,
          ),
        ],
      },
    }));
  };

  const getAssigneeUsersIds = (users, newIds) => {
    const currentIds = users.map(({ id }) => id);
    return uniqueData([...currentIds, ...newIds]);
  };

  const getUsersGroupsName = (groups) => {
    return groups.map(({ name }) => name);
  };

  const isDisabled = useMemo(() => {
    const checkIsQuestionsCorrect = isQuestionsCorrect(model.questions.value);
    const isAssignees =
      assigneesIds.length ||
      groupIds.length ||
      model.assigneeUsers?.value.length ||
      model.assigneeUserGroups?.value.length;

    return !(
      isAssignees &&
      checkIsQuestionsCorrect &&
      Object.entries(model).every(([key, { required, value }]) => {
        if (required && typeof value === 'object') {
          return key === ASSIGNEE_USER_KEY
            ? assigneesIds.length || value.length
            : value.length;
        }
        return required ? isEmptyValue(value) : true;
      })
    );
  }, [model, assigneesIds, groupIds.length]);

  const [isInitalLink, updateIsInitialLink] = useState(true);

  const handleModelChangeKey = (modelKey, value) => {
    updateModel((prev) => ({
      ...prev,
      [modelKey]: { ...prev[modelKey], value },
    }));
  };

  return (
    <>
      <div className="general-header">
        <h6 className="mt-2">Edit training record</h6>
      </div>
      <hr />
      <AvForm className="form-horizontal mt-2" onValidSubmit={handleSubmit}>
        <div className="general-info">
          <Row>
            <Col xs="12" md="2">
              <FormGroupWrapper title="System #">
                <Input
                  name="number"
                  className="form-control"
                  type="text"
                  value={model.number.value}
                  onChange={(event) => handleModelChange(event)}
                  disabled
                />
              </FormGroupWrapper>
            </Col>
            <Col xs="12" md="7">
              <FormGroupWrapper title="Training name*">
                <AvField
                  name="name"
                  className="form-control"
                  placeholder="Enter training name"
                  maxLength={MAX_INPUT_LENGTH}
                  onChange={(event) => handleModelChange(event)}
                  value={model.name.value}
                  type="text"
                />
              </FormGroupWrapper>
            </Col>
            <Col xs="12" md="3">
              <FormGroupWrapper title="Due Date*">
                <input
                  className="form-control"
                  type="date"
                  name="dueDate"
                  value={model.dueDate.value}
                  placeholder="Choose due date"
                  onChange={(event) => handleModelChange(event)}
                />
              </FormGroupWrapper>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroupWrapper title="Description*">
                <AvInput
                  type="textarea"
                  id="textarea"
                  name="description"
                  placeholder="Enter description here"
                  maxLength={`${MAX_TEXTAREA_LENGTH}`}
                  onChange={(event) => handleModelChange(event)}
                  value={model.description.value}
                  rows="3"
                />
              </FormGroupWrapper>
            </Col>
          </Row>
          <TagsListEdit
            tags={model.tags.value}
            onChange={(values) => handleModelChangeKey('tagIds', values)}
            handleRemoveExist={(deletedId) =>
              handleModelChangeKey(
                'tags',
                getFilteredData(model.tags.value, 'id', deletedId),
              )
            }
          />
          <hr />
          <Row>
            <Col xs="12">
              <FormGroupWrapper title="Assignee(s)*">
                <AsyncSelect
                  styles={customStyles}
                  isMulti={true}
                  cacheOptions={false}
                  defaultOptions
                  onChange={(value) => handleAssigneesChange(value)}
                  loadOptions={debounceAssignees}
                  placeholder="Choose assignee(s)"
                />
              </FormGroupWrapper>
            </Col>
          </Row>
          <Row className="quality-edit-reviewer">
            <Col>
              <div className="info-column">
                {model.assigneeUsers.value.map(
                  (
                    { email, firstName, lastName, id, role, isHidden },
                    i,
                    arr,
                  ) => {
                    if (isHidden) return null;
                    return (
                      <React.Fragment key={`user-${id}`}>
                        <Row className="quality-item">
                          <Col xs="4">
                            <p>{`${firstName || ''} ${lastName || ''}`}</p>
                          </Col>
                          <Col xs="4">
                            <p>{email}</p>
                          </Col>
                          <Col xs="4">
                            <div className="role-block">
                              <p className="info-column__text info-column_right">
                                {role || ''}
                              </p>
                              <i
                                className="bx bxs-trash-alt"
                                onClick={() => handleAssigneeDelete(id)}
                              ></i>
                            </div>
                          </Col>
                        </Row>
                        <hr />
                      </React.Fragment>
                    );
                  },
                )}
                {model.assigneeUserGroups.value.map(
                  ({ name, userCount, users }, i, arr) => {
                    return (
                      <React.Fragment key={`user-group-${name}`}>
                        <Row className="quality-item">
                          <Col xs="4">
                            <p className="quality-item_blue">{name}</p>
                          </Col>
                          <Col xs="4">
                            <p className="quality-item_blue">
                              {userCount} users
                            </p>
                          </Col>
                          <Col xs="4">
                            <div className="role-block">
                              <i
                                className="bx bxs-trash-alt"
                                onClick={() => handleUserGroupsDelete(name)}
                              ></i>
                            </div>
                          </Col>
                        </Row>
                        {i !== arr.length - 1 && <hr />}
                      </React.Fragment>
                    );
                  },
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroupWrapper title="Assigner*">
                <Select
                  isMulti={false}
                  styles={customStyles}
                  options={usersOption}
                  value={defaultUser}
                  onChange={(value) => handleAssignerChange(value)}
                  onInputChange={(value) => handleUsersSearch(value)}
                  placeholder="Choose assigner"
                />
              </FormGroupWrapper>
            </Col>
          </Row>
          <hr />
          {isInitalLink && trainingModel.link ? (
            <TaskEditLink
              link={model.link.value}
              linkDescription={model.linkDescription.value}
              handleDelete={() => {
                updateModel((prev) => getLinkToRecord(prev, '', ''));
                updateIsInitialLink(false);
              }}
            />
          ) : (
            <TrainingLinkToRecord
              updateLinkToRecord={(value, link) => {
                updateModel((prev) => getLinkToRecord(prev, value, link));
              }}
            />
          )}
          <TaskExternalLink
            extraLinkDescription={model.extraLinkDescription.value}
            extraLink={model.extraLink.value}
            handleModelChange={handleModelChange}
          />
          <hr />
          <DocumentAttachedFiles
            title="File(s) attached"
            files={model.files.value}
            onDeleteFile={(id) => {
              updateModel((model) =>
                changeFileTrainingRecords(
                  model,
                  filterByField(model.files.value, id),
                ),
              );
            }}
            updateModel={(body) => {
              updateModel((model) =>
                changeFileTrainingRecords(model, [...model.files.value, body]),
              );
            }}
          />
          <hr />
          <TrainingQuestions
            questions={model.questions.value}
            updateModel={updateModel}
          />
          <Row className="form-group mt-2">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
                handler={() => updateEditMode(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
                disabled={isDisabled}
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </div>
        <AvField
          name="id"
          type="text"
          value={recordId}
          className="btn-hidden"
        />
        <AvField
          name="organizationId"
          type="text"
          value={organizationId}
          className="btn-hidden"
        />
        <AvField
          name="fileIds"
          type="text"
          value={getFilesIds(model.files.value)}
          className="btn-hidden"
        />
        <AvField
          name="dueDate"
          type="text"
          value={model.dueDate.value}
          className="btn-hidden"
        />
        <AvField
          name="assignerId"
          type="text"
          value={model.assigner.value}
          className="btn-hidden"
        />
        <AvField
          name="assigneeUserIds"
          type="text"
          value={getAssigneeUsersIds(model.assigneeUsers.value, assigneesIds)}
          className="btn-hidden"
        />
        <AvField
          name="newAssigneeUserGroupIds"
          type="text"
          value={groupIds}
          className="btn-hidden"
        />
        <AvField
          name="oldAssigneeUserGroupNames"
          type="text"
          value={getUsersGroupsName(model.assigneeUserGroups.value)}
          className="btn-hidden"
        />
        <AvField
          name="questions"
          type="text"
          value={removeIdQuestions(model.questions.value)}
          className="btn-hidden"
        />
        <AvField name="link" type="hidden" value={model.link.value} />
        <AvField
          name="linkDescription"
          type="hidden"
          value={model.linkDescription.value}
        />
        <AvField
          name="tagIds"
          type="hidden"
          value={getUnionArrayIds([...model.tags.value, ...model.tagIds.value])}
        />
      </AvForm>
    </>
  );
};

export default TrainingDetailsEdit;
