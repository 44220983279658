import React, { useCallback } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';
import SwitchOptionMulti from '../SwitchOptionMulti';
import { getCurrentSetting } from '../../../utils/disable-setting';
import { VIEW_DOCUMENTS_ID } from '../../../constants/helper';

const SwitchOption = ({
  type,
  label,
  name,
  tooltipText,
  idx,
  id,
  organizationId,
  handleChangeGroupSetting,
  handleChecked,
  groupTypes,
  settings,
  active,
  isOnlyView,
  subOptions,
  handleChangeGroupSettingMulti,
}) => {
  const isDisabled = useCallback(() => {
    if (!active) return true;
    if (!settings.length) return false;

    return groupTypes.some((type) => {
      const indexOfSetting = settings.findIndex(
        (setting) => setting.type === type,
      );
      if (!settings[indexOfSetting]) {
        return false;
      }
      return settings[indexOfSetting].isSwitchedOn;
    });
  }, [active, groupTypes, settings]);

  const isChecked = handleChecked(type);

  return (
    <div className={classnames('option-container', { active: active })}>
      <div className="custom-control custom-switch" dir="ltr">
        <input
          disabled={isOnlyView ? true : isDisabled()}
          type="checkbox"
          className="custom-control-input"
          id={type}
          onChange={() =>
            handleChangeGroupSetting(id, organizationId, type, subOptions)
          }
          checked={isChecked}
        />
        <label className="custom-control-label" htmlFor={type}>
          <span>
            {label}
            <i id={name + idx} className="bx bx-info-circle info v-middle"></i>
            <UncontrolledTooltip
              placement="top"
              id="tooltip-accordion"
              target={name + idx}
            >
              <p className="mb-0">{tooltipText}</p>
            </UncontrolledTooltip>
          </span>
          
          
        </label>
      </div>
      {subOptions && isChecked && (
        <div className="pl-4 pt-2">
          {subOptions.map(({ label, type }) => {
            return (
              <SwitchOptionMulti
                type={type}
                key={type}
                label={label}
                id={id}
                isOnlyView={isOnlyView}
                organizationId={organizationId}
                handleCheckedMulti={handleChecked}
                active={getCurrentSetting(settings, VIEW_DOCUMENTS_ID)}
                handleChangeGroupSettingMulti={handleChangeGroupSettingMulti}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SwitchOption;
