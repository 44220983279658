import React from 'react';
import { Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { connect } from 'react-redux';
import { changePasswordProfile } from '../../../../store/actions';
import { useEmptyForm } from '../../../../custom-hooks/useEmptyForm';

import {
  MIN_LENGTH,
  MIN_LENGTH_ERROR,
  MAX_LENGTH,
  MAX_LENGTH_ERROR,
  PASSWORD_VALIDATION,
  PASSWORD_VALIDATION_ERROR,
  PASSWORD_MATCH_ERROR,
} from '../../../../constants/form';

const ChangePasswordProfile = ({ changePasswordProfile }) => {
  const formInputs = ['oldPassword', 'password', 'confirmPassword'];
  const { disabled, onChange, values, clear } = useEmptyForm(formInputs);

  const handleValidSubmit = (event, values) => {
    changePasswordProfile(values);
    clear();
  };

  const getValidatePassword = (_, { password, confirmPassword }, input, cb) => {
    password === confirmPassword ? cb(true) : cb(PASSWORD_MATCH_ERROR);
  };

  const validationPassword = {
    minLength: { value: MIN_LENGTH, errorMessage: MIN_LENGTH_ERROR },
    maxLength: { value: MAX_LENGTH, errorMessage: MAX_LENGTH_ERROR },
    pattern: {
      value: PASSWORD_VALIDATION,
      errorMessage: PASSWORD_VALIDATION_ERROR,
    },
  };

  const validationConfirmPassword = {
    minLength: { value: MIN_LENGTH, errorMessage: MIN_LENGTH },
    maxLength: { value: MAX_LENGTH, errorMessage: MAX_LENGTH_ERROR },
    async: getValidatePassword,
  };

  return (
    <AvForm
      className="form-horizontal mt-4"
      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
    >
      <div className="form-group">
        <AvField
          name="oldPassword"
          label="Current password"
          className="form-control"
          placeholder="Enter your current password"
          type="password"
          value={values.oldPassword}
          onChange={onChange}
          validate={validationPassword}
        />
      </div>
      <div className="form-group">
        <AvField
          name="password"
          label="New Password"
          className="form-control"
          placeholder="Enter your new password"
          type="password"
          value={values.password}
          onChange={onChange}
          validate={validationPassword}
        />
      </div>
      <div className="form-group">
        <AvField
          name="confirmPassword"
          label="Confirm New Password"
          className="form-control"
          placeholder="Confirm new password"
          type="password"
          value={values.confirmPassword}
          onChange={onChange}
          validate={validationConfirmPassword}
        />
      </div>
      <Row className="form-group">
        <Col className="text-right">
          <button
            className="btn btn-submit waves-effect waves-light"
            type="submit"
            disabled={disabled}
          >
            Save Changes
          </button>
        </Col>
      </Row>
    </AvForm>
  );
};

export default connect(null, { changePasswordProfile })(ChangePasswordProfile);
