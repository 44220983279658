import React from 'react';
import classnames from 'classnames';

import TableCellText from '../TableCellText';

const TableBody = ({
  columnsHidden,
  pagination,
  columnsBody,
  organizationId,
  updateCheckedHead,
  user,
  updatingTable,
  organizationName,
  setPagination,
  checkedHead,
  handleUpdateChecked,
  onDeleteRow,
}) => {
  return (
    <tbody>
      {pagination.data.length
        ? pagination.data.map((order, key) => (
            <tr key={'_order_' + key}>
              {columnsBody.map(({ name, accessor, classes, Component }) => {
                if (Component) {
                  return (
                    <Component
                      key={name}
                      name={name}
                      order={order}
                      accessor={accessor}
                      columnsHidden={columnsHidden}
                      organizationId={organizationId}
                      updateCheckedHead={updateCheckedHead}
                      user={user}
                      checkedHead={checkedHead}
                      updatingTable={updatingTable}
                      onDeleteRow={onDeleteRow}
                      organizationName={organizationName}
                      pagination={pagination}
                      setPagination={setPagination}
                      handleUpdateChecked={handleUpdateChecked}
                    />
                  );
                }
                return (
                  <td
                    key={name}
                    className={classnames(classes, {
                      'column-hidden': !columnsHidden[name],
                    })}
                  >
                    <p className="td-before">{accessor}</p>
                    <TableCellText
                      id={`${name}${order.id}`}
                      text={order[name]}
                    />
                  </td>
                );
              })}
            </tr>
          ))
        : null}
    </tbody>
  );
};

export default TableBody;
