import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

const RequiresTrainingView = ({ name, id }) => {
  const { id: organizationId } = useRouteMatch().params;

  if (id) {
    return (
      <Row>
        <Col>
          <div className="info-column">
            <p className="info-column__title">Requires Training:</p>
            <Link
              to={`/organizations/${organizationId}/training-records/${id}`}
            >
              <i className="bx bx-link top-link"></i>
              {name}
            </Link>
          </div>
          <hr />
        </Col>
      </Row>
    );
  }
  return null;
};

export default RequiresTrainingView;
