import React, { useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import { Col, FormGroup, Row } from 'reactstrap';
import { getTags } from '../../../utils/api/organization-api';
import { configTags } from '../../../utils/config-get-organization';
import Index from '../../CustomMultiSelect';

const TagsListEdit = ({ tags, onChange, handleRemoveExist }) => {
  const { id: organizationId } = useRouteMatch().params;

  const getTagsFromAPI = useCallback(() => {
    return getTags(organizationId);
  }, [organizationId]);

  return (
    <Row>
      <Col>
        <div className="info-column">
          <p className="info-column__title">Tag(s)</p>
          <FormGroup className="select2-container">
            <Index
              handleRequest={getTagsFromAPI}
              configOptions={configTags}
              placeholder="Choose tag(s)"
              onChange={(values) => onChange(values || [])}
            />
          </FormGroup>
          <div className="filter-menu-container">
            {tags.map(({ name, id }) => {
              return (
                <div key={id} className="filter-item">
                  <span>{name}</span>
                  <i
                    className="bx bx-x"
                    onClick={() => handleRemoveExist(id)}
                  ></i>
                </div>
              );
            })}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default TagsListEdit;
