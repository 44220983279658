import React from 'react';

// import ProfileMenu from "../../../components/CommonForBoth/TopbarDropdown/ProfileMenu";
import ProfileMenu from '../../../components/Profile/Menu';

import HeaderLogo from './Logo';

const Header = ({ user }) => {
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <HeaderLogo />
        </div>
        <div className="d-flex">
          <ProfileMenu user={user} />
        </div>
      </div>
    </header>
  );
};

export default Header;
