import { takeEvery, fork, all, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import React from 'react';
import MessageResetSuccess from '../../../components/ToastSuccess';
import { IS_VALID_TOKEN, RESET_PASSWORD } from './actionTypes';
import { resetPassword, checkValidToken } from '../../../utils/backend-helper';
import { SUCCESS_MESSAGE_RESET } from '../../../constants/messages';

import { ERROR_INVALID_TOKEN } from '../../../constants/messages';

function* userResetPassword({
  payload: {
    data: { email, token, password, confirmPassword },
    history,
  },
}) {
  try {
    const response = yield call(resetPassword, '/verifications/password', {
      email,
      token,
      password,
      confirmPassword,
    });
    if (response) {
      toast.success(<MessageResetSuccess message={SUCCESS_MESSAGE_RESET} />);
      history.push('/Login');
    }
  } catch (error) {
    history.push('/Login');
  }
}

function* isValidToken({ payload: { data, history } }) {
  try {
    const response = yield call(checkValidToken, '/verifications/token', data);
    const {
      data: { isValid },
    } = response;

    if (!isValid) {
      toast.error(ERROR_INVALID_TOKEN);
      history.push('/Login');
    }
  } catch (error) {}
}

export function* watchRestPassword() {
  yield takeEvery(RESET_PASSWORD, userResetPassword);
}

export function* watchTokenValid() {
  yield takeEvery(IS_VALID_TOKEN, isValidToken);
}

function* changePasswordSaga() {
  yield all([fork(watchRestPassword), fork(watchTokenValid)]);
}

export default changePasswordSaga;
