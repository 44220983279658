import { LocalStorage } from './/storage';
import { SessionStorage } from './/sessionStorage';
import { keyAuth, keySession, keyUser, local, session } from '../constants/api';
import { storages } from '../constants/storages';

const storage = new LocalStorage();
const sessionStorage = new SessionStorage();

export const getUserStore = () => {
  return storage.getItem(keyUser) || sessionStorage.getItem(keyUser);
};

export const getDataUser = () => {
  const currentStorage = storage.getItem(keySession);
  if (!currentStorage) return {};

  return storages[currentStorage].getItem(keyUser);
};

export const saveLoginStorage = ({ user, token }, isRemember) => {
  const currentStorage = isRemember ? storage : sessionStorage;

  currentStorage.setItem(keyUser, user);
  currentStorage.setItem(keyAuth, token);
  storage.setItem(keySession, isRemember ? local : session);
};
