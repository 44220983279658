import React, { useState } from 'react';
import { Row, Col, Modal, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import AsyncSelect from 'react-select/async';

import { DEBOUNCE_TIMER } from '../../constants/helper';
import { changeFieldSelect } from '../../utils/selector-helper-changed';
import Button from '../../components/Button';
import { debounce } from 'lodash';
import {
  configureGetOrganization,
  configureGetAdmins,
} from '../../utils/config-get-organization';
import { getOrganizations, getAdmins } from '../../utils/backend-helper';

const customStyles = {
  option: (styles) => {
    return {
      ...styles,
      color: '#231dba',
      fontFamily: 'Poppins',
      fontWeight: '500',
    };
  },
};

const AdminAddExistModal = ({ onSubmit, onCancel, isOpen, organizationId }) => {
  const [organizationIds, setOrganizationIds] = useState([]);
  const [adminsIds, setAdminsIds] = useState([]);

  const debouncedOrganization = debounce((inputValue, callback) => {
    getOrganizations(inputValue).then(({ data }) =>
      callback(configureGetOrganization(data, organizationId)),
    );
  }, DEBOUNCE_TIMER);

  let debouncedAdmins = debounce((inputValue, callback) => {
    getAdmins(organizationId, organizationIds, inputValue).then(({ data }) =>
      callback(configureGetAdmins(data)),
    );
  }, DEBOUNCE_TIMER);

  const handleOrganizationChange = (organizations) => {
    setOrganizationIds(changeFieldSelect(organizations));
  };

  const handleAdminsChange = (admins) => {
    setAdminsIds(changeFieldSelect(admins));
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Add an Existing Admin</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm className="form-horizontal mt-2" onValidSubmit={onSubmit}>
        <div className="modal-body">
          <p className="text-exist">
            To add existing Admin(s), search directly in the second field, or
            you can first select Organization(s) to pull the list of Admins.
          </p>
          <FormGroup className="select2-container">
            <label className="control-label">Select Organization(s)</label>
            <AsyncSelect
              styles={customStyles}
              isMulti={true}
              cacheOptions={false}
              defaultOptions
              onChange={(value) => handleOrganizationChange(value)}
              loadOptions={debouncedOrganization}
              placeholder="Search by organization"
            />
          </FormGroup>
          <FormGroup className="select2-container">
            <label className="control-label">Select Admin(s)</label>
            <AsyncSelect
              key={organizationIds.length}
              styles={customStyles}
              defaultOptions
              cacheOptions={false}
              isMulti={true}
              onChange={(value) => handleAdminsChange(value)}
              loadOptions={debouncedAdmins}
              placeholder="Search by name or email"
            />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Row className="form-group">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3"
                handler={onCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={!adminsIds.length}
                type="submit"
                color="btn btn-edit w-md waves-effect waves-light mr-3"
              >
                Add
              </Button>
            </Col>
          </Row>
        </div>
        <AvField
          name="organizationId"
          type="text"
          value={organizationId}
          className="btn-hidden"
        />
        <AvField
          name="adminsIds"
          type="text"
          value={adminsIds}
          className="btn-hidden"
        />
      </AvForm>
    </Modal>
  );
};

export default AdminAddExistModal;
