import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  LOCKED_DOCUMENT_ERROR_CODE,
  LOCKED_ERROR_CODE,
  NOT_FOUND_CODE,
} from '../constants/helper';
import { getErrorStatusCode } from '../utils/get-status-response';
import { getRouteErrorOrganization } from '../utils/getRouteErrorOrganization';

const NOT_FOUND_DIRECT = '/pages-404';

export const useErrorHandler = (path) => {
  const history = useHistory();
  const { id: organizationId } = useRouteMatch().params;

  return useCallback(
    async (error) => {
      const statusCode = getErrorStatusCode(error);

      if (statusCode === NOT_FOUND_CODE) {
        history.push(NOT_FOUND_DIRECT);
      }

      if (statusCode === LOCKED_ERROR_CODE) {
        history.push(path || `/organizations/${organizationId}/dashboard`);
      }

      if (statusCode === LOCKED_DOCUMENT_ERROR_CODE) {
        const routePath = await getRouteErrorOrganization();
        history.push(routePath);
      }
    },
    [history, path, organizationId],
  );
};
