import React, { useState, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { CardBody, Col, Row, Container, Card } from 'reactstrap';

import Breadcrumbs from '../../../../components/shared/Breadcrumbs/Breadcrumbs';
import HumanResourcesDetails from './Details';

import { getHumanResourcesDetails } from '../../../../utils/api/human-resources-api';
import { useErrorHandler } from '../../../../custom-hooks/useErrorHandler';

const HumanRecoursesView = ({ user }) => {
  const { id: organizationId, resourceId } = useRouteMatch().params;

  const [resourcesDetails, updateResourcesDetails] = useState(null);

  const onError = useErrorHandler();

  useEffect(() => {
    getHumanResourcesDetails(resourceId, organizationId)
      .then(({ data }) => updateResourcesDetails(data))
      .catch(onError);
  }, [onError, organizationId, resourceId]);

  const breadCrumbItems = useMemo(
    () => [
      {
        link: `/organizations/${organizationId}/human-resources`,
        title: 'Human Resources',
      },
      {
        link: '',
        title:
          resourcesDetails &&
          `${resourcesDetails.firstName} ${resourcesDetails.lastName}`,
      },
    ],
    [organizationId, resourcesDetails],
  );

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          link={`/organizations/${organizationId}/human-resources`}
          name="Human Resources"
          breadCrumbItems={breadCrumbItems}
        />
        <Row>
          <Col>
            <Card>
              <CardBody>
                <div className="general">
                  <HumanResourcesDetails
                    organizationId={organizationId}
                    resourcesDetails={resourcesDetails}
                    user={user}
                    resourceId={resourceId}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HumanRecoursesView;
