export const getLinkToRecord = (model, value, link) => {
  return {
    ...model,
    link: {
      ...model.link,
      value: link,
    },
    linkDescription: {
      ...model.linkDescription,
      value,
    },
  };
};
