import React from 'react';
import { Button } from 'reactstrap';

const TableAddExist = ({ onClick }) => {
  return (
    <div className="text-sm-right mt-2 mt-md-0 mr-3">
      <Button
        id="btn-exist"
        onClick={onClick}
        className="waves-effect waves-light pl-3 pr-3"
      >
        <i className="mdi mdi-plus mr-1"></i> Add Existing
      </Button>
    </div>
  );
};

export default TableAddExist;
