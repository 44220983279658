import React from 'react';

const CustomSwitch = ({ name, isChecked, onChange, disabled }) => {
  return (
    <input
      className="custom-switch-radio"
      type="radio"
      name={name}
      checked={isChecked}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default CustomSwitch;
