import React from 'react';
import { Col, Row } from 'reactstrap';

const TablePlaceholder = ({ text }) => (
  <Row>
    <Col>
      <h5 className="mt-2">{text}</h5>
    </Col>
  </Row>
);

export default TablePlaceholder;
