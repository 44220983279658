import React from 'react';
import { Button } from 'reactstrap';

import { User } from '../../../constants/role';
import { getDataUser } from '../../../utils/get-store-user';

const TableAddNew = ({ onClick, isCanAdd }) => {
  const user = getDataUser();

  return (
    <div className="text-sm-right mt-2 mt-md-0">
      {!(user.role === User && !isCanAdd) && (
        <Button
          id="btn-added"
          onClick={onClick}
          className="waves-effect waves-light mr-2 pl-4 pr-4"
        >
          <i className="mdi mdi-plus mr-1"></i>
          Add New
        </Button>
      )}
    </div>
  );
};

export default TableAddNew;
