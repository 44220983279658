import React from 'react';
import { Redirect } from 'react-router-dom';

// Profile
import UserProfile from '../pages/Authentication/Profile/User';
import Organization from '../pages/Authentication/Organization';
import UserOrganization from '../pages/Authentication/UserOrganizations';
import ProfileEdit from '../pages/Authentication/Profile/ProfileEdit';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import ForgetPwd from '../pages/Authentication/ForgotPassword';
import ChangePwd from '../pages/Authentication/ChangePassword';
import ConfirmEmail from '../pages/Authentication/ConfirmEmail';

import OrganizationAdmins from '../pages/Organization/Admins';
import OrganizationUserGroup from '../pages/Organization/UserGroup';

import OrganizationUsers from '../pages/Organization/Users';
import UserAccessPermission from '../pages/Organization/Users/AccessPermission';

import OrganizationSetting from '../pages/Organization/Setting';
import OrganizationUserGroupUsers from '../pages/Organization/UserGroupUsers';
import setPassword from '../pages/Authentication/SetPassword';
import Documents from '../pages/Organization/Documents';
import DocumentView from '../pages/Organization/DocumentView';
import DocumentAdd from '../pages/Organization/DocumentAdd';
import Tasks from '../pages/Organization/Tasks';
import taskView from '../pages/Organization/TaskView';
import TaskCreate from '../pages/Organization/TaskCreate';

import TrainingRecords from '../pages/Organization/TrainingRecords';
import TrainingRecordView from '../pages/Organization/TrainingRecordsView';
import TrainingRecordsCreate from '../pages/Organization/TrainingRecordsCreate';

import Notifications from '../pages/Organization/Notifications';

// Human Resources components
import HumanResources from '../pages/Organization/HumanResources';
import HumanRecoursesView from '../pages/Organization/HumanResources/View/human-recourses-view';

// Global Search

import GlobalSearch from '../pages/Organization/GlobalSearch';

// Dashboard

import Dashboard from '../pages/Organization/Dashboard';

// Help page

import HelpPage from '../pages/Help';
import NotFoundPage from '../pages/NotFound';
import HelpOrganizationPage from '../pages/Organization/Help';

const userRoutes = [
  { path: '/profile/edit', component: ProfileEdit },
  { path: '/profile', exact: true, component: UserProfile },
  { path: '/organizations', component: Organization },
  { path: '/user-organizations', component: UserOrganization },
  { path: '/help', component: HelpPage },
  { path: '/pages-404', component: NotFoundPage },
  { path: '/', exact: true, component: () => <Redirect to="/profile" /> },
];

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/reset-password', component: ChangePwd },
  { path: '/set-password', component: setPassword },
  { path: '/confirm-email', component: ConfirmEmail },
];

const organizationRoutes = [
  { path: '/organizations/:id/admins', component: OrganizationAdmins },
  {
    path: '/organizations/:id/user-groups',
    exact: true,
    component: OrganizationUserGroup,
  },
  {
    path: '/organizations/:id/user-groups/:groupId',
    component: OrganizationUserGroupUsers,
  },
  {
    path: '/organizations/:id/users',
    exact: true,
    component: OrganizationUsers,
  },
  { path: '/organizations/:id/users/:userId', component: UserAccessPermission },
  { path: '/organizations/:id/setting', component: OrganizationSetting },
  { path: '/organizations/:id/documents', exact: true, component: Documents },
  { path: '/organizations/:id/documents/create', component: DocumentAdd },
  {
    path: '/organizations/:id/documents/:documentId',
    exact: true,
    component: DocumentView,
  },
  { path: '/organizations/:id/tasks/', exact: true, component: Tasks },
  { path: '/organizations/:id/tasks/create', component: TaskCreate },
  { path: '/organizations/:id/tasks/:taskId', component: taskView },
  {
    path: '/organizations/:id/training-records',
    exact: true,
    component: TrainingRecords,
  },
  {
    path: '/organizations/:id/training-records/create',
    component: TrainingRecordsCreate,
  },
  {
    path: '/organizations/:id/training-records/:recordId',
    component: TrainingRecordView,
  },
  { path: '/organizations/:id/notifications', component: Notifications },

  {
    path: '/organizations/:id/human-resources/',
    exact: true,
    component: HumanResources,
  },
  {
    path: '/organizations/:id/human-resources/:resourceId',
    component: HumanRecoursesView,
  },

  { path: '/organizations/:id/global-search/', component: GlobalSearch },

  { path: '/organizations/:id/dashboard', component: Dashboard },
  { path: '/organizations/:id/help', component: HelpOrganizationPage },
];

export { userRoutes, authRoutes, organizationRoutes };
