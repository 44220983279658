import React, { useMemo } from 'react';
import { Card, CardBody, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

import { navigationForUser, navigationList } from './navigationList';
import { getDataUser } from '../../../utils/get-store-user';
import { SUPERADMIN_ROLE } from '../../../constants/helper';

const ProfileNavigation = ({ activeTab, onChangeTab }) => {
  const { role } = getDataUser();

  const navList = useMemo(() => {
    return role === SUPERADMIN_ROLE
      ? navigationList
      : [navigationForUser, ...navigationList];
  }, [role]);

  return (
    <Card>
      <CardBody>
        <ul
          className="nav nav-tabs nav-tabs-setting nav-custom-tabs"
          role="tablist"
        >
          {navList.map(({ navigationText, tabId }) => {
            return (
              <NavItem key={tabId}>
                <NavLink
                  className={classnames({ active: activeTab === tabId })}
                  onClick={() => onChangeTab(tabId)}
                >
                  {navigationText}
                </NavLink>
              </NavItem>
            );
          })}
        </ul>
      </CardBody>
    </Card>
  );
};

export default ProfileNavigation;
