import React, { useState, useEffect, useCallback } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';

import CustomPagination from '../../../../components/shared/Pagination';
import MessageResetSuccess from '../../../../components/ToastSuccess';

import { NOTIFICATION_LIMIT_LIST } from '../../../../constants/notification';
import {
  changeAllSystemNotification,
  changeSystemNotification,
  getSystemNotificationList,
} from '../../../../utils/api/notification-api';
import {
  INITIAL_OFFSET,
  INITIAL_PAGE,
  PAGE_COUNT,
  TOTAL_ITEMS,
} from '../../../../constants/table';
import {
  CREATON_FORMAT,
  NOTIFICATION_DATE,
  UNREAD,
} from '../../../../constants/helper';
import { updateNotification } from '../../../../store/actions';

const NotificationList = ({ updateNotification }) => {
  const organizationId = useRouteMatch().params.id;
  const history = useHistory();

  const [pagination, setPagination] = useState({
    data: [],
    limit: NOTIFICATION_LIMIT_LIST,
    offset: INITIAL_OFFSET,
    pageNumber: INITIAL_PAGE,
    pageCount: PAGE_COUNT,
    totalItems: TOTAL_ITEMS,
  });

  const [notificationList, updateNotificationList] = useState(null);

  const getNotificationList = useCallback(() => {
    const notificationsParams = {
      limit: pagination.limit,
      offset: pagination.offset,
      organizationId,
    };
    getSystemNotificationList(notificationsParams).then((result) =>
      updateNotificationList(result),
    );
  }, [pagination.offset, pagination.limit, organizationId]);

  const handleDismiss = (id, organizationId) => {
    changeSystemNotification(id, organizationId)
      .then(({ data }) => {
        const { message } = data;
        toast.success(<MessageResetSuccess message={message} />);
      })
      .then(() => {
        updateNotification();
        getNotificationList();
      });
  };

  const handleDismissAll = (organizationId) => {
    return changeAllSystemNotification(organizationId)
      .then(({ data }) => {
        const { message } = data;
        toast.success(<MessageResetSuccess message={message} />);
      })
      .then(() => {
        updateNotification();
        getNotificationList();
      });
  };

  useEffect(() => {
    getNotificationList();
  }, [
    pagination.offset,
    pagination.limit,
    organizationId,
    getNotificationList,
  ]);

  useEffect(() => {
    if (!notificationList) return;
    const {
      data,
      pagination: { totalCount },
    } = notificationList;
    setPagination((prev) => ({
      ...prev,
      pageCount: Math.ceil(totalCount / prev.limit),
      totalItems: totalCount,
      data,
    }));
  }, [notificationList]);

  const handlePageClick = ({ selected }) => {
    const offset = Math.ceil(selected * pagination.limit);
    setPagination((prev) => ({
      ...prev,
      offset,
      pageNumber: selected,
    }));
  };

  const handleRedirectTask = (organizationId, taskId) => {
    history.push(`/organizations/${organizationId}/tasks/${taskId}`);
  };

  return (
    <>
      <div className="notification-header">
        <h5 className="notification-title">Notifications</h5>
        <span onClick={() => handleDismissAll(organizationId)}>
          Dismiss all
        </span>
      </div>
      <hr className="mt-4" />
      <ul className="notification-list">
        {pagination.data.map(({ id, createdAt, task, status }) => {
          const { id: taskId, number, dueDate, name } = task;
          return (
            <li key={id} className="notification-item">
              <div className="notification-item__header">
                <h6>Action Assigned</h6>
              </div>
              <p className="notification-item__description">
                The Action has been assigned to you: <b>#{number}</b> {name}{' '}
                <b>Due Date: {moment(dueDate).format(CREATON_FORMAT)}</b>
                <br />
                Open the Action for more detailed information.
              </p>
              <div className="notification-item__footer">
                <p>{moment(createdAt).format(NOTIFICATION_DATE)}</p>
                <div>
                  {status === UNREAD && (
                    <span
                      className="dismiss"
                      onClick={() => handleDismiss(id, organizationId)}
                    >
                      Dismiss
                    </span>
                  )}
                  <span
                    className="see"
                    onClick={() => handleRedirectTask(organizationId, taskId)}
                  >
                    See action
                  </span>
                </div>
              </div>
              <hr />
            </li>
          );
        })}
      </ul>
      <Row>
        <Col className="d-flex justify-content-center">
          <CustomPagination
            pageCount={pagination.pageCount}
            forcePage={pagination.pageNumber}
            handlePageClick={handlePageClick}
          />
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { updateNotification })(NotificationList);
