import React from 'react';

const InfoColumnWrapper = ({ title, children }) => {
  return (
    <div className="info-column">
      <p className="info-column__title">{title}</p>
      {children}
    </div>
  );
};

export default InfoColumnWrapper;
