import React, { useMemo, useState } from 'react';
import { Row, Col } from 'reactstrap';

import HelpNavigation from '../Navigation';
import HelpPages from '../Pages';

import { getNavigationList } from '../navigationList';
import { INTRODUCTION_TAB } from '../../../constants/helper';
import { getDataUser } from '../../../utils/get-store-user';

const HelpContent = () => {
  const { role } = getDataUser();

  const [activeTab, updateActiveTab] = useState(INTRODUCTION_TAB);

  const navList = useMemo(() => {
    return getNavigationList(role);
  }, [role]);

  return (
    <Row>
      <Col md="3">
        <HelpNavigation
          navList={navList}
          activeTab={activeTab}
          onChangeTab={(newTab) => updateActiveTab(newTab)}
        />
      </Col>
      <Col md="9">
        <HelpPages navList={navList} activeTab={activeTab} />
      </Col>
    </Row>
  );
};

export default HelpContent;
