import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Card, CardBody, Media } from 'reactstrap';
import moment from 'moment';

import CardHeader from '../../shared/DueDateHeader';
import TableSpinner from '../../../tables/Spinner';
import NoDataPlaceholder from '../../shared/NoDataPlaceholder';

import { DUR_DATE_TRAINING_DASHBOARD } from '../../../../constants/helper';
import { getTrainings } from '../../../../utils/api/dashboard-api';

const DashboardTraining = () => {
  const history = useHistory();
  const { id: organizationId } = useRouteMatch().params;

  const [isLoading, updateIsLoading] = useState(false);
  const [trainingsData, updateTrainingsData] = useState([]);

  useEffect(() => {
    updateIsLoading(true);
    getTrainings({ organizationId }).then(({ data }) => {
      updateIsLoading(false);
      updateTrainingsData(data);
    });
  }, [organizationId]);

  return (
    <Card>
      <CardHeader
        title="Training to pass"
        onClick={() =>
          history.push(`/organizations/${organizationId}/training-records`)
        }
      />
      <CardBody className="dashboard-action-card">
        {isLoading && <TableSpinner />}
        {!isLoading && !trainingsData.length && (
          <NoDataPlaceholder title="No training records yet" />
        )}
        {!isLoading && !!trainingsData.length && (
          <ul className="verti-timeline list-unstyled training-list">
            {trainingsData.map(({ id, name, number, dueDate }) => (
              <li
                key={id}
                className="event-list"
                onClick={() =>
                  history.push(
                    `/organizations/${organizationId}/training-records/${id}`,
                  )
                }
              >
                <div className="event-timeline-dot">
                  <i className="bx bx-right-arrow-circle font-size-18"></i>
                </div>
                <Media className="d-flex align-items-center">
                  <div className="mr-3 d-flex align-items-center">
                    <h5 className="font-size-13">
                      Due Date:{' '}
                      {moment(dueDate).format(DUR_DATE_TRAINING_DASHBOARD)}
                      <i className="bx bx-right-arrow-alt font-size-16 align-middle ml-2"></i>
                    </h5>
                  </div>
                  <Media body>
                    <div>{name}</div>
                  </Media>
                </Media>
              </li>
            ))}
          </ul>
        )}
      </CardBody>
    </Card>
  );
};

export default DashboardTraining;
