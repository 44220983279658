import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { CardBody, Card, Button } from 'reactstrap';
import { toast } from 'react-toastify';

import {
  APPROVAL_DOCUMENT_TAB,
  REFERENCES_DOCUMENT_TAB,
  GENERAL_DOCUMENT_TAB,
  QUALITY_DOCUMENT_TAB,
  REVIEW_DOCUMENT_TAB,
} from '../../../constants/helper';
import { User } from '../../../constants/role';
import { getDataUser } from '../../../utils/get-store-user';
import DocumentDeleteModal from '../../../modals/DocumentDelete';
import { deleteDocument } from '../../../utils/backend-helper';
import MessageResetSuccess from '../../ToastSuccess';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';
import NavigationItem from '../../shared/NavigationItem';

const DocumentNavigation = ({
  accessPermission,
  activeTab,
  toggleTabs,
  document,
  revisionAddMode,
}) => {
  const history = useHistory();

  const { id: organizationId } = useRouteMatch().params;
  const { role } = getDataUser();

  const {
    canViewDocumentGeneral,
    canViewDocumentQualityTasks,
    canViewDocumentReferences,
    canViewReviews,
    canViewApprovals,
    canBeDeleted,
  } = accessPermission;

  const onError = useErrorHandler(`/organizations/${organizationId}/documents`);

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    model: {
      organizationId: null,
      documentIds: null,
    },
    values: {
      type: '',
      name: '',
      number: '',
      revision: '',
    },
  });

  const handleDeleteModal = (organizationId, document) => {
    const { id, type, name, number, revision, typeDescription } = document;
    setDeleteModal((prev) => ({
      isOpen: !prev.isOpen,
      model: {
        organizationId,
        documentIds: [id],
      },
      values: { type, name, number, revision, typeDescription },
    }));
  };

  return (
    <>
      <Card>
        <CardBody className="p-3">
          <ul
            className="nav nav-tabs nav-tabs-setting nav-custom-tabs"
            role="tablist"
          >
            {canViewDocumentGeneral && (
              <NavigationItem
                text="General"
                activeTab={activeTab}
                activedTab={GENERAL_DOCUMENT_TAB}
                onClick={() => toggleTabs(GENERAL_DOCUMENT_TAB)}
              />
            )}
            {canViewDocumentQualityTasks && (
              <NavigationItem
                text="Quality Actions"
                activeTab={activeTab}
                activedTab={QUALITY_DOCUMENT_TAB}
                onClick={() => toggleTabs(QUALITY_DOCUMENT_TAB)}
              />
            )}
            {canViewDocumentReferences && (
              <NavigationItem
                text="References"
                activeTab={activeTab}
                activedTab={REFERENCES_DOCUMENT_TAB}
                onClick={() => toggleTabs(REFERENCES_DOCUMENT_TAB)}
              />
            )}
            {canViewReviews && !revisionAddMode && (
              <NavigationItem
                text="Review"
                activeTab={activeTab}
                activedTab={REVIEW_DOCUMENT_TAB}
                onClick={() => toggleTabs(REVIEW_DOCUMENT_TAB)}
              />
            )}
            {canViewApprovals && !revisionAddMode && (
              <NavigationItem
                text="Approval"
                activeTab={activeTab}
                activedTab={APPROVAL_DOCUMENT_TAB}
                onClick={() => toggleTabs(APPROVAL_DOCUMENT_TAB)}
              />
            )}
          </ul>
          {!(role === User && !canBeDeleted) && (
            <>
              <hr className="nav-line" />
              <Button
                color="danger"
                className="nav-button"
                onClick={() => handleDeleteModal(organizationId, document)}
              >
                Delete document
              </Button>
            </>
          )}
        </CardBody>
      </Card>
      <DocumentDeleteModal
        isOpen={deleteModal.isOpen}
        values={deleteModal.values}
        model={deleteModal.model}
        isMulty={false}
        onSubmit={(_, { organizationId, documentIds }) => {
          deleteDocument(organizationId, documentIds)
            .then(({ data }) => {
              const { message } = data;
              toast.success(<MessageResetSuccess message={message} />);
              setDeleteModal((prev) => ({ ...prev, isOpen: false }));
              history.push(`/organizations/${organizationId}/documents`);
            })
            .catch(onError);
        }}
        onCancel={() => setDeleteModal((prev) => ({ ...prev, isOpen: false }))}
      />
    </>
  );
};

export default DocumentNavigation;
