import React from 'react';

const HelpIntroductionContent = () => {
  return (
    <div className="help-introduction-content">
      <p className="mb-2">
        Q Suite is a flexible electronic Quality Management System (eQMS) that
        can be tailored to fit the needs of any medical device company. The
        system was designed to allow compliance to 21 CFR 820 and conformance to
        ISO 13485:2016 and MDSAP for global marketing of medical devices.
      </p>
      <p className="mb-2">
        The central core to any quality system is documents and records. These
        provide the evidence that an organization has adequately established
        their QMS by defining, documenting, implementing, maintaining, and
        assessing the effectiveness of the QMS. This is the framework upon which
        Q Suite was built.
      </p>
      <p className="mb-2">
        Q Suite functions as an easy-to-use documentation control system, but it
        provides so much more value than that! Document structure is completely
        customizable, allowing for adaptation to any existing documentation
        control system. The system also incorporates automated processes such as
        training to ensure compliance is maintained, even in a rapidly changing
        organization.
      </p>
      <p className="mb-2">
        The information contained in this Help Menu will help users utilize Q
        Suite effectively in any organization!
      </p>
      <div className='d-flex justify-content-end mt-5'>
        <p className='logo-text'>
          Q Suite V 1.2
        </p>
      </div>
    </div>
  );
};

export default HelpIntroductionContent;
