import React from 'react';
import { formatBytes } from '../../../utils/format-bytes-helper';

const AttachedList = ({ files = [], onDeleteFile }) => {
  if (files.length) {
    return files.map(({ fileName, fileSize, id }) => (
      <div className="document-attached document-attached_delete" key={id}>
        <div className="file-wrapper">
          <i className="bx bxs-file-doc"></i>
        </div>
        <div className="file-info">
          <p className="file-info__name">{fileName}</p>
          <p className="file-info__size">Size: {formatBytes(fileSize)}</p>
        </div>
        <div className="file-info__remove">
          <i className="bx bxs-trash-alt" onClick={() => onDeleteFile(id)}></i>
        </div>
      </div>
    ));
  }

  return null;
};

export default AttachedList;
