import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CHECKBOX_WIDTH, ONE_HUNDRED_PERCENT } from '../constants/table';

export const useTableWidth = (updateTable, customWidth) => {
  const tableRef = useRef();
  const { leftSideBarType } = useSelector(({ Layout }) => Layout);

  useEffect(() => {
    if (tableRef) {
      let tableWidth =
        tableRef.current.getBoundingClientRect().width -
        (customWidth || CHECKBOX_WIDTH);

      updateTable((columns) => {
        return columns.map((column) => {
          return {
            ...column,
            width: (tableWidth * column.widthPercent) / ONE_HUNDRED_PERCENT,
            savedWidth:
              (tableWidth * column.widthPercent) / ONE_HUNDRED_PERCENT,
          };
        });
      });
    }
  }, [leftSideBarType, customWidth, updateTable]);

  return tableRef;
};
