import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button, Col, Row } from 'reactstrap';

import InfoColumn from '../../../../../components/shared/GeneralColumn';
import ListSectionContainer from '../ListSection';
import HumanRecordsAttachedFile from '../AttachedFile';
import PlaceholderText from '../../../../../components/shared/PlaceholderText';

import {
  getActivated,
  getStatus,
} from '../../../../../utils/human-resources-helper/get-status';
import { User } from '../../../../../constants/role';
import { CREATON_FORMAT, FORMAT_TIME } from '../../../../../constants/helper';
import TagsList from '../../../../../components/shared/TagsList';
import TaskDeleteModal from '../../../../../modals/TaskDelete';
import { deleteHumanRecource } from '../../../../../utils/api/human-resources-api';
import { toast } from 'react-toastify';
import MessageResetSuccess from '../../../../../components/ToastSuccess';
import CellBlock from '../../../../../components/shared/CellBlock';
import { SUCCESS_RECORD_DELETE } from '../../../../../constants/messages';

const HumanResourcesView = ({
  user: currentUser,
  humanResourcesModel,
  updateEditMode,
  organizationId,
}) => {
  const history = useHistory();

  const {
    id,
    firstName,
    lastName,
    status,
    createdAt,
    documents,
    trainings,
    userGroups,
    activated,
    canBeDeleted,
    role,
    canBeEdited,
    file,
    tags,
  } = humanResourcesModel;

  const { role: currentRole } = currentUser;

  const [deleteModal, updateDeleteModal] = useState({
    isOpen: false,
    model: {
      organizationId: null,
      ids: [],
    },
    values: {
      countChecked: null,
    },
  });

  const handleDelete = (organizationId, id, firstName, lastName, role) => {
    updateDeleteModal({
      isOpen: true,
      values: {
        name: `${firstName || ''} ${lastName || ''} ${role ? `- ${role}` : ''}`,
      },
      model: { organizationId, ids: [id] },
    });
  };

  return (
    <>
      <div className="general-header">
        <h6>
          {firstName} {lastName} Details
        </h6>
        <div className="d-flex">
          {canBeDeleted && (
            <Button
              className="button-delete"
              onClick={() =>
                handleDelete(organizationId, id, firstName, lastName, role)
              }
            >
              Delete record
            </Button>
          )}
          {(canBeEdited || currentRole !== User) && (
            <button
              className="general__btn-edit"
              onClick={() => updateEditMode((prev) => !prev)}
            >
              <i className="bx bxs-edit-alt"></i> Edit
            </button>
          )}
        </div>
      </div>
      <hr />
      <Row>
        <Col sm="6">
          <InfoColumn
            title="First and Last Name:"
            text={`${firstName} ${lastName}`}
          />
        </Col>
        <Col sm="2">
          <InfoColumn title="Status:" text={`${getStatus(status) || ''} `} />
        </Col>
        <Col sm="2">
          <CellBlock
            title="Activated:"
            text={`${getActivated(activated) || ''}`}
            classes="d-flex flex-column align-items-start align-items-sm-center"
          />
        </Col>
        <Col sm="2">
          <InfoColumn
            title="Role:"
            text={`${role}`}
            classes="text-left text-sm-right"
          />
        </Col>
      </Row>
      <hr />
      <TagsList tags={tags} />
      <hr />
      <ListSectionContainer title="User Group(s):">
        {userGroups.length ? (
          userGroups.map(({ id, name, userCount }, i, arr) => (
            <li key={id}>
              {currentRole === User ? (
                <p>
                  {name} - {userCount} user(s)
                </p>
              ) : (
                <Link target='_self' to={`/organizations/${organizationId}/user-groups/${id}`}>
                  <i className='bx bx-link top-link'></i>
                  {name} - {userCount} user(s) 
                </Link>
              )}
              {arr.length !== i + 1 && <hr />}
            </li>
          ))
        ) : (
          <PlaceholderText text="No user groups yet." />
        )}
      </ListSectionContainer>
      <ListSectionContainer title="Connected Document(s):">
        {documents.length ? (
          documents.map(({ id, name }, i, arr) => (
            <li key={id}>
              <Link target='_self' to={`/organizations/${organizationId}/documents/${id}`}>
                <i className='bx bx-link top-link'></i>
                {name}
              </Link>
              {arr.length !== i + 1 && <hr />}
            </li>
          ))
        ) : (
          <PlaceholderText text="No documents yet." />
        )}
      </ListSectionContainer>
      <ListSectionContainer title="Connected Training(s):">
        {trainings.length ? (
          trainings.map(({ id, name }, i, arr) => (
            <li key={id}>
              <Link target='_self' to={`/organizations/${organizationId}/training-records/${id}`}>
                <i className='bx bx-link top-link'></i>
                {name}
              </Link>
              {arr.length !== i + 1 && <hr />}
            </li>
          ))
        ) : (
          <PlaceholderText text="No trainings yet." />
        )}
      </ListSectionContainer>
      <hr />
      <div className="general-attached">
        <div className="info-column">
          <p className="info-column__title">File attached:</p>
          <HumanRecordsAttachedFile files={file ? [file] : []} />
        </div>
      </div>
      <hr />
      <Row className="mt-3">
        <Col>
          <InfoColumn
            title="Creation Date:"
            text={createdAt ? moment(createdAt).format(CREATON_FORMAT) : ''}
          />
        </Col>
        <Col>
          <InfoColumn
            title="Creation Time:"
            text={createdAt ? moment(createdAt).format(FORMAT_TIME) : ''}
            classes="info-column_right"
          />
        </Col>
      </Row>
      <TaskDeleteModal
        title="Human Resource"
        footerText="It will be permanently deleted."
        isOpen={deleteModal.isOpen}
        model={deleteModal.model}
        values={deleteModal.values}
        onSubmit={(_, values) => {
          deleteHumanRecource(values)
            .then(({ data }) => {
              history.push(`/organizations/${organizationId}/human-resources/`);
              toast.success(
                <MessageResetSuccess message={SUCCESS_RECORD_DELETE} />,
              );
            })
            .finally(() => {
              updateDeleteModal((prev) => ({ ...prev, isOpen: false }));
            });
        }}
        onCancel={() =>
          updateDeleteModal((prev) => ({ ...prev, isOpen: false }))
        }
      />
    </>
  );
};

export default HumanResourcesView;
