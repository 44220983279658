import React from 'react';
import { CardTitle, Col, Row } from 'reactstrap';

import TableAddNew from '../TableAddNew';
import TableOrganizationSettings from '../OrganizationSetting';
import TableSearch from '../TableSearch';

const TableHeaderOrganizations = ({
  title,
  handleAddNew,
  downloadCSV,
  manageColumns,
  handleSearch,
  isOpenExportLogs,
  handleOpenExportLogs,
}) => {
  return (
    <div>
      <Row>
        <Col sm='6' className="d-flex align-items-center">
          <CardTitle className="mb-0">{title}</CardTitle>
        </Col>
        <Col sm='6' className="d-flex justify-content-start justify-content-sm-end">
          <TableAddNew onClick={handleAddNew} />
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col sm="8" className="d-flex align-items-center flex-wrap">
          <TableOrganizationSettings
            isOpenExportLogs={isOpenExportLogs}
            handleOpenExportLogs={handleOpenExportLogs}
            downloadCSV={downloadCSV}
            manageColumns={manageColumns}
          />
        </Col>
        <Col sm="4" className="d-flex justify-content-end">
          <TableSearch handleSearch={handleSearch} />
        </Col>
      </Row>
    </div>
  );
};

export default TableHeaderOrganizations;
