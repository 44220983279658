import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const RequiresTrainingText = () => {
  return (
    <>
      Create & Link Training
      <i
        id="Tooltip-accordion-link"
        className="bx bx-info-circle info-tooltip"
      ></i>
      <UncontrolledTooltip
        placement="top"
        id="tooltip-document"
        target="Tooltip-accordion-link"
      >
        <p className="mb-0">
          You will open the screen to create a Training that will be linked to
          the current Document
        </p>
      </UncontrolledTooltip>
    </>
  );
};

export default RequiresTrainingText;
