import React from 'react';
import HelpImage from '../../shared/HelpImage';

import trainingImage from '../../../../assets/images/help-screen/flow/flow-21.png';
import trainingImage2 from '../../../../assets/images/help-screen/flow/flow-22.png';
import trainingImage3 from '../../../../assets/images/help-screen/flow/flow-23.png';
import trainingImage4 from '../../../../assets/images/help-screen/flow/flow-24.png';
import trainingImage5 from '../../../../assets/images/help-screen/flow/flow-25.png';
import trainingImage6 from '../../../../assets/images/help-screen/flow/flow-26.png';
import trainingImage7 from '../../../../assets/images/help-screen/flow/flow-27.png';
import trainingImage8 from '../../../../assets/images/help-screen/flow/flow-28.png';
import trainingImage9 from '../../../../assets/images/help-screen/flow/flow-29.png';
import trainingImage10 from '../../../../assets/images/help-screen/flow/flow-30.png';

export const trainingRecordTexts = [
  {
    text: 'Assignees and Links',
    modalContent: (
      <>
        <p>
          One of the required fields is the Training Assignee(s). Assignees can
          be any Admin or User in the organization. Multiple assignees can be
          selected. An entire User Group can also be added as a Training
          Assignee. In this case all the Users of the User Group become the
          Training Assignees.
        </p>
        <HelpImage src={trainingImage} />
        <p>
          One major benefit of assigning trainings to User Groups is that when
          new Users are added to User Groups, existing trainings for that User
          Groups may be assigned to the new User.
        </p>
        <HelpImage src={trainingImage2} />
        <HelpImage src={trainingImage3} />
        <p>
          Users may have the “Edit” permission for a Training. This depends upon
          the individual access permissions and the User’s relationship to the
          specific Training (Creator, Assignee, etc).
        </p>
        <HelpImage src={trainingImage4} />
        <p>
          Trainings can be linked to an existing artifact in the Organization.
          Only 1 link may be added. Links can be added for a Document Revision,
          Action, or HR Record. A similar option is also available to add an
          External Link - which allows the user to configure the link name and
          insert any direct link here.
        </p>
        <HelpImage src={trainingImage5} />
      </>
    ),
  },
  {
    text: 'Training Competence',
    modalContent: (
      <>
        <p>
          When a training record is created or edited, multiple choice Quiz
          questions can be configured. Quizzes can be used to demonstrate
          competence of users. When a question is added, it is required to
          define at least 2 possible answers are provided, and one is marked as
          the correct answer.
        </p>
        <HelpImage src={trainingImage6} />
        <p>
          When users are assigned to a training, the “Pass Training” option will
          be available for those users. 
        </p>
        <HelpImage src={trainingImage7} />
        <p>
          When users enter the Pass Training mode,
          users will review the training materials, answer any Quiz Questions,
          and select the confirmation checkboxes. Users must answer all Quiz
          questions correctly to pass the training.
        </p>
        <HelpImage src={trainingImage8} />
        <p>
          When a user passes the training, this is reflected in the Completion
          Progress section. Users who have not yet completed the training will
          be listed under “Pending”. The results of an individual user’s
          training can be viewed by selecting “Show Details”.
        </p>
        <HelpImage src={trainingImage9} />
        <HelpImage src={trainingImage10} />
      </>
    ),
  },
];
