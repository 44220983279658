import { useState, useEffect } from 'react';

const getIsFormEmpty = (inputs) =>
  !Object.values(inputs).every((value) => value);

const getObjectForm = (arrayInputs) => {
  return arrayInputs.reduce((acc, input) => {
    acc[input] = '';
    return acc;
  }, {});
};

export const useEmptyForm = (inputs) => {
  const [disabled, setDisable] = useState(true);

  const [values, setValues] = useState(() => getObjectForm(inputs));

  const onChange = (event) => {
    const { name, value } = event.target;
    setValues((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  useEffect(() => {
    const isEmpty = getIsFormEmpty(values);
    isEmpty ? setDisable(true) : setDisable(false);
  }, [values]);

  return { disabled, onChange, values };
};
