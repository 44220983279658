import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

import GlobalSearchTable from '../Table';
import TablePagination from '../../tables/Pagination';
import TablePlaceholder from '../../tables/Placeholder';

import {
  EMPTY_STRING,
  INITIAL_OFFSET,
  INITIAL_PAGE,
  LIMIT,
  ORDER_BY_KEY,
  ORDER_DIRECTION_KEY,
  PAGE_COUNT,
  TOTAL_ITEMS,
} from '../../../constants/table';
import { getGlobalSearch } from '../../../utils/api/organization-api';
import { getSortedTable } from '../../../utils/tables-helper/get-sorted-table';
import { globalSearchColumns } from '../Columns';
import { getExistingParams } from '../../../utils/tables-helper/get-existing-params';
import {
  changeLimit,
  changeOffset,
  changePage,
  getInitialPage,
  getPaginationChanges,
} from '../../../utils/tables-helper/table-pagination';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';
import { FINISHED_LOADING } from '../../../store/search/actionTypes';
import TableSpinner from '../../tables/Spinner';
import { dragReorder } from '../../../utils/dragable-helpers/dragable-reoder';
import {
  getChangedResizeColumns,
  getEndResizeColumns,
} from '../../../utils/tables-helper/resizable';

const GlobalSearchContainer = () => {
  const dispatch = useDispatch();

  const { id: organizationId } = useRouteMatch().params;
  const { inputSearch: search } = useSelector(({ Search }) => Search);

  const [data, updateData] = useState([]);
  const [isLoading, updateIsLoading] = useState(false);

  const [pagination, updatePagination] = useState({
    limit: LIMIT,
    offset: INITIAL_OFFSET,
    search: EMPTY_STRING,
    [ORDER_BY_KEY]: EMPTY_STRING,
    [ORDER_DIRECTION_KEY]: EMPTY_STRING,
  });

  const [paginatePage, updatePaginatePage] = useState({
    currentPage: INITIAL_PAGE,
    pageCount: PAGE_COUNT,
    totalCount: TOTAL_ITEMS,
  });

  const onError = useErrorHandler();

  const getDataAPI = useCallback(() => {
    const params = getExistingParams(pagination);
    updateIsLoading(true);
    return getGlobalSearch(organizationId, params)
      .then(({ data, pagination: { totalCount } }) => {
        updateData(data);
        updatePaginatePage((paginationPage) =>
          getPaginationChanges(paginationPage, totalCount, pagination.limit),
        );
      })
      .catch(onError)
      .finally(() => {
        updateIsLoading(false);
        dispatch({ type: FINISHED_LOADING });
      });
  }, [dispatch, onError, organizationId, pagination]);

  const handleSort = (field) => {
    updatePagination((pagination) => getSortedTable(pagination, field));
  };

  useEffect(() => {
    updatePagination((pagination) => ({ ...pagination, search }));
  }, [search]);

  const isInitialLoading = useRef(false);

  useEffect(() => {
    if (isInitialLoading.current) {
      getDataAPI();
      return;
    }
    isInitialLoading.current = true;
  }, [getDataAPI]);

  const [columns, updateColumns] = useState(globalSearchColumns);
  // const tableRef = useTableWidth(updateColumns);

  return (
    <Row className="d-flex justify-content-center">
      <Col xs="12">
        <Card>
          <CardBody>
            <Row>
              <Col>
                <CardTitle className="mb-3">Search result</CardTitle>
              </Col>
            </Row>
            {!!(data?.length && !isLoading) && (
              <>
                <GlobalSearchTable
                  // tableRef={tableRef}
                  updateColumns={updateColumns}
                  rows={data}
                  columns={columns}
                  handleSort={handleSort}
                  orderBy={pagination[ORDER_BY_KEY]}
                  orderType={pagination[ORDER_DIRECTION_KEY]}
                  handleChangeColumns={(columns, startIndex, endIndex) => {
                    updateColumns(dragReorder(columns, startIndex, endIndex));
                  }}
                  onResizeColumns={(columnIndex, changedWidth) => {
                    updateColumns((columns) =>
                      getChangedResizeColumns(
                        columns,
                        columnIndex,
                        changedWidth,
                      ),
                    );
                  }}
                  onResizeEnd={(columnIndex) => {
                    updateColumns((columns) =>
                      getEndResizeColumns(columns, columnIndex),
                    );
                  }}
                />
                <TablePagination
                  limit={pagination.limit}
                  pageCount={paginatePage.pageCount}
                  currentPage={paginatePage.currentPage}
                  handleChangeLimit={(limit) => {
                    updatePagination((pagination) =>
                      changeLimit(pagination, limit),
                    );
                    updatePaginatePage((paginationPage) =>
                      getInitialPage(paginationPage),
                    );
                  }}
                  handleChangePage={({ selected }) => {
                    updatePagination((pagination) =>
                      changeOffset(pagination, selected),
                    );
                    updatePaginatePage((paginationPage) =>
                      changePage(paginationPage, selected),
                    );
                  }}
                />
              </>
            )}
            {!(data?.length || isLoading) && (
              <TablePlaceholder text="No records to display" />
            )}
            {isLoading && <TableSpinner />}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default GlobalSearchContainer;
