import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { TAG_SEARCH } from '../../../store/search/actionTypes';

const TagsList = ({ tags = [] }) => {
  const dispatch = useDispatch();

  const handleSearch = (name) => {
    dispatch({ type: TAG_SEARCH, payload: name });
  };

  return (
    <Row>
      <Col className="tags-list">
        <p className="tags-list__title">Tag(s):</p>
        {tags.length ? (
          <div className="tag-menu-container">
            {tags.map(({ id, name }) => {
              return (
                <div
                  key={id}
                  onClick={() => handleSearch(name)}
                  className="filter-item"
                >
                  <span>{name}</span>
                </div>
              );
            })}
          </div>
        ) : (
          <p>There are no tags added</p>
        )}
      </Col>
    </Row>
  );
};

export default TagsList;
