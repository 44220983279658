import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useRouteMatch } from 'react-router';
import { Card, CardBody } from 'reactstrap';
import moment from 'moment';

import CardHeader from '../../shared/DueDateHeader';
import TableSpinner from '../../../tables/Spinner';
import NoDataPlaceholder from '../../shared/NoDataPlaceholder';

import { DUE_DATE_DASHBOARD } from '../../../../constants/helper';
import { getActions } from '../../../../utils/api/dashboard-api';

const DashboardActions = () => {
  const history = useHistory();
  const { id: organizationId } = useRouteMatch().params;

  const [isLoading, updateIsLoading] = useState(false);
  const [actionsData, updateActionsData] = useState([]);

  useEffect(() => {
    updateIsLoading(true);
    getActions({ organizationId })
      .then(({ data }) => updateActionsData(data))
      .finally(() => updateIsLoading(false));
  }, [organizationId]);

  return (
    <Card>
      <CardHeader
        title="Actions to do"
        onClick={() => history.push(`/organizations/${organizationId}/tasks`)}
      />
      <CardBody className="dashboard-action-card">
        {isLoading && <TableSpinner />}
        {!isLoading && !actionsData.length && (
          <NoDataPlaceholder title="No actions yet" />
        )}
        {!isLoading && !!actionsData.length && (
          <ul className="actions-list">
            {actionsData.map(({ id, name, dueDate }, index) => (
              <li key={id}>
                <Link
                  className="action"
                  target="_self"
                  to={`/organizations/${organizationId}/tasks/${id}`}
                >
                  <p className="action__date">
                    Due Date: {moment(dueDate).format(DUE_DATE_DASHBOARD)}
                  </p>
                  <p className="action__text">{name}</p>
                </Link>
                {index !== actionsData.length - 1 && <hr />}
              </li>
            ))}
          </ul>
        )}
      </CardBody>
    </Card>
  );
};

export default DashboardActions;
