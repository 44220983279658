import React from 'react';
import { Row, Col, Alert, Card, CardBody, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import { useEmptyForm } from '../useEmptyForm';
import { userForgetPassword } from '../../../store/actions';

const ForgetPasswordPage = ({
  userForgetPassword,
  history,
  forgetError,
  forgetSuccessMsg,
}) => {
  const handleValidSubmit = (event, values) => {
    userForgetPassword(values, history);
  };

  const formInputs = ['email'];

  const { disabled, onChange, values } = useEmptyForm(formInputs);

  return (
    <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-12 text-center">
                      <div className="login-header">
                        <h5>Reset Password</h5>
                        <p>
                          Enter your Email and instructions will be sent to you.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    {forgetError && (
                      <Alert color="danger" className="alert-message">
                        {forgetError}
                      </Alert>
                    )}
                    {forgetSuccessMsg ? (
                      <Alert color="success" className="alert-message">
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          value={values.email}
                          onChange={onChange}
                        />
                      </div>
                      <Row className="form-group">
                        <Col className="text-right">
                          <button
                            className="btn w-md waves-effect waves-light w-100"
                            disabled={disabled}
                            type="submit"
                          >
                            Send
                          </button>
                        </Col>
                      </Row>
                      <div className="mt-4 text-center">
                        <Link to="/login" className="text-password">
                          Back to login
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStatetoProps = ({
  ForgetPassword: { forgetError, forgetSuccessMsg },
}) => ({ forgetError, forgetSuccessMsg });

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage),
);
