export const changeCheckboxChecked = (list, checkId) => {
  return list.map((item = {}) => {
    const { id, isChecked = false } = item;

    return id === checkId ? { ...item, isChecked: !isChecked } : item;
  });
};

export const changeCheckedFromIndex = (list, checkedIndex) => {
  return list.map((item, index) => {
    return checkedIndex === index
      ? { ...item, isChecked: !item.isChecked }
      : item;
  });
};
