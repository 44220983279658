import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';

import ReferencesView from './View';
import ReferencesEdit from './Edit';
import ReferencesAddRevision from './AddRevision';

import { useQueryHelper } from '../../../utils/query-params-helper';
import { getReferences } from '../../../utils/api/document-api';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';
import { REVISION_PARAM } from '../../../constants/helper';
import { configRevisionRequest } from '../../../utils/documents/config-body-revision';

const References = ({
  currentRevision,
  revision,
  updateCurrentRevision,
  rUpdateModel,
  revisionAddMode,
}) => {
  const { id: organizationId, documentId } = useRouteMatch().params;

  const { revision: revisionParams } = useQueryHelper([REVISION_PARAM]);

  const [editMode, updateEditMode] = useState(false);

  const onError = useErrorHandler();

  const [referencesData, updateReferencesData] = useState({ references: [] });
  const { references } = referencesData;

  useEffect(() => {
    const revision = currentRevision.value || revisionParams;
    const params = configRevisionRequest({ organizationId }, revision);
    getReferences(documentId, params)
      .then(({ data }) => updateReferencesData(data))
      .catch(onError);
  }, [currentRevision, documentId, onError, organizationId, revisionParams]);

  if (revisionAddMode) {
    return (
      <ReferencesAddRevision
        updateModel={rUpdateModel}
        oldReferences={references}
      />
    );
  }

  if (editMode) {
    return (
      <ReferencesEdit
        references={references}
        updateEditMode={updateEditMode}
        currentRevision={currentRevision}
        revision={revision}
        updateCurrentRevision={updateCurrentRevision}
      />
    );
  }

  return (
    <ReferencesView
      referencesData={referencesData}
      updateEditMode={updateEditMode}
    />
  );
};

export default References;
