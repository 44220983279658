import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { AvForm, AvInput, AvField } from 'availity-reactstrap-validation';
import moment from 'moment';
import Select from 'react-select';

import ReviewComment from '../../ReviewComment';
import { updateApproval, getApprovals } from '../../../utils/backend-helper';
import {
  APPROVAL_STATUS,
  DOCUMENT_RESPONSE_STATUS,
  REVISION_PARAM,
} from '../../../constants/helper';
import {
  MAX_TEXTAREA_LENGTH,
  MAX_ACCESS_CODE,
} from '../../../constants/helper';
import Button from '../../Button';
import { configRevisionRequest } from '../../../utils/documents/config-body-revision';
import { useQueryHelper } from '../../../utils/query-params-helper';
import { useErrorHandler } from '../../../custom-hooks/useErrorHandler';
import { customStyles } from '../../../utils/select-styles';
import InputWrapper from '../../shared/InputWrapper/InputWrapper';
import ReviewColumn from '../Review/Column';

const DocumentApproval = ({
  currentRevision,
  revision,
  updateCurrentRevision,
}) => {
  const { id: organizationId, documentId } = useRouteMatch().params;
  const { revision: revisionParams } = useQueryHelper([REVISION_PARAM]);

  const [editMode, updateEditMode] = useState(false);

  const [approvalInfo, updateApproverInfo] = useState({
    approvals: [],
    canAddApproval: false,
  });

  const [model, updateModel] = useState({
    status: null,
    comment: '',
  });

  useEffect(() => {
    const revison = currentRevision.value || revisionParams;
    const params = configRevisionRequest({ organizationId }, revison);
    getApprovals(documentId, params).then(({ data }) =>
      updateApproverInfo(data),
    );
  }, [documentId, organizationId, currentRevision, revisionParams]);

  const responseOptions = [
    { value: '0', label: 'Approved', status: '0' },
    { value: '1', label: 'Rejected', status: '1' },
  ];

  const handleResponseChange = (value) => {
    updateModel((prev) => ({ ...prev, status: value.status }));
  };

  const handleModelChange = (event) => {
    const { value } = event.target;
    updateModel((prev) => ({ ...prev, comment: value }));
  };

  const onError = useErrorHandler(`/organizations/${organizationId}/documents`);

  const handleSubmit = (_, values) => {
    const { documentId, ...body } = values;
    updateApproval(documentId, body)
      .then(() => {
        updateEditMode(false);
        updateCurrentRevision((prev) => ({ ...prev }));
        const params = configRevisionRequest(
          { organizationId },
          currentRevision.value,
        );
        getApprovals(documentId, params).then(({ data }) =>
          updateApproverInfo(data),
        );
      })
      .catch(onError);
  };

  return (
    <div className="general">
      <div className="general-header">
        <h6 className="general-header__title">Approval</h6>
        {approvalInfo.canAddApproval && (
          <button
            className="general__btn-edit"
            onClick={() => updateEditMode(true)}
          >
            <i className="bx bxs-edit-alt"></i> Edit
          </button>
        )}
      </div>
      <hr />
      {editMode ? (
        <AvForm className="form-horizontal mt-2" onValidSubmit={handleSubmit}>
          <InputWrapper text="Approval Response">
            <Select
              placeholder="Choose approval response"
              styles={customStyles}
              options={responseOptions}
              onChange={(value) => handleResponseChange(value)}
            />
          </InputWrapper>
          <InputWrapper text="Comment">
            <AvInput
              type="textarea"
              id="textarea"
              name="comment"
              placeholder="Leave comment"
              value={model.revisionDescription}
              onChange={(event) => handleModelChange(event)}
              maxLength={MAX_TEXTAREA_LENGTH}
              rows="3"
            />
          </InputWrapper>
          <InputWrapper text="Access Code">
            <AvInput
              type="number"
              name="accessCode"
              placeholder="Enter access code"
              value={model.accessCode}
              onChange={(event) => handleModelChange(event)}
              max={MAX_ACCESS_CODE}
            />
          </InputWrapper>
          <Row className="form-group mt-4">
            <Col className="text-right">
              <Button
                type="button"
                color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
                handler={() => updateEditMode(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={model.status === null}
                color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
              >
                Save Changes
              </Button>
            </Col>
          </Row>
          <AvField
            name="organizationId"
            type="text"
            value={organizationId}
            className="btn-hidden"
          />
          <AvField
            name="documentId"
            type="text"
            value={documentId}
            className="btn-hidden"
          />
          <AvField
            name="status"
            type="text"
            value={model.status}
            className="btn-hidden"
          />
          <AvField
            name="revision"
            type="text"
            value={currentRevision.value || revision}
            className="btn-hidden"
          />
        </AvForm>
      ) : (
        <>
          <div className="general-responsible">
            <ReviewColumn
              title="Status"
              text={DOCUMENT_RESPONSE_STATUS[approvalInfo.status]}
            />
            <div className="info-column info-column_right">
              <p className="info-column__title">Approved Date:</p>
              <p className="info-column__text">
                {approvalInfo.approvedAt
                  ? moment(approvalInfo.approvedAt).format('ll')
                  : ''}
              </p>
            </div>
          </div>
          <hr />
          {approvalInfo.approvals.map(
            ({ firstName, lastName, email, comment, responseStatus }, i) => {
              return (
                <div className="review-user" key={lastName + i}>
                  <div className="review-responses">
                    <ReviewColumn
                      title="Approver name:"
                      text={`${firstName} ${lastName}`}
                    />
                    <ReviewColumn title="Email:" text={email} />
                    <ReviewColumn
                      title="Response:"
                      text={APPROVAL_STATUS[responseStatus]}
                    />
                  </div>
                  <ReviewComment comment={comment} />
                  <hr />
                </div>
              );
            },
          )}
          {!approvalInfo.approvals.length && (
            <h5 className="mt-2">There are no approval responses yet</h5>
          )}
        </>
      )}
    </div>
  );
};

export default DocumentApproval;
