import React from 'react';
import { Container } from 'reactstrap';

import ProfileContainer from '../../../../components/Profile/Container';
import Breadcrumbs from '../../../../components/shared/Breadcrumbs/Breadcrumbs';

import { breadCrumbItemsProfile } from './breadcrumb-items';

const ProfileEdit = ({ handleUpdateUser }) => {
  return (
    <Container>
      <Breadcrumbs
        link="/profile"
        backText="Back to Profile"
        breadCrumbItems={breadCrumbItemsProfile}
      />
      <ProfileContainer handleUpdateUser={handleUpdateUser} />
    </Container>
  );
};

export default ProfileEdit;
