import React, { useState, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, Row, FormGroup } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import moment from 'moment';

import Index from '../../../../components/Button';
import TaskLinkToRecord from '../../../../components/task/LinkToRecord';
import TaskExternalLink from '../../../../components/task/ExternalLink';
import TagField from '../../../../components/shared-forms/TagField';

import {
  MAX_INPUT_LENGTH,
  MAX_TEXTAREA_LENGTH,
  DEBOUNCE_TIMER,
  FORMAT_DUE_DATE,
} from '../../../../constants/helper';
import { customStyles } from '../../../../utils/select-styles';
import { getDocumentUsers } from '../../../../utils/backend-helper';
import { configureGetUsers } from '../../../../utils/config-get-organization';

import { getLinkToRecord } from '../../../../utils/tasks-helper/get-task-link';
import { getUnionArrayIds } from '../../../../utils/shared/get-union-array';
import { isEmptyValue } from '../../../../utils/is-empty-value';
import { validateEmptyText } from '../../../../constants/validatePassword';
import { REQUIRED_FIELD } from '../../../../constants/form';

const TaskForm = ({ handleSubmit, handleCancel, user }) => {
  const organizationId = useRouteMatch().params.id;
  const defaultUser = {
    label: `${user.firstName || ''} ${user.lastName || ''} - ${user.email} - ${
      user.role
    }`,
    value: `${user.id}`,
  };

  const [model, updateModel] = useState({
    assignerId: {
      value: user.id,
      required: true,
    },
    assigneeId: {
      value: null,
      required: true,
    },
    name: {
      value: '',
      required: true,
    },
    description: {
      value: null,
      required: true,
    },
    link: {
      value: null,
      required: false,
    },
    linkDescription: {
      value: null,
      required: false,
    },
    dueDate: {
      value: moment().format(FORMAT_DUE_DATE),
      required: true,
    },
    extraLink: {
      value: null,
      required: false,
    },
    extraLinkDescription: {
      value: null,
      required: false,
    },
    tagIds: {
      value: null,
      required: false,
    },
  });

  const handleModelChange = (event) => {
    const { name, value } = event.target;
    updateModel((prev) => ({
      ...prev,
      [name]: { ...prev[name], value },
    }));
  };

  const handleAssigneeChange = ({ id: assigneeId }) => {
    updateModel((prev) => ({
      ...prev,
      assigneeId: { ...prev['assigneeId'], value: assigneeId },
    }));
  };

  const debounceResponses = debounce((inputValue, callback) => {
    getDocumentUsers(organizationId, inputValue).then(({ data }) =>
      callback(configureGetUsers(data)),
    );
  }, DEBOUNCE_TIMER);

  const [userOptions, updateUserOptions] = useState([]);

  useEffect(() => {
    getDocumentUsers(organizationId, '').then(({ data }) =>
      updateUserOptions(configureGetUsers(data)),
    );
  }, [organizationId]);

  const handleAssigneerChange = ({ id: assignerId }) => {
    updateModel((prev) => ({
      ...prev,
      assignerId: { ...prev['assignerId'], value: assignerId },
    }));
  };

  const debounceHandleSearch = useMemo(() => {
    return debounce((input) => {
      getDocumentUsers(organizationId, input).then(({ data }) =>
        updateUserOptions(configureGetUsers(data)),
      );
    }, DEBOUNCE_TIMER);
  }, [organizationId]);

  const handleAssigneerSearch = (value) => {
    debounceHandleSearch(value);
  };

  const isValidForm = useMemo(() => {
    return Object.values(model).every(({ value, required }) => {
      if (!required) return true;
      return isEmptyValue(value);
    });
  }, [model]);

  const tasksOptions = [{ label: 'To do', value: 'To do' }];

  return (
    <AvForm onValidSubmit={handleSubmit}>
      <div className="general">
        <div className="general-header">
          <h6 className="mt-2">Create New Action</h6>
        </div>
        <hr />
        <Row>
          <Col xs="12">
            <div className="info-column">
              <p className="info-column__title">Action name*</p>
              <FormGroup className="select2-container">
                <AvField
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter action name"
                  maxLength={MAX_INPUT_LENGTH}
                  onChange={(event) => handleModelChange(event)}
                  value={model.name.value}
                  validate={validateEmptyText}
                  errorMessage={REQUIRED_FIELD}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            <div className="info-column">
              <p className="info-column__title">Action status*</p>
              <FormGroup className="select2-container">
                <Select
                  styles={customStyles}
                  defaultValue={tasksOptions[0]}
                  options={tasksOptions}
                  placeholder="Choose document type"
                />
              </FormGroup>
            </div>
          </Col>
          <Col xs="6">
            <div className="info-column">
              <p className="info-column__title">Due Date*</p>
              <FormGroup className="select2-container">
                <input
                  className="form-control"
                  type="date"
                  name="dueDate"
                  value={model.dueDate.value}
                  onChange={(event) => handleModelChange(event)}
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="info-column">
              <p className="info-column__title">Action description*</p>
              <FormGroup className="select2-container">
                <AvField
                  type="textarea"
                  id="textarea"
                  name="description"
                  placeholder="Enter description here"
                  maxLength={`${MAX_TEXTAREA_LENGTH}`}
                  onChange={(event) => handleModelChange(event)}
                  value={model.description.value}
                  validate={validateEmptyText}
                  errorMessage={REQUIRED_FIELD}
                  rows="3"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <TagField
          onChange={(values) => {
            updateModel((prev) => ({
              ...prev,
              tagIds: {
                ...prev.tagIds,
                value: getUnionArrayIds(values),
              },
            }));
          }}
        />
        <hr />
        <TaskLinkToRecord
          updateLinkToRecord={(value, link) => {
            updateModel((prev) => getLinkToRecord(prev, value, link));
          }}
        />
        <TaskExternalLink
          extraLinkDescription={model.extraLinkDescription.value}
          extraLink={model.extraLink.value}
          handleModelChange={handleModelChange}
        />
        <Row className="mt-3">
          <Col xs="12" md="6">
            <div className="info-column">
              <p className="info-column__title">Action Assignee*</p>
              <FormGroup className="select2-container">
                <AsyncSelect
                  isMulti={false}
                  styles={customStyles}
                  cacheOptions={false}
                  defaultOptions
                  onChange={(value) => handleAssigneeChange(value)}
                  loadOptions={debounceResponses}
                  placeholder="Choose assignee"
                />
              </FormGroup>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="info-column">
              <p className="info-column__title">Action Assigner*</p>
              <FormGroup className="select2-container">
                <Select
                  isMulti={false}
                  styles={customStyles}
                  options={userOptions}
                  defaultValue={defaultUser}
                  onChange={(value) => handleAssigneerChange(value)}
                  onInputChange={(value) => handleAssigneerSearch(value)}
                  placeholder="Choose assigner"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <Row className="form-group mt-3">
          <Col className="text-right">
            <Index
              type="button"
              color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4 mb-3"
              handler={handleCancel}
            >
              Cancel
            </Index>
            <Index
              type="submit"
              color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4 mb-3"
              disabled={!isValidForm}
            >
              Create Action
            </Index>
          </Col>
        </Row>
        <AvField
          name="organizationId"
          type="text"
          value={organizationId}
          className="btn-hidden"
        />
        <AvField
          name="assignerId"
          type="text"
          value={model.assignerId.value}
          className="btn-hidden"
        />
        <AvField
          name="assigneeId"
          type="text"
          value={model.assigneeId.value}
          className="btn-hidden"
        />
        <AvField
          name="dueDate"
          type="text"
          value={model.dueDate.value}
          className="btn-hidden"
        />
        <AvField name="link" type="hidden" value={model.link.value} />
        <AvField
          name="linkDescription"
          type="hidden"
          value={model.linkDescription.value}
        />
        {model.tagIds.value && (
          <AvField name="tagIds" type="hidden" value={model.tagIds.value} />
        )}
      </div>
    </AvForm>
  );
};

export default TaskForm;
