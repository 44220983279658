import { isEmptyValue } from '../is-empty-value';
import { isObject } from '../is-object';

export const removeIdQuestions = (questions) => {
  if (!questions && !questions.length) return;

  return questions.map((question) => {
    const { name, answers, number } = question;

    const newAnswers = answers.map((answer) => {
      const { name, number, isCorrect } = answer;
      return { name, number, isCorrect };
    });

    return { name, number, answers: newAnswers };
  });
};

export const getLatestId = (array) => {
  const size = array.length;
  return array[size - 1] ? array[size - 1].id + 1 : 0;
};

export const removeQuestion = (questions, currentId) => {
  return questions.filter(({ id }) => id !== currentId);
};

export const getCurrentIndex = (array, currentId) => {
  if (!Array.isArray(array) && !array.length) return -1;
  return array.findIndex(({ id }) => id === currentId);
};

export const removeAnswer = (questions, questionIndex, answerId) => {
  return questions.map((item, index) => {
    if (questionIndex === index) {
      const { id, name, answers } = item;

      const newAnswers = answers.filter(({ id }) => id !== answerId);
      return { id, name, answers: newAnswers };
    }
    return item;
  });
};

export const addAnswer = (questions, currentId) => {
  return questions.map((item) => {
    const { id, name, answers } = item;
    if (currentId !== id) return item;

    const answerId = answers[answers.length - 1]
      ? answers[answers.length - 1].id + 1
      : 0;
    return {
      id,
      name,
      answers: [...answers, { id: answerId, name: '', isCorrect: false }],
    };
  });
};

export const changeQuestionName = (questions, currentId, name) => {
  return questions.map((item) =>
    currentId === item.id ? { ...item, name } : item,
  );
};

const createNewAnswers = (answers, answerIndex, text) => {
  return answers.map((answer, index) => {
    return answerIndex === index ? { ...answer, name: text } : answer;
  });
};

export const changeAnswerName = (questions, questionIndex, answerId, text) => {
  return questions.map((question, index) => {
    if (questionIndex === index) {
      const { id, name, answers } = question;
      const answerIndex = answers.findIndex(({ id }) => id === answerId);
      const newAnswers = createNewAnswers(answers, answerIndex, text);

      return { id, name, answers: newAnswers };
    }

    return question;
  });
};

const changeAnswer = (answers, answerIndex) => {
  return answers.map((answer, index) => ({
    ...answer,
    isCorrect: answerIndex === index ? !answer.isCorrect : false,
  }));
};

export const toggleAnswerCorrect = (questions, questionIndex, answerId) => {
  return questions.map((item, index) => {
    if (questionIndex === index) {
      const { id, name, answers } = item;
      const answerIndex = answers.findIndex(({ id }) => id === answerId);
      const newAnswers = changeAnswer(answers, answerIndex);

      return { id, name, answers: newAnswers };
    }

    return item;
  });
};

export const isQuestionsCorrect = (questions) => {
  if (!questions.length) return true;
  return questions.every(({ name, answers }) => {
    return (
      name &&
      answers.every(({ name }) => name) &&
      answers.some(({ isCorrect }) => isCorrect)
    );
  });
};

export const getIsAssignees = (
  { assigneeUserIds, newAssigneeUserGroupIds },
  assigneesIds,
  groupIds,
) => {
  return (
    assigneeUserIds?.value.length ||
    newAssigneeUserGroupIds?.value.length ||
    assigneesIds.length ||
    groupIds.length
  );
};

export const getValidRequiredValue = (model) => {
  return Object.values(model).every(({ value, required }) => {
    if (required && isObject(value)) {
      return !!value.length;
    }
    return required ? isEmptyValue(value) : true;
  });
};
