export const EditTrainingModel = {
  number: {
    required: false,
    value: '',
  },
  dueDate: {
    required: true,
    value: '',
  },
  description: {
    required: true,
    value: '',
  },
  name: {
    required: true,
    value: '',
  },
  assigner: {
    required: true,
    value: '',
  },
  assigneeUsers: {
    required: false,
    value: [],
  },
  assigneeUserGroups: {
    required: false,
    value: [],
  },
  link: {
    required: false,
    value: '',
  },
  linkDescription: {
    required: false,
    value: '',
  },
  files: {
    required: false,
    value: [],
  },
  questions: {
    required: false,
    value: [],
  },
  extraLink: {
    value: null,
    required: false,
  },
  extraLinkDescription: {
    value: null,
    required: false,
  },
  tagIds: {
    required: false,
    value: [],
  },
  tags: {
    required: false,
    value: [],
  },
};
