import axios from 'axios';
import qs from 'qs';
import { get } from 'lodash';

import { STATUS_CODES } from '../constants/statusCode';
import {
  GET_MAX_ORGANIZATIONS,
  FORBIDDEN_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
} from '../constants/helper';
import { NETWORK_ERROR, ERROR_ACCESS_MESSAGE } from '../constants/messages';
import { clearSessionData } from './/clear-storage-helper';
import history from '../components/history/history';
import { sendRefreshToken } from './api/auth-api';
import { getCurrentTokens, updateTokens } from './get-current-token';
import { showToaster } from './toast-helper/toast-helper';

const API_BASE = process.env.REACT_APP_API;

export const API = axios.create({
  baseURL: API_BASE,
  responseType: 'json',
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
});

API.interceptors.request.use(
  (config) => {
    const { accessToken } = getCurrentTokens();
    if (accessToken) {
      config.headers['Authorization'] = 'bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const logoutAndRedirect = () => {
  showToaster(ERROR_ACCESS_MESSAGE);
  clearSessionData();
  history.push('/login');
};

API.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const { response, config } = error;
    const { refreshToken } = getCurrentTokens();
    const { UNAUTHORIZED } = STATUS_CODES;

    if (config.supressError) {
      throw error;
    }

    const statusCode = response && response.status;
    const isUnAuth =
      statusCode === UNAUTHORIZED || statusCode === 'Unauthorized';

    if (isUnAuth && refreshToken) {
      let isRefreshed = false;
      return sendRefreshToken({ refreshToken })
        .then(({ data }) => {
          const { accessToken } = data;
          error.config.headers['Authorization'] = `bearer ${accessToken}`;
          isRefreshed = true;

          updateTokens(data);
          return API(error.config).then((result) => result);
        })
        .catch((err) => {
          if (!isRefreshed) {
            logoutAndRedirect();
          }
          throw err;
        });
    } else if (isUnAuth && !refreshToken) {
      logoutAndRedirect();
      throw error;
    }

    if (response && response.status === FORBIDDEN_ERROR_CODE) {
      showToaster(ERROR_ACCESS_MESSAGE);
      throw error;
    }

    if (!response) {
      error.response = {
        status: NOT_FOUND_ERROR_CODE,
      };
      throw error;
    }

    const message = get(
      error,
      'response.data.errors[0].message',
      NETWORK_ERROR,
    );
    showToaster(message);
    throw error;
  },
);

export const postLogin = (url, data) => {
  return API.post(url, data);
};

export const forgetPassword = (url, data) => {
  return API.post(url, data);
};

export const resetPassword = (url, data) => {
  return API.put(url, data);
};

export const setPassword = (url, data) => {
  return API.put(url, data);
};

export const changePasswordAdminProfile = (url, data) => {
  return API.put(url, data);
};

export const changeEmailAdminProfile = (url, data) => {
  return API.post(url, data);
};

export const getDataOrganizations = (
  url,
  { limit, offset, search, orderBy, orderType },
) => {
  if (orderBy || orderType) {
    return API.get(url, {
      params: {
        limit,
        offset,
        search,
        'Order.Key': orderBy,
        'Order.Direction': orderType,
      },
    });
  } else {
    return API.get(url, { params: { limit, offset, search } });
  }
};

export const getOrganizations = (search, limit = 21) => {
  return API.get('/organizations?', { params: { search, limit } });
};

export const createNewOrganization = (url, name) => {
  return API.post(url, name);
};

export const toogleManageByClient = (url, id) => {
  return API.patch(url, id);
};

export const deleteNewOrganization = (url, id) => {
  return API.delete(url, id);
};

export const changeOrganizationStatus = (id) => {
  return API.patch(`/organizations/${id}/status`);
};

export const deleteOrganization = (id) => {
  return API.delete(`/organizations/${id}`);
};

export const setActiveStatus = (url, id) => {
  return API.patch(url, id);
};

export const editOneOrganization = (id, name) => {
  return API.put(`/organizations/${id}/name`, { name });
};

export const getAdminsData = (
  url,
  { id, limit, offset, search, orderBy, orderType },
) => {
  if (orderBy || orderType) {
    return API.get(url, {
      params: {
        limit,
        offset,
        search,
        'Order.Key': orderBy,
        'Order.Direction': orderType,
      },
    });
  } else {
    return API.get(url, { params: { limit, offset, search } });
  }
};

export const createNewAdmin = (
  url,
  { organizationId, firstName, lastName, email },
) => {
  return API.post(url, { organizationId, firstName, lastName, email });
};

export const uploadNewImage = (url, blob) => {
  return API.post(url, blob);
};

export const uploadImage = (blob) => {
  return API.post('/upload/image', blob);
};

export const uploadAvatar = (imageId) => {
  return API.patch(`/users/me/profile/avatar/${imageId}`, { imageId });
};

export const uploadLogoOrganization = (url, { id, imageId }) => {
  return API.put(url, { id, imageId });
};

export const getOrganization = (url, id) => {
  return API.get(url, { id });
};

export const checkValidToken = (url, { email, token }) => {
  return API.post(url, { token, email });
};

export const confirmUserEmail = (url, data) => {
  return API.put(url, data);
};

export const changeAdminStatus = (id, routeId) => {
  return API.patch(`/client-admins/${id}/status`, { ids: routeId });
};

export const getAdmins = (organizationId, organizationIds, search) => {
  return API.get(`/client-admins/all`, {
    params: {
      organizationId,
      organizationIds,
      search,
    },
  });
};

export const getClientUsers = (organizationId, organizationIds, search) => {
  return API.get(`client-users/all`, {
    params: {
      organizationId,
      organizationIds,
      search,
    },
  });
};

export const addClientUsers = (organizationId, userIds) => {
  return API.post('client-users/add', {
    organizationId,
    ids: userIds,
  });
};

export const addAdmins = (organizationId, adminIds) => {
  return API.post('/client-admins/add', {
    organizationId,
    ids: adminIds,
  });
};

export const getUsers = (
  organizationId,
  limit,
  offset,
  search,
  orderBy,
  orderType,
) => {
  const orderParams = {};

  if (orderBy) {
    orderParams['Order.Key'] = orderBy;
  }
  if (orderType) {
    orderParams['Order.Direction'] = orderType;
  }

  return API.get(`/client-users`, {
    params: {
      organizationId,
      limit,
      offset,
      search,
      ...orderParams,
    },
  });
};

export const getUserGroups = (
  organizationId,
  limit,
  offset,
  search,
  orderBy,
  orderType,
) => {
  const orderParams = {};

  if (orderBy) {
    orderParams['Order.Key'] = orderBy;
  }
  if (orderType) {
    orderParams['Order.Direction'] = orderType;
  }

  return API.get(`/user-groups`, {
    params: {
      organizationId,
      limit,
      offset,
      search,
      ...orderParams,
    },
  });
};

export const editAdmin = (id, body) => {
  return API.put(`users/${id}`, { ...body });
};

export const editUserGroup = (id, organizationId, name) => {
  return API.put(`/user-groups/${id}/name`, {
    organizationId,
    name,
  });
};

export const createUser = (firstName, lastName, email, organizationId) => {
  return API.post(`/client-users`, {
    organizationId,
    firstName,
    lastName,
    email,
  });
};

export const resendEmail = (email) => {
  return API.post(`/verifications/resend-invite`, {
    email,
  });
};

export const deleteAdmin = (id, ids) => {
  return API.delete(`/client-admins/${id}`, {
    params: {
      ids: [ids],
    },
  });
};

export const deleteUser = (id, ids) => {
  return API.delete(`/client-users/${id}`, {
    params: {
      organizationIds: [ids],
    },
  });
};

export const getClientOrganizations = (limit = GET_MAX_ORGANIZATIONS) => {
  return API.get('/organizations?', { params: { limit } });
};

export const changeUserStatus = (id, routeId) => {
  return API.patch(`/client-users/${id}/status`, { ids: routeId });
};

export const changeUserInfo = (id, firstName, lastName) => {
  return API.put(`/users/${id}`, { firstName, lastName });
};

export const changeUserInfoEmail = (id, email) => {
  return API.put(`/users/${id}`, { email });
};

export const createUserGroup = (name, organizationId) => {
  return API.post('/user-groups', { name, organizationId });
};

export const getUserGroupsList = (
  id,
  organizationId,
  limit,
  offset,
  search,
  orderBy,
  orderType,
) => {
  const orderParams = {};

  if (orderBy) {
    orderParams['Order.Key'] = orderBy;
  }
  if (orderType) {
    orderParams['Order.Direction'] = orderType;
  }

  return API.get(`user-groups/${id}/users`, {
    params: {
      organizationId,
      limit,
      offset,
      search,
      ...orderParams,
    },
  });
};

export const getUsersGroupAll = (id, organizationId, search) => {
  return API.get(`/user-groups/${id}/all`, {
    params: {
      organizationId,
      search,
    },
  });
};

export const addUserGroupUsers = (id, organizationId, users) => {
  return API.post(`/user-groups/${id}/add`, {
    organizationId,
    users,
  });
};

export const deleteUserGroupList = (
  id,
  userId,
  organizationId,
  trainingIds,
) => {
  return API.delete(`/user-groups/${id}/users/${userId}`, {
    params: { organizationId, trainingIds },
  });
};

export const deleteUserGroup = (id, organizationId, groupReceiverId) => {
  return API.delete(`/user-groups/${id}`, {
    params: { organizationId, groupReceiverId },
  });
};

export const resendInvite = (email) => {
  return API.post('/verifications/resend-confirm-email', { email });
};

export const getProfile = () => {
  return API.get('/users/me/profile');
};

export const getDocumentsList = (organizationId) => {
  return API.get(`/organizations/${organizationId}/document-types`);
};

export const createDocumentList = (name, organizationId) => {
  return API.post(`/organizations/${organizationId}/document-types`, { name });
};

export const deleteDocumentType = (id, doctypeId) => {
  return API.delete(`organizations/${id}/document-types/${doctypeId}`);
};

export const getGroupSetting = (id, organizationId) => {
  return API.get(`user-groups/${id}/settings`, {
    params: { organizationId },
  });
};

export const changeGroupSetting = (id, organizationId, type) => {
  return API.put(`user-groups/${id}/settings`, { organizationId, type });
};

export const getClientUser = (id, organizationId) => {
  return API.get(`client-users/${id}`, { params: { organizationId } });
};

export const getDocuments = (fieldParams, orderBy, orderType) => {
  const orderParams = {};

  if (orderBy) {
    orderParams['Order.Key'] = orderBy;
  }
  if (orderType) {
    orderParams['Order.Direction'] = orderType;
  }

  return API.get('/documents', {
    params: {
      ...fieldParams,
      ...orderParams,
    },
  });
};

export const downloadDocument = (id, organizationId, fileId) => {
  return API.get(`documents/${id}/file/${fileId}`, {
    params: { organizationId },
  });
};

export const getFileByUrl = (url) => {
  return axios.get(url, {
    responseType: 'blob',
    timeout: 30000,
  });
};

export const getDocument = (id, organizationId) => {
  return API.get(`documents/${id}/general`, { params: { organizationId } });
};

export const getDocumentTypes = (organizationId) => {
  return API.get(`/organizations/${organizationId}/document-types`);
};

export const editDocumentGeneral = (id, body) => {
  return API.put(`/documents/${id}/general`, body);
};

export const getQualityTask = (id, params) => {
  return API.get(`/documents/${id}/quality-tasks`, { params });
};

export const getReviews = (id, params) => {
  return API.get(`/documents/${id}/reviews`, { params });
};

export const getApprovals = (id, params) => {
  return API.get(`/documents/${id}/approvals`, { params });
};

export const getEmployees = (id, params) => {
  return API.get(`/documents/${id}/employee-job-description`, { params });
};

export const editEmployees = (id, body) => {
  return API.put(`/documents/${id}/employee-job-description`, body);
};

export const getDocumentUsers = (organizationId, search) => {
  return API.get(`/users`, {
    params: {
      organizationId,
      search,
    },
  });
};

export const editQuality = (id, body) => {
  return API.put(`/documents/${id}/quality-tasks`, body);
};

export const createDocument = (body) => {
  return API.post('/documents', body);
};

export const uploadFile = (file) => {
  return API.post(`/upload/file`, file);
};

export const deleteDocument = (organizationId, documentIds) => {
  return API.delete(`/documents`, {
    params: {
      organizationId,
      ids: documentIds,
    },
  });
};

export const updateReview = (id, body) => {
  return API.post(`/documents/${id}/reviews`, body);
};

export const updateApproval = (id, body) => {
  return API.post(`/documents/${id}/approvals`, body);
};

export const exportFile =
  (url) =>
  (body, supressError = false) => {
    return API.get(url, {
      params: { ...body },
      responseType: 'blob',
      supressError,
    });
  };

export const getTasks = (fieldParams, orderBy, orderType) => {
  const orderParams = {};

  if (orderBy) {
    orderParams['Order.Key'] = orderBy;
  }
  if (orderType) {
    orderParams['Order.Direction'] = orderType;
  }

  return API.get('/tasks', {
    params: {
      ...fieldParams,
      ...orderParams,
    },
  });
};

export const getTaskDetails = (id, organizationId) => {
  return API.get(`/tasks/${id}/details`, { params: { organizationId } });
};

export const createTask = (body) => {
  return API.post('/tasks', body);
};

export const editTaskDetails = (id, body) => {
  return API.put(`tasks/${id}/details`, body);
};

export const deleteTask = (body) => {
  return API.delete('/tasks', { params: { ...body } });
};

export const getDataTable = (url, params) => {
  return API.get(url, { params });
};

export const getDataTableColumns = (url, params, columns) => {
  return API.get(url, { params: { ...params, columns: columns } });
};

export const generateAccessCode = (id) => {
  return API.patch(`/verifications/user-access-code/${id}`);
};
