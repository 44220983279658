import React from 'react';

import Checkbox from '../../../pages/Organization/TrainingRecords/Details/shared/Checkbox';

export const BodyCheckbox = ({ id, isChecked, handleUpdateChecked }) => {
  return (
    <td className="fixed-column">
      <Checkbox
        id={id}
        isChecked={isChecked}
        onChange={() => handleUpdateChecked(id)}
        classes="document-checkbox table-checkbox"
      />
    </td>
  );
};

export default BodyCheckbox;
