import React, { useCallback, useEffect, useRef } from 'react';

const ResizingColumn = ({
  onResizeStart,
  onResizeEnd,
  onResizeColumns,
  columnIndex,
}) => {
  const resizingRef = useRef(null);
  const positionRef = useRef(null);

  const mouseMoveHandler = useCallback(
    (event) => {
      const positionX = positionRef.current;
      const dx = event.clientX - positionX;

      onResizeColumns(columnIndex, dx);
    },
    [columnIndex, onResizeColumns],
  );

  const mouseUpHandler = useCallback(() => {
    onResizeEnd();
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  }, [mouseMoveHandler, onResizeEnd]);

  const mouseDownHandler = useCallback(
    (event) => {
      positionRef.current = event.clientX;

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    },
    [mouseMoveHandler, mouseUpHandler],
  );

  const mouseStartResizingHandler = useCallback(() => {
    onResizeStart(true);
  }, [onResizeStart]);

  const mouseLeaveResizingHandler = useCallback(() => {
    onResizeStart(false);
  }, [onResizeStart]);

  useEffect(() => {
    const resizing = resizingRef.current;
    resizing.addEventListener('mousedown', mouseDownHandler);
    resizing.addEventListener('mouseover', mouseStartResizingHandler);
    resizing.addEventListener('mouseleave', mouseLeaveResizingHandler);

    return () => {
      resizing.removeEventListener('mousedown', mouseDownHandler);
      resizing.removeEventListener('mouseover', mouseStartResizingHandler);
      resizing.removeEventListener('mouseleave', mouseLeaveResizingHandler);
    };
  }, [mouseDownHandler, mouseLeaveResizingHandler, mouseStartResizingHandler]);

  return <div className="resizing" ref={resizingRef}></div>;
};

export default ResizingColumn;
