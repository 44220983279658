import React from 'react';
import { CardTitle, Col, Row } from 'reactstrap';
import TableAddExist from '../TableAddExist';

import TableAddNew from '../TableAddNew';
import TableSearch from '../TableSearch';
import TableSettings from '../Settings';

const TableHeader = ({
  title,
  isCanAdd,
  handleAddNew,
  downloadCSV,
  downloadOneDocument,
  downloadManyDocuments,
  downloadDocumentAttachments,
  manageColumns,
  handleSearch,
  FilterComponent,
  handleAddExisting,
}) => {
  return (
    <div>
      <Row>
        <Col md="6" className="d-flex align-items-center">
          <CardTitle className="mb-0">{title}</CardTitle>
        </Col>
        <Col
          md="6"
          className="d-flex justify-content-start justify-content-md-end flex-wrap"
        >
          {handleAddExisting && <TableAddExist onClick={handleAddExisting} />}
          {handleAddNew && (
            <TableAddNew onClick={handleAddNew} isCanAdd={isCanAdd} />
          )}
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col sm="8" className="d-flex align-items-center flex-wrap mb-2">
          <TableSettings
            downloadCSV={downloadCSV}
            downloadOneDocument={downloadOneDocument}
            downloadManyDocuments={downloadManyDocuments}
            downloadDocumentAttachments={downloadDocumentAttachments}
            manageColumns={manageColumns}
            FilterComponent={FilterComponent}
          />
        </Col>
        <Col sm="4" className="d-flex justify-content-end">
          <TableSearch handleSearch={handleSearch} />
        </Col>
      </Row>
    </div>
  );
};

export default TableHeader;
