import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Col, FormGroup, Row } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { debounce, union } from 'lodash';

import { configureGetUsers } from '../../../../utils/config-get-organization';
import { getDocumentUsers } from '../../../../utils/backend-helper';
import {
  APPROVERS,
  DEBOUNCE_TIMER,
  REVIEWERS,
} from '../../../../constants/helper';
import { changeFieldSelect } from '../../../../utils/selector-helper-changed';
import { qualityStyles } from '../../../../utils/select-styles';
import UsersList from './UsersList';
import Index from '../../RequiresTraining/Edit';

const RevisionAddQuality = ({ usersReviewer, usersApprover, updateModel }) => {
  const { id: organizationId } = useRouteMatch().params;

  const [isChecked, updateIsChecked] = useState(false);

  const [reviewerIds, updateReviewerIds] = useState([]);
  const [approverIds, updateApproverIds] = useState([]);

  const [qualityData, updateQualityData] = useState({
    reviewers: [],
    approvers: [],
  });

  const { reviewers, approvers } = qualityData;

  useEffect(() => {
    updateQualityData({
      reviewers: usersReviewer,
      approvers: usersApprover,
    });
  }, [usersReviewer, usersApprover]);

  const debounceReviewers = debounce((inputValue, callback) => {
    getDocumentUsers(organizationId, inputValue).then(({ data }) =>
      callback(configureGetUsers(data)),
    );
  }, DEBOUNCE_TIMER);

  const debounceApprovers = debounce((inputValue, callback) => {
    getDocumentUsers(organizationId, inputValue).then(({ data }) =>
      callback(configureGetUsers(data)),
    );
  }, DEBOUNCE_TIMER);

  const handleReviewersChange = (user) => {
    updateReviewerIds(changeFieldSelect(user));
  };

  const handleApproversChange = (user) => {
    updateApproverIds(changeFieldSelect(user));
  };

  const handleDelete = (removedId, key) => {
    updateQualityData((prev) => ({
      ...prev,
      [key]: prev[key].filter(({ id }) => id !== removedId),
    }));
  };

  useEffect(() => {
    const [reviewers, approvers] = [REVIEWERS, APPROVERS].map((key) =>
      qualityData[key].map((item) => item.id),
    );

    updateModel((prev) => {
      return {
        ...prev,
        reviewerIds: union(reviewers, reviewerIds),
        approverIds: union(approvers, approverIds),
        requiresTraining: `${isChecked}`,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewerIds, approverIds, reviewers, approvers, isChecked]);

  return (
    <div className="general">
      <div className="general-header">
        <h6 className="mt-2">Quality Actions</h6>
      </div>
      <hr />
      <div className="quality-assign">
        <Row>
          <Col>
            <div className="info-column">
              <p className="info-column__title">Assign Reviewer(s)</p>
              <FormGroup className="select2-container">
                <AsyncSelect
                  styles={qualityStyles}
                  isMulti={true}
                  cacheOptions={false}
                  defaultOptions
                  onChange={(value) => handleReviewersChange(value)}
                  loadOptions={debounceReviewers}
                  placeholder="Assign new reviewer(s)"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <hr />
        {!!reviewers.length && (
          <>
            <UsersList
              title="Assigned Reviewer(s)"
              usersData={qualityData.reviewers}
              handleDelete={handleDelete}
              userKey="reviewers"
            />
            <hr />
          </>
        )}
        <Row>
          <Col>
            <div className="info-column">
              <p className="info-column__title">Assign Approver(s)</p>
              <FormGroup className="select2-container">
                <AsyncSelect
                  styles={qualityStyles}
                  isMulti={true}
                  cacheOptions={false}
                  defaultOptions
                  onChange={(value) => handleApproversChange(value)}
                  loadOptions={debounceApprovers}
                  placeholder="Assign new approver(s)"
                />
              </FormGroup>
            </div>
          </Col>
        </Row>
        <hr />
        {!!approvers.length && (
          <>
            <UsersList
              title="Assigned Approver(s)"
              usersData={qualityData.approvers}
              handleDelete={handleDelete}
              userKey="approvers"
            />
            <hr />
          </>
        )}
        <Index
          isChecked={isChecked}
          handleChecked={() => updateIsChecked((isChecked) => !isChecked)}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default RevisionAddQuality;
