import React, { useMemo } from 'react';
import { Card, CardBody, TabContent, TabPane } from 'reactstrap';
import { SUPERADMIN_ROLE } from '../../../constants/helper';
import { getDataUser } from '../../../utils/get-store-user';
import {
  navigationForUser,
  navigationList,
} from '../Navigation/navigationList';

const ProfilePages = ({ activeTab, handleUpdateUser }) => {
  const { role } = getDataUser();

  const navList = useMemo(() => {
    return role === SUPERADMIN_ROLE
      ? navigationList
      : [navigationForUser, ...navigationList];
  }, [role]);

  return (
    <Card>
      <CardBody>
        <TabContent activeTab={activeTab} className="p-0">
          {navList.map(({ tabId, component: Component }) => {
            return (
              <TabPane key={tabId} tabId={tabId}>
                <Component handleUpdateUser={handleUpdateUser} />
              </TabPane>
            );
          })}
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default ProfilePages;
