import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import SidebarContent from '../SidebarContent';
import { leftSideBarCondensed } from '../../../../constants/sidebarTypes';
import { getDataUser } from '../../../../utils/get-store-user';
import { User } from '../../../../constants/role';

const Sidebar = ({ type }) => {
  const { id: organizationId } = useRouteMatch().params;
  const history = useHistory();
  const { leftSideBarType } = useSelector(({ Layout }) => Layout);

  const user = useMemo(() => getDataUser(), []);

  const handleRedirect = (organizationId, userId) => {
    history.push(`/organizations/${organizationId}/users/${userId}`);
  };

  return (
    <div className="vertical-menu">
      <div data-simplebar className="h-100">
        {type !== leftSideBarCondensed ? (
          <SimpleBar className="sidebar">
            <SidebarContent id={user.id} role={user.role} />
          </SimpleBar>
        ) : (
          <SidebarContent id={user.id} role={user.role} />
        )}
        <div className="navigation-bottom">
          {user.role === User && (
            <div className="navigation-bottom__block">
              <span onClick={() => handleRedirect(organizationId, user.id)}>
                <i className="bx bxs-lock-open"></i>
                {leftSideBarType === 'default' && 'Access Permissions'}
              </span>
            </div>
          )}
          <div className="navigation-bottom__block">
            <span
              onClick={() =>
                history.push(`/organizations/${organizationId}/help`)
              }
            >
              <i className="bx bxs-help-circle"></i>
              {leftSideBarType === 'default' && 'Help'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
