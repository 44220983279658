import { API } from '../backend-helper';

const USERS = 'users';
const ME = 'me';
const PROFILE = 'profile';
const AVATAR = 'avatar';

export const deleteAvatar = () => {
  return API.delete(`/${USERS}/${ME}/${PROFILE}/${AVATAR}`);
};
