import React from 'react';

const HeaderLogo = ({ organizationImage }) => {
  return (
    <span className="logo-sm">
      {organizationImage && (
        <img src={organizationImage.originalPath} alt="logo" width="30" />
      )}
    </span>
  );
};

export default HeaderLogo;
