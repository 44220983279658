import React from 'react';
import TableExportDropdown from '../TableExportDropdown';

const TableSettings = ({
  downloadCSV,
  downloadOneDocument,
  downloadManyDocuments,
  downloadDocumentAttachments,
  manageColumns,
  FilterComponent,
}) => {
  return (
    <>
      <TableExportDropdown
        downloadCSV={downloadCSV}
        downloadOneDocument={downloadOneDocument}
        downloadManyDocuments={downloadManyDocuments}
        downloadDocumentAttachments={downloadDocumentAttachments}
      />
      <div
        className="file-container"
        id="Tooltip-columns"
        onClick={manageColumns}
      >
        <i className="bx bxs-cog setting"></i>
        <span className="setting-text">Manage columns</span>
      </div>
      {FilterComponent}
    </>
  );
};

export default TableSettings;
