/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Row, Col, Modal, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';

import { DEBOUNCE_TIMER } from '../../constants/helper';
import { getUserGroups } from '../../utils/backend-helper';
import { configureGetOrganization } from '../../utils/config-get-organization';
import { SEARCH_LIMIT, INITIAL_OFFSET } from '../../constants/table';
import Button from '../../components/Button';

const customStyles = {
  option: (styles) => {
    return {
      ...styles,
      color: '#000000',
      fontFamily: 'Poppins',
      fontWeight: '500',
    };
  },
};

const UserGroupDeleteModal = ({
  onSubmit,
  onCancel,
  isOpen,
  values,
  organizationId,
  isMulti,
}) => {
  const [groupReceiverId, setGroupReceiverId] = useState(null);

  const debouncedOrganization = debounce((inputValue, callback) => {
    getUserGroups(
      values.organizationId,
      SEARCH_LIMIT,
      INITIAL_OFFSET,
      inputValue,
    ).then(({ data }) => callback(configureGetOrganization(data, values.id)));
  }, DEBOUNCE_TIMER);

  const handleUserGroupsChange = (userGroup) => {
    setGroupReceiverId(userGroup.id);
  };

  const [checked, setChecked] = useState(false);

  const handleChangeChecked = () => {
    setChecked((checked) => !checked);
  };

  const clearCheckBox = () => setChecked(false);

  const handleSubmitModal = (event, values) => {
    onSubmit(event, values, clearCheckBox);
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} centered={true}>
      <div className="modal-header align-items-center">
        <h5 className="modal-title mt-0">Delete User Group</h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="bx bx-x close-icon-modal"></i>
        </button>
      </div>
      <AvForm
        className="form-horizontal mt-2"
        onValidSubmit={handleSubmitModal}
      >
        {isMulti ? (
          <>
            <div className="modal-body">
              <p className="text-modal">
                Are you sure you want to delete the User Group{' '}
                <b>{values.name}</b>? This group has {values.userCounter}{' '}
                user(s). Select a new group for the user(s) in{' '}
                <b>{values.name}</b>. The user(s) will get access permissions
                from the User Group you select.
              </p>
              <p className="text-modal">
                If a new User Group is not selected, the user(s) will have no
                access to the system unless they are assigned to another User
                Group.
              </p>
              <FormGroup className="select2-container">
                <label className="control-label">Select Group</label>
                <AsyncSelect
                  styles={customStyles}
                  cacheOptions={false}
                  defaultOptions
                  onChange={(value) => handleUserGroupsChange(value)}
                  loadOptions={debouncedOrganization}
                  placeholder="Select or search group"
                />
              </FormGroup>
              <div className="custom-control custom-checkbox">
                <input
                  checked={checked}
                  type="checkbox"
                  onChange={handleChangeChecked}
                  className="custom-control-input"
                  id="customControlInline"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customControlInline"
                >
                  I acknowledge
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <Row className="form-group">
                <Col className="text-right">
                  <Button
                    type="submit"
                    color="btn btn-danger w-md waves-effect waves-light delete-btn mr-3"
                    disabled={!checked}
                  >
                    Delete
                  </Button>
                  <Button
                    type="button"
                    color="btn btn-light w-md waves-effect waves-light mr-3"
                    handler={onCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <>
            <div className="modal-body">
              <p className="text-modal">
                Are you sure you want to delete a User Group{' '}
                <b>{values.name}</b>? This group has {values.userCounter} users.
              </p>
              <p className="text-modal">
                The users will remain with no group and without access
                permissions. With this, they won’t be able to perform any
                actions in the current Organization.
              </p>
              <div className="custom-control custom-checkbox">
                <input
                  checked={checked}
                  type="checkbox"
                  onChange={handleChangeChecked}
                  className="custom-control-input"
                  id="customControlInline"
                />
                <label
                  className="custom-control-label"
                  htmlFor="customControlInline"
                >
                  I acknowledge
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <Row className="form-group">
                <Col className="text-right">
                  <Button
                    type="submit"
                    color="btn btn-danger w-md waves-effect waves-light delete-btn mr-3"
                    disabled={!checked}
                  >
                    Delete
                  </Button>
                  <Button
                    type="button"
                    color="btn btn-light w-md waves-effect waves-light mr-3"
                    handler={onCancel}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        )}
        <AvField
          name="id"
          type="text"
          value={values.id}
          className="btn-hidden"
        />
        <AvField
          name="organizationId"
          type="text"
          value={values.organizationId}
          className="btn-hidden"
        />
        <AvField
          name="groupReceiverId"
          type="text"
          value={groupReceiverId}
          className="btn-hidden"
        />
      </AvForm>
    </Modal>
  );
};

export default UserGroupDeleteModal;
