import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { toast } from 'react-toastify';

import UserListDeleteModal from '../../../../modals/UserListDelete';
import MessageResetSuccess from '../../../../components/ToastSuccess';
import CheckboxHead from '../../../../components/tables/HeadCheckbox';
import BodyCheckbox from '../../../../components/tables/BodyCheckbox';

import { SUCCESS_MESSAGE_DELETE_USER } from '../../../../constants/messages';
import { deleteUserGroupList } from '../../../../utils/backend-helper';
import IconTooltip from '../../../../components/tooltips/IconTooltip';

export const columnsHead = [
  {
    name: 'checkbox',
    accessor: '',
    sort: false,
    Component: ({
      provided,
      snapshot,
      checkAllDocuments,
      checkedHead,
      pagination: { data },
    }) => {
      return (
        <CheckboxHead
          data={data}
          checkedHead={checkedHead}
          checkAllDocuments={checkAllDocuments}
          provided={provided}
          snapshot={snapshot}
        />
      );
    },
  },
  {
    name: 'firstName',
    accessor: 'First Name',
    sort: true,
    classes: 'column-title',
    widthPercent: 19.8,
  },
  {
    name: 'lastName',
    accessor: 'Last Name',
    sort: true,
    classes: 'column-title',
    widthPercent: 20,
  },
  {
    name: 'email',
    accessor: 'Email',
    sort: true,
    classes: 'column-title',
    widthPercent: 20,
  },
  {
    name: 'status',
    accessor: 'Status',
    sort: true,
    classes: 'column-title ',
    widthPercent: 15,
  },
  {
    name: 'isactivated',
    accessor: 'Activated',
    sort: true,
    classes: 'column-title',
    widthPercent: 15,
  },
  {
    name: 'activeted',
    accessor: 'Options',
    sort: false,
    classes: 'text-center',
    widthPercent: 10,
  },
];

export const columnsBody = [
  {
    name: 'checkbox',
    accessor: 'Selected',
    Component: ({ order: { id, isChecked = false }, handleUpdateChecked }) => {
      return (
        <BodyCheckbox
          id={id}
          isChecked={isChecked}
          handleUpdateChecked={handleUpdateChecked}
        />
      );
    },
  },
  {
    name: 'firstName',
    accessor: 'First Name',
    classes: '',
  },
  {
    name: 'lastName',
    accessor: 'Last Name',
    classes: '',
  },
  {
    name: 'email',
    accessor: 'Email',
    classes: '',
  },
  {
    name: 'status',
    accessor: 'Status',
    Component: ({ order, columnsHidden }) => (
      <td className={`${columnsHidden.status ? '' : 'column-hidden'}`}>
        <p className="td-before">Status</p>
        <div>
          <Badge
            className={`font-size-12 badge-soft-${
              order.status === 'Active' ? 'success' : 'danger'
            }`}
            pill
          >
            {order.status}
          </Badge>
        </div>
      </td>
    ),
  },
  {
    name: 'isactivated',
    accessor: 'Activated',
    classes: '',
    Component: ({ order, columnsHidden }) => (
      <td className={columnsHidden.isactivated ? '' : 'column-hidden'}>
        <p className="td-before">Activated</p>
        <p>{order.isActivated ? 'Yes' : 'No'}</p>
      </td>
    ),
  },
  {
    name: 'activeted',
    accessor: 'Actions',
    classes: '',
    Component: ({
      order,
      columnsHidden,
      organizationId,
      updatingTable,
      onDeleteRow,
    }) => {
      const history = useHistory();

      const { groupId } = useRouteMatch().params;

      const [deleteModal, setDeleteModal] = useState({
        isOpen: false,
        model: {
          id: null,
          userId: null,
          organizationId: null,
          firstName: '',
          lastName: '',
          email: '',
          groupCount: 0,
        },
      });

      const handleDeleteAdmin = (
        id,
        userId,
        organizationId,
        firstName,
        lastName,
        email,
        groupCount,
        trainings,
      ) => {
        setDeleteModal((prev) => ({
          isOpen: !prev.isOpen,
          model: {
            id,
            userId,
            organizationId,
            firstName,
            lastName,
            email,
            groupCount,
            trainings,
          },
        }));
      };

      const handleRedirect = (organizationId, userId) => {
        history.push(`/organizations/${organizationId}/users/${userId}`);
      };

      return (
        <>
          <td
            className={`activate-column ${
              columnsHidden.activeted ? '' : 'column-hidden'
            }`}
          >
            <p className="td-before">Actions</p>
            <div className="d-flex justify-content-start justify-content-md-center overflow-auto">
              <div className="d-flex justify-content-start justify-content-md-center">
                <div className="actions">
                  <IconTooltip
                    icon="bx bx-show action"
                    id={`UserViewDetails${order.id}`}
                    text="View details"
                    onClick={() => handleRedirect(organizationId, order.id)}
                  />
                  <IconTooltip
                    icon="bx bxs-trash-alt action"
                    id={`HRDeleteDetails${order.id}`}
                    text="Delete"
                    onClick={() =>
                      handleDeleteAdmin(
                        groupId,
                        order.id,
                        organizationId,
                        order.firstName,
                        order.lastName,
                        order.email,
                        order.groupCount,
                        order.trainings,
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </td>
          <UserListDeleteModal
            isOpen={deleteModal.isOpen}
            values={deleteModal.model}
            onCancel={() =>
              setDeleteModal((prev) => ({ ...prev, isOpen: false }))
            }
            onSubmit={(
              _,
              { id, userId, organizationId, trainingIds },
              callback,
            ) => {
              deleteUserGroupList(id, userId, organizationId, trainingIds).then(
                () => {
                  onDeleteRow(userId);
                  toast.success(
                    <MessageResetSuccess
                      message={SUCCESS_MESSAGE_DELETE_USER}
                    />,
                  );
                  callback();
                  setDeleteModal((prev) => ({ ...prev, isOpen: false }));
                },
              );
            }}
          />
        </>
      );
    },
  },
];
