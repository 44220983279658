import React, { useState, useEffect, useMemo } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import Index from '../../../../components/Button';
import MessageResetSuccess from '../../../../components/ToastSuccess';
import FormGroupWrapper from '../../TrainingRecords/Details/shared/FormGroupWrapper';
import TrainingQuestions from '../../TrainingRecords/Details/Questions';
import TrainingLinkToRecord from '../../../../components/TrainingRecord/TrainingLinkToRecord';
import TagField from '../../../../components/shared-forms/TagField';
import TaskExternalLink from '../../../../components/task/ExternalLink';

import {
  MAX_INPUT_LENGTH,
  MAX_TEXTAREA_LENGTH,
} from '../../../../constants/helper';
import { customStyles } from '../../../../utils/select-styles';
import { CreateTrainingRecordsModel } from '../../../../models/training-records/training-records-create-model';
import { useUserAsyncSelect } from '../../../../custom-hooks/useUserAsyncSelect';
import { useUserSelect } from '../../../../custom-hooks/useUserSelect';
import { createTrainingRecords } from '../../../../utils/api/training-records-api';
import {
  SUCCESS_TRAINING_CREATE_NEW,
  SUCCESS_TRAINING_LINKED,
} from '../../../../constants/messages';
import { getFilesIds } from '../../../../utils/training-records-helper/get-files-ids';
import {
  getIsAssignees,
  getValidRequiredValue,
  isQuestionsCorrect,
  removeIdQuestions,
} from '../../../../utils/training-records-helper/questions-helpers';
import { filterByField } from '../../../../utils/training-records-helper/filter-by-id';
import { getLinkToRecord } from '../../../../utils/tasks-helper/get-task-link';
import { getUnionArrayIds } from '../../../../utils/shared/get-union-array';
import DocumentAttachedFiles from '../../../../components/document/AttachedFiles';
import { changeFileTrainingRecords } from '../../../../utils/file-attached/change-file-training-records';
import { validateEmptyText } from '../../../../constants/validatePassword';
import { REQUIRED_FIELD } from '../../../../constants/form';
import { useErrorHandler } from '../../../../custom-hooks/useErrorHandler';

const TrainingRecordsForm = ({ handleCancel, user }) => {
  const organizationId = useRouteMatch().params.id;
  const history = useHistory();
  const onError = useErrorHandler();

  const { state } = history.location;

  const [documentInfo, updateDocumentInfo] = useState({
    isEdit: false,
  });

  const [document, updateDocument] = useState({
    id: null,
    revision: null,
  });

  useEffect(() => {
    if (state && state.documentInfo) {
      updateDocumentInfo(state.documentInfo);
    }
    if (state && state.document) {
      updateDocument(state.document);
    }
  }, [state]);

  const [model, updateModel] = useState(CreateTrainingRecordsModel);

  const handleModelChange = (event) => {
    const { name, value } = event.target;
    updateModel((prev) => ({
      ...prev,
      [name]: { ...prev[name], value },
    }));
  };

  const updateAssignerId = (prev, id) => ({
    ...prev,
    assignerId: { ...prev.assignerId, value: id },
  });

  useEffect(() => {
    updateModel((prev) => updateAssignerId(prev, user.id));
  }, [user]);

  const handleSubmit = (_, values, documentInfo, document) => {
    const { isEdit } = documentInfo;
    const { id } = document;
    createTrainingRecords(values)
      .then(() => {
        toast.success(
          <MessageResetSuccess
            message={
              isEdit ? SUCCESS_TRAINING_LINKED : SUCCESS_TRAINING_CREATE_NEW
            }
          />,
        );
        history.push(
          isEdit
            ? `/organizations/${organizationId}/documents/${id}`
            : `/organizations/${organizationId}/training-records`,
        );
      })
      .catch(onError);
  };

  const {
    userIds: assigneesIds,
    groupIds,
    debounceUser: debounceAssignees,
    handleUserChange: handleAssigneesChange,
  } = useUserAsyncSelect(organizationId);

  useEffect(() => {
    updateModel((prev) => ({
      ...prev,
      assigneeUserIds: { ...prev.assigneeUserIds, value: assigneesIds },
    }));
  }, [assigneesIds]);

  useEffect(() => {
    updateModel((prev) => ({
      ...prev,
      newAssigneeUserGroupIds: {
        ...prev.newAssigneeUserGroupIds,
        value: groupIds,
      },
    }));
  }, [groupIds]);

  const { usersOption, defaultUser, handleUsersSearch, updateDefaultUser } =
    useUserSelect(organizationId, user);

  const handleAssignerChange = ({ label, id: assignerId }) => {
    updateDefaultUser({ label });
    updateModel((prev) => updateAssignerId(prev, assignerId));
  };

  const isDisabled = useMemo(() => {
    const checkIsQuestionsCorrect = isQuestionsCorrect(model.questions.value);
    const isAssignees = getIsAssignees(model, assigneesIds, groupIds);

    return !(
      isAssignees &&
      checkIsQuestionsCorrect &&
      getValidRequiredValue(model)
    );
  }, [assigneesIds, groupIds, model]);

  return (
    <AvForm
      onValidSubmit={(_, values) =>
        handleSubmit(_, values, documentInfo, document)
      }
    >
      <div className="general">
        <div className="general-header">
          <h6 className="mt-2">Create training record</h6>
        </div>
        <hr />
        <Row>
          <Col md="8">
            <FormGroupWrapper name="Training name*">
              <AvField
                type="text"
                name="name"
                className="form-control"
                placeholder="Enter training name"
                maxLength={MAX_INPUT_LENGTH}
                onChange={(event) => handleModelChange(event)}
                value={model.name.value}
                validate={validateEmptyText}
                errorMessage={REQUIRED_FIELD}
              />
            </FormGroupWrapper>
          </Col>
          <Col md="4">
            <FormGroupWrapper name="Due Date*">
              <input
                className="form-control"
                type="date"
                name="dueDate"
                value={model.dueDate.value}
                onChange={(event) => handleModelChange(event)}
              />
            </FormGroupWrapper>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroupWrapper name="Description*">
              <AvField
                type="textarea"
                id="textarea"
                name="description"
                placeholder="Enter description here"
                value={model.description.value}
                onChange={(event) => handleModelChange(event)}
                maxLength={`${MAX_TEXTAREA_LENGTH}`}
                validate={validateEmptyText}
                errorMessage={REQUIRED_FIELD}
                rows="3"
              />
            </FormGroupWrapper>
          </Col>
        </Row>
        <TagField
          onChange={(values) => {
            updateModel((prev) => ({
              ...prev,
              tagIds: {
                ...prev.tagIds,
                value: getUnionArrayIds(values),
              },
            }));
          }}
        />
        <hr />
        <Row>
          <Col xs="12">
            <FormGroupWrapper name="Assignee(s)*">
              <AsyncSelect
                styles={customStyles}
                isMulti={true}
                cacheOptions={false}
                defaultOptions
                onChange={(value) => handleAssigneesChange(value)}
                loadOptions={debounceAssignees}
                placeholder="Choose assignee(s)"
              />
            </FormGroupWrapper>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs="12">
            <FormGroupWrapper name="Assigner*">
              <Select
                isMulti={false}
                styles={customStyles}
                options={usersOption}
                value={defaultUser}
                onChange={(value) => handleAssignerChange(value)}
                onInputChange={(value) => handleUsersSearch(value)}
                placeholder="Choose assigner"
              />
            </FormGroupWrapper>
          </Col>
        </Row>
        <hr />
        <TrainingLinkToRecord
          updateLinkToRecord={(value, link) => {
            updateModel((prev) => getLinkToRecord(prev, value, link));
          }}
        />
        <TaskExternalLink
          extraLinkDescription={model.extraLinkDescription.value}
          extraLink={model.extraLink.value}
          handleModelChange={handleModelChange}
        />
        <hr />
        <DocumentAttachedFiles
          title="File(s) attached"
          files={model.files.value}
          onDeleteFile={(id) => {
            updateModel((model) =>
              changeFileTrainingRecords(
                model,
                filterByField(model.files.value, id),
              ),
            );
          }}
          updateModel={(body) => {
            updateModel((model) =>
              changeFileTrainingRecords(model, [...model.files.value, body]),
            );
          }}
        />
        <TrainingQuestions
          questions={model.questions.value}
          updateModel={updateModel}
        />
        <Row className="form-group mt-3">
          <Col className="text-right">
            <Index
              type="button"
              color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4 mb-3"
              handler={handleCancel}
            >
              Cancel
            </Index>
            <Index
              type="submit"
              color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4 mb-3"
              disabled={isDisabled}
            >
              Save
            </Index>
          </Col>
        </Row>
        <AvField
          name="organizationId"
          type="text"
          value={organizationId}
          className="btn-hidden"
        />
        <AvField
          name="assignerId"
          type="text"
          value={model.assignerId.value || defaultUser.value}
          className="btn-hidden"
        />
        <AvField
          name="fileIds"
          type="text"
          value={getFilesIds(model.files.value)}
          className="btn-hidden"
        />
        <AvField
          name="assigneeUserIds"
          type="text"
          value={model.assigneeUserIds.value}
          className="btn-hidden"
        />
        <AvField
          name="newAssigneeUserGroupIds"
          type="text"
          value={model.newAssigneeUserGroupIds.value}
          className="btn-hidden"
        />
        <AvField
          name="dueDate"
          type="text"
          value={model.dueDate.value}
          className="btn-hidden"
        />
        <AvField
          name="questions"
          type="text"
          value={removeIdQuestions(model.questions.value)}
          className="btn-hidden"
        />
        {document.id && (
          <AvField
            name="documentModel"
            type="text"
            value={document}
            className="btn-hidden"
          />
        )}
        <AvField name="link" type="hidden" value={model.link.value} />
        <AvField
          name="linkDescription"
          type="hidden"
          value={model.linkDescription.value}
        />
      </div>
      {model.tagIds.value && (
        <AvField name="tagIds" type="hidden" value={model.tagIds.value} />
      )}
    </AvForm>
  );
};

export default TrainingRecordsForm;
