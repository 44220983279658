export const trainingRecordDetailsModel = {
  number: '',
  name: '',
  status: '',
  dueDate: '',
  description: '',
  link: '',
  linkDescription: '',
  extraLink: '',
  extraLinkDescription: '',
  assignee: {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  },
  assigner: {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  },
  creator: {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  },
  files: [],
  assigneeUsers: [],
  questions: [],
  creationDate: '',
  creationTime: '',
  assigneeUserGroups: [],
  tags: [],
  canBeEdited: false,
  canBePassed: false,
  canBeDeleted: false,
  trainingStatus: null,
};
