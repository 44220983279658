export const DEBOUNCE_TIMER = 1000;

export const SUPERADMIN_ROLE = 'SuperAdmin';
export const ADMIN_ROLE = 'Admin';
export const USER_ROLE = 'User';

export const DEFAULT_PAGINATION_LIMIT = 25;

export const GET_MAX_ORGANIZATIONS = 100;

export const GET_MAX_ORGANIZATION_SELECT = 21;

export const MAX_INPUT_LENGTH = 100;
export const MAX_TEXTAREA_LENGTH = 299;

export const TOAST_AUTO_CLOSE = 10000;

export const MAX_LENGTH_TO_VIEW = 30;
export const MAX_LENGTH_TO_VIEW_PROFILE = 100;
export const MAX_LENGTH_TO_VIEW_NAME = 40;

export const AUTH_ERROR_CODE = 401;
export const FORBIDDEN_ERROR_CODE = 403;
export const NOT_FOUND_ERROR_CODE = 404;
export const LOCKED_DOCUMENT_ERROR_CODE = 422;
export const LOCKED_ERROR_CODE = 423;
export const NOT_FOUND_CODE = 404;

export const VIEW_DOCUMENTS_ID = 0;
export const VIEW_APPROVED_ID = 11;
export const VIEW_TASK_ID = 16;
export const VIEW_ASSIGNEE_TASK_ID = 22;
export const VIEW_TRAINING_ID = 26;
export const VIEW_ASSIGNEE_TRAINING_ID = 30;
export const START_VIEW_ID = 0;
export const END_VIEW_ID = 16;
export const START_TASK_ID = 22;
export const END_TASK_ID = 26;
export const START_TRAINING_ID = 26;
export const END_TRAINING_ID = 34;
export const START_ALL = 34;
export const END_ALL = 39;
export const START_RELATED = 47;
export const END_RELATED = 53;
export const MANAGE_DOCUMENT_ID = 56;

export const MAX_LENGTH_TEXT = 10;
export const LIMIT_SEARCH_SELECT = 20;

export const DOCUMENT_STATUS = [
  'Submitted',
  'Reviewed',
  'Approved',
  'Rejected',
];
export const DOCUMENT_RESPONSE_STATUS = [
  'Not Started',
  'In Progress',
  'Complete',
];
export const REVIEW_STATUS = ['Reviewed', 'Rejected'];
export const APPROVAL_STATUS = ['Approved', 'Rejected'];
export const TASK_STATUS = ['To do', 'Complete'];

export const FIRST_SETTING_TAB = '1';
export const SECOND_SETTING_TAB = '2';
export const THIRD_SETTING_TAB = '3';
export const FOURTH_SETTING_TAB = '4';

export const GENERAL_DOCUMENT_TAB = '1';
export const QUALITY_DOCUMENT_TAB = '2';
export const REFERENCES_DOCUMENT_TAB = '3';
export const REVIEW_DOCUMENT_TAB = '4';
export const APPROVAL_DOCUMENT_TAB = '5';

export const TASK_DETAILS_TAB = '1';

export const TRAINING_RECORD_TAB = '1';
export const TRAINING_PROGGRESS_TAB = '2';

export const HUMAN_RESOURCES_TAB = '1';

export const USERS_LINK = 'users';
export const DOCUMENTS_LINK = 'documents';
export const TASKS_LINK = 'Tasks';

export const APPROVERS = 'approvers';
export const REVIEWERS = 'reviewers';
export const MIN_REVISION = '1';
export const MAX_REVISION = '999999999';
export const MAX_ACCESS_CODE = '999999';

export const CSV = 'csv';
export const PDF = 'pdf';
export const ZIP = 'zip';

export const DEFAULT_SORT = 'firstName';
export const DEFAULT_DOCUMENT_SORT = 'number';
export const DEFAULT_NAME_SORT = 'name';

export const ASSIGNEE_USER_KEY = 'assigneeUsers';
export const TRAINING_RECORD_KEY = 'trainingRecordId';
export const DESCRIPTION_TYPE_KEY = 'typeDescription';
export const FINAL_KEY = 'isFinal';
export const SET_FINAL_KEY = 'setAsFinal';
export const FILE_KEY = 'fileId';
export const REVISION_KEY = 'revision';
export const REVISION_PARAM = 'revision';
export const REVISION_NAME_KEY = 'revisionName';
export const REVIEWER_IDS_KEY = 'reviewerIds';
export const APPROVER_IDS_KEY = 'approverIds';
export const TAG_IDS_KEY = 'tagIds';
export const REQUIRES_TRAINING_KEY = 'requiresTraining';
export const DUE_DATE_KEY = 'dueDate';
export const OPTIONAL_ADD_REVISION_KEYS = [
  DESCRIPTION_TYPE_KEY,
  SET_FINAL_KEY,
  REVISION_NAME_KEY,
  REVIEWER_IDS_KEY,
  APPROVER_IDS_KEY,
  TAG_IDS_KEY,
  REQUIRES_TRAINING_KEY,
];

export const FORMAT_DUE_DATE = 'YYYY-MM-DD';
export const FORMAT_DUE_DATE_TIME = 'YYY-MM-DD hh:mm:ss';
export const FORMAT_EXPORT_DATE = 'll HH-mm-ss';
export const NOTIFICATION_DATE = 'll HH:mm:ss';
export const FORMAT_TIME = 'HH:mm:ss';
export const CREATON_FORMAT = 'll';
export const DUE_DATE_DASHBOARD = 'll';
export const DUR_DATE_TRAINING_DASHBOARD = 'MMM DD';

export const DOCUMENT_EDIT_MODE = 'edit';
export const DOCUMENT_CREATE_MODE = 'create';

export const FIRST_RADIO = 0;
export const SECOND_RADIO = 1;

export const UNREAD = 'Unread';

export const ACCESS_USER_TAB = '0';
export const DOCUMENTS_USER_TAB = '1';
export const TASKS_USER_TAB = '2';
export const TRAINING_USER_TAB = '3';
export const DOCUMENT_USER_TAB = '4';

export const HR_STATUS = ['Active', 'Inactive'];
export const HR_ACTIVATED = ['Yes', 'No'];
export const HUMAN_RESOURCES = 'Human Resources';

export const SELECT_ALL = 'Select All';

export const FIRST_ACTIVE_TAB = '1';
export const SECOND_ACTIVE_TAB = '2';

export const STATUS_APPROVED = 2;

// Select field constants

export const DEBOUNCE_DELAY = 1000;

export const FIRST_SWITCH = 0;
export const SECOND_SWITCH = 1;
export const THIRD_SWITCH = 2;

// Dashboard constants

export const REVIEW_TAB = '1';
export const APPROVE_TAB = '2';

// Profile constants

export const PERSONAL_INFO_TAB = '1';
export const EMAIL_TAB = '2';
export const PASSWORD_TAB = '3';

// Help constants

export const INTRODUCTION_TAB = '1';
export const NOTES_SUPER_ADMIN_TAB = '2';
export const NOTES_ADMIN_TAB = '3';
export const MAIN_ARTIFACTS_TAB = '4';
export const GENERAL_FEATURE_TAB = '5';

export const ONE_ROUTE = 1;

export const ACTIVE = 'Active';

export const NOT_FOUND_INDEX = -1;
