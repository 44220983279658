import React from 'react';
import ReactPaginate from 'react-paginate';

const CustomPagination = ({ pageCount, forcePage, handlePageClick }) => {
  return (
    <ReactPaginate
      previousLabel={'‹'}
      nextLabel={'›'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      pageCount={pageCount}
      forcePage={forcePage}
      onPageChange={(pageNumber) => handlePageClick(pageNumber)}
      containerClassName={'pagination'}
      subContainerClassName={'pages Pagination'}
      activeClassName={'active'}
    />
  );
};

export default CustomPagination;
