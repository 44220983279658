import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { logoutUser } from '../../../store/actions';

const Logout = ({ logoutUser }) => {
  const history = useHistory();

  useEffect(() => {
    logoutUser(history);
  });

  return <></>;
};

export default connect(null, { logoutUser })(Logout);
