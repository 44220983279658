import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { AvField } from 'availity-reactstrap-validation';
import { unionBy } from 'lodash';

import Button from '../../../../components/Button';
import ReferencesAddDocument from '../../../../components/document/References/AddDocument';

import {
  INITIAL_LINK,
  INITIAL_OTHER_LINK,
} from '../../../../constants/document';
import { addNewItem } from '../../../../utils/documents/add-new-item';
import AddOtherReferences from '../../../../components/document/References/AddOther';
import { useRouteMatch } from 'react-router';
import { removeFilterIds } from '../../../../utils/documents/remove-filter-by-id';

const ReferencesSection = ({ createdDocument, handleCancelCreator }) => {
  const { id: organizationId } = useRouteMatch().params;

  const [references, updateReferences] = useState([]);

  const [documentLinks, updateDocumentLinks] = useState([INITIAL_LINK]);
  const [otherLinks, updateOtherLinks] = useState([INITIAL_OTHER_LINK]);

  const handleUpdateReferences = (values, partUrl, linkId) => {
    if (values) {
      const { value: linkDescription, id, revision, type } = values;

      let link = `${window.location.origin}/organizations/${organizationId}/${partUrl}/${id}`;
      if (revision) {
        link = `${link}?revision=${revision}`;
      }

      updateReferences((references) => {
        const equalRefIndex = references.findIndex(
          ({ linkId: id }) => linkId === id,
        );
        if (equalRefIndex === -1) {
          return [...references, { type, link, linkDescription, linkId }];
        }

        return references.map((reference) => {
          const { linkId: id } = reference;
          if (linkId === id) {
            return { type, link, linkDescription, linkId };
          }
          return reference;
        });
      });
      return;
    }
    updateReferences((references) =>
      references.filter(({ linkId: id }) => id !== linkId),
    );
  };

  const handleDelete = (linkId) => {
    updateDocumentLinks((items) => removeFilterIds(items, linkId));
    updateReferences((references) =>
      references.filter(({ linkId: id }) => id !== linkId),
    );
  };

  const handleDeleteOther = (linkId) => {
    updateOtherLinks((items) => removeFilterIds(items, linkId));
    updateReferences((references) =>
      references.filter(({ linkId: id }) => id !== linkId),
    );
  };

  return (
    <div className="general">
      <div className="general-header">
        <h6 className="general-header__title mt-2">References</h6>
      </div>
      <hr />
      <Row>
        <Col>
          <div className="info-column">
            <p className="info-column__title">Documents</p>
            {documentLinks.map((id) => {
              return (
                <ReferencesAddDocument
                  key={id}
                  id={id}
                  links={documentLinks}
                  handleDelete={handleDelete}
                  handleUpdateReferences={handleUpdateReferences}
                />
              );
            })}
            <Button
              color="btn btn-create"
              handler={() => updateDocumentLinks((prev) => addNewItem(prev))}
            >
              <i className="bx bx-plus"></i> Add New
            </Button>
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <div className="info-column">
            <p className="info-column__title">Other</p>
            {otherLinks.map((id) => {
              return (
                <AddOtherReferences
                  key={id}
                  id={id}
                  links={otherLinks}
                  handleDelete={handleDeleteOther}
                  handleUpdateReferences={handleUpdateReferences}
                />
              );
            })}
            <Button
              color="btn btn-create"
              handler={() => updateOtherLinks((prev) => addNewItem(prev))}
            >
              <i className="bx bx-plus"></i> Add New
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="form-group mt-4">
        <Col className="text-right">
          <Button
            type="button"
            color="btn btn-light w-md waves-effect waves-light mr-3 pl-4 pr-4"
            handler={handleCancelCreator}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="btn btn-edit w-md waves-effect waves-light pl-4 pr-4"
          >
            Save
          </Button>
        </Col>
      </Row>

      <AvField
        name="newReferences"
        type="hidden"
        value={unionBy(references, 'link')}
      />
      <AvField name="organizationId" type="hidden" value={organizationId} />
      <AvField name="id" type="hidden" value={createdDocument?.id} />
      <AvField
        name="revision"
        type="hidden"
        value={createdDocument?.revision}
      />
      <AvField name="isCreating" type="hidden" value="true" />
    </div>
  );
};

export default ReferencesSection;
