export const getUserLabel = ({ firstName, lastName, email, role }) => {
  return `${firstName || ''} ${lastName || ''} - ${email || ''} - ${
    role || ''
  }`;
};

export const getUserInputName = (user) => {
  const { firstName = '', lastName = '', email = '', role = '' } = user;
  return `${firstName} ${lastName} ${email ? `(${email})` : ''} ${
    role ? `-${role}` : ''
  }`;
};
