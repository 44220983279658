import { useState, useEffect } from 'react';

import { IS_CHECKED_KEY } from '../constants/table';
import { isEveryElement } from '../utils/shared/is-every-element';
import {
  changeCheckboxHidden,
  changePaginationCheckbox,
} from '../utils/tables-helper/change-checkbox-pagination';
import {
  changeCheckedAll,
  changeCheckedAllHidden,
} from '../utils/tables-helper/change-checked-all';

export const useCheckboxOrganizationTable = (
  { limit, pageNumber, search, offset },
  setPagination,
  updateShadowData,
) => {
  const [checkedHead, updateCheckedHead] = useState(false);

  useEffect(() => {
    updateCheckedHead(false);
  }, [limit, pageNumber, search]);

  const checkAllDocuments = (data) => {
    const isCheckedAll = isEveryElement(data, IS_CHECKED_KEY);

    setPagination((pagination) => changeCheckedAll(pagination, isCheckedAll));
    updateShadowData((data) =>
      changeCheckedAllHidden(data, offset, limit, isCheckedAll),
    );

    updateCheckedHead((isCheckedHead) =>
      isCheckedAll ? false : !isCheckedHead,
    );
  };

  const handleUpdateChecked = (checkboxId) => {
    setPagination((pagination) =>
      changePaginationCheckbox(pagination, checkboxId),
    );
    updateShadowData((data) => changeCheckboxHidden(data, checkboxId));
  };

  return {
    checkedHead,
    updateCheckedHead,
    handleUpdateChecked,
    checkAllDocuments,
  };
};
