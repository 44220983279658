export const getDisableSetting = (
  settings,
  start,
  end,
  startSection,
  endSection,
  startRelated,
  endRelated,
) => {
  return settings.map((setting) => {
    if (
      (start <= setting.type && setting.type < end) ||
      (startSection <= setting.type && setting.type < endSection) ||
      (startRelated <= setting.type && setting.type < endRelated)
    ) {
      return { type: setting.type, isSwitchedOn: false };
    }
    return setting;
  });
};

export const getCurrentSetting = (settings, type) => {
  const indexOfSetting = settings.findIndex((setting) => setting.type === type);
  if (!settings[indexOfSetting]) {
    return false;
  }
  return settings[indexOfSetting].isSwitchedOn;
};
