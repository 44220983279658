import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { CardBody, Card, Button, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import { User } from '../../../constants/role';
import {
  TRAINING_RECORD_TAB,
  TRAINING_PROGGRESS_TAB,
} from '../../../constants/helper';
import TaskDeleteModal from '../../../modals/TaskDelete';
import { deleteTrainingRecords } from '../../../utils/api/training-records-api';
import MessageResetSuccess from '../../ToastSuccess';
import { SUCCESS_TRAINING_DELETE } from '../../../constants/messages';

export const DetailsNavigation = ({
  data,
  user,
  activeTab,
  updateActiveTab,
  redirectBack,
  children,
}) => {
  const organizationId = useRouteMatch().params.id;
  const history = useHistory();

  const [model, updateModel] = useState({
    id: null,
    name: '',
    number: null,
    canBeDeleted: false,
  });

  useEffect(() => {
    if (data) {
      const { id, name, number, canBeDeleted } = data;
      updateModel({ id, name, number, canBeDeleted });
    }
  }, [data]);

  const toggleTabs = (tab) => {
    if (tab !== activeTab) {
      updateActiveTab(tab);
    }
  };

  const [deleteModal, updateDeleteModal] = useState({
    isOpen: false,
    values: {
      number: '',
      name: '',
    },
    model: {
      organizationId: '',
      ids: [],
    },
  });

  const handleDelete = (organizationId, id, number, name) => {
    updateDeleteModal({
      isOpen: true,
      values: { number, name },
      model: { organizationId, ids: [id] },
    });
  };

  const navTabs = [
    {
      tab: TRAINING_RECORD_TAB,
      text: 'Training record details',
    },
    {
      tab: TRAINING_PROGGRESS_TAB,
      text: 'Completion progress',
    },
  ];

  const { id, name, number, canBeDeleted } = model;

  return (
    <>
      <Card>
        <CardBody>
          <Link className="nav-back" to={redirectBack}>
            <i className="bx bx-chevron-left arrow"></i> Back to List
          </Link>
          <hr className="nav-line" />
          <ul
            className="nav nav-tabs nav-tabs-setting nav-custom-tabs"
            role="tablist"
          >
            {navTabs.map(({ tab, text }) => (
              <NavItem key={tab}>
                <NavLink
                  className={classnames({ active: activeTab === tab })}
                  onClick={() => toggleTabs(tab)}
                >
                  {text}
                </NavLink>
              </NavItem>
            ))}
          </ul>
          {!(user.role === User && !canBeDeleted) && (
            <Button
              color="danger"
              className="nav-button"
              onClick={() => handleDelete(organizationId, id, number, name)}
            >
              Delete record
            </Button>
          )}
        </CardBody>
      </Card>
      <TaskDeleteModal
        text="Training"
        isOpen={deleteModal.isOpen}
        model={deleteModal.model}
        values={deleteModal.values}
        onSubmit={(_, values) => {
          deleteTrainingRecords(values).then(() => {
            toast.success(
              <MessageResetSuccess message={SUCCESS_TRAINING_DELETE} />,
            );
            updateDeleteModal((prev) => ({ ...prev, isOpen: false }));
            history.push(`/organizations/${organizationId}/training-records`);
          });
        }}
        onCancel={() =>
          updateDeleteModal((prev) => ({ ...prev, isOpen: false }))
        }
      />
    </>
  );
};

export default DetailsNavigation;
