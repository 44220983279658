import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import { User } from '../../../../../constants/role';
import { getUserWithCount } from '../../../../../pipes/getUserWithCount';
import { isUserRole } from '../../../../../utils/shared/is-user-role';

const AccessPermission = ({
  user,
  userGroups,
  currentUser,
  organizationId,
}) => {
  const { firstName, lastName, email } = user;
  const { role } = currentUser;

  return (
    <div className="general">
      <div className="general-header">
        <h6>Access permissions</h6>
      </div>
      <hr />
      <div className="general-info">
        <div className="info-column access">
          <p className="info-column__title">
            Name:
            <i className="bx bx-info-circle access" id="info-tooltip"></i>
            <UncontrolledTooltip
              placement="top"
              id="tooltip-access"
              target="info-tooltip"
            >
              <span className="active">
                {isUserRole(role)
                  ? 'Here you can see your individual access permissions that resulted from the combination of access permissions in the User Group(s) where your are included. Only the settings displayed as turned on are applied for you'
                  : 'Here you can see the individual access permissions that resulted from the combination of access permissions in the User Group(s) where the current user is included. In case of needing to configure different permissions, an administrator should make sure the current user is included into User Group(s) with correct permissions set up. Please, consider, in case of being included into multiple Groups with different permissions, the user inherits wider permissions'}
              </span>
            </UncontrolledTooltip>
          </p>
          <p className="info-column__text">
            {firstName} {lastName} {email ? `-${email}` : ''}
          </p>
        </div>
      </div>
      <div className="user-group">
        <p className="user-group__title">User Group(s):</p>
        <ul className="user-group-list">
          {userGroups.map(({ id, name, userCount }, i, arr) => {
            return (
              <li key={id}>
                {role === User ? (
                  <p>{getUserWithCount(name, userCount)}</p>
                ) : (
                  <Link target='_self' 
                    to={{
                      pathname: `/organizations/${organizationId}/user-groups/${id}`,
                      state: { name },
                    }}
                  >
                    {getUserWithCount(name, userCount)}
                  </Link>
                )}
                {arr.length !== i + 1 && <hr />}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default AccessPermission;
