import React, { useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classnames from 'classnames';
import {
  getItemStyle,
  getListStyle,
} from '../../../utils/dragable-helpers/dragable-styles';

import { getStyleTableHead } from '../../../utils/styles/get-style-table-head';
import ResizingColumn from '../ResizingColumn';

const TableHead = ({
  columnsHidden,
  handleOrder,
  columnsHead,
  checkAllDocuments,
  checkedHead,
  updateCheckedHead,
  pagination,
  columns,
  handleChangeColumns,
  onResizeEnd,
  onResizeColumns,
}) => {
  const currentColumns = useRef(null);
  const currentColumnsHead = useRef(null);

  const onDragStart = () => {
    currentColumns.current = columns;
    currentColumnsHead.current = columnsHead;
  };

  const onDragUpdate = ({ source, destination }) => {
    handleChangeColumns(
      currentColumns.current,
      currentColumnsHead.current,
      source?.index,
      destination?.index,
    );
  };

  const { orderBy, orderType } = pagination;
  const [isResizing, updateIsResizing] = useState(false);

  return (
    <thead className="thead-light">
      <DragDropContext onDragStart={onDragStart} onDragUpdate={onDragUpdate}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <tr
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {columnsHead.map(
                (
                  { name, accessor, sort, classes, width, Component },
                  columnIndex,
                ) => (
                  <Draggable
                    key={name}
                    draggableId={name}
                    index={columnIndex}
                    isDragDisabled={isResizing}
                  >
                    {(provided, snapshot) => {
                      if (Component) {
                        return (
                          <Component
                            key={name}
                            name={name}
                            pagination={pagination}
                            checkAllDocuments={checkAllDocuments}
                            checkedHead={checkedHead}
                            updateCheckedHead={updateCheckedHead}
                            handleOrder={handleOrder}
                            columnsHidden={columnsHidden}
                            provided={provided}
                            snapshot={snapshot}
                            width={width}
                            columnIndex={columnIndex}
                            onResizeStart={(isStarted) =>
                              updateIsResizing(isStarted)
                            }
                            onResizeEnd={() => onResizeEnd(columnIndex)}
                            onResizeColumns={onResizeColumns}
                          />
                        );
                      }
                      return (
                        <th
                          key={name}
                          className={classnames(
                            classes,
                            { 'head-sort': sort },
                            { 'd-cursor-scroll': isResizing },
                            ...getStyleTableHead(
                              columnsHidden[name],
                              name,
                              orderBy,
                              orderType,
                            ),
                          )}
                          onClick={() => {
                            sort && !isResizing && handleOrder(name);
                          }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            minWidth: `${width}px`,
                            maxWidth: `${width}px`,
                            ...getItemStyle(
                              snapshot,
                              provided.draggableProps.style,
                            ),
                          }}
                        >
                          <span>{accessor}</span>
                          <ResizingColumn
                            columnIndex={columnIndex}
                            onResizeStart={(isStarted) =>
                              updateIsResizing(isStarted)
                            }
                            onResizeEnd={() => onResizeEnd(columnIndex)}
                            onResizeColumns={onResizeColumns}
                          />
                        </th>
                      );
                    }}
                  </Draggable>
                ),
              )}
            </tr>
          )}
        </Droppable>
      </DragDropContext>
    </thead>
  );
};

export default TableHead;
