import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import CustomTooltip from '../../../../components/tooltips/CustomTooltip';

import { changeNotification } from '../../../../utils/api/notification-api';

const UserNotification = ({
  user: { emailNotificationState },
  handleUpdateUser,
}) => {
  const [isChecked, updateIsChecked] = useState(false);

  useEffect(() => {
    updateIsChecked(emailNotificationState);
  }, [emailNotificationState]);

  const handleChangeNotification = () => {
    changeNotification().then(({ data }) => handleUpdateUser(data));
  };

  return (
    <Row>
      <Col>
        <CustomTooltip
          id="notification-tooltip"
          className="d-flex justify-content-between"
          text="You can turn On-Off email notifications about new Actions assigned to you"
        >
          <div
            dir="ltr"
            id="notification-tooltip"
            className="custom-control custom-switch custom-switch-lg"
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id="customSwitchsizelg"
              onChange={handleChangeNotification}
              checked={isChecked}
            />
            <label
              className="custom-control-label notification-text"
              htmlFor="customSwitchsizelg"
            >
              Notifications
            </label>
          </div>
        </CustomTooltip>
      </Col>
    </Row>
  );
};

export default UserNotification;
